import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { uuid } from 'short-uuid';
import styled from 'styled-components';

import { DEFAULT_SAVINGS_ENTRY } from './constants';
import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import Button from '../../lib/Button';
import { IncentiveSavingType } from '../types';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  padding: 1px 7px;
  border-radius: 10px;
  font-size: 12px;
  height: 20px;
  color: var(--text-primary);
`;

const IncentivesCreateOffersSavingAddEntry = () => {
  const { setSavings } = useIncentivesCreateModalContext();

  const handleAddSaving = (event: any) => {
    setSavings((prev: IncentiveSavingType[]) => {
      return [...prev, Object.assign({}, DEFAULT_SAVINGS_ENTRY, { id: uuid() })];
    });
  };

  return (
    <Container>
      <StyledButton
        onClick={handleAddSaving}
        icon={<PlusOutlined style={{ color: 'var(--button-blue)', fontSize: 12 }} />}
      >
        Add More Savings
      </StyledButton>
    </Container>
  );
};

export default IncentivesCreateOffersSavingAddEntry;
