import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MailOutlined } from '@ant-design/icons/lib';
import { Button, Divider, Form, Input, message, Modal } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';
import styled from 'styled-components';

import { useCMASummaryContext } from './CMASummaryContext';
import CMASummaryExcelDownload from './CMASummaryExcelDownload';
import { FILE_EXTENSION, MODAL_TYPES, MODAL_WIDTH } from './constants';
import { generateExcelFile } from './utils';
import { STATUS } from '../../../constants';
import { validateEmail } from '../../../helpers/emails';
import { useAppSelector } from '../../../store/hook';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 25px;
`;

const CMASummaryEmailModal = () => {
  const [params] = useSearchParams();
  const communityId = params.get('id') as string;

  const { openModal, setOpenModal, showMessage, communityName, currentUser, tableDataHeaders, tableDataSource } =
    useCMASummaryContext();

  const [status, setStatus] = useState(STATUS.IDLE);

  const handleConfirm = async (formData: any) => {
    const { recipients, subject, message } = formData;

    setStatus(STATUS.LOADING);
    const recipientsList = recipients.split(';');
    const data = new FormData();

    const filename = `${communityName}-${format(new Date(), 'MM-dd-yyyy')}`;
    await generateExcelFile(tableDataSource, tableDataHeaders, filename, currentUser.name, communityName).then(
      (workbookBlob: any) => {
        data.append('attachment', workbookBlob);
      }
    );

    const fullFilename = `${filename}${FILE_EXTENSION}`;
    data.append('filename', fullFilename);
    data.append('subject', subject);
    data.append('sender', currentUser.fullName);
    data.append('message', message);

    recipientsList.forEach((recipient: string) => {
      data.append('recipients', recipient.trim());
    });

    axios
      .post(`/communities/${communityId}/send-cma-summary`, data)
      .then((response) => {
        setStatus(STATUS.LOADED);
        showMessage('success', 'CMA Summary sent successfully.');
        setOpenModal('');
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Trouble sending CMA Summary via email.');
        setStatus(STATUS.FAILURE);
      });
  };

  const handleCancel = () => {
    setOpenModal('');
  };

  const recipientAddressValidator = (_: any, value: string) => {
    const entries = value.split(';');
    if (entries.every((emailEntry) => validateEmail(emailEntry))) {
      return Promise.resolve();
    }

    return Promise.reject('Please provide correct email address');
  };

  const initialValues = {
    recipients: currentUser.email,
    subject: `CMA Summary for ${communityName}`,
    message: '',
  };

  return (
    <Modal
      title="Send CMA Summary via Email"
      open={openModal === MODAL_TYPES.EMAIL}
      onCancel={handleCancel}
      width={MODAL_WIDTH}
      footer={[]}
      destroyOnClose
    >
      <Container>
        <Divider />
        <StyledForm
          onFinish={handleConfirm}
          labelAlign="left"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          autoComplete="off"
          disabled={status === STATUS.LOADING}
        >
          <StyledFormItem
            label="Recipient Email"
            name="recipients"
            extra="use semi-colon for multiple email address."
            rules={[
              {
                validator: recipientAddressValidator,
              },
            ]}
          >
            <Input addonBefore={<MailOutlined />} />
          </StyledFormItem>
          <StyledFormItem
            label="Subject"
            name="subject"
            rules={[{ required: true, message: 'Please provide subject' }]}
          >
            <Input />
          </StyledFormItem>
          <StyledFormItem
            label="Message"
            name="message"
            rules={[{ required: true, message: 'Please provide message content' }]}
          >
            <Input.TextArea rows={4} placeholder={'Write your message here...'} />
          </StyledFormItem>
          <StyledFormItem label="Attached File">
            <CMASummaryExcelDownload>
              <Button size={'small'}>Preview/Download</Button>
            </CMASummaryExcelDownload>
          </StyledFormItem>
          <Form.Item wrapperCol={{ span: 3, offset: 21 }}>
            <Button type="primary" loading={status === STATUS.LOADING} htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </StyledForm>
      </Container>
    </Modal>
  );
};

export default CMASummaryEmailModal;
