import React, { useState } from 'react';

import { RadioChangeEvent, Typography } from 'antd';
import styled from 'styled-components';

import Radio from 'components/lib/Radio';

import { useShopRequestModalContext } from './ShopRequestModalContext';
import { MYSTERY_SHOP_MODAL_REQUEST_FROM } from '../constants';
import { useShopRequestsContext } from '../ShopRequestsContext';
import ShopRequestStyledSelect from '../ShopRequestStyledSelect';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  height: 36px;
  padding: 11px 30px;
  align-items: center;
  border-bottom: solid 1px var(--line-gray);
`;

const StyledLabel = styled(Typography.Text)`
  margin-right: 30px;
  font-family: var(--font-bold);
`;

const StyledRadioButton = styled(Radio)`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const ShopRequestModalSourceSelector = () => {
  const { requestFrom, selectedCompany, setSelectedCompany, setRequestFrom, setShopRequests, setShopForBaseCommunity } =
    useShopRequestModalContext();
  const { companies } = useShopRequestsContext();

  const onChangeSource = (e: RadioChangeEvent) => {
    setRequestFrom(e.target.value);
    setShopRequests([]);
    setShopForBaseCommunity(false);
  };

  return (
    <Container>
      <StyledLabel>Request From</StyledLabel>
      {/* 
      For now we don't support requests from FURTHER, only from the COMPANY
      <Radio.Group
        onChange={onChangeSource}
        value={requestFrom}
      >
        <StyledRadioButton value={MYSTERY_SHOP_MODAL_REQUEST_FROM.FURTHER}>Further</StyledRadioButton>
        <StyledRadioButton value={MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY}>Company</StyledRadioButton>
      </Radio.Group> */}
      {requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY && (
        <ShopRequestStyledSelect
          placeholder="Select company"
          options={companies?.map((c) => ({ value: c.id, label: c.name }))}
          onChange={(companyId) => setSelectedCompany(companyId)}
          value={selectedCompany}
          style={{ width: '290px' }}
          showSearch
          // search should be handled by backend to allow fuzzy searching
          filterOption={(input, option) =>
            Boolean(option?.label) &&
            typeof option?.label === 'string' &&
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      )}
    </Container>
  );
};

export default ShopRequestModalSourceSelector;
