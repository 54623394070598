import { AccommodationDataType } from '../../pageComponents/AddCommunityCompetitor/accommodation/constants';
import { LivingInfoDataType } from '../../pages/price_healing/Accommodation/constants';
import { CareFeeDateType, CommunityFeeDateType } from '../../pages/price_healing/Fees/constants';
import { OccupancyType } from '../../pages/price_healing/Occupancies/constants';

export type EditableTableDataType =
  | CommunityFeeDateType
  | CareFeeDateType
  | LivingInfoDataType
  | AccommodationDataType
  | OccupancyType;

export const DEPENDENCY_TYPE = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};
