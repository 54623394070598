import React, { useEffect, useState } from 'react';

import { Image, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import axios from 'config/axiosPrivate';
import IsValidImage from 'formComponents/isValidImage';

function groupByApartmentId(data: any[]) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val.apartment_id;
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
}

interface DataType {
  id: any;
  CommunityName: string;
  Type: 'apartment' | 'competitor';
  careFee: {
    id: number;
    amount: string;
    fee_note: string;
    units: string;
    occupancy: string;
    unit_note: '';
    level_1: number;
    level_2: number;
    level_3: number;
    level_4: number;
    level_5: number;
    level_6: number;
    level_7: number;
    level_8: number;
    medication_management_fee: number;
    care_pricing_type: string;
    additional_care_levels_rates: string;
    additional_care_costs: string;
    status: 1;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    average_care_cost: number;
    Community: string;
    LivingType: string;
    abv: string;
    LivingTypeid: number;
    FeeType: string;
    FeeTypeid: number;
  };
  key: string;
}

interface Props {
  community_id: number;
}

const CompareImage = ({ community_id }: Props) => {
  const [tableCollumns, settableCollumns] = useState<any[]>([]);
  const [tableData, settableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (community_id) {
      setLoading(true);
      axios
        .get(`/communities/Get_CommunityCompareImages/${community_id}/`)
        .then((res) => {
          setLoading(false);
          const baseUrl = res.data?.BaseURL;

          const headerData: any[] = res.data?.payload?.Header;
          const Combination: any[] = res.data?.payload?.Combination;
          const Data: any[] = res.data?.payload?.Data;

          const table_columns: ColumnsType<DataType> = headerData.map((collumn: any) => ({
            title: (
              <div
                style={{
                  padding: '10px 4px',
                  whiteSpace: 'normal',
                }}
              >
                <Typography.Title style={{ margin: 0 }} level={4}>
                  {collumn?.CommunityName}

                  {collumn?.CommunityType === 'apartment' && (
                    <span
                      style={{
                        background: '#4CD7D2',
                        color: '#fff',
                        fontSize: '12px',
                        padding: '5px 9px',
                        borderRadius: '10px',
                        marginLeft: 6,
                        whiteSpace: 'normal',
                      }}
                    >
                      ME
                    </span>
                  )}
                </Typography.Title>
              </div>
            ),
            dataIndex: collumn?.CommunityID,
            key: collumn?.CommunityID,
            width: collumn?.CommunityType === 'apartment' ? 400 : 300,
            render: (value) => {
              if (value) {
                const data = value[0];
                return (
                  <div>
                    <div>
                      {data.Type == 'apartment' ? (
                        <Typography.Title level={4} style={{ margin: 0, marginBottom: -10 }}>
                          {data?.image_category}
                        </Typography.Title>
                      ) : (
                        ' '
                      )}
                    </div>
                    <div
                      style={{
                        paddingBottom: 6,
                        paddingRight: 5,
                        marginTop: 10,
                      }}
                    >
                      {/*@ts-ignore */}
                      <IsValidImage
                        url={
                          data.image_based_on === 'upload' ? `${baseUrl}/media/${data?.image_path}` : data?.image_url
                        }
                      />

                      {/* // <Image
                          //   width={"200px"}
                          //   height={150}
                          //   style={{ objectFit: "cover" }}
                          //   src={
                          //     data.image_based_on === "upload"
                          //       ? `${baseUrl}/media/${data?.image_path}`
                          //       : data?.image_url
                          //   }
                          //   fallback="/no-image.png"
                          // /> */}
                    </div>
                  </div>
                );
              }
            },
          }));
          settableCollumns(table_columns);

          const my_Community = Data.filter((el) => el?.Type === 'apartment');

          // set Table data
          const apartmentData = my_Community[0];

          // set Table data
          const rows = Combination.map((cmb: any) => {
            const cmb_data = Data.filter((d) => d?.Combination_Id === cmb?.Combination_Id);

            const hasCommunity = cmb_data?.find((el) => el?.Type === 'apartment');

            if (cmb_data.length > 0 && !hasCommunity) {
              const communityData = {
                id: `${Date.now() + Math.random()}`,
                company_id: 22,
                apartment_id: apartmentData ? apartmentData?.apartment_id : headerData[0]?.CommunityID,
                name: 'image',
                image_path: '',
                created_at: '2023-07-16T17:58:30.199581',
                updated_at: '2023-07-16T17:58:30.199604',
                image_based_on: 'upload',
                image_url: '',
                image_category: cmb_data[0]?.image_category,
                image_name: '',
                image_extension: '',
                CommunityName: 'Atria Buckhead ',
                CommunityId: 804,
                CompanyId: 22,
                CompanyName: 'Caddis Healthcare Real Estate',
                Type: 'apartment',
                Combination_Id: 2,
              };

              cmb_data.push(communityData);
            }

            return {
              ...groupByApartmentId(cmb_data),
            };
          });
          settableData(rows);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [community_id]);
  //
  return (
    <div>
      <Table
        columns={tableCollumns}
        dataSource={tableData}
        scroll={{ x: 'max-content', y: '80vh' }}
        size="middle"
        bordered={true}
        className="p_healing_table"
        loading={loading}
        pagination={false}
        rowKey={() => Math.random()}
      />
    </div>
  );
};

export default CompareImage;
