import React from 'react';

import DashboardColumnChart from '../charts/DashboardColumnChart';
import { CommunityLivingDataType } from '../datatypes';

interface Props {
  data: CommunityLivingDataType[];
}

export const DashboardReportGoogleRatingChart = ({ data }: Props) => {
  const tooltipFormatter = (item: any) => {
    return {
      name: item.community_name,
      value: item?.google_rating >= 0 ? `${item.google_rating.toFixed(1)}` : '',
    };
  };

  const labelFormatter = (item: any) => {
    return item?.google_rating >= 0 ? `${item.google_rating.toFixed(1)}` : '';
  };

  const tooltipSorter = (originalItems: any) => {
    return originalItems.sort((a: any, b: any) => a.data.google_rating - b.data.google_rating);
  };

  return (
    <DashboardColumnChart
      title="Google Ratings Comparison"
      data={data}
      xField="community_name"
      yField="google_rating"
      grouped
      seriesField="community_name"
      labelFormatter={labelFormatter}
      tooltipFormatter={tooltipFormatter}
      tooltipSorter={tooltipSorter}
      tooltipShowTitle={false}
    />
  );
};

export default DashboardReportGoogleRatingChart;
