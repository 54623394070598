import React from 'react';

import { Button, theme } from 'antd';

type Props = {
  title: string;
  textAlign?: 'left' | 'center' | 'right';
};

const CommunityTitleBar = ({ title, textAlign = 'center' }: Props) => {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        background: '#F7F7F7',
        textAlign: textAlign,
        padding: '5px 20px',
        borderRadius: '8px',
        color: '#060606',
        width: '100%',
      }}
    >
      <Button
        type="text"
        className="p_healing_active_tab"
        shape="round"
        style={{
          marginRight: 20,
          height: 'auto',
          padding: '5px 20px',
          fontSize: 18,
          pointerEvents: 'none',
        }}
      >
        {title}
      </Button>
    </div>
  );
};

export default CommunityTitleBar;
