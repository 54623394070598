import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { handleUpdateFeatureFlags } from 'store/slices/authSlice';
import { handleSetSelectedCommunity, handleSetSelectedManagementCompany } from 'store/slices/globalSlice';

import ConfirmModal from '../../../common/Modal/ConfirmModal';
import { USER_LEVELS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import Button from '../../lib/Button';

const StyledButton = styled(Button)`
  background: linear-gradient(270deg, var(--neutral-purple), var(--sidebar-purple-light));
  background-size: 400% 400%;

  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const HeaderUserApplicationSwitch = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentUser, featureFlags } = useAppSelector((state) => state.auth);
  const { selectedClient } = useAppSelector((state) => state.global);

  const [openAppSwitchModal, setOpenAppSwitchModal] = useState<boolean>(false);

  const handleApplicationSwitch = (showBetaApp: boolean) => {
    dispatch(handleUpdateFeatureFlags({ ...featureFlags, showBetaApplication: showBetaApp }));
    if (selectedClient?.level == USER_LEVELS.COMPANY) {
      dispatch(handleSetSelectedManagementCompany(undefined));
      dispatch(handleSetSelectedCommunity(undefined));
    }
    navigate('/');
  };

  if (
    !currentUser?.feature_flags ||
    currentUser?.feature_flags?.use_beta_application ||
    !currentUser.feature_flags.try_beta_application
  ) {
    return null;
  }

  return (
    <div>
      {!featureFlags?.showBetaApplication && (
        <>
          <ConfirmModal
            open={openAppSwitchModal}
            title={'Switch to new App'}
            description={'This action will showcase design updates and provide new insights using existing data.'}
            confirmLabel={'I understand'}
            onConfirm={() => handleApplicationSwitch(true)}
            onCancel={() => setOpenAppSwitchModal(false)}
          />
          <StyledButton
            type="primary"
            shape="round"
            size="small"
            style={{ height: 30 }}
            onClick={() => setOpenAppSwitchModal(true)}
          >
            Try the New Version
          </StyledButton>
        </>
      )}
      {featureFlags?.showBetaApplication && (
        <Button
          type="primary"
          shape="round"
          size="small"
          style={{ height: 30 }}
          onClick={() => handleApplicationSwitch(false)}
        >
          Switch to Classic
        </Button>
      )}
    </div>
  );
};

export default HeaderUserApplicationSwitch;
