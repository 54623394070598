import React, { createContext, useContext } from 'react';

type SwotCompetitorAnalysisContent = {
  step: number;
  selectedAction: string;
  selectedCompetitor: number;
  communityOptions: any;
  setStep: (value: number) => void;
  setSelectedAction: (value: string) => void;
  setSelectedCompetitor: (value: number) => void;
  handleClose: () => void;
  onCompetitorSelect: (value: number) => void;
};

export const SwotCompetitorAnalysisContext = createContext<SwotCompetitorAnalysisContent>({
  step: 1,
  selectedAction: 'editData',
  selectedCompetitor: 0,
  communityOptions: [],
  setStep: (value: number) => {
    // Do Nothing
  },
  setSelectedAction: (value: string) => {
    // Do Nothing
  },
  setSelectedCompetitor: (value: number) => {
    // Do Nothing
  },
  handleClose: () => {
    // Do Nothing
  },
  onCompetitorSelect: (value: number) => {
    // Do Nothing
  },
});

export const useSwotCompetitorAnalysisContext = () => useContext(SwotCompetitorAnalysisContext);
