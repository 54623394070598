import React from 'react';

import { UnitType } from '../../types';

interface Props {
  record: UnitType;
}

const IncentivesTableUnitsCommunity = ({ record }: Props) => (
  <span
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <span>{record?.community?.comunity_name}</span>
    <span
      style={{
        fontSize: 12,
        color: 'var(--text-secondary)',
      }}
    >
      {record?.community?.address}
    </span>
  </span>
);

export default IncentivesTableUnitsCommunity;
