import React from 'react';

import styled from 'styled-components';

import ShopRequestModalCommunityRow from './ShopRequestModalCommunityRow';
import { useShopRequestModalContext } from './ShopRequestModalContext';
import ShopRequestModalDropdown from './ShopRequestModalDropdown';
import { MYSTERY_SHOP_MODAL_REQUEST_FROM } from '../constants';

const Container = styled.div`
  padding: 18px 30px;
  max-height: 348px;
  overflow-y: auto;
  min-width: 600px;
`;

const ShopRequestModalCommunityRows = () => {
  const { requestFrom, shopRequests, selectedCompany, remainingCommunities, selectedBaseCommunity, selectCommunity } =
    useShopRequestModalContext();

  const disableAddingCompetitors =
    requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY && (!selectedCompany || !selectedBaseCommunity);

  return (
    <Container>
      {shopRequests.map((el: any) => (
        <ShopRequestModalCommunityRow shopRequest={el} key={el.communityId} />
      ))}
      <ShopRequestModalDropdown
        communities={remainingCommunities}
        disabled={disableAddingCompetitors}
        value={null}
        onChange={selectCommunity}
      />
    </Container>
  );
};

export default ShopRequestModalCommunityRows;
