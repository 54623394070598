import React, { ReactNode } from 'react';

import { format } from 'date-fns';

import { useCMASummaryContext } from './CMASummaryContext';
import { exportToExcel } from './utils';

interface Props {
  children: ReactNode;
}

const CMASummaryExcelDownload = ({ children }: Props) => {
  const { tableDataHeaders, tableDataSource, currentUser, communityName } = useCMASummaryContext();

  const handleClick = () => {
    exportToExcel(tableDataSource, tableDataHeaders, currentUser.name, communityName);
  };

  return <div onClick={handleClick}>{children}</div>;
};

export default CMASummaryExcelDownload;
