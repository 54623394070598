import React from 'react';
import { useQuery } from 'react-query';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import { matchSorter } from 'match-sorter';

import axios from 'config/axiosPrivate';
import AddMystryShopModal from 'modals/AddMystryShopModal';
import UpdateStatusModal from 'modals/UpdateStatusModal';

import AdminPageHeader from '../../common/Admin/AdminPageHeader';
import { useAppSelector } from '../../store/hook';
import MysteryShopTable from '../MysteryShop/MysteryShopTable';

const MysteryShop = () => {
  const state = useAppSelector((state) => state.global);
  const { selectedCompany, selectedManagementCompany } = state;

  const [open, setOpen] = React.useState<boolean>(false);
  const [openUpdateStatus, setOpenUpdateStatus] = React.useState<boolean>(false);
  const [allData, setAllData] = React.useState<any[]>([]);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [selectedData, setSelectedData] = React.useState<any>(null);
  const [selectedStatus, setStatus] = React.useState(1);
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const mystry_state = useQuery({
    queryKey: 'Get_MysteryShops',
    queryFn: () =>
      axios.get(`/communities/mystery-shops/${selectedCompany?.id}`).then((response) => {
        const updatedData = response.data?.map((el: any, index: number) => ({
          ...el,
          sr_no: index + 1,
        }));
        return updatedData;
      }),
    onSuccess: (data) => {
      setAllData(data);
      setTableData(data);
    },
  });

  const handleStatusChange = (value: number) => {
    setStatus(value);
    if (value === 0) {
      setTableData(allData);
      return;
    }
    const selectedData = allData.filter((d) => `${d.status}` === value.toString());
    setTableData(selectedData);
  };

  const handleEdit = (data: any) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleDelete = async (data: any) => {
    const payload = {
      id: data.id,
      op: 'deleted',
    };
    await axios
      .post('/communities/Save_MysteryShop/', { data: [payload] })
      .then((res) => {
        showMessage('success', 'Deleted successfully!');
        mystry_state.refetch();
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong');
      });
  };

  const handleOnSearch = (value: string) => {
    const results = matchSorter(allData, value, {
      keys: ['apartment_name', 'request_deadline', 'user_name'],
    });
    setTableData(results);
  };

  const handleUpdateStatus = (data: any) => {
    setSelectedData(data);
    setOpenUpdateStatus(true);
  };
  const handleCancel = () => {
    setSelectedData(null);
    setOpen(false);
    setOpenUpdateStatus(false);
  };

  return (
    <div style={{ padding: 20, width: '100%' }}>
      <AdminPageHeader title={'Shop Requests'} description={'Manage Mystery Shop requests'} />
      {contextHolder}
      <UpdateStatusModal
        open={openUpdateStatus}
        handleClose={handleCancel}
        selectedId={selectedData?.id}
        status={selectedData?.status}
        onSuccess={() => {
          showMessage('success', 'Saved successfully!');
          mystry_state.refetch();
          setOpenUpdateStatus(false);
        }}
      />
      {open && (
        <AddMystryShopModal
          open={open}
          handleClose={handleCancel}
          onSuccess={() => {
            showMessage('success', 'Saved successfully!');
            mystry_state.refetch();
            setSelectedData(null);
            setOpen(false);
          }}
          selectedData={selectedData}
        />
      )}

      <Form layout="vertical" requiredMark={false} autoComplete="off">
        <Row gutter={[10, 10]}>
          <Col xs={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '450px' }}>
                <Select
                  placeholder="Select"
                  options={[
                    { label: 'All', value: 0 },
                    { label: 'Requested', value: 1 },
                    { label: 'In Progress', value: 2 },
                    { label: 'Ready for Review', value: 3 },
                    { label: 'Solved', value: 4 },
                    { label: 'Satisfied', value: 5 },
                  ]}
                  style={{ width: '100%' }}
                  onSelect={(value) => handleStatusChange(value)}
                  value={selectedStatus}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  prefix={<SearchOutlined />}
                  style={{ marginRight: 20 }}
                  placeholder="Search..."
                  onChange={(e) => handleOnSearch(e.target.value)}
                />
                <Button
                  icon={<PlusOutlined />}
                  type="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpen(true)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <br />
      <MysteryShopTable
        data={tableData}
        loading={mystry_state.isLoading}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleUpdateStatus={handleUpdateStatus}
      />
    </div>
  );
};

export default MysteryShop;
