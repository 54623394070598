import React from 'react';
import { useParams } from 'react-router';

import CommunityView from './CommunityView';
import { COMMUNITY_TYPE } from '../../constants';
import { useAppSelector } from '../../store/hook';

const CompetitorViewWrapper = () => {
  const { viewId } = useParams();
  const { selectedCommunity } = useAppSelector((state) => state.global);

  const communityId =
    selectedCommunity?.type === COMMUNITY_TYPE.COMMUNITY ? selectedCommunity?.id : selectedCommunity?.parentId;

  const competitorId = selectedCommunity?.type === COMMUNITY_TYPE.COMPETITOR ? selectedCommunity.id : undefined;

  return (
    <CommunityView
      communityId={communityId || -1}
      competitorId={competitorId}
      viewId={parseInt(viewId ?? '')}
      fallbackUrl={`/community/${communityId}`}
    />
  );
};

export default CompetitorViewWrapper;
