import React from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';
import Select from 'components/lib/Select';

import { END_REPEAT, SHOP_REQUEST_REPEAT_OCCURENCES_LABELS } from './constants';

const Container = styled.div`
  width: 50%;
`;

const StyledCheckbox = styled(Checkbox)`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-bottom: 10px;
  &&& .ant-checkbox + span {
    padding-inline-start: 10px;
    padding-inline-end: 30px;
    display: flex;
    align-items: center;
  }
`;

const StyledSelect = styled(Select)`
  &&&.ant-select {
    // width: 148px;
    width: 100%;
    height: 34px;
    .ant-select-selection-item {
      font-size: 14px;
    }
    .ant-select-selector {
      border-radius: 4px;
    }
  }
`;

type Props = {
  repeat: number;
  endRepeat: number;
  repeatEnabled: boolean;
  setEndRepeat: (value: number) => void;
  setRepeatEnabled: (value: boolean) => void;
  setRepeat: (value: number) => void;
};

const ShopRequestsRepeatPicker = ({
  repeatEnabled,
  repeat,
  endRepeat,
  setRepeat,
  setEndRepeat,
  setRepeatEnabled,
}: Props) => {
  const repeatOptions = [
    { value: 1, label: 'Every month' },
    { value: 2, label: 'Every quarter' },
    { value: 3, label: 'Every 6 months' },
    { value: 4, label: 'Every year' },
  ];
  const endRepeatOptions = [
    { value: 1, label: 'After 1 year' },
    { value: 2, label: 'After 2 year' },
    { value: 3, label: 'After 3 year' },
  ];

  return (
    <Container>
      <StyledCheckbox
        checked={repeatEnabled}
        className={'green'}
        onChange={(event: CheckboxChangeEvent) => setRepeatEnabled(event.target.checked)}
      >
        Repeat
      </StyledCheckbox>
      <StyledSelect
        options={repeatOptions}
        value={{ value: repeat, label: SHOP_REQUEST_REPEAT_OCCURENCES_LABELS[repeat] }}
        disabled={!repeatEnabled}
        onChange={(value: number) => setRepeat(value)}
      />
      <StyledCheckbox checked={repeatEnabled} className={'green'} disabled={true} style={{ marginTop: '15px' }}>
        End Repeat
      </StyledCheckbox>
      <StyledSelect
        options={endRepeatOptions}
        value={{ value: endRepeat, label: END_REPEAT[endRepeat] }}
        disabled={!repeatEnabled}
        onChange={(value: number) => setEndRepeat(value)}
      />
    </Container>
  );
};

export default ShopRequestsRepeatPicker;
