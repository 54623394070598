import { Dayjs } from 'dayjs';

export type CommunityFeeDateType = {
  id?: number;
  key: string;
  livingTypeId: number;
  livingType: string;
  communityFee: number;
  frequency: number;
  frequencyName?: string;
  secondPersonFee: number;
  petFee: number;
  parkingFee: number;
  respiteFee: number;
  additionalFee: number;
  moveInFee: number;
  entranceFee: number;
  deposit: number;
  depositEquivalentFactor: number;
  incentiveValue: number;
  incentive: string;
  createdBy: string;
  createdAt: Date | Dayjs;
  updatedBy: string;
  updatedAt: Date | Dayjs;
  date: Date | Dayjs;
};

export type CareFeeDateType = {
  id?: number;
  key: string;
  livingTypeId: number;
  livingType: string;
  feesTypeId: number;
  feesType: string;
  feeNote: string;
  medicationManagementFee: number;
  averageCareCost: number;
  level1: number;
  level2: number;
  level3: number;
  level4: number;
  level5: number;
  level6: number;
  level7: number;
  level8: number;
  createdBy: string;
  createdAt: Date | Dayjs;
  updatedBy: string;
  updatedAt: Date | Dayjs;
  date: Date | Dayjs;
};

export const FREQUENCY_SELECT_VALUE = 0;
export const FREQUENCY_OPTIONS = [
  {
    value: FREQUENCY_SELECT_VALUE,
    label: 'Select Value ',
  },
  {
    value: 0.5,
    label: 'Half Month',
  },
  {
    value: 1,
    label: '1 Month',
  },
  {
    value: 2,
    label: '2 Month',
  },
  {
    value: 3,
    label: '3 Month',
  },
];

export const LEVELS = ['level1', 'level2', 'level3', 'level4', 'level5', 'level6', 'level7', 'level8'];
