import React from 'react';

import FeesFormHistoryCareFees from './FeesFormHistoryCareFees';
import FeesFormHistoryCommunityFees from './FeesFormHistoryCommunityFees';

const FeesFormHistory = () => (
  <>
    <FeesFormHistoryCommunityFees />
    <FeesFormHistoryCareFees />
  </>
);

export default FeesFormHistory;
