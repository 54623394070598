import React, { useEffect, useState } from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { DatePicker } from 'components/DatePicker/DatePicker';
import Radio from 'components/lib/Radio';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';
import ShopRequestsRepeatPicker from '../ShopRequestsRepeatPicker';

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-flow: column;
`;

const StyledRadioButton = styled(Radio)`
  font-family: var(--font-regular);
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 20px 30px;
  max-width: 370px;
  box-sizing: border-box;
  border-bottom: solid 1px var(--line-gray);
`;

const Title = styled(Typography.Text)`
  font-weight: 900;
  margin-bottom: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
`;

const EditShopRequestModalDates = () => {
  const { shopRequest, setShopRequest } = useEditShopRequestModalContext();
  const [repeatEnabled, setRepeatEnabled] = useState(false);

  useEffect(() => {
    if (shopRequest) {
      setRepeatEnabled(Boolean(shopRequest.endRepeat));
    }
  }, []);

  const changeDate = (date: Date) => {
    if (shopRequest) {
      setShopRequest({ ...shopRequest, dueDate: date });
    }
  };
  const changeRepeat = (value: number) => {
    if (shopRequest) {
      setShopRequest({ ...shopRequest, repeat: value });
    }
  };
  const changeEndRepeat = (value: number) => {
    if (shopRequest) {
      setShopRequest({ ...shopRequest, endRepeat: value });
    }
  };

  return (
    <Container>
      <Column>
        <Title>Due date</Title>
        <DatePicker selectedDate={shopRequest!.dueDate} onChange={changeDate} />
      </Column>
      <ShopRequestsRepeatPicker
        repeat={shopRequest?.repeat || 1}
        endRepeat={shopRequest?.endRepeat || 1}
        repeatEnabled={repeatEnabled}
        setRepeat={changeRepeat}
        setEndRepeat={changeEndRepeat}
        setRepeatEnabled={setRepeatEnabled}
      />
    </Container>
  );
};

export default EditShopRequestModalDates;
