import React from 'react';

import { Checkbox, InputNumber, InputNumberProps, Slider } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 20px;
  padding-left: 26px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

type Props = {
  overMarketRateEnabled: boolean;
  overMarketRate: number;
  setOverMarketRate: (rate: number) => void;
  setOverMarketRateEnabled: (value: boolean) => void;
  setUnderMarketRate: (rate: number) => void;
  underMarketRate: number;
  underMarketRateEnabled: boolean;
  setUnderMarketRateEnabled: (value: boolean) => void;
};

const MarketRatePreferences = ({
  overMarketRateEnabled,
  overMarketRate,
  setOverMarketRate,
  setOverMarketRateEnabled,
  setUnderMarketRate,
  underMarketRate,
  underMarketRateEnabled,
  setUnderMarketRateEnabled,
}: Props) => {
  const onOverMarketRateChange: InputNumberProps['onChange'] = (value) => {
    if (isNaN(value as number)) {
      return;
    }
    setOverMarketRate(value as number);
  };
  const onUnderMarketRateChange: InputNumberProps['onChange'] = (value) => {
    if (isNaN(value as number)) {
      return;
    }
    setUnderMarketRate(value as number);
  };

  return (
    <Container>
      <VerticalContainer>
        <Checkbox checked={overMarketRateEnabled} onChange={(event) => setOverMarketRateEnabled(event.target.checked)}>
          When over market rate by:
        </Checkbox>
        <Slider
          min={0}
          max={100}
          value={overMarketRate}
          onChange={onOverMarketRateChange}
          disabled={!overMarketRateEnabled}
        />
        <InputNumber
          min={0}
          max={100}
          step={1}
          value={overMarketRate}
          onChange={onOverMarketRateChange}
          disabled={!overMarketRateEnabled}
          formatter={(value) => `${value}%`}
        />
      </VerticalContainer>
      <VerticalContainer>
        <Checkbox
          checked={underMarketRateEnabled}
          onChange={(event) => setUnderMarketRateEnabled(event.target.checked)}
        >
          When under market rate by:
        </Checkbox>
        <Slider
          min={0}
          max={100}
          value={underMarketRate}
          onChange={onUnderMarketRateChange}
          disabled={!underMarketRateEnabled}
        />
        <InputNumber
          min={0}
          max={100}
          step={1}
          value={underMarketRate}
          onChange={onUnderMarketRateChange}
          disabled={!underMarketRateEnabled}
          formatter={(value) => `${value}%`}
        />
      </VerticalContainer>
    </Container>
  );
};

export default MarketRatePreferences;
