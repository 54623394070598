import React from 'react';

import { INCENTIVES_FEES_TYPE_LABELS, INCENTIVES_SAVINGS_DISCOUNT_TYPE } from '../create/constants';
import { getUnitFeePrice } from '../helpers';
import { IncentiveSavingType, IncentiveType, UnitType } from '../types';

interface Props {
  incentive: IncentiveType;
  unit: UnitType;
}

const TotalSavingsPercentage = ({ incentive, unit }: Props) => {
  const percentageSavings = incentive?.savings.filter(
    (item: IncentiveSavingType) => item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE
  );

  return (
    <span>
      {(percentageSavings ?? []).map((item: IncentiveSavingType) => {
        const initialAmount = getUnitFeePrice(unit, item?.type) as number;

        return (
          <div key={item.type}>
            <span style={{ textDecoration: 'line-through', marginRight: 4 }}>${initialAmount}</span>
            <span>${initialAmount - (initialAmount * (item?.discount ?? 100)) / 100} </span>
            <span style={{ textTransform: 'lowercase' }}>
              {item?.type ? INCENTIVES_FEES_TYPE_LABELS[item.type] : ''}
            </span>
          </div>
        );
      })}
    </span>
  );
};
export default TotalSavingsPercentage;
