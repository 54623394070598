import React from 'react';

import { G2, Gauge } from '@ant-design/plots';

const GaugeGraph = ({ percentage, community }: any) => {
  const { registerShape, Util } = G2; // 自定义 Shape 部分

  registerShape('point', 'custom-gauge-indicator', {
    draw(cfg: any, container: any) {
      // 使用 customInfo 传递参数
      const { indicator, defaultColor } = cfg.customInfo;
      const { pointer } = indicator;
      const group = container.addGroup(); // 获取极坐标系下画布中心点
      // @ts-ignore
      const center = this.parsePoint({
        x: 0,
        y: 0,
      }); // 绘制指针

      if (pointer) {
        // @ts-ignore
        const { startAngle, endAngle } = Util.getAngle(cfg, this.coordinate);
        // @ts-ignore
        const radius = this.coordinate.getRadius();
        const midAngle = (startAngle + endAngle) / 2;
        const { x: x1, y: y1 } = Util.polarToCartesian(center.x, center.y, radius / 15, midAngle + 1 / Math.PI);
        const { x: x2, y: y2 } = Util.polarToCartesian(center.x, center.y, radius / 15, midAngle - 1 / Math.PI);
        const { x, y } = Util.polarToCartesian(center.x, center.y, radius * 0.65, midAngle);
        const path = [['M', center.x, center.y], ['L', x1, y1], ['L', x, y], ['L', x2, y2], ['Z']]; // pointer

        group.addShape('path', {
          name: 'pointer',
          attrs: {
            path,
            fill: defaultColor,
            ...pointer.style,
          },
        });
      }

      return group;
    },
  });
  const config = {
    percent: percentage || 0,
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: ['#F4664A', '#FAAD14', '#30BF78'],
    },
    tickLine: {
      lineWidth: 1,
      stroke: '#ccc',
      length: 15,
    },
    indicator: {
      shape: 'custom-gauge-indicator',
      pointer: {
        style: {
          stroke: '#D0D0D0',
          lineWidth: 1,
          fill: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter(v: any) {
          return '';
        },
      },
      //   subTickLine: {
      //     count: 3,
      //   },
    },
    statistic: {
      content: {
        formatter: ({ percent }: any) => community && `ME`,
        style: {
          color: '#fff',
          fontSize: '36',
          padding: '2px',
          paddingTop: '4px',
          marginTop: '6px',
          backgroundColor: community ? '#4CD7D2' : '#fff',
          borderRadius: '4px',
        },
      },
    },
  };
  return (
    <div style={{ height: '60px', width: '150px' }}>
      <Gauge {...config} />
    </div>
  );
};

export default GaugeGraph;
