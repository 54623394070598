import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons/lib';
import styled from 'styled-components';

import { SELECTOR_ITEM_TYPES } from './constants';
import { createView, linkView } from '../../../apis/ViewAPI';
import { STATUS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { handleStoreUserViews } from '../../../store/slices/globalSlice';
import { VIEW_MODALS, VIEW_TYPE } from '../../View/constants';
import ViewInputModal from '../../View/modals/ViewInputModal';
import { useRootContext } from '../RootContext';

const Container = styled.div`
  font-size: 14px;
`;

const SidebarMenuAddView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { showMessage } = useRootContext();
  const { views, selectedCommunity, selectedCompany } = useAppSelector((state) => state.global);

  const [open, setOpen] = useState<boolean>(false);
  const [createStatus, setCreateStatus] = useState<string>(STATUS.IDLE);

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;

  const handleConfirm = (viewName: string) => {
    const handleCreateView = async () => {
      return await createView({
        name: viewName,
        type: VIEW_TYPE.PUBLIC,
      });
    };
    setCreateStatus(STATUS.LOADING);
    handleCreateView()
      .then(async (viewResponse) => {
        const viewId = viewResponse.id;
        await linkView({ company: selectedCompany?.id, view: viewId })
          .then((linkResponse) => {
            dispatch(handleStoreUserViews([...views, viewResponse]));
            showMessage('success', 'New view created.');
            setOpen(false);
            setCreateStatus(STATUS.LOADED);
            if (selectedCommunity?.type == SELECTOR_ITEM_TYPES.COMPETITOR) {
              navigate(`/competitor/${selectedCommunity?.id}/view/${viewId}`);
            } else {
              navigate(`/community/${selectedCommunity?.id}/view/${viewId}`);
            }
          })
          .catch((reason) => {
            setCreateStatus(STATUS.FAILURE);
            showMessage('error', 'Trouble creating company related view.');
          });
      })
      .catch((reason) => {
        setCreateStatus(STATUS.FAILURE);
        showMessage('error', 'Trouble creating view.');
      });
  };

  if (permissions?.indexOf('views.add_view') === -1) {
    return null;
  }

  return (
    <Container>
      <ViewInputModal
        open={open}
        type={VIEW_MODALS.ADD}
        loading={createStatus === STATUS.LOADING}
        onConfirm={handleConfirm}
        onCancel={() => setOpen(false)}
      />
      <PlusOutlined size={20} onClick={() => setOpen(true)} />
    </Container>
  );
};

export default SidebarMenuAddView;
