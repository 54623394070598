import React from 'react';

import { Rate as AntDRate } from 'antd';
import { RateProps } from 'antd/es/rate';
import styled from 'styled-components';

const StyledRate = styled(AntDRate)`
  color: var(--text-secondary);
  font-size: 10px;
  && > .ant-rate-star {
    margin-inline-end: 4px;
  }

  &.small {
    font-size: 7px;
    && > .ant-rate-star {
      margin-inline-end: 1px;
    }
  }
`;

const Rate = (props: RateProps) => {
  return <StyledRate {...props} />;
};

export default Rate;
