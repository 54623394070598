import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const Company = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 900;
  max-width: 330px;
`;

const Address = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
  line-height: 19px;
  max-width: 300px;
  word-break: break-word;
`;

const Link = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  color: var(--link-blue);
  line-height: 19px;
  max-width: 300px;
  word-break: break-word;

  a {
    color: var(--link-blue);
  }
`;

const EditShopRequestModalCommunityInfo = () => {
  const { shopRequest } = useEditShopRequestModalContext();

  return (
    <Container>
      <Company>{shopRequest?.community.name}</Company>
      <Address title={shopRequest?.community.address}>{shopRequest?.community.address}</Address>
      <Link title={shopRequest?.community.website}>
        <a href={shopRequest?.community.website} target="_blank" rel="noreferrer">
          {shopRequest?.community.website}
        </a>
      </Link>
      <Link title={shopRequest?.community.phone}>{shopRequest?.community.phone}</Link>
    </Container>
  );
};

export default EditShopRequestModalCommunityInfo;
