import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

import { MenuProps } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import { DATA_DISPLAY_TYPE } from './constants';
import { useWidgetContext } from './WidgetContext';
import WidgetEditModal from './WidgetEditModal';
import WidgetRemoveModal from './WidgetRemoveModal';
import { PDF_IMAGE_OFFSET } from '../../../components/Dashboard/constants';
import Dropdown from '../../../components/lib/Dropdown';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import { useAppSelector } from '../../../store/hook';
import { exportElementAsImage, exportElementAsPDF } from '../../../utils/helpers/export';
import { CUSTOM_WIDGET_TYPES, WIDGET_TYPES } from '../constants';
import { getFilename } from '../GraphWidget/utils';

const Container = styled.div`
  position: relative
  width: 20px;
  height: 20px;
`;

const ActionIcon = styled.div`
  background-image: url('/icons/elipsis-small.svg');
  background-size: cover;
  width: 14px;
  height: 14px;
  margin: auto;
  :hover {
    background-image: url('/icons/elipsis-small-blue.svg');
  }
`;

const IconWrapper = styled.div`
  &.map {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 2.9px 3.1px 3.1px 2.9px;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    background-image: linear-gradient(144deg, rgba(255, 255, 255, 0.4) 21%, rgba(255, 255, 255, 0.24) 78%);
  }
`;

const WidgetActions = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { view, preview } = useCommunityViewContext();
  const {
    type,
    name,
    elementRef,
    displayType,
    csvData,
    showEditWidgetModal,
    showRemoveWidgetModal,
    setShowEditWidgetModal,
    setShowRemoveWidgetModal,
  } = useWidgetContext();

  const [filename, setFilename] = useState<string>('');

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;

  useEffect(() => {
    setFilename(getFilename(selectedCommunity?.name || 'Community', name));
  }, [selectedCommunity, name]);

  const showDownloadOptions = type !== WIDGET_TYPES.PHOTOS && type != WIDGET_TYPES.MAP;
  const showManageOptions = !preview && view && !view?.default;
  const isCustomVidget = CUSTOM_WIDGET_TYPES.includes(type);
  const isMapWidget = type == WIDGET_TYPES.MAP;

  const items: MenuProps['items'] = [
    showDownloadOptions && !isCustomVidget && displayType === DATA_DISPLAY_TYPE.GRAPH
      ? {
          label: 'Download as PDF',
          key: 'downloadAsPDF',
          onClick: () => exportElementAsPDF(elementRef, filename, { padding: PDF_IMAGE_OFFSET }),
        }
      : null,
    showDownloadOptions && !isCustomVidget && displayType === DATA_DISPLAY_TYPE.GRAPH
      ? {
          label: 'Download as Image',
          key: 'downloadAsImage',
          onClick: () => {
            exportElementAsImage(elementRef, filename);
          },
        }
      : null,
    showDownloadOptions && (displayType === DATA_DISPLAY_TYPE.TABLE || isCustomVidget)
      ? {
          label: (
            <CSVLink filename={`${filename}.csv`} data={csvData}>
              Download as CSV
            </CSVLink>
          ),
          key: 'downloadAsCSV',
        }
      : null,
    showManageOptions && !isMapWidget && permissions?.indexOf('views.change_widget') !== -1
      ? {
          label: 'Edit',
          key: 'edit',
          onClick: () => setShowEditWidgetModal(true),
        }
      : null,
    showManageOptions && permissions?.indexOf('views.delete_widget') !== -1
      ? {
          label: 'Remove',
          key: 'remove',
          onClick: () => setShowRemoveWidgetModal(true),
        }
      : null,
  ].filter(Boolean);

  return (
    <div>
      <>
        {showRemoveWidgetModal && <WidgetRemoveModal />}
        {showEditWidgetModal && <WidgetEditModal />}
      </>

      {items?.length > 0 && (
        <Container>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
            className="widget-actions"
          >
            <IconWrapper
              className={classNames({
                map: type === WIDGET_TYPES.MAP,
              })}
            >
              <ActionIcon />
            </IconWrapper>
          </Dropdown>
        </Container>
      )}
    </div>
  );
};

export default WidgetActions;
