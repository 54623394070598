import { Md5 } from 'ts-md5';

export const filterUndefinedParams = (object: any) => {
  Object.keys(object).forEach((key) => {
    if (object[key] === undefined) {
      delete object[key];
    }
  });
  return object;
};

export const getChecksum = (data: any) => {
  const secretParts = ['6vC24bTu4L', 'la05L2pS3V', '0PGsx1waa', '9QMt4', 'R2O', 'R8JD4', 'PSCXSzK9', 'zWkJ'];

  const prepareSecret = () => {
    const secretPartsCopy = [...secretParts];
    const partsToReverse = [1, 2, 5, 7];
    partsToReverse.forEach((i) => (secretPartsCopy[i] = secretPartsCopy[i].split('').reverse().join('')));
    return secretPartsCopy.join('');
  };

  const dataStr = (typeof data === 'string' ? data : JSON.stringify(data)) || '';

  return Md5.hashStr(dataStr + prepareSecret());
};
