import React from 'react';

import styled from 'styled-components';

import IncentivesCreateActions from './IncentivesCreateActions';
import IncentivesCreateOffersInfo from './IncentivesCreateOffersInfo';
import IncentivesCreateOffersSavings from './IncentivesCreateOffersSavings';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 328px;
  max-width: 328px;
  border-left: 1px solid var(--line-gray);
  background-color: var(--background-gray-light);
`;

const Segment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 20px;
  gap: 10px;
  border-bottom: 1px solid var(--line-gray);
`;

const ExpandableSegment = styled(Segment)`
  height: 100%;
  overflow-y: auto;
`;

const IncentivesCreateOffers = () => (
  <Container>
    <Segment>
      <IncentivesCreateOffersInfo />
    </Segment>
    <ExpandableSegment>
      <IncentivesCreateOffersSavings />
    </ExpandableSegment>
    <IncentivesCreateActions />
  </Container>
);

export default IncentivesCreateOffers;
