import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import {
  EXPORTABLE_HEADER_CLASS,
  EXPORTABLE_PAGE_CLASS,
  FILE_EXTENSION,
  PDF_PAGE_HEIGHT,
  PDF_PAGE_PADDING,
  PDF_PAGE_WIDTH,
} from './constants';
import { EXPORT_TIMEOUT, SCREEN_HEIGHT, SCREEN_WIDTH } from '../../components/Dashboard/constants';

export const exportElementToPDF = async (filename: string, element: HTMLElement | null) => {
  // Check for the presence of the HTML Element
  if (!element) {
    throw new Error('Export to PDF failed due to missing HTML Element!');
  }

  // A4 size page of PDF with 300 DPI
  const pdf = new jsPDF({
    unit: 'mm',
    format: 'a4',
    userUnit: 300,
    compress: true,
  });

  const headers = Array.from(document.getElementsByClassName(EXPORTABLE_HEADER_CLASS));
  const pagesContent = Array.from(document.querySelectorAll(`.${EXPORTABLE_PAGE_CLASS} > div`));

  if (headers.length > 1) {
    console.warn('Multiple headers detected. Using first in order.');
  }

  const pagesHeader = headers.length ? headers[0] : null;

  //@ts-ignore
  const headerImage = await html2canvas(pagesHeader, {
    imageTimeout: EXPORT_TIMEOUT,
    windowWidth: SCREEN_WIDTH,
    windowHeight: SCREEN_HEIGHT,
  });
  //@ts-ignore
  const pagesImages = await Promise.all(
    //@ts-ignore
    pagesContent.map((page) =>
      //@ts-ignore
      html2canvas(page, { imageTimeout: EXPORT_TIMEOUT })
    )
  );

  const headerImageHeight = (headerImage.height / headerImage.width) * PDF_PAGE_WIDTH;
  const headerContentDataURL = headerImage.toDataURL('image/png');
  pdf.addImage(headerContentDataURL, 'PNG', PDF_PAGE_PADDING, PDF_PAGE_PADDING, PDF_PAGE_WIDTH, headerImageHeight);

  let pageHeight = headerImageHeight;
  pagesImages.forEach((pageImage, index) => {
    const pageImageHeight = (pageImage.height / pageImage.width) * PDF_PAGE_WIDTH;
    const pageContentDataURL = pageImage.toDataURL('image/png');

    if (pageHeight + (pageImageHeight + headerImageHeight) > PDF_PAGE_HEIGHT) {
      pdf.addPage();
      pdf.addImage(headerContentDataURL, 'PNG', PDF_PAGE_PADDING, PDF_PAGE_PADDING, PDF_PAGE_WIDTH, headerImageHeight);
      pageHeight = headerImageHeight;
    }

    pdf.addImage(pageContentDataURL, 'PNG', PDF_PAGE_PADDING, pageHeight, PDF_PAGE_WIDTH, pageImageHeight);

    pageHeight += pageImageHeight;
  });

  const currentDate = new Date().toLocaleDateString();
  const fullFilename = `${filename}_${currentDate}.${FILE_EXTENSION.PDF}`;

  pdf.save(fullFilename);
};
