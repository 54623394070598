import React, { useEffect, useState } from 'react';

import { CloseCircleFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Image, message, Popconfirm, Space, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import shortUUID from 'short-uuid';

import axios from 'config/axiosPrivate';
import AddShopNoteModal from 'modals/AddShopNoteModal';
import { useAppSelector } from 'store/hook';

interface DataType {
  sr_num?: number;
  id: number;
  key: string;
  company_id?: number;
  user_id?: number;
  apartment_id: number;
  request_date: string;
  request_notes: string;
  attachment: File | null;
  file_url: string | undefined;
  based_on: 'upload' | 'url' | null;
  file_mime_type: string;
  status: 1 | 0;
  editMode: boolean;
  op: 'added' | 'edited' | 'deleted' | null;
}

interface Props {
  community_id: number;
}

const ShopNotes = ({ community_id }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [messageApi, contextHolder] = message.useMessage();

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const [selectedData, setselectedData] = useState<DataType | null>(null);

  const [openShopNote, setOpenShopNote] = useState(false);

  useEffect(() => {
    if (community_id) {
      getShopNotes();
    }
  }, [community_id]);

  const getShopNotes = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_CommunityShopNotes/${community_id}/`)
      .then((res) => {
        if (res.data?.payload && res.data?.payload?.length > 0) {
          setBaseUrl(res?.data?.URL);
          const data = res.data.payload?.map((el: any, idx: number) => ({
            sr_num: idx + 1,
            id: el?.id,
            key: shortUUID.generate(),
            company_id: el?.company,
            user_id: el?.user,
            apartment_id: el?.apartment,
            request_date: el?.request_date ? format(new Date(el?.request_date), 'MM-dd-yyyy') : null,
            request_notes: el?.request_notes,
            attachment: el.attachment,
            file_url: el?.file_url,
            based_on: el?.based_on,
            file_mime_type: el?.file_mime_type,
            status: el?.status,
            editMode: false,
            op: null,
          }));
          setDataSource(data);
        } else {
          setDataSource([]);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const handleDeleteData = (values: any) => {
    const formData = new FormData();
    formData.append('id', values?.id);
    formData.append('company_id', `${currentUser?.company}`);
    formData.append('user_id', `${currentUser?.user_id}`);
    formData.append('apartment_id', `${community_id}`);
    formData.append('request_date', `${format(new Date(), 'yyyy-MM-dd hh:mm:ss')}`);
    formData.append('request_notes', values?.request_notes || '');
    formData.append('file_url', values?.file_url || '');
    formData.append('attachment', values?.file || '');
    formData.append('based_on', values?.file_url ? 'url' : 'upload');
    formData.append('file_mime_type', values?.file ? values?.file?.type : 'Text');
    formData.append('attachment_mime_type', values?.file ? values?.file?.type : 'Text');
    formData.append('file_type', values?.file ? values?.file?.type : 'Text');
    formData.append('type', values?.file ? values?.file?.type : 'Text');
    formData.append('status', '1');
    formData.append('op', 'deleted');
    axios
      .post('communities/Save_ShopNotes/', formData)
      .then((res) => {
        showMessage('success', 'Deleted successfully!');
        getShopNotes();
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong');
      });
  };

  const saveFormData = (values: any) => {
    const formData = new FormData();
    formData.append('id', selectedData ? `${selectedData?.id}` : '1');
    formData.append('company_id', `${currentUser?.company}`);
    formData.append('user_id', `${currentUser?.user_id}`);
    formData.append('apartment_id', `${community_id}`);
    formData.append('request_date', `${format(new Date(values?.request_date), 'yyyy-MM-dd hh:mm:ss')}`);
    formData.append('request_notes', values?.request_notes || '');
    formData.append('file_url', values?.file_url || '');
    formData.append('attachment', values?.file || '');
    formData.append('based_on', values?.file_url ? 'url' : 'upload');
    formData.append('file_mime_type', values?.file ? values?.file?.type : 'Text');
    formData.append('attachment_mime_type', values?.file ? values?.file?.type : 'Text');
    formData.append('file_type', values?.file ? values?.file?.type : 'Text');
    formData.append('type', values?.file ? values?.file?.type : 'Text');
    formData.append('status', '1');
    formData.append('op', selectedData ? 'edited' : 'added');
    setLoading(true);
    axios
      .post('communities/Save_ShopNotes/', formData)
      .then((res) => {
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        getShopNotes();
        setOpenShopNote(false);
        setselectedData(null);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  const isImage = (url: string) => {
    if (!url) return false;
    const arr = url?.split('.');
    const list = ['png', 'jpeg', 'jpg'];
    const is_img = list.indexOf(arr[arr.length - 1]) !== -1;
    if (is_img) return true;
    return false;
  };

  const getAttachmentName = (url: string) => {
    if (!url) return '';
    const arr = url?.split('/');
    const name = arr[arr.length - 1];
    return name ? name : '';
  };
  const columns: ColumnsType<DataType> = [
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          Sr. No.
        </Typography.Title>
      ),
      dataIndex: 'sr_num',
      key: 'sr_num',
      align: 'center',
      width: 80,
      render(value, record: any, index) {
        return <span style={{ fontSize: 20 }}>{value}</span>;
      },
    },
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          Requested Date
        </Typography.Title>
      ),
      dataIndex: 'request_date',
      key: 'request_date',
      align: 'center',
      width: 180,
      render(value, record: any, index) {
        return <span style={{ fontSize: 20 }}>{value}</span>;
      },
    },
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          File Url
        </Typography.Title>
      ),
      dataIndex: 'file_url',
      key: 'file_url',
      width: 250,
      align: 'center',
      render(value, record: any, index) {
        if (value) {
          if (isImage(value)) {
            return (
              <Image width={'200px'} height={150} style={{ objectFit: 'cover' }} src={value} fallback="/no-image.png" />
            );
          } else {
            return (
              <a href={`${value}`} target="_blank" rel="noreferrer">
                <svg width="60px" height="60px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#494c4e"
                    d="M10 7H8c-.553 0-1-.448-1-1s.447-1 1-1h2c.553 0 1 .448 1 1s-.447 1-1 1zM15 11H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1zM15 15H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z"
                  />
                  <path
                    fill="#494c4e"
                    d="M20.71 4.29l-4-4C16.52.1 16.26 0 16 0H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-.26-.1-.52-.29-.71zM16 2.42L18.58 5H16.5c-.28 0-.5-.22-.5-.5V2.42zm3 19.08c0 .28-.22.5-.5.5h-13c-.28 0-.5-.22-.5-.5v-19c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5V5c0 1.1.9 2 2 2h2.5c.28 0 .5.22.5.5v14z"
                  />
                  <path fill="#494c4e" d="M15 19H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z" />
                </svg>
              </a>
            );
          }
        } else {
          return null;
        }
      },
    },
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          Attachment
        </Typography.Title>
      ),

      dataIndex: 'attachment',
      key: 'attachment',
      align: 'center',
      width: 250,
      render(value, record: any, index) {
        if (value) {
          if (isImage(value)) {
            return (
              <>
                <Image width={'200px'} height={150} style={{ objectFit: 'cover' }} src={`${baseUrl}${value}`} />
                <p>{getAttachmentName(record.attachment)}</p>
              </>
            );
          } else {
            return (
              <a href={`${baseUrl}${value}`} target="_blank" rel="noreferrer">
                <svg width="60px" height="60px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#494c4e"
                    d="M10 7H8c-.553 0-1-.448-1-1s.447-1 1-1h2c.553 0 1 .448 1 1s-.447 1-1 1zM15 11H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1zM15 15H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z"
                  />
                  <path
                    fill="#494c4e"
                    d="M20.71 4.29l-4-4C16.52.1 16.26 0 16 0H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-.26-.1-.52-.29-.71zM16 2.42L18.58 5H16.5c-.28 0-.5-.22-.5-.5V2.42zm3 19.08c0 .28-.22.5-.5.5h-13c-.28 0-.5-.22-.5-.5v-19c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5V5c0 1.1.9 2 2 2h2.5c.28 0 .5.22.5.5v14z"
                  />
                  <path fill="#494c4e" d="M15 19H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z" />
                </svg>
              </a>
            );
          }
        } else {
          return null;
        }
      },
    },
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          Notes
        </Typography.Title>
      ),
      dataIndex: 'request_notes',
      key: 'request_notes',
      width: 350,
      render(value, record: any, index) {
        return (
          <Typography.Paragraph
            style={{ fontSize: 20, whiteSpace: 'break-spaces' }}
            ellipsis={{ rows: 6, expandable: true, symbol: 'more' }}
          >
            {value}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      render: (_, record: any) => (
        <Space size="small" className="actions">
          <Button
            icon={<EditFilled />}
            size="small"
            type="default"
            onClick={() => {
              setOpenShopNote(true);
              setselectedData(record);
            }}
          />
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => handleDeleteData(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<CloseCircleFilled />} size="small" type="default" danger style={{ margin: '10px 0px' }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const tableData = dataSource.filter((el) => el?.op !== 'deleted');

  return (
    <div style={{ padding: '0px 20px 50px 20px' }}>
      {contextHolder}
      {openShopNote && (
        <AddShopNoteModal
          open={openShopNote}
          title={selectedData ? 'Update Shop Note' : 'Add Shop Note'}
          handleClose={() => {
            setOpenShopNote(false);
            setselectedData(null);
          }}
          handleAddNote={saveFormData}
          loading={loading}
          initialValues={selectedData}
        />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          style={{ marginBottom: '20px' }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setOpenShopNote(true)}
        />
      </div>
      <Table
        bordered
        dataSource={tableData}
        columns={columns}
        pagination={false}
        scroll={{ x: 'scroll', y: '60vh' }}
        loading={dataLoading}
      />
    </div>
  );
};

export default ShopNotes;
