import React, { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';

import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';

import classes from './styles.module.scss';

interface DataType {
  Id: number;
  parent_id: number;
  longitude: string;
  latitude: string;
  apartmenttype: string;
  apartmentname: number | null;
  apartmentaddress: number | null;
  Avg_base_rent: number | null;
  Avg_sq_footage: number | null;
  Avg_price_per_sq: number;
  Avg_google_rating: number;
  Sum_google_reviews: number;
  market_Avg_sq_footage: number;
  market_Avg_base_rent: number;
  market_Avg_price_per_sq: number;
  market_Avg_google_rating: string;
  market_Sum_google_reviews: string;
}

type DataIndex = keyof DataType;

interface Props {
  data: any[];
  loading: boolean;
  title: string;
}

const CommunityTable = ({ data, loading, title }: Props) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <span className={classes.header_title}>{title}</span>,
      dataIndex: 'id',
      width: 400,
      key: 'id',
      ...getColumnSearchProps('apartmentname'),
      render: (value, record: any) => {
        return (
          <div style={{ width: 400 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '0px 15px',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                    border: '2px solid #389e0d',
                    borderRadius: '100%',
                    fontSize: 24,
                    color: '#389e0d',
                    background: 'rgb(203 255 182 / 44%)',
                  }}
                >
                  <span style={{ marginTop: 3, fontSize: '14px' }}>
                    {/* @ts-ignore */}
                    {record?.sr_num}
                  </span>
                </div>
              </div>
              <div
                style={{
                  textAlign: 'left',
                  marginLeft: 10,
                  flex: 1,
                }}
              >
                <Link
                  to={
                    record?.apartmenttype === 'competitor'
                      ? `/view-apartments?id=${record.parent_id}&c_id=${record?.id}`
                      : `/view-apartments?id=${record.id}`
                  }
                >
                  <span
                    style={{
                      fontSize: '18px',
                      color: '#1890ff',
                      fontWeight: 'normal',
                    }}
                  >
                    {record?.community?.apartmentname}
                  </span>
                </Link>
                <Typography.Paragraph style={{ margin: '0px' }}>
                  {record?.community?.apartmentaddress}
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className={classes.header_title}>AVG. RATE</span>,
      dataIndex: 'Avg_base_rent',
      key: 'Avg_base_rent',
      sorter: (a: any, b: any) => a?.Avg_base_rent > b?.Avg_base_rent,
      sortDirections: ['descend'],
      width: 200,
      render: (value, record: any) => {
        return (
          <div>
            <span
              className="c_avg_market"
              style={{
                color: `${value < record.market_Avg_base_rent ? '#1ad392' : '#fa7550'}`,
                fontSize: 18,
              }}
            >
              $ {value?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
            </span>
            {record?.apartmenttype === 'apartment' && (
              <>
                <br />
                <span className="c_avg_market" style={{ color: '#333' }}>
                  Market $
                  {' ' +
                    parseFloat(`${record?.market_Avg_base_rent}`).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: <span className={classes.header_title}>AVG. SQ. FT.</span>,
      dataIndex: 'Avg_sq_footage',
      key: 'Avg_sq_footage',
      sorter: (a: any, b: any) => a.Avg_sq_footage > b.Avg_sq_footage,
      sortDirections: ['descend'],
      width: 200,
      render: (value, record: any) => (
        <div>
          <span
            className="c_avg_market"
            style={{
              color: `${value < record.market_Avg_sq_footage ? '#1ad392' : '#fa7550'}`,
            }}
          >
            {value?.toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}
          </span>
          {record?.apartmenttype === 'apartment' && (
            <>
              <br />
              <span className="c_avg_market" style={{ color: '#333' }}>
                Market{' '}
                {parseFloat(`${record?.market_Avg_sq_footage}`).toLocaleString('en-US', { maximumFractionDigits: 2 })}
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      title: <span className={classes.header_title}>AVG. RATE / SQ. FT.</span>,
      dataIndex: 'Avg_price_per_sq',
      key: 'Avg_price_per_sq',
      sorter: (a: any, b: any) => a.Avg_price_per_sq > b.Avg_price_per_sq,
      sortDirections: ['descend'],
      width: 200,
      render: (value, record: any) => (
        <div>
          <span
            className="c_avg_market"
            style={{
              color: `${value < record?.market_Avg_price_per_sq ? '#1ad392' : '#fa7550'}`,
            }}
          >
            $ {value?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
          </span>
          {record?.apartmenttype === 'apartment' && (
            <>
              <br />
              <span className="c_avg_market" style={{ color: '#333' }}>
                <span>Market</span> ${' '}
                {parseFloat(`${record?.market_Avg_price_per_sq || 0}`).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      title: <span className={classes.header_title}>AVG. GOOGLE RATING</span>,
      dataIndex: 'Avg_google_rating',
      key: 'Avg_google_rating',
      sorter: (a: any, b: any) => a.Avg_google_rating > b.Avg_google_rating,
      sortDirections: ['descend'],
      width: 200,
      render: (value, record: any) => {
        return (
          <div>
            <p>
              <span
                className="c_avg_market"
                style={{
                  color: `${record?.google_rating > record?.market_Avg_google_rating ? '#1ad392' : '#fa7550'}`,
                }}
              >
                {(record?.google_rating || 0)?.toLocaleString('en-US', {
                  maximumFractionDigits: 1,
                })}
              </span>{' '}
              <span className="c_avg_market" style={{ color: '#333' }}>
                ({parseFloat(`${record?.google_reviews || 0}`).toLocaleString('en-US', { maximumFractionDigits: 2 })})
              </span>
            </p>
            {record?.apartmenttype === 'apartment' ? (
              <span className="c_avg_market" style={{ color: '#333' }}>
                Competitors:{' '}
                {record.cmp_google_rating && Object.keys(record.cmp_google_rating).length > 0
                  ? parseFloat(`${record.cmp_google_rating?.avg_google_rating || 0}`).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })
                  : 0}{' '}
                (
                {record.cmp_google_reviews
                  ? parseFloat(`${record?.cmp_google_reviews?.avg_google_reviews || 0}`).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })
                  : 0}
                )
              </span>
            ) : (
              <span className="c_avg_market" style={{ color: '#333' }}>
                Competitors:{' '}
                {parseFloat(`${record?.market_Avg_google_rating || 0}`).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                (
                {parseFloat(`${record?.market_Sum_google_reviews}`).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
                )
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const expandedRowRender = (record: any) => {
    const expandTableColumns = columns.slice();
    const column = {
      title: <span className={classes.header_title}>COMPETITORS</span>,
      dataIndex: 'id',
      width: 400,
      key: 'id',
      ...getColumnSearchProps('apartmentname'),
      render: (value: any, record: any) => {
        return (
          <div style={{ width: 400 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: '0px 15px',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 40,
                    height: 40,
                    border: '2px solid #fa7550',
                    borderRadius: '100%',
                    fontSize: 24,
                    color: '#fa7550',
                    background: 'rgb(250, 117, 80, 0.24)',
                  }}
                >
                  <span style={{ marginTop: 3, fontSize: '14px' }}>
                    {/* @ts-ignore */}
                    {record?.sr_num}
                  </span>
                </div>
              </div>
              <div
                style={{
                  textAlign: 'left',
                  marginLeft: 10,
                  flex: 1,
                }}
              >
                <Link
                  to={
                    record?.apartmenttype === 'competitor'
                      ? `/view-apartments?id=${record.parent_id}&c_id=${record?.id}`
                      : `/view-apartments?id=${record.id}`
                  }
                >
                  <span
                    style={{
                      fontSize: '18px',
                      color: '#1890ff',
                      fontWeight: 'normal',
                    }}
                  >
                    {record?.community?.apartmentname}
                  </span>
                </Link>
                <Typography.Paragraph style={{ margin: '0px' }}>
                  {record?.community?.apartmentaddress}
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        );
      },
    };
    expandTableColumns[0] = column;

    const data: any[] = record.competitors;
    if (data) {
      return (
        <Table
          columns={expandTableColumns}
          dataSource={data.map((ele: any, idx: number) => ({
            ...ele,
            sr_num: idx + 1,
          }))}
          pagination={false}
          size="small"
          className={classes.home_community_table}
          style={{}}
          bordered={false}
          rowKey="sr_num"
        />
      );
    } else {
      return false;
    }
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data.map((ele: any, idx: number) => ({
          ...ele,
          sr_num: idx + 1,
        }))}
        scroll={{ x: 991 }}
        size="small"
        bordered={false}
        loading={loading}
        className={classes.home_community_table}
        pagination={false}
        rowKey="sr_num"
        expandable={{
          expandedRowRender,
          columnWidth: 0,
          expandedRowClassName: () => classes.expand_row,
          expandIcon: ({ expanded, onExpand, record }) => (
            <>
              {title != 'COMPETITOR' && record.competitors?.length > 0 ? (
                <div
                  style={{
                    position: 'absolute',
                    top: '40%',
                    zIndex: '999',
                    height: 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {expanded ? (
                    <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                  )}
                </div>
              ) : null}
            </>
          ),
          rowExpandable: (record) => record.competitors,
        }}
      />
    </div>
  );
};

export default CommunityTable;
