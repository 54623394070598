import React from 'react';

import { Divider } from 'antd';
import styled from 'styled-components';

import SelectorClient from './SelectorClient';
import SelectorCompany from './SelectorCompany';
import SelectorManagementCompany from './SelectorManagementCompany';

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-top: none;
  padding: 20px;
`;

const SelectorContent = () => {
  return (
    <Container>
      <SelectorClient />
      <Divider style={{ margin: 0 }}>Or</Divider>
      <SelectorCompany />
      <Divider />
      <SelectorManagementCompany />
    </Container>
  );
};

export default SelectorContent;
