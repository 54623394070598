import React, { createContext, useContext } from 'react';

import { SHOP_REQUEST_DUE_DATE_RANGES, SHOP_REQUESTS_PAGE_SIZE } from './constants';
import { ShopRequestsFiltersType } from './types';

type ShopRequestsDataContextType = {
  filters: Partial<ShopRequestsFiltersType>;
  ordering?: string;
  setOrdering: (value: string) => void;
  setSearch: (s: string) => void;
  setPage: (page: number) => void;
  setFilters: (f: any) => void;
};

export const ShopRequestsDataContext = createContext<ShopRequestsDataContextType>({
  filters: {
    page: 1,
    pageSize: SHOP_REQUESTS_PAGE_SIZE,
    search: '',
    status: [],
    requestFrom: undefined,
    shoppers: [],
    companyIds: [],
    dueDate: undefined,
    dueDateRange: SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS,
    groupBy: undefined,
    orderBy: undefined,
  },
  ordering: undefined,
  setOrdering: () => {
    // empty
  },
  setFilters: () => {
    // empty
  },
  setSearch: () => {
    // empty
  },
  setPage: () => {
    // empty
  },
});

export const useShopRequestsDataContext = () => useContext(ShopRequestsDataContext);
