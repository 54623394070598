import React from 'react';

import Header from 'common/Header';

import SettingsBreadcrumbs from './SettingsBreadcrumbs';
import { useSettingsContext } from './SettingsContext';

const SettingsHeader = () => {
  const { headerContent } = useSettingsContext();
  const breadcrumbs = <SettingsBreadcrumbs showAllSettings={!headerContent.title && !headerContent.description} />;
  return (
    <Header
      title={headerContent.title || 'Settings'}
      description={headerContent.description}
      breadcrumbs={breadcrumbs}
      iconSrc={headerContent.iconSrc || '/icons/settings.svg'}
      iconBackgroundColor={headerContent.iconBackgroundColor || '#e8ecf2'}
    />
  );
};

export default SettingsHeader;
