import React from 'react';

import { List } from 'antd';
import styled from 'styled-components';

import CommunitiesListHeader from './CommunitiesListHeader';
import CommunityListItem from './CommunityListItem';
import Pagination from './Pagination';
import { useSettingsContext } from './SettingsContext';
import SettingsToolbar from './SettingsToolbar';

const Container = styled.div`
  padding: 20px 30px;
`;

const StyledList = styled(List)`
  && .ant-list-item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  && .ant-list-item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

type Props = {
  filterCommunitiesByStatus: (data: any[], status: number) => any[];
  selectPopupWidth?: number;
  toggleMultipleSelection: () => void;
  modifyCommunity: (c: any) => void;
  setPage: (i: number) => void;
  setSearchTerm: (i: string) => void;
  setStatus: (i: number) => void;
};

const CommunitiesList = ({
  filterCommunitiesByStatus,
  selectPopupWidth,
  toggleMultipleSelection,
  modifyCommunity,
  setPage,
  setSearchTerm,
  setStatus,
}: Props) => {
  const { status, communities, selectedCommunitiesIds } = useSettingsContext();

  return (
    <Container>
      <SettingsToolbar
        toggleMultipleSelection={toggleMultipleSelection}
        setSearchTerm={setSearchTerm}
        setStatus={setStatus}
        selectPopupWidth={selectPopupWidth}
      />
      <div>
        <CommunitiesListHeader />
        <div>
          <StyledList
            bordered
            dataSource={filterCommunitiesByStatus(communities, status)}
            renderItem={(item: any) => (
              <CommunityListItem
                key={item.id}
                item={item}
                isSelected={selectedCommunitiesIds.some((el: any) => el === item.id)}
                onClick={modifyCommunity}
              />
            )}
          />
          <Pagination setPage={(page: number) => setPage(page)} />
        </div>
      </div>
    </Container>
  );
};

export default CommunitiesList;
