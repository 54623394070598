import React from 'react';

import styled from 'styled-components';

import { useSwotCompetitorAnalysisContext } from './SwotCompetitorAnalysisContext';
import Radio from '../../../lib/Radio';

const Container = styled.div`
  height: 230px;
  padding-right: 10px;
  overflow-y: scroll;
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 14px;
  color: var(--text-primary);
`;

const SwotCompetitorAnalysisActionOptionsList = () => {
  const { selectedCompetitor, communityOptions, setSelectedCompetitor } = useSwotCompetitorAnalysisContext();

  return (
    <Container className="mini-scroll">
      {communityOptions.map((community: any) => (
        <OptionContainer key={community.id}>
          <Radio checked={selectedCompetitor == community.id} onClick={() => setSelectedCompetitor(community.id)} />
          <Title>{community.apartmentname}</Title>
        </OptionContainer>
      ))}
    </Container>
  );
};

export default SwotCompetitorAnalysisActionOptionsList;
