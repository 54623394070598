import React from 'react';

import { Badge, Image } from 'antd';
import styled from 'styled-components';

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  .ant-image {
    position: absolute;
  }
`;

interface Props {
  images: string[];
}

const PhotosThumbnail = ({ images }: Props) => {
  const firstImageUrl = images && images[0];
  const secondImageUrl = images && images[1];

  return (
    <ImagesContainer>
      <ImageWrapper>
        <Image
          width={40}
          height={40}
          style={{
            objectFit: 'cover',
            borderRadius: '4px',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          src={firstImageUrl}
          fallback="/no-image.png"
          preview={false}
        />

        {secondImageUrl && (
          <Image
            width={40}
            height={40}
            style={{
              borderRadius: '4px',
              position: 'absolute',
              top: 2,
              left: 10,
              transform: 'rotate(10deg)',
            }}
            src={secondImageUrl}
            preview={false}
          />
        )}
      </ImageWrapper>
      {secondImageUrl && (
        <Badge
          count={'+' + images.length}
          color="#fff"
          style={{
            padding: '2px 7px 0',
            borderRadius: '10px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            position: 'absolute',
            top: 20,
            right: -28,
            zIndex: 2,
            color: '#000',
          }}
        />
      )}
    </ImagesContainer>
  );
};

export default PhotosThumbnail;
