import React from 'react';

import { Rate, Typography } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import styled from 'styled-components';

import { REVIEW_SOURCE } from 'components/GoogleMetricsReviews/reviews/constants';

const { Paragraph } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10px;
  gap: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderInfoRating = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const HeaderDateCategory = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--gray-dark);
  gap: 5px;
  align-items: end;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--gray-dark);
  font-size: 14px;
`;

const DateInfo = styled.span`
  font-size: 10px;
`;

const Category = styled.span`
  font-size: 12px;
`;

const CategoryLabel = styled.span`
  font-size: 12px;

  &.positive {
    color: var(--positive-green);
  }

  &.negative {
    color: var(--negative-orange);
  }

  &:last-child {
    span {
      display: none;
    }
  }
`;

const Info = styled.span`
  font-size: 14px;
`;

const Name = styled.span`
  font-size: 14px;
  font-family: var(--font-bold);
  color: var(--text-primary);
`;

const Source = styled.span`
  font-size: 12px;
  color: var(--text-secondary);
  margin-left: 4px;
`;

const Rating = styled.span`
  font-size: 10px;
`;

interface Props {
  item: any;
}

const ReviewItem = ({ item }: Props) => (
  <Container>
    <HeaderContainer>
      <HeaderInfoRating>
        <Info>
          <Name>{item?.name.length > 0 ? item.name : 'anonymous'}</Name>
          <Source>{REVIEW_SOURCE[item.type]}</Source>
        </Info>
        <Rating>
          <Rate
            allowHalf
            value={item.rating}
            style={{ fontSize: '10px', color: 'var(--active-blue)', marginRight: '4px' }}
            disabled
          />
          <span>{item?.rating?.toFixed(1)}</span>
        </Rating>
      </HeaderInfoRating>
      <HeaderDateCategory>
        <Category>
          {item.categories.map((category: any) => (
            <CategoryLabel
              key={category.id}
              className={classNames({ positive: category.score > 0.5, negative: category.score < -0.2 })}
            >
              {category.label}
              <span>, </span>
            </CategoryLabel>
          ))}
        </Category>
        <DateInfo>{format(new Date(item?.date ?? ''), 'MMM, dd yyyy')}</DateInfo>
      </HeaderDateCategory>
    </HeaderContainer>
    <ContentContainer>
      <Paragraph
        style={{
          whiteSpace: 'break-spaces',
          fontSize: '14px',
          marginBottom: 0,
          color: 'var(--gray-dark)',
        }}
        ellipsis={{ rows: 6, expandable: true, symbol: 'more' }}
      >
        {item.text}
      </Paragraph>
    </ContentContainer>
  </Container>
);

export default ReviewItem;
