import React, { createContext, useContext } from 'react';

import { User } from './types';
import { STATUS } from '../../../constants';

type ShareViaEmailContent = {
  loading: string;
  note: string;
  views: number[];
  emails: string[];
  users: User[];
  selectedUsers: User[];
  setNote: (value: string) => void;
  setEmails: (value: string[]) => void;
  handleClose: () => void;
  handleSend: () => void;
  handleSelect: (value: number) => void;
  handleDeselect: (value: number) => void;
  handleUserSearch: (event: any) => void;
};

export const ShareViaEmailContext = createContext<ShareViaEmailContent>({
  loading: STATUS.IDLE,
  note: '',
  views: [],
  emails: [],
  users: [],
  selectedUsers: [],
  setNote: () => {
    // Do Nothing
  },
  setEmails: () => {
    // Do Nothing
  },
  handleClose: () => {
    // Do Nothing
  },
  handleSend: () => {
    // Do Nothing
  },
  handleSelect: () => {
    // Do Nothing
  },
  handleDeselect: () => {
    // Do Nothing
  },
  handleUserSearch: () => {
    // Do Nothing
  },
});

export const useShareViaEmailContext = () => useContext(ShareViaEmailContext);
