import React from 'react';

import SavingsList from '../../common/SavingsList';
import TotalSavings from '../../common/TotalSavings';
import { useIncentivesDataContext } from '../../IncentivesDataContext';
import { IncentiveType, UnitType } from '../../types';

interface Props {
  incentive: IncentiveType;
  unit: UnitType;
  hideIcon?: boolean;
}

const IncentivesTableUnitsIncentive = ({ incentive, unit, hideIcon = false }: Props) => {
  const { selectionMode, archiveMode } = useIncentivesDataContext();

  return (
    <>
      {incentive ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {(!selectionMode && !hideIcon) && (
            <span
              style={{
                paddingTop: 4,
                paddingRight: 20,
              }}
            >
              <img
                src={archiveMode ? '/icons/price-tag-gray.svg' : '/icons/price-tag-green.svg'}
                alt="Incentive"
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            </span>
          )}
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontFamily: 'var(--font-bold)',
                fontSize: 14,
                color: 'var(--text-primary)',
              }}
            >
              {incentive.name}
            </span>
            <div style={{ color: 'var(--text-secondary)' }}>
              <SavingsList savings={incentive.savings ?? []} />
            </div>
            <TotalSavings incentive={incentive} unit={unit} />
          </span>
        </div>
      ) : (
        <span>No Incentive</span>
      )}
    </>
  );
};

export default IncentivesTableUnitsIncentive;
