import React from 'react';

import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import classes from './rentroll.module.scss';
import { CategoryType } from './types';

interface Props {
  data: CategoryType[];
  title: string;
  handleUpdateSwot: (option: any, record: CategoryType) => void;
}

const importanceOptions = [
  { label: 'Very UnImportant', value: 1 },
  { label: 'UnImportant', value: 2 },
  { label: 'Neutral', value: 3 },
  { label: 'Important', value: 4 },
  { label: 'Very Important', value: 5 },
];

const SwotTable = ({ data, title, handleUpdateSwot }: Props) => {
  const columns: ColumnsType<CategoryType> = [
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Sr. No.</span>,
      dataIndex: 'key',
      key: 'key',
      width: '50px',
      align: 'left',
      render(value) {
        return <span style={{ fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Name</span>,
      dataIndex: 'sub_category_name',
      key: 'sub_category_name',
      width: '300px',
      align: 'left',
      render(value) {
        return <span style={{ fontSize: 18 }}>{value}</span>;
      },
    },

    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Importance</span>,
      dataIndex: 'important_score',
      key: 'important_score',
      width: '150px',
      align: 'left',
      render(value, record: any) {
        return (
          <Select
            placeholder="Select"
            options={importanceOptions}
            onChange={(_, option) => {
              handleUpdateSwot(option, record);
            }}
            value={value}
            style={{ width: '100%' }}
            disabled
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Score</span>,
      dataIndex: 'sub_category_score',
      key: 'sub_category_score',
      width: '100px',
      align: 'center',
      render(value) {
        return <span style={{ fontSize: 18 }}>{value}</span>;
      },
    },
  ];

  return (
    <div
      style={{
        padding: '0px 10px 30px 10px',
        maxWidth: 860,
        margin: '0px auto',
      }}
    >
      <div style={{ textAlign: 'center', fontSize: 22 }}>
        <h2>{title}</h2>
      </div>
      <br />
      <Table
        className={classes.rentroll_table}
        // components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content', y: '50vh' }}
      />
    </div>
  );
};

export default SwotTable;
