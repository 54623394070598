import React from 'react';

import styled from 'styled-components';

import IncentivesProposalModalProposalDisplay from './IncentiveProposalModalProposalDisplay';
import IncentivesProposalModalProposalHeader from './IncentiveProposalModalProposalHeader';
import IncentivesProposalModalProposalLead from './IncentiveProposalModalProposalLead';
import IncentivesProposalModalProposalTime from './IncentiveProposalModalProposalTime';
import IncentivesProposalModalProposalAgreements from './IncentivesProposalModalProposalAgreements';
import IncentivesProposalModalProposalResident from './IncentivesProposalModalProposalResident';
import IncentivesProposalModalProposalSecondPerson from './IncentivesProposalModalProposalSecondPerson';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px;
  padding-top: 0;
  max-height: 430px;
  overflow-y: auto;
  box-sizing: border-box;
`;

const IncentivesProposalModalProposalData = () => (
  <>
    <IncentivesProposalModalProposalHeader />
    <Container>
      <IncentivesProposalModalProposalLead />
      <IncentivesProposalModalProposalResident />
      <IncentivesProposalModalProposalSecondPerson />
      <IncentivesProposalModalProposalTime />
      <IncentivesProposalModalProposalDisplay />
      <IncentivesProposalModalProposalAgreements />
    </Container>
  </>
);

export default IncentivesProposalModalProposalData;
