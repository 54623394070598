import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import Button from '../../lib/Button';
import Select from '../../lib/Select';
import Slider from '../../lib/Slider';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const StyledSelect = styled(Select)`
  &.ant-select {
    font-family: var(--font-regular);
    font-size: 12px;
    height: 28px;
    color: inherit;
    color: var(--deep-blue);
    background-color: var(--lighter-gray);
    border-radius: 4px;

    &.active {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
    }

    &.ant-select-focused {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
      border: 1px solid var(--button-blue);
    }

    .ant-select-arrow {
      color: inherit;
      width: 16px;
      height: 16px;
      top: 12px;
    }
    .ant-select-arrow .anticon {
      width: 8px;
      height: 4.9px;
      margin: auto;
    }
    .ant-select-selector {
      color: inherit;
      .ant-select-selection-placeholder {
        color: inherit;
        font-size: 12px;
      }
      .ant-select-selection-item {
        color: inherit;
        font-size: 12px;
      }
    }
  }
`;

const Label = styled.span``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 10px 20px;
  border-bottom: 1px solid var(--line-gray);
  gap: 10px;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SelectionActions = styled.div`
  display: flex;
  gap: 10px;

  span {
    color: var(--link-blue);
    font-size: 12px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SliderLabel = styled.span`
  font-size: 14px;
  color: var(--text-primary);
`;

const SliderInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-secondary);

  span.value {
    color: var(--text-primary);
    font-family: var(--font-bold);
  }
`;

const SLIDER_STEP = 5;
const SLIDER_VALUE_MIN = 0;
const SLIDER_VALUE_MAX = 100;
const SLIDER_DEFAULT_VALUE = [0, 80];

const IncentivesFiltersOccupancyDropdown = () => {
  const { filters, setFilters } = useIncentivesDataContext();
  const { occupancyRange } = filters;

  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<number[]>(SLIDER_DEFAULT_VALUE);

  useEffect(() => {
    setRange(occupancyRange?.length ? occupancyRange : SLIDER_DEFAULT_VALUE);
  }, [occupancyRange]);

  const handleApply = () => {
    setFilters({ ...filters, page: 1, occupancyRange: range });
    setOpen(false);
  };

  const handleReset = () => {
    setFilters({ ...filters, page: 1, occupancyRange: [] });
    setOpen(false);
  };

  const handleSliderChange = (value: number[]) => {
    setRange(value);
  };

  return (
    <StyledSelect
      open={open}
      variant={'borderless'}
      className={classNames({ active: filters?.occupancyRange?.length })}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      placeholder={'Occupancy'}
      value={range?.length ? range : undefined}
      labelRender={({ label, value }) => {
        const numberLabel = occupancyRange?.length
          ? `${occupancyRange && occupancyRange[0]} - ${occupancyRange && occupancyRange[1]}%`
          : '';
        return <Label>Occupancy: {numberLabel}</Label>;
      }}
      dropdownStyle={{
        padding: 0,
        borderRadius: 0,
        border: '1px solid var(--line-gray)',
        minWidth: 270,
      }}
      dropdownRender={() => {
        return (
          <Container>
            <Controls>
              <Selection>
                <SelectionActions>
                  <span onClick={handleReset}>Reset</span>
                </SelectionActions>
                <Button type={'primary'} onClick={handleApply} style={{ width: 96, height: 36 }}>
                  Apply
                </Button>
              </Selection>
            </Controls>
            <Content>
              <SliderContainer>
                <SliderLabel>0%</SliderLabel>
                <Slider
                  className={'range'}
                  range
                  min={SLIDER_VALUE_MIN}
                  max={SLIDER_VALUE_MAX}
                  step={SLIDER_STEP}
                  value={range}
                  tooltip={{ placement: 'bottom', formatter: (value) => `${value}%` }}
                  onChange={handleSliderChange}
                />
                <SliderLabel>100%</SliderLabel>
              </SliderContainer>
              <SliderInfoContainer>
                <span>
                  Community unit occupancy ratio between <span className={'value'}>{Math.min(...range)}</span> and{' '}
                  <span className={'value'}>{Math.max(...range)}</span> percent.
                </span>
              </SliderInfoContainer>
            </Content>
          </Container>
        );
      }}
    />
  );
};
export default IncentivesFiltersOccupancyDropdown;
