import React, { useEffect, useState } from 'react';

import { Radio, RadioChangeEvent, Typography } from 'antd';
import styled from 'styled-components';

import { COMMUNITY_DIGEST_STATUS } from './constants';
import SettingsModal from './SettingsModal';

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-flow: column;
`;

const RadioButton = styled(Radio)`
  margin-top: 20px;
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  communitiesToModify: any[];
  onSave: (newStatus: number) => void;
};

const CommunityDigestModal = ({ onClose, onSave, isOpen, communitiesToModify }: Props) => {
  const [value, setValue] = useState<number | undefined>();

  useEffect(() => {
    if (!communitiesToModify.length || communitiesToModify.length > 1) {
      return;
    }
    setValue(communitiesToModify[0].community_digest_email_status);
  }, [communitiesToModify]);

  const saveChanges = () => {
    if (!value) {
      return;
    }
    onSave(value);
    onClose();
  };

  return (
    <SettingsModal
      title={COMMUNITY_DIGEST_STATUS.title}
      onClose={onClose}
      onSave={saveChanges}
      isOpen={isOpen}
      communitiesToModify={communitiesToModify}
    >
      <Typography.Text style={{ fontSize: '14px', marginTop: '10px' }}>
        {COMMUNITY_DIGEST_STATUS.description}
      </Typography.Text>
      <RadioGroup onChange={(event: RadioChangeEvent) => setValue(event.target.value)} value={value}>
        <RadioButton value={COMMUNITY_DIGEST_STATUS.statusElements.OFF.value}>
          {COMMUNITY_DIGEST_STATUS.statusElements.OFF.label}
        </RadioButton>
        <RadioButton value={COMMUNITY_DIGEST_STATUS.statusElements.MONTHLY.value}>
          {COMMUNITY_DIGEST_STATUS.statusElements.MONTHLY.label}
        </RadioButton>
        <RadioButton value={COMMUNITY_DIGEST_STATUS.statusElements.QUARTERLY.value}>
          {COMMUNITY_DIGEST_STATUS.statusElements.QUARTERLY.label}
        </RadioButton>
      </RadioGroup>
    </SettingsModal>
  );
};

export default CommunityDigestModal;
