import React from 'react';

import SavingsItem from './SavingsItem';
import { IncentiveSavingType } from '../types';

interface Props {
  savings: IncentiveSavingType[];
}

const SavingsList = ({ savings }: Props) => (
  <span
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {savings?.map((item: IncentiveSavingType) => <SavingsItem key={`${item.id}`} item={item} />)}
  </span>
);

export default SavingsList;
