import React, { useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper, useTransformEffect } from 'react-zoom-pan-pinch';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import ZoomControls from './ZoomControls';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  color: var(--text-primary);
  font-size: 18px;
  margin-bottom: 85px;
`;

const CarouselControls = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  justify-content: space-between;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 10;
`;

const ControlsContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 5px;
  display: none;
`;

const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  &:hover ${CarouselControls} {
    display: flex;
  }
  &:hover ${ControlsContainer} {
    display: flex;
  }
`;

const ImageWrapper = styled.div`
  &.zoomable {
    cursor: grab;
  }
`;

const CarouselButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: white;
  border: solid 1px var(--line-gray);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &.disabled {
    span {
      opacity: 0.3;
    }
    pointer-events: none;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ThumbnailImageWrapper = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

interface Props {
  title: string;
  images?: string[];
}

const ComparisonCarousal = ({ title, images = [] }: Props) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  useEffect(() => {
    setCurrentImage(0);
  }, [images]);

  const handleThumbnailClick = (index: number) => {
    setCurrentImage(index);
  };

  const handleNextClick = () => {
    setCurrentImage((prev) => prev + 1);
  };

  const handlePreviousClick = () => {
    setCurrentImage((prev) => prev - 1);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <CarouselContainer>
        {images && images[currentImage] && (
          <ImageWrapper className={`${isZoomed ? 'zoomable' : ''}`}>
            <CarouselControls>
              <CarouselButton className={classNames({ disabled: currentImage <= 0 })} onClick={handlePreviousClick}>
                <LeftOutlined />
              </CarouselButton>
              <CarouselButton
                className={classNames({ disabled: currentImage >= images?.length - 1 })}
                onClick={handleNextClick}
              >
                <RightOutlined />
              </CarouselButton>
            </CarouselControls>
            <TransformWrapper>
              <ControlsContainer>
                <ZoomControls setIsZoomed={setIsZoomed} />
              </ControlsContainer>
              <TransformComponent>
                <Image
                  src={images[currentImage]}
                  style={{ position: 'relative', maxWidth: 650, maxHeight: 350 }}
                  preview={false}
                  fallback="/no-image.png"
                />
              </TransformComponent>
            </TransformWrapper>
          </ImageWrapper>
        )}
      </CarouselContainer>
      <ThumbnailWrapper>
        {images?.map((imageUrl: string, index: number) => (
          <ThumbnailImageWrapper key={index} onClick={() => handleThumbnailClick(index)}>
            <Image
              src={imageUrl}
              preview={false}
              alt={`Thumbnail ${index + 1}`}
              fallback="/no-image.png"
              style={{
                width: 40,
                height: 40,
                borderRadius: '4px',
                boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.15)',
                border: 'solid 3px #fff',
              }}
            />
          </ThumbnailImageWrapper>
        ))}
      </ThumbnailWrapper>
    </Container>
  );
};

export default ComparisonCarousal;
