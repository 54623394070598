import React from 'react';

import { message } from 'antd';

import { OccupanciesContext } from './OccupanciesContext';
import OccupanciesFormData from './OccupanciesFormData';
import OccupanciesFormHistory from './OccupanciesFormHistory';
import FormModeSwitch from '../../../common/FormNavigation/FormModeSwitch';

interface Props {
  communityId: any;
}

const OccupanciesForm = ({ communityId }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = (type: 'success' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <OccupanciesContext.Provider value={{ communityId: parseInt(communityId), showMessage: showMessage }}>
      {contextHolder}
      <FormModeSwitch editChild={<OccupanciesFormData />} historyChild={<OccupanciesFormHistory />} />
    </OccupanciesContext.Provider>
  );
};

export default OccupanciesForm;
