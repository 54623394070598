import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import IncentivesFiltersMenu from './IncentivesFiltersMenu';
import Input from '../../lib/Input';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const IncentivesFilters = () => {
  const { createMode, filters, setFilters } = useIncentivesDataContext();

  const handleChange = (event: any) => {
    const search = event.target.value;
    setFilters({ ...filters, page: 1, search });
  };

  const searchPlaceholder = createMode
    ? 'Search by community or unit name'
    : 'Search by incentive, community, unit or recipient name';

  return (
    <Container>
      <Input
        value={filters?.search}
        prefix={<SearchOutlined />}
        style={{ width: 500 }}
        onChange={handleChange}
        placeholder={searchPlaceholder}
      />
      <IncentivesFiltersMenu />
    </Container>
  );
};

export default IncentivesFilters;
