import { HeaderContent } from './types';

export const COMMUNITY_DIGEST_STATUS = {
  statusElements: {
    MONTHLY: {
      label: 'Monthly',
      value: 1,
    },
    QUARTERLY: {
      label: 'Quarterly',
      value: 2,
    },
    OFF: {
      label: 'Off',
      value: 3,
    },
  },
  icon: {
    backgroundColor: '#22cfd1',
    url: '/icons/newspaper.svg',
  },
  title: 'Community Digest',
  description: 'Choose if a digest email is sent summarizing new community developments.',
};

export const MARKET_RATE_COMPARISON_UPDATES_STATUS = {
  statusElements: {
    WHEN_OVER_MARKET_RATE: {
      label: 'When over market rate',
      value: 1,
    },
    WHEN_UNDER_MARKET_RATE: {
      label: 'When under market rate',
      value: 2,
    },
    OFF: {
      label: 'Off',
      value: 3,
    },
  },
  icon: {
    backgroundColor: '#5f5fff',
    url: '/icons/price-change.svg',
  },
  title: 'Market Rate Comparison Updates',
  description: 'Choose if an email is sent if your pricing differs significantly from the market rate.',
};

export const COMPETITOR_INCENTIVE_UPDATES = {
  statusElements: {
    ON_EACH_UPDATE: {
      label: 'On each update',
      value: 1,
    },
    WEEKLY_SUMMARY: {
      label: 'Weekly summary',
      value: 2,
    },
    MONTHLY_SUMMARY: {
      label: 'Monthly summary',
      value: 3,
    },
    OFF: {
      label: 'Off',
      value: 4,
    },
  },
  icon: {
    backgroundColor: '#007aff',
    url: '/icons/local-offer.svg',
  },
  title: 'Competitor Incentive Updates',
  description: 'Choose if an email is sent when a competitor adds new incentives.',
};

export const REMINDER_TO_UPDATE_PRICING_STATUS = {
  statusElements: {
    MONTHLY: {
      label: 'Monthly',
      value: 1,
    },
    EVERY_2_MONTHS: {
      label: 'Every 2 months',
      value: 2,
    },
    QUARTERLY: {
      label: 'Quarterly',
      value: 3,
    },
    EVERY_6_months: {
      label: 'Every 6 months',
      value: 4,
    },
    OFF: {
      label: 'Off',
      value: 5,
    },
  },
  icon: {
    backgroundColor: '#ff5b98',
    url: '/icons/rule.svg',
  },
  title: 'Reminder to Update Pricing',
  description: 'Choose if an email is sent reminding that pricing has not been updated in a while.',
};

export const COMMUNITY_LABEL_COLOR = {
  NEUTRAL: 0,
  POSITIVE: 1,
};

export const SETTINGS_PAGE_INFO: { [key: string]: HeaderContent } = {
  COMMUNITY_DIGEST: {
    title: COMMUNITY_DIGEST_STATUS.title,
    description: COMMUNITY_DIGEST_STATUS.description,
    iconBackgroundColor: COMMUNITY_DIGEST_STATUS.icon.backgroundColor,
    iconSrc: COMMUNITY_DIGEST_STATUS.icon.url,
  },
  MARKET_RATE_COMPARISON: {
    title: MARKET_RATE_COMPARISON_UPDATES_STATUS.title,
    description: MARKET_RATE_COMPARISON_UPDATES_STATUS.description,
    iconBackgroundColor: MARKET_RATE_COMPARISON_UPDATES_STATUS.icon.backgroundColor,
    iconSrc: MARKET_RATE_COMPARISON_UPDATES_STATUS.icon.url,
  },
  REMINDER_TO_UPDATE_PRICING: {
    title: REMINDER_TO_UPDATE_PRICING_STATUS.title,
    description: REMINDER_TO_UPDATE_PRICING_STATUS.description,
    iconBackgroundColor: REMINDER_TO_UPDATE_PRICING_STATUS.icon.backgroundColor,
    iconSrc: REMINDER_TO_UPDATE_PRICING_STATUS.icon.url,
  },
  COMPETITOR_INCENTIVE: {
    title: COMPETITOR_INCENTIVE_UPDATES.title,
    description: COMPETITOR_INCENTIVE_UPDATES.description,
    iconBackgroundColor: COMPETITOR_INCENTIVE_UPDATES.icon.backgroundColor,
    iconSrc: COMPETITOR_INCENTIVE_UPDATES.icon.url,
  },
};

export const COMMUNITY_SETTINGS_PAGE_TYPE = {
  COMMUNITY_DIGEST: 'COMMUNITY_DIGEST',
  MARKET_RATE_COMPARISON: 'MARKET_RATE_COMPARISON',
  REMINDER_TO_UPDATE_PRICING: 'REMINDER_TO_UPDATE_PRICING',
  COMPETITOR_INCENTIVE: 'COMPETITOR_INCENTIVE',
};

export const COMMUNITY_SETTINGS_LIST_PAGE_SIZE = 10;
