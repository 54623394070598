import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

interface Props {
  item: any;
  handleRemove: () => void;
}

const CompetitorSearchItem = ({ item, handleRemove }: Props) => {
  return (
    <div
      style={{
        borderBottom: '1px solid #ccc',
        paddingRight: 50,
        position: 'relative',
        marginBottom: 10,
      }}
      onClick={handleRemove}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        {item?.name}
      </Typography.Title>
      <Typography.Paragraph style={{ color: '#ccc' }}>{item?.formatted_address}</Typography.Paragraph>

      <Button
        icon={<CloseOutlined />}
        type="link"
        style={{ position: 'absolute', right: 0, top: '50%', marginTop: -20 }}
        danger
      />
    </div>
  );
};

export default CompetitorSearchItem;
