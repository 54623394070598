import React from 'react';

import { IconProps } from './types';

const Icon = ({ src, alt, size = 10 }: IconProps) => (
  <img
    src={src}
    alt={alt}
    style={{
      width: size,
      height: size,
    }}
  />
);

export default Icon;
