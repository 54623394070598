import React from 'react';

import styled from 'styled-components';

import { STEPS } from './constants';
import { useSwotCompetitorAnalysisContext } from './SwotCompetitorAnalysisContext';
import { COMMUNITY_TYPE } from '../../../../constants';
import { useAppSelector } from '../../../../store/hook';
import Button from '../../../lib/Button';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;

  & > :first-child {
    margin-right: 10px;
  }

  .ant-btn {
    padding: 10px 42.5px 10px 43.5px;
  }
`;

const SwotCompetitorAnalysisModalFooter = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const isCompetitor = selectedCommunity?.type == COMMUNITY_TYPE.COMPETITOR;
  const { step, selectedCompetitor, setStep, handleClose, onCompetitorSelect } = useSwotCompetitorAnalysisContext();

  const showNext = step == STEPS.ACTIONS_SELECTOR;
  const selectCompetitorButtonText = !isCompetitor ? 'Select Competitor' : 'Select My Community';

  return (
    <Container>
      {showNext && (
        <Button type="primary" onClick={() => setStep(2)}>
          Next
        </Button>
      )}
      {!showNext && (
        <Button type="primary" disabled={!selectedCompetitor} onClick={() => onCompetitorSelect(selectedCompetitor)}>
          {selectCompetitorButtonText}
        </Button>
      )}
      <Button onClick={handleClose}>Cancel</Button>
    </Container>
  );
};

export default SwotCompetitorAnalysisModalFooter;
