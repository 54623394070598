import React from 'react';

import { Table } from 'antd';
import styled from 'styled-components';

export const IncentivesStyledTable = styled(Table)`
  &&&&&& {
    width: 100%;

    tr {
      font-family: var(--font-regular);
      font-weight: 200;
      color: var(--text-primary);
      background-color: #fff;
      
      &.mixed-selection {
          .ant-table-selection-column {
        visibility: hidden;
      }
      
      &.selectable {
        .ant-table-selection-column {
          visibility: visible;
        }
      }
      

    }

    td {
      padding: 10px 10px 10px 0px;
      vertical-align: top;

      &.ant-table-cell {
        background: white;
      }

      &.ant-table-cell-row-hover {
        .hover-visible {
          visibility: visible;
        }
      }
    }

    th {
      font-family: var(--font-bold);
      text-transform: uppercase;
      padding-left: 0;
      background-color: #fff;

      &.ant-table-cell::before {
        background-color: transparent;
      }
    }

    .ant-table-selection-column {
      .ant-checkbox-inner {
        width: 18px;
        height: 18px;
        border: 2px solid var(--gray-dark);
      }
      .ant-checkbox-indeterminate {
        .ant-checkbox-inner::after {
          background-color: var(--positive-green);
        }
      }
      .ant-checkbox-wrapper-checked {
        .ant-checkbox-inner {
          border-color: var(--positive-green);
          background-color: var(--positive-green);
        }
      }
    }

    .ant-table-pagination {
      margin: 22px 0px;
      align-items: baseline;
      font-size: 12px;

      .ant-pagination-total-text {
        position: absolute;
        right: 20px;
      }

      .ant-pagination-item {
        line-height: 24px;
        color: var(--text-primary);
      }

      .ant-pagination-item-active {
        border-radius: 11px;

        box-shadow: 0 2px 6px 0 var(--box-shadow-gray);
        background-color: #fff;
        border: none;
        a {
          color: var(--text-primary) !important;
        }
      }
    }
  }
`;

export default IncentivesStyledTable;
