import React, { useState } from 'react';

import { Radio, Space } from 'antd';
import styled from 'styled-components';

import Select from './Select';
import { UnitIncentivesFiltersType } from '../Incentives/types';

const StyledSelect = styled(Select)`
  &.ant-select {
    font-family: var(--font-regular);
    font-size: 12px;
    height: 28px;
    color: inherit;
    color: var(--deep-blue);
    background-color: var(--lighter-gray);
    border-radius: 4px;

    &.active {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
    }

    &.ant-select-focused {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
      border: 1px solid var(--button-blue);
    }

    .ant-select-arrow {
      color: inherit;
      width: 16px;
      height: 16px;
      top: 12px;
    }
    .ant-select-arrow .anticon {
      width: 8px;
      height: 4.9px;
      margin: auto;
    }
    .ant-select-selector {
      color: inherit;
      .ant-select-selection-placeholder {
        color: inherit;
        font-size: 12px;
      }
      .ant-select-selection-item {
        color: inherit;
        font-size: 12px;
      }
    }
  }
`;

const Label = styled.span`
  font-family: var(--font-bold);
`;

const StyledRadio = styled(Radio)``;

interface Props {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  options: any[];
  defaultValue: number | undefined;
  onSelect: any;
  showSemicolon?: boolean;
}

const SelectAdvancedFilterRadio = ({
  className,
  disabled = false,
  options,
  defaultValue,
  placeholder,
  onSelect,
  showSemicolon = true,
}: Props) => {
  const [open, setOpen] = useState(false);

  const handleChange = (event: any) => {
    onSelect(event.target.value);
  };

  return (
    <StyledSelect
      open={open}
      className={className}
      variant={'borderless'}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      disabled={disabled}
      placeholder={placeholder}
      value={defaultValue}
      labelRender={({ label, value }) => {
        const selectedOption = options.find((item: any) => item.value === value);
        return (
          <Label>
            {placeholder}
            {selectedOption && showSemicolon && ':'} {selectedOption?.label}
          </Label>
        );
      }}
      dropdownStyle={{
        padding: 20,
        borderRadius: 0,
        border: '1px solid var(--line-gray)',
        minWidth: 223,
      }}
      dropdownRender={(menu) => {
        return (
          <Radio.Group onChange={handleChange} value={defaultValue} size="large">
            <Space direction="vertical" style={{ rowGap: 15 }}>
              {options?.map((item: any) => (
                <StyledRadio
                  style={{ fontSize: 14, lineHeight: '14px', fontWeight: 500 }}
                  key={item.value ?? -1}
                  value={item.value}
                >
                  {item.label}
                </StyledRadio>
              ))}
            </Space>
          </Radio.Group>
        );
      }}
    />
  );
};

export default SelectAdvancedFilterRadio;
