import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  margin-left: 10px;
  width: 100px;
  height: 2px;
  border-radius: 5px;
  background-color: var(--line-gray);
`;

const ProgressBarInner = styled.div`
  height: 2px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #275aff, #27b4ff);
`;

type Props = {
  percentage: number;
  style?: any;
};

const ProgressBar = ({ percentage, style }: Props) => {
  return (
    <Container className="progress-bar-container" style={style}>
      <ProgressBarInner style={{ width: `${percentage}%` }} />
    </Container>
  );
};

export default ProgressBar;
