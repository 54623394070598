import React from 'react';

import styled from 'styled-components';

import { isMysteryShopAdmin, isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';
import EditShopRequestModalNote from './EditShopRequestModalNote';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const EditShopRequestModalNotes = () => {
  const { shopRequest, setShopRequest } = useEditShopRequestModalContext();
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);
  const isShopRequestSalesperson = currentUser && isMysteryShopShopper(currentUser);

  return (
    <Container>
      <EditShopRequestModalNote
        title="Note from Further"
        note={shopRequest?.adminNote}
        allowEdit={!!isShopRequestAdmin}
        updateNote={(note: string) => setShopRequest({ ...shopRequest!, adminNote: note })}
      />
      <EditShopRequestModalNote
        title="Note from Shopper"
        note={shopRequest?.shopperNote}
        style={{ marginTop: '20px' }}
        allowEdit={!!isShopRequestSalesperson}
        disabled={currentUser?.PAUser_Id !== shopRequest?.shopper?.userId}
        updateNote={(note: string) => setShopRequest({ ...shopRequest!, shopperNote: note })}
      />
    </Container>
  );
};

export default EditShopRequestModalNotes;
