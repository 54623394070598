import React, { useState } from 'react';

import styled from 'styled-components';

import SidebarMenuQuickCompetitorsList from './SidebarMenuQuickCompetitorsList';
import SidebarMenuQuickCompetitorsSelectorButton from './SidebarMenuQuickCompetitorsSelectorButton';

const Container = styled.div`
  position: relative;
  margin-top: 10px;
`;

const SidebarMenuQuickCompetitors = () => {
  const [showCompetitors, setShowCompetitors] = useState<boolean>(true);

  return (
    <Container>
      <SidebarMenuQuickCompetitorsSelectorButton
        showCompetitors={showCompetitors}
        setShowCompetitors={setShowCompetitors}
      />
      {showCompetitors && <SidebarMenuQuickCompetitorsList />}
    </Container>
  );
};

export default SidebarMenuQuickCompetitors;
