import React from 'react';

import styled from 'styled-components';

import { getProposalPricingSavings } from './helpers';
import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
`;

const IncentivesProposalModalInfoTotal = () => {
  const { rates } = useIncentivesProposalModalContext();

  const totalSavings = getProposalPricingSavings(rates);

  return (
    <Container style={{ fontFamily: 'var(--font-bold)', fontSize: 12 }}>
      <div style={{ fontFamily: 'var(--font-bold)' }}>Total Savings</div>
      <div style={{ display: 'flex', alignItems: 'start', color: 'var(--positive-green)' }}>
        <span>${totalSavings}</span>
      </div>
    </Container>
  );
};

export default IncentivesProposalModalInfoTotal;
