import React from 'react';

import styled from 'styled-components';

import { Divider } from 'components/lib/Divider';
import Loader from 'components/lib/Loader';

import IncentivesProposalModalInfoCareType from './IncentivesProposalModalInfoCareType';
import IncentivesProposalModalInfoCommunity from './IncentivesProposalModalInfoCommunity';
import IncentivesProposalModalInfoRates from './IncentivesProposalModalInfoRates';
import IncentivesProposalModalInfoSavings from './IncentivesProposalModalInfoSavings';
import IncentivesProposalModalInfoSelector from './IncentivesProposalModalInfoSelector';
import IncentivesProposalModalInfoTotal from './IncentivesProposalModalInfoTotal';
import IncentivesProposalModalInfoUnit from './IncentivesProposalModalInfoUnit';
import { useIncentivesContext } from '../IncentivesContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 300px;
  background-color: var(--background-gray-light);
  border-right: solid 1px var(--light-gray);
  padding: 30px;
`;

const UpperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 200;
  color: var(--text-primary);
`;

const Title = styled.div`
  font-size: 16px;
  font-family: var(--font-bold);
  color: var(--text-primary);
`;

const IncentivesProposalModalInfo = () => {
  const { selectedProposalUnit } = useIncentivesContext();

  return (
    <Container>
      {
        selectedProposalUnit?.incentives
          ? <>
              <UpperContent>
                <Title>Incentive</Title>
                <IncentivesProposalModalInfoSelector />
                <IncentivesProposalModalInfoSavings />
                <IncentivesProposalModalInfoCommunity />
                <IncentivesProposalModalInfoUnit />
                <IncentivesProposalModalInfoCareType />
                <IncentivesProposalModalInfoRates />
              </UpperContent>
              <Divider />
              <IncentivesProposalModalInfoTotal />
            </>
          : <Loader active />
      }
    </Container>
  );
};

export default IncentivesProposalModalInfo;
