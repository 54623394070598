import React, { useState } from 'react';

import Button from 'components/lib/Button';

import CreateShopRequestModal from './CreateMysteryShopRequestModal/CreateShopRequestModal';

const ShopRequestsCreateButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Button type="primary" style={{ padding: '0 25px' }} onClick={() => setModalOpen(true)}>
        Create Shop Request
      </Button>
      {isModalOpen && <CreateShopRequestModal open={isModalOpen} onCancel={() => setModalOpen(false)} />}
    </>
  );
};

export default ShopRequestsCreateButton;
