import React from 'react';

import { LoadingOutlined } from '@ant-design/icons/lib';
import { Spin } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

interface Props {
  active: boolean;
  size?: number;
}

const Loader = ({ active = false, size = 24 }: Props) =>
  active ? (
    <Container>
      <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
    </Container>
  ) : null;

export default Loader;
