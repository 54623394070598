import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { getCompetitorName } from 'selectors/communitySelector';
import { useAppSelector } from 'store/hook';

import { useEditSwotDataModalContext } from './EditSwotDataModalContext';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: right;
  margin-bottom: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 380px;
`;

const StyledText = styled(Typography.Text)`
  width: 76px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const ComparisonTextContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  margin-bottom: 20px;
  height: 42px;
`;

const ComparisonText = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-secondary);
`;

const ComparisonItemsContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

const CategoriesHeader = () => {
  const { competitorId } = useEditSwotDataModalContext();
  const competitorName = useAppSelector((state) => getCompetitorName(state, competitorId));

  return (
    <Container>
      <InnerContainer>
        <ComparisonTextContainer>
          <ComparisonText>In comparison to my community,</ComparisonText>
          <ComparisonText>
            <span style={{ color: 'var(--text-primary)' }}>{competitorName}</span> is:
          </ComparisonText>
        </ComparisonTextContainer>
        <ComparisonItemsContainer>
          <StyledText>Much Worse</StyledText>
          <StyledText>Worse</StyledText>
          <StyledText>Same</StyledText>
          <StyledText>Better</StyledText>
          <StyledText>Much Better</StyledText>
        </ComparisonItemsContainer>
      </InnerContainer>
    </Container>
  );
};

export default CategoriesHeader;
