import React, { ReactNode } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import { isLocationMatchingMenuItem } from 'utils/helpers/locationMatch';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 12px;
`;

const MenuItem = styled.div`
  padding: 15px 30px;

  &:hover {
    background-color: var(--sidebar-selector-border);
  }

  &.selected {
    background-color: var(--sidebar-selector-border);
  }
`;

const IconWrapper = styled.span`
  font-size: 14px;
  margin-right: 10px;
`;

interface Props {
  to: string;
  pattern?: string;
  label: string;
  icon?: ReactNode;
}

const SidebarMenuItem = ({ to, pattern, label, icon = null }: Props) => {
  const location = useLocation();

  const isPathMatch = matchPath(pattern || to, location.pathname);
  const menuItemClassnames = classNames({
    selected: isPathMatch || isLocationMatchingMenuItem(pattern || to, location.pathname),
  });
  return (
    <StyledLink to={to}>
      <MenuItem className={menuItemClassnames}>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <span>{label}</span>
      </MenuItem>
    </StyledLink>
  );
};

export default SidebarMenuItem;
