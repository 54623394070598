import React from 'react';

import styled from 'styled-components';

import IncentivesFiltersCareType from './IncentivesFiltersCareType';
import IncentivesFiltersCommunity from './IncentivesFiltersCommunity';
import IncentivesFiltersIncentiveStatus from './IncentivesFiltersIncentiveStatus';
import IncentivesFiltersOccupancy from './IncentivesFiltersOccupancy';
import IncentivesFiltersProposalStatus from './IncentivesFiltersProposalStatus';
import IncentivesFiltersReset from './IncentivesFiltersReset';
import IncentivesFiltersRoomType from './IncentivesFiltersRoomType';
import IncentivesFiltersUnitAvailability from './IncentivesFiltersUnitAvailability';
import { INCENTIVES_TABS } from '../constants';
import { useIncentivesContext } from '../IncentivesContext';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-right: 10px;
`;

const IncentivesFiltersMenu = () => {
  const { createMode } = useIncentivesDataContext();
  const { currentTab } = useIncentivesContext();

  const showUnitAvailabilityFilter = createMode || currentTab === INCENTIVES_TABS.UNIT;
  const showIncentiveStatusFilter = createMode || currentTab === INCENTIVES_TABS.UNIT;
  const showOccupancyFilter = createMode || currentTab === INCENTIVES_TABS.UNIT;
  const showProposalStatusFilter = currentTab === INCENTIVES_TABS.PROPOSAL;

  return (
    <Container>
      <IncentivesFiltersCommunity />
      <IncentivesFiltersRoomType />
      <IncentivesFiltersCareType />
      {showOccupancyFilter && <IncentivesFiltersOccupancy />}
      {showUnitAvailabilityFilter && <IncentivesFiltersUnitAvailability />}
      {showIncentiveStatusFilter && <IncentivesFiltersIncentiveStatus />}
      {showProposalStatusFilter && <IncentivesFiltersProposalStatus />}
      <IncentivesFiltersReset />
    </Container>
  );
};

export default IncentivesFiltersMenu;
