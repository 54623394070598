import React from 'react';

import styled from 'styled-components';

import SidebarLogo from './SidebarLogo';
import SidebarMenu from './SidebarMenu';
import SidebarSelector from './SidebarSelector';

const Container = styled.div`
  min-width: 210px;
  max-width: 210px;
  background: linear-gradient(to top, var(--sidebar-purple-dark) 20%, var(--sidebar-purple-light));
`;

const Sidebar = () => (
  <Container>
    <SidebarLogo />
    <SidebarSelector />
    <SidebarMenu />
  </Container>
);

export default Sidebar;
