import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, message, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios';

import { useAppSelector } from 'store/hook';

interface CalculationType {
  label: string;
  value: number;
  id: number;
  CompanyName: string;
  CompanyId: number;
  description: string;
  current_occupancy: number;
  goal_occupancy: number;
  months_to_goal: number;
  lead_volume: string;
  move_outs: string;
  created_at: string;
  update_at: string;
  created_by: string;
  updated_by: string;
  status: 0 | 1;
  baseline_delta: string;
  baseline_swot: string;
  sales_conversion: string;
  company: number;
  base_rent: number;
  sq_footage: number;
  price_per_sq: number;
  CommunityId: number;
  LivingTypeName: string;
  LivingTypeId: number;
  ApartmentTypeId: number;
  process_id: string;
  mkt_avg_base_rent: string;
  calc_proc_id: number;
  competitors_avg_swot_score: number;
  community_swot_score: number;
  needed_to_goal: number;
  inquiry_needed: number;
  first_adjustment: string;
  second_adjustment: string;
  final_recommended_rate: string;
}

interface Props {
  calculationsData: any;
  setCalculationsData: any;
  currentConfig?: any[];
  currentCalculator?: any;
  onSuccess?: () => void;
}

function GeneratedCalculationTable({
  calculationsData,
  setCalculationsData,
  currentConfig = [],
  currentCalculator,
  onSuccess,
}: Props) {
  const [params] = useSearchParams();
  const communityId = params.get('id');
  const [isEditable, setIsEditable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [livingOptions, setLivingOptions] = useState<any[]>([]);
  const [apartmentOptions, setApartmentOptions] = useState<any[]>([]);
  const [add_loading, setAddLoading] = useState<boolean>(false);
  const company_id = useAppSelector((state) => state.auth.currentUser?.company);

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const handleEdit = () => {
    setIsEditable(true);
    showMessage('success', 'Editing is Enabled');
  };

  const handleDelete = (record: CalculationType) => {
    const idx = calculationsData.findIndex((el: any) => el?.id === record.id);
    if (idx !== -1) {
      if (calculationsData[idx].op === 'added' || calculationsData[idx].op === undefined) {
        const temp = [...calculationsData];
        temp.splice(idx, 1);
        setCalculationsData(temp);
      } else {
        const finalPayload = {
          data: [
            {
              id: currentConfig[0]?.id,
              apartment_id: currentConfig[0].CommunityId,
              description: 'Test Parent',
              current_occupancy: currentConfig[0].current_occupancy,
              goal_occupancy: currentConfig[0].goal_occupancy,
              months_to_goal: currentConfig[0].months_to_goal,
              lead_volume: currentConfig[0].lead_volume,
              move_outs: currentConfig[0].move_outs,
              status: 1,
              baseline_delta: currentConfig[0].baseline_delta,
              baseline_swot: currentConfig[0].baseline_swot,
              competitors_avg_swot_score: currentConfig[0].competitors_avg_swot_score,
              community_swot_score: currentConfig[0].community_swot_score,
              process_id: currentConfig[0].process_id,
              inquiry_needed: currentConfig[0].inquiry_needed,
              needed_to_goal: currentConfig[0].needed_to_goal,
              Calculator: [
                {
                  id: calculationsData[idx]?.id,
                  description: calculationsData[idx]?.description,
                  apartment_type_id: calculationsData[idx].ApartmenTypeId,
                  living_type_id: calculationsData[idx].LivingTypeId,
                  mkt_avg_base_rent: calculationsData[idx].mkt_avg_base_rent,
                  first_adjustment: calculationsData[idx].first_adjustment,
                  second_adjustment: calculationsData[idx].second_adjustment,
                  final_recommended_rate: calculationsData[idx].final_recommended_rate,
                  calc_proc_id: calculationsData[idx].calc_proc_id,
                  op: 'deleted',
                  disabled: false,
                },
              ],
              sales_conversion: currentConfig[0].sales_conversion,
              op: 'edited',
              disabled: false,
            },
          ],
        };

        setAddLoading(true);
        axios
          .post('/communities/Save_CommunityCalculator/', finalPayload)
          .then((res) => {
            setAddLoading(false);
            setCalculationsData([]);
            showMessage('success', 'Deleted Successfully');
            axios
              .get(`/communities/Get_CommunityCalculator/${currentCalculator}/`)
              .then((res) => {
                setCalculationsData(res.data?.payload?.Calculator);
              })
              .catch((err) => {
                console.log('Error | Get_CommunityCalculator', err);
              });
          })
          .catch((err) => {
            setAddLoading(false);
            showMessage('error', 'Something went wrong !');
            console.log('Error | Save_CommunityCalculator');
          });
        setIsEditable(false);
      }
    }
  };

  const handleGetRespectiveMarketRate = (
    index: number,
    LivingTypeId: number | string,
    ApartmentTypeId: number | string,
    operation?: string
  ) => {
    axios
      .get(`/communities/Get_CommunityCalculatorRate/${communityId}/${LivingTypeId}/${ApartmentTypeId}/`)
      .then((res) => {
        const base_rent = res.data?.payload['Avg_Values'][0]['Avg_base_rent'];
        const mkt_avg_base_rent = Number(parseInt(base_rent).toFixed(0));

        const sales_conversion: any =
          parseFloat(currentConfig[0]['baseline_delta']) - parseFloat(currentConfig[0]['inquiry_needed']);

        let firstAdj;
        if (currentConfig[0]['community_swot_score'] > currentConfig[0]['competitors_avg_swot_score']) {
          if (currentConfig[0]['community_swot_score'] < parseInt(currentConfig[0]['baseline_swot'])) {
            firstAdj =
              mkt_avg_base_rent +
              (((parseInt(currentConfig[0]['baseline_swot']) + currentConfig[0]['community_swot_score']) * 100) / 100) *
                mkt_avg_base_rent;
          } else {
            firstAdj =
              mkt_avg_base_rent -
              (((currentConfig[0]['community_swot_score'] - parseFloat(currentConfig[0]['baseline_swot'])) * 100) /
                100) *
                mkt_avg_base_rent;
          }
        } else {
          if (currentConfig[0]['competitors_avg_swot_score'] < parseInt(currentConfig[0]['baseline_swot'])) {
            firstAdj =
              mkt_avg_base_rent +
              (((parseFloat(currentConfig[0]['baseline_swot']) - currentConfig[0]['competitors_avg_swot_score']) *
                100) /
                100) *
                mkt_avg_base_rent;
          } else {
            firstAdj =
              mkt_avg_base_rent -
              (((currentConfig[0]['competitors_avg_swot_score'] - parseInt(currentConfig[0]['baseline_swot'])) * 100) /
                100) *
                mkt_avg_base_rent;
          }
        }
        const secondAdj = (firstAdj * sales_conversion) / 100;
        const finalAdj = firstAdj + secondAdj;

        const temp = [...calculationsData];
        // console.log({ operation, temp });
        if (operation === 'edit') {
          temp[index]['mkt_avg_base_rent'] = mkt_avg_base_rent;
          temp[index]['first_adjustment'] = firstAdj;
          temp[index]['second_adjustment'] = secondAdj;
          temp[index]['final_recommended_rate'] = finalAdj;
          temp[index]['calc_proc_id'] = currentConfig[0]?.id;
        } else {
          temp.push({
            id: -1,
            description: 'Test',
            LivingTypeId: '1',
            ApartmentTypeId: '1',
            mkt_avg_base_rent: mkt_avg_base_rent,
            first_adjustment: firstAdj.toFixed(0),
            second_adjustment: secondAdj.toFixed(2),
            final_recommended_rate: finalAdj,
            calc_proc_id: currentConfig[0]?.id,
            company_id: company_id,
            op: 'added',
          });
        }
        setCalculationsData(temp);
      })
      .catch((err) => {
        console.log(err);
        console.log('Error in Avg base Rent');
      });
  };
  const handleTableChange = (val: string | number | boolean, row: CalculationType, key: string) => {
    const temp = [...calculationsData];
    const index = temp.findIndex((el) => el?.id === row.id);
    if (index !== -1) {
      temp[index][key] = val;

      if (temp[index]['op'] === '') {
        temp[index]['op'] = 'edited';
      }
      setCalculationsData(temp);

      if (key === 'LivingTypeId' || key === 'ApartmentTypeId') {
        handleGetRespectiveMarketRate(
          index,
          calculationsData[index].LivingTypeId,
          calculationsData[index].ApartmentTypeId,
          'edit'
        );
      }
    }
  };

  const handleAddRow = () => {
    handleGetRespectiveMarketRate(calculationsData.length, 1, 1);
  };

  const handleSave = () => {
    const childRecords = calculationsData?.map((obj: any) => ({
      id: -1,
      apartment_id: obj?.CommunityId,
      description: obj?.description,
      apartment_type_id: obj.ApartmentTypeId,
      living_type_id: obj.LivingTypeId,
      mkt_avg_base_rent: obj.mkt_avg_base_rent,
      first_adjustment: obj.first_adjustment,
      second_adjustment: obj.second_adjustment,
      final_recommended_rate: obj.final_recommended_rate,
      calc_proc_id: obj.calc_proc_id,
      base_rent: obj.base_rent,
      company_id: obj?.CompanyId,
      sq_footage: obj.sq_footage,
      price_per_sq: obj.price_per_sq,
      op: obj.op !== undefined ? obj.op : 'added',
      disabled: false,
    }));

    // console.log({ c: currentConfig });
    let finalPayload;
    if (calculationsData[0].CommunityId) {
      finalPayload = {
        data: [
          {
            id: -1,
            apartment_id: calculationsData[0].CommunityId,
            description: 'Test Parent',
            current_occupancy: calculationsData[0].current_occupancy,
            goal_occupancy: calculationsData[0].goal_occupancy,
            months_to_goal: calculationsData[0].months_to_goal,
            lead_volume: calculationsData[0].lead_volume,
            move_outs: calculationsData[0].move_outs,
            status: currentConfig[0]?.status,
            baseline_delta: calculationsData[0].baseline_delta,
            baseline_swot: calculationsData[0].baseline_swot,
            competitors_avg_swot_score: calculationsData[0].competitors_avg_swot_score,
            community_swot_score: calculationsData[0].community_swot_score,
            process_id: calculationsData[0].process_id,
            inquiry_needed: calculationsData[0].inquiry_needed,
            needed_to_goal: calculationsData[0].needed_to_goal,
            Calculator: childRecords,
            sales_conversion: calculationsData[0].sales_conversion,
            op: 'added',
            disabled: false,
          },
        ],
      };
    } else {
      finalPayload = {
        data: [
          {
            id: currentConfig[0]?.id,
            apartment_id: currentConfig[0]?.apartment,
            description: 'Test Parent',
            current_occupancy: currentConfig[0]?.current_occupancy,
            goal_occupancy: currentConfig[0]?.goal_occupancy,
            months_to_goal: currentConfig[0]?.months_to_goal,
            lead_volume: currentConfig[0]?.lead_volume,
            move_outs: currentConfig[0]?.move_outs,
            status: currentConfig[0]?.status,
            baseline_delta: currentConfig[0]?.baseline_delta,
            baseline_swot: currentConfig[0]?.baseline_swot,
            competitors_avg_swot_score: currentConfig[0]?.competitors_avg_swot_score,
            community_swot_score: currentConfig[0]?.community_swot_score,
            process_id: currentConfig[0]?.process_id,
            inquiry_needed: currentConfig[0]?.inquiry_needed,
            needed_to_goal: currentConfig[0]?.needed_to_goal,
            Calculator: childRecords,
            sales_conversion: currentConfig[0]?.sales_conversion,
            op: 'edited',
            disabled: false,
          },
        ],
      };
    }

    setAddLoading(true);
    axios
      .post('/communities/Save_CommunityCalculator/', finalPayload)
      .then((res) => {
        setAddLoading(false);
        if (onSuccess) {
          onSuccess();
        }
        if (calculationsData[0].CommunityId) setCalculationsData([]);
        showMessage('success', 'Saved Successfully');
      })
      .catch((err) => {
        setAddLoading(false);
        showMessage('error', 'Something went wrong !');
        console.log('Error | Save_CommunityCalculator');
      });
    setIsEditable(false);
  };

  useEffect(() => {
    axios
      .get(`/communities/Get_UniqueList/`)
      .then((res: any) => {
        const livingType = res.data?.payload['LivingTypes'];
        const apartmentType = res.data?.payload['ApartmentTypes'];

        const modifiedLivingTypes: any = [
          ...livingType.map((service: any) => ({
            label: service.type,
            value: Math.floor(service.id),
          })),
        ];

        const modifiedApartmentTypes: any = [
          ...apartmentType.map((floorPlan: any) => ({
            label: floorPlan.type,
            value: Math.floor(floorPlan.id),
          })),
        ];

        setLivingOptions(modifiedLivingTypes);
        setApartmentOptions(modifiedApartmentTypes);
      })
      .catch((err) => {
        console.log('get Unique list error', err);
      });
  }, []);

  // table props

  const columns: ColumnsType<CalculationType> = [
    {
      title: <h2 style={{ fontSize: 18 }}>Living Type</h2>,
      dataIndex: 'LivingTypeName',
      key: 'LivingTypeName',
      width: 160,
      render: (value, record) => {
        return <span style={{ fontSize: 16 }}>{value}</span>;
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Apartment Type</h2>,
      dataIndex: 'ApartmentTypeName',
      key: 'ApartmentTypeName',
      width: 160,
      render: (value, record) => {
        return <span style={{ fontSize: 16 }}>{value}</span>;
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Avg Market Rate</h2>,
      dataIndex: 'mkt_avg_base_rent',
      key: 'mkt_avg_base_rent',
      width: 160,
      render: (value, record) => {
        return <span style={{ fontSize: 16 }}>$ {value}</span>;
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Swot Adjustment</h2>,
      dataIndex: 'first_adjustment',
      key: 'first_adjustment',
      width: 160,
      render: (value, record) => {
        return (
          <span style={{ fontSize: 16 }}>
            {/* $ {value}  */}

            {(parseFloat(record?.first_adjustment) - parseFloat(record?.mkt_avg_base_rent)).toFixed(2)}
          </span>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Sales Conversion Adjustment</h2>,
      dataIndex: 'second_adjustment',
      key: 'second_adjustment',
      width: 230,
      render: (value, record) => {
        return <span style={{ fontSize: 16 }}>$ {value}</span>;
      },
    },
    {
      title: <h2 style={{ fontSize: 22 }}>Final Price</h2>,
      dataIndex: 'final_recommended_rate',
      key: 'final_recommended_rate',
      width: 150,
      render: (value, record) => {
        return (
          <div style={{ fontSize: 22 }}>
            $
            {parseFloat(value || 0).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Description</h2>,
      dataIndex: 'description',
      key: 'description',
      width: 200,
      render: (value, record) => {
        return (
          <div style={{ fontSize: 20 }}>
            <Input
              type="text"
              value={value}
              onChange={(e) => handleTableChange(e.target.value, record, 'description')}
            />
          </div>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Action</h2>,
      key: 'operation',
      width: 50,
      align: 'center',
      render: (_, record) => {
        return <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDelete(record)} />;
      },
    },
  ];

  return (
    <div style={{ margin: '0 auto', padding: '0px 20px 100px 20px' }}>
      {contextHolder}

      <Table
        columns={columns}
        dataSource={calculationsData}
        scroll={{ x: 'max-content', y: '50vh' }}
        pagination={false}
        bordered={true}
        rowKey="ID"
      />

      <br />
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          htmlType="button"
          loading={add_loading}
          disabled={add_loading}
          onClick={handleSave}
          style={{ minWidth: 150 }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default GeneratedCalculationTable;
