import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../index';

type Communitytabledata = {
  id: number;
  living_type_id: any;
  community_fee: any;
  second_person_fee: any;
  pet_fee: any;
  respite_fee: any;
  additional_fee: any;
  incentive: any;
  op: 'added' | 'edited' | 'deleted' | '';
};

type CareTableData = {
  id: number;
  living_type_id: any;
  fees_type_id: any;
  fee_note: string;
  level_1: any;
  level_2: any;
  level_3: any;
  level_4: any;
  level_5: any;
  level_6: any;
  level_7: any;
  level_8: any;
  medication_management_fee: any;
  average_care_cost: any;
  op: 'added' | 'edited' | 'deleted' | '';
};
interface GlobalType {
  basic_info: any;
  cares_info: any;
  communityFees: any;
  careFees: any;
  competitors: any[];
}

const initialCaresInfo = [
  {
    id: 0,
    accommodation: 1,
    sq_ft: '',
    living_type: [
      {
        id: 1,
        value: '',
        type: 'Independent Living',
      },
      {
        id: 2,
        value: '',
        type: 'Assisted Living',
      },
      {
        id: 3,
        value: '',
        type: 'Memory Care',
      },
    ],
    op: 'added',
  },
];

const community_table_data: Communitytabledata[] = [
  {
    id: -1,
    living_type_id: '1',
    community_fee: '',
    second_person_fee: '',
    pet_fee: '',
    respite_fee: '',
    additional_fee: '',
    incentive: '',
    op: 'added',
  },
];
const care_fees_table: CareTableData[] = [
  {
    id: -1,
    living_type_id: 1,
    fees_type_id: 1,
    fee_note: '',
    level_1: '',
    level_2: '',
    level_3: '',
    level_4: '',
    level_5: '',
    level_6: '',
    level_7: '',
    level_8: '',
    medication_management_fee: '',
    average_care_cost: '',
    op: 'added',
  },
];

// Define the initial state using that type
const initialState: GlobalType = {
  basic_info: null,
  cares_info: initialCaresInfo,
  communityFees: community_table_data,
  careFees: care_fees_table,
  competitors: [],
};

export const communitySlice = createSlice({
  name: 'sub_community',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleStoreBasicInfo: (state, action: PayloadAction<any>) => {
      state.basic_info = action.payload;
    },
    handleStoreCareInfo: (state, action: PayloadAction<any>) => {
      state.cares_info = action.payload;
    },
    handleStoreCareFees: (state, action: PayloadAction<any>) => {
      state.careFees = action.payload;
    },
    handleStoreCommunityFees: (state, action: PayloadAction<any>) => {
      state.communityFees = action.payload;
    },
    handleStoreCompetitorsInfo: (state, action: PayloadAction<any>) => {
      state.competitors = action.payload;
    },
    handleClearCommunityData: (state) => {
      state.basic_info = null;
      state.cares_info = initialCaresInfo;
      state.communityFees = community_table_data;
      state.careFees = care_fees_table;
      state.competitors = [];
    },
  },
});

export const {
  handleStoreBasicInfo,
  handleStoreCareInfo,
  handleStoreCareFees,
  handleStoreCommunityFees,
  handleStoreCompetitorsInfo,
  handleClearCommunityData,
} = communitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default communitySlice.reducer;
