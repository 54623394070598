import React, { useState } from 'react';

import styled from 'styled-components';

import { FORM_MODE_SWITCH_TABS } from './constants';
import FormModeSwitchTabs from './FormModeSwitchTabs';

interface Props {
  editChild: React.ReactNode;
  historyChild: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Content = styled.div`
  width: 90%;
`;

const FormModeSwitch = ({ editChild, historyChild }: Props) => {
  const [selectedTab, setSelectedTab] = useState(FORM_MODE_SWITCH_TABS.EDIT);

  return (
    <Container>
      <FormModeSwitchTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <Content>
        {selectedTab === FORM_MODE_SWITCH_TABS.EDIT && editChild}
        {selectedTab === FORM_MODE_SWITCH_TABS.HISTORY && historyChild}
      </Content>
    </Container>
  );
};

export default FormModeSwitch;
