import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import EditSwotDataModal from 'components/View/modals/EditSwotDataModal/EditSwotDataModal';

import { DataInsertionBar } from './types';

type Props = {
  positions: DataInsertionBar[];
  data: any;
};

const BarContainer = styled.div`
  position: absolute;
  width: 156px;
  height: 20px;
  min-height: 20px;
  font-size: 10px;
  background-color: white;
  display: flex;
  justify-content: space-around;

  border: solid 1px var(--line-gray);
  border-left: 0px;

  span,
  a {
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-secondary);
  }

  a {
    color: var(--link-blue);
    cursor: pointer;
  }
`;

const BAR_TOP_OFFSET = -6;
const BAR_LEFT_OFFSET = 1;

const DataInsertionBars = ({ positions, data }: Props) => {
  const [showInsertSwotDataModal, setShowInsertSwotDataModal] = useState(false);
  const [competitorId, setCompetitorId] = useState<number | null>(null);

  const closeModal = useCallback(() => setShowInsertSwotDataModal(false), [setShowInsertSwotDataModal]);

  return (
    <>
      {positions.map((bar, index) => (
        <BarContainer
          key={index}
          style={{
            top: bar.y + BAR_TOP_OFFSET,
            left: bar.x + BAR_LEFT_OFFSET,
          }}
        >
          <div>
            <span>There is no data. </span>
            <a
              onClick={() => {
                setShowInsertSwotDataModal(true);
                setCompetitorId(data[bar.index]?.community_id);
              }}
            >
              Add some
            </a>
          </div>
        </BarContainer>
      ))}
      {showInsertSwotDataModal && (
        <EditSwotDataModal onClose={closeModal} open={showInsertSwotDataModal} competitorId={competitorId} />
      )}
    </>
  );
};

export default DataInsertionBars;
