import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import Map from './Map';
import { GetFullHomeCommunities } from '../../../apis/CommunityAPI';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import { useAppSelector } from '../../../store/hook';
import { processFullHomeCommunities } from '../../../utils/data/communities';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-y: auto;
  border-radius: 16px;
  height: 100%;
`;

export const MapWidgetContent = () => {
  const { managementCompanyId } = useParams();

  const { communityId } = useCommunityViewContext();
  const { filters = { competitors: [] } } = useWidgetContext();

  const { selectedClient, selectedCompany } = useAppSelector((state) => state.global);

  const [community, setCommunity] = useState<any>([]);
  const [competitors, setCompetitors] = useState<any>([]);

  const { isSuccess: communitiesLoaded } = useQuery({
    queryKey: [selectedClient, selectedCompany, managementCompanyId],
    queryFn: () =>
      GetFullHomeCommunities({
        client_id: selectedClient?.id || 0,
        company_list: selectedCompany ? [selectedCompany?.id] : [],
        management_company_list: managementCompanyId ? [parseInt(managementCompanyId)] : [],
        comm_list: [communityId],
        caretype_list: [],
      }),
    onSuccess: (data) => {
      const { updatedCommunities, updatedCompetitor } = processFullHomeCommunities(data);
      setCommunity(updatedCommunities);
      setCompetitors(updatedCompetitor);
    },
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const filteredCompetitors =
    filters.competitors.length == 0
      ? competitors
      : competitors.filter((competitor: any) => filters.competitors.some((value) => value == competitor.id));

  const getMapOptions = (maps: any) => {
    return {
      zoomControl: true,
      fullscreenControl: false,
      keyboardShortcuts: false,
    };
  };

  return (
    <>
      {communitiesLoaded && (
        <Container>
          <Map communities={community} competitors={filteredCompetitors} getMapOptions={getMapOptions} />
        </Container>
      )}
    </>
  );
};

export default MapWidgetContent;
