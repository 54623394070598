import { ACTOR_TYPE } from '../../../constants';

export const SELECTOR_ITEM_TYPES = {
  ALL: 'all',
  COMMUNITY: ACTOR_TYPE.COMMUNITY,
  COMPETITOR: ACTOR_TYPE.COMPETITOR,
  MANAGEMENT_COMPANY: ACTOR_TYPE.MANAGEMENT_COMPANY,
  COMPANY: ACTOR_TYPE.COMPANY,
};

export const SELECTOR_ITEM_FILTERS_LABEL = {
  [SELECTOR_ITEM_TYPES.ALL]: 'All',
  [SELECTOR_ITEM_TYPES.COMMUNITY]: 'My Communities',
  [SELECTOR_ITEM_TYPES.COMPETITOR]: 'Competitors',
  [SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY]: 'My Companies',
};

export const SELECTOR_ITEM_DESCRIPTION = {
  [SELECTOR_ITEM_TYPES.COMMUNITY]: 'My Community',
  [SELECTOR_ITEM_TYPES.COMPETITOR]: 'Community Competitor',
  [SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY]: 'Mgmt. Company',
  [SELECTOR_ITEM_TYPES.COMPANY]: 'Company',
};

export const COMMON_MENU_ITEMS_PATHS = {
  SELECTOR: '/selector',
  OVERVIEW: '/overview',
  SHOP_REQUESTS: '/shop-requests',
  GOOGLE_METRICS: '/google-metrics-reviews',
  USER_LOG_DASHBOARD: '/user-log-dashboard',
  SENTIMENT_ANALYSIS: '/sentiment-analysis',
  USER_LOG_REPORT: '/user-log-report',
  ONBOARDING: '/onboarding',
};
