import React, { useCallback, useEffect } from 'react';

import { getManagementCompanies } from 'apis/UserAPI';
import SelectInput from 'formComponents/SelectInput';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleSetSelectedManagementCompany } from 'store/slices/globalSlice';

import { useHeaderSelectorContext } from './HeaderSelectorContext';

const HeaderSelectorManagementCompany = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.global);
  const { managementCompanyList, selectedManagementCompany } = state;

  const { setCommunityList } = useHeaderSelectorContext();

  const handleSelect = (value: any) => {
    const selectedManagementCompany = managementCompanyList.find((item) => item.id === value);

    dispatch(handleSetSelectedManagementCompany(selectedManagementCompany));
    setCommunityList([]);
  };

  const handleClear = () => {
    dispatch(handleSetSelectedManagementCompany(undefined));
    setCommunityList([]);
  };

  const initialValue = selectedManagementCompany ? [selectedManagementCompany?.id] : [];

  const selectionDisabled = managementCompanyList?.length === 1 && !!selectedManagementCompany;

  const managementCompaniesOptions = managementCompanyList?.map((item: any) => ({
    label: item.management_company,
    value: item.id,
  }));

  return (
    <SelectInput
      initValue={initialValue}
      options={managementCompaniesOptions}
      placeholder="Management Company"
      disabled={selectionDisabled}
      onSelect={handleSelect}
      onClear={handleClear}
    />
  );
};

export default HeaderSelectorManagementCompany;
