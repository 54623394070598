import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { OccupancyType } from './constants';
import { useOccupanciesContext } from './OccupanciesContext';
import OccupanciesFormDataTable from './OccupanciesFormDataTable';
import { mapCommunityOccupancyDataToRequest, mapCommunityOccupancyResponseToData } from './utils';
import { STATUS } from '../../../constants';

const OccupanciesFormData = () => {
  const { communityId, showMessage } = useOccupanciesContext();

  const [occupancies, setOccupancies] = useState<OccupancyType[]>([]);
  const [dataLoading, setDataLoading] = React.useState(STATUS.IDLE);

  const getCommunityOccupancies = () => {
    setDataLoading(STATUS.LOADING);
    axios
      .get(`/communities/${communityId}/occupancies?active=1`)
      .then((response) => {
        const data = response.data;
        const formatted_data: OccupancyType[] = data?.map((item: any) => mapCommunityOccupancyResponseToData(item));

        setOccupancies(formatted_data);
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to fetch Community Occupancies');
        setDataLoading(STATUS.FAILURE);
      });
  };

  useEffect(() => {
    if (communityId) {
      getCommunityOccupancies();
    }
  }, [communityId]);

  const handleCreate = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityOccupancyDataToRequest(item);

    axios
      .post(`/communities/${communityId}/occupancies`, [data])
      .then((response) => {
        const newOccupancies = response.data.map((record: OccupancyType) =>
          mapCommunityOccupancyResponseToData(record)
        );
        setOccupancies([...occupancies, ...newOccupancies]);
        showMessage('success', 'Community Occupancy added successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to add Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityOccupancyDataToRequest(item);

    axios
      .put(`/communities/${communityId}/occupancies/${item.id}`, data)
      .then((response) => {
        const occupancy_index = occupancies.findIndex((occupancy) => occupancy.id === item.id);
        occupancies[occupancy_index] = mapCommunityOccupancyResponseToData(response.data);
        setOccupancies([...occupancies]);
        showMessage('success', 'Community Occupancy changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to change Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    axios
      .delete(`/communities/${communityId}/occupancies/${item.id}`)
      .then((response) => {
        const occupancy_index = occupancies.findIndex((occupancy) => occupancy.id === item.id);
        occupancies.splice(occupancy_index, 1);
        setOccupancies([...occupancies]);
        showMessage('success', 'Community Occupancy deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <OccupanciesFormDataTable
      loading={dataLoading === STATUS.LOADING}
      data={occupancies}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default OccupanciesFormData;
