import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import CloseIcon from 'common/Icons/CloseIcon';
import Button from 'components/lib/Button';

import OptionsCheckboxes from './ShopRequestModalCheckboxes';
import { useShopRequestModalContext } from './ShopRequestModalContext';
import ShopRequestModalDropdown from './ShopRequestModalDropdown';
import { MYSTERY_SHOP_MODAL_REQUEST_FROM } from '../constants';
import EditNoteButton from '../NoteButton/EditNoteButton';
import { ShopRequestCreationObject } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 10px;
  .note-button-container {
    opacity: 0;
  }
  .shop-request-add-note {
    opacity: 1;
  }
  :hover {
    .note-button-container {
      opacity: 1;
    }
  }
`;

const CloseButton = styled(Button)`
  padding: 0;
  height: 16px;
  width: 16px;
  border: 0;
  margin-left: 20px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  outline: none;
`;

type Props = {
  shopRequest: ShopRequestCreationObject;
};

const ShopRequestModalCommunityRow = ({ shopRequest }: Props) => {
  const {
    requestFrom,
    shopRequests,
    companyCompetitors,
    remainingCommunities,
    communityList,
    setShopRequests,
    deleteCommunity,
    replaceCommunity,
  } = useShopRequestModalContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const communities = requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.FURTHER ? communityList : companyCompetitors;
  const community = communities?.find((c: any) => c.id === shopRequest.communityId);

  const toggleShopRequestProperty = useCallback(
    (checked: boolean, property: 'independentLiving' | 'assistedLiving' | 'memoryCare') => {
      const updatedShopRequests = [...shopRequests];
      updatedShopRequests[updatedShopRequests.indexOf(shopRequest)][property] = checked;
      setShopRequests(updatedShopRequests);
    },
    [shopRequests, setShopRequests]
  );

  const toggleCommunityIndependentLiving = useCallback(
    (checked: boolean) => {
      toggleShopRequestProperty(checked, 'independentLiving');
    },
    [toggleShopRequestProperty]
  );

  const toggleCommunityAssistedLiving = useCallback(
    (checked: boolean) => {
      toggleShopRequestProperty(checked, 'assistedLiving');
    },
    [toggleShopRequestProperty]
  );

  const toggleCommunityMemoryCare = useCallback(
    (checked: boolean) => {
      toggleShopRequestProperty(checked, 'memoryCare');
    },
    [toggleShopRequestProperty]
  );

  const updateAdminNote = useCallback(
    (note: string) => {
      const modifiedShopRequests = [...shopRequests];
      modifiedShopRequests[shopRequests.indexOf(shopRequest)].adminNote = note;
      setShopRequests(modifiedShopRequests);
    },
    [shopRequest, shopRequests, setShopRequests]
  );

  return (
    <Container>
      <ShopRequestModalDropdown
        communities={[community, ...remainingCommunities]}
        onChange={(newCommunity: any) => replaceCommunity(community, newCommunity)}
        value={community?.id}
      />
      <OptionsCheckboxes
        independantLiving={!!shopRequest.independentLiving}
        assistedLiving={!!shopRequest.assistedLiving}
        memoryCare={!!shopRequest.memoryCare}
        toggleIndependentLiving={toggleCommunityIndependentLiving}
        toggleAssistedLiving={toggleCommunityAssistedLiving}
        toggleMemoryCare={toggleCommunityMemoryCare}
      />
      <EditNoteButton
        isModalOpen={isModalOpen}
        note={shopRequest.adminNote}
        setModalOpen={setModalOpen}
        onCloseModal={() => setModalOpen(false)}
        modifyNote={updateAdminNote}
      />
      <CloseButton onClick={() => deleteCommunity(community.id)}>
        <CloseIcon size={16} alt="add" />
      </CloseButton>
    </Container>
  );
};

export default ShopRequestModalCommunityRow;
