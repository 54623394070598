import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const { Title, Paragraph } = Typography;

const SelectorHeader = () => (
  <Container>
    <Typography style={{ textAlign: 'center' }}>
      <Title level={3}>Select User or Company to view</Title>
      <Paragraph type={'secondary' as const}>
        Narrow down communities overview by choosing related User or Company.
        <br />
        For users with dedicated positions within company some options might be selected automatically.
      </Paragraph>
    </Typography>
  </Container>
);

export default SelectorHeader;
