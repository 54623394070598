import React, { useContext } from 'react';

import OverviewCommunitiesTable from './OverviewCommunitiesTable';
import { OverviewContext } from './OverviewContext';
import { COMMUNITY_TYPE } from '../../constants';

const OverviewCommunities = () => {
  const { communities } = useContext(OverviewContext);

  return <OverviewCommunitiesTable type={COMMUNITY_TYPE.COMMUNITY} communities={communities} />;
};

export default OverviewCommunities;
