import React, { useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Modal, Tabs } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import ComparisonCarousal from './ComparisonCarousal';
import { COMMUNITY_NAME_COLUMN, COMPARISON_MODAL_WIDTH } from './constants';
import Button from '../../../components/lib/Button';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0px;
    padding: 0;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;
  padding: 20px 30px;
  border-bottom: 1px solid var(--line-gray);
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
`;

const ModalTitleContainer = styled.div`
  font-family: var(--font-bold);
  font-size: 20px;
`;

const Container = styled.div`
  display: flex;
  height: 700px;
`;

const Sidebar = styled.div`
  min-width: 220px;
  overflow-y: auto;
  border-right: solid 1px var(--line-gray);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px;
`;

const ContentFooter = styled.div`
  display: flex;
  margin-top: 70px;
  justify-content: end;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  gap: 10px;
`;

const Community = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  padding: 17px 30px;
  cursor: pointer;
  color: var(--text-primary);
  border-bottom: solid 1px var(--line-gray);

  &.selected {
    color: white;
    background-color: var(--button-blue);
  }
`;

const StyledTabs = styled(Tabs)`
  && {
    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      margin-left: 8px;
      padding: 0;
    }

    .ant-tabs-tab-btn {
      border: none;
      font-size: 14px;
      color: var(--text-secondary);
      text-shadow: none;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--community-marker-blue);
      font-weight: 500;
    }

    .ant-tabs-nav::before {
      border: none;
    }

    .ant-tabs-content-holder {
      display: none;
    }
  }
`;

const CloseButton = styled(Button)`
  width: 120px;
  height: 40px;
  padding: 10px 41.5px 10px 40.5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-gray-dark);
  border: solid 1px var(--line-gray);
  font-weight: 500;
  font-family: var(--font-bold);
`;

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedData: any;
  columns: any;
  data: any;
}

const ComparePhotosModal = ({ open, handleClose, selectedData, columns = [], data = [] }: Props) => {
  const [activeTab, setActiveTab] = useState('');
  const [activeCompetitor, setActiveCompetitor] = useState<any>(undefined);
  const [communityData, setCommunityData] = useState<any>(undefined);
  const [competitorsData, setCompetitorsData] = useState<any>([]);

  const handleTabChange = (key: any) => {
    setActiveTab(key);
  };

  useEffect(() => {
    setActiveTab(selectedData.column);

    const modifiedData = [...data];
    const adjustedCommunityData = modifiedData?.length ? modifiedData.shift() : {};
    const adjustedCompetitorData =
      modifiedData.find((row: any) => row?.communityName === selectedData?.record?.communityName) ||
      (modifiedData?.length && modifiedData[0]);

    setCompetitorsData(modifiedData);
    setCommunityData(adjustedCommunityData);
    setActiveCompetitor(adjustedCompetitorData);
  }, [open]);

  const tabItems = columns
    .filter((col: any) => col.dataIndex != COMMUNITY_NAME_COLUMN.FIELD)
    .map((col: any) => ({
      label: col.dataIndex,
      key: col.dataIndex,
    }));

  const communityImages = (communityData && communityData[activeTab]?.map((url: any) => url)) || [];
  const competitorImages = (activeCompetitor && activeCompetitor[activeTab]?.map((url: any) => url)) || [];

  return (
    <StyledModal
      centered
      width={COMPARISON_MODAL_WIDTH}
      open={open}
      closable={false}
      onOk={handleClose}
      onCancel={handleClose}
      footer={false}
    >
      <Header>
        <HeaderInfo>
          <ModalTitleContainer>Compare Photos</ModalTitleContainer>
          <StyledTabs onChange={handleTabChange} items={tabItems} activeKey={activeTab} />
        </HeaderInfo>
        <CloseOutlined onClick={handleClose} style={{ fontSize: '24px' }} />
      </Header>

      <Container>
        <Sidebar>
          {competitorsData.map((entry: any) => (
            <Community
              className={classNames({
                selected: entry?.communityName === activeCompetitor?.communityName,
              })}
              key={entry.communityName}
              onClick={() => setActiveCompetitor(entry)}
            >
              {entry.communityName}
            </Community>
          ))}
        </Sidebar>
        {data.length > 0 && (
          <Content>
            <CarouselContainer>
              <ComparisonCarousal title={activeCompetitor?.communityName} images={competitorImages} />
              <ComparisonCarousal title={`${communityData?.communityName} (My Community)`} images={communityImages} />
            </CarouselContainer>
            <ContentFooter>
              <CloseButton onClick={handleClose}>Close</CloseButton>
            </ContentFooter>
          </Content>
        )}
      </Container>
    </StyledModal>
  );
};

export default ComparePhotosModal;
