import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import OverviewHeaderBreadcrumbs from './OverviewHeaderBreadcrumbs';
import Header from '../../common/Header';
import AddCompetitor from '../../pages/AddCompetitor';
import { useAppSelector } from '../../store/hook';
import { useRootContext } from '../layout/RootContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OverviewAddCompetitor = () => {
  const navigate = useNavigate();
  const { refreshCommunities } = useRootContext();
  const { selectedManagementCompany } = useAppSelector((state) => state.global);

  const handleAddCompetitor = (competitor: any, community: any) => {
    refreshCommunities();
    navigate(`/overview/${selectedManagementCompany?.id}`);
  };

  return (
    <Container>
      <Header title={selectedManagementCompany?.management_company || ''} breadcrumbs={<OverviewHeaderBreadcrumbs />} />
      <AddCompetitor onComplete={handleAddCompetitor} />
    </Container>
  );
};

export default OverviewAddCompetitor;
