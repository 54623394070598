import React, { useEffect, useState } from 'react';

import { Button, Tabs } from 'antd';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreCareInfo } from 'store/slices/communitySlice';

import AccommodationFormOnboardingTable from './AccommodationFormOnboardingTable';
import { AccommodationDataType, CaresInfoDataType } from './constants';
import { mapAccommodationToCareInfo, mapCareInfoToAccommodation } from './util';

const Container = styled.div`
  width: 90%;
`;

const AccommodationFormOnboardingData = () => {
  const [selectedTab, setSelectedTab] = useState('');
  const dispatch = useAppDispatch();
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const cares_info: CaresInfoDataType[] = useAppSelector((state) => state.community.cares_info);

  const selectedLivingType: any = livingTypes.find(
    (lt: any, idx: number) => lt.type == (selectedTab || livingTypes[0].type)
  );

  const handleAddAccommodationRecord = (item: Partial<AccommodationDataType>) => {
    const parsedItem = mapAccommodationToCareInfo(item, [selectedLivingType]);
    const jsData = JSON.parse(JSON.stringify(cares_info));
    const cp = [...jsData];
    cp.push(parsedItem);
    dispatch(handleStoreCareInfo(cp));
  };

  const handleUpdateAccommodationRecord = (item: Partial<AccommodationDataType>) => {
    const parsedItem = mapAccommodationToCareInfo(item, livingTypes);
    const communityCareInfoIndex = cares_info.findIndex((careInfo) => careInfo.key === item.key);
    const jsData = JSON.parse(JSON.stringify(cares_info));
    const cp = [...jsData];
    cp[communityCareInfoIndex] = parsedItem;
    dispatch(handleStoreCareInfo(cp));
  };

  const handleDeleteAccommodationRecord = (item: Partial<AccommodationDataType>) => {
    const communityCareInfoIndex = cares_info.findIndex((careInfo) => careInfo.key === item.key);
    const jsData = JSON.parse(JSON.stringify(cares_info));
    const cp = [...jsData];
    cp.splice(communityCareInfoIndex, 1);
    dispatch(handleStoreCareInfo(cp));
  };

  const transformedCaresInfo: AccommodationDataType[] = cares_info.map((careInfo) =>
    mapCareInfoToAccommodation(careInfo)
  );

  const selectedLivingTypeData = transformedCaresInfo.filter(
    (item) => item.livingTypeId == selectedLivingType.livingtype_order
  );

  const items = livingTypes.map((item: any) => ({
    label: (
      <Button
        type="text"
        className={selectedTab == item.type ? 'p_healing_active_tab' : 'btnStyle'}
        shape="round"
        style={{ fontSize: 14 }}
        onClick={() => setSelectedTab(item.type)}
      >
        {item.type}
      </Button>
    ),
    key: item.type,
    destroyInactiveTabPane: true,
    children: (
      <AccommodationFormOnboardingTable
        selectedTab={selectedTab}
        data={selectedLivingTypeData}
        onCreate={handleAddAccommodationRecord}
        onUpdate={handleUpdateAccommodationRecord}
        onDelete={handleDeleteAccommodationRecord}
      />
    ),
    style: {
      padding: 0,
    },
  }));

  useEffect(() => {
    setSelectedTab(livingTypes[0]?.type || '');
  }, [livingTypes]);

  return (
    <Container>
      <Tabs activeKey={selectedTab} tabPosition="top" tabBarGutter={10} size="small" items={items} />
    </Container>
  );
};

export default AccommodationFormOnboardingData;
