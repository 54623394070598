import React, { useEffect, useState } from 'react';

import { Form, Input } from 'antd';
import styled from 'styled-components';

import { useUserOnboardingContext } from './UserOnboardingContext';
import { useDebounce } from '../../utils/hooks/useDebounce';

const Container = styled.div`
  margin-bottom: 10px;
`;

const UserOnboardingFilter = () => {
  const { setSearch } = useUserOnboardingContext();

  const [keyword, setKeyword] = useState<string>('');
  const debouncedKeyword = useDebounce(keyword);

  useEffect(() => {
    setSearch(debouncedKeyword);
  }, [debouncedKeyword]);

  const handleChange = (values: any) => {
    setKeyword(values.target.value);
  };

  return (
    <Container>
      <Input onChange={handleChange} placeholder="Search users by name, email, username..." />
    </Container>
  );
};

export default UserOnboardingFilter;
