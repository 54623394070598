import React from 'react';

import { Button } from 'antd';
import styled from 'styled-components';

import { SWOT_CATEGORIES } from 'common/Widgets/constants';

import { useEditSwotDataModalContext } from './EditSwotDataModalContext';
import SubmitSwotScoresButton from './SubmitSwotScoresButton';

const StyledFooter = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: 142px;
  border-radius: 4px;
  margin-left: 10px;
`;

const EditSwotDataModalFooter = () => {
  const { stepNumber, setStepNumber, isEditMode } = useEditSwotDataModalContext();
  const showNextButton = isEditMode || (!isEditMode && stepNumber < SWOT_CATEGORIES.length);

  return (
    <StyledFooter>
      <StyledButton
        key="cancel"
        type="default"
        onClick={() => setStepNumber(stepNumber - 1)}
        disabled={stepNumber === 1}
      >
        Back
      </StyledButton>
      <div>
        {showNextButton && (
          <StyledButton
            key="next"
            type="primary"
            onClick={() => setStepNumber(stepNumber + 1)}
            disabled={stepNumber === SWOT_CATEGORIES.length}
          >
            Next
          </StyledButton>
        )}
        <SubmitSwotScoresButton />
      </div>
    </StyledFooter>
  );
};

export default EditSwotDataModalFooter;
