import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Typography } from 'antd';
import styled from 'styled-components';

import { fetchShopRequestTabsMetrics } from 'apis/ShopRequestAPI';

import { SHOP_REQUEST_DUE_DATE_RANGES } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import { getShopRequestListingPayload } from '../utils';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  padding: 0 0 0 30px;
`;

const Tab = styled.div`
  margin-right: 30px;
  color: var(--gray-dark);
  font-size: 14px;
  cursor: pointer;
  padding-bottom: 11px;

  &.active {
    color: black;
    border-bottom: solid 1px black;
  }
`;

const RequestsCount = styled(Typography.Text)`
  font-size: 10px;
  margin-left: 4px;
  color: inherit;
`;

const ShopRequestsTabs = () => {
  const { filters, setFilters } = useShopRequestsDataContext();

  const onChangeDueDateRange = (range: number) => {
    const updatedFilters = { ...filters, dueDateRange: range };
    if (range !== SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS) {
      updatedFilters.dueDate = undefined;
    }
    setFilters(updatedFilters);
  };

  const { data } = useQuery({
    queryKey: ['shop-requests-tabs-metrics', filters],
    queryFn: () => {
      const selectedFilters = { ...getShopRequestListingPayload(filters, undefined), due_date_range: undefined };
      return fetchShopRequestTabsMetrics(selectedFilters);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <Container>
      <Tab
        className={filters.dueDateRange === SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS ? 'active' : ''}
        onClick={() => onChangeDueDateRange(SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS)}
      >
        All requests <RequestsCount>{data?.all_requests || 0}</RequestsCount>
      </Tab>
      <Tab
        className={filters.dueDateRange === SHOP_REQUEST_DUE_DATE_RANGES.DUE_THIS_WEEK ? 'active' : ''}
        onClick={() => onChangeDueDateRange(SHOP_REQUEST_DUE_DATE_RANGES.DUE_THIS_WEEK)}
      >
        Due This Week <RequestsCount>{data?.due_this_week || 0}</RequestsCount>
      </Tab>
      <Tab
        className={filters.dueDateRange === SHOP_REQUEST_DUE_DATE_RANGES.DUE_NEXT_WEEK ? 'active' : ''}
        onClick={() => onChangeDueDateRange(SHOP_REQUEST_DUE_DATE_RANGES.DUE_NEXT_WEEK)}
      >
        Due Next Week <RequestsCount>{data?.due_next_week || 0}</RequestsCount>
      </Tab>
      <Tab
        className={filters.dueDateRange === SHOP_REQUEST_DUE_DATE_RANGES.DUE_IN_30_DAYS ? 'active' : ''}
        onClick={() => onChangeDueDateRange(SHOP_REQUEST_DUE_DATE_RANGES.DUE_IN_30_DAYS)}
      >
        Due in 30 days <RequestsCount>{data?.due_in_30_days || 0}</RequestsCount>
      </Tab>
      <Tab
        className={filters.dueDateRange === SHOP_REQUEST_DUE_DATE_RANGES.UPCOMING ? 'active' : ''}
        onClick={() => onChangeDueDateRange(SHOP_REQUEST_DUE_DATE_RANGES.UPCOMING)}
      >
        Upcoming <RequestsCount>{data?.upcoming || 0}</RequestsCount>
      </Tab>
      <Tab
        className={filters.dueDateRange === SHOP_REQUEST_DUE_DATE_RANGES.OVERDUE ? 'active' : ''}
        onClick={() => onChangeDueDateRange(SHOP_REQUEST_DUE_DATE_RANGES.OVERDUE)}
      >
        Overdue <RequestsCount>{data?.overdue || 0}</RequestsCount>
      </Tab>
    </Container>
  );
};

export default ShopRequestsTabs;
