import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarMenuItem from './SidebarMenuItem';
import { isIncentivesCorporate, isIncentivesSalesperson } from '../../../helpers/permissions';

const OtherLaber = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 30px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.6);
`;

const SidebarMenuSettings = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { selectedManagementCompany, selectedCompany } = useAppSelector((state) => state.global);

  const showIncentives =
    currentUser && selectedCompany && (isIncentivesCorporate(currentUser) || isIncentivesSalesperson(currentUser));
  const showSettings = currentUser?.feature_flags?.show_beta_settings;

  if (!showIncentives && !showIncentives) {
    return null;
  }

  return (
    <div>
      <OtherLaber>OTHER</OtherLaber>
      {showIncentives && (
        <SidebarMenuItem
          to={selectedManagementCompany ? `/incentives/${selectedManagementCompany?.id}` : '/incentives'}
          label={'Incentives'}
        />
      )}
      {showSettings && <SidebarMenuItem to={`/preferences/${selectedManagementCompany?.id}`} label={'Settings'} />}
    </div>
  );
};

export default SidebarMenuSettings;
