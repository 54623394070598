import React, { useEffect } from 'react';

import { WIDGET_TYPES } from 'common/Widgets/constants';

type Props = {
  chartRef: any;
  type: number;
  communityId: number;
  onBarClickCallback: (competitorId: number) => void;
};

export const useClickableAntdBars = ({ chartRef, type, communityId, onBarClickCallback }: Props) => {
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.on('element:click', (evt: any) => {
        if (type === WIDGET_TYPES.SWOT_SCORE) {
          if (evt.data.data.community_id === communityId) {
            // User can only edit the competitors SWOT scores - not the community itself
            return;
          }

          onBarClickCallback(evt.data.data.community_id);
        }
      });
    }
  }, [chartRef.current, onBarClickCallback, communityId, type]);
};
