import React, { useContext } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { SELECTOR_ITEM_FILTERS_LABEL } from './constants';
import { SidebarContext } from './SidebarContext';
import Button from '../../lib/Button';

const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 12px;
  height: 26px;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-gray-dark);
  padding: 0px 10px;
`;

interface Props {
  type: string;
}

const SidebarSelectorDropdownFilterButton = ({ type }: Props) => {
  const { filterType, setFilterType } = useContext(SidebarContext);

  return (
    <StyledButton
      type={filterType === type ? 'primary' : 'default'}
      className={classNames({ active: false })}
      onClick={() => setFilterType(type)}
    >
      {SELECTOR_ITEM_FILTERS_LABEL[type]}
    </StyledButton>
  );
};

export default SidebarSelectorDropdownFilterButton;
