import React from 'react';

import { Form } from 'antd';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { Divider } from 'components/lib/Divider';

import { PROPOSAL_DURATION } from './constants';
import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import Checkbox from '../../lib/Checkbox';
import Select from '../../lib/Select';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding: 18px 0px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  margin-top: 20px;
`;

const LabelContainer = styled.div`
  display: flex;
  width: 88px;
  gap: 10px;
  align-items: center;

  span {
    display: flex;
    font-family: var(--font-bold);
    font-size: 14px;
    color: var(--text-primary);
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ExpirationContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 12px;
  font-family: var(--font-regular);

  .expiration-label {
    color: var(--text-secondary);
  }

  .expiration-date {
    color: var(--text-primary);
  }
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-bottom: 10px;
`;

const IncentivesProposalModalProposalTime = () => {
  const { incentive, readOnly } = useIncentivesProposalModalContext();

  const timeLimitOptions = Object.values(PROPOSAL_DURATION)
    .filter(
      (value: number) =>
        (!incentive?.end_at && value != PROPOSAL_DURATION.EXPIRATION_DATE) ||
        (incentive?.end_at && value <= dayjs(incentive.end_at).diff(dayjs(), 'day'))
    )
    .map((value: number) => ({
      label:
        value === PROPOSAL_DURATION.EXPIRATION_DATE
          ? 'Incentive expiration date'
          : `${value} ${value > 1 ? 'days' : 'day'} starting today`,
      value,
    }));

  return (
    <Container>
      <Title>Proposal Time Limit</Title>
      <Divider />
      <Row>
        <LabelContainer style={{ width: 110 }}>
          <Form.Item noStyle name={['time', 'limit']} valuePropName="checked">
            <Checkbox className={'green'} />
          </Form.Item>
          <span>Limit to</span>
        </LabelContainer>
        <Form.Item style={{ width: '100%' }} shouldUpdate>
          {({ getFieldValue }) => (
            <FormContent>
              <Form.Item name={['time', 'duration']}>
                <Select
                  disabled={!getFieldValue(['time', 'limit']) || readOnly}
                  style={{ width: 205, height: 36 }}
                  className={'rectangular'}
                  options={timeLimitOptions}
                />
              </Form.Item>
              {getFieldValue(['time', 'limit']) && incentive?.end_at && (
                <ExpirationContainer>
                  <span className={'expiration-label'}>Overall incentive expiration date</span>
                  <span className={'expiration-date'}>{format(new Date(incentive?.end_at), 'MMM dd, yyyy')}</span>
                </ExpirationContainer>
              )}
            </FormContent>
          )}
        </Form.Item>
      </Row>
    </Container>
  );
};

export default IncentivesProposalModalProposalTime;
