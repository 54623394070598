import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { Popover } from 'antd';
import styled from 'styled-components';

import { updateShopRequest } from 'apis/ShopRequestAPI';

import PopoverContent from './NotePopoverContent';
import { SHOP_REQUEST_MODAL_STATUS } from '../constants';
import NoteModal from '../NoteModal/NoteModal';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import { MysteryShopRequestInbound, MysteryShopRequestOutbound } from '../types';

const NoteImage = styled.span`
  border: solid 1px black;
  border-radius: 30px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: auto;
`;

type Props = { shopRequest: MysteryShopRequestInbound };

const NotePopover = ({ shopRequest }: Props) => {
  const queryClient = useQueryClient();
  const [modalStatus, setModalStatus] = useState(SHOP_REQUEST_MODAL_STATUS.CLOSED);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { filters, ordering } = useShopRequestsDataContext();

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const togglePopover = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const updateNote = async (note: string) => {
    setIsLoading(true);
    const payload: Partial<MysteryShopRequestOutbound> = {};
    if (modalStatus === SHOP_REQUEST_MODAL_STATUS.SHOPPER_NOTE) {
      payload.shopper_note = note;
    } else if (modalStatus === SHOP_REQUEST_MODAL_STATUS.ADMIN_NOTE) {
      payload.admin_note = note;
    }

    await updateShopRequest(shopRequest.id, payload).then(() => {
      queryClient.invalidateQueries(['shopRequests', filters, ordering]);
      setIsLoading(false);
      setModalStatus(SHOP_REQUEST_MODAL_STATUS.CLOSED);
    });
  };

  return (
    <>
      <Popover
        placement="bottom"
        content={
          <PopoverContent
            shopperNote={shopRequest.shopperNote!}
            adminNote={shopRequest.adminNote}
            shopperId={shopRequest.shopper?.userId}
            editAdminNote={() => {
              setModalStatus(SHOP_REQUEST_MODAL_STATUS.ADMIN_NOTE);
              togglePopover();
            }}
            editShopperNote={() => {
              setModalStatus(SHOP_REQUEST_MODAL_STATUS.SHOPPER_NOTE);
              togglePopover();
            }}
          />
        }
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        <NoteImage>
          <img src="/icons/edit-note-black.svg" height={12} width={12} alt="edit note" />
        </NoteImage>
      </Popover>
      {modalStatus !== SHOP_REQUEST_MODAL_STATUS.CLOSED && (
        <NoteModal
          title={'Edit Note'}
          note={modalStatus === SHOP_REQUEST_MODAL_STATUS.ADMIN_NOTE ? shopRequest.adminNote : shopRequest.shopperNote}
          isModalOpen={modalStatus !== SHOP_REQUEST_MODAL_STATUS.CLOSED}
          okButtonDisabled={isLoading}
          closeModal={() => setModalStatus(SHOP_REQUEST_MODAL_STATUS.CLOSED)}
          modifyNote={updateNote}
        />
      )}
    </>
  );
};

export default NotePopover;
