import React from 'react';

import { Select } from 'antd';

interface Props {
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value?: any;
  options: any;
  optionRender: any;
  onSearch: (value: string) => void;
  onSelect: (value: any) => void;
  onClear: () => void;
}

const DropdownSearch = ({
  loading = false,
  placeholder,
  disabled,
  value,
  options,
  optionRender,
  onSearch,
  onSelect,
  onClear,
}: Props) => (
  <Select
    allowClear
    filterOption={false}
    disabled={disabled}
    loading={loading}
    placeholder={placeholder}
    value={value}
    showSearch
    options={options}
    optionRender={optionRender}
    onSearch={onSearch}
    onSelect={onSelect}
    onClear={onClear}
  />
);

export default DropdownSearch;
