import React from 'react';

import EditSwotDataModal from 'components/View/modals/EditSwotDataModal/EditSwotDataModal';

import { WIDGET_TYPES } from '../constants';

type Props = {
  widgetType: number;
  onClose: () => void;
  open: boolean;
  competitorId: number | null;
};

/**
 * A generic component which handles edit data modals for all widget types.
 */
const EditDataModal = ({ widgetType, onClose, competitorId, open }: Props) => {
  switch (widgetType) {
    case WIDGET_TYPES.SWOT_SCORE:
      return <EditSwotDataModal open={open} onClose={onClose} competitorId={competitorId} />;
    default:
      return null;
  }
};

export default EditDataModal;
