import {
  COMMUNITY_DIGEST_STATUS,
  COMPETITOR_INCENTIVE_UPDATES,
  REMINDER_TO_UPDATE_PRICING_STATUS,
} from 'components/Settings/constants';

import { groupByApartmentId } from '../../components/layout/Header/utils';
import { LivingInfoRateDateType } from '../../components/Overview/types';
import { COMMUNITY_TYPE } from '../../constants';

export const processFullHomeCommunities = (data: any) => {
  const google_rating: any[] = data?.Community_Competitor_Google_Values[0];
  const google_reviews: any[] = data?.Community_Competitor_Google_Values[1];

  const communities = data.Community.map((ele: any, idx: number) => ({
    ...ele,
    index: idx + 1,
  }));

  const communitiesOptions = communities?.map((ele: any) => ({
    label: ele?.apartmentname,
    value: ele?.Id,
  }));

  const updatedCompetitor = data.Competitor?.map((cm: any) => {
    const avg_value = data?.Competitor_avg?.find((cm_avg: any) => cm_avg?.apartment_id === cm?.Id);

    return {
      id: cm?.Id,
      community: cm,
      ...cm,
      apartmenttype: cm?.apartmenttype,
      Avg_base_rent: avg_value?.Avg_base_rent || 0,
      Avg_sq_footage: avg_value?.Avg_sq_footage || 0,
      Avg_price_per_sq: avg_value?.Avg_price_per_sq || 0,
      Avg_google_rating: avg_value?.Avg_google_rating || 0,
      Sum_google_reviews: avg_value?.Sum_google_reviews || 0,

      market_Avg_base_rent: 0,
      market_Avg_sq_footage: 0,
      market_Avg_price_per_sq: 0,
      market_Avg_google_rating: 0,
      market_Sum_google_reviews: 0,
    };
  });
  const grup_apartment = groupByApartmentId(updatedCompetitor, 'parent_id');
  const updatedCommunities = data.Community?.map((cm: any) => {
    const avg_value = data?.community_avg?.find((cm_avg: any) => cm_avg?.apartment_id === cm?.Id);
    const market_value = data?.Community_Competitor_avg?.find((cm_avg: any) => cm_avg?.community_id === cm?.Id);

    return {
      id: cm?.Id,
      community: cm,
      ...cm,
      competitors: grup_apartment[cm.Id] ? grup_apartment[cm.Id] : [],
      cmp_google_rating: google_rating.find((el) => el?.parent_id === cm?.Id),
      cmp_google_reviews: google_reviews.find((el) => el?.parent_id === cm?.Id),
      apartmenttype: cm?.apartmenttype,
      Avg_base_rent: avg_value?.Avg_base_rent || 0,
      Avg_sq_footage: avg_value?.Avg_sq_footage || 0,
      Avg_price_per_sq: avg_value?.Avg_price_per_sq || 0,
      Avg_google_rating: avg_value?.Avg_google_rating || 0,
      Sum_google_reviews: avg_value?.Sum_google_reviews || 0,

      market_Avg_base_rent: market_value?.Avg_base_rent || 0,
      market_Avg_sq_footage: market_value?.Avg_sq_footage || 0,
      market_Avg_price_per_sq: market_value?.Avg_price_per_sq || 0,
      market_Avg_google_rating: market_value?.Avg_google_rating || 0,
      market_Sum_google_reviews: market_value?.Sum_google_reviews || 0,
    };
  });

  return {
    communities: communitiesOptions,
    updatedCommunities,
    updatedCompetitor,
  };
};

export const processUserCommunitiesAverages = (response: any) => {
  const data = response?.data.payload;

  const { Avg_Values, Avg_Values_mrkt } = data?.Community_Summary[0];
  const averageCommunityRates = Avg_Values;
  const averageMarketRates = Avg_Values_mrkt;

  const community = averageCommunityRates?.find((el: any) => el?.apartmenttype === COMMUNITY_TYPE.COMMUNITY);
  const competitor = averageMarketRates?.find((el: any) => el?.Type === COMMUNITY_TYPE.COMPETITOR);

  const averageCommunityLivingTypeRatesDetails = data?.Community_Detail[0]?.Avg_Values;
  const averageMarketLivingTypeRatesDetails = data?.Community_Detail[0]?.Avg_Values_mrkt;

  const averageLivingTypeRates = averageCommunityLivingTypeRatesDetails.map((livingType: LivingInfoRateDateType) => {
    const market_rent = averageMarketLivingTypeRatesDetails.find(
      (item: LivingInfoRateDateType) => item.LivingType === livingType.LivingType
    );
    return {
      type: livingType.LivingType,
      rate: livingType.Avg_base_rent,
      marketRate: market_rent?.Avg_base_rent,
    };
  });

  return {
    averageRate: {
      community: community?.Avg_base_rent,
      market: competitor?.Avg_base_rent,
    },
    averageLivingTypeRates,
    averagePricePerSquareFoot: {
      community: community?.Avg_price_per_sq,
      market: competitor?.Avg_price_per_sq,
    },
  };
};

/**
 * This is a temporary function which HARDCODES settings status values in Community objects.
 * Remove it once we have settings implemented on backend.
 */
export const processCommunitiesSettings = (data: any[]) => {
  return data.map((el: any) => ({
    ...el,
    community_digest_email_status: COMMUNITY_DIGEST_STATUS.statusElements.MONTHLY.value,
    competitor_incentive_updates_status: COMPETITOR_INCENTIVE_UPDATES.statusElements.WEEKLY_SUMMARY.value,
    reminder_to_update_pricing_status: REMINDER_TO_UPDATE_PRICING_STATUS.statusElements.EVERY_6_months.value,
    market_rate_comparison_updates_status: {
      enabled: true,
      overMarketRate: {
        enabled: true,
        value: 5,
      },
      underMarketRate: {
        enabled: false,
        value: 5,
      },
    },
  }));
};

export const DEFAULT_SWOT_SCORES = {
  aa_apartment_size: 3,
  aa_apartment_flooring: 3,
  aa_kitchen_cabinets_countertops: 3,
  aa_apartment_views: 3,
  aa_apartment_lighting: 3,
  aa_washer_dryer_laundry: 3,
  aa_cable_internet_access: 3,
  ca_fitness_exercise_space: 3,
  ca_indoor_amenities: 3,
  ca_laundry_room: 3,
  ca_lobby_hallway_aesthetics: 3,
  ca_onsite_physical_therapy: 3,
  ca_outdoor_amenities: 3,
  ca_pet_friendliness: 3,
  ca_serene_outdoor_space: 3,
  de_service_experience: 3,
  de_multiple_dining_venues: 3,
  de_menu_options: 3,
  de_food_quality: 3,
  de_dining_hours: 3,
  cs_breadth_of_clinical_services: 3,
  cs_nursing_coverage: 3,
  cs_state_survey_results: 3,
  cs_care_staff_ratio: 3,
  ap_diversity_of_programming: 3,
  ap_outings: 3,
  ap_unique_programming: 3,
  ap_transportation_services: 3,
  ap_physical_exercise_programming: 3,
  ap_spiritual_religious_programming: 3,
  reputation_general: 3,
  reputation_google_rating: 3,
  reputation_caring_com: 3,
  reputation_place_for_mom_rating: 3,
  community_feel_first_impressions: 3,
  community_feel_front_desk_experience: 3,
  community_feel_associate_engagement: 3,
  community_feel_associate_tenure: 3,
  location_healthcare_services: 3,
  location_shopping_options: 3,
  po_competitive_pricing: 3,
  po_access_to_government_funding: 3,
  po_care_pricing: 3,
  internet_seo_ranking: 3,
  internet_website_design: 3,
  internet_website_content: 3,
  internet_social_media: 3,
  internet_email_marketing_campaigns: 3,
  internet_community_pictures_videos: 3,
  internet_access_to_virtual_tour: 3,
  internet_website_navigation: 3,
  cs_levels_of_care: 3,
  community_feel_associate_knowledge: 3,
  po_reaccuring_fees: 3,
  internet_traditional_marketing: 3,
  apartment_amenities_score: 3,
  care_services_score: 3,
  community_amenities_score: 3,
  community_feel_score: 3,
  dining_experience_score: 3,
  internet_social_media_score: 3,
  life_enrichment_score: 3,
  location_score: 3,
  pricing_score: 3,
  reputation_score: 3,
  overall_score: 3,
  apartment_amenities_note: null,
  care_services_note: null,
  community_amenities_note: null,
  community_feel_note: null,
  dining_experience_note: null,
  internet_social_media_note: null,
  life_enrichment_note: null,
  location_note: null,
  pricing_note: null,
  reputation_note: null,
};

export const getSwotScoresOutboundPayload = ({
  companyId,
  competitorId,
  competitorName,
  managementCompanyId,
  communityId,
  communityName,
  emailAddress,
  userId,
  id,
}: {
  companyId: number;
  competitorId: number;
  competitorName: string;
  managementCompanyId: number;
  communityId: number;
  communityName: string;
  emailAddress: string;
  userId: number;
  id: number;
}) => {
  return {
    company_id: companyId,
    company: companyId,
    competitor_id: competitorId,
    competitor_name: competitorName,
    competitor: competitorId,
    email_address: emailAddress,
    management_company: managementCompanyId,
    my_community_id: communityId,
    my_community: communityId,
    my_community_name: communityName,
    created_by: userId,
    updated_by: userId,
    id,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    timestamp: new Date().toISOString(),
  };
};
