import React from 'react';

import { Divider, Typography } from 'antd';
import styled from 'styled-components';

import { useDashboardReportsContext } from './DashboardReportsContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PricingAssistantLogo = styled.div`
  display: flex;
  align-items: center;
`;

const PricingAssistantTitle = styled(Typography.Title)`
  &&& {
    position: relative;
    left: -14px;
    margin: 0;
    line-height: 1;
    letter-spacing: 1pt;
    text-align: right;
  }
`;

const DashboardReportsExportHeader = () => {
  const { communityName } = useDashboardReportsContext();

  return (
    <Container>
      <ContainerContent>
        <PricingAssistantLogo>
          <img
            src="/logo.svg"
            alt="TalkFurther Pricing Assistant"
            style={{
              width: 50,
              height: 50,
            }}
          />
          <PricingAssistantTitle level={4}>
            Pricing
            <br />
            Assistant
          </PricingAssistantTitle>
        </PricingAssistantLogo>
        <Typography.Title level={1}>Competitive Market Analysis for {communityName}</Typography.Title>
        <PricingAssistantLogo>
          <img
            src="/logo.svg"
            alt="TalkFurther Pricing Assistant"
            style={{
              width: 50,
              height: 50,
            }}
          />
          <PricingAssistantTitle level={4}>
            Pricing
            <br />
            Assistant
          </PricingAssistantTitle>
        </PricingAssistantLogo>
      </ContainerContent>
      <Divider />
    </Container>
  );
};

export default DashboardReportsExportHeader;
