import React, { useCallback, useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select, Tooltip } from 'antd';

import axios from 'config/axiosPrivate';
import LocationSearchInput from 'formComponents/LocationSearchInput';
import { CITY_OPTIONS, STATES } from 'helpers/constants';
import { useAppSelector } from 'store/hook';

import { getManagementCompanies } from '../../apis/UserAPI';
import { PAGINATION_PAGE_SIZE } from '../../constants';

// import AccessibilityTable from './AccessibilityTable';

interface Props {
  communityId: number;
  initialData: any;
  setData: (data: any) => void;
  getCommunityInfo?: any;
}

const BasicInfoForm = ({ communityId, initialData, setData, getCommunityInfo }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const paymentTypes = useAppSelector((state) => state.global.PaymentTypes);
  const business_models = useAppSelector((state) => state.global.business_models);
  const accessibilities_data = useAppSelector((state) => state.community.accessibility);
  const { selectedCompany, selectedManagementCompany } = useAppSelector((state) => state.global);

  const [unsaved, setUnSaved] = React.useState<boolean>(false);
  const [location, setLocation] = React.useState<any>(null);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [managementCompaniesOptions, setManagementCompaniesOptions] = useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  // const [accessibilities, setAccessibilities] = useState([]);

  const fetchManagementCompanies = useCallback(
    async (companyId: number) => {
      try {
        const { results } = await getManagementCompanies(companyId, {
          page_size: PAGINATION_PAGE_SIZE.MEDIUM,
        });

        const options = results.map((company: any) => ({
          label: company.management_company,
          value: company.id,
        }));

        setManagementCompaniesOptions(options);
      } catch (e) {
        console.error(e);
      }
    },
    [setManagementCompaniesOptions]
  );

  useEffect(() => {
    if (!selectedCompany) return;
    setCompanyOptions([
      {
        label: selectedCompany.name,
        value: selectedCompany.id,
      },
    ]);

    form.setFieldValue('company_id', selectedCompany.id);
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedManagementCompany) {
      setManagementCompaniesOptions([
        {
          label: selectedManagementCompany.management_company,
          value: selectedManagementCompany.id,
        },
      ]);

      form.setFieldValue('management_company_id', selectedManagementCompany.id);
    } else if (selectedCompany) {
      fetchManagementCompanies(selectedCompany.id);
    }
  }, [selectedManagementCompany]);

  const onFinish = (values: any) => {
    const payload: any = {
      ...initialData,
      id: communityId,
      company_id: values?.company_id,
      management_company_id: values.management_company_id,
      comunity_name: values.comunity_name || '',
      address: values?.address || '',
      state: values.state || '',
      managed_by: values?.managed_by || '',
      phone_number: values?.phone_number || '',
      website: values?.website || '',
      year_opened: values?.year_opened || '',
      payment_types: values?.payment_types || '',
      business_model: `${values?.business_model}` || '',
      latitude: location?.lat || initialData?.latitude,
      longitude: location?.lng || initialData?.longitude,
      city: values?.city || '',
      zip_code: values?.zip_code || '',
      google_map_location: values?.google_map_location || '',
      google_formatted_address: values?.address || '',
      google_rating: initialData?.google_rating || 0,
      google_reviews: initialData?.google_reviews || 0,
      a_place_for_mom_id: values?.a_place_for_mom_id || '',
      caring_url: values?.caring_url || '',
      op: 'edited',
      disabled: false,
    };
    if (accessibilities_data?.length > 0 && initialData?.accessibility === 'public') {
      payload.Accessibility = accessibilities_data.map((ac: any) => ({
        ...ac,
        company_id: communityId,
      }));
    }

    setLoading(true);
    setData(payload);
    axios
      .post('/communities/Save_AddComm_CommunityInfo/', {
        data: [payload],
      })
      .then((res) => {
        getCommunityInfo();
        showMessage('success', 'Saved successfully!');
        setLoading(false);
        setUnSaved(false);
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong!');
        setLoading(false);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const paymentTypesOptions = paymentTypes.map((pt) => ({
    label: pt.type,
    value: pt.id,
  }));

  const businessOptions = business_models.map((pt: any) => ({
    label: pt.type,
    value: pt.id,
  }));

  const [form] = Form.useForm<any>();
  // const accessibility = Form.useWatch('accessibility', form);

  const handleSetLocation = (data: any) => {
    setLocation(data);
    form.setFieldsValue({
      comunity_name: data?.name,
      address: data?.formatted_address,
      city: data?.city,
      state: data?.state,
      zip_code: data?.zip_code,
      google_map_location: data?.place_id,
      phone_number: data?.phone_number,
      website: data?.website,
    });
  };

  React.useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        ...initialData,
        id: +initialData?.id,
        accessibility: 'private',
        management_company_id: +initialData?.management_company_id,
        payment_types: +initialData?.payment_types || null,
        business_model: +initialData?.business_model || null,
      });
    }
  }, [initialData]);

  return (
    <div>
      {contextHolder}
      <div style={{ padding: '0px 20px' }}>
        <div>
          <Form
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            form={form}
            onValuesChange={() => (!unsaved ? setUnSaved(true) : null)}
          >
            <div
              style={{
                maxWidth: '570px',
                margin: '0 auto',
              }}
            >
              <Row gutter={[5, 0]}>
                <Col xs={24} style={{ marginBottom: 20 }}>
                  <Form.Item
                    name="comunity_name"
                    label={<p className="form_label">My Community Name:</p>}
                    rules={[{ required: true, message: 'Enter Community Name' }]}
                  >
                    <LocationSearchInput
                      getLocationBySearch={handleSetLocation}
                      placeholder="Enter name or select from Google Places"
                      style={{ borderRadius: '6px', height: '40px', boxShadow: '0px' }}
                      updateCommunityName={(value: string) => form.setFieldValue('comunity_name', value)}
                      defaultValue={initialData?.comunity_name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="address"
                    label={<p className="form_label">Address:</p>}
                    rules={[{ required: true, message: 'Enter Address' }]}
                  >
                    <Input
                      suffix={
                        initialData.longitude ? (
                          <Tooltip
                            title={() => (
                              <span>
                                Longitude: {initialData.longitude} <br /> Latitude: {initialData.latitude} <br />
                                Rating: {initialData.google_rating} <br />
                                Reviews {initialData.google_reviews}
                              </span>
                            )}
                            color="blue"
                          >
                            <InfoCircleOutlined
                              style={{
                                color: 'rgba(0,0,0,.45)',
                                cursor: 'pointer',
                              }}
                            />
                          </Tooltip>
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="city"
                    label={<p className="form_label">City:</p>}
                    rules={[{ required: true, message: 'Please Select City' }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={CITY_OPTIONS}
                      placeholder="Select city"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="state"
                    label={<p className="form_label">State:</p>}
                    rules={[{ required: true, message: 'Please Select State' }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select state"
                      options={STATES}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="zip_code"
                    label={<p className="form_label">Zip Code:</p>}
                    rules={[{ required: false, message: 'Enter Zip Code' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="phone_number"
                    label={<p className="form_label">Phone Number:</p>}
                    rules={[{ required: false, message: 'Please Enter Phone Number' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} style={{ marginBottom: '30px' }}>
                  <Form.Item
                    name="website"
                    label={<p className="form_label">Website:</p>}
                    rules={[{ required: false, message: 'Please Enter Website Url' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="google_map_location"
                    label={<p className="form_label">Google Place ID</p>}
                    rules={[{ required: false, message: 'Please Enter Place ID' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} style={{ marginBottom: '30px' }}>
                  <Form.Item
                    name="year_opened"
                    label={<p className="form_label">Year Opened:</p>}
                    rules={[{ required: false, message: 'Please Year Opened' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="a_place_for_mom_id" label={<p className="form_label">A Place For Mom ID</p>}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="caring_url" label={<p className="form_label">Caring URL</p>}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="managed_by"
                    label={<p className="form_label">Managed by</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter managed by',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="payment_types"
                    label={<p className="form_label">Payment type:</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please Select Payment type',
                      },
                    ]}
                  >
                    <Select options={paymentTypesOptions} placeholder="Select" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="business_model"
                    label={<p className="form_label">Business Model:</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please Select  Business Model',
                      },
                    ]}
                  >
                    <Select options={businessOptions} placeholder="Select" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="company_id"
                    label={<p className="form_label">Company</p>}
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Company',
                      },
                    ]}
                  >
                    <Select showSearch options={companyOptions} disabled placeholder="Select" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="management_company_id"
                    label={<p className="form_label">Management Company</p>}
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Management Company',
                      },
                    ]}
                  >
                    <Select options={managementCompaniesOptions} placeholder="Select" />
                  </Form.Item>
                </Col>

                {/* Feature is currently not fully function. hiding it for later use */}

                {/* <Col xs={24} md={12}>
                  <Form.Item
                    name="accessibility"
                    label={<p className="form_label">Accessibility</p>}
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Management Company',
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio defaultChecked value="private">
                        Private
                      </Radio>
                      <Radio value="public">Public</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col> */}
              </Row>
              {/* {accessibility === 'public' && (
                <div>
                  <AccessibilityTable management_options={mngmntOptions} initialValues={accessibilities} />
                </div>
              )} */}
              <div
                style={{
                  width: '100%',
                  borderTop: '2px solid #eee',
                  boxSizing: 'border-box',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  height: '80px',
                  marginTop: 30,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoForm;
