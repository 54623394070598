import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { HolderOutlined } from '@ant-design/icons/lib';
import classNames from 'classnames';
import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import { DATA_DISPLAY_TYPE, DATA_RANGE_TYPE } from './constants';
import WidgetContent from './WidgetContent';
import { WidgetContext } from './WidgetContext';
import WidgetHeader from './WidgetHeader';
import { GRAPH_WIDGET_TYPES, SINGLE_METRIC_WIDGET_TYPES, WIDGET_TYPES } from '../constants';
import { WidgetType } from '../types';

const WidgetContainer = styled.div`
  display: flex;
  padding: 15px 20px;
  background: white;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 100%;
  min-height: 158px;

  &.single-metric {
    .widget-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  &.graph,
  &.swot-score,
  &.swot-performance,
  &.reviews,
  &.incentives,
  &.photos {
    width: 585px;
    height: 340px;
  }

  &.graph,
  &.photos {
    .widget-drag-handle {
      top: 18px;
    }
  }
  &.map {
    height: 370px;
    padding: 0;
    background: none;
    box-shadow: none;
  }

  :hover {
    .widget-drag-handle {
      display: block;
    }
    .widget-actions {
      visibility: visible;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  width: 100%;
`;

const Handle = styled.div`
  position: absolute;
  display: none;
  cursor: pointer;
  top: 22px;
  left: 5px;
  width: 15px;
  height: 15px;
  &.map {
    top: 30px;
    z-index: 999;
  }
  svg {
    color: var(--text-secondary);
  }
`;

const Widget = forwardRef<HTMLDivElement, WidgetType>(({ id, type, name, note, filters }: WidgetType, ref) => {
  const { preview } = useCommunityViewContext();
  const elementRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => elementRef.current as HTMLDivElement);

  const [meta, setMeta] = useState<any>(undefined);
  const [rangeType, setRangeType] = useState<string>(DATA_RANGE_TYPE.NOW);
  const [displayType, setDisplayType] = useState<string>(DATA_DISPLAY_TYPE.GRAPH);
  const [csvData, setCSVData] = useState<any>([]);

  const [showRemoveWidgetModal, setShowRemoveWidgetModal] = useState<boolean>(false);
  const [showEditWidgetModal, setShowEditWidgetModal] = useState<boolean>(false);

  return (
    <WidgetContainer
      className={classNames('widget-container', {
        'single-metric': SINGLE_METRIC_WIDGET_TYPES.includes(type),
        graph: GRAPH_WIDGET_TYPES.includes(type),
        reviews: type === WIDGET_TYPES.REVIEWS,
        incentives: type === WIDGET_TYPES.INCENTIVES,
        photos: type === WIDGET_TYPES.PHOTOS,
        'swot-performance': type === WIDGET_TYPES.SWOT_PERFORMANCE,
        'swot-score': type === WIDGET_TYPES.SWOT_SCORE,
        map: type === WIDGET_TYPES.MAP,
      })}
      ref={elementRef}
    >
      <WidgetContext.Provider
        value={{
          id,
          type,
          name,
          note,
          filters,
          meta,
          elementRef,
          rangeType,
          displayType,
          csvData,
          setMeta,
          showRemoveWidgetModal,
          showEditWidgetModal,
          setRangeType,
          setDisplayType,
          setCSVData,
          setShowRemoveWidgetModal,
          setShowEditWidgetModal,
        }}
      >
        <Container>
          {!preview && (
            <Handle
              className={classNames('widget-drag-handle', {
                map: type === WIDGET_TYPES.MAP,
              })}
            >
              <HolderOutlined />
            </Handle>
          )}
          <WidgetHeader />
          <WidgetContent />
        </Container>
      </WidgetContext.Provider>
    </WidgetContainer>
  );
});

export default Widget;
