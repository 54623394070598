import React, { createContext, useContext } from 'react';

import { APARTMENT_TYPE_CATEGORIES, STATUS } from '../../constants';

type DashboardReportsContent = {
  communityId: number | null;
  communityName: string;
  careTypeId: number;
  apartmentTypeCategory: number | undefined;
  dataStatus: string;
  setDataStatus: (value: string) => void;
};

export const DashboardReportsContext = createContext<DashboardReportsContent>({
  communityId: null,
  communityName: '',
  careTypeId: 1,
  apartmentTypeCategory: APARTMENT_TYPE_CATEGORIES.BASIC,
  dataStatus: STATUS.IDLE,
  setDataStatus: () => {
    // Do nothing
  },
});

export const useDashboardReportsContext = () => useContext(DashboardReportsContext);
