import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 4px;
  color: var(--text-primary);
  font-size: 14px;
`;

interface ValueProps {
  positive: boolean;
}

const Value = styled.div<ValueProps>`
  color: ${(props) => (props.positive ? 'var(--positive-green)' : 'var(--negative-orange)')};
`;

interface Props {
  label: string;
  value: string;
  positive: boolean;
}

const CardFooter = ({ label, value, positive }: Props) => (
  <Container>
    <span>{label}</span>
    <Value positive={positive}>{value}</Value>
  </Container>
);

export default CardFooter;
