import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Space, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import shortUUID from 'short-uuid';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

import classes from './rentroll.module.scss';

interface DataType {
  id: number;
  key: string;
  rating_name: string;
  order_no: number;
  rate_pct: number;
  updated_by: string;
  created_by: string;
  updated_at: string;
  created_at: string;
  status: 1 | 0;
  company: number;
  op: 'added' | 'edited' | 'deleted' | null;
  disabled: boolean;
}

const ApartmentRatings = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const companyId = useAppSelector((state) => state.auth.currentUser?.company);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  // Get Total occupencies units
  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_ApartmentRatings/${companyId}/`)
      .then((res) => {
        setDataLoading(false);
        const data: any[] = res.data?.payload?.CompanyRatings;

        const updated_data = data.map((el) => ({
          ...el,
          key: shortUUID.generate(),
          op: null,
        }));
        setDataSource(updated_data);
      })
      .catch((err) => {
        setDataLoading(false);
        console.log(err);
      });
  };

  const handleDeleteRentRoll = (row: DataType) => {
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el.key === row.key);
    if (index !== -1) {
      if (cp[index].id === -1 && row.op === 'added') {
        cp.splice(index, 1);
      } else {
        cp[index].op = 'deleted';
      }
      setDataSource(cp);
    }
  };

  // handle form functions
  const handleUpdateInput = (val: any, row: DataType, key: string) => {
    const cp: DataType[] = [...dataSource];
    const index = cp.findIndex((el) => el.key === row.key);
    if (index !== -1) {
      if (key === 'status') {
        // @ts-ignore
        cp[index][key] = val ? 1 : 0;
        setDataSource(cp);
        if (cp[index].op === null) {
          cp[index].op = 'edited';
        }
      } else {
        // @ts-ignore
        cp[index][key] = val;
        setDataSource(cp);
        if (cp[index].op === null) {
          cp[index].op = 'edited';
        }
      }
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Order No</span>,
      dataIndex: 'order_no',
      key: 'order_no',
      width: '100px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'order_no');
            }}
            style={{ width: '100%', textAlign: 'center' }}
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Rating Name</span>,
      dataIndex: 'rating_name',
      key: 'rating_name',
      width: '300px',
      align: 'left',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'rating_name');
            }}
            style={{ width: '100%' }}
          />
        );
      },
    },

    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Rate %</span>,
      dataIndex: 'rate_pct',
      key: 'rate_pct',
      width: '200px',
      align: 'left',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'rate_pct');
            }}
            style={{ width: '100%' }}
            addonAfter="%"
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Active</span>,
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Switch
            checked={parseInt(value) === 1 ? true : false}
            onChange={(checked: boolean) => handleUpdateInput(checked, record, 'status')}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Space size="small" className="actions">
            <Button type="link" danger onClick={() => handleDeleteRentRoll(record)} icon={<DeleteOutlined />} />
          </Space>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: DataType = {
      id: 1,
      key: shortUUID.generate(),
      rating_name: '',
      order_no: dataSource.length + 1,
      rate_pct: 0,
      updated_by: currentUser?.name || '',
      created_by: currentUser?.name || '',
      updated_at: format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
      created_at: format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
      status: 1,
      company: companyId || -1,
      op: 'added',
      disabled: false,
    };
    setDataSource((prevData) => [...prevData, newData]);
  };

  const [loading, setLoading] = useState(false);
  const handleSave = () => {
    const data_to_save = dataSource.filter((d) => d.op === 'added' || d.op === 'edited' || d.op === 'deleted');
    setLoading(true);
    axios
      .post('/communities/Save_ApartmentRatings/', {
        data: data_to_save.map((el) => ({
          id: el?.id,
          rating_name: el?.rating_name,
          order_no: el?.order_no,
          rate_pct: el?.rate_pct,
          status: el?.status,
          company_id: el?.company,
          op: el?.op,
          disabled: false,
        })),
      })
      .then((res) => {
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        getInitialData();
      })
      .catch((err) => {
        console.log(err.response);
        showMessage('error', err?.response?.data[0]);
        setLoading(false);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const deletedDatasource: DataType[] = dataSource.filter((d) => d.op !== 'deleted');

  return (
    <div
      style={{
        padding: '0px 10px 30px 10px',
        margin: '0px auto',
      }}
    >
      {contextHolder}

      <div style={{ textAlign: 'center' }}>
        <h2>Apartment Ratings</h2>
      </div>
      <br />
      <Table
        className={classes.rentroll_table}
        // components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={deletedDatasource}
        columns={columns}
        pagination={false}
        scroll={{ y: 500 }}
        loading={dataLoading}
      />
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px 30px',
        }}
      >
        <Button
          icon={<PlusOutlined />}
          type="default"
          shape="round"
          style={{
            fontSize: 14,
            height: 'max-content',
            padding: '6px 10px 4px 10px',
            marginTop: '15px',
          }}
          onClick={() => handleAdd()}
        >
          Add
        </Button>
        <Button
          type="primary"
          style={{
            fontSize: 14,
            height: 'max-content',
            padding: '6px 10px 4px 10px',
            marginTop: '15px',
            minWidth: 130,
          }}
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ApartmentRatings;
