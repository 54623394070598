import React, { ReactNode } from 'react';

import { Avatar } from 'antd';
import styled from 'styled-components';

import { getInitials } from './utils';

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
  border-color: var(--negative-orange);
  color: var(--negative-orange);
  background-color: #ffefeb;
  display: flex;

  .ant-avatar-string {
    width: 12px;
    height: 14px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
  }
`;

type Props = {
  label: string | ReactNode;
};

const ShopRequestUserAvatar = ({ label }: Props) => {
  return (
    <StyledAvatar shape="circle" size="small">
      {typeof label === 'string' ? getInitials(label) : ''}
    </StyledAvatar>
  );
};

export default ShopRequestUserAvatar;
