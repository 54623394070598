import React from 'react';
import { useControls, useTransformEffect } from 'react-zoom-pan-pinch';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type Props = {
  setIsZoomed: (value: boolean) => void;
};

const Container = styled.div`
  padding: 5px 0;
  display: flex;
  width: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.62;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background-image: linear-gradient(144deg, rgba(255, 255, 255, 0.4) 21%, rgba(255, 255, 255, 0.24) 78%);
}

  & > :first-child {
    margin-bottom: 5px;
  }
`;

const ZoomControls = ({ setIsZoomed }: Props) => {
  const { zoomIn, zoomOut } = useControls();

  useTransformEffect(({ state }) => {
    setIsZoomed(state.scale > 1);
  });

  return (
    <Container>
      <PlusOutlined onClick={() => zoomIn()} />
      <MinusOutlined onClick={() => zoomOut()} />
    </Container>
  );
};

export default ZoomControls;
