import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { message } from 'antd';
import styled from 'styled-components';

import CommunityInfoContent from './CommunityInfoContent';
import { CommunityInfoContext } from './CommunityInfoContext';
import CommunityInfoHeaderBreadcrumbs from './CommunityInfoHeaderBreadcrumbs';
import Header from '../../common/Header';
import axios from '../../config/axiosPrivate';
import { ACTOR_TYPE, COMMUNITY_TYPE } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { handleClearCommunityData } from '../../store/slices/communitySlice';

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const Content = styled.div`
  padding: 30px 20px;
  overflow: auto;
`;

const CommunityInfo = () => {
  const { communityId, competitorId } = useParams();

  const dispatch = useAppDispatch();

  const { selectedCommunity, communityList, communityCompetitorsList } = useAppSelector((state) => state.global);

  const [parentCommunityId, setParentCommunityId] = useState<number>(0);
  const [communityInfo, setCommunityInfo] = useState<any>(null);
  const [competitorInfo, setCompetitorInfo] = useState<any>(null);
  const [openPdfModal, setOpenPdfModal] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [openBirdEyeModal, setOpenBirdEyeModal] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [activeTabKey, setActiveTabKey] = useState('Basic_Info');

  const selectedCompetitor =
    competitorId &&
    competitorInfo &&
    Object.values(competitorInfo).find((competitor: any) => competitor.id == competitorId);
  const communityName = selectedCompetitor ? selectedCompetitor.comunity_name : selectedCommunity?.name;

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  useEffect(() => {
    if (communityId) {
      const allCommunities = [...communityList, ...communityCompetitorsList];
      const community = allCommunities.find((item) => item.id === parseInt(communityId));

      const parentId = community.apartmenttype === COMMUNITY_TYPE.COMMUNITY ? community.id : community.parent_id;
      setParentCommunityId(parentId);
    }
  }, [communityId]);

  React.useEffect(() => {
    dispatch(handleClearCommunityData());
    return () => {
      dispatch(handleClearCommunityData());
    };
  }, []);

  const state = useQuery({
    queryKey: ['CommunityInfo', parentCommunityId],
    queryFn: () =>
      axios.get(`/communities/Get_HomeCommunityCompetitors/${parentCommunityId}`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const community_data = {
        ...data?.CommunityInfo[0][0],
        livingInfo: data?.CommunityInfo[1]?.Details?.LivingInfo,
        logo: data?.CompanyLogo,
      };

      setCommunityInfo(community_data);
      const competitor_data: any = {};
      if (data?.CompetitorInfo) {
        Object.keys(data?.CompetitorInfo).forEach((key) => {
          competitor_data[key] = {
            ...data?.CompetitorInfo[key][0],
            livingInfo: data?.CompetitorInfo[key][1]?.Details?.LivingInfo,
          };
        });
      }
      setCompetitorInfo(competitor_data);
    },
    enabled: Boolean(parentCommunityId),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (parentCommunityId) {
      state.refetch();
    }
  }, [toggleRefresh]);

  return (
    <CommunityInfoContext.Provider
      value={{
        isLoading: state.isLoading,
        parentCommunityId,
        activeTabKey,
        setActiveTabKey,
        openBirdEyeModal,
        setOpenBirdEyeModal,
        openPdfModal,
        setOpenPdfModal,
        toggleRefresh,
        setToggleRefresh,
        communityInfo,
        setCommunityInfo,
        competitorInfo,
        setCompetitorInfo,
        showMessage,
      }}
    >
      <Container>
        <Header
          title={communityName || ''}
          breadcrumbs={
            <CommunityInfoHeaderBreadcrumbs
              competitor={selectedCommunity?.type === ACTOR_TYPE.COMPETITOR || !!competitorId}
            />
          }
        />
        <Content>{state.isFetched && state.isSuccess && communityInfo && <CommunityInfoContent />}</Content>
        {contextHolder}
      </Container>
    </CommunityInfoContext.Provider>
  );
};

export default CommunityInfo;
