import React, { useState } from 'react';

import CommunitySettingLabel from './CommunitySettingLabel';
import { COMMUNITY_LABEL_COLOR, COMMUNITY_SETTINGS_PAGE_TYPE, REMINDER_TO_UPDATE_PRICING_STATUS } from './constants';
import ReminderToUpdatePricingModal from './ReminderToUpdatePricingModal';
import SettingsPage from './SettingsPage';

const getItemStatusComponent = (item: any) => {
  const { statusElements } = REMINDER_TO_UPDATE_PRICING_STATUS;

  switch (item.reminder_to_update_pricing_status) {
    case statusElements.MONTHLY.value:
      return (
        <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.POSITIVE}>
          {statusElements.MONTHLY.label}
        </CommunitySettingLabel>
      );
    case statusElements.EVERY_2_MONTHS.value:
      return (
        <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.POSITIVE}>
          {statusElements.EVERY_2_MONTHS.label}
        </CommunitySettingLabel>
      );
    case statusElements.QUARTERLY.value:
      return (
        <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.POSITIVE}>
          {statusElements.QUARTERLY.label}
        </CommunitySettingLabel>
      );
    case statusElements.EVERY_6_months.value:
      return (
        <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.POSITIVE}>
          {statusElements.EVERY_6_months.label}
        </CommunitySettingLabel>
      );
    case statusElements.OFF.value:
    default:
      return (
        <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.NEUTRAL}>{statusElements.OFF.label}</CommunitySettingLabel>
      );
  }
};

const filterCommunitiesByStatus = (data: any[], status: number) => {
  if (status === 0) {
    return data;
  }
  return data.filter((c: any) => c.reminder_to_update_pricing_status === status);
};

const ReminderToUpdatePricing = () => {
  const [communities, setCommunities] = useState<{ id: number; comunity_name: string }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMultipleSelection, setMultipleSelection] = useState(false);
  const [communitiesToModify, setCommunitiesToModify] = useState<any[]>([]);

  const setReminderToUpdatePricingStatus = (newStatus: number) => {
    const filteredOutCommunities = communities.filter(
      (community) => !communitiesToModify.some((modifyCommunity) => modifyCommunity.id === community.id)
    );
    const modifiedCommunities = communitiesToModify.map((c: any) => ({
      ...c,
      reminder_to_update_pricing_status: newStatus,
    }));
    setCommunities([...filteredOutCommunities, ...modifiedCommunities]);
    setMultipleSelection(false);
  };

  const showCommunitiesEditModal = (items: any[]) => {
    setCommunitiesToModify(items);
    setIsModalOpen(true);
  };

  return (
    <>
      <SettingsPage
        filterOptions={Object.values(REMINDER_TO_UPDATE_PRICING_STATUS.statusElements)}
        getItemStatusComponent={getItemStatusComponent}
        filterCommunitiesByStatus={filterCommunitiesByStatus}
        showCommunitiesEditModal={showCommunitiesEditModal}
        communities={communities}
        setCommunities={setCommunities}
        settingsPageType={COMMUNITY_SETTINGS_PAGE_TYPE.REMINDER_TO_UPDATE_PRICING}
        isMultipleSelection={isMultipleSelection}
        setMultipleSelection={setMultipleSelection}
      />
      <ReminderToUpdatePricingModal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        communitiesToModify={communitiesToModify}
        onSave={setReminderToUpdatePricingStatus}
      />
    </>
  );
};

export default ReminderToUpdatePricing;
