import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import { updateFilter } from '../../../apis/FilterAPI';
import { duplicateView } from '../../../apis/ViewAPI';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { handleStoreUserViews } from '../../../store/slices/globalSlice';
import { useRootContext } from '../../layout/RootContext';
import { useCommunityViewContext } from '../CommunityViewContext';
import ViewSaveFilterModal from '../modals/ViewSaveFilterModal';
import { equalFilters, transformFilterInbound, transformFilterOutbound } from '../utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 10px;
  padding: 0px 10px;
  margin: 10px;
  border-left: 1px solid var(--line-gray);
`;

const Link = styled.span`
  color: var(--text-secondary);
  font-size: 10px;
  cursor: pointer;
  &.active {
    color: var(--active-blue);
  }
  &.disabled {
    pointer-events: none;
  }
`;

const HeaderViewFiltersControls = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { communityId, competitorId } = useCommunityViewContext();
  const { showMessage } = useRootContext();

  const { views } = useAppSelector((state) => state.global);

  const [filtersModified, setFiltersModified] = useState<boolean>(false);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const { view, setView, viewFilters, setViewFilters } = useCommunityViewContext();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;

  const handleConfirmSave = async (values: any) => {
    await updateFilter(view.filter.id, transformFilterOutbound(viewFilters))
      .then(() => {
        showMessage('success', 'Updated changes to view filter.');
        setOpenSaveModal(false);
      })
      .catch(() => {
        showMessage('error', 'Failed to update changes to view filter.');
      });
  };

  const handleConfirmCreateNew = async (values: any) => {
    await duplicateView(view.id, { ...values, filter: transformFilterOutbound(viewFilters) })
      .then((response: any) => {
        const viewId = response.id;
        dispatch(handleStoreUserViews([...views, response]));
        showMessage('success', 'New view created from template.');
        navigate(`/community/${communityId}/view/${viewId}`);
        setOpenSaveModal(false);
      })
      .catch(() => {
        showMessage('error', 'Failed to create new view.');
      });
  };

  const handleConfirmReset = async () => {
    const transformedFilters = transformFilterInbound(view?.filter);
    if (competitorId) {
      setViewFilters((prevState: any) => Object.assign({}, transformedFilters, { competitors: [competitorId] }));
    } else {
      setViewFilters(transformedFilters);
    }
  };

  useEffect(() => {
    setFiltersModified(equalFilters(view?.filter, viewFilters));
  }, [view, viewFilters]);

  return (
    <Container>
      <Link onClick={handleConfirmReset}>Reset</Link>
      <ViewSaveFilterModal
        open={openSaveModal}
        onConfirmSave={handleConfirmSave}
        onConfirmCreateNew={handleConfirmCreateNew}
        onCancel={() => setOpenSaveModal(false)}
      />

      {permissions?.indexOf('views.add_view') !== -1 && (
        <Link
          className={classNames({ disabled: filtersModified, active: !filtersModified })}
          onClick={() => setOpenSaveModal(true)}
        >
          Save Filter
        </Link>
      )}
    </Container>
  );
};

export default HeaderViewFiltersControls;
