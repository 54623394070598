import React, { createContext, useContext } from 'react';

import { INCENTIVES_TABS } from './constants';
import { ProposalType, UnitType } from './types';
import { ManagementCompanyDataType } from '../Selector/types';

type IncentivesContent = {
  managementCompany?: ManagementCompanyDataType;
  currentTab: string;
  search: string;
  selectedProposalUnit?: UnitType;
  selectedProposal?: ProposalType;
  key: string;
  setSelectedProposalUnit: (value: UnitType | undefined) => void;
  setSelectedProposal: (value: ProposalType | undefined) => void;
  setCurrentTab: (value: string) => void;
  setKey: (value: string) => void;
  setSearch: (value: string) => void;
};

export const IncentivesContext = createContext<IncentivesContent>({
  managementCompany: undefined,
  currentTab: INCENTIVES_TABS.INCENTIVE,
  search: '',
  selectedProposalUnit: undefined,
  selectedProposal: undefined,
  setSelectedProposalUnit: (value: UnitType | undefined) => {
    // Do nothing
  },
  setSelectedProposal: (value: ProposalType | undefined) => {
    // Do nothing
  },
  key: '',
  setCurrentTab: (value: string) => {
    // Do nothing
  },
  setKey: (value: string) => {
    // Do nothing
  },
  setSearch: (value: string) => {
    // Do nothing
  },
});

export const useIncentivesContext = () => useContext(IncentivesContext);
