import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Image, Popconfirm } from 'antd';
import clsx from 'clsx';

import classes from './styles.module.scss';

interface Props {
  img: any;
  imagePath: string;
  handleDeleteData: (img: any, type: any) => void;
}

const BrochureImageComp = ({ img, imagePath, handleDeleteData }: Props) => {
  return (
    <Card
      key={img.id}
      size="small"
      title=""
      headStyle={{ height: '30px' }}
      bodyStyle={{ padding: 0 }}
      style={{
        width: 300,
        borderRadius: 8,
        position: 'relative',
      }}
      className={clsx('box_shadow', classes.item_card)}
    >
      <Popconfirm
        title="Delete"
        description="Are you sure to delete?"
        onConfirm={() => handleDeleteData(img, 'image')}
        onOpenChange={() => console.log('open change')}
      >
        <Button
          icon={<CloseOutlined />}
          type="default"
          size="small"
          danger
          shape="circle"
          style={{
            position: 'absolute',
            top: -10,
            right: -10,
            zIndex: 99,
          }}
          className={classes.closeBtn}
        />
      </Popconfirm>

      <Image
        width={'100%'}
        height={200}
        style={{ objectFit: 'cover' }}
        src={img.image_based_on === 'upload' ? `${imagePath}${img?.image_path}` : img?.image_url}
        fallback="/no-image.png"
      />
      <p style={{ textAlign: 'center' }}>{img.image_name}</p>
    </Card>
  );
};

export default BrochureImageComp;
