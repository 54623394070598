import React from 'react';

import { Avatar } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 10px;

  &&.large-radius {
    border-radius: 10px;
  }
  &&.box-shadow {
    box-shadow: 0 2px 4px 0 #d7dce5;
  }
`;

type Props = {
  size: number;
  style: React.CSSProperties;
  src: string | React.ReactNode;
  largeRadius?: boolean;
  boxShadow?: boolean;
};

const SettingsIcon = ({ size = 40, style, src, largeRadius, boxShadow }: Props) => {
  const classes = classNames('styled-avatar', {
    'large-radius': largeRadius,
    'box-shadow': boxShadow,
  });
  return <StyledAvatar shape="square" style={style} size={size} src={src} className={classes} />;
};

export default SettingsIcon;
