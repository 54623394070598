import React, { forwardRef, useRef } from 'react';
import DatePickerCalendar from 'react-datepicker';

import { Typography } from 'antd';
import styled from 'styled-components';

import { useShopRequestsDataContext } from 'components/MysteryShopping/ShopRequestsDataContext';
import { getFormattedDate } from 'helpers/dates';

const StyledSelect = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 12px;
  height: 28px;
  border-radius: 4px;
  padding: 0 11px;
  background-color: var(--lighter-gray);
  border-color: var(--lighter-gray);
  color: var(--deep-blue);
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-right: 5px;
  :focus {
    color: var(--dark-blue);
    background: var(--faded-blue);
    border: solid 1px var(--button-blue);
    height: 28px;
  }
`;

const BoldText = styled(Typography.Text)`
  font-family: var(--font-bold);
  font-size: 12px;
  color: inherit;
`;

const CustomInput = forwardRef((props: any, ref: React.ForwardedRef<unknown>) => {
  const { filters } = useShopRequestsDataContext();
  return (
    <StyledSelect tabIndex={0} onClick={props.onClick}>
      {filters.dueDate ? (
        <span>
          <BoldText>Due Date:</BoldText>
          {` ${getFormattedDate(filters.dueDate, 'MMMM D YYYY')}`}
        </span>
      ) : (
        'Due Date'
      )}
    </StyledSelect>
  );
});

type Props = {
  selected: Date;
  minDate: Date;
  onChange: (date: any) => void;
};

const SelectAdvancedFilterDate = ({ selected, minDate, onChange }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <DatePickerCalendar
      selected={selected}
      minDate={minDate}
      onChange={onChange}
      showPopperArrow={false}
      customInput={<CustomInput inputRef={inputRef} />}
    />
  );
};

export default SelectAdvancedFilterDate;
