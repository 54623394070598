import React from 'react';
import { useQuery } from 'react-query';

import { fetchData } from '../../../apis/DataAPI';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import CardAverageContent from '../Card/CardAverageContent';
import CardLabelContent from '../Card/CardLabelContent';
import CardPercentageContent from '../Card/CardPercentageContent';
import CardRatingContent from '../Card/CardRatingContent';
import { WIDGET_TYPES } from '../constants';
import NoDataInfo from '../Widget/NoDataContent';
import { useWidgetContext } from '../Widget/WidgetContext';

const CardWidgetContent = () => {
  const { communityId, competitorId } = useCommunityViewContext();
  const { type, filters, rangeType } = useWidgetContext();
  const { time, competitors, careTypes, roomTypes, careLevels, reviewSources, reviewCategories, swotCategories } =
    filters;

  const { data } = useQuery({
    queryKey: [type, communityId, filters, rangeType],
    queryFn: () =>
      fetchData(communityId, type, rangeType, {
        time,
        competitors,
        care_types: careTypes,
        room_types: roomTypes,
        care_levels: careLevels,
        review_sources: reviewSources,
        review_categories: reviewCategories,
        swot_categories: swotCategories,
      }),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const cardData = data?.length > 0 ? data[0] : undefined;

  if (cardData && !cardData.community && !cardData.market) {
    return <NoDataInfo>No Data Available</NoDataInfo>;
  }

  const primaryValue = competitorId ? cardData?.market : cardData?.community;
  const secondaryValue = competitorId ? cardData?.community : cardData?.market;
  const footerLabel = competitorId ? 'My community avg.' : 'Competitor avg.';

  if (type === WIDGET_TYPES.OCCUPANCY) {
    return <CardPercentageContent value={primaryValue} marketValue={secondaryValue} footerLabel={footerLabel} />;
  } else if (type === WIDGET_TYPES.RATING) {
    return (
      <CardRatingContent
        value={primaryValue}
        total={cardData?.total}
        marketValue={secondaryValue}
        footerLabel={footerLabel}
        showTotals={!competitorId}
      />
    );
  } else if (type === WIDGET_TYPES.SQUARE_FOOTAGE) {
    return (
      <CardLabelContent value={primaryValue} marketValue={secondaryValue} label={'sq ft'} footerLabel={footerLabel} />
    );
  }

  return <CardAverageContent value={primaryValue} marketValue={secondaryValue} footerLabel={footerLabel} />;
};

export default CardWidgetContent;
