import React from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

const StyledBreadcrumb = styled(Breadcrumb)`
  li {
    color: var(--text-primary);

    &:last-child {
      color: var(--text-secondary);
    }
  }
`;

const CompetitorHeaderBreadcrumbs = () => (
  <StyledBreadcrumb
    items={[
      {
        title: 'My Community',
      },
      { title: `Competitors` },
    ]}
    separator="·"
  />
);

export default CompetitorHeaderBreadcrumbs;
