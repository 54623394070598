import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Col, Form, Input, Modal, Row } from 'antd';

import axios from 'config/axiosPrivate';
import { HTTP_STATUS } from 'config/constants';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleLogout } from 'store/slices/authSlice';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
}

const ChangePasswordModal: React.FC<Props> = ({ open, handleClose, title = '' }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    setLoading(true);
    const formData = new FormData();
    // @ts-ignore
    formData.append('username', currentUser?.name);
    formData.append('forget', '0');
    formData.append('email', '');
    formData.append('code', '');
    formData.append('oldpassword', values.old_password);
    formData.append('password', values.new_password);
    axios
      .post('/api/auth_changepassword/', formData)
      .then((res) => {
        dispatch(handleLogout());
        navigate('/sign-in');
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status == HTTP_STATUS.UNAUTHORIZED) {
          form.setFields([
            {
              name: 'old_password',
              errors: ['Invalid Old Password!'],
            },
          ]);
        }
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    form.resetFields();
    handleClose();
  };

  return (
    <>
      <Modal title={title} centered open={open} onCancel={handleCloseModal} width={500} footer={false}>
        <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off" form={form}>
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Form.Item
                name="old_password"
                label="Old Password"
                rules={[{ required: true, message: 'Please Enter Old Password!' }]}
              >
                <Input type="password" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="new_password"
                label="New Password"
                rules={[{ required: true, message: 'Please Enter New Password!' }]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="confirm_password"
                label="Confirm Password"
                rules={[
                  { required: true, message: 'Please Confirm Your Password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The New Password that You Entered do not Match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
