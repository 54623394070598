import React from 'react';

import { format } from 'date-fns';
import styled from 'styled-components';

import { CHART_TYPE } from './constants';

const Container = styled.div`
  display: flex;
  font-family: var(--font-regular);
  padding: 7px 10px;
  border-radius: 10px;
  border: solid 1px #fff;
  background-image: linear-gradient(to left, var(--sidebar-purple-light) 99%, var(--community-marker-purple-dark));
  color: #fff;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 10px;
  gap: 6px;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ItemFooter = styled.div`
  display: flex;
  gap: 10px;
`;

const ItemValue = styled.div`
  font-size: 14px;
`;

const ItemTotalValue = styled.div`
  font-size: 10px;
`;

interface Props {
  title: string;
  data: any;
  type: string;
}

const ChartTooltip = ({ title, data, type }: Props) => {
  const isLine = type === CHART_TYPE.LINE;

  return (
    <Container>
      {data?.map((item: any) => (
        <Item key={item.name}>
          <ItemInfo>
            <span>{item.name}</span>
            {isLine && <span>{format(new Date(item.data.date), 'MMM d')}</span>}
          </ItemInfo>
          <ItemFooter>
            <ItemValue>{item.value}</ItemValue>
            {item.data?.total && <ItemTotalValue>{item.data.total} reviews</ItemTotalValue>}
          </ItemFooter>
        </Item>
      ))}
    </Container>
  );
};

export default ChartTooltip;
