import React from 'react';

import styled from 'styled-components';

import ShopRequestsStyledTable from './ShopRequestsStyledTable';

const ShopRequestGroupsStyledTable = styled(ShopRequestsStyledTable)`
  .ant-table-row-level-0,
  .ant-table-row:hover .ant-table-cell {
    background-color: #f0f1f3 !important;
    background: #f0f1f3 !important;
  }

  thead {
    th {
      z-index: 0;
    }
  }

  .ant-table-row-level-0 .ant-table-cell,
  .ant-table-row:first-child .ant-table-cell {
    border-top: solid 4px var(--line-gray);
  }

  .ant-table-row-level-0 {
    .ant-table-cell:last-of-type {
      border-right: solid 4px var(--line-gray);
    }
    .ant-table-cell:first-of-type {
      border-left: solid 4px var(--line-gray);
    }
  }

  .ant-table-column-sort {
    background: #f0f1f3 !important;
  }
  .ant-table-expanded-row-level-1:last-of-type {
    .shop-request-group-table-row:last-of-type {
      border-bottom: solid 4px var(--line-gray);
    }
  }

  .shop-request-group-table-row {
    border-left: solid 4px var(--line-gray);
    border-right: solid 4px var(--line-gray);
  }

  &&&.ant-table-row-level-0:hover {
    background-color: #f0f1f3 !important;
  }

  .ant-table-expanded-row-level-1 {
    .ant-table-cell {
      padding: 0;
    }
  }

  .updated-at-column {
    width: 15%;
  }
  .shopper-column {
    width: 23%;
  }
  .status-column {
    width: 20%;
  }
  .due-date-column {
    width: 15%;
  }
  .community-column {
    width: 27%;
  }

  .ant-table-row-expand-icon-cell {
    padding-left: 12px !important;
  }
`;

export default ShopRequestGroupsStyledTable;
