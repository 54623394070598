import React from 'react';

import { Avatar } from 'antd';
import styled from 'styled-components';

import ViewAgendaIcon from 'common/Icons/ViewAgendaIcon';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 30px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 7px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: row;
  height: 55px;
  align-items: center;
`;

const Title = styled.div`
  font-size: 40px;
  height: 40px;
  font-weight: 900;
`;

const StyledAvatar = styled(Avatar)`
  border-radius: 4px;
  margin-right: 10px;

  &.ant-avatar {
    background-color: var(--competitor-purple);

    .ant-avatar-string {
      display: flex;
      align-items: center;
    }
  }
`;

const ShopRequestsOverviewHeader = () => {
  return (
    <Container>
      <SubTitle>Mystery Shopping</SubTitle>
      <TitleContainer>
        <StyledAvatar shape="square" size={40}>
          <ViewAgendaIcon size={24} alt="shop requests image" />
        </StyledAvatar>
        <Title>Shop Requests</Title>
      </TitleContainer>
    </Container>
  );
};

export default ShopRequestsOverviewHeader;
