import React from 'react';

import styled from 'styled-components';

import CardFooter from './CardFooter';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: var(--text-primary);
  font-size: 42px;
`;

const Label = styled.div`
  font-size: 20px;
  padding-left: 2px;
`;

interface Props {
  value?: number;
  marketValue?: number;
  label: string;
  footerLabel: string;
}

const CardLabelContent = ({ value = 0, marketValue = 0, label, footerLabel }: Props) => (
  <Content>
    <ValueContainer>
      <div>
        <span>{value?.toFixed(0) || 0}</span>
      </div>
      <Label>{label}</Label>
    </ValueContainer>
    <CardFooter
      label={footerLabel}
      value={marketValue ? `${marketValue?.toFixed(0)} ${label}` : 'Not Available'}
      positive={marketValue >= value}
    />
  </Content>
);

export default CardLabelContent;
