import React, { useState } from 'react';

import styled from 'styled-components';

import Modal from 'components/lib/Modal';
import TextArea from 'components/lib/TextArea';

const StyledModal = styled(Modal)`
  .ant-modal-close {
    top: 22px;
  }
  &&& .ant-modal-body {
    padding: 10px 30px 30px 30px;
  }
  &&& .ant-modal-footer {
    margin-top: 0;
    button {
      font-size: 14px;
    }
  }
  .ant-modal-content {
    max-width: 1040px;

    .ant-modal-header {
      margin-bottom: 0;
    }
    .ant-modal-footer {
      display: flex;
      flex-flow: row-reverse;

      button {
        margin-right: 10px;
        border-radius: 4px;
        width: 155px;
      }
    }
  }
`;

const Subtitle = styled.div`
  color: var(--gray-dark);
  margin-bottom: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-flow: row;
`;

const ContainerTitle = styled.div`
  margin-right: 10px;
  margin-top: 8px;
  font-family: 'AvenirLTPro-Heavy';
`;

const NoteImage = styled.span`
  border: solid 1px black;
  border-radius: 30px;
  width: 16px;
  height: 16px;
  margin: 0 4px;
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

type Props = {
  isModalOpen: boolean;
  title: string;
  note?: string | null;
  okButtonDisabled?: boolean;
  closeModal: (event: React.MouseEvent) => void;
  modifyNote: (note: string) => void;
};

const NoteModal = ({ isModalOpen, title, note, okButtonDisabled = false, closeModal, modifyNote }: Props) => {
  const [noteText, setNoteText] = useState(note || '');

  return (
    <StyledModal
      open={isModalOpen}
      title={title}
      centered
      okText="Save"
      okButtonProps={{ disabled: okButtonDisabled }}
      onCancel={closeModal}
      onOk={(event: React.MouseEvent) => {
        modifyNote(noteText);
        closeModal(event);
      }}
    >
      <Subtitle>
        You can edit your custom note here.
        <br />
        You can read and edit notes by pressing on the
        <NoteImage>
          <img src="/icons/edit-note-black.svg" height={12} width={12} alt="edit note" />
        </NoteImage>
        icon.
      </Subtitle>
      <Container>
        <ContainerTitle>Note</ContainerTitle>
        <TextArea
          style={{ height: '250px' }}
          placeholder="Note text"
          value={noteText}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setNoteText(event.target.value)}
        />
      </Container>
    </StyledModal>
  );
};

export default NoteModal;
