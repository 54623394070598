import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LogoutOutlined, SettingFilled, UnlockOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons/lib';
import { Avatar, Dropdown, MenuProps, Space, Typography } from 'antd';

import axios from 'config/axiosPrivate';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleLogout } from 'store/slices/authSlice';
import { handleReset } from 'store/slices/globalSlice';

import classes from './styles.module.scss';
import ChangeAvatarModal from '../../../modals/ChangeAvatar';
import ChangePasswordModal from '../../../modals/ChangePassword';

const HeaderUserActions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const onLogoutUser = async () => {
    await axios.get(`api/jwt/auth/logout/`).then(() => {
      dispatch(handleLogout());
      dispatch(handleReset());
      navigate('/sign-in');
    });
  };

  const items: MenuProps['items'] = [
    {
      label: 'Settings',
      key: '1',
      icon: <SettingFilled />,
      style: {
        minWidth: 200,
      },
      onClick: () => console.log(''),
      children: [
        {
          label: 'Apartment Ratings',
          key: 'apartment_ratings',
          style: {
            minWidth: 200,
          },
          onClick: () => navigate(`/settings/apartment-ratings`),
        },
        {
          label: 'Occupancy Tiers',
          key: 'occupancy_tiers',
          style: {
            minWidth: 200,
          },
          onClick: () => navigate(`/settings/occupancy-tiers`),
        },
        currentUser?.all_permissions?.indexOf('PASettings.view_importancerating') != -1 || currentUser?.isSuperuser
          ? {
              label: 'SWOT Settings',
              key: 'swot_settings',
              style: {
                minWidth: 200,
              },
              onClick: () => navigate(`/settings/swot-settings`),
            }
          : null,
        currentUser?.all_permissions?.indexOf('PAScreens.can_view_swot_analysis') != -1 || currentUser?.isSuperuser
          ? {
              label: 'SWOT Survey',
              key: 'swot_survey',
              style: {
                minWidth: 200,
              },
              onClick: () => navigate(`/swot-survey`),
            }
          : null,
        currentUser?.all_permissions?.indexOf('PAScreens.can_view_swot_analysis') != -1 || currentUser?.isSuperuser
          ? {
              label: 'SWOT Survey List',
              key: 'swot_survey_list',
              style: {
                minWidth: 200,
              },
              onClick: () => navigate(`/swot-survey-list`),
            }
          : null,
      ],
    },

    {
      label: 'Change Avatar',
      key: 'Change Avatar',
      icon: <UploadOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: () => setOpenAvatarModal(true),
    },
    {
      label: 'Change Password',
      key: 'Change Password',
      icon: <UnlockOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: () => setOpenPasswordModal(true),
    },
    {
      label: 'Logout',
      key: '0',
      icon: <LogoutOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: onLogoutUser,
    },
  ];

  return (
    <div>
      <ChangePasswordModal
        open={openPasswordModal}
        handleClose={() => setOpenPasswordModal(false)}
        title="Change Password"
      />
      <ChangeAvatarModal open={openAvatarModal} handleClose={() => setOpenAvatarModal(false)} title="Change Avatar" />
      <Dropdown menu={{ items }} placement="bottomRight" arrow trigger={['click']}>
        <a onClick={(e) => e.preventDefault()} href="#!">
          <Space>
            <Typography.Text className={classes.username_text}>{currentUser?.FullName}</Typography.Text>
            {currentUser?.avatar ? (
              <Avatar size={30} src={currentUser?.avatar} />
            ) : (
              <Avatar size={30} icon={<UserOutlined />} />
            )}
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default HeaderUserActions;
