import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
`;

interface Props {
  children: React.ReactNode;
}

const BetaContent = ({ children }: Props) => {
  const { selectedCompany } = useAppSelector((state) => state.global);
  const { currentUser } = useAppSelector((state) => state.auth);

  const showSidebar = !currentUser?.isSuperuser || (currentUser?.isSuperuser && selectedCompany);

  return (
    <Container>
      {showSidebar && <Sidebar />}
      <ContentContainer>
        <Header />
        <Content>{children}</Content>
      </ContentContainer>
    </Container>
  );
};

export default BetaContent;
