import React from 'react';

import { message } from 'antd';

import { FeesContext } from './FeesContext';
import FeesFormEdit from './FeesFormEdit';
import FeesFormHistory from './FeesFormHistory';
import FormModeSwitch from '../../../common/FormNavigation/FormModeSwitch';

interface Props {
  community_id: any;
}

const FeesForm = ({ community_id }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = (type: 'success' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <FeesContext.Provider value={{ communityId: parseInt(community_id), showMessage: showMessage }}>
      {contextHolder}
      <FormModeSwitch editChild={<FeesFormEdit />} historyChild={<FeesFormHistory />} />
    </FeesContext.Provider>
  );
};
export default FeesForm;
