import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../index';

interface GlobalType {
  basic_info: any;
  cares_info: any;
  communityFees: any;
  careFees: any;
  competitors: any[];
  accessibility: any[];
}

const accessibility = [
  {
    id: -1,
    apartment_id: -1,
    company_id: -1,
    management_company_id: null,
    comments: '',
    status: 0,
    select: 0,
    insert: 0,
    update: 0,
    delete: 0,
    op: 'added',
  },
];

// Define the initial state using that type
const initialState: GlobalType = {
  basic_info: null,
  cares_info: [],
  communityFees: [],
  careFees: [],
  competitors: [],
  accessibility: accessibility,
};

export const communitySlice = createSlice({
  name: 'community',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleStoreBasicInfo: (state, action: PayloadAction<any>) => {
      state.basic_info = action.payload;
    },
    handleStoreCareInfo: (state, action: PayloadAction<any>) => {
      state.cares_info = action.payload;
    },
    handleStoreCareFees: (state, action: PayloadAction<any>) => {
      state.careFees = action.payload;
    },
    handleStoreCommunityFees: (state, action: PayloadAction<any>) => {
      state.communityFees = action.payload;
    },
    handleStoreCompetitorsInfo: (state, action: PayloadAction<any>) => {
      state.competitors = action.payload;
    },
    handleStoreAccessibility: (state, action: PayloadAction<any[]>) => {
      state.accessibility = action.payload;
    },
    handleClearCommunityData: (state) => {
      state.basic_info = null;
      state.cares_info = [];
      state.communityFees = [];
      state.careFees = [];
      state.competitors = [];
      state.accessibility = accessibility;
    },
  },
});

export const {
  handleStoreBasicInfo,
  handleStoreCareInfo,
  handleStoreCareFees,
  handleStoreCommunityFees,
  handleStoreCompetitorsInfo,
  handleClearCommunityData,
  handleStoreAccessibility,
} = communitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default communitySlice.reducer;

