import axios from '../config/axiosPrivate';

export const getCommunitiesOptions = async (params: any) => {
  const response = await axios.get(`/incentives/communities`, { params });
  return response.data;
};

export const getRoomTypesOptions = async (params: any) => {
  const response = await axios.get(`/incentives/room-types`, { params });
  return response.data;
};

export const getCareTypesOptions = async (params: any) => {
  const response = await axios.get(`/incentives/care-types`, { params });
  return response.data;
};

export const getIncentives = async (params: any) => {
  const url = `/incentives`;

  const response = await axios.get(url, { params });
  return response.data;
};

export const getUnits = async (params: any) => {
  const url = `/incentives/units`;

  const response = await axios.get(url, { params });
  return response.data;
};


export const createIncentive = async (payload: any) => {
  const url = `/incentives/`;

  const response = await axios.post(url, payload);
  return response.data;
};

export const updateIncentiveUnit = async (incentiveId: number, unitId: number, payload: any) => {
  const url = `/incentives/${incentiveId}/units/${unitId}`;

  const response = await axios.patch(url, payload);
  return response.data;
};

export const getIncentiveUnit = async (incentiveId: number, unitId: number, proposalId: number) => {
  const url = `/incentives/${incentiveId}/units/${unitId}/proposals/${proposalId}`;

  const response = await axios.get(url);
  return response.data;
};

export const archiveIncentive = async (incentiveId: number) => {
  const url = `/incentives/${incentiveId}/archive`;

  const response = await axios.put(url);
  return response.data;
};

export const getProposals = async (params: any) => {
  const url = `/incentives/proposals`;

  const response = await axios.get(url, { params });
  return response.data;
};

export const createIncentiveProposal = async (payload: any) => {
  const url = `/incentives/proposals`;

  const response = await axios.post(url, payload);
  return response.data;
};
