import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { duplicateView } from '../../../apis/ViewAPI';
import { STATUS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { handleStoreUserViews } from '../../../store/slices/globalSlice';
import { useRootContext } from '../../layout/RootContext';
import { VIEW_MODALS } from '../../View/constants';
import ViewInputModal from '../../View/modals/ViewInputModal';
import { useCommunityViewContext } from '../CommunityViewContext';

interface Props {
  open: boolean;
  onClose: any;
}

const DuplicateViewModal = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { viewId, communityId, competitorId } = useCommunityViewContext();

  const { showMessage } = useRootContext();
  const { views } = useAppSelector((state) => state.global);
  const [duplicateStatus, setDuplicateStatus] = useState<string>(STATUS.IDLE);

  const handleConfirm = async (viewName: string) => {
    setDuplicateStatus(STATUS.LOADING);
    return await duplicateView(viewId, { name: viewName })
      .then(async (viewResponse) => {
        const viewId = viewResponse.id;
        dispatch(handleStoreUserViews([...views, viewResponse]));
        setDuplicateStatus(STATUS.LOADED);
        showMessage('success', 'Duplicated view created.');
        onClose();
        if (competitorId) {
          navigate(`/competitor/${competitorId}/view/${viewId}`);
        } else {
          navigate(`/community/${communityId}/view/${viewId}`);
        }
      })
      .catch((reason) => {
        setDuplicateStatus(STATUS.FAILURE);
        showMessage('error', 'Trouble duplicating view.');
      });
  };

  return (
    <ViewInputModal
      open={open}
      type={VIEW_MODALS.DUPLICATE}
      loading={duplicateStatus === STATUS.LOADING}
      onConfirm={handleConfirm}
      onCancel={() => onClose()}
    />
  );
};

export default DuplicateViewModal;
