import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { handleSetReviewCategories } from 'store/slices/globalSlice';

import { CommunityViewContext } from './CommunityViewContext';
import CommunityViewLayout from './CommunityViewLayout';
import { DEFAULT_FILTERS } from './constants';
import CommunityViewHeader from './header/CommunityViewHeader';
import { equalFilters, transformFilterInbound } from './utils';
import { getCommunity, getCompetitors } from '../../apis/CommunityAPI';
import { getCategories } from '../../apis/ReviewsAPI';
import { getApartmentTypes } from '../../apis/SettingsAPI';
import { getView } from '../../apis/ViewAPI';
import { useAppDispatch } from '../../store/hook';
import { useRootContext } from '../layout/RootContext';
import Loader from '../lib/Loader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: start;
`;

const ExpandedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

interface Props {
  communityId: number;
  competitorId?: number;
  viewId: number;
  preview?: boolean;
  fallbackUrl: string;
}

const CommunityView = ({ communityId, competitorId, viewId, preview = false, fallbackUrl }: Props) => {
  const navigate = useNavigate();
  const { showMessage } = useRootContext();
  const dispatch = useAppDispatch();

  const contentRef = useRef<HTMLDivElement>(null);

  const [showViewModal, setShowViewModal] = useState<string>('');
  const [view, setView] = useState<any>(undefined);
  const [viewFilters, setViewFilters] = useState(DEFAULT_FILTERS);
  const [filtersModified, setFiltersModified] = useState<boolean>(false);

  const { data: reviewCategoriesList } = useQuery({
    queryKey: ['constant', 'categories'],
    queryFn: () => getCategories(),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(handleSetReviewCategories(reviewCategoriesList));
  }, [reviewCategoriesList]);

  const { data: community } = useQuery({
    queryKey: ['community', communityId],
    queryFn: () => getCommunity(communityId),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const { data: competitors } = useQuery({
    queryKey: ['competitors', communityId],
    queryFn: () => getCompetitors(communityId),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const { data: apartmentTypes } = useQuery({
    queryKey: ['apartmentTypes', community, competitors],
    queryFn: () => {
      return getApartmentTypes({ communities: [communityId, ...(competitors ?? []).map((item: any) => item.id)] });
    },
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const {
    data,
    isLoading,
    isFetched,
    isError,
    refetch: viewRefetch,
  } = useQuery({
    queryKey: ['view', viewId, communityId, competitorId],
    queryFn: () => getView(viewId),
    enabled: !!viewId && viewId > 0,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setView(data);

      const transformedFilters = transformFilterInbound(data?.filter);
      if (competitorId) {
        setViewFilters((prevState: any) => Object.assign({}, transformedFilters, { competitors: [competitorId] }));
      } else {
        setViewFilters(transformedFilters);
      }
    } else if (isFetched && isError) {
      showMessage('error', 'View could not be found!');
      navigate(fallbackUrl);
    }
  }, [data, isFetched, isError]);

  useEffect(() => {
    if (view && viewFilters) {
      setFiltersModified(!equalFilters(view.filter, viewFilters));
    }
  }, [viewFilters]);

  return (
    <CommunityViewContext.Provider
      value={{
        contentRef,
        viewId,
        view,
        viewFilters,
        filtersModified,
        communityId,
        community,
        competitorId,
        competitors,
        apartmentTypes,
        setView,
        showViewModal,
        setShowViewModal,
        setViewFilters,
        viewRefetch,
        preview,
      }}
    >
      <Container>
        <Loader active={isLoading} />
        {isFetched && (
          <ExpandedContainer ref={contentRef}>
            <CommunityViewHeader />
            <CommunityViewLayout data={data} />
          </ExpandedContainer>
        )}
      </Container>
    </CommunityViewContext.Provider>
  );
};

export default CommunityView;
