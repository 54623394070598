import React from 'react';

import styled from 'styled-components';

import IncentivesHeaderBreadcrumbs from './IncentivesHeaderBreadcrumbs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--positive-green);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-pink);
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 40px;
  font-weight: 900;
`;

const IncentivesHeader = () => {
  return (
    <Container>
      <HeaderContent>
        <IncentivesHeaderBreadcrumbs />
        <TitleContainer>
          <TitleIcon>
            <img
              src={'/icons/price-tag.svg'}
              alt="Incentives"
              style={{
                width: 24,
                height: 24,
              }}
            />
          </TitleIcon>
          <Title>Incentives</Title>
        </TitleContainer>
      </HeaderContent>
    </Container>
  );
};

export default IncentivesHeader;
