import React, { createContext, useContext } from 'react';

type CMASummaryUser = {
  name: string;
  fullName: string;
  email: string;
};

type CMASummaryContent = {
  communityName: string;
  currentUser: CMASummaryUser;
  dataLoading: boolean;
  tableColumns: any[];
  tableDataHeaders: any[];
  tableDataSource: any[];
  openModal: string;
  setOpenModal: (isOpen: string) => void;
  showMessage: (type: 'success' | 'error', message: string) => void;
};

export const CMASummaryContext = createContext<CMASummaryContent>({
  communityName: '',
  currentUser: {
    name: '',
    fullName: '',
    email: '',
  },
  dataLoading: false,
  tableColumns: [],
  tableDataHeaders: [],
  tableDataSource: [],
  openModal: '',
  setOpenModal: () => {
    // Does nothing
  },
  showMessage: () => {
    // Does nothing
  },
});

export const useCMASummaryContext = () => useContext(CMASummaryContext);
