import React, { useState } from 'react';

import styled from 'styled-components';

import { deleteShopRequest, updateShopRequest } from 'apis/ShopRequestAPI';
import { useRootContext } from 'components/layout/RootContext';
import Modal from 'components/lib/Modal';

import EditShopRequestModalContent from './EditShopRequestModalContent';
import { EditShopRequestModalContext } from './EditShopRequestModalContext';
import EditShopRequestModalFooter from './EditShopRequestModalFooter';
import { MysteryShopRequestInbound, MysteryShopRequestOutbound } from '../types';
import { transformShopRequestOutbound } from '../utils';

const StyledModal = styled(Modal)`
  &&& .ant-modal {
    max-width: 1040px;
  }

  .ant-modal-close {
    top: 22px;
  }

  &&& .ant-modal-body {
    padding: 0;
  }

  &&& .ant-modal-footer {
    margin-top: 0;
    button {
      font-size: 14px;
    }
  }

  .ant-modal-content {
    max-width: 1040px;

    .ant-modal-header {
      margin-bottom: 0;
    }
    .ant-modal-footer {
      display: flex;
      flex-flow: row-reverse;

      button {
        margin-right: 10px;
        border-radius: 4px;
        width: 155px;
      }
    }
  }
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
  onCancel: () => void;
  refetchData: () => void;
};

const EditShopRequestModal = ({ shopRequest, refetchData, onCancel }: Props) => {
  const { showMessage } = useRootContext();
  const [temporaryShopRequest, setTemporaryShopRequest] = useState<MysteryShopRequestInbound>(shopRequest);
  const [isLoading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const transformedShopRequest: MysteryShopRequestOutbound = transformShopRequestOutbound(temporaryShopRequest);
      await updateShopRequest(temporaryShopRequest.id, transformedShopRequest).then(() => {
        setLoading(false);
        onCancel();
        refetchData();
        showMessage('success', 'Shop requests created');
      });
    } catch (e) {
      showMessage('error', 'Trouble editing shop requests.');
      console.error('Trouble editing shop requests.', e);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteShopRequest(temporaryShopRequest.id).then(() => {
        setLoading(false);
        onCancel();
        refetchData();
        showMessage('success', 'Shop requests deleted');
      });
    } catch (e) {
      showMessage('error', 'Trouble deleting shop requests.');
      console.error('Trouble deleting shop requests.', e);
    }
  };

  return (
    <EditShopRequestModalContext.Provider
      value={{
        shopRequest: temporaryShopRequest,
        isLoading,
        setLoading,
        setShopRequest: setTemporaryShopRequest,
      }}
    >
      <StyledModal
        open
        title={`Mystery Shop for ${shopRequest.community.name}`}
        cancelText="Close"
        okText="Save"
        onCancel={onCancel}
        width="fit-content"
        footer={<EditShopRequestModalFooter onOk={handleConfirm} onCancel={onCancel} onDelete={onDelete} />}
      >
        <EditShopRequestModalContent />
      </StyledModal>
    </EditShopRequestModalContext.Provider>
  );
};

export default EditShopRequestModal;
