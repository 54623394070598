import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { SELECTOR_ITEM_TYPES } from './constants';
import SidebarSelectorDropdownListItem from './SidebarSelectorDropdownListItem';

const Container = styled.div`
  max-height: 400px;
  overflow-x: auto;
  background-image: linear-gradient(to top, var(--gray-lighter), var(--white));
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right-icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    color: var(--gray-dark);
  }
`;

const SidebarSelectorDropdownCompanySelector = () => {
  const { selectedCompany } = useAppSelector((state) => state.global);

  const company = {
    id: selectedCompany?.id || -1,
    type: SELECTOR_ITEM_TYPES.COMPANY,
    name: selectedCompany?.name || '',
  };
  return (
    <Container>
      <SidebarSelectorDropdownListItem item={company} /> <RightOutlined className="right-icon" />
    </Container>
  );
};

export default SidebarSelectorDropdownCompanySelector;
