import React from 'react';

import styled from 'styled-components';

import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import IncentivesCreateOffersSavingAddEntry from './IncentivesCreateOffersSavingsAddEntry';
import IncentivesCreateOffersSavingsEntry from './IncentivesCreateOffersSavingsEntry';
import { Divider } from '../../lib/Divider';
import { IncentiveSavingType } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const IncentivesCreateOffersSaving = () => {
  const { savings } = useIncentivesCreateModalContext();

  return (
    <Container>
      {savings.map((item: IncentiveSavingType, index) => (
        <IncentivesCreateOffersSavingsEntry key={item.id} index={index} savingsItem={item} />
      ))}
      <Divider />
      <IncentivesCreateOffersSavingAddEntry />
    </Container>
  );
};

export default IncentivesCreateOffersSaving;
