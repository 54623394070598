import React from 'react';

import styled from 'styled-components';

import IncentivesCreateOffersInfoDates from './IncentivesCreateOffersInfoDates';
import IncentivesCreateOffersInfoDescription from './IncentivesCreateOffersInfoDescription';
import IncentivesCreateOffersInfoName from './IncentivesCreateOffersInfoName';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  gap: 10px;
`;

const IncentivesCreateOffersInfo = () => (
  <Container>
    <IncentivesCreateOffersInfoName />
    <IncentivesCreateOffersInfoDates />
    <IncentivesCreateOffersInfoDescription />
  </Container>
);

export default IncentivesCreateOffersInfo;
