import React from 'react';

import { useWidgetContext } from './WidgetContext';
import { removeWidget } from '../../../apis/WidgetAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import ConfirmModal from '../../Modal/ConfirmModal';

const WidgetRemoveModal = () => {
  const { showMessage } = useRootContext();
  const { viewRefetch } = useCommunityViewContext();
  const { id, showRemoveWidgetModal, setShowRemoveWidgetModal } = useWidgetContext();

  const handleWidgetRemove = async () => {
    if (id) {
      await removeWidget(id)
        .then((response: any) => {
          showMessage('success', 'Widget removed from view.');
          setShowRemoveWidgetModal(false);
          viewRefetch();
        })
        .catch((reason: any) => {
          showMessage('error', 'Trouble removing widget.');
        });
    }
  };

  const handleCancel = () => {
    setShowRemoveWidgetModal(false);
  };

  return (
    <ConfirmModal
      open={showRemoveWidgetModal}
      title={'Remove Widget?'}
      description={
        'This will remove the selected widget for this view. This applies to all communities using the view. You can add it back by using Add Widget.'
      }
      confirmLabel={'Remove'}
      onConfirm={handleWidgetRemove}
      onCancel={handleCancel}
    />
  );
};

export default WidgetRemoveModal;
