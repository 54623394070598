import React, { useContext, useEffect, useRef, useState } from 'react';

import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputRef, message, Select, Space, Switch, Table, Tooltip } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { ColumnsType } from 'antd/es/table';
// @ts-ignore
import { format } from 'date-fns';
import { matchSorter } from 'match-sorter';
import shortUUID from 'short-uuid';
import * as XLSX from 'xlsx';
// @ts-ignore
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

import classes from './rentroll.module.scss';

type EditableTableProps = Parameters<typeof Table>[0];

interface RentRollRatingDataType {
  id: any;
  key: any;
  apartment_id: number;
  rent_roll_id: number;
  ratings_id: string;
  rate_pct: number;
  notes: string;
  op: 'added' | 'edited' | 'deleted' | null;
}

interface RentRollDataType {
  id: any;
  key: any;
  apartment_id: number;
  living_type_id: string;
  living_type_name: string;
  apartment_type_id: string;
  calc_proc_id: number | null;
  occupied: number;
  sq_footage: number;
  apartment_features: number;
  rec_rate: number;
  rate_pct: number;
  adj_rate: number;
  status: 0 | 1;
  apartment_no: string | number;
  original_rate: number;
  price_per_sq: number;
  enhanced_rate: number;
  notes: string;
  supply_adjustment: number;
  final_rate: number;
  supply_feature_enhanced_rate: number;
  RentRollRating: RentRollRatingDataType[];
  op: 'added' | 'edited' | 'deleted' | null;
  disabled: boolean;
}

const getTotalApartmentFeatures = (data: RentRollRatingDataType[]) => {
  if (data.length === 0) return 0;
  let total = 0;
  data.forEach((el) => {
    total += parseFloat(`${el?.rate_pct || 0}`);
  });
  return total;
};

// main component
interface Props {
  community_id: number;
  communityInfo: any;
}
const RentRoll: React.FC<Props> = ({ community_id, communityInfo }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const livingtypes = useAppSelector((state) => state.global.livingtypes);
  const apartmenttypes = useAppSelector((state) => state.global.apartmenttypes);
  const apartment_ratings = useAppSelector((state) => state.global.apartment_ratings);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const companyId = useAppSelector((state) => state.auth.currentUser?.company);
  const [apartmentRatings, setApartmentRatings] = useState<any[]>([]);
  const [calculatorList, setCalculatorList] = useState<any[]>([]);
  const [occupancies, setOcupancies] = useState<any[]>([]);
  const [companyOccupanyTiers, setCompanyOccupanyTiers] = useState<any[]>([]);
  const [livingInfo, setLivingInfo] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<RentRollDataType[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loadOccupamcyData, setLoadOccupancyData] = useState<boolean>(false);

  // get rec data
  useEffect(() => {
    async function getInitilaData() {
      try {
        const calclator_list = await axios
          .get(`/communities/Get_CommunityRentRollRecRate/${community_id}`)
          .then((res) => {
            const data = res.data?.payload;
            if (data?.calculator && data.calculator?.length > 0) {
              return data?.calculator;
            } else {
              return [];
            }
          });

        const CompanyOccupany_Tiers = await axios.get(`/communities/Get_OccupancyTiers/${companyId}`).then((res) => {
          const data = res.data?.payload;
          if (data?.CompanyOccupanyTiers && data.CompanyOccupanyTiers?.length > 0) {
            return data?.CompanyOccupanyTiers;
          } else {
            return [];
          }
        });
        const acc_data = await axios.get(`/communities/Get_CommunityLivingInfo/${community_id}/`).then((res) => {
          const data = res.data?.payload;
          if (data?.length > 0) {
            return data;
          } else {
            return [];
          }
        });
        const rating_data = await axios.get(`/communities/Get_ApartmentRatings/${companyId}/`).then((res) => {
          const data = res.data?.payload?.CompanyRatings;
          if (data?.length > 0) {
            return data;
          } else {
            return [];
          }
        });

        setCompanyOccupanyTiers(CompanyOccupany_Tiers);
        setCalculatorList(calclator_list);
        setLivingInfo(acc_data);
        setApartmentRatings(rating_data);
        // call empty row of data not exists
        if (loadOccupamcyData) {
          const initial_data: RentRollDataType[] = [];
          occupancies.forEach((d) => {
            for (let i = 1; i <= d.total_apartments_units; i++) {
              const livingTypeName = livingtypes.find((lt) => `${lt.id}` === `${d?.LivingTypeId}`);
              const newData: RentRollDataType = {
                id: i + 1,
                key: shortUUID.generate(),
                apartment_id: community_id,
                living_type_id: d?.LivingTypeId,
                living_type_name: livingTypeName ? livingTypeName.type : '',
                apartment_type_id: d?.ApartmentTypeId,
                calc_proc_id: null,
                occupied: 0,
                sq_footage: getValueFromLivingInfo(acc_data, 'sq_footage', d?.LivingTypeId, d?.ApartmentTypeId),
                apartment_features: 0,
                rec_rate: 0,
                rate_pct: 0,
                adj_rate: 0,
                status: 1,
                apartment_no: '',
                original_rate: getValueFromLivingInfo(acc_data, 'base_rent', d?.LivingTypeId, d?.ApartmentTypeId),
                price_per_sq: getValueFromLivingInfo(acc_data, 'price_per_sq', d?.LivingTypeId, d?.ApartmentTypeId),
                enhanced_rate: 0,
                notes: '',
                supply_adjustment: 0,
                final_rate: 0,
                supply_feature_enhanced_rate: 0,
                RentRollRating: [],
                op: 'added',
                disabled: true,
              };
              const calculated_rentroll = update_calculated_field(
                newData,
                d?.LivingTypeId,
                d?.ApartmentTypeId,
                calclator_list,
                occupancies,
                CompanyOccupany_Tiers
              );
              initial_data.push(calculated_rentroll);
            }
          });
          const sorted_d = initial_data
            .map((a, idx) => ({ ...a, apartment_no: idx + 1 }))
            .sort((a, b) => (parseInt(`${a.apartment_no}`) > parseInt(`${b.apartment_no}`) ? 1 : -1));
          setDataSource(sorted_d);
          setDataLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }

    getInitilaData();
  }, [community_id, occupancies, companyId, loadOccupamcyData]);

  const getValueFromLivingInfo = (livingData: any[], key: string, LivingTypeId: number, ApartmentTypeId: number) => {
    const target = livingData?.find(
      (el) =>
        `${el?.LivingTypeId}` === `${LivingTypeId}` &&
        `${el?.ApartmentTypeId}` === `${ApartmentTypeId}` &&
        el.status == 1
    );
    if (target) {
      return target[key];
    } else {
      return 0;
    }
  };
  // get Total occupencies units
  useEffect(() => {
    getApartmentRentroll();
    getTotalOccupancyUnits();
  }, [community_id]);

  const getApartmentRentroll = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_CommunityRentRoll/${community_id}`)
      .then((res) => {
        const RentRoll: any[] = res.data?.payload?.RentRoll || [];
        const RentRollRatings: any[] = res.data?.payload?.RentRollRatings;

        if (RentRoll.length > 0) {
          const modified_rentroll = RentRoll?.map((elem: any, idx) => {
            const livingTypeName = livingtypes.find((lt) => `${lt.id}` === `${elem?.LivingTypeId}`);
            const single_roll: RentRollDataType = {
              id: elem?.id,
              key: idx,
              apartment_id: elem?.apartment,
              living_type_id: `${elem?.LivingTypeId}`,
              living_type_name: livingTypeName ? livingTypeName.type : '',
              apartment_type_id: `${elem?.apartment_type}`,
              calc_proc_id: parseFloat(elem?.calc_proc),
              occupied: parseFloat(elem?.occupied) > 0 ? 1 : 0,
              sq_footage: parseFloat(elem?.sq_footage),
              rec_rate: parseFloat(elem?.rec_rate || 0),
              rate_pct: parseFloat(elem?.rate_pct || 0),
              adj_rate: parseFloat(elem?.adj_rate || 0),
              status: elem?.status,
              apartment_no: elem?.apartment_no || '',
              original_rate: elem?.original_rate,
              price_per_sq: parseFloat(elem?.price_per_sq || 0),
              apartment_features: 0,
              enhanced_rate: parseFloat(elem?.enhanced_rate || 0),
              notes: elem?.notes,
              supply_adjustment: parseFloat(elem?.supply_adjustment || 0),
              final_rate: parseFloat(elem?.final_rate || 0),
              supply_feature_enhanced_rate: 0,
              RentRollRating: [],
              op: null,
              disabled: true,
            };

            const ratings = RentRollRatings?.filter((rating) => rating?.rent_roll === elem?.id);
            const updated_ratings = ratings?.map((rt, idx) => ({
              id: rt?.id,
              key: idx,
              apartment_id: rt?.apartment,
              rent_roll_id: rt?.rent_roll,
              ratings_id: rt?.ratings,
              rate_pct: parseFloat(rt?.rate_pct || 0),
              notes: rt?.notes,
              op: null,
            }));

            single_roll.apartment_features = getTotalApartmentFeatures(updated_ratings);
            single_roll.RentRollRating = updated_ratings;
            single_roll.supply_feature_enhanced_rate =
              single_roll.enhanced_rate + (single_roll.enhanced_rate * single_roll.supply_adjustment) / 100;
            return single_roll;
          });

          setDataSource(modified_rentroll);
        } else {
          setLoadOccupancyData(true);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        console.log(err);
      });
  };

  const getTotalOccupancyUnits = () => {
    axios.get(`/communities/Get_CommunityOccupancies/${community_id}`).then((res) => {
      const data: any[] = res.data?.payload;
      if (data && data?.length > 0) {
        const active_data = data?.filter((d) => d?.status === 1);
        setOcupancies(active_data);
      }
    });
  };

  const handleDeleteRentRoll = (row: RentRollDataType) => {
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el.id === row.id);
    if (index !== -1) {
      cp[index].op = 'deleted';
      setDataSource(cp);
    }
  };

  // select option
  const careTypes = livingtypes.map((el) => ({
    label: el?.type,
    value: `${el?.id}`,
  }));
  const floorTypes = apartmenttypes.map((el) => ({
    label: el?.type,
    value: `${el?.id}`,
  }));
  const ratings_options = apartmentRatings?.map((el) => ({
    ...el,
    label: el?.rating_name,
    value: `${el?.id}`,
  }));

  // handle form functions
  const handleUpdateOccupied = (val: boolean, row: RentRollDataType) => {
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el.id === row.id);
    if (index !== -1) {
      cp[index].occupied = val ? 1 : 0;
      setDataSource(cp);

      if (cp[index].op === null) {
        cp[index].op = 'edited';
      }
    }
  };

  const handleUpdateLivingAndCareType = (val: string, row: RentRollDataType, key: string) => {
    // console.log(val, row, key);
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el.id === row.id);
    if (index !== -1) {
      // @ts-ignore
      cp[index][key] = val;
      if (key === 'living_type_id') {
        const livingTypeName = livingtypes.find((lt) => `${lt.id}` === `${val}`);
        cp[index].living_type_name = livingTypeName?.type || '';
      }

      // update original rate
      const target_calculator = calculatorList.find((c) => `${c[key]}` === val);
      if (target_calculator) {
        cp[index].calc_proc_id = target_calculator?.calc_proc_id;
        cp[index].original_rate = target_calculator?.base_rent || 0;
        cp[index].rec_rate = target_calculator?.final_recommended_rate || 0;

        // calculate enhanced feature rate
        const enhanced_rate_formula_value =
          (target_calculator?.base_rent || 0) + (target_calculator?.base_rent || 0) * row?.apartment_features;
        cp[index].enhanced_rate = enhanced_rate_formula_value;
      }

      // update supply adustment
      const target_occupancy = occupancies.find((oc) => (`${oc.LivingTypeId}` || `${oc?.apartment_type}`) === val);

      if (target_occupancy) {
        let supply_formula_value = 0;
        companyOccupanyTiers.forEach((oct) => {
          const impact_pct = parseFloat(oct?.impact_pct);
          const from_pct = parseFloat(oct?.from_pct);
          const to_pct = parseFloat(oct?.to_pct);
          const target = parseFloat(target_occupancy?.pct_occupancy);

          if (target >= from_pct && target < to_pct) {
            supply_formula_value = impact_pct;
          }
        });
        cp[index].supply_adjustment = supply_formula_value;

        // update Supply & Feature Enhanced Rate
        // formula -> Featured Enhanced Rate+Featured Enhanced Rate*supplyadjustment
        const calculated_supply_enhanced_rate =
          cp[index].enhanced_rate + (cp[index].enhanced_rate * cp[index].supply_adjustment) / 100;
        cp[index].supply_feature_enhanced_rate = calculated_supply_enhanced_rate;
      }
      setDataSource(cp);

      if (cp[index].op === null) {
        cp[index].op = 'edited';
      }
    }
  };
  const handleUpdateRentRollInput = (val: any, row: RentRollDataType, key: string) => {
    const cp: RentRollDataType[] = [...dataSource];
    const index = cp.findIndex((el) => el.id === row.id);
    if (index !== -1) {
      // @ts-ignore
      cp[index][key] = val;
      setDataSource(cp);
      if (cp[index].op === null) {
        cp[index].op = 'edited';
      }
    }
  };

  const columns: ColumnsType<RentRollDataType> = [
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Occupied</span>,
      dataIndex: 'occupied',
      key: 'occupied',
      width: '90px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <div style={{ width: 80 }}>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={value ? true : false}
              onChange={(val) => handleUpdateOccupied(val, record)}
            />
          </div>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          Apartment <br /> No
        </span>
      ),
      dataIndex: 'apartment_no',
      key: 'apartment_no',
      width: '100px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'apartment_no');
            }}
            style={{ width: '100%' }}
          />
        );
      },
      defaultSortOrder: 'ascend',
      sorter: (a: any, b: any) => parseInt(a.apartment_no) - parseInt(b.apartment_no),
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Care Type</span>,
      dataIndex: 'living_type_id',
      key: 'living_type_id',
      width: '180px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Select
            placeholder="Select"
            options={careTypes}
            onChange={(value) => {
              handleUpdateLivingAndCareType(value, record, 'living_type_id');
            }}
            value={`${value}`}
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Floor Plan</span>,
      dataIndex: 'apartment_type_id',
      key: 'apartment_type_id',
      width: '200px',
      align: 'left',
      render(value, record: any, index) {
        return (
          <Select
            placeholder="Select"
            options={floorTypes}
            onChange={(value) => {
              handleUpdateLivingAndCareType(value, record, 'apartment_type_id');
            }}
            value={`${record.apartment_type_id}`}
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Original Rate</span>,
      dataIndex: 'original_rate',
      key: 'original_rate',
      width: '130px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="number"
            value={record.original_rate || null}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'original_rate');
            }}
            prefix="$"
            disabled
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          Calc Gen <br /> Rate
        </span>
      ),
      dataIndex: 'rec_rate',
      key: 'rec_rate',
      width: '130px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="number"
            value={value || null}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'rec_rate');
            }}
            disabled
            style={{ width: '100%' }}
            prefix="$"
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Sq. Ft.</span>,
      dataIndex: 'sq_footage',
      key: 'sq_footage',
      width: '100px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="number"
            value={value || null}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'sq_footage');
            }}
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Rate/Sq. Ft</span>,
      dataIndex: 'price_per_sq',
      key: 'price_per_sq',
      width: '100px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="number"
            value={value || null}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'rate_pct');
            }}
            style={{ width: '100%' }}
            prefix="$"
          />
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          APT Feature <br /> Score
        </span>
      ),
      dataIndex: 'apartment_features',
      key: 'apartment_features',
      width: '130px',
      align: 'center',
      render(value, record: any, index) {
        return <Input type="number" value={value || null} disabled style={{ width: '100%' }} />;
      },
    },
    {
      title: (
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          Feature <br /> Enhanced Rate
        </span>
      ),
      dataIndex: 'enhanced_rate',
      key: 'enhanced_rate',
      width: '130px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="number"
            value={value || null}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'enhanced_rate');
            }}
            disabled
            prefix="$"
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          Supply <br /> Adjustment
        </span>
      ),
      dataIndex: 'supply_adjustment',
      key: 'supply_adjustment',
      width: '130px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="number"
            value={value || null}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'supply_adjustment');
            }}
            disabled
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          Supply & Feature <br /> Enhanced Rate
        </span>
      ),
      dataIndex: 'supply_feature_enhanced_rate',
      key: 'supply_feature_enhanced_rate',
      width: '150px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="number"
            value={value.toFixed(2) || null}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'supply_feature_enhanced_rate');
            }}
            disabled
            prefix="$"
            style={{ width: '100%' }}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'notes',
      key: 'notes',
      width: '200px',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={record.notes}
            onChange={(e) => {
              handleUpdateRentRollInput(e.target.value, record, 'notes');
            }}
            style={{ width: '100%' }}
          />
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Space size="small" className="actions">
            <Button icon={<PlusOutlined />} type="link" onClick={() => handleAddChild(record)} />
            <Button type="link" danger onClick={() => handleDeleteRentRoll(record)} icon={<DeleteOutlined />} />
          </Space>
        ) : null,
    },
  ];

  const update_calculated_field = (
    rentroll: RentRollDataType,
    living_type_id: string,
    apartment_type_id: string,
    calculatorList: any[],
    occupancies: any[],
    companyOccupanyTiers: any[]
  ) => {
    // update original rate
    const target_calculator = calculatorList.find(
      (c) => `${c?.living_type_id}` === `${living_type_id}` && `${c?.apartment_type_id}` === `${apartment_type_id}`
    );
    if (target_calculator) {
      rentroll.calc_proc_id = target_calculator?.calc_proc_id;
      rentroll.rec_rate = target_calculator?.final_recommended_rate || 0;

      // calculate enhanced feature rate
      const enhanced_rate_formula_value =
        (rentroll.rec_rate || 0) + ((rentroll.rec_rate || 0) * rentroll?.apartment_features) / 100;
      rentroll.enhanced_rate = enhanced_rate_formula_value;
    }

    // update supply adustment
    const target_occupancy = occupancies.find(
      (oc) => `${oc.LivingTypeId}` === `${living_type_id}` && `${oc?.apartment_type}` === `${apartment_type_id}`
    );
    if (target_occupancy) {
      let supply_formula_value = 0;
      companyOccupanyTiers.forEach((oct) => {
        const impact_pct = parseFloat(oct?.impact_pct);
        const from_pct = parseInt(oct?.from_pct);
        const to_pct = parseInt(oct?.to_pct);
        const target = parseInt(target_occupancy?.pct_occupancy);
        if (target >= from_pct && target <= to_pct) {
          supply_formula_value = impact_pct;
        }
      });
      rentroll.supply_adjustment = supply_formula_value;

      // update Supply & Feature Enhanced Rate
      // formula -> Featured Enhanced Rate+Featured Enhanced Rate*supplyadjustment
      const calculated_supply_enhanced_rate =
        rentroll.enhanced_rate + (rentroll.enhanced_rate * rentroll.supply_adjustment) / 100;
      rentroll.supply_feature_enhanced_rate = calculated_supply_enhanced_rate;
    }

    return rentroll;
  };

  const exportToExcell = (tabledata: any) => {
    const customHeadings = tabledata.map((item: any) => ({
      Occupied: item.occupied == 0 ? 'No' : 'Yes',
      'Apartment No': item.apartment_no,
      'Care Type': careTypes.filter((care) => care.value == item.living_type_id)[0].label,
      'Floor Plan': floorTypes.filter((floor) => floor.value == item.apartment_type_id)[0].label,
      'Original rate': `$${item.original_rate}`,
      'Calc Gen Rate': `$${item.rec_rate}`,
      'Sq.Ft': item.sq_footage,
      'Rate/Sq.Ft': `$${item.price_per_sq}`,
      'APT Feature Square': item.apartment_features,
      'Feature Enhanced Rate': `$${item.enhanced_rate}`,
      'Supply Adjustment': item.supply_adjustment,
      'Supply & Feature Enhanced Rate': `$${item.supply_feature_enhanced_rate}`,
      Description: item.notes,
    }));
    const ws = XLSX.utils.json_to_sheet([]);
    const wb = {
      Sheets: { Rent_Roll: ws },
      SheetNames: ['Rent_Roll'],
      origin: 'A1',
    };
    XLSX.utils.sheet_add_json(
      wb.Sheets.Rent_Roll,
      [
        {
          note: "Report Generated by Further's Pricing Assistant",
        },
      ],
      {
        header: ['note'],
        skipHeader: true,
        origin: 'A1',
      }
    );
    XLSX.utils.sheet_add_json(
      wb.Sheets.Rent_Roll,
      [
        {
          logo: <img src="/pricing_assistant_logo.png" />,
          note: 'Rent Roll',
          space: '',
          at: `Report Created at: ${format(new Date(), 'MM-dd-yyyy:hh:mm:ss')}`,
        },
      ],
      {
        header: ['logo', 'note', 'space', 'at'],
        skipHeader: true,
        origin: 'A2',
      }
    );
    XLSX.utils.sheet_add_json(wb.Sheets.Rent_Roll, customHeadings, {
      origin: 'A3',
    });
    const ws_details_sheet = 'Pricing Assistant Details';
    /* make worksheet */
    const ws_data = [
      ['Company Name', 'Further Technologies'],
      ['Product Name', 'Pricing Assistant'],
      ['File Name', 'Rent Roll.xlsx'],
      ['Product Name', 'Pricing Assistant'],
      ['Download By', `${currentUser?.name}`],
      ['Download Date', `${format(new Date(), 'MM-dd-yyyy')}`],
      ['Learn More about the Pricing Assistant', 'https://www.talkfurther.com/pricingassistant'],
    ];
    const ws_details = XLSX.utils.aoa_to_sheet(ws_data);
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws_details, ws_details_sheet);
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
      Props: {
        Company: 'Further Technologies',
        Manager: 'Alex Smit',
        Author: `${currentUser?.name}`,
        LastAuthor: `${currentUser?.name}`,
        Title: 'Rent Roll From Pricing Assistant',
        Subject: `Rent Roll File of ${communityInfo?.comunity_name}`,
      },
    });
    const data = new Blob([excelBuffer], { type: 'xlsx' });
    const dataInfo = {
      atCell: 'D2:D2',
      titleRange: 'B2:C2',
      theadRange: 'A3:M3',
      tbodyRange: `A4:M${customHeadings.length + 3}`,
    };
    addStyle(data, dataInfo).then((url: any) => {
      console.log(url);
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', url);
      downloadAnchorNode.setAttribute('download', 'Rent Roll.xlsx');
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const addStyle = (workbookBlob: any, dataInfo: any) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook: any) => {
      workbook.sheets().forEach((sheet: any, index: number) => {
        if (index == 0) {
          sheet.usedRange().style({
            fontFamily: 'AvenirLTPro-Medium',
            verticalAlignment: 'center',
          });
          sheet.column('A').width(35);
          sheet.column('B').width(33);
          sheet.column('C').width(33);
          sheet.column('D').width(33);
          sheet.column('E').width(33);
          sheet.column('F').width(35);
          sheet.column('G').width(25);
          sheet.column('H').width(25);
          sheet.column('I').width(50);
          sheet.column('J').width(55);
          sheet.column('K').width(55);
          sheet.column('L').width(65);
          sheet.column('M').width(35);
          sheet.row(1).style({
            horizontalAlignment: 'left',
            fontSize: 9,
          });
          sheet.range(dataInfo.titleRange).merged(true).style({
            fontSize: 36,
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            underline: true,
          });
          sheet.range(dataInfo.theadRange).style({
            bold: true,
            horizontalAlignment: 'left',
            fontSize: 24,
            fill: 'D9D9D9',
          });
          if (dataInfo.tbodyRange) {
            sheet.range(dataInfo.tbodyRange).style({
              horizontalAlignment: 'left',
              bold: true,
              fontSize: 12,
              wrapText: true,
            });
          }
          sheet.cell(2, 4).style({
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
            fontSize: 9,
          });
        } else if (index == 1) {
          sheet.column('A').width(35);
          sheet.column('B').width(55);
          sheet.cell(7, 2).style({
            horizontalAlignment: 'left',
            fontColor: '0563c1',
            underline: true,
          });
        }
      });

      return workbook.outputAsync().then((workbookBlob: any) => URL.createObjectURL(workbookBlob));
    });
  };

  const handleAdd = (living_type_id = '2', apartment_type_id = '1') => {
    const livingTypeName = livingtypes.find((lt) => `${lt.id}` === `${living_type_id}`);
    const newData: RentRollDataType = {
      id: Date.now(),
      key: Date.now(),
      apartment_id: community_id,
      living_type_id: living_type_id,
      living_type_name: livingTypeName ? livingTypeName.type : '',
      apartment_type_id: apartment_type_id,
      calc_proc_id: null,
      occupied: 0,
      sq_footage: getValueFromLivingInfo(livingInfo, 'sq_footage', +living_type_id, +apartment_type_id),
      apartment_features: 0,
      rec_rate: 0,
      rate_pct: 0,
      adj_rate: 0,
      status: 1,
      apartment_no: dataSource?.length + 1,
      original_rate: getValueFromLivingInfo(livingInfo, 'base_rent', +living_type_id, +apartment_type_id),
      price_per_sq: getValueFromLivingInfo(livingInfo, 'price_per_sq', +living_type_id, +apartment_type_id),
      enhanced_rate: 0,
      notes: '',
      supply_adjustment: 0,
      final_rate: 0,
      supply_feature_enhanced_rate: 0,
      RentRollRating: [],
      op: 'added',
      disabled: true,
    };
    const calculated_rentroll = update_calculated_field(
      newData,
      living_type_id,
      apartment_type_id,
      calculatorList,
      occupancies,
      companyOccupanyTiers
    );
    setDataSource((prevData) => [...prevData, calculated_rentroll]);
  };
  const handleAddChild = (row: RentRollDataType) => {
    const newChildData: RentRollRatingDataType = {
      id: Date.now(),
      key: 0,
      apartment_id: row?.apartment_id,
      rent_roll_id: row.id,
      ratings_id: '',
      rate_pct: 0,
      notes: '',
      op: 'added',
    };
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el?.id === row.id);
    if (index !== -1) {
      const prevData = cp[index].RentRollRating;
      newChildData.key = prevData.length + 1;
      cp[index].RentRollRating = [...prevData, newChildData];
    }
    setDataSource(cp);
  };

  const handleRemoveChild = (row: RentRollRatingDataType) => {
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el?.id === row.rent_roll_id);
    if (index !== -1) {
      const RentRollRating = cp[index].RentRollRating;
      const rating_index = RentRollRating.findIndex((el) => el.id === row.id);

      if (rating_index !== -1) {
        RentRollRating[rating_index].op = 'deleted';
        setDataSource(cp);
      }
      if (cp[index].op === null) {
        cp[index].op = 'edited';
      }
    }
  };

  const [loading, setLoading] = useState(false);

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };
  const handleSave = () => {
    const data_to_save = dataSource.filter((d) => d.op === 'added' || d.op === 'edited' || d.op === 'deleted');
    const data_with_updated_child = data_to_save.map((parent: RentRollDataType) => {
      const parent_obj = {
        ...parent,
        RentRollRating: parent.RentRollRating?.filter(
          (child: RentRollRatingDataType) => child.op === 'added' || child.op === 'deleted' || child.op === 'edited'
        ).map((el) => ({ ...el, id: el?.op === 'added' ? -1 : el?.id })),
      };

      return parent_obj;
    });
    setLoading(true);
    axios
      .post('/communities/Save_ApartmentRentRoll/', {
        data: data_with_updated_child,
      })
      .then((res) => {
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        getApartmentRentroll();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSetChildValue = (option: any, rent_record_id: number, child_record: RentRollRatingDataType) => {
    const cp = [...dataSource];
    const index = dataSource.findIndex((el) => el?.id === rent_record_id);
    if (index !== -1) {
      const RentRollRating = cp[index].RentRollRating;
      const rating_index = RentRollRating.findIndex((el) => el.id === child_record.id);
      if (rating_index !== -1) {
        RentRollRating[rating_index].ratings_id = `${option.id}`;
        RentRollRating[rating_index].rate_pct = option?.rate_pct;
        let total = 0;
        RentRollRating.forEach((rct) => {
          total += parseInt(`${rct?.rate_pct}`);
        });
        cp[index].apartment_features = total;

        // update rent roll row
        const calculated_rentroll = update_calculated_field(
          cp[index],
          cp[index].living_type_id,
          cp[index].apartment_type_id,
          calculatorList,
          occupancies,
          companyOccupanyTiers
        );
        cp[index] = calculated_rentroll;
        setDataSource(cp);
      }
      if (cp[index].op === null) {
        cp[index].op = 'edited';
      }
    }
  };

  const handleUpdateChildDescription = (
    value: string,
    rent_record_id: number,
    child_record: RentRollRatingDataType
  ) => {
    const cp = [...dataSource];
    const index = dataSource.findIndex((el) => el?.id === rent_record_id);
    if (index !== -1) {
      const RentRollRating = cp[index].RentRollRating;
      const rating_index = RentRollRating.findIndex((el) => el.id === child_record.id);
      if (rating_index !== -1) {
        RentRollRating[rating_index].notes = value;
        if (RentRollRating[rating_index].op === null) {
          RentRollRating[rating_index].op = 'edited';
        }
        setDataSource(cp);
      }
      if (cp[index].op === null) {
        cp[index].op = 'edited';
      }
    }
  };

  const expandedRowRender = (rent_record: RentRollDataType) => {
    const columns: ColumnsType<RentRollRatingDataType> = [
      {
        title: 'Ratings',
        dataIndex: 'ratings_id',
        key: 'ratings_id',
        width: '180px',
        render(value, record: any, index) {
          return (
            <Select
              placeholder="Select"
              options={ratings_options}
              onChange={(val, option) => {
                handleSetChildValue(option, rent_record.id, record);
              }}
              value={`${value}` || null}
              style={{ width: '100%' }}
            />
          );
        },
      },
      {
        title: 'Percentage %',
        dataIndex: 'rate_pct',
        key: 'rate_pct',
        width: '130px',
        render(value, record: any, index) {
          return <Input type="number" value={value} addonAfter="%" readOnly disabled />;
        },
      },

      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        width: '250px',
        render(value, record: any, index) {
          return (
            <Input
              type="text"
              value={record.notes}
              onChange={(e) => {
                handleUpdateChildDescription(e.target.value, rent_record.id, record);
              }}
              style={{ width: '100%' }}
            />
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: 100,
        render: (_, record: any) =>
          dataSource.length >= 1 ? (
            <Space size="small" className="actions">
              <Button type="link" danger onClick={() => handleRemoveChild(record)} icon={<DeleteOutlined />} />
            </Space>
          ) : null,
      },
    ];

    const deleted_data = rent_record.RentRollRating?.filter((el) => el?.op !== 'deleted');

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Table columns={columns} dataSource={deleted_data} pagination={false} />
      </div>
    );
  };

  const deletedDatasource: RentRollDataType[] = dataSource.filter((d) => d.op !== 'deleted');

  const searchRows = matchSorter(deletedDatasource, searchQuery, {
    keys: ['apartment_no', 'living_type_name'],
  }).sort((a, b) => (a.id > b.id ? 1 : -1));
  return (
    <div style={{ padding: '0px 10px 30px 10px' }}>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 8,
        }}
      >
        <Tooltip title="Export to Excel Sheet" placement="topRight">
          <Button
            onClick={() => exportToExcell(searchRows)}
            type="text"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 96 96"
                fill="#FFF"
                strokeMiterlimit="10"
                strokeWidth="2"
                style={{ width: '100%', height: 25 }}
              >
                <path
                  stroke="#979593"
                  d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z"
                />
                <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18" />
                <path
                  fill="#C8C6C4"
                  d="M51 61H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 60.5523 51.5523 61 51 61zM51 55H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 54.5523 51.5523 55 51 55zM51 49H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 48.5523 51.5523 49 51 49zM51 43H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 42.5523 51.5523 43 51 43zM51 67H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 66.5523 51.5523 67 51 67zM79 61H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 67H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67zM79 55H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM65 61H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 60.5523 65.5523 61 65 61zM65 67H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 66.5523 65.5523 67 65 67zM65 55H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 54.5523 65.5523 55 65 55zM65 49H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 48.5523 65.5523 49 65 49zM65 43H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 42.5523 65.5523 43 65 43z"
                />
                <path
                  fill="#107C41"
                  d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z"
                />
                <path d="M16.9492,66l7.8848-12.0337L17.6123,42h5.8115l3.9424,7.6486c0.3623,0.7252,0.6113,1.2668,0.7471,1.6236 h0.0508c0.2617-0.58,0.5332-1.1436,0.8164-1.69L33.1943,42h5.335l-7.4082,11.9L38.7168,66H33.041l-4.5537-8.4017 c-0.1924-0.3116-0.374-0.6858-0.5439-1.1215H27.876c-0.0791,0.2684-0.2549,0.631-0.5264,1.0878L22.6592,66H16.9492z" />
              </svg>
            }
            style={{ marginBottom: 10, marginLeft: 25 }}
          />
        </Tooltip>
        <Input placeholder="Search..." style={{ width: 300 }} onChange={(e) => setSearchQuery(e.target.value)} />
      </div>

      <Table
        className={classes.rentroll_table}
        bordered
        dataSource={searchRows}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content', y: '50vh' }}
        loading={dataLoading}
        expandable={{
          expandedRowRender,
          rowExpandable: (record: RentRollDataType) => true,
          expandIcon: ({ expanded, onExpand, record }) => {
            return expanded ? (
              <Button
                icon={<CaretUpOutlined />}
                type={record.RentRollRating?.length > 0 ? 'primary' : 'default'}
                onClick={(e) => onExpand(record, e)}
                style={{
                  width: '25px',
                  height: '25px',
                  padding: '0px 0px 0px 0px',
                }}
              />
            ) : (
              <Button
                icon={<CaretDownOutlined />}
                type={record.RentRollRating?.length > 0 ? 'primary' : 'default'}
                onClick={(e) => onExpand(record, e)}
                style={{
                  width: '25px',
                  height: '25px',
                  padding: '3px 0px 0px 0px',
                }}
              />
            );
          },
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px 30px',
        }}
      >
        <Button
          icon={<PlusOutlined />}
          type="default"
          shape="round"
          style={{
            fontSize: 14,
            height: 'max-content',
            padding: '6px 10px 4px 10px',
            marginTop: '15px',
          }}
          onClick={() => handleAdd('2', '1')}
        >
          Add
        </Button>
        <Button
          type="primary"
          style={{
            fontSize: 14,
            height: 'max-content',
            padding: '6px 10px 4px 10px',
            marginTop: '15px',
            minWidth: 130,
          }}
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default RentRoll;
