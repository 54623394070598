import React from 'react';

import { Select as AntDSelect, SelectProps } from 'antd';
import { OptionProps } from 'antd/lib/select';
import styled from 'styled-components';

const StyledSelect = styled(AntDSelect)`
  font-family: var(--font-regular);
  font-size: 14px;

  &.rectangular {
    .ant-select-selector {
      font-size: 14px;
      color: var(--text-primary);
      border-radius: 0px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px var(--line-gray);
    }
  }
`;

const Select = (props: SelectProps) => {
  return <StyledSelect {...props} />;
};

Select.Option = ({ ...props }: OptionProps) => <AntDSelect.Option {...props} />;

export default Select;
