import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import { Spin } from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';

import Reviews from './Reviews';
import { getCommunityReviews } from '../../../apis/ReviewsAPI';
import { REVIEW_SOURCE } from '../../../components/GoogleMetricsReviews/reviews/constants';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import NoDataInfo from '../Widget/NoDataContent';
import { useWidgetContext } from '../Widget/WidgetContext';

const SpinWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const ReviewsWidgetContent = () => {
  const { communityId } = useCommunityViewContext();

  const { filters, meta = { reviewsCommunityOverride: undefined }, setCSVData } = useWidgetContext();
  const { reviewSources, reviewCategories, time } = filters;
  const { reviewsCommunityOverride } = meta;

  const { data, isLoading } = useQuery({
    queryKey: [communityId, reviewsCommunityOverride, reviewSources, reviewCategories, time],
    queryFn: () =>
      getCommunityReviews(reviewsCommunityOverride ?? communityId, {
        review_sources: reviewSources.length > 0 ? reviewSources : null,
        review_categories: reviewCategories.length > 0 ? reviewCategories : null,
        time,
        page_size: 3000,
      }),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  useEffect(() => {
    if (data?.results) {
      const communitiesData = data?.results.map((item: any) => [
        item.name,
        item.text,
        item.rating,
        format(new Date(item.date), 'MMM dd, yyyy'),
        REVIEW_SOURCE[item.type],
        `[${item.categories.map((category: any) => category.label).join(',')}]`,
      ]);
      const exportData = [['USER', 'REVIEW', 'RATING', 'DATE', 'SOURCE', 'CATEGORIES'], ...communitiesData];
      setCSVData(exportData);
    }
  }, [data]);

  const reviews = data?.results || [];
  const hasData = reviews.length > 0;

  return (
    <>
      {isLoading && (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )}
      {!isLoading && !hasData && <NoDataInfo>No Reviews Available</NoDataInfo>}
      {!isLoading && hasData && <Reviews data={reviews} />}
    </>
  );
};

export default ReviewsWidgetContent;
