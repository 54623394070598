import React from 'react';

import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  handleAddData: (values: any) => void;
  loading?: boolean;
}

const AddImageUrlModal: React.FC<Props> = ({ open, handleClose, title = '', handleAddData, loading = false }) => {
  const [form] = Form.useForm<any>();
  const onFinish = (values: any) => {
    form.resetFields();
    const payload = {
      data: values,
      type: 'image_url',
    };
    handleAddData(payload);
  };

  const onClose = () => {
    form.resetFields();
    handleClose();
  };

  return (
    <>
      <Modal title={title} centered open={open} onCancel={onClose} width={500} footer={false}>
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
          initialValues={{
            category: 'Other Image',
          }}
          form={form}
        >
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Form.Item
                name="category"
                label={<p className="form_label">Image Category:</p>}
                rules={[{ required: true, message: 'Please Select Image Category' }]}
              >
                <Select
                  placeholder="Select"
                  options={[
                    { label: 'Exterior Front', value: 'Exterior Front' },
                    { label: 'Common Area', value: 'Common Area' },
                    { label: 'Apartment Image', value: 'Apartment Image' },
                    { label: 'Dining Room', value: 'Dining Room' },
                    {
                      label: 'Menu',
                      value: 'Menu',
                    },
                    {
                      label: 'Activity Calendar',
                      value: 'Activity Calendar',
                    },
                    { label: 'Other Image', value: 'Other Image' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="imageUrl"
                label={<p className="form_label">Image URL:</p>}
                rules={[
                  { required: true, message: 'Please Enter Valid URL' },
                  {
                    type: 'url',
                    required: true,
                    message: 'You have entered wrong url',
                  },
                  { type: 'string', min: 6 },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddImageUrlModal;
