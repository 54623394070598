import React from 'react';

import dayjs from 'dayjs';

import DownCaretIcon from '../../../../common/Icons/DownCaretIcon';
import Button from '../../../lib/Button';
import Dropdown from '../../../lib/Dropdown';
import { getRecordKey } from '../../helpers';
import { useIncentivesContext } from '../../IncentivesContext';
import { useIncentivesDataContext } from '../../IncentivesDataContext';
import { IncentiveType, UnitType } from '../../types';

interface Props {
  unit: UnitType;
}

const IncentivesTableUnitsActionsDropdown = ({ unit }: Props) => {
  const { setSelectedProposalUnit } = useIncentivesContext();
  const { setArchivedItemsIds } = useIncentivesDataContext();

  const incentive = unit.incentives?.find((item: IncentiveType) => item.id === unit.incentive_id);
  const recordKey = getRecordKey(unit);

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: 'Send Proposal',
            key: 'sendProposal',
            disabled: dayjs().isBefore(dayjs(incentive?.start_at)),
            onClick: () => setSelectedProposalUnit(unit),
          },
          incentive
            ? {
                label: 'Archive',
                key: 'archive',
                onClick: () => setArchivedItemsIds([recordKey]),
              }
            : null,
        ].filter(Boolean),
      }}
    >
      <Button style={{ width: 100 }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <span>Actions</span>
          <DownCaretIcon />
        </div>
      </Button>
    </Dropdown>
  );
};

export default IncentivesTableUnitsActionsDropdown;
