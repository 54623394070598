import React from 'react';

import classNames from 'classnames';

import { SWOT_CATEGORIES } from 'common/Widgets/constants';

import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';

const HeaderViewFiltersSwotCategory = () => {
  const { viewFilters, setViewFilters } = useCommunityViewContext();

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, swotCategories: values.map((item: any) => item.value) }));
  };

  return (
    <SelectAdvancedFilter
      className={classNames({ active: viewFilters?.swotCategories?.length > 0 })}
      placeholder={'SWOT Category'}
      options={SWOT_CATEGORIES}
      defaultValue={viewFilters.swotCategories}
      onSelect={handleSelect}
    />
  );
};

export default HeaderViewFiltersSwotCategory;
