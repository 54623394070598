import React from 'react';

import { Line, LineConfig } from '@ant-design/plots';

import {
  CHART_TYPE,
  LABEL_FONT_COLOR,
  LABEL_FONT_FAMILY,
  LABEL_FONT_SIZE,
  LINE_CHART_DATA_OFFSET_FACTOR,
} from './constants';
import { axisLabelFormatter, labelFormatter, tooltipContent, tooltipFormatter } from './utils';
import { WIDGET_WITHOUT_PREFIX } from '../constants';

interface Props {
  widgetType: number;
  lineProps: LineConfig;
}

export const LineChart = ({ widgetType, lineProps }: Props) => {
  const { data } = lineProps;

  const dataValues = data?.map((item: any) => item.value);
  const maxDataValueOffset = Math.max(...dataValues);
  const minDataValueOffset = Math.min(...dataValues);

  const maxValueLimit = Math.round(maxDataValueOffset + maxDataValueOffset * LINE_CHART_DATA_OFFSET_FACTOR);
  const minValueLimit = Math.round(minDataValueOffset - minDataValueOffset * LINE_CHART_DATA_OFFSET_FACTOR);

  const enableLimits = !WIDGET_WITHOUT_PREFIX.includes(widgetType);

  const lineConfig = {
    legend: {
      marker: {
        symbol: 'circle' as const,
        style: { lineWidth: 5, r: 3 },
      },
      text: {
        style: {
          fill: LABEL_FONT_COLOR,
          fontFamily: LABEL_FONT_FAMILY,
          fontSize: LABEL_FONT_SIZE,
        },
      },
      orientation: 'horizontal',
      position: 'bottom-left' as const,
      flipPage: false,
    },
    label: undefined,
    xAxis: {
      animate: undefined,
      label: {
        formatter: (value: any) => axisLabelFormatter(value, widgetType),
        style: {
          fill: LABEL_FONT_COLOR,
          fontFamily: LABEL_FONT_FAMILY,
          fontSize: LABEL_FONT_SIZE,
        },
      },
    },
    yAxis: {
      animate: undefined,
      ...(enableLimits && {
        maxLimit: maxValueLimit,
        minLimit: minValueLimit,
      }),
      label: {
        formatter: (value: any) => labelFormatter(parseFloat(value), widgetType),
        style: {
          fill: LABEL_FONT_COLOR,
          fontFamily: LABEL_FONT_FAMILY,
          fontSize: LABEL_FONT_SIZE,
        },
      },
    },
    lineStyle: {
      width: 5,
    },
    tooltip: {
      shared: false,
      customContent: (title: string, data: any) => tooltipContent(title, data, CHART_TYPE.LINE),
      formatter: (value: any) => tooltipFormatter(value, widgetType),
      domStyles: {
        'g2-tooltip': {
          opacity: 1,
          borderRadius: 0,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: 0,
        },
      },
    },
  };

  const expandedConfig = { ...lineProps, ...lineConfig };

  return <Line {...expandedConfig} />;
};

export default LineChart;
