export const PDF_PAGE_WIDTH = 200; // millimeters
export const PDF_PAGE_HEIGHT = 297; // millimeters
export const PDF_PAGE_PADDING = 5; // millimeters

export const EXPORTABLE_PAGE_CLASS = 'exportable-page-class';
export const EXPORTABLE_HEADER_CLASS = 'exportable-header-class';

export const FILE_EXTENSION = {
  PNG: 'png',
  PDF: 'pdf',
};
