import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const HeaderLogo = () => (
  <Link to="/home">
    <Container>
      <img
        src="/new-logotype.png"
        alt="Further"
        style={{
          objectFit: 'contain',
          width: 20,
          height: 20,
        }}
      />
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        Pricing Assistant
      </Typography.Title>
    </Container>
  </Link>
);

export default HeaderLogo;
