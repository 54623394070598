import React from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { getCompanies, getMysteryShopShoppers } from 'apis/UserAPI';
import { isMysteryShopAdmin } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import ShopRequestsMetrics from './Metrics/ShopRequestsMetrics';
import { ShopRequestsContext } from './ShopRequestsContext';
import ShopRequestsData from './ShopRequestsData';
import ShopRequestsOverviewHeader from './ShopRequestsOverviewHeader';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-flow: column;
`;

const ShopRequestsOverview = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const isUserShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);

  const { data: staffUsers } = useQuery({
    queryKey: ['staffUsers'],
    queryFn: () => getMysteryShopShoppers(),
    refetchOnWindowFocus: false,
  });
  const { data: companies } = useQuery({
    queryKey: ['allcompanies'],
    queryFn: () => getCompanies(null),
    refetchOnWindowFocus: false,
  });

  return (
    <Container>
      <ShopRequestsContext.Provider
        value={{
          staffUsers,
          companies: companies?.results ?? [],
        }}
      >
        <ShopRequestsOverviewHeader />
        {isUserShopRequestAdmin && <ShopRequestsMetrics />}
        <ShopRequestsData />
      </ShopRequestsContext.Provider>
    </Container>
  );
};

export default ShopRequestsOverview;
