import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useShareViaEmailContext } from './ShareViaEmailContext';
import ShareViaEmailFooter from './ShareViaEmailFooter';
import ShareViaEmailHeader from './ShareViaEmailHeader';
import ShareViaEmailUsers from './ShareViaEmailUsers';
import { User } from './types';
import Input from '../../lib/Input';
import InputMultipleEmails from '../../lib/InputMultipleEmails';
import TextArea from '../../lib/TextArea';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  padding-bottom: 10px;
`;

const UserContainer = styled.div`
  margin-top: 20px;
  max-height: 200px;
  overflow: auto;
`;

const Label = styled.h1`
  font-size: 14px;
  color: #222;
  margin-top: 27px;
  margin-bottom: 5px;
`;

const Paragraph = styled.p`
  font-size: 12px;
  color: var(--gray-dark);
`;

const ShareViaEmailMainContent = () => {
  const { emails, users, selectedUsers, note, setNote, setEmails, handleSelect, handleDeselect, handleUserSearch } =
    useShareViaEmailContext();

  return (
    <Container>
      <Content>
        <ShareViaEmailHeader />
        <Paragraph>
          The current view with applied filters will be sent via email as a clickable link, to the people below.
        </Paragraph>
        <Label>New Email Addresses</Label>
        <InputMultipleEmails emails={emails} setEmails={setEmails} />
        <Label className="mt">Existing Users</Label>
        <Input prefix={<SearchOutlined />} placeholder="Search People to share to" onChange={handleUserSearch} />
        <UserContainer className="mini-scroll">
          {selectedUsers.map((user: any) => (
            <ShareViaEmailUsers key={`selected-${user.id}`} checked={true} user={user} handleChange={handleDeselect} />
          ))}
          {users
            .filter((user: User) => !selectedUsers.some((selectedUser: User) => selectedUser.id === user.id))
            .map((user: any) => (
              <ShareViaEmailUsers key={user.id} user={user} handleChange={handleSelect} />
            ))}
        </UserContainer>
        <Label>Note (optional)</Label>
        <TextArea
          value={note}
          onChange={(e: any) => setNote(e.target.value)}
          placeholder="Add an optional note here to display in the email"
          rows={4}
        />
      </Content>
      <ShareViaEmailFooter />
    </Container>
  );
};

export default ShareViaEmailMainContent;
