import React from 'react';
import { useQuery } from 'react-query';

import { Button, Col, Form, Input, InputNumber, message, Row, Select, Spin } from 'antd';

import axios from 'config/axiosPrivate';

import CommunityTitleBar from './common/CommunityTitleBar';
const { TextArea } = Input;

interface Props {
  community_id: any;
}

const CommunityDetails = ({ community_id }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = React.useState(true);
  const [unsaved, setUnSaved] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Data updated successfully!',
    });
  };

  const cmm_details = useQuery({
    queryKey: `Get_CommunityDetails_${community_id}`,
    queryFn: () => axios.get(`/communities/Get_CommunityDetails/${community_id}`).then((res) => res.data?.payload[0]),
    onSuccess: (data) => {
      setLoading(false);
      setTimeout(function () {
        if (data) {
          form.setFieldsValue({ ...data });
        } else {
          form.resetFields();
        }
      }, 1000);
    },
    refetchOnWindowFocus: false,
  });
  React.useEffect(() => {
    setLoading(true);
  }, [community_id]);
  const onFinish = (values: any) => {
    const payload = {
      apartment_id: community_id,
      id: cmm_details?.data ? cmm_details?.data?.id : 1,
      services_no_of_daily_meals: values?.services_no_of_daily_meals || null,
      services_meal_program: values?.services_meal_program || null,
      services_additional_meal_charges: values?.services_additional_meal_charges || null,
      services_multiple_dining_rooms: values?.services_multiple_dining_rooms || null,
      services_housekeeping_frequency: values?.services_housekeeping_frequency || null,
      services_personal_laundry: values?.services_personal_laundry || null,
      staffing_hour_24_onsite_staff: values?.staffing_hour_24_onsite_staff || null,
      staffing_security_pt_ft: values?.staffing_security_pt_ft || null,
      staffing_concierge_service: values?.staffing_concierge_service || null,
      staffing_transportation_frequency: values?.staffing_transportation_frequency || null,
      staffing_activities: values?.staffing_activities || null,
      staffing_transition_program: values?.staffing_transition_program || null,
      staffing_valet_service: values?.staffing_valet_service || null,
      building_art_crafts_room: values?.building_art_crafts_room || null,
      building_beauty_barber_shop: values?.building_beauty_barber_shop || null,
      building_chapel: values?.building_chapel || null,
      building_coffee_shop: values?.building_coffee_shop || null,
      building_bar_pub: values?.building_bar_pub || null,
      building_dog_park: values?.building_dog_park || null,
      building_game_room: values?.building_game_room || null,
      building_comupter_for_residents: values?.building_comupter_for_residents || null,
      building_exercise_room: values?.building_exercise_room || null,
      building_garden_area: values?.building_garden_area || null,
      building_library: values?.building_library || '',
      building_pool: values?.building_pool || '',
      building_spa: values?.building_spa || '',
      building_store: values?.building_store || '',
      building_outdoor_space_includes: values?.building_outdoor_space_includes || '',
      building_theater: values?.building_theater || '',
      building_others: values?.building_others || '',

      kitchen_countertops: values?.kitchen_countertops || '',
      kitchen_stove_oven: values?.kitchen_stove_oven || '',
      kitchen_microwave: values?.kitchen_microwave || '',
      kitchen_refrigerator: values?.kitchen_refrigerator || '',
      bathroom_countertops: values?.bathroom_countertops || '',
      bathroom_bath_tub: values?.bathroom_bath_tub || '',
      bathroom_walk_in_shower: values?.bathroom_walk_in_shower || '',
      livingarea_flooring: values?.livingarea_flooring || '',
      other_walk_in_closets: values?.other_walk_in_closets || '',
      other_patios_balconies: values?.other_patios_balconies || '',
      other_windo_coverings: values?.other_windo_coverings || '',
      other_washer_dryer_connections: values?.other_washer_dryer_connections || '',
      other_washer_dryer_in_unit: values?.other_washer_dryer_in_unit || '',
      other_ac: values?.other_ac || '',
      parking_surface: values?.parking_surface || '',
      parking_carport: values?.parking_carport || '',
      parking_detached_garage: values?.parking_detached_garage || '',
      parking_attached_garage: values?.parking_attached_garage || '',
      parking_structure: values?.parking_structure || '',
      parking_storage: values?.parking_storage || '',
      parking_storage_rate: values?.parking_storage_rate || '',
      utilities_water: values?.utilities_water || '',
      utilities_sewer: values?.utilities_sewer || '',
      utilities_trash: values?.utilities_trash || '',
      utilities_gas: values?.utilities_gas || '',
      utilities_electric: values?.utilities_electric || '',
      utilities_wifi: values?.utilities_wifi || '',
      utilities_telephone: values?.utilities_telephone || '',
      utilities_cable_internet: values?.utilities_cable_internet || '',
      additional_notes: values?.additional_notes || '',
      disabled: false,
      op: cmm_details?.data ? 'edited' : 'added',
    };
    //
    setLoading(true);
    axios
      .post('/communities/Save_CommunityDetails/', { daya: [payload] })
      .then((res) => {
        setUnSaved(false);
        success();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const lookup_1 = useQuery({
    queryKey: 'Get_ComDetailLookup_1',
    queryFn: () => axios.get(`/communities/Get_ComDetailLookup/1`).then((res) => res.data?.payload.LookUp),
    refetchOnWindowFocus: false,
  });

  const lookup_2 = useQuery({
    queryKey: 'Get_ComDetailLookup_2',
    queryFn: () => axios.get(`/communities/Get_ComDetailLookup/2`).then((res) => res.data?.payload.LookUp),
  });
  const lookup_3 = useQuery({
    queryKey: 'Get_ComDetailLookup_3',
    queryFn: () => axios.get(`/communities/Get_ComDetailLookup/3`).then((res) => res.data?.payload.LookUp),
  });
  const lookup_4 = useQuery({
    queryKey: 'Get_ComDetailLookup_4',
    queryFn: () => axios.get(`/communities/Get_ComDetailLookup/4`).then((res) => res.data?.payload.LookUp),
  });
  const lookup_6 = useQuery({
    queryKey: 'Get_ComDetailLookup_6',
    queryFn: () => axios.get(`/communities/Get_ComDetailLookup/6`).then((res) => res.data?.payload.LookUp),
  });
  const lookup_7 = useQuery({
    queryKey: 'Get_ComDetailLookup_7',
    queryFn: () => axios.get(`/communities/Get_ComDetailLookup/7`).then((res) => res.data?.payload.LookUp),
  });
  const lookup_8 = useQuery({
    queryKey: 'Get_ComDetailLookup_8',
    queryFn: () => axios.get(`/communities/Get_ComDetailLookup/8`).then((res) => res.data?.payload.LookUp),
  });

  const yesNoOptions = lookup_1.data
    ? lookup_1.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];
  const includeOptions = lookup_6.data
    ? lookup_6.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];
  const graniteQtzOptions = lookup_3.data
    ? lookup_3.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];
  const miniBlindOptions = lookup_4.data
    ? lookup_4.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];
  const mealOptions = lookup_1.data
    ? lookup_1.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];
  const freqOptions = lookup_6.data
    ? lookup_6.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];
  const stoveOptions = lookup_7.data
    ? lookup_7.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];
  const floorOptions = lookup_8.data
    ? lookup_8.data.map((el: any) => ({
        label: el?.lookup_value_display,
        value: el?.lookup_value_display,
      }))
    : [];

  return (
    <div style={{ padding: '20px 30px' }}>
      {contextHolder}
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 200,
          }}
        >
          <Spin />
        </div>
      ) : (
        <Form
          onFinish={onFinish}
          onValuesChange={() => (!unsaved ? setUnSaved(true) : null)}
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
          form={form}
          style={{ maxWidth: '1200px', margin: '0 auto' }}
        >
          <CommunityTitleBar title="Services" />
          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="services_no_of_daily_meals"
                label={<p className="form_label"># of Daily Meals Included in Rent:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Enter # of Daily Meals Included in Rent',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="services_meal_program"
                label={<p className="form_label">Meal Program :</p>}
                rules={[{ required: false, message: 'Please Select Meal Program' }]}
              >
                <Select options={mealOptions} placeholder="Select" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="services_additional_meal_charges"
                label={<p className="form_label">Additional Meal Charge:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Enter Additional Meal Charge',
                  },
                ]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="services_multiple_dining_rooms"
                label={<p className="form_label">Multiple Dining Rooms:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Multiple Dining Rooms',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                name="services_housekeeping_frequency"
                label={<p className="form_label">Housekeeping Frequency:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Housekeeping Frequency',
                  },
                ]}
              >
                <Select options={freqOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="services_personal_laundry"
                label={<p className="form_label">Personal Laundry:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Personal Laundry',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <CommunityTitleBar title="Staffing" />
          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="staffing_hour_24_onsite_staff"
                label={<p className="form_label">24 Hour Onsite Staff:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select 24 Hour Onsite Staff',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="staffing_security_pt_ft"
                label={<p className="form_label">Security / PT or FT:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Security / PT or FT',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="staffing_concierge_service"
                label={<p className="form_label">Concierge Service:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Concierge Service',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="staffing_transportation_frequency"
                label={<p className="form_label">Transportation / Frequency:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Transportation / Frequency',
                  },
                ]}
              >
                <Select options={includeOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="staffing_activities"
                label={<p className="form_label">Activities:</p>}
                rules={[{ required: false, message: 'Please Enter Activities' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="staffing_transition_program"
                label={<p className="form_label">Transition Support:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Enter Transition Support',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="staffing_valet_service"
                label={<p className="form_label">Valet Service:</p>}
                rules={[{ required: false, message: 'Please Enter Valet Service' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <CommunityTitleBar title="Building Amenities" />

          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_art_crafts_room"
                label={<p className="form_label">Art & Crafts Room:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Selct Art & Crafts Room',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_beauty_barber_shop"
                label={<p className="form_label">Beauty/ Barber Shop:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Beauty/ Barber Shop',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_game_room"
                label={<p className="form_label">Billiards/Game Room:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Billiards/Game Room',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_chapel"
                label={<p className="form_label">Chapel:</p>}
                rules={[{ required: false, message: 'Please Select Chapel' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_coffee_shop"
                label={<p className="form_label">Coffee Shop:</p>}
                rules={[{ required: false, message: 'Please Select Coffee Shop' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_bar_pub"
                label={<p className="form_label">Bar / Pub:</p>}
                rules={[{ required: false, message: 'Please Select Bar / Pub' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_dog_park"
                label={<p className="form_label">Dog Park:</p>}
                rules={[{ required: false, message: 'Please Select Dog Park' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_comupter_for_residents"
                label={<p className="form_label">Computer for Residents:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Computer for Residents',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_exercise_room"
                label={<p className="form_label">Exercise Room:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Exercise Room:',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_garden_area"
                label={<p className="form_label">Garden Area:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Garden Area',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_library"
                label={<p className="form_label">Library:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Library',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_pool"
                label={<p className="form_label">Pool:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Pool',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_spa"
                label={<p className="form_label">Spa:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Spa',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_store"
                label={<p className="form_label">Store</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Store',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_outdoor_space_includes"
                label={<p className="form_label">Outdoor Space Includes:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Outdoor Space Includes:',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_theater"
                label={<p className="form_label">Theater:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Theater',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="building_others"
                label={<p className="form_label">Other:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Other',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <CommunityTitleBar title="Kitchen" />

          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="kitchen_countertops"
                label={<p className="form_label">Countertops:</p>}
                rules={[{ required: false, message: 'Please Select Countertops' }]}
              >
                <Select options={graniteQtzOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="kitchen_stove_oven"
                label={<p className="form_label">Stove/Oven:</p>}
                rules={[{ required: false, message: 'Please Select Stove/Oven' }]}
              >
                <Select options={stoveOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="kitchen_refrigerator"
                label={<p className="form_label">Refrigerator:</p>}
                rules={[{ required: false, message: 'Please Select Refrigerator' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="kitchen_microwave"
                label={<p className="form_label">Microwave:</p>}
                rules={[{ required: false, message: 'Please Select Microwave' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <CommunityTitleBar title="Bathroom" />

          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="bathroom_countertops"
                label={<p className="form_label">Countertops:</p>}
                rules={[{ required: false, message: 'Please Select Countertops' }]}
              >
                <Select options={graniteQtzOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="bathroom_bath_tub"
                label={<p className="form_label">Bath Tub:</p>}
                rules={[{ required: false, message: 'Please Select Bath Tub' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="bathroom_walk_in_shower"
                label={<p className="form_label">Walk-in Shower:</p>}
                rules={[{ required: false, message: 'Please Select Walk-in Shower' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <CommunityTitleBar title="Living Area" />

          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="livingarea_flooring"
                label={<p className="form_label">Flooring:</p>}
                rules={[{ required: false, message: 'Please Select Flooring' }]}
              >
                <Select options={floorOptions} placeholder="Select" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <CommunityTitleBar title="Other" />

          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="other_walk_in_closets"
                label={<p className="form_label">Walk-in Closets:</p>}
                rules={[{ required: false, message: 'Please Select Walk-in Closets' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="other_patios_balconies"
                label={<p className="form_label">Patios/Balconies:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Patios/Balconies',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="other_windo_coverings"
                label={<p className="form_label">Window Coverings:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Window Coverings',
                  },
                ]}
              >
                <Select options={miniBlindOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="other_washer_dryer_connections"
                label={<p className="form_label">Washer/Dryer Connection:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Washer/Dryer Connection',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="other_washer_dryer_in_unit"
                label={<p className="form_label">Washer/Dryer in Unit:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Washer/Dryer in Unit',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="other_ac"
                label={<p className="form_label">A/C:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select A/C',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <CommunityTitleBar title="Parking Rates" />

          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="parking_surface"
                label={<p className="form_label">Surface:</p>}
                rules={[{ required: false, message: 'Please Enter Surface' }]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="parking_carport"
                label={<p className="form_label">Carport:</p>}
                rules={[{ required: false, message: 'Please Enter Carport' }]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="parking_detached_garage"
                label={<p className="form_label">Detached Garage:</p>}
                rules={[{ required: false, message: 'Please Enter Detached Garage' }]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="parking_attached_garage"
                label={<p className="form_label">Attached Garage:</p>}
                rules={[{ required: false, message: 'Please Enter Attached Garage' }]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="parking_structure"
                label={<p className="form_label">Structure:</p>}
                rules={[{ required: false, message: 'Please Enter Structure' }]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="parking_storage"
                label={<p className="form_label">Storage:</p>}
                rules={[{ required: false, message: 'Please Enter Storage' }]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="parking_storage_rate"
                label={<p className="form_label">Storage Rate:</p>}
                rules={[{ required: false, message: 'Please Enter Storage Rate' }]}
              >
                <InputNumber addonBefore="$" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <CommunityTitleBar title="Utilities" />

          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_water"
                label={<p className="form_label">Water:</p>}
                rules={[{ required: false, message: 'Please Select Water' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_sewer"
                label={<p className="form_label">Sewer:</p>}
                rules={[{ required: false, message: 'Please Select Sewer' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_trash"
                label={<p className="form_label">Trash:</p>}
                rules={[{ required: false, message: 'Please Select Trash' }]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_gas"
                label={<p className="form_label">Gas:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Gas',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_electric"
                label={<p className="form_label">Electric:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Electric',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_wifi"
                label={<p className="form_label">Wifi:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Wifi',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_telephone"
                label={<p className="form_label">Telephone:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Telephone',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="utilities_cable_internet"
                label={<p className="form_label">Cable/Internet:</p>}
                rules={[
                  {
                    required: false,
                    message: 'Please Select Cable/Internet',
                  },
                ]}
              >
                <Select options={yesNoOptions} placeholder="Select" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <CommunityTitleBar title="Additional Notes" />
          <Row gutter={[20, 10]}>
            <Col xs={24} md={24}>
              <Form.Item
                name="additional_notes"
                label={<p className="form_label">Notes:</p>}
                rules={[{ required: false, message: 'Enter 2nd Person Fee' }]}
              >
                <TextArea showCount maxLength={1000} style={{ height: 120, resize: 'none' }} placeholder="Add A Note" />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <div
                style={{
                  background: '#eee',
                  borderRadius: 8,
                  padding: 10,
                  textAlign: 'right',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 10, fontWeight: 600 }}
                  loading={loading}
                  disabled={false}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default CommunityDetails;
