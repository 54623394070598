import React, { useContext } from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons/lib';
import styled from 'styled-components';

type Props = {
  showCompetitors: boolean;
  setShowCompetitors: React.Dispatch<React.SetStateAction<boolean>>;
};

const Container = styled.div`
  padding: 0px 10px 0px 10px;
`;

const Selector = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  padding: 11px 14px 11px 20px;
`;

const SelectorLabel = styled.span`
  font-size: 10px;
  color: #fff;
  opacity: 0.6;
  margin-right: 2px;
`;
const SelectorIcon = styled.span`
  width: 18px;
  height: 18px;
  &::after {
    content: '';
    display: block;
    width: 9px;
    height: 6px;
    margin: 6px 4.5px;
    background-image: url('/icons/dropdown-caret-down.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const SidebarMenuQuickCompetitorsSelectorButton = ({ showCompetitors, setShowCompetitors }: Props) => {
  return (
    <Container>
      <Selector onClick={() => setShowCompetitors(!showCompetitors)}>
        <SelectorLabel>COMPETITOR DATA</SelectorLabel>
        <SelectorIcon />
      </Selector>
    </Container>
  );
};

export default SidebarMenuQuickCompetitorsSelectorButton;
