import React, { createContext, useContext } from 'react';

import { SHOP_REQUESTS_PAGE_SIZE } from './constants';
import { StaffUser } from './types';

type ShopRequestsContextType = {
  staffUsers: StaffUser[];
  companies: any[];
};

export const ShopRequestsContext = createContext<ShopRequestsContextType>({
  staffUsers: [],
  companies: [],
});

export const useShopRequestsContext = () => useContext(ShopRequestsContext);
