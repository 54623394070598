import React, { ReactNode } from 'react';
import { useParams } from 'react-router';

import { Button, Typography } from 'antd';
import styled from 'styled-components';

import SettingsIcon from 'components/Settings/SettingsIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 30px;
  border-bottom: 1px solid var(--line-gray);
  background-color: white;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 40px;
  font-weight: 900;
  padding: 3px 0px;
`;

const Description = styled(Typography.Text)`
  color: var(--text-secondary);
  margin: 8px 0 0 50px;
`;

const IconTitleContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 5px 23.5px 8px 24px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(19, 33, 90, 0.15);
  background-color: var(--button-blue);
  font-size: 14px;
  font-weight: 900;
  color: var(--white);
`;
const Link = styled.a`
  text-decoration: none;
`;

const Image = styled.span`
  width: 22px;
  height: 18.5px;
  background-image: url('/icons/auto-graph.svg');
  margin-right: 4.5px;
`;

interface Props {
  title: string;
  description?: string;
  breadcrumbs: ReactNode;
  iconSrc?: string;
  iconBackgroundColor?: string;
  children?: ReactNode;
}

const Header = ({ title, breadcrumbs, iconSrc, iconBackgroundColor, description, children }: Props) => {
  const { competitorId } = useParams();
  let icon;
  if (iconSrc) {
    icon = (
      <SettingsIcon
        style={{ backgroundColor: iconBackgroundColor }}
        size={40}
        src={<img src={iconSrc} style={{ width: '20px', height: '20px' }} />}
        largeRadius
        boxShadow
      />
    );
  }

  let descriptionContainer;
  if (description) {
    descriptionContainer = <Description>{description}</Description>;
  }

  return (
    <Container>
      <HeaderContent>
        <Info>
          {breadcrumbs}
          <IconTitleContainer>
            {icon}
            <Title>{title}</Title>
            {!!competitorId && (
              <Link href={`/community/${competitorId}`} target="_blank">
                <StyledButton type="primary">
                  <Image />
                  Open Overview
                </StyledButton>
              </Link>
            )}
          </IconTitleContainer>
          {descriptionContainer}
        </Info>
      </HeaderContent>
      {children}
    </Container>
  );
};

export default Header;
