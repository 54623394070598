import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { useAppSelector } from 'store/hook';
import { generateMgmtCompanyRatings } from 'utils/data/reviews';

import OverviewAverages from './OverviewAverages';
import { OverviewContext } from './OverviewContext';
import { OverviewStatsContext } from './OverviewStatsContext';
import { OverviewStatsDataType } from './types';
import axios from '../../config/axiosPrivate';
import { processUserCommunitiesAverages } from '../../utils/data/communities';

const OverviewStats = () => {
  const { managementCompanyId } = useParams();

  const { selectedClient, selectedCompany } = useAppSelector((state) => state.global);

  const { communities, competitors } = useContext(OverviewContext);

  const [averagesOverview, setAveragesOverview] = useState<OverviewStatsDataType>();

  useQuery({
    queryKey: [communities, selectedClient, selectedCompany, managementCompanyId, competitors],
    queryFn: () =>
      axios.post('/communities/Get_UserCommunitiesAverages/', {
        client_id: selectedClient?.id,
        company_list: selectedCompany ? [selectedCompany.id] : [],
        management_company_list: managementCompanyId ? [parseInt(managementCompanyId)] : [],
        comm_list: communities.map((item: any) => item.id),
        caretype_list: [],
      }),
    onSuccess: (response) => {
      const communitiesAverages = processUserCommunitiesAverages(response);
      const averageRating = generateMgmtCompanyRatings(communities, competitors);

      setAveragesOverview({
        ...communitiesAverages,
        ...averageRating,
      });
    },
    refetchOnWindowFocus: false,
  });

  return (
    <OverviewStatsContext.Provider value={{ overviewStats: averagesOverview }}>
      <OverviewAverages />
    </OverviewStatsContext.Provider>
  );
};

export default OverviewStats;
