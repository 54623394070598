import React from 'react';

import styled from 'styled-components';

import Button from 'components/lib/Button';
import { isMysteryShopAdmin } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
`;

const StyledDangerButton = styled(Button)`
  color: var(--fa7550);
  border-color: var(--fa7550);
  background-color: rgba(250, 117, 80, 0.09);
`;

type Props = {
  onOk: () => void;
  onCancel: () => void;
  onDelete: () => void;
};

const EditShopRequestModalFooter = ({ onOk, onCancel, onDelete }: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { isLoading } = useEditShopRequestModalContext();
  const isShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);

  return (
    <Container>
      {isShopRequestAdmin ? (
        <StyledDangerButton danger onClick={onDelete} disabled={isLoading}>
          Delete
        </StyledDangerButton>
      ) : (
        <div />
      )}
      <div>
        <Button type="primary" onClick={onOk} disabled={isLoading}>
          Save
        </Button>
        <Button onClick={onCancel}>Close</Button>
      </div>
    </Container>
  );
};

export default EditShopRequestModalFooter;
