import React from 'react';

import classNames from 'classnames';

import { useAppSelector } from '../../../store/hook';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';

const HeaderViewFiltersReviewCategory = () => {
  const { viewFilters, setViewFilters } = useCommunityViewContext();

  const { reviewCategories } = useAppSelector((state) => state.global);

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, reviewCategories: values.map((item: any) => item.value) }));
  };

  const options = reviewCategories?.map((item) => ({
    label: item.label,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: viewFilters?.reviewCategories?.length > 0 })}
      placeholder={'Review Category'}
      options={options}
      defaultValue={viewFilters.reviewCategories}
      onSelect={handleSelect}
    />
  );
};

export default HeaderViewFiltersReviewCategory;
