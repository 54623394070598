import { COMMUNITY_TYPE } from '../../constants';

export const GOOGLE_MAP_HEIGHT = 350;
export const GOOGLE_MAP_MARKER_SIZE = 16;
export const GOOGLE_MAP_MARKER_INFO_OFFSET = 14;
export const USA_CENTER_COORDINATES = {
  lat: 44.5,
  lng: -89.5,
};

export const COMMUNITIES_AVATAR_SIZE = 30;
export const COMMUNITIES_PAGE_SIZE = 5;
export const COMMUNITIES_INDENT_SIZE = 35;

export const COMMUNITIES_TABLE_TITLE = {
  [COMMUNITY_TYPE.COMMUNITY]: 'My Communities',
  [COMMUNITY_TYPE.COMPETITOR]: 'Competitors',
};

export const COMMUNITIES_TABLE_SEARCH_PLACEHOLDER = {
  [COMMUNITY_TYPE.COMMUNITY]: 'Search communities',
  [COMMUNITY_TYPE.COMPETITOR]: 'Search competitor communities',
};

export const COMMUNITIES_TABLE_FIRST_COLUMN_TITLE = {
  [COMMUNITY_TYPE.COMMUNITY]: 'COMMUNITY NAME',
  [COMMUNITY_TYPE.COMPETITOR]: 'COMPETITOR COMMUNITY NAME',
};
