import React, { useState } from 'react';

import { MenuProps } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import Button from '../../lib/Button';
import Dropdown from '../../lib/Dropdown';
import { useCommunityViewContext } from '../CommunityViewContext';
import DeleteViewModal from '../modals/DeleteViewModal';
import DuplicateViewModal from '../modals/DuplicateViewModal';
import RenameViewModal from '../modals/RenameViewModal';
import ResetLayoutModal from '../modals/ResetLayoutModal';

const Container = styled.div`
  .icon-wrapper {
    padding: 7px;
  }
`;

const HeaderViewActionsControls = () => {
  const { view } = useCommunityViewContext();

  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showResetLayoutModal, setResetLayoutModal] = useState<boolean>(false);

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;

  const items: MenuProps['items'] = [];
  if (permissions?.indexOf('views.add_view') !== -1) {
    items?.push({
      label: 'Duplicate View',
      key: 'duplicate',
      onClick: () => setShowDuplicateModal(true),
    });
  }
  if (permissions?.indexOf('views.change_view') !== -1) {
    items?.push({
      label: 'Rename View',
      key: 'rename',
      disabled: view?.default,
      onClick: () => setShowRenameModal(true),
    });
  }
  if (permissions?.indexOf('views.delete_view') !== -1) {
    items?.push({
      label: 'Delete View',
      key: 'delete',
      disabled: view?.default,
      onClick: () => setShowDeleteModal(true),
    });
  }
  items.push({
    label: 'Reset Layout',
    key: 'reset-layout',
    disabled: false,
    onClick: () => setResetLayoutModal(true),
  });

  return (
    <Container>
      <DuplicateViewModal open={showDuplicateModal} onClose={() => setShowDuplicateModal(false)} />
      <RenameViewModal open={showRenameModal} onClose={() => setShowRenameModal(false)} />
      <DeleteViewModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
      <ResetLayoutModal open={showResetLayoutModal} onClose={() => setResetLayoutModal(false)} />

      <Dropdown menu={{ items }}>
        <Button className="icon-wrapper">
          <img src="/icons/elipsis.svg" alt="custom" />
        </Button>
      </Dropdown>
    </Container>
  );
};

export default HeaderViewActionsControls;
