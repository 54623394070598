import React, { createContext, useContext } from 'react';

type AccommodationContent = {
  communityId: number | null;
  showMessage: (type: 'success' | 'error', message: string) => void;
};

export const AccommodationContext = createContext<AccommodationContent>({
  communityId: null,
  showMessage: () => {
    // Does nothing
  },
});
export const useAccommodationContext = () => useContext(AccommodationContext);
