export const INCENTIVES_SAVINGS_CONTENT_TYPE = {
  COMMON: 'common',
  CUSTOM_AMOUNT: 'custom_amount',
  CUSTOM: 'custom',
};

export const INCENTIVES_FEES_TYPE = {
  CUSTOM: 'custom',
  MONTHLY_RENT: 'monthly_rent',
  COMMUNITY_FEE: 'community_fee',
  MOVE_IN_FEE: 'move_in_fee',
  PET_FEE: 'pet_fee',
  PARKING_FEE: 'parking_fee',
  DEPOSIT: 'deposit',
  ADDITIONAL_FEE: 'additional_fee',
  SECOND_PERSON_FEE: 'second_person_fee',
};

export const INCENTIVES_FEES_TYPES_LIST = [
  INCENTIVES_FEES_TYPE.MONTHLY_RENT,
  INCENTIVES_FEES_TYPE.COMMUNITY_FEE,
  INCENTIVES_FEES_TYPE.SECOND_PERSON_FEE,
  INCENTIVES_FEES_TYPE.PET_FEE,
  INCENTIVES_FEES_TYPE.PARKING_FEE,
  INCENTIVES_FEES_TYPE.ADDITIONAL_FEE,
  INCENTIVES_FEES_TYPE.DEPOSIT,
];

export const PROPOSAL_MODAL_INCENTIVES_FEES_TYPE = {
  MONTHLY_RENT: 'monthly_rent',
  COMMUNITY_FEE: 'community_fee',
  SECOND_PERSON_FEE: 'second_person_fee',
  PET_FEE: 'pet_fee',
  PARKING_FEE: 'parking_fee',
  ADDITIONAL_FEE: 'additional_fee',
  DEPOSIT: 'deposit',
};

export const INCENTIVES_FEES_TYPE_LABELS = {
  [INCENTIVES_FEES_TYPE.CUSTOM]: 'Custom',
  [INCENTIVES_FEES_TYPE.MOVE_IN_FEE]: 'Move-in Fee',
  [INCENTIVES_FEES_TYPE.COMMUNITY_FEE]: 'Community Fee',
  [INCENTIVES_FEES_TYPE.MONTHLY_RENT]: 'Monthly Rent',
  [INCENTIVES_FEES_TYPE.DEPOSIT]: 'Deposit',
  [INCENTIVES_FEES_TYPE.SECOND_PERSON_FEE]: '2nd Person Fee',
  [INCENTIVES_FEES_TYPE.PET_FEE]: 'Pet Fee',
  [INCENTIVES_FEES_TYPE.PARKING_FEE]: 'Parking Fee',
  [INCENTIVES_FEES_TYPE.ADDITIONAL_FEE]: 'Additional Fee',
};

export const INCENTIVES_SAVINGS_DISCOUNT_TYPE = {
  AMOUNT: 'amount',
  PERCENTAGE: 'percentage',
};

export const INCENTIVES_SAVINGS_DURATION_TYPE = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
  FOREVER: 'forever',
};

export const INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR = {
  [INCENTIVES_SAVINGS_DURATION_TYPE.MONTH]: 1,
  [INCENTIVES_SAVINGS_DURATION_TYPE.YEAR]: 12,
  [INCENTIVES_SAVINGS_DURATION_TYPE.FOREVER]: 12,
};

export const INCENTIVES_SAVINGS_DISCOUNT_DURATION = {
  ONE_MONTH: 10,
  TWO_MONTHS: 20,
  THREE_MONTHS: 30,
  FOUR_MONTHS: 40,
  SIX_MONTHS: 50,
  ONE_YEAR: 60,
  TWO_YEARS: 70,
  FOREVER: 80,
};

export const INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR = {
  ONE_MONTH: 1,
  TWO_MONTHS: 2,
  THREE_MONTHS: 3,
  FOUR_MONTHS: 4,
  SIX_MONTHS: 6,
  ONE_YEAR: 12,
  TWO_YEARS: 24,
  FOREVER: 1,
};

export const INCENTIVES_SAVINGS_DISCOUNT_DURATION_MAP = {
  [INCENTIVES_SAVINGS_DURATION_TYPE.MONTH]: {
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.ONE_MONTH]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.ONE_MONTH,
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.TWO_MONTHS]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.TWO_MONTHS,
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.THREE_MONTHS]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.THREE_MONTHS,
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.FOUR_MONTHS]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.FOUR_MONTHS,
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.SIX_MONTHS]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.SIX_MONTHS,
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.ONE_YEAR]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.ONE_YEAR,
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.TWO_YEARS]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.TWO_YEARS,
  },
  [INCENTIVES_SAVINGS_DURATION_TYPE.YEAR]: {},
  [INCENTIVES_SAVINGS_DURATION_TYPE.FOREVER]: {
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.FOREVER]: INCENTIVES_SAVINGS_DISCOUNT_DURATION.FOREVER,
  },
};

export const INCENTIVES_SAVINGS_DISCOUNT_DURATION_LABELS = {
  [INCENTIVES_SAVINGS_DURATION_TYPE.MONTH]: {
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.ONE_MONTH]: 'for 1 month',
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.TWO_MONTHS]: 'for 2 month',
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.THREE_MONTHS]: 'for 3 month',
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.FOUR_MONTHS]: 'for 4 month',
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.SIX_MONTHS]: 'for 6 month',
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.ONE_YEAR]: 'for 1 year',
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.TWO_YEARS]: 'for 2 years',
  },
  [INCENTIVES_SAVINGS_DURATION_TYPE.YEAR]: {},
  [INCENTIVES_SAVINGS_DURATION_TYPE.FOREVER]: {
    [INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.FOREVER]: '/ year',
  },
};

export const INCENTIVES_SAVINGS_DISCOUNT_DURATION_VALUES = {
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.ONE_MONTH]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.ONE_MONTH,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
  },
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.TWO_MONTHS]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.TWO_MONTHS,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
  },
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.THREE_MONTHS]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.THREE_MONTHS,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
  },
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.FOUR_MONTHS]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.FOUR_MONTHS,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
  },
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.SIX_MONTHS]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.SIX_MONTHS,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
  },
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.ONE_YEAR]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.ONE_YEAR,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
  },
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.TWO_YEARS]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.TWO_YEARS,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.MONTH,
  },
  [INCENTIVES_SAVINGS_DISCOUNT_DURATION.FOREVER]: {
    discount_duration: INCENTIVES_SAVINGS_DISCOUNT_DURATION_FACTOR.FOREVER,
    discount_duration_type: INCENTIVES_SAVINGS_DURATION_TYPE.FOREVER,
  },
};

export const INCENTIVES_SAVINGS_CONTENT_TYPE_OPTIONS = [
  { id: INCENTIVES_SAVINGS_CONTENT_TYPE.COMMON, name: 'Common savings' },
  { id: INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM_AMOUNT, name: 'Custom savings with amount' },
  { id: INCENTIVES_SAVINGS_CONTENT_TYPE.CUSTOM, name: 'Custom savings without amount' },
];

export const INCENTIVES_SAVINGS_TYPE_OPTIONS = [
  { id: INCENTIVES_FEES_TYPE.MONTHLY_RENT, name: 'Monthly Rent' },
  { id: INCENTIVES_FEES_TYPE.COMMUNITY_FEE, name: 'Community Fee' },
  { id: INCENTIVES_FEES_TYPE.MOVE_IN_FEE, name: 'Move In Fee' },
  { id: INCENTIVES_FEES_TYPE.DEPOSIT, name: 'Deposit' },
  { id: INCENTIVES_FEES_TYPE.PET_FEE, name: 'Pet Fee' },
  { id: INCENTIVES_FEES_TYPE.PARKING_FEE, name: 'Parking Fee' },
];

export const INCENTIVES_SAVINGS_DISCOUNT_TYPE_OPTIONS = [
  { id: INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT, name: 'Dollar Amount' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE, name: 'Percentage' },
];

export const INCENTIVES_SAVINGS_DURATION_OPTIONS = [
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.ONE_MONTH, name: '1 mo.' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.TWO_MONTHS, name: '2 mos.' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.THREE_MONTHS, name: '3 mos.' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.FOUR_MONTHS, name: '4 mos.' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.SIX_MONTHS, name: '6 mos.' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.ONE_YEAR, name: '1 yr.' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.TWO_YEARS, name: '2 yrs.' },
  { id: INCENTIVES_SAVINGS_DISCOUNT_DURATION.FOREVER, name: 'Forever' },
];

export const INCENTIVES_SAVINGS_FEE_TYPE_LABELS = {
  [INCENTIVES_FEES_TYPE.MONTHLY_RENT]: 'monthly rent',
  [INCENTIVES_FEES_TYPE.COMMUNITY_FEE]: 'community fee',
  [INCENTIVES_FEES_TYPE.MOVE_IN_FEE]: 'move in fee',
  [INCENTIVES_FEES_TYPE.DEPOSIT]: 'deposit',
  [INCENTIVES_FEES_TYPE.PET_FEE]: 'pet fee',
  [INCENTIVES_FEES_TYPE.PARKING_FEE]: 'parking fee',
};

export const DEFAULT_SAVINGS_ENTRY = {
  content_type: INCENTIVES_SAVINGS_CONTENT_TYPE.COMMON,
  type: undefined,
  name: undefined,
  discount_type: INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT,
  discount: undefined,
  discount_duration_type: undefined,
  discount_duration: undefined,
};

export const DEFAULT_SAVINGS_PERCENTAGE_ENTRY = {
  content_type: INCENTIVES_SAVINGS_CONTENT_TYPE.COMMON,
  type: undefined,
  name: undefined,
  discount_type: INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE,
  discount: undefined,
  discount_duration_type: undefined,
  discount_duration: undefined,
};
