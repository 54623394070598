import React from 'react';

import styled from 'styled-components';

import IncentivesTableIncentives from './incentive/IncentivesTableIncentives';
import IncentivesTableProposals from './proposal/IncentivesTableProposals';
import IncentivesTableUnits from './unit/IncentivesTableUnits';
import { INCENTIVES_TABS } from '../constants';
import { useIncentivesContext } from '../IncentivesContext';
import { useIncentivesDataContext } from '../IncentivesDataContext';


const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

const IncentivesTable = () => {
  const { currentTab } = useIncentivesContext();
  const { createMode } = useIncentivesDataContext();

  if (createMode) {
    return <IncentivesTableUnits />;
  }

  return (
    <Container>
      {currentTab === INCENTIVES_TABS.INCENTIVE && <IncentivesTableIncentives />}
      {currentTab === INCENTIVES_TABS.UNIT && <IncentivesTableUnits />}
      {currentTab === INCENTIVES_TABS.PROPOSAL && <IncentivesTableProposals />}
      {currentTab === INCENTIVES_TABS.ARCHIVE && <IncentivesTableUnits />}
    </Container>
  );
};

export default IncentivesTable;
