import React from 'react';

import styled from 'styled-components';

import HeaderViewFiltersControls from './HeaderViewFiltersControls';
import HeaderViewFiltersMenu from './HeaderViewFiltersMenu';
import { useCommunityViewContext } from '../CommunityViewContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  align-items: center;
`;

const HeaderViewFilters = () => {
  const { view, preview } = useCommunityViewContext();
  return (
    <Container>
      <HeaderViewFiltersMenu />
      {view?.default && !preview && <HeaderViewFiltersControls />}
    </Container>
  );
};

export default HeaderViewFilters;
