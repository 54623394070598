import React from 'react';

import { FilePdfOutlined, PictureOutlined } from '@ant-design/icons/lib';
import { Button, Tooltip } from 'antd';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import styled from 'styled-components';

import { useAppSelector } from '../../../store/hook';
import { FILE_EXTENSION, PDF_IMAGE_OFFSET, PDF_IMAGE_PADDING } from '../constants';
import { useDashboardReportsContext } from '../DashboardReportsContext';

interface Props {
  chartRef: any;
  children: React.ReactNode;
}

const ButtonContainer = styled.div`
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  gap: 10px;
  margin-top: -10px;
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 25px;
  &:hover {
    ${ButtonContainer} {
      display: flex;
    }
  }
`;

const DashboardReportsChartHandler = ({ chartRef, children }: Props) => {
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const { careTypeId } = useDashboardReportsContext();

  const getFileName = (fileExtension: string) => {
    const careType = livingTypes.find((livingType: any) => livingType.id === careTypeId);
    const careTypeName = careType?.type.replaceAll(/\s/g, '');
    const currentDate = new Date().toLocaleDateString();
    return `Dashboard_Report_${careTypeName}_Report_${currentDate}.${fileExtension}`;
  };

  const exportAsImage = () => {
    if (chartRef.current) {
      const fileName = getFileName(FILE_EXTENSION.PNG);
      //@ts-ignore
      if (typeof chartRef.current?.downloadImage == 'function') {
        chartRef.current?.downloadImage(fileName);
      } else {
        html2canvas(chartRef.current).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = imgData;
          link.download = fileName;
          link.click();
        });
      }
    }
  };

  const exportAsPDF = async () => {
    if (chartRef.current) {
      let base64data;
      if (typeof chartRef.current?.downloadImage == 'function') {
        //@ts-ignore
        base64data = chartRef.current?.toDataURL();
      } else {
        const canvas = await html2canvas(chartRef.current);
        base64data = canvas.toDataURL();
      }
      const image = new Image();
      image.src = base64data;
      image.onload = () => {
        const doc = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [image.height + PDF_IMAGE_PADDING, image.width + PDF_IMAGE_PADDING],
          hotfixes: ['px_scaling'],
        });

        const fileName = getFileName(FILE_EXTENSION.PDF);
        doc.addImage(image, FILE_EXTENSION.PNG, PDF_IMAGE_OFFSET, PDF_IMAGE_OFFSET, 0, 0);
        doc.save(fileName);
      };
    }
  };

  return (
    <Container>
      <ButtonContainer>
        <Tooltip title="Export as Image">
          <Button size="small" icon={<PictureOutlined />} onClick={exportAsImage} />
        </Tooltip>
        <Tooltip title="Export as PDF">
          <Button size="small" icon={<FilePdfOutlined />} onClick={exportAsPDF} />
        </Tooltip>
      </ButtonContainer>
      {children}
    </Container>
  );
};

export default DashboardReportsChartHandler;
