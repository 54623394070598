import React from 'react';

import styled from 'styled-components';

import CardFooter from './CardFooter';
import { getDecimalParts } from '../../../utils/helpers/numbers';
import { WIDGET_TYPES } from '../constants';
import { useWidgetContext } from '../Widget/WidgetContext';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: flex-start;
  color: var(--text-primary);
`;

const DollarSign = styled.div`
  font-size: 20px;
`;

const Amount = styled.div`
  font-size: 42px;
  line-height: 42px;
`;

const DecimalPart = styled.span`
  font-size: 20px;
`;

interface Props {
  value?: number;
  marketValue?: number;
  footerLabel: string;
}

const CardAverageContent = ({ value = 0, marketValue = 0, footerLabel }: Props) => {
  const { type } = useWidgetContext();
  const { whole, decimal } = getDecimalParts(value);
  const fixedPositions = WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE == type ? 2 : 0;
  return (
    <Content>
      <ValueContainer>
        <DollarSign>$</DollarSign>
        <Amount>
          <span>{whole}</span>
          {WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE == type && <DecimalPart>{decimal}</DecimalPart>}
        </Amount>
      </ValueContainer>
      <CardFooter
        label={footerLabel}
        value={`$${(marketValue ?? 0)?.toLocaleString(undefined, {
          minimumFractionDigits: fixedPositions,
          maximumFractionDigits: fixedPositions,
        })}`}
        positive={marketValue >= value}
      />
    </Content>
  );
};

export default CardAverageContent;
