import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: normal;
`;

const Market = styled.span`
  display: flex;
  font-size: 12px;
  gap: 4px;

  &.hidden {
    visibility: hidden;
  }
`;

const MarketValue = styled.span`
  color: var(--negative-orange);

  &.positive {
    color: var(--positive-green);
  }
`;

interface Props {
  value: number;
  marketValue: number;
  prefix?: string;
}

const OverviewCommunitiesInfoValueCell = ({ value, marketValue, prefix = '' }: Props) => (
  <Container>
    <span>
      {prefix}
      {value?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    </span>
    <Market className={classNames({ hidden: !marketValue })}>
      <span>Market:</span>
      <MarketValue className={classNames({ positive: marketValue >= value })}>
        {prefix + marketValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </MarketValue>
    </Market>
  </Container>
);

export default OverviewCommunitiesInfoValueCell;
