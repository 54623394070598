import React from 'react';

import { format } from 'date-fns';

import { ProposalType } from '../../types';

interface Props {
  record: ProposalType;
}

const IncentivesTableProposalsSent = ({ record }: Props) => (
  <span
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <span>{record.created_at && format(new Date(record.created_at), 'MMM dd, yyyy hh:mm a')}</span>
    <span
      style={{
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        color: 'var(--text-secondary)',
      }}
    >
      {record?.sender && (
        <span>
          <span>by </span>
          <span>{record.sender}</span>
        </span>
      )}
    </span>
  </span>
);

export default IncentivesTableProposalsSent;