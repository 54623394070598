import React, { useEffect, useState } from 'react';

import { Radio, RadioChangeEvent, Typography } from 'antd';
import styled from 'styled-components';

import { MARKET_RATE_COMPARISON_UPDATES_STATUS } from './constants';
import MarketRatePreferences from './MarketRatePreferences';
import SettingsModal from './SettingsModal';
import { MarketRateComparisonUpdateSettings } from './types';

const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-flow: column;
`;

const RadioButton = styled(Radio)`
  margin-top: 20px;
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  communitiesToModify: any[];
  onSave: (newStatus: MarketRateComparisonUpdateSettings) => void;
};

const MarketRateComparisonModal = ({ onClose, onSave, isOpen, communitiesToModify }: Props) => {
  const [marketRateUpdatesEnabled, setMarketRateUpdates] = useState<number | undefined>();
  const [overMarketRate, setOverMarketRate] = useState(0);
  const [underMarketRate, setUnderMarketRate] = useState(0);
  const [overMarketRateEnabled, setOverMarketRateEnabled] = useState(false);
  const [underMarketRateEnabled, setUnderMarketRateEnabled] = useState(false);

  useEffect(() => {
    if (!communitiesToModify.length || communitiesToModify.length > 1) {
      return;
    }

    const {
      enabled,
      overMarketRate: over,
      underMarketRate: under,
    } = communitiesToModify[0].market_rate_comparison_updates_status;

    setMarketRateUpdates(enabled);
    setOverMarketRateEnabled(over.enabled);
    setOverMarketRate(over.value);
    setUnderMarketRateEnabled(under.enabled);
    setUnderMarketRate(under.value);
  }, [communitiesToModify]);

  const saveChanges = () => {
    const modifiedSettings = {
      enabled: !!marketRateUpdatesEnabled,
      overMarketRate: {
        enabled: overMarketRateEnabled,
        value: overMarketRate,
      },
      underMarketRate: {
        enabled: underMarketRateEnabled,
        value: underMarketRate,
      },
    };
    onSave(modifiedSettings);
    onClose();
  };

  let marketRatePreferences;
  if (marketRateUpdatesEnabled) {
    marketRatePreferences = (
      <MarketRatePreferences
        overMarketRateEnabled={overMarketRateEnabled}
        overMarketRate={overMarketRate}
        setOverMarketRate={setOverMarketRate}
        setOverMarketRateEnabled={setOverMarketRateEnabled}
        setUnderMarketRate={setUnderMarketRate}
        underMarketRate={underMarketRate}
        underMarketRateEnabled={underMarketRateEnabled}
        setUnderMarketRateEnabled={setUnderMarketRateEnabled}
      />
    );
  }

  return (
    <SettingsModal
      title={MARKET_RATE_COMPARISON_UPDATES_STATUS.title}
      onClose={onClose}
      onSave={saveChanges}
      isOpen={isOpen}
      communitiesToModify={communitiesToModify}
      disabled={!!marketRateUpdatesEnabled && !underMarketRateEnabled && !overMarketRateEnabled}
    >
      <Typography.Text style={{ fontSize: '14px', marginTop: '10px' }}>
        {MARKET_RATE_COMPARISON_UPDATES_STATUS.description}
      </Typography.Text>
      <RadioGroup
        onChange={(event: RadioChangeEvent) => setMarketRateUpdates(event.target.value)}
        value={marketRateUpdatesEnabled}
      >
        <RadioButton value={false}>Off</RadioButton>
        <RadioButton value={true}>On</RadioButton>
      </RadioGroup>
      {marketRatePreferences}
    </SettingsModal>
  );
};

export default MarketRateComparisonModal;
