import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Button, Form, Input, message, Typography } from 'antd';
import { NoticeType } from 'antd/es/message/interface';

import axios from 'config/axiosPublic';

import ForgotPasswordChangeNotification from './ForgotPasswordChangeNotification';

const { Title } = Typography;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [step, setStep] = React.useState<number>(1);
  const [loading, setLoading] = React.useState(false);
  const [step1Data, setStep1Data] = React.useState<any>(null);
  const [error, setError] = React.useState<any>({ msg: '', show: false });

  const showMessage = (mgs: string, type: NoticeType | undefined) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const onFinish = (values: any) => {
    setLoading(true);
    if (step < 2) {
      const formData = new FormData();
      formData.append('forget', '2');
      formData.append('email', values.email.trim());
      formData.append('code', '');
      formData.append('oldpassword', '');
      formData.append('password', '');
      axios
        .post('/api/auth_changepassword/', formData)
        .then((res) => {
          setStep1Data({
            email: values.email.trim(),
          });
          if (error.show) {
            setError({ show: false, msg: '' });
          }
          setStep(2);
          setLoading(false);
        })
        .catch((err) => {
          const { msg } = err.response?.data;
          setError({ show: true, msg: msg });
          setLoading(false);
        });
    } else {
      const formData = new FormData();
      formData.append('forget', '1');
      formData.append('email', step1Data.email);
      formData.append('code', values.code);
      formData.append('oldpassword', '');
      formData.append('password', values.password);
      axios
        .post('/api/auth_changepassword/', formData)
        .then((res) => {
          showMessage('Password Changed Successfully', 'success');
          setLoading(false);
          setTimeout(() => {
            navigate('/sign-in');
          }, 2000);
        })
        .catch((err) => {
          const { msg } = err.response?.data;
          setError({ show: true, msg: msg });
          setLoading(false);
        });
    }
  };

  const cancel = () => {
    navigate('/sign-in');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {contextHolder}
      <ForgotPasswordChangeNotification />
      <div className="login__wrapper">
        <div className="login__bg__img">
          <div>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9,
                padding: '50px 20px 50px 20px',
                boxSizing: 'border-box',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <svg
                  version="1.1"
                  id="further-logo"
                  x="0px"
                  y="0px"
                  viewBox="0 0 126 21"
                  enableBackground="new 0 0 126 21"
                  width={150}
                  fill="#fff"
                >
                  <g transform="translate(.365)" fillRule="nonzero" fill="none">
                    <path
                      d="M66.204 7.73V7.7a3.183 3.183 0 0 0-.92-2.25A4.601 4.601 0 0 0 62 4.283h-4.89v11.498h1.506v-4.48h3l3.442 4.48h1.907l-3.643-4.718c1.815-.443 2.882-1.652 2.882-3.333zm-7.58-1.987h3.347c1.744 0 2.744.732 2.744 2.003v.034c0 1.28-1.096 2.112-2.79 2.112H58.64l-.016-4.15zM29.02 15.769h1.505v-4.881h6.099V9.445h-6.099V5.73h6.793V4.283H29.02zM49.753 10.934c0 2.304-1.15 3.576-3.237 3.576s-3.296-1.33-3.296-3.652V4.283h-1.506v6.65c0 3.087 1.828 5.02 4.773 5.02 2.944 0 4.768-1.966 4.768-5.111V4.283h-1.506l.004 6.65zM121.353 11.05c1.815-.418 2.882-1.639 2.882-3.32V7.7a3.2 3.2 0 0 0-.92-2.25 4.601 4.601 0 0 0-3.284-1.167h-4.881v11.498h1.506v-4.48h2.999l3.438 4.48H125l-3.647-4.73zm-4.697-5.307h3.346c1.744 0 2.744.732 2.744 2.003v.034c0 1.28-1.096 2.112-2.79 2.112h-3.284l-.016-4.15zM102.497 10.704h6.178V9.26h-6.178V5.73h6.868V4.283h-8.374V15.77h8.454v-1.443h-6.948zM71.629 5.73h3.76V15.77h1.506V5.73h3.756V4.283h-9.022zM93.613 9.273h-6.199v-4.99h-1.506V15.77h1.506V10.72h6.199v5.049h1.506V4.283h-1.506z"
                      fill="#FFF"
                    ></path>
                    <path
                      d="M14.865 5.567H2.785A2.786 2.786 0 0 1 0 2.781 2.786 2.786 0 0 1 2.786 0h17.638l-5.559 5.567z"
                      fill="#F97C6B"
                    ></path>
                    <path
                      d="M7.428 12.996H2.786A2.786 2.786 0 0 1 0 10.21a2.786 2.786 0 0 1 2.786-2.794H13l-5.572 5.58z"
                      fill="#F6D052"
                    ></path>
                    <circle fill="#255DB7" cx="2.807" cy="17.417" r="2.807"></circle>
                  </g>
                </svg>
                <Typography.Title level={2} style={{ color: '#fff', marginTop: '100px' }}>
                  Virtual Pricing Assistant
                </Typography.Title>
                <Typography.Title level={5} style={{ color: '#fff' }}>
                  Senior Housing Revenue Optimization
                </Typography.Title>
              </div>
              <div>
                <Typography.Title level={4} style={{ color: '#fff' }}>
                  Senior Housing Revenue Optimization
                </Typography.Title>
                <Typography.Paragraph style={{ color: '#fff' }}>
                  How Does Your Community Compare Against Your Competition on What Matters to Families?
                </Typography.Paragraph>
                <Typography.Paragraph style={{ color: '#fff' }}>Further Can Help !</Typography.Paragraph>
                <a href="https://www.talkfurther.com/pricingassistant" target="_blank" rel="noopener noreferrer">
                  <Button type="default" shape="round">
                    Let's Talk
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="login__form">
          <div className="form__div">
            <Title className="mb-15">Reset Password</Title>
            <p className="">
              {step < 2
                ? 'Enter your email address and click Send Code to start your password reset.'
                : 'A Verification Code has been sent to your email address. Kindly check your email.'}
            </p>
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical" className="row-col">
              {step < 2 ? (
                <div>
                  <Form.Item
                    className="username"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                    ]}
                    style={{ marginBottom: '8px' }}
                  >
                    <Input />
                  </Form.Item>
                </div>
              ) : (
                <div>
                  <Form.Item
                    className="username"
                    label="Code"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: 'Please input code sent on your email!',
                      },
                    ]}
                    style={{ marginBottom: '8px' }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    label="New Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    className="username"
                    label="Confirm Password"
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The Password that You Entered do not Match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>{' '}
                </div>
              )}

              <Form.Item style={{ textAlign: 'center' }}>
                <Button
                  style={{
                    width: '45%',
                    marginTop: '20px',
                    marginRight: '5px',
                  }}
                  onClick={cancel}
                >
                  Cancel
                </Button>
                {step < 2 ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '45%', marginTop: '20px' }}
                    loading={loading}
                  >
                    Send Code
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '45%', marginTop: '20px' }}
                    loading={loading}
                  >
                    Update Password
                  </Button>
                )}
              </Form.Item>
            </Form>
            <div>
              <Alert
                message=""
                description={error.msg}
                type="error"
                showIcon={false}
                style={{
                  marginTop: '20px',
                  fontSize: '18px',
                  display: error.show ? 'block' : 'none',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
