import React from 'react';

import DashboardColumnChart from '../charts/DashboardColumnChart';
import { CommunityLivingDataType } from '../datatypes';

interface Props {
  data: CommunityLivingDataType[];
}

export const DashboardReportCareTypeChart = ({ data }: Props) => {
  const labelFormatter = (item: any) => {
    return item?.base_rent ? `$${item.base_rent.toFixed(0)}` : '';
  };

  const tooltipFormatter = (item: any) => {
    return {
      name: item.community_name,
      value: item?.base_rent ? `$${item.base_rent.toFixed(0)}` : '',
    };
  };

  const tooltipSorter = (originalItems: any) => {
    return originalItems.sort((a: any, b: any) => a.data.base_rent - b.data.base_rent);
  };

  return (
    <DashboardColumnChart
      title="Market Rate Comparison"
      data={data}
      xField="apartment_type"
      yField="base_rent"
      seriesField="community_name"
      grouped
      labelFormatter={labelFormatter}
      tooltipFormatter={tooltipFormatter}
      tooltipSorter={tooltipSorter}
    />
  );
};

export default DashboardReportCareTypeChart;
