import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import SelectFilter from 'components/lib/SelectFilter';

import { PROPOSAL_SIGNATURE_SUBMISSION_STATUS, PROPOSAL_SIGNATURE_SUBMISSION_STATUS_LABEL } from '../constants';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const Label = styled.span`
  font-family: var(--font-bold);
`;

const IncentivesFiltersProposalStatus = () => {
  const { filters, setFilters } = useIncentivesDataContext();

  const handleSelect = (value: string) => {
    setFilters({ ...filters, proposalStatus: value });
  };

  const options = Object.values(PROPOSAL_SIGNATURE_SUBMISSION_STATUS)?.map((id: string) => ({
    label: PROPOSAL_SIGNATURE_SUBMISSION_STATUS_LABEL[id],
    value: id,
  }));
  options.unshift({ label: 'Any', value: '' });

  return (
    <SelectFilter
      className={classNames({ active: !!filters.proposalStatus })}
      variant="borderless"
      value={filters.proposalStatus}
      placeholder={'Proposal Status'}
      options={options}
      placement="bottomLeft"
      onChange={handleSelect}
      labelRender={({ label }: any) => (
        <>
          <Label>{'Proposal Status: '}</Label>
          {label}
        </>
      )}
    />
  );
};

export default IncentivesFiltersProposalStatus;