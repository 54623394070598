import React from 'react';

import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreCommunityFees } from 'store/slices/communitySlice';

import { CommunityFeeDateType } from '../../../pages/price_healing/Fees/constants';
import FeesFormCommunityFeesTable from '../../../pages/price_healing/Fees/FeesFormCommunityFeesTable';

const Container = styled.div`
  min-height: 250px;
  width: 90%;
`;

const FeesFormOnboardingCommunityFees = () => {
  const dispatch = useAppDispatch();

  const communityFees: CommunityFeeDateType[] = useAppSelector((state) => state.community.communityFees);

  const handleAddCommunityFeeRecord = (item: Partial<CommunityFeeDateType>) => {
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp.push(item);
    dispatch(handleStoreCommunityFees(cp));
  };

  const handleUpdateCommunityFeeRecord = (item: Partial<CommunityFeeDateType>) => {
    const communityFeeIndex = communityFees.findIndex((communityfee) => communityfee.key === item.key);
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp[communityFeeIndex] = item;
    dispatch(handleStoreCommunityFees(cp));
  };

  const handleDeleteCommunityFeeRecord = (item: Partial<CommunityFeeDateType>) => {
    const communityFeeIndex = communityFees.findIndex((communityfee) => communityfee.key === item.key);
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp.splice(communityFeeIndex, 1);
    dispatch(handleStoreCommunityFees(cp));
  };

  return (
    <Container>
      <FeesFormCommunityFeesTable
        loading={false}
        data={communityFees}
        onCreate={handleAddCommunityFeeRecord}
        onUpdate={handleUpdateCommunityFeeRecord}
        onDelete={handleDeleteCommunityFeeRecord}
      />
    </Container>
  );
};
export default FeesFormOnboardingCommunityFees;
