import React from 'react';
import { useParams } from 'react-router';

import IncentivesFiltersOccupancyDropdown from './IncentivesFiltersOccupancyDropdown';

const IncentivesFiltersOccupancy = () => {
  const { managementCompanyId } = useParams();

  return <IncentivesFiltersOccupancyDropdown />;
};

export default IncentivesFiltersOccupancy;
