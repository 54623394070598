import React, { useCallback, useEffect, useState } from 'react';

import { Space } from 'antd';
import styled from 'styled-components';

import DropdownSearch from './DropdownSearch';
import { useSelectorContext } from './SelectorContext';
import { CompanyDataType } from './types';
import { getManagementCompanies, getManagementCompany } from '../../apis/UserAPI';
import { USER_LEVELS } from '../../constants';
import useDataList from '../../utils/hooks/useDataList';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectorManagementCompany = () => {
  const [options, setOptions] = useState<any>([]);
  const { items, filters, setItems, setSearch } = useDataList();

  const { client, company, managementCompany, setManagementCompany } = useSelectorContext();

  const fetchManagementCompanies = useCallback(
    async (companyId: number, { search }: any) => {
      try {
        const { results, count } = await getManagementCompanies(companyId, {
          search,
        });
        setItems({ results, count });
      } catch (e) {
        console.error(e);
      }
    },
    [setItems]
  );

  const fetchManagementCompany = useCallback(
    async (companyId: number, managementCompanyId: number) => {
      try {
        const response = await getManagementCompany(companyId, managementCompanyId);
        setManagementCompany(response);
        setItems({ results: [response], count: 1 });
      } catch (e) {
        console.error(e);
      }
    },
    [setItems]
  );

  useEffect(() => {
    if (company && client?.level === USER_LEVELS.MANAGEMENT_COMPANY && client.management_company) {
      fetchManagementCompany(company.id, client.management_company);
    } else if (company) {
      fetchManagementCompanies(company.id, filters);
    }
  }, [fetchManagementCompanies, filters, company, client]);

  useEffect(() => {
    setManagementCompany(undefined);
  }, [company, client]);

  useEffect(() => {
    const dropdownOptions = (items || []).map((item: any) => ({
      value: item.id,
      label: item.management_company,
      email: item.email,
    }));

    setOptions(dropdownOptions);
  }, [items]);

  const handleSelect = (selectedManagementCompanyId: number) => {
    const selectedManagementCompany = (items || []).find(
      (item: CompanyDataType) => item.id === selectedManagementCompanyId
    );
    setManagementCompany(selectedManagementCompany);
  };

  const handleClear = () => {
    setManagementCompany(undefined);
    setSearch('');
  };

  const dropdownDisabled = !company || (client?.level === USER_LEVELS.MANAGEMENT_COMPANY && !!managementCompany);

  return (
    <DropdownSearch
      value={managementCompany?.id}
      options={options}
      disabled={dropdownDisabled}
      loading={false}
      placeholder={'Search Management Company' as const}
      onSearch={setSearch}
      onSelect={handleSelect}
      onClear={handleClear}
      optionRender={(option: any) => {
        return (
          <Space>
            <InfoContainer>
              <span>
                <b>{option.label}</b>
              </span>
              <span>{option.data.email}</span>
            </InfoContainer>
          </Space>
        );
      }}
    />
  );
};

export default SelectorManagementCompany;
