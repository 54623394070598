import React, { ChangeEvent, useState } from 'react';

import styled from 'styled-components';

import { isInteger } from '../../../helpers/numbers';
import Checkbox from '../../lib/Checkbox';
import Input from '../../lib/Input';
import { DEFAULT_UNIT_VACANCY_DAY_COUNT } from '../constants';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

interface Props {
  vacancyCountRef: any;
}

const IncentivesFiltersUnitAvailabilityVacancyCount = ({ vacancyCountRef }: Props) => {
  const [inputEnabled, setInputEnabled] = useState<boolean>(false);
  const [unitVacancyDayCount, setUnitVacancyDayCount] = useState(DEFAULT_UNIT_VACANCY_DAY_COUNT);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target;

    if (isInteger(inputValue)) {
      setUnitVacancyDayCount(Number(inputValue));
    }
  };

  return (
    <Container
      ref={vacancyCountRef}
      className={'selected-visible'}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Checkbox
        id={'vacancy-checkbox'}
        className={'small green'}
        checked={inputEnabled}
        onChange={() => setInputEnabled((prev) => !prev)}
      />
      <span> over </span>
      <Input
        id={'vacancy-input'}
        value={unitVacancyDayCount}
        disabled={!inputEnabled}
        style={{ height: 36, width: 48 }}
        maxLength={3}
        onChange={handleChange}
        inputMode={'numeric'}
      />
      <span> days</span>
    </Container>
  );
};

export default IncentivesFiltersUnitAvailabilityVacancyCount;
