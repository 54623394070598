import axios from '../config/axiosPrivate';

export const createFilter = async (payload: any) => {
  const url = `/views/filter`;

  const response = await axios.post(url, payload);
  return response.data;
};

export const deleteFilter = async (filterId: number) => {
  const url = `/views/filter/${filterId}`;

  const response = await axios.delete(url);
  return response.data;
};

export const updateFilter = async (filterId: number, payload: any) => {
  const url = `/views/filter/${filterId}`;

  const response = await axios.put(url, payload);
  return response.data;
};

export const updatePartialFilter = async (filterId: number, payload: any) => {
  const url = `/views/filter/${filterId}`;

  const response = await axios.patch(url, payload);
  return response.data;
};
