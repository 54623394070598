import { USER_GROUPS } from '../constants';
import { UserType } from '../store/slices/authSlice';

export const isIncentivesCorporate = (user: UserType | null) => {
  return user?.groups.includes(USER_GROUPS.INCENTIVES_CORPORATE);
};

export const isIncentivesSalesperson = (user: UserType | null) => {
  return user?.groups.includes(USER_GROUPS.INCENTIVES_SALESPERSON);
};

export const isMysteryShopAdmin = (user: UserType | null) => {
  return user?.groups.includes(USER_GROUPS.MYSTERY_SHOP_ADMIN);
};

export const isMysteryShopShopper = (user: UserType | null) => {
  return user?.groups.includes(USER_GROUPS.MYSTERY_SHOP_SHOPPER);
};
