import React, { useState } from 'react';

import { updatePartialView } from '../../../apis/ViewAPI';
import { STATUS } from '../../../constants';
import { useRootContext } from '../../layout/RootContext';
import { VIEW_MODALS } from '../../View/constants';
import ViewInputModal from '../../View/modals/ViewInputModal';
import { useCommunityViewContext } from '../CommunityViewContext';

interface Props {
  open: boolean;
  onClose: any;
}

const ResetLayoutModal = ({ open, onClose }: Props) => {
  const { viewId, viewRefetch } = useCommunityViewContext();

  const { showMessage } = useRootContext();
  const [status, setResetStatus] = useState<string>(STATUS.IDLE);

  const handleConfirm = async () => {
    setResetStatus(STATUS.LOADING);
    viewRefetch();
    return await updatePartialView(viewId, { layout: [] })
      .then(() => {
        viewRefetch();
        showMessage('success', 'Layout reset successfully.');
        setResetStatus(STATUS.LOADED);
        onClose();
      })
      .catch((reason) => {
        setResetStatus(STATUS.FAILURE);
        showMessage('error', 'Trouble reseting the layout.');
      });
  };

  return (
    <ViewInputModal
      open={open}
      type={VIEW_MODALS.RESET_LAYOUT}
      loading={status === STATUS.LOADING}
      onConfirm={handleConfirm}
      onCancel={() => onClose()}
    />
  );
};

export default ResetLayoutModal;
