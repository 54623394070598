import React from 'react';

import styled from 'styled-components';

import { useShareViaEmailContext } from './ShareViaEmailContext';
import { STATUS } from '../../../constants';
import Button from '../../lib/Button';

const Footer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  border-top: 1px solid var(--line-gray);
  padding: 20px 30px;
  gap: 10px;
  margin-top: 10px;
`;

const ShareViaEmailFooter = () => {
  const { loading, views, emails, selectedUsers, handleClose, handleSend } = useShareViaEmailContext();
  const disabled =
    !((Boolean(emails.length) || Boolean(selectedUsers.length)) && Boolean(views.length)) || loading === STATUS.LOADING;

  return (
    <Footer>
      <Button
        type="primary"
        style={{ width: 120, height: 40 }}
        onClick={handleSend}
        loading={loading === STATUS.LOADING}
        disabled={disabled}
      >
        Send
      </Button>
      <Button onClick={handleClose} style={{ width: 120, height: 40 }} disabled={loading === STATUS.LOADING}>
        Cancel
      </Button>
    </Footer>
  );
};

export default ShareViaEmailFooter;
