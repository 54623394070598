import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { COMMON_MENU_ITEMS_PATHS } from './constants';
import SidebarMenuAdmin from './SidebarMenuAdmin';
import SidebarMenuCommon from './SidebarMenuCommon';
import SidebarMenuCommunity from './SidebarMenuCommunity';
import SidebarMenuCommunityViews from './SidebarMenuCommunityViews';
import SidebarMenuMysteryShopping from './SidebarMenuMysteryShopping';
import SidebarMenuQuickCompetitors from './SidebarMenuQuickCompetitors';
import SidebarMenuSettings from './SidebarMenuSettings';
import { COMMUNITY_TYPE } from '../../../constants';
import {
  isIncentivesCorporate,
  isIncentivesSalesperson,
  isMysteryShopAdmin,
  isMysteryShopShopper,
} from '../../../helpers/permissions';

const StyledMenu = styled.div`
  color: white;
  background: transparent;
  font-size: 12px;
  height: 90vh;
  overflow-y: scroll;
`;

const SidebarMenu = () => {
  const navigate = useNavigate();

  const { selectedCompany, selectedCommunity, selectedManagementCompany } = useAppSelector((state) => state.global);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const isCommunity = selectedCommunity?.type != COMMUNITY_TYPE.COMPETITOR;

  useEffect(() => {
    if (currentUser?.isSuperuser && !selectedCompany) {
      navigate(COMMON_MENU_ITEMS_PATHS.SELECTOR);
    }
  }, [currentUser, selectedCompany]);

  const showIncentives = currentUser && (isIncentivesCorporate(currentUser) || isIncentivesSalesperson(currentUser));
  const showShopRequests = currentUser && (isMysteryShopAdmin(currentUser) || isMysteryShopShopper(currentUser));
  const showOtherMenu = currentUser?.feature_flags?.show_beta_settings || showIncentives;

  return (
    <StyledMenu className="mini-scroll">
      {selectedManagementCompany && !selectedCommunity && <SidebarMenuCommon />}
      {selectedCommunity && (
        <>
          <SidebarMenuCommunityViews />
          <SidebarMenuCommunity />
          {isCommunity && <SidebarMenuQuickCompetitors />}
        </>
      )}
      {showShopRequests && <SidebarMenuMysteryShopping />}
      {showOtherMenu && <SidebarMenuSettings />}

      {currentUser?.isSuperuser && <SidebarMenuAdmin />}
    </StyledMenu>
  );
};

export default SidebarMenu;
