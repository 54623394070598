import React from 'react';

import styled from 'styled-components';

import {
  INCENTIVES_FEES_TYPE,
  INCENTIVES_SAVINGS_DISCOUNT_DURATION_LABELS,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE,
  INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR,
  INCENTIVES_SAVINGS_FEE_TYPE_LABELS,
} from '../create/constants';
import { IncentiveSavingType } from '../types';

const Content = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  color: var(--text-secondary);
`;

const Row = styled.div`
  display: flex;
  gap: 6px;
`;

interface Props {
  item: IncentiveSavingType;
}

const SavingsItem = ({ item }: Props) => {
  const label =
    item?.type !== INCENTIVES_FEES_TYPE.CUSTOM ? INCENTIVES_SAVINGS_FEE_TYPE_LABELS[item.type ?? ''] : item.name;

  const value =
    item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT
      ? (item?.discount ?? 0) * (INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR[item?.discount_duration_type ?? ''] ?? 1)
      : item?.discount;

  const durationLabel =
    INCENTIVES_SAVINGS_DISCOUNT_DURATION_LABELS[item.discount_duration_type ?? ''] &&
    INCENTIVES_SAVINGS_DISCOUNT_DURATION_LABELS[item.discount_duration_type ?? ''][item.discount_duration!];

  const showCommonSavings = item.type !== INCENTIVES_FEES_TYPE.CUSTOM;
  const showSavingsAmount = item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT;

  return (
    <Content>
      <Row>
        <span>·</span>
        {showCommonSavings && !showSavingsAmount && (
          <span>
            {value}% off {label} {durationLabel}
          </span>
        )}
        {showCommonSavings && showSavingsAmount && (
          <span>
            ${value} off {label} {durationLabel}
          </span>
        )}
        {!showCommonSavings && showSavingsAmount && (
          <span>
            ${value} {label}
          </span>
        )}
        {!showCommonSavings && !showSavingsAmount && <span>{label}</span>}
      </Row>
    </Content>
  );
};

export default SavingsItem;
