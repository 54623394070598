import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import classNames from 'classnames';

import { getCareTypesOptions } from '../../../apis/IncentiveAPI';
import { useAppSelector } from '../../../store/hook';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useIncentivesDataContext } from '../IncentivesDataContext';

type CareTypeType = {
  id: number;
  name: string;
};

const IncentivesFiltersCareType = () => {
  const { managementCompanyId } = useParams();

  const { selectedCompany } = useAppSelector((state) => state.global);
  const { filters, setFilters } = useIncentivesDataContext();

  const handleSelect = (selection: any[]) => {
    const values = selection.map((item: any) => item.value);
    setFilters({ ...filters, page: 1, careTypes: values });
  };

  const { data } = useQuery({
    queryKey: ['careTypes', 'incentives', selectedCompany, managementCompanyId],
    queryFn: () =>
      getCareTypesOptions({
        companies: selectedCompany ? [selectedCompany.id] : [],
        management_companies: managementCompanyId ? [managementCompanyId] : undefined,
      }),
    enabled: Boolean(selectedCompany) || Boolean(managementCompanyId),
    refetchOnWindowFocus: false,
  });

  const options = data?.map((item: CareTypeType) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: Boolean(filters.careTypes?.length) })}
      placeholder={'Care Type'}
      options={options}
      defaultValue={filters?.careTypes ?? []}
      onSelect={handleSelect}
    />
  );
};

export default IncentivesFiltersCareType;
