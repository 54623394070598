import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Tooltip } from 'antd';

import { handleReset } from 'store/slices/globalSlice';

const HeaderSelectorClear = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClear = () => {
    dispatch(handleReset());
    navigate('/selector');
  };

  return (
    <Tooltip title="Clear Selection">
      <Button danger size="small" shape="round" onClick={handleClear}>
        Clear
      </Button>
    </Tooltip>
  );
};

export default HeaderSelectorClear;
