import React from 'react';

import { Slider as AntDSlider } from 'antd';
import { SliderRangeProps, SliderSingleProps } from 'antd/lib/slider';
import styled from 'styled-components';

const StyledSlider = styled(AntDSlider)`
  display: flex;
  flex-grow: 1;

  .ant-slider-handle {
    width: 20px;
    height: 20px;
    border: solid 7px #275aff;
    background-color: #fff;
    border-radius: 14px;
    margin-top: -6px;

    ::before,
    ::after {
      display: none;
    }
  }

  && {
    &:not(.range) {
      margin: 11px 0px;
      .ant-slider-track {
        background: transparent;
      }
    }
    .ant-slider-rail {
      height: 2px;
    }
  }

  .ant-slider-tracks {
    display: none;
  }
`;

const TickMark = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: var(--line-gray);
  margin-top: -3px;
`;

const TickMarks = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const Slider = (props: SliderRangeProps | SliderSingleProps) => {
  return (
    <SliderContainer>
      {!props.range && (
        <TickMarks>
          {Array.from({ length: 5 }, (_, i) => (
            <TickMark key={i} />
          ))}
        </TickMarks>
      )}
      <StyledSlider {...props} />
    </SliderContainer>
  );
};

export default Slider;
