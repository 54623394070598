import React from 'react';

import { uuid } from 'short-uuid';
import styled from 'styled-components';

import { INCENTIVES_FEES_TYPE, INCENTIVES_SAVINGS_DISCOUNT_DURATION } from './constants';
import IncentivesCreateActionsOverview from './IncentivesCreateActionsOverview';
import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import { createIncentive } from '../../../apis/IncentiveAPI';
import { useRootContext } from '../../layout/RootContext';
import Button from '../../lib/Button';
import { useIncentivesContext } from '../IncentivesContext';
import { IncentiveSavingType, UnitType } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  gap: 15px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IncentivesCreateActions = () => {
  const { showMessage } = useRootContext();

  const { setKey } = useIncentivesContext();
  const { name, startDate, endDate, description, savings, units, loading, handleClose, setLoading } =
    useIncentivesCreateModalContext();

  const handleCreate = async () => {
    setLoading(true);

    // Leave `discount_duration` if Forever is selected
    const adjustedSavings = savings.map((item: IncentiveSavingType) =>
      Object.assign(item, {
        discount_duration:
          item.discount_duration === INCENTIVES_SAVINGS_DISCOUNT_DURATION.FOREVER ? undefined : item.discount_duration,
      })
    );

    const unitsIds = (units ?? []).map((item: UnitType) => item.id);

    await createIncentive({
      name,
      start_at: startDate,
      end_at: endDate,
      description: description,
      savings: adjustedSavings,
      units: unitsIds,
    })
      .then(async (response: any) => {
        showMessage('success', 'Incentive successfully created.');
        handleClose();
      })
      .catch(() => {
        showMessage('error', 'Trouble creating Incentive.');
      })
      .finally(() => {
        setLoading(false);
        setKey(uuid());
      });
  };

  // Common and Custom Savings with Amount require both savings type and discount value
  // Custom Savings without amount only requires savings type to be present
  const hasMissingData = savings.some((item: IncentiveSavingType) => {
    return (
      !item.type ||
      (item.type === INCENTIVES_FEES_TYPE.CUSTOM && !item.name) ||
      (item.type !== INCENTIVES_FEES_TYPE.CUSTOM && !(item.discount && item.discount_type)) ||
      (item.discount_type === INCENTIVES_FEES_TYPE.MONTHLY_RENT && item.discount_duration == undefined)
    );
  });
  const createDisabled = !name || hasMissingData || units.length === 0 || loading;

  return (
    <Container>
      <IncentivesCreateActionsOverview />
      <Buttons>
        <Button
          disabled={createDisabled}
          loading={loading}
          type={'primary'}
          style={{ width: 170, height: 40 }}
          onClick={handleCreate}
        >
          Create Incentive
        </Button>
        <Button loading={loading} style={{ width: 107, height: 40 }} onClick={handleClose}>
          Cancel
        </Button>
      </Buttons>
    </Container>
  );
};

export default IncentivesCreateActions;
