import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AuditOutlined,
  FileSearchOutlined,
  GoogleCircleFilled,
  HomeFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Divider, Layout, Menu, MenuProps, Typography } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import { SIDEBAR_BACKGROUND_COLOR, SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH } from './constants';

const { Sider } = Layout;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledSider = styled(Sider)`
  && {
    background-color: ${SIDEBAR_BACKGROUND_COLOR};
  }
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = useAppSelector((state) => state.global);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState(['/home']);

  const { selectedCompany } = state;

  useEffect(() => {
    if (currentUser?.isSuperuser && !selectedCompany) {
      navigate('/selector');
    }
  }, [currentUser, selectedCompany, location.pathname]);

  const onMenuSelection: MenuProps['onClick'] = (e) => {
    setSelectedKeys([e.key]);
    navigate(e.key);
  };

  useEffect(() => {
    const route = location.pathname;
    setSelectedKeys([route]);
  }, []);

  return (
    <StyledSider collapsible collapsed={collapsed} collapsedWidth={SIDEBAR_COLLAPSED_WIDTH} width={SIDEBAR_WIDTH}>
      <ToggleContainer>
        {!collapsed && (
          <TitleContainer>
            <Typography.Title
              level={5}
              style={{
                color: '#fff',
                margin: '0px',
              }}
            >
              Pricing Assistant
            </Typography.Title>
          </TitleContainer>
        )}

        {currentUser?.isSuperuser && (
          <Button
            type="link"
            onClick={() => setCollapsed(!collapsed)}
            icon={
              collapsed ? (
                <MenuUnfoldOutlined style={{ fontSize: 22, color: '#fff' }} />
              ) : (
                <MenuFoldOutlined style={{ fontSize: 22, color: '#fff' }} />
              )
            }
            size="large"
          />
        )}
      </ToggleContainer>
      <Divider style={{ margin: '0px 0px 10px 0px', background: '#333' }} />
      <Menu
        defaultSelectedKeys={['/home']}
        selectedKeys={selectedKeys}
        style={{ background: 'transparent' }}
        theme={'dark'}
        onClick={onMenuSelection}
        items={[
          {
            key: '/home',
            icon: <HomeFilled />,
            label: 'Home',
          },
          {
            key: '/shop-requests',
            icon: <ShopFilled />,
            label: 'Shop Requests',
          },
          {
            key: '/google-metrics-reviews',
            icon: <GoogleCircleFilled />,
            label: 'Google Metrics',
          },
          {
            key: '/user-log-dashboard',
            icon: <UserOutlined />,
            label: 'User Log Dashboard',
          },
          {
            key: '/sentiment-analysis',
            icon: <FileSearchOutlined />,
            label: 'Sentiment Analysis',
          },
          {
            key: '/user-log-report',
            icon: <UserOutlined />,
            label: 'User Log Report',
          },
          {
            key: '/onboarding',
            icon: <AuditOutlined />,
            label: 'User Onboarding',
          },
        ]}
      />
    </StyledSider>
  );
};

export default Sidebar;
