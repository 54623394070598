import React from 'react';

import HeaderUserActions from './HeaderUserActions';
import HeaderUserApplicationSwitch from './HeaderUserApplicationSwitch';
import HeaderUserNotifications from './HeaderUserNotifications';
import classes from './styles.module.scss';

const HeaderUser = () => (
  <div className={classes.header_right}>
    <HeaderUserApplicationSwitch />
    <HeaderUserNotifications />
    <HeaderUserActions />
  </div>
);

export default HeaderUser;
