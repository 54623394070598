import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { SHOP_REQUEST_STATUS_LABELS } from '../constants';
import ProgressBar from '../ProgressBar';
import { MysteryShopRequestGroup } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const Label = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 2px;
`;

type Props = {
  shopRequestGroup: MysteryShopRequestGroup;
};

const ShopRequestGroupStatus = ({ shopRequestGroup }: Props) => {
  const shopRequestsTotal = shopRequestGroup.shop_requests?.length || 0;
  const completedShopRequests =
    shopRequestGroup.shop_requests?.filter(
      (request) =>
        request.status &&
        (request.status === SHOP_REQUEST_STATUS_LABELS.APPROVED ||
          request.status === SHOP_REQUEST_STATUS_LABELS.NEEDS_APPROVAL)
    ).length || 0;
  const loadedPercentage = Math.round((completedShopRequests / shopRequestsTotal) * 100);

  return (
    <Container>
      <Label>
        {completedShopRequests} / {shopRequestsTotal}
      </Label>
      <ProgressBar percentage={loadedPercentage} style={{ marginLeft: 0 }} />
    </Container>
  );
};

export default ShopRequestGroupStatus;
