import React from 'react';

import styled from 'styled-components';

import ReviewItem from './ReviewItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow-y: auto;
  gap: 20px;
`;

interface Props {
  data: any;
}

export const Reviews = ({ data }: Props) => {
  return (
    <Container className="mini-scroll">{data?.map((item: any) => <ReviewItem key={item.id} item={item} />)}</Container>
  );
};

export default Reviews;
