import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { uuid } from 'short-uuid';
import styled from 'styled-components';

import { INCENTIVES_TABS } from './constants';
import IncentivesHeader from './header/IncentivesHeader';
import { IncentivesContext } from './IncentivesContext';
import IncentivesData from './InventivesData';
import IncentivesTabs from './InventivesTabs';
import IncentivesProposalModal from './proposal/IncentivesProposalModal';
import { ProposalType, UnitType } from './types';
import { getManagementCompany } from '../../apis/UserAPI';
import { isIncentivesCorporate } from '../../helpers/permissions';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { handleSetSelectedManagementCompany } from '../../store/slices/globalSlice';
import Loader from '../lib/Loader';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const IncentivesOverview = () => {
  const dispatch = useAppDispatch();
  const { managementCompanyId } = useParams();

  const { currentUser } = useAppSelector((state) => state.auth);
  const { selectedCompany } = useAppSelector((state) => state.global);

  const [currentTab, setCurrentTab] = useState(INCENTIVES_TABS.INCENTIVE);
  const [search, setSearch] = useState('');
  const [selectedProposalUnit, setSelectedProposalUnit] = useState<UnitType | undefined>(undefined);
  const [selectedProposal, setSelectedProposal] = useState<ProposalType | undefined>(undefined);
  const [key, setKey] = useState<string>(uuid());

  const { data, isLoading } = useQuery({
    queryKey: ['managementCompany', selectedCompany, managementCompanyId],
    queryFn: () => getManagementCompany(selectedCompany?.id || -1, Number.parseInt(managementCompanyId ?? '')),
    enabled: Boolean(selectedCompany) && Boolean(managementCompanyId),
    onSuccess: (data) => {
      dispatch(handleSetSelectedManagementCompany(data));
    },
  });

  if (isLoading) {
    return <Loader active={isLoading} />;
  }

  return (
    <IncentivesContext.Provider
      value={{
        managementCompany: data,
        currentTab,
        search,
        key,
        selectedProposalUnit,
        selectedProposal,
        setSelectedProposal,
        setCurrentTab,
        setSelectedProposalUnit,
        setKey,
        setSearch,
      }}
    >
      <Container>
        <IncentivesProposalModal />
        <IncentivesHeader />
        {isIncentivesCorporate(currentUser) && <IncentivesTabs />}
        <IncentivesData
          archiveMode={currentTab === INCENTIVES_TABS.ARCHIVE}
          onSelectionChanged={() => {
            // Do nothing
          }}
        />
      </Container>
    </IncentivesContext.Provider>
  );
};

export default IncentivesOverview;
