import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import classNames from 'classnames';

import { RoomTypeType } from './types';
import { getRoomTypesOptions } from '../../../apis/IncentiveAPI';
import { useAppSelector } from '../../../store/hook';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const IncentivesFiltersRoomType = () => {
  const { managementCompanyId } = useParams();

  const { selectedCompany } = useAppSelector((state) => state.global);
  const { filters, setFilters } = useIncentivesDataContext();

  const handleSelect = (selection: any[]) => {
    const values = selection.map((item: any) => item.value);
    setFilters({ ...filters, page: 1, roomTypes: values });
  };

  const { data } = useQuery({
    queryKey: ['roomTypes', 'incentives', selectedCompany, managementCompanyId, filters.communities],
    queryFn: () =>
      getRoomTypesOptions({
        companies: selectedCompany ? [selectedCompany.id] : [],
        management_companies: managementCompanyId ? [managementCompanyId] : undefined,
        communities: filters?.communities,
      }),
    enabled: Boolean(selectedCompany) || Boolean(managementCompanyId),
    refetchOnWindowFocus: false,
  });

  const options = data?.map((item: RoomTypeType) => ({
    label: item.name,
    value: item.name,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: Boolean(filters.roomTypes?.length) })}
      placeholder={'Room Type'}
      options={options}
      defaultValue={filters.roomTypes ?? []}
      onSelect={handleSelect}
    />
  );
};

export default IncentivesFiltersRoomType;
