import React from 'react';
import { useQuery } from 'react-query';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import GoogleMatrixReviews from 'components/GoogleMetricsReviews';
import PrivateRoot from 'components/layout/PrivateRoot';
import ShopRequests from 'components/MysteryShopping/ShopRequests';
import ShopRequestsOverview from 'components/MysteryShopping/ShopRequestsOverview';
import Overview from 'components/Overview/Overview';
import Selector from 'components/Selector';
import CommunityDigest from 'components/Settings/CommunityDigest';
import CompetitorIncentive from 'components/Settings/CompetitorIncentive';
import MarketRateComparison from 'components/Settings/MarketRateComparison';
import ReminderToUpdatePricing from 'components/Settings/ReminderToUpdatePricing';
import Settings from 'components/Settings/Settings';
import SettingsOverview from 'components/Settings/SettingsOverview';
import UserOnboarding from 'components/UserOnboarding';
import axios from 'config/axiosPrivate';
import MysteryShop from 'pages/MysteryShop/MysteryShop';
import PriceHealing from 'pages/price_healing';
import SentimentAnalysis from 'pages/SentimentAnalysis';
import SettingsPage from 'pages/Settings';
import OccupancyTiers from 'pages/Settings/OccupancyTiers';
import SwotSettings from 'pages/Settings/SwotSettings';
import SwotSurvey from 'pages/SwotSurvey/Swot';
import './assets/styles/main.css';
import SwotList from 'pages/SwotSurvey/SwotList';
import UserLogReport from 'pages/UserLogReport';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleSetOptionTypes } from 'store/slices/globalSlice';

import Community from './components/Community';
import CommunityOverview from './components/Community/CommunityOverview';
import CommunityInfo from './components/CommunityInfo';
import CompetitorOverview from './components/Competitor/CompetitorOverview';
import Competitors from './components/Competitors';
import Incentives from './components/Incentives/Incentives';
import IncentivesOverview from './components/Incentives/InventivesOverview';
import OverviewAddCommunity from './components/Overview/OverviewAddCommunity';
import OverviewAddCompetitor from './components/Overview/OverviewAddCompetitor';
import OverviewManagementCompany from './components/Overview/OverviewManagementCompany';
import Publication from './components/Publication';
import PublicationView from './components/Publication/PublicationView';
import CommunityViewRoot from './components/View/CommunityViewRoot';
import CommunityViewWrapper from './components/View/CommunityViewWrapper';
import CompetitorViewWrapper from './components/View/CompetitorViewWrapper';
import AddCommunityInitial from './pages/AddCommunityInitial';
import AddCompetitorInitial from './pages/AddCompetitorInitial';
import ForgotPassword from './pages/Authentication/ForgotPassword';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';
import ApartmentRatings from './pages/Settings/ApartmentRatings';
import Home from './pages/Settings/Home';
import UserLogDashboard from './pages/UserLogDashboard';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="public/:hash" element={<Publication />}>
        <Route path="view/:viewId" element={<PublicationView />} />
      </Route>
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/" element={<PrivateRoot />}>
        <Route path="selector" element={<Selector />} />
        <Route path="home" element={<Home />} />

        <Route path="user-log-dashboard" element={<UserLogDashboard />} />
        <Route path="user-log-report" element={<UserLogReport />} />
        <Route path="onboarding" element={<UserOnboarding />} />
        <Route path="sentiment-analysis" element={<SentimentAnalysis />} />
        <Route path="add-community" element={<AddCommunityInitial />} />
        <Route path="add-competitor" element={<AddCompetitorInitial />} />

        <Route path="preferences/:managementCompanyId" element={<Settings />}>
          <Route path="" element={<SettingsOverview />} />
          <Route path="community-digest" element={<CommunityDigest />} />
          <Route path="market-rates-comparison-updates" element={<MarketRateComparison />} />
          <Route path="competitor-incentive-updates" element={<CompetitorIncentive />} />
          <Route path="reminder-to-update-pricing" element={<ReminderToUpdatePricing />} />
        </Route>

        <Route path="settings" element={<SettingsPage />}>
          <Route path="apartment-ratings" element={<ApartmentRatings />} />
          <Route path="occupancy-tiers" element={<OccupancyTiers />} />
          <Route path="swot-settings" element={<SwotSettings />} />
        </Route>

        <Route path="view-apartments" element={<PriceHealing />} />
        <Route path="swot-survey" element={<SwotSurvey />} />
        <Route path="swot-survey-list" element={<SwotList />} />
        <Route path="google-metrics-reviews/*" element={<GoogleMatrixReviews />} />
        <Route path="shop-requests" element={<MysteryShop />} />
      </Route>
      <Route path="*" element={<Navigate to="/home" replace />} />
    </>
  )
);

const betaRouter = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="public/:hash" element={<Publication />}>
        <Route path="view/:viewId" element={<PublicationView />} />
      </Route>

      <Route path="/" element={<PrivateRoot />}>
        <Route path="selector" element={<Selector />} />
        <Route path="home" element={<Overview />} />
        <Route path="overview" element={<Overview />}>
          <Route path="" element={<OverviewManagementCompany />} />
          <Route path="add-community" element={<OverviewAddCommunity />} />
          <Route path="add-competitor" element={<OverviewAddCompetitor />} />
          <Route path=":managementCompanyId" element={<Overview />}>
            <Route path="" element={<OverviewManagementCompany />} />
            <Route path="add-community" element={<OverviewAddCommunity />} />
            <Route path="add-competitor" element={<OverviewAddCompetitor />} />
          </Route>
        </Route>
        <Route path="community/:communityId" element={<Community />}>
          <Route path="" element={<CommunityOverview />} />
          <Route path="competitors/" element={<Competitors />} />
          <Route path="info" element={<CommunityInfo />} />
          <Route path="competitor/:competitorId/info" element={<CommunityInfo />} />
          <Route path="view" element={<CommunityViewRoot />}>
            <Route path=":viewId" element={<CommunityViewWrapper />} />
          </Route>
        </Route>
        <Route path="competitor/:communityId" element={<Community />}>
          <Route path="" element={<CompetitorOverview />} />
          <Route path="info" element={<CommunityInfo />} />
          <Route path="view" element={<CommunityViewRoot />}>
            <Route path=":viewId" element={<CompetitorViewWrapper />} />
          </Route>
        </Route>
        <Route path="user-log-dashboard" element={<UserLogDashboard />} />
        <Route path="user-log-report" element={<UserLogReport />} />
        <Route path="onboarding" element={<UserOnboarding />} />
        <Route path="sentiment-analysis" element={<SentimentAnalysis />} />
        <Route path="add-community" element={<AddCommunityInitial />} />
        <Route path="add-competitor" element={<AddCompetitorInitial />} />
        <Route path="incentives" element={<Incentives />}>
          <Route path=":managementCompanyId?" element={<IncentivesOverview />} />
        </Route>
        <Route path="preferences/:managementCompanyId" element={<Settings />}>
          <Route path="" element={<SettingsOverview />} />
          <Route path="community-digest" element={<CommunityDigest />} />
          <Route path="market-rates-comparison-updates" element={<MarketRateComparison />} />
          <Route path="competitor-incentive-updates" element={<CompetitorIncentive />} />
          <Route path="reminder-to-update-pricing" element={<ReminderToUpdatePricing />} />
        </Route>
        <Route path="settings" element={<SettingsPage />}>
          <Route path="apartment-ratings" element={<ApartmentRatings />} />
          <Route path="occupancy-tiers" element={<OccupancyTiers />} />
          <Route path="swot-settings" element={<SwotSettings />} />
        </Route>
        <Route path="mystery-shopping" element={<ShopRequests />}>
          <Route path="" element={<ShopRequestsOverview />} />
        </Route>
        ]
        <Route path="swot-survey" element={<SwotSurvey />} />
        <Route path="swot-survey-list" element={<SwotList />} />
        <Route path="google-metrics-reviews/*" element={<GoogleMatrixReviews />} />
        <Route path="shop-requests" element={<MysteryShop />} />
      </Route>
      <Route path="*" element={<Navigate to="/home" replace />} />
    </>
  )
);

function App() {
  const dispatch = useAppDispatch();
  const { currentUser, featureFlags } = useAppSelector((state) => state.auth);

  // get apartment types
  useQuery({
    queryKey: 'Get_UniqueList',
    queryFn: () => axios.get(`/communities/Get_UniqueList/`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const formatted_types = {
        FeeTypes: data?.FeeTypes,
        LevelTypes: data?.LevelTypes,
        OccupancyTypes: data?.OccupancyTypes,
        PaymentTypes: data?.PaymentTypes,
        apartmenttypes: data?.ApartmentTypes,
        livingtypes: data?.LivingTypes,
        business_models: data?.BusinessModels,
        apartment_ratings: data?.ApartmentRating,
        selectedComunityCompanyName: '',
        loading: false,
      };
      dispatch(handleSetOptionTypes(formatted_types));
    },
    refetchOnWindowFocus: false,
  });

  if (featureFlags && featureFlags?.showBetaApplication) {
    return <RouterProvider router={betaRouter} />;
  }

  return <RouterProvider router={router} />;
}

export default App;
