import React, { useEffect, useState } from 'react';

import { Alert, Spin } from 'antd';
import styled from 'styled-components';

import DashboardReportGoogleRatingChart from './DashboardReportGoogleRatingChart';
import { getCommunityRating } from '../../../apis/CommunityAPI';
import { STATUS } from '../../../constants';
import { useDashboardReportsContext } from '../DashboardReportsContext';
import { CommunityLivingDataType } from '../datatypes';

const SpinWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20px;
`;

export const DashboardReportGoogleRating = () => {
  const { communityId, careTypeId } = useDashboardReportsContext();

  const [data, setData] = useState<CommunityLivingDataType[]>([]);
  const [status, setStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    const loadCommunityCareTypes = async () => {
      setStatus(STATUS.LOADING);
      try {
        const communityRatings = await getCommunityRating(communityId ?? 0);
        setData(communityRatings);
        setStatus(STATUS.LOADED);
      } catch (e) {
        console.error(e);
        setStatus(STATUS.FAILURE);
      }
    };
    loadCommunityCareTypes();
  }, [careTypeId]);

  const loading = status === STATUS.LOADING;
  const hasData = data.length > 0;

  return (
    <>
      {loading && (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )}

      {!loading && !hasData && (
        <Alert message="No data for Google Ratings" style={{ marginTop: '20px' }} type="info" showIcon />
      )}

      {!loading && hasData && <DashboardReportGoogleRatingChart data={data} />}
    </>
  );
};

export default DashboardReportGoogleRating;
