import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { DeleteFilled, EditFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import { matchSorter } from 'match-sorter';
import styled from 'styled-components';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

const TableContainer = styled.div`
  display: flex;
`;

interface DataType {
  id: number;
  MyCommunityName: string;
  MyCommunityId: number;
  CompetitorName: string;
  CompetitorId: number;
  overall_score: string;
  apartment_amenities_score: string;
  community_amenities_score: string;
  dining_experience_score: string;
  care_services_score: string;
  life_enrichment_score: string;
  reputation_score: string;
  internet_social_media_score: string;
  community_feel_score: string;
  pricing_score: string;
  location_score: string;
  updated_at: number;
}

const SwotList = () => {
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [tableData, settableData] = useState<any[]>([]);
  const [allData, setAllData] = useState<any[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const state = useQuery({
    queryKey: 'Get_StrengthFinders',
    queryFn: () =>
      axios
        .get(`/communities/Get_StrengthFinders/${currentUser?.company}/`)
        .then((res) => res.data?.payload?.StrengthFinder),
    onSuccess: (data) => {
      if (data && data?.length > 0) {
        const modified_data = data.map((el: any, idx: any) => ({
          id: el?.id,
          sr_no: idx + 1,
          MyCommunityName: el?.MyCommunityName,
          MyCommunityId: el?.MyCommunityId,
          CompetitorName: el?.CompetitorName,
          CompetitorId: el?.CompetitorId,
          overall_score: el?.overall_score,
          apartment_amenities_score: el?.apartment_amenities_score,
          community_amenities_score: el?.community_amenities_score,
          dining_experience_score: el?.dining_experience_score,
          care_services_score: el?.care_services_score,
          life_enrichment_score: el?.life_enrichment_score,
          reputation_score: el?.reputation_score,
          internet_social_media_score: el?.internet_social_media_score,
          community_feel_score: el?.community_feel_score,
          pricing_score: el?.pricing_score,
          location_score: el?.location_score,
          updated_at: el?.updated_at,
        }));
        settableData(modified_data);
        setAllData(modified_data);
      }
    },
  });

  const [loading, setLoading] = useState(false);
  const handleRemove = (id: number) => {
    const payload = {
      id,
      op: 'deleted',
    };
    setLoading(true);
    axios
      .post('/communities/Save_StrengthFinder/', { data: [payload] })
      .then((res) => {
        setLoading(false);
        state.refetch();
        showMessage('success', 'Successfully Removed');
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something error to save');
        console.log(err);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const handleOnSearch = (value: string) => {
    const results = matchSorter(allData, value, {
      keys: ['MyCommunityName', 'CompetitorName'],
    });
    settableData(results);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Serial No
          </Typography.Title>
        </div>
      ),
      dataIndex: 'sr_no',
      key: 'sr_no',
      width: 100,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            My Community Name
            <span
              style={{
                background: '#4CD7D2',
                color: '#fff',
                fontSize: '12px',
                padding: '5px 9px',
                borderRadius: '10px',
                marginLeft: 6,
              }}
            >
              ME
            </span>
          </Typography.Title>
        </div>
      ),
      dataIndex: 'MyCommunityName',
      key: 'MyCommunityName',
      width: 430,
      render(_, row) {
        return (
          <div
            style={{
              minWidth: '400px',
              minHeight: 75,
            }}
            className="healing__active"
          >
            <Typography.Title level={4} style={{ margin: 0, padding: '5px 20px' }}>
              {row.MyCommunityName}
            </Typography.Title>
            <Typography.Paragraph style={{ fontSize: '12x', margin: 0, padding: '0 20px' }}>
              Updated At: {row.updated_at && format(new Date(row.updated_at), 'MM-dd-yyyy')}
            </Typography.Paragraph>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Competitor Name
          </Typography.Title>
        </div>
      ),
      dataIndex: 'CompetitorName',
      key: 'CompetitorName',
      width: 430,
      render(value, record) {
        return (
          <div
            style={{
              minWidth: '400px',
              minHeight: 75,
            }}
            className="healing__active"
          >
            <Typography.Title level={4} style={{ margin: 0, padding: '5px 20px' }}>
              {value}
            </Typography.Title>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Overall Score
          </Typography.Title>
        </div>
      ),
      dataIndex: 'overall_score',
      width: 150,
      key: 'overall_score',
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Apartment
          </Typography.Title>
        </div>
      ),
      dataIndex: 'apartment_amenities_score',
      key: 'apartment_amenities_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Community
          </Typography.Title>
        </div>
      ),
      dataIndex: 'community_amenities_score',
      width: 150,
      key: 'community_amenities_score',
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Dining
          </Typography.Title>
        </div>
      ),
      dataIndex: 'dining_experience_score',
      width: 150,
      key: 'dining_experience_score',
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Care
          </Typography.Title>
        </div>
      ),
      dataIndex: 'care_services_score',
      key: 'care_services_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Life
          </Typography.Title>
        </div>
      ),
      dataIndex: 'life_enrichment_score',
      key: 'life_enrichment_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Reputation
          </Typography.Title>
        </div>
      ),
      dataIndex: 'reputation_score',
      key: 'reputation_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Internet
          </Typography.Title>
        </div>
      ),
      dataIndex: 'internet_social_media_score',
      key: 'internet_social_media_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Feel
          </Typography.Title>
        </div>
      ),
      dataIndex: 'community_feel_score',
      key: 'community_feel_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Pricing
          </Typography.Title>
        </div>
      ),
      dataIndex: 'pricing_score',
      key: 'pricing_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Location
          </Typography.Title>
        </div>
      ),
      dataIndex: 'location_score',
      key: 'location_score',
      width: 150,
      render(value) {
        return <span style={{ fontWeight: 700, fontSize: 18 }}>{value}</span>;
      },
    },
    {
      title: (
        <div
          style={{
            padding: '10px 4px',
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Action
          </Typography.Title>
        </div>
      ),
      key: '',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/swot-survey?id=${record.id}`}>
            <Button type="link" icon={<EditFilled />} />
          </Link>
          <Popconfirm
            title="Delete"
            description="Are you sure to delete this?"
            onConfirm={() => handleRemove(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {' '}
            <Button type="link" danger icon={<DeleteFilled />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div style={{ padding: '30px 20px', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
        {contextHolder}
        <Typography.Title style={{ margin: 0, textAlign: 'center', marginBottom: 20 }} level={3}>
          Strength Finder | SWOT Survey
        </Typography.Title>
        <div
          style={{
            float: 'right',
            marginBottom: 10,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Input
            prefix={<SearchOutlined />}
            style={{ marginRight: 10 }}
            placeholder="Search..."
            onChange={(e) => handleOnSearch(e.target.value)}
          />
          <Link to={'/swot-survey'}>
            <Button style={{}} icon={<PlusOutlined />}>
              Add
            </Button>
          </Link>
        </div>
        <TableContainer>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{ x: 'scroll', y: '60vh' }}
            size="small"
            bordered={true}
            className="p_healing_table"
            loading={state.isLoading || loading}
            pagination={false}
            rowKey="id"
          />
        </TableContainer>
      </div>
    </>
  );
};

export default SwotList;
