import React from 'react';

import { Avatar } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import { COMMUNITIES_AVATAR_SIZE } from './constants';
import { COMMUNITY_TYPE } from '../../constants';

const StyledAvatar = styled(Avatar)`
  color: var(--positive-green);
  background-color: var(--positive-green-light);
  border: 1px solid var(--positive-green);

  &.competitor {
    color: var(--neutral-purple);
    background-color: var(--neutral-purple-light);
    border: 1px solid var(--neutral-purple);
  }
  span {
    font-size: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  name: string;
  type: string;
}

const OverviewCommunitiesAvatar = ({ name, type }: Props) => (
  <Container>
    <StyledAvatar
      className={classNames({ competitor: type === COMMUNITY_TYPE.COMPETITOR })}
      size={COMMUNITIES_AVATAR_SIZE}
    >
      {name ? name[0] : 'n/a'}
    </StyledAvatar>
  </Container>
);

export default OverviewCommunitiesAvatar;
