import React from 'react';

import { Pagination as AntdPagination, Typography } from 'antd';
import styled from 'styled-components';

import { COMMUNITY_SETTINGS_LIST_PAGE_SIZE } from './constants';
import { useSettingsContext } from './SettingsContext';

const StyledPagination = styled(AntdPagination)`
  &&.ant-pagination {
    font-size: 12px;

    .ant-pagination-item {
      line-height: 24px;
      color: var(--text-primary);
      border-radius: 32px;
      line-height: 32px;
    }

    .ant-pagination-item-active {
      border-radius: 32px;

      box-shadow: 0 2px 6px 0 var(--box-shadow-gray);
      background-color: #fff;
      border: none;
      a {
        color: var(--text-primary) !important;
      }
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 21px 15px 0 4px;
`;

const PaginationText = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
`;

type Props = {
  setPage: (page: number) => void;
};

const Pagination = ({ setPage }: Props) => {
  const { communitiesTotalCount, page, communities } = useSettingsContext();

  return (
    <PaginationContainer>
      <StyledPagination
        total={communitiesTotalCount}
        current={page}
        pageSize={COMMUNITY_SETTINGS_LIST_PAGE_SIZE}
        onChange={(page: number) => setPage(page)}
      />
      <PaginationText>
        Showing {communities.length} of {communitiesTotalCount} records
      </PaginationText>
    </PaginationContainer>
  );
};

export default Pagination;
