import React from 'react';

import { Segmented, Switch } from 'antd';
import styled from 'styled-components';

import { useDashboardReportsContext } from './DashboardReportsContext';
import { STATUS } from '../../constants';
import { useAppSelector } from '../../store/hook';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 10px;
`;

interface Props {
  onCareTypeChange: (value: any) => void;
  onApartmentTypeCategoryChange: (value: any) => void;
}

const DashboardCareTypeMenu = ({ onCareTypeChange, onApartmentTypeCategoryChange }: Props) => {
  const livingTypes = useAppSelector((state: any) => state.global.livingtypes);

  const { dataStatus, apartmentTypeCategory } = useDashboardReportsContext();

  const livingTypesOptions = livingTypes.map((el: any) => ({
    label: el?.type,
    value: el?.id,
  }));

  return (
    <Container>
      <Segmented disabled={dataStatus === STATUS.LOADING} options={livingTypesOptions} onChange={onCareTypeChange} />
      <SwitchContainer>
        <span>Show Only Basic Room Types</span>
        <Switch
          disabled={dataStatus === STATUS.LOADING}
          onChange={onApartmentTypeCategoryChange}
          checked={!!apartmentTypeCategory}
        />
      </SwitchContainer>
    </Container>
  );
};

export default DashboardCareTypeMenu;
