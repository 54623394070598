import React from 'react';

import { Avatar, CheckboxProps, List, Typography } from 'antd';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';

import { useSettingsContext } from './SettingsContext';

const StyledListItem = styled(List.Item)`
  background-color: white;
  cursor: pointer;

  &&.ant-list-item {
    padding: 15px 10px 15px 15px;
  }

  &:hover {
    background-color: var(--background-gray-light);
  }
`;

const AvatarLetter = styled(Typography.Text)`
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  color: #0ab776;

  &&.ant-avatar-string {
    display: flex;
  }
`;

const CommunityName = styled(Typography.Text)`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #222;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledLeftContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

type Props = {
  item: any;
  isSelected: boolean;
  onClick: (item: any) => void;
};

const CommunityListItem = ({ item, isSelected, onClick }: Props) => {
  const { isMultipleSelection, getItemStatusComponent, toggleOneCommunity } = useSettingsContext();

  let checkbox;
  if (isMultipleSelection) {
    checkbox = (
      <Checkbox checked={isSelected} onChange={() => toggleOneCommunity(item.id)} style={{ marginRight: '15px' }} />
    );
  }

  const status = getItemStatusComponent(item);

  return (
    <StyledListItem style={{ cursor: isMultipleSelection ? 'auto' : 'pointer' }} onClick={() => onClick(item)}>
      <StyledLeftContainer>
        {checkbox}
        <Avatar
          size={30}
          style={{
            backgroundColor: '#e2f5ee',
            border: 'solid 1px var(--positive-green)',
            color: 'var(--positive-green)',
          }}
        >
          <AvatarLetter>{item.comunity_name.charAt(0)}</AvatarLetter>
        </Avatar>
        <CommunityName>{item.comunity_name}</CommunityName>
      </StyledLeftContainer>
      <StatusContainer>
        {status}
        <img src="/icons/chevron-right.svg" style={{ width: '24px', height: '24px' }} />
      </StatusContainer>
    </StyledListItem>
  );
};

export default CommunityListItem;
