import React, { useEffect, useState } from 'react';

import { Bar, BarConfig } from '@ant-design/plots';
import { Col, Row } from 'antd';

import axios from 'config/axiosPrivate';

interface Props {
  community_id: number;
}

const CompareAccommodation = ({ community_id }: Props) => {
  const [type1, setType1Data] = useState<any[]>([]);
  const [type2, setType2Data] = useState<any[]>([]);
  const [type3, setType3Data] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const myCommunity: any = [];
  const getColor = (data: any) => {
    let color = '#000';
    myCommunity.forEach((community: any) => {
      if (community.ApartmentTypeName == data.ApartmentTypeName && community.LivingTypeName == data.LivingTypeName) {
        if (community.base_rent < data.base_rent) {
          color = '#0ab776';
        } else {
          color = '#fa7550';
        }
      }
    });
    return color;
  };
  useEffect(() => {
    //
    if (community_id) {
      setLoading(true);
      axios
        .get(`/communities/Get_CommunityCompareAccommodation/${community_id}/`)
        .then((res) => {
          //
          const headerData: any[] = res.data?.Header;
          const Combination: any[] = res.data?.Combination;
          const Data: any[] = res.data?.Data;
          const type1: any = [];
          const type2: any = [];
          const type3: any = [];
          Data.forEach((item) => {
            if (item.LivingTypeId == 1) {
              type1.push(item);
            } else if (item.LivingTypeId == 2) {
              type2.push(item);
            } else {
              type3.push(item);
            }
          });
          //
          setType1Data(type1);
          setType2Data(type2);
          setType3Data(type3);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [community_id]);

  const type1Config: BarConfig = {
    data: type1,
    xField: 'base_rent',
    yField: 'ApartmentTypeName',
    seriesField: 'CommunityName',
    appendPadding: 20,
    isGroup: true,
    height: 600,
    barStyle: {
      radius: [2, 2, 0, 0],
    },

    label: {
      position: 'middle',
      // 'top', 'middle', 'bottom'
      layout: [
        {
          type: 'interval-adjust-position',
        },
        // {
        //   type: "interval-hide-overlap",
        // },
        {
          type: 'adjust-color',
        },
      ],
      formatter: function (val: any, opt: any) {
        return val?.base_rent > 0 ? '$ ' + parseFloat(val?.base_rent).toLocaleString() : '';
      },
    },
    legend: {
      position: 'top',
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        formatter(text) {
          return `$ ${parseInt(text).toLocaleString()}`;
        },
      },
      tickLine: {
        length: 0,
      },
    },

    tooltip: {
      formatter: (datum: any) => {
        return {
          name: datum.CommunityName,
          value: datum.base_rent ? `$ ${parseInt(datum.base_rent).toLocaleString()}` : '$ 0',
        };
      },
    },
  };
  const type3Config: BarConfig = {
    data: type3,
    xField: 'base_rent',
    yField: 'ApartmentTypeName',
    seriesField: 'CommunityName',
    appendPadding: 20,
    height: 600,
    barStyle: {
      radius: [2, 2, 0, 0],
    },
    isGroup: true,
    // groupField: "type",
    // columnStyle: {
    //   radius: [0, 0, 0, 0],
    // },

    label: {
      position: 'middle',
      // 'top', 'middle', 'bottom'
      layout: [
        {
          type: 'interval-adjust-position',
        },
        // {
        //   type: "interval-hide-overlap",
        // },
        {
          type: 'adjust-color',
        },
      ],
      formatter: function (val: any, opt: any) {
        return val?.base_rent > 0 ? '$ ' + parseFloat(val?.base_rent).toLocaleString() : '';
      },
    },

    legend: {
      position: 'top',
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        formatter(text) {
          return `$ ${parseInt(text).toLocaleString()}`;
        },
      },
      tickLine: {
        length: 0,
      },
    },

    tooltip: {
      formatter: (datum: any) => {
        return {
          name: datum.CommunityName,
          value: datum.base_rent ? `$ ${parseInt(datum.base_rent).toLocaleString()}` : '$ 0',
        };
      },
    },
  };
  const type2Config: BarConfig = {
    data: type2,
    xField: 'base_rent',
    yField: 'ApartmentTypeName',
    seriesField: 'CommunityName',
    appendPadding: 20,
    isGroup: true,
    height: 600,
    barStyle: {
      radius: [2, 2, 0, 0],
    },

    label: {
      position: 'middle',
      // 'top', 'middle', 'bottom'
      layout: [
        {
          type: 'interval-adjust-position',
        },

        {
          type: 'adjust-color',
        },
      ],
      formatter: function (val: any, opt: any) {
        return val?.base_rent > 0 ? '$ ' + parseFloat(val?.base_rent).toLocaleString() : '';
      },
    },
    legend: {
      position: 'top',
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        formatter(text) {
          return `$ ${parseInt(text).toLocaleString()}`;
        },
      },
      tickLine: {
        length: 0,
      },
    },

    tooltip: {
      formatter: (datum: any) => {
        return {
          name: datum.CommunityName,
          value: datum.base_rent ? `$ ${parseInt(datum.base_rent).toLocaleString()}` : '$ 0',
        };
      },
    },
  };

  return (
    <div style={{ width: '100%' }}>
      <Row style={{ marginTop: '20px' }} gutter={[20, 20]}>
        {type1.length > 0 ? (
          <Col span={24}>
            <h6 style={{ textAlign: 'center' }}>Independent Living</h6>
            <Bar {...type1Config} />
          </Col>
        ) : null}
        {type2.length > 0 ? (
          <Col span={24} style={{}}>
            <h6 style={{ textAlign: 'center' }}>Assisted Living</h6>
            <Bar {...type2Config} />
          </Col>
        ) : null}
        {type3.length > 0 ? (
          <Col span={24} style={{}}>
            <h6 style={{ textAlign: 'center' }}>Memory Care</h6>
            <Bar {...type3Config} />
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default CompareAccommodation;
