import React from 'react';

import { message, Modal } from 'antd';

import LocationSearchInput from 'formComponents/LocationSearchInput';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSetLocation: (data: any) => void;
  loading?: boolean;
  searchKeyword?: string;
}

const SearchCommunityModal: React.FC<Props> = ({ open, handleClose, handleSetLocation, loading, searchKeyword }) => {
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <>
      <Modal title="Search Community Name" centered open={open} onCancel={handleCloseModal} width={500} footer={false}>
        <div style={{ height: '300px' }}>
          <LocationSearchInput
            getLocationBySearch={handleSetLocation}
            placeholder="Search places on Google"
            defaultValue={searchKeyword}
          />
        </div>
      </Modal>
    </>
  );
};

export default SearchCommunityModal;
