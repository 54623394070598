import React from 'react';

import shortUUID from 'short-uuid';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreCareFees } from 'store/slices/communitySlice';

import { CareFeeDateType } from '../../../pages/price_healing/Fees/constants';
import FeesFormCareFeesTable from '../../../pages/price_healing/Fees/FeesFormCareFeesTable';

const Container = styled.div`
  min-height: 250px;
  width: 90%;
`;

const FeesFormOnboardingCareFees = () => {
  const dispatch = useAppDispatch();

  const careFees: CareFeeDateType[] = useAppSelector((state) => state.community.careFees);

  const handleAddCommunityCareFeeRecord = (item: Partial<CareFeeDateType>) => {
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp.push(item);
    dispatch(handleStoreCareFees(cp));
  };

  const handleUpdateCommunityCareFeeRecord = (item: Partial<CareFeeDateType>) => {
    const communityCareFeeIndex = careFees.findIndex((careFee) => careFee.key === item.key);
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp[communityCareFeeIndex] = item;
    dispatch(handleStoreCareFees(cp));
  };

  const handleDeleteCommunityCareFeeRecord = (item: Partial<CareFeeDateType>) => {
    const communityCareFeeIndex = careFees.findIndex((careFee) => careFee.key === item.key);
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp.splice(communityCareFeeIndex, 1);
    dispatch(handleStoreCareFees(cp));
  };

  return (
    <Container>
      <FeesFormCareFeesTable
        loading={false}
        data={careFees}
        onCreate={handleAddCommunityCareFeeRecord}
        onUpdate={handleUpdateCommunityCareFeeRecord}
        onDelete={handleDeleteCommunityCareFeeRecord}
      />
    </Container>
  );
};

export default FeesFormOnboardingCareFees;
