import React from 'react';
import { useNavigate } from 'react-router-dom';

import AddCommunity from './AddCommunity';

const AddCommunityInitial = () => {
  const navigate = useNavigate();

  const handleAddCommunity = (community: any) => {
    navigate(`/view-apartments?id=${community?.id}`);
  };

  return <AddCommunity onComplete={handleAddCommunity} />;
};

export default AddCommunityInitial;
