import React from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import CommunityViewHeaderBreadcrumbs from './CommunityViewHeaderBreadcrumbs';
import { getCommunity } from '../../../apis/CommunityAPI';
import HeaderViewActions from '../actions/HeaderViewActions';
import { useCommunityViewContext } from '../CommunityViewContext';
import HeaderViewFilters from '../filters/HeaderViewFilters';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  border-bottom: 1px solid var(--line-gray);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 30px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 40px;
  font-weight: 900;
  padding: 3px 0px;
`;

const CommunityViewHeader = () => {
  const { communityId, competitorId } = useCommunityViewContext();

  const { data: community } = useQuery({
    queryKey: ['community', 'information', communityId, competitorId],
    queryFn: () => getCommunity(competitorId ?? communityId),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  return (
    <Container>
      <Content>
        <HeaderContent>
          <Info>
            <CommunityViewHeaderBreadcrumbs community={community} />
            <Title>{community?.comunity_name}</Title>
          </Info>
          <HeaderViewActions />
        </HeaderContent>
        <HeaderViewFilters />
      </Content>
    </Container>
  );
};

export default CommunityViewHeader;
