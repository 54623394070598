import React from 'react';

import { Modal as AntDModal, ModalProps } from 'antd';
import styled from 'styled-components';

const StyledModal = styled(AntDModal)`
  &&& {
    .ant-modal-content {
      padding: 0px;
      line-height: 1;
      border-radius: 1px;
    }
    .ant-modal-header {
      padding: 25px 30px 0px 30px;
    }
    .ant-modal-title {
      font-size: 20px;
      font-family: var(--font-bold);
    }
    .ant-modal-body {
      padding: 0px 30px;
    }
    .ant-modal-footer {
      margin-top: 30px;
      padding: 20px 30px;
      border-top: 1px solid var(--line-gray);
    }
  }
`;

const Modal = (props: ModalProps) => {
  return <StyledModal {...props} />;
};

export default Modal;
