import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import HeaderLogo from './HeaderLogo';
import HeaderSelector from './HeaderSelector';
import HeaderUser from './HeaderUser';
import classes from './styles.module.scss';

const MainHeader = () => {
  const { pathname } = useLocation();

  const isHome = matchPath('/home', pathname);
  const isOnboarding = matchPath('/onboarding', pathname);
  const showSelector = isHome || isOnboarding;

  return (
    <div className={classes.header_main} color="light">
      <HeaderLogo />
      {showSelector && <HeaderSelector />}
      <HeaderUser />
    </div>
  );
};

export default MainHeader;
