import React from 'react';

import { Scatter } from '@ant-design/plots';

type Props = {
  data: any;
};

const SCATTER_CHART_HEIGHT = 262;

const ScatterChart = ({ data }: Props) => {
  return (
    <Scatter
      data={data}
      appendPadding={20}
      height={SCATTER_CHART_HEIGHT}
      xField="score"
      yField="categoryScore"
      size={5}
      color="blue"
      shape="circle"
      pointStyle={{ fillOpacity: 1 }}
      yAxis={{
        line: {
          style: {
            stroke: ' #aaa',
          },
        },
        title: {
          text: 'Importance to Customer',
        },
      }}
      xAxis={{
        min: 0,
        max: 6,
        tickCount: 8,
        title: {
          text: 'Avg.Market Competition Rating',
        },
        label: {
          autoHide: true,
          autoRotate: false,
        },
        grid: {
          line: {
            style: (text: any, index: any) => {
              if (index == 1) {
                return {
                  stroke: '#fa7550',
                };
              } else if (index == 3) {
                return {
                  stroke: '#89919d',
                };
              } else if (index == 5) {
                return {
                  stroke: '#22cfd1',
                };
              }
              return {
                stroke: '#eee',
              };
            },
          },
        },
        line: {
          style: {
            stroke: '#aaa',
          },
        },
        tickLine: {
          length: 0,
        },
      }}
      tooltip={{
        fields: ['Score', 'Category Score'],
      }}
      label={{
        formatter: function (val: any) {
          return val?.categoryName;
        },
      }}
      legend={false}
      style={{ opacity: '1', height: '100%' }}
    />
  );
};

export default ScatterChart;
