import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import { ACTIONS, STEPS } from './constants';
import { SwotCompetitorAnalysisContext } from './SwotCompetitorAnalysisContext';
import SwotCompetitorAnalysisModalBody from './SwotCompetitorAnalysisModalBody';
import SwotCompetitorAnalysisModalFooter from './SwotCompetitorAnalysisModalFooter';
import SwotCompetitorAnalysisModalHeader from './SwotCompetitorAnalysisModalHeader';
import { COMMUNITY_TYPE, STATUS } from '../../../../constants';
import { useAppSelector } from '../../../../store/hook';
import Modal from '../../../lib/Modal';
import EditSwotDataModal from '../EditSwotDataModal/EditSwotDataModal';

type Props = {
  open: boolean;
  onClose: () => void;
};

const StyledModal = styled(Modal)`
  &&& .ant-modal-body {
    padding: 0;
  }
`;

const SwotCompetitorAnalysisModal = ({ open, onClose }: Props) => {
  const { selectedCommunity, communityList, communityCompetitorsList } = useAppSelector((state) => state.global);
  const [step, setStep] = useState<number>(STEPS.COMPETITOR_SELECTOR); // setting it STEPS.COMPETITOR_SELECTOR because invite to edit and view options are not availbale yet
  const [selectedAction, setSelectedAction] = useState<string>(ACTIONS.EDIT_DATA);
  const [selectedCompetitor, setSelectedCompetitor] = useState<number>(0);
  const [communityOptions, setCommunityOptions] = useState<any[]>([]);

  const [loading, setLoading] = useState<string>(STATUS.IDLE);

  const [showInsertSwotDataModal, setShowInsertSwotDataModal] = useState(false);
  const [competitorId, setCompetitorId] = useState<number | null>(null);

  const closeModal = useCallback(() => setShowInsertSwotDataModal(false), [setShowInsertSwotDataModal]);

  const handleClose = () => {
    setStep(STEPS.COMPETITOR_SELECTOR);
    setSelectedAction(ACTIONS.EDIT_DATA);
    setSelectedCompetitor(0);

    onClose();
  };

  const onCompetitorSelect = (id: number) => {
    setCompetitorId(id);
    setShowInsertSwotDataModal(true);
    handleClose();
  };

  useEffect(() => {
    const loadCommunityOptions = () => {
      const isCompetitor = selectedCommunity?.type === COMMUNITY_TYPE.COMPETITOR;
      const options = isCompetitor
        ? communityList.filter((community) => community.id === selectedCommunity?.parentId)
        : communityCompetitorsList.filter((competitor) => competitor.parent_id === selectedCommunity?.id);

      if (options.length === 1) {
        onCompetitorSelect(options[0].id);
      } else {
        setCommunityOptions(options);
        setLoading(STATUS.LOADED);
      }
    };
    if (open) {
      loadCommunityOptions();
    }
  }, [open]);

  return (
    <SwotCompetitorAnalysisContext.Provider
      value={{
        step,
        selectedAction,
        selectedCompetitor,
        communityOptions,
        setStep,
        setSelectedAction,
        setSelectedCompetitor,
        handleClose,
        onCompetitorSelect,
      }}
    >
      {loading == STATUS.LOADED && (
        <StyledModal open={open} onCancel={handleClose} footer={<SwotCompetitorAnalysisModalFooter />} width={540}>
          <SwotCompetitorAnalysisModalHeader />
          <SwotCompetitorAnalysisModalBody />
        </StyledModal>
      )}

      {showInsertSwotDataModal && (
        <EditSwotDataModal onClose={closeModal} open={showInsertSwotDataModal} competitorId={competitorId} />
      )}
    </SwotCompetitorAnalysisContext.Provider>
  );
};

export default SwotCompetitorAnalysisModal;
