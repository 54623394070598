import React, { useState } from 'react';

import axios from 'config/axiosPrivate';

import { CareFeeDateType } from './constants';
import { useFeesContext } from './FeesContext';
import FeesFormCareFeesTable from './FeesFormCareFeesTable';
import { mapCareFeeDataToRequest, mapCareFeeResponseToData } from './utils';
import { STATUS } from '../../../constants';

const FeesFormCareFees = () => {
  const { communityId, showMessage } = useFeesContext();

  const [careFees, setCareFees] = useState<CareFeeDateType[]>([]);

  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  React.useEffect(() => {
    getCareFees();
  }, [communityId]);

  const getCareFees = () => {
    setDataLoading(STATUS.LOADING);
    axios
      .get(`/communities/${communityId}/care-fees?active=1`)
      .then((response) => {
        const data = response.data;
        const formatted_data: CareFeeDateType[] = data?.map((item: any) => mapCareFeeResponseToData(item));
        setCareFees(formatted_data);
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to fetch Care Fees');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleCreate = (item: Partial<CareFeeDateType>) => {
    const data = mapCareFeeDataToRequest(item);
    axios
      .post(`/communities/${communityId}/care-fees`, [data])
      .then((response) => {
        const newCommunityCareFees = response.data.map((record: CareFeeDateType) => mapCareFeeResponseToData(record));
        setCareFees([...careFees, ...newCommunityCareFees]);
        showMessage('success', 'Care Fee created successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to create Care Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<CareFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCareFeeDataToRequest(item);

    axios
      .put(`/communities/${communityId}/care-fees/${item.id}`, data)
      .then((response) => {
        const fee_index = careFees.findIndex((fee) => fee.id === item.id);
        careFees[fee_index] = mapCareFeeResponseToData(response.data);
        setCareFees([...careFees]);
        showMessage('success', 'Care Fee changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to change Care Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<CareFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    axios
      .delete(`/communities/${communityId}/care-fees/${item.id}`)
      .then((response) => {
        const fee_index = careFees.findIndex((care_fee) => care_fee.id === item.id);
        careFees.splice(fee_index, 1);
        setCareFees([...careFees]);
        showMessage('success', 'Care Fee deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Care Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <FeesFormCareFeesTable
      loading={dataLoading === STATUS.LOADING}
      data={careFees}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default FeesFormCareFees;
