import React from 'react';

import styled from 'styled-components';

import Chart from '../Graph/Chart';
import { CHART_TYPE } from '../Graph/constants';
import { DATA_RANGE_TYPE } from '../Widget/constants';
import { useWidgetContext } from '../Widget/WidgetContext';

interface Props {
  data: any;
}

const Container = styled.div`
  height: 100%;
`;

const GraphWidgetContentGraph = ({ data }: Props) => {
  const { type, rangeType } = useWidgetContext();

  return (
    <Container>
      {rangeType === DATA_RANGE_TYPE.NOW && (
        <Chart
          widgetType={type}
          type={CHART_TYPE.BAR}
          data={data?.sort((a: any, b: any) => b.value - a.value) ?? []}
          xField={'value'}
          yField={'community_name'}
          seriesField={'community_name'}
        />
      )}
      {rangeType === DATA_RANGE_TYPE.OVERTIME && (
        <Chart
          widgetType={type}
          type={CHART_TYPE.LINE}
          data={data}
          xField={'date'}
          yField={'value'}
          seriesField={'community_name'}
        />
      )}
    </Container>
  );
};

export default GraphWidgetContentGraph;
