import React from 'react';

import dayjs from 'dayjs';

import { useAppSelector } from 'store/hook';

import { OccupancyType } from './constants';
import { EditableTable } from '../../../common/Table';
import { INPUT_TYPES } from '../../../constants';

interface Props {
  loading: boolean;
  data: OccupancyType[];
  onCreate: (record: Partial<OccupancyType>) => void;
  onUpdate: (record: Partial<OccupancyType>) => void;
  onDelete: (record: Partial<OccupancyType>) => void;
}

const OccupanciesFormData = ({ loading, data, onCreate, onUpdate, onDelete }: Props) => {
  const livingTypes = useAppSelector((state) => state.global.livingtypes);

  const apartmentTypes = useAppSelector((state) => state.global.apartmenttypes);

  const livingTypesOptions = livingTypes.map((val) => ({
    label: val.type,
    value: +val.id,
  }));

  const accommodationOptions = apartmentTypes.map((val) => ({
    label: val.type,
    value: +val.id,
  }));

  const uniqueLivingTypeApartmentTypeValidator = async (record: OccupancyType) => {
    const filteredData = data.filter((entry) => entry.id != record?.id);
    const hasLivingTypeApartmentTypeEntry = filteredData.some(
      (entry) => entry.livingTypeId === record.livingTypeId && entry.apartmentTypeId === record.apartmentTypeId
    );

    if (hasLivingTypeApartmentTypeEntry) {
      return Promise.reject('This entry matches an existing record with the same Living Type and Accommodation.');
    }

    return Promise.resolve();
  };

  const vacantUnitsValidator = async (record: OccupancyType) => {
    const validTotalUnitsValue = record?.totalApartmentsUnits !== null && record.totalApartmentsUnits >= 0;
    const validFreeUnitsValue = record?.freeApartmentsUnits !== null && record.freeApartmentsUnits >= 0;

    const onlyOneFieldValue = validTotalUnitsValue ? !validFreeUnitsValue : validFreeUnitsValue;
    const hasInvalidValueRatio = record?.totalApartmentsUnits < record?.freeApartmentsUnits;

    if (onlyOneFieldValue) {
      return Promise.reject('Please provide number of total units and number of vacant units ');
    } else if (hasInvalidValueRatio) {
      return Promise.reject('Number of vacant units cannot be greater than number of total units');
    }

    return Promise.resolve();
  };

  const futureDatesValidator = async (record: OccupancyType) => {
    const isFutureDate = record.date > dayjs().endOf('day');
    if (isFutureDate) {
      return Promise.reject('Future dates are not acceptable. Choose a valid date.');
    }
    return Promise.resolve();
  };

  const columns = [
    {
      title: 'Living Type',
      dataIndex: 'livingTypeId',
      inputType: INPUT_TYPES.SELECT,
      options: livingTypesOptions,
      editable: true,
      required: true,
      width: '200px',
      validator: uniqueLivingTypeApartmentTypeValidator,
    },
    {
      title: 'Accommodation',
      dataIndex: 'apartmentTypeId',
      inputType: INPUT_TYPES.SELECT,
      options: accommodationOptions,
      editable: true,
      required: true,
      width: '340px',
      validator: uniqueLivingTypeApartmentTypeValidator,
    },
    {
      title: 'Total Units',
      dataIndex: 'totalApartmentsUnits',
      inputType: INPUT_TYPES.NUMBER_POSITIVE,
      editable: true,
    },
    {
      title: 'Vacant Units',
      dataIndex: 'freeApartmentsUnits',
      inputType: INPUT_TYPES.NUMBER_POSITIVE,
      editable: true,
      dependencies: ['totalApartmentsUnits'],
      validator: vacantUnitsValidator,
    },
    {
      title: 'Occupancy %',
      dataIndex: 'occupancyPercentage',
      align: 'right',
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      inputType: 'date',
      editable: true,
      initialValue: dayjs(),
      width: '200px',
      validator: futureDatesValidator,
    },
  ];

  return (
    <EditableTable
      tableLayout="fixed"
      loading={loading}
      columns={columns}
      data={data}
      onAdd={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  );
};

export default OccupanciesFormData;
