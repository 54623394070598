import React from 'react';

import OccupanciesForm from './OccupanciesForm';

interface Props {
  communityId: any;
}

const Occupancies = ({ communityId }: Props) => {
  return <OccupanciesForm communityId={communityId} />;
};

export default Occupancies;
