import React, { useState } from 'react';

import CommunitySettingLabel from './CommunitySettingLabel';
import {
  COMMUNITY_LABEL_COLOR,
  COMMUNITY_SETTINGS_PAGE_TYPE,
  MARKET_RATE_COMPARISON_UPDATES_STATUS,
} from './constants';
import MarketRateComparisonModal from './MarketRateComparisonModal';
import SettingsPage from './SettingsPage';
import { MarketRateComparisonUpdateSettings } from './types';

const getListItemStatusComponent = (item: any) => {
  if (item.market_rate_comparison_updates_status.enabled) {
    const { underMarketRate, overMarketRate } = item.market_rate_comparison_updates_status;
    if (underMarketRate.enabled && overMarketRate.enabled) {
      return (
        <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.POSITIVE}>
          {`When ${overMarketRate.value}% over or ${underMarketRate.value}% under market rate`}
        </CommunitySettingLabel>
      );
    } else if (underMarketRate.enabled) {
      return (
        <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.POSITIVE}>
          {`When ${underMarketRate.value}% under market rate`}
        </CommunitySettingLabel>
      );
    } else if (overMarketRate.enabled) {
      return (
        <CommunitySettingLabel
          color={COMMUNITY_LABEL_COLOR.POSITIVE}
        >{`When ${overMarketRate.value}% over market rate`}</CommunitySettingLabel>
      );
    }
  } else {
    const { statusElements } = MARKET_RATE_COMPARISON_UPDATES_STATUS;
    return (
      <CommunitySettingLabel color={COMMUNITY_LABEL_COLOR.NEUTRAL}>{statusElements.OFF.label}</CommunitySettingLabel>
    );
  }
};

const filterCommunitiesByStatus = (data: any[], status: number) => {
  const { statusElements } = MARKET_RATE_COMPARISON_UPDATES_STATUS;

  if (status === statusElements.WHEN_OVER_MARKET_RATE.value) {
    return data.filter((c: any) => {
      const status = c.market_rate_comparison_updates_status;
      return status.enabled && status.overMarketRate.enabled;
    });
  } else if (status === statusElements.WHEN_UNDER_MARKET_RATE.value) {
    return data.filter((c: any) => {
      const status = c.market_rate_comparison_updates_status;
      return status.enabled && status.underMarketRate.enabled;
    });
  } else if (status === statusElements.OFF.value) {
    return data.filter((c: any) => !c.market_rate_comparison_updates_status.enabled);
  }

  return data;
};

const MarketRateComparison = () => {
  const [communities, setCommunities] = useState<{ id: number; comunity_name: string }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMultipleSelection, setMultipleSelection] = useState(false);
  const [communitiesToModify, setCommunitiesToModify] = useState<any[]>([]);

  const setUpdatesStatus = (newStatus: MarketRateComparisonUpdateSettings) => {
    const filteredOutCommunities = communities.filter(
      (community) => !communitiesToModify.some((modifyCommunity) => modifyCommunity.id === community.id)
    );
    const modifiedCommunities = communitiesToModify.map((c: any) => ({
      ...c,
      market_rate_comparison_updates_status: newStatus,
    }));
    setCommunities([...filteredOutCommunities, ...modifiedCommunities]);
    setMultipleSelection(false);
  };

  const showCommunitiesEditModal = (items: any[]) => {
    setCommunitiesToModify(items);
    setIsModalOpen(true);
  };

  return (
    <>
      <SettingsPage
        filterOptions={Object.values(MARKET_RATE_COMPARISON_UPDATES_STATUS.statusElements)}
        getItemStatusComponent={getListItemStatusComponent}
        filterCommunitiesByStatus={filterCommunitiesByStatus}
        showCommunitiesEditModal={showCommunitiesEditModal}
        communities={communities}
        setCommunities={setCommunities}
        settingsPageType={COMMUNITY_SETTINGS_PAGE_TYPE.MARKET_RATE_COMPARISON}
        selectPopupWidth={190}
        isMultipleSelection={isMultipleSelection}
        setMultipleSelection={setMultipleSelection}
      />
      <MarketRateComparisonModal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        communitiesToModify={communitiesToModify}
        onSave={setUpdatesStatus}
      />
    </>
  );
};

export default MarketRateComparison;
