import React from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';
import { Divider } from 'components/lib/Divider';

import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import { SIGNATURE_DOCUMENT_TYPES } from '../constants';
import { PROPOSAL_MODAL_INCENTIVES_FEES_TYPE } from '../create/constants';
import { useIncentivesContext } from '../IncentivesContext';

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-bottom: 10px;
`;

const FormItem = styled(Form.Item)`
  margin-top: 20px;
  display: flex;
  flex-flow: column;

  label {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    height: 18px;
    width: 18px;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  color: var(--dark-blue);
  padding-top: 2px;
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
`;

const IncentivesProposalModalProposalAgreements = () => {
  const { selectedProposalUnit } = useIncentivesContext();
  const { incentive, readOnly } = useIncentivesProposalModalContext();
  const displayedRatesSelection = Form.useWatch('rates');

  const signatureDocuments = selectedProposalUnit?.community && selectedProposalUnit.community.signature_documents;

  if (!signatureDocuments || !signatureDocuments.length) {
    return null;
  }

  const petFeeEnabled = displayedRatesSelection?.includes(PROPOSAL_MODAL_INCENTIVES_FEES_TYPE.PET_FEE);

  const filteredSignatureDocuments = signatureDocuments
    .filter(document => {
      if (!incentive) {
        return document.type !== SIGNATURE_DOCUMENT_TYPES.TYPE_INCENTIVE;
      }
      return true;
    })
    .filter(document => {
      if (!petFeeEnabled) {
        return document.type !== SIGNATURE_DOCUMENT_TYPES.TYPE_P;
      }
      return true;
    });

  return (
    <div>
      <Title>Agreements & Addenda</Title>
      <Divider />
      <FormItem name={'signature_documents_ids'}>
        <CheckboxGroup>
          {filteredSignatureDocuments.map(document => {
            let disabled = false;
            if (document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_AGREEMENT) { disabled = true; }
            if (document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_A) { disabled = true; }
            if (document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_INCENTIVE && incentive) { disabled = true; }
            if (document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_P && petFeeEnabled) { disabled = true; }
            
            return (
              <StyledCheckbox
                key={document.id}
                value={document.id}
                disabled={document.required || disabled || readOnly}
                className={'green'}
              >
                <CheckboxLabel>{document.display_name}</CheckboxLabel>
              </StyledCheckbox>
            );
          })}
        </CheckboxGroup>
      </FormItem>
    </div>
  )
}

export default IncentivesProposalModalProposalAgreements