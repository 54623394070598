import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import Rate from '../lib/Rate';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: normal;
`;

const Community = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Market = styled.span`
  display: flex;
  font-size: 12px;
  gap: 4px;

  &.hidden {
    visibility: hidden;
  }
`;

const MarketValue = styled.span`
  color: var(--negative-orange);

  &.positive {
    color: var(--positive-green);
  }
`;

interface Props {
  value?: number;
  total?: number;
  marketValue?: number;
  marketTotal?: number;
}

const OverviewCommunitiesRatingCell = ({ value = 0, total = 0, marketValue = 0, marketTotal = 0 }: Props) => (
  <Container>
    <Community>
      <span>{value?.toFixed(1)}</span>
      <span>({total?.toFixed(0)})</span>
      <Rate className={'small'} disabled allowHalf value={value} />
    </Community>
    <Market className={classNames({ hidden: !marketValue })}>
      <span>Competitors: </span>
      <MarketValue className={classNames({ positive: marketValue >= value })}>{marketValue?.toFixed(1)}</MarketValue>
      <span>({marketTotal?.toFixed(0)})</span>
    </Market>
  </Container>
);

export default OverviewCommunitiesRatingCell;
