import React, { useState } from 'react';

import HeaderSelectorContent from './HeaderSelectorContent';
import { HeaderSelectorContext } from './HeaderSelectorContext';

const HeaderSelector = () => {
  const [communityList, setCommunityList] = useState<any[]>([]);

  return (
    <HeaderSelectorContext.Provider
      value={{
        communityList,
        setCommunityList,
      }}
    >
      <HeaderSelectorContent />
    </HeaderSelectorContext.Provider>
  );
};

export default HeaderSelector;
