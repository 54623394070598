import React from 'react';

import styled from 'styled-components';

import { ACTIONS, STEPS } from './constants';
import { useSwotCompetitorAnalysisContext } from './SwotCompetitorAnalysisContext';
import { COMMUNITY_TYPE } from '../../../../constants';
import { useAppSelector } from '../../../../store/hook';

const Container = styled.div`
  padding: 20px 0 10px 30px;
  margin: 0 0 30px 0;
  width: 510px;
  border-bottom: 1px solid var(--line-gray);
`;

const Title = styled.p`
  font-size: 20px;
  font-family: var(--font-bold);
  color: var(--text-primary);
`;

const Label = styled.p`
  font-size: 14px;
  color: var(--text-secondary);
`;

const Link = styled.span`
  color: var(--link-blue);
`;

const Note = styled.p`
  font-size: 14px;
  font-family: var(--font-bold);
  color: var(--text-primary);
  margin-top: 22px;
`;

const SwotCompetitorAnalysisModalHeader = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { step, selectedAction } = useSwotCompetitorAnalysisContext();

  const isCompetitor = selectedCommunity?.type == COMMUNITY_TYPE.COMPETITOR;
  const showNote = step == STEPS.COMPETITOR_SELECTOR;
  const showEditDataNote = showNote && selectedAction == ACTIONS.EDIT_DATA;
  const showInviteEmailNote = showNote && selectedAction != ACTIONS.EDIT_DATA;

  return (
    <Container>
      <Title>SWOT Competitor Analysis</Title>
      <Label>
        for {isCompetitor && 'competitor'} <Link>{selectedCommunity?.name}</Link>
      </Label>
      {showEditDataNote && (
        <Note>{`Select ${isCompetitor ? 'my community' : 'the competitor'} to compare against:`}</Note>
      )}
      {showInviteEmailNote && (
        <Note>
          johnsmith@email.com will receive an email link to enter analysis data. <br /> Select the competitor for them
          to compare this community against.
        </Note>
      )}
    </Container>
  );
};

export default SwotCompetitorAnalysisModalHeader;
