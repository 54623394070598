import React from 'react';

import DataTableAverage from '../DataTable/DataTableAverage';
import DataTableRange from '../DataTable/DataTableRange';
import { DATA_RANGE_TYPE } from '../Widget/constants';
import { useWidgetContext } from '../Widget/WidgetContext';

interface Props {
  data: any;
}

const GraphWidgetContentTable = ({ data }: Props) => {
  const { rangeType } = useWidgetContext();

  return (
    <>
      {rangeType === DATA_RANGE_TYPE.NOW && <DataTableAverage data={data} />}
      {rangeType === DATA_RANGE_TYPE.OVERTIME && <DataTableRange data={data} />}
    </>
  );
};

export default GraphWidgetContentTable;
