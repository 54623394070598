import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import Select from '../../lib/Select';
import { useIncentivesContext } from '../IncentivesContext';
import { IncentiveType } from '../types';

const Container = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const NO_INCENTIVE = 0;

const IncentivesProposalModalInfoSelector = () => {
  const { selectedProposalUnit } = useIncentivesContext();
  const { incentive, readOnly, setIncentive } = useIncentivesProposalModalContext();

  const handleChange = (value: any) => {
    const selectedIncentive = (selectedProposalUnit?.incentives ?? []).find((item: IncentiveType) => item.id === value);
    setIncentive(selectedIncentive);
  };

  // Filter out all the instance of incentives from selector that have either expired or not started
  // This is redundant as it's also being filtered on the backend
  const incentiveOptions = selectedProposalUnit?.incentives
    .filter(
      (item: IncentiveType) =>
        dayjs().isSameOrAfter(dayjs(item.start_at), 'day') &&
        (!item.end_at || dayjs().isSameOrBefore(dayjs(item.end_at), 'day'))
    )
    .map((item: IncentiveType) => ({
      label: item.name,
      value: item.id,
    }));

  const hasIncentives = Boolean(incentiveOptions?.length);

  const options = [
    ...(incentiveOptions ?? []),
    { label: hasIncentives ? 'No Incentive' : 'No Incentive Available', value: NO_INCENTIVE },
  ];

  return (
    <Container>
      <Select
        style={{
          width: 240,
          height: 36,
        }}
        className={'rectangular'}
        disabled={!hasIncentives || readOnly}
        value={incentive?.id ?? NO_INCENTIVE}
        options={options}
        onChange={handleChange}
      />
    </Container>
  );
};

export default IncentivesProposalModalInfoSelector;
