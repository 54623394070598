import React, { createContext, useContext } from 'react';

import { WidgetFilterType } from '../types';

type WidgetContent = {
  id?: number;
  type: number;
  name: string;
  note: string;
  filters: WidgetFilterType;
  meta: any;
  elementRef: any;
  rangeType: string;
  displayType: string;
  csvData: any;
  showRemoveWidgetModal: boolean;
  showEditWidgetModal: boolean;
  setMeta: (obj: any) => void;
  setRangeType: (range: string) => void;
  setDisplayType: (display: string) => void;
  setCSVData: (data: any[]) => void;
  setShowRemoveWidgetModal: (show: boolean) => void;
  setShowEditWidgetModal: (show: boolean) => void;
};

export const WidgetContext = createContext<WidgetContent>({
  id: 0,
  type: 0,
  name: '',
  note: '',
  filters: {
    competitors: [],
    careTypes: [],
    roomTypes: [],
    careLevels: [],
    reviewSources: [],
    reviewCategories: [],
    swotCategories: [],
  },
  meta: undefined,
  elementRef: undefined,
  rangeType: '',
  displayType: '',
  csvData: [],
  showRemoveWidgetModal: false,
  showEditWidgetModal: false,
  setMeta: () => {
    //  Do Nothing
  },
  setRangeType: () => {
    //  Do Nothing
  },
  setDisplayType: () => {
    //  Do Nothing
  },
  setCSVData: () => {
    //  Do Nothing
  },
  setShowRemoveWidgetModal: () => {
    //  Do Nothing
  },
  setShowEditWidgetModal: () => {
    //  Do Nothing
  },
});

export const useWidgetContext = () => useContext(WidgetContext);
