import React, { useState } from 'react';

import { Image } from 'antd';

interface Props {
  url: string;
}

const IsValidImage = ({ url }: Props) => {
  const [validImage, setValidImage] = useState(true);

  return (
    <>
      <Image
        width={'200px'}
        height={150}
        style={{ objectFit: 'cover' }}
        src={url}
        fallback="/no-image.svg"
        preview={validImage}
        onError={() => setValidImage(false)}
      />
    </>
  );
};

export default IsValidImage;
