import React from 'react';

import styled from 'styled-components';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';

import { useShopRequestsContext } from '../ShopRequestsContext';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const StyledFilter = styled(SelectAdvancedFilter)`
  margin-right: 5px;
`;

const ShopRequestsShopperFilter = () => {
  const { staffUsers } = useShopRequestsContext();
  const { filters, setFilters } = useShopRequestsDataContext();

  const onChange = (values: any[]) => {
    setFilters({ ...filters, shoppers: values.map((item: any) => item.value) });
  };

  const staffUsersArray = staffUsers?.map((user) => ({ value: user.userId, label: user.name })) || [];
  const options = [...staffUsersArray, { value: 0, label: 'Unassigned ' }];

  return (
    <StyledFilter options={options} defaultValue={filters.shoppers ?? []} onSelect={onChange} placeholder="Assignee" />
  );
};

export default ShopRequestsShopperFilter;
