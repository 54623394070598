import axios from '../config/axiosPrivate';

export const getGoogleMetricsReviews = async (params: any = {}) => {
  const url = '/communities/google-metrics';

  const response = await axios.get(url, {
    params,
  });
  return response.data;
};

export const createGoogleMetrics = async (payload: any) => {
  const url = '/communities/google-metrics/create';

  const response = await axios.post(url, payload);
  return response.data;
};

export const createGoogleReviews = async (payload: any) => {
  const url = '/communities/google-reviews/create';

  const response = await axios.post(url, payload);
  return response.data;
};
