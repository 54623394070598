import React from 'react';

import styled from 'styled-components';

import { isMysteryShopAdmin, isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import EditShopRequestModalChecklist from './EditShopRequestModalChecklist';
import EditShopRequestModalCommunityInfo from './EditShopRequestModalCommunityInfo';
import EditShopRequestModalDates from './EditShopRequestModalDates';
import EditShopRequestModalEnterDataButton from './EditShopRequestModalEnterDataButton';
import EditShopRequestModalHeader from './EditShopRequestModalHeader';
import EditShopRequestModalNotes from './EditShopRequestModalNotes';
import EditShopRequestModalPricingOptions from './EditShopRequestModalPricingOptions';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 1040px;
`;

const Content = styled.div`
  display: flex;
  flex-flow: row;
  max-height: 435px;
`;

const MainInfo = styled.div`
  display: flex;
  flex-flow: column;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  padding: 20px 30px 0 30px;
  justify-content: space-between;
  border-right: solid 1px var(--line-gray);
  overflow-y: auto;
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  max-width: 370px;
`;

const EditShopRequestModalContent = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);
  const isShopRequestShopper = currentUser && isMysteryShopShopper(currentUser);

  return (
    <Container>
      <EditShopRequestModalHeader />
      <Content>
        <Row>
          <MainInfo>
            <EditShopRequestModalCommunityInfo />
            <EditShopRequestModalPricingOptions />
          </MainInfo>
          <EditShopRequestModalNotes />
        </Row>
        <Column>
          {/*
          EditShopRequestModalEnterDataButton is hidden because shoppers that are not superUsers might not have access to a company that shopRequest
          is related to. Therefore we need to first fix the permissions so that such users can get to the CommunityInfo screen in such cases
          */}
          {/* {isShopRequestShopper && <EditShopRequestModalEnterDataButton />} */}
          {isShopRequestAdmin && <EditShopRequestModalDates />}
          <EditShopRequestModalChecklist />
        </Column>
      </Content>
    </Container>
  );
};

export default EditShopRequestModalContent;
