import React from 'react';

import { Button, Col, Form, Input, Modal, Row, Select, SelectProps, Tabs } from 'antd';
import type { TabsProps } from 'antd';

import axios from 'config/axiosPrivate';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  handleAddData: (values: any) => void;
  loading?: boolean;
  edit?: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  companyOptions: SelectProps['options'];
}

const CreateBuisnessModal: React.FC<Props> = ({
  open,
  handleClose,
  title = '',
  handleAddData,
  companyOptions,
  loading,
  setEdit,
  edit,
}) => {
  const [form] = Form.useForm();
  const [communities, setCommunities] = React.useState<any>([]);
  const [competitors, setCompetitors] = React.useState<any>([]);
  const [loadingCommunities, setLoadingCommunities] = React.useState(false);
  const [loadingCompetitors, setLoadingCompetitors] = React.useState(false);
  const [isCompetitor, setIsCompetitor] = React.useState(false);
  const [selectedCommunity, setSelectedCommunity] = React.useState<any>(null);
  const [selectedCompetitor, setSelectedCompetitor] = React.useState<any>(null);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Community',
    },
    {
      key: '2',
      label: 'Competitor',
    },
  ];

  const onFinish = (values: any) => {
    values.aggrOptions = '4';
    const community = isCompetitor
      ? competitors.filter((item: any) => item.value == values.competitor)
      : communities.filter((item: any) => item.value == values.buisness);

    if (community.length > 0) {
      values.buisnessName = community[0].label;
      values.latitude = community[0].latitude;
      values.longitude = community[0].longitude;
      values.bid = community[0].bid;

      if (values.competitor) {
        values.buisness = values.competitor;
      }
    }

    handleAddData(values);
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const onChange = (key: string) => {
    setEdit(false);
    if (key == '2') {
      setIsCompetitor(true);
      form.resetFields(['competitor', 'buisness', 'zip', 'phone', 'company', 'state', 'city', 'address', 'website']);
    } else {
      setIsCompetitor(false);
      form.resetFields(['competitor', 'buisness', 'zip', 'phone', 'state', 'city', 'address', 'website']);
    }
  };

  const getCommunityInfo = (id: number, competitor: boolean) => {
    const community = competitor
      ? competitors.filter((item: any) => item.value == id)
      : communities.filter((item: any) => item.value == id);
    if (community.length > 0) {
      form.setFieldValue('zip', community[0].zip_code);
      form.setFieldValue('phone', community[0].phone_number);
      form.setFieldValue('address', community[0].address);
      form.setFieldValue('city', community[0].city);
      form.setFieldValue('state', community[0].state);
      form.setFieldValue('website', community[0].website);
      if (competitor) {
        setSelectedCompetitor(community[0]);
      } else {
        setSelectedCommunity(community[0]);
      }
    }
  };

  const getCompanyCommunities = async (value: any) => {
    form.resetFields(['competitors', 'buisness', 'zip', 'phone']);
    setLoadingCommunities(true);
    try {
      const res = await axios.get(`/communities/Get_CompanyCommunities/${value}/`);
      const data = res.data?.payload;
      if (data) {
        const list = data?.map((el: any) => {
          if (el.type == 'apartment') {
            return {
              label: el?.comunity_name,
              value: el?.id,
              zip_code: el.zip_code,
              phone_number: el.phone_number,
              address: el.address,
              website: el.website,
              state: el.state,
              city: el.city,
              latitude: el.latitude,
              longitude: el.longitude,
              bid: el.birdeye_business_id,
            };
          }
        });
        setCommunities(list);
      } else {
        setCommunities([]);
      }
      setLoadingCommunities(false);
    } catch (error) {
      setLoadingCommunities(false);
    }
  };

  const getCommunitiyCompetitors = async (value: any) => {
    form.resetFields(['competitor', 'zip', 'phone']);
    setLoadingCompetitors(true);
    try {
      const res = await axios.get(`communities/Get_CompetitorInfo/${value}/`);
      const data = res.data?.payload.Competitor_Info;
      if (data) {
        const list = data?.map((el: any) => ({
          label: el?.ApartmentName,
          value: el?.Id,
          zip_code: el.ZipCode,
          phone_number: el.PhoneNumber,
          address: el.ApartmentAddress,
          website: el.Website,
          city: el.City,
          state: el.State,
          latitude: el.Latitude,
          longitude: el.Longitude,
          bid: el.birdeye_business_id,
        }));
        setCompetitors(list);
      } else {
        setCompetitors([]);
      }
      setLoadingCompetitors(false);
    } catch (error) {
      setLoadingCompetitors(false);
    }
  };

  React.useEffect(() => {
    setEdit(Boolean(selectedCompetitor?.bid));
  }, [selectedCompetitor]);

  React.useEffect(() => {
    if (!isCompetitor) {
      setEdit(Boolean(selectedCompetitor?.bid));
    }
  }, [selectedCommunity]);

  return (
    <>
      <Modal title={title} centered open={open} onCancel={handleCloseModal} width={600} footer={false}>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
          initialValues={{ type: 'Enterprise-Location' }}
        >
          <Row gutter={[20, 10]}>
            <Col xs={12}>
              <Form.Item
                name="company"
                label={<p className="form_label">Select Company</p>}
                rules={[
                  { required: true, message: 'Please Select Company Name' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Company does not exist on Bird Api...!!'));
                    },
                  }),
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={companyOptions}
                  placeholder="Company"
                  style={{ width: '100%' }}
                  onSelect={(val) => {
                    const company = companyOptions?.filter((item) => item.value == val)[0];

                    getCompanyCommunities(company?.id);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="buisness"
                label={
                  <p className="form_label">
                    Community{' '}
                    {form.getFieldValue('buisness') && (
                      <span>{selectedCommunity.bid && `[${selectedCommunity.bid}]`}</span>
                    )}
                  </p>
                }
                rules={[{ required: true, message: 'Please Select Community' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={communities}
                  loading={loadingCommunities}
                  placeholder="Select Community"
                  style={{ width: '100%' }}
                  onSelect={(val) => {
                    getCommunityInfo(val, false);
                    if (isCompetitor) {
                      getCommunitiyCompetitors(val);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {isCompetitor && (
              <Col xs={12}>
                <Form.Item
                  name="competitor"
                  label={
                    <p className="form_label">
                      Competitor{' '}
                      {form.getFieldValue('competitor') && (
                        <span>{selectedCompetitor.bid && `[${selectedCompetitor.bid}]`}</span>
                      )}
                    </p>
                  }
                  rules={[{ required: true, message: 'Please Select Competitor' }]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    loading={loadingCompetitors}
                    options={competitors}
                    placeholder="Select Competitor"
                    style={{ width: '100%' }}
                    onSelect={(val) => {
                      getCommunityInfo(val, true);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={12}>
              <Form.Item
                name="zip"
                label={<p className="form_label">Zip Code</p>}
                rules={[{ required: true, message: 'Please Enter Zip Code' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="type"
                label={<p className="form_label">Buisness Type</p>}
                rules={[{ required: true, message: 'Please Select Buisness Type' }]}
              >
                <Select
                  options={[
                    { value: 'Business', label: 'Business' },
                    {
                      value: 'Enterprise-Location',
                      label: 'Enterprise-Location',
                    },
                    {
                      value: 'Enterprise-Product',
                      label: 'Enterprise-Product',
                    },
                    { value: 'Reseller', label: 'Reseller' },
                  ]}
                  placeholder="Select"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="phone"
                label={<p className="form_label">Phone Number</p>}
                rules={[{ required: true, message: 'Please Enter Phone Number' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="address"
                label={<p className="form_label">Address</p>}
                rules={[{ required: true, message: 'Please Enter Address' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="city"
                label={<p className="form_label">City</p>}
                rules={[{ required: true, message: 'Please Enter City' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="state"
                label={<p className="form_label">State</p>}
                rules={[{ required: true, message: 'Please Enter State' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="website"
                label={<p className="form_label">Website</p>}
                // rules={[{ required: true, message: "Please Enter Website" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={12}>
              <Form.Item
                name="aggrOptions"
                label={<p className="form_label">Aggregation Options</p>}
                rules={[
                  {
                    required: true,
                    message: "Please Select Aggregation Options",
                  },
                ]}
              >
                <Select
                  options={[
                    { value: "0", label: "0 to Disable all aggregations" },
                    {
                      value: "1",
                      label:
                        "1 to Disable Fetch Business Info, Enable Review Aggregation and Search Business",
                    },
                    {
                      value: "3",
                      label:
                        "3 to Enable Search Business, Disable Fetch Business and Disable Review Aggregation",
                    },
                    { value: "4", label: "4 to Enable all Aggregations" },
                  ]}
                  placeholder="Select"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col> */}
            {/* <Col xs={24}>
              <Form.Item
                name="buisnessName"
                label={<p className="form_label">Buisness Name</p>}
                style={}
              >
                <Input />
              </Form.Item>
            </Col> */}
          </Row>

          <br />
          {!edit && (
            <p>
              <span style={{ color: 'orange' }}>Note:</span> The newly created buisness will take atleast 30 mins to get
              registerd
            </p>
          )}
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  {edit ? 'Update on BirdApi' : 'Create on BirdApi'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateBuisnessModal;
