import styled from 'styled-components';

export const Divider = styled.div`
  min-height: 1px;
  background-image: linear-gradient(
    to right,
    var(--background-gray-light) 0%,
    var(--line-gray) 50%,
    var(--background-gray-light)
  );
`;
