import React from 'react';

import styled from 'styled-components';

import { useShopRequestModalContext } from './ShopRequestModalContext';
import { useShopRequestsContext } from '../ShopRequestsContext';
import ShopRequestStyledSelect from '../ShopRequestStyledSelect';
import ShopRequestUserAvatar from '../ShopRequestUserAvatar';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const ShopRequestModalShopperSelect = () => {
  const { shopperId, setShopperId } = useShopRequestModalContext();
  const { staffUsers } = useShopRequestsContext();

  return (
    <ShopRequestStyledSelect
      options={staffUsers?.map((user) => ({ value: user.userId, label: user.name }))}
      placeholder="Select a shopper (Optional)"
      value={shopperId}
      onChange={(value: number) => setShopperId(value)}
      labelRender={({ value, label }) => (
        <Container key={value}>
          <ShopRequestUserAvatar label={label} />
          {label}
        </Container>
      )}
      style={{ marginBottom: '10px' }}
    />
  );
};

export default ShopRequestModalShopperSelect;
