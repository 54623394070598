import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';
import { getCompetitorName } from 'selectors/communitySelector';
import { useAppSelector } from 'store/hook';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 28px 0 19px;
  border-bottom: solid 1px var(--line-gray);
`;

const StyledCommunityName = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`;

const StyledDivider = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: oblique;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--text-secondary);
  padding: 0 30px;
`;

type Props = {
  competitorId: number | null;
};

const EditSwotDataModalHeader = ({ competitorId }: Props) => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const competitorName = useAppSelector((state) => getCompetitorName(state, competitorId));
  const { community } = useCommunityViewContext();

  return (
    <Container>
      <StyledCommunityName title={competitorName || selectedCommunity?.name} style={{ textAlign: 'end' }}>
        {competitorName || selectedCommunity?.name}
      </StyledCommunityName>
      <StyledDivider>vs.</StyledDivider>
      <StyledCommunityName title={community.comunity_name} style={{ textAlign: 'start' }}>
        {community.comunity_name}
      </StyledCommunityName>
    </Container>
  );
};

export default EditSwotDataModalHeader;
