import React, { useContext } from 'react';

import OverviewCommunitiesTable from './OverviewCommunitiesTable';
import { OverviewContext } from './OverviewContext';
import { COMMUNITY_TYPE } from '../../constants';

const OverviewCompetitors = () => {
  const { competitors } = useContext(OverviewContext);

  return <OverviewCommunitiesTable type={COMMUNITY_TYPE.COMPETITOR} communities={competitors} />;
};

export default OverviewCompetitors;
