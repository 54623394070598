import React, { useState } from 'react';

import { DownOutlined } from '@ant-design/icons/lib';
import { Space } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import CommunityViewWidgetModal from './CommunityViewWidgetModal';
import {
  CUSTOM_WIDGET_TYPES,
  GRAPH_WIDGET_TYPES,
  SINGLE_METRIC_WIDGET_TYPES,
  WIDGET_TYPES,
  WIDGET_TYPES_LABELS,
} from '../../../common/Widgets/constants';
import { DEFAULT_WIDGET, WIDGET_MODAL_TYPE } from '../../../common/Widgets/Widget/constants';
import Button from '../../lib/Button';
import Dropdown from '../../lib/Dropdown';

const MenuContainer = styled.div`
  display: flex;
  background: white;
  flex-direction: row;
  border: 1px solid var(--line-gray);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryTitle = styled.span`
  font-family: var(--font-bold);
  color: var(--text-secondary);
  padding: 7px 10px;
  background-color: var(--background-gray);
`;

const MenuItem = styled.div`
  width: 200px;
  padding: 7px 10px;
  border-top: 1px solid var(--line-gray);
  cursor: pointer;
  &:hover {
    background-color: var(--light-gray);
  }
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;

  &.left-column {
    ${MenuItem} {
      border-right: 1px solid var(--line-gray);
    }
  }
  &.right-column {
    ${MenuItem}:last-child {
      border-bottom: 1px solid var(--line-gray);
    }
  }
`;

const CommunityViewAddWidget = () => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [widgetType, setWidgetType] = useState<number>(WIDGET_TYPES.MARKET_RATE);

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;

  const handleSelection = (widgetType: any) => {
    setOpenDropdown(false);

    setOpenModal(true);
    setWidgetType(widgetType);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  if (permissions?.indexOf('views.add_widget') === -1) {
    return null;
  }

  return (
    <div>
      {openModal && (
        <CommunityViewWidgetModal
          open={openModal}
          onClose={handleClose}
          modalType={WIDGET_MODAL_TYPE.ADD}
          widget={{
            ...DEFAULT_WIDGET,
            type: widgetType,
          }}
        />
      )}

      <Dropdown
        open={openDropdown}
        onOpenChange={(visible) => setOpenDropdown(visible)}
        dropdownRender={(menu) => {
          return (
            <MenuContainer>
              <Category>
                <CategoryTitle>GRAPHS AND TABLES</CategoryTitle>
                <CategoryList className={'left-column'}>
                  {[...GRAPH_WIDGET_TYPES, WIDGET_TYPES.SWOT_PERFORMANCE, ...CUSTOM_WIDGET_TYPES].map((type) => (
                    <MenuItem key={type} onClick={() => handleSelection(type)}>
                      {WIDGET_TYPES_LABELS[type]}
                    </MenuItem>
                  ))}
                </CategoryList>
              </Category>
              <Category>
                <CategoryTitle>SINGLE METRICS</CategoryTitle>
                <CategoryList className={'right-column'}>
                  {SINGLE_METRIC_WIDGET_TYPES.map((type) => (
                    <MenuItem key={type} onClick={() => handleSelection(type)}>
                      {WIDGET_TYPES_LABELS[type]}
                    </MenuItem>
                  ))}
                </CategoryList>
              </Category>
            </MenuContainer>
          );
        }}
      >
        <Button type="primary">
          <Space align="baseline">
            <span>Add Widget</span>
            <DownOutlined style={{ fontSize: 12 }} />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default CommunityViewAddWidget;
