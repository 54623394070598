import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from './CommunityViewContext';
import CommunityViewLayoutResponsive from './CommunityViewLayoutResponsive';
import { SINGLE_METRIC_WIDGET_TYPES } from '../../common/Widgets/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  padding: 0;
  width: 100%;
  background-color: var(--background-gray);
  box-sizing: border-box;
  position: relative;

  .react-grid-item.react-grid-placeholder {
    background-color: rgba(68, 64, 119, 0.8);
  }
`;

interface Props {
  data: any;
}

const CommunityViewLayout = ({ data }: Props) => {
  const { viewFilters } = useCommunityViewContext();

  const [singleMetricItems, setSingleMetricItems] = useState<any>([]);
  const [graphMetricItems, setGraphMetricItems] = useState<any>([]);
  const [globalFilters, setGlobalFilters] = useState<any>(undefined);

  useEffect(() => {
    if (data?.widgets) {
      const filteredSingleMetricItems = data.widgets.filter((item: any) =>
        SINGLE_METRIC_WIDGET_TYPES.includes(item.type)
      );
      const filteredGraphMetricItems = data.widgets.filter(
        (item: any) => !SINGLE_METRIC_WIDGET_TYPES.includes(item.type)
      );
      setSingleMetricItems(filteredSingleMetricItems);
      setGraphMetricItems(filteredGraphMetricItems);
    }

    setGlobalFilters(viewFilters);
  }, [data, viewFilters]);

  return (
    <Container>
      <CommunityViewLayoutResponsive
        singleMetricItems={singleMetricItems}
        graphMetricItems={graphMetricItems}
        globalFilters={globalFilters}
      />
    </Container>
  );
};

export default CommunityViewLayout;
