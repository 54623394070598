import React, { useEffect } from 'react';

import { Button, Col, Form, Input, Modal, Row } from 'antd';
const { TextArea } = Input;

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  handleAddNote: (value: any) => void;
  loading?: boolean;
  initialValues?: any;
}

const AddLinkModal: React.FC<Props> = ({ open, handleClose, title = '', handleAddNote, loading, initialValues }) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    handleAddNote({ ...values });
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [open]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldValue('page_url', initialValues.page_url);
      form.setFieldValue('description', initialValues.description);
    }
  }, [initialValues]);

  return (
    <>
      <Modal title={title} centered open={open} onOk={handleClose} onCancel={handleClose} width={700} footer={false}>
        <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off" form={form}>
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Form.Item
                name="page_url"
                label={<p className="form_label">Page URL:</p>}
                rules={[{ required: false, message: 'Please Enter Page URL' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="description"
                label={<p className="form_label">Description:</p>}
                rules={[{ required: false, message: 'Please Enter Description' }]}
              >
                <TextArea placeholder="Write Description Here..." />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddLinkModal;
