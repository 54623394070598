import React, { useEffect, useState } from 'react';

import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import axios from 'config/axiosPrivate';

function groupByApartmentId(data: any[]) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val.apartment_id;
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
}

function groupByLivingType(data: any[], key: any) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val[key];
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
}

interface DataType {
  id: any;
  CommunityName: string;
  Type: 'apartment' | 'competitor';
  careFee: {
    id: number;
    amount: string;
    fee_note: string;
    units: string;
    occupancy: string;
    unit_note: '';
    level_1: number;
    level_2: number;
    level_3: number;
    level_4: number;
    level_5: number;
    level_6: number;
    level_7: number;
    level_8: number;
    medication_management_fee: number;
    care_pricing_type: string;
    additional_care_levels_rates: string;
    additional_care_costs: string;
    status: 1;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    average_care_cost: number;
    Community: string;
    LivingType: string;
    abv: string;
    LivingTypeid: number;
    FeeType: string;
    FeeTypeid: number;
  };
  key: string;
}

interface Props {
  community_id: number;
  pdf?: boolean;
}

const CompareCare = ({ community_id, pdf }: Props) => {
  const [tableCollumns, settableCollumns] = useState<any[]>([]);
  const [splitCollumns, setSplitCollumns] = useState<any[]>([]);
  const [tableData, settableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const renderSubComp = (record: any) => {
    if (record?.FeeTypeid === 1) {
      // all-inclusive
      return (
        <div>
          <Typography.Text style={{ color: '#989696' }}>
            <strong>Medical Management Fee: </strong>
            <span style={{ color: '#222' }}>$ {parseInt(record?.medication_management_fee || 0).toLocaleString()}</span>
          </Typography.Text>
        </div>
      );
    }
    if (record?.FeeTypeid === 2) {
      // all-inclusive
      return (
        <div>
          <Typography.Text style={{ color: '#989696' }}>
            <strong>Average Care Cost: </strong>
            <span style={{ color: '#222' }}>
              $ {parseFloat(record?.average_care_cost || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
            </span>
          </Typography.Text>
        </div>
      );
    }

    if (record?.FeeTypeid === 3) {
      // all-inclusive
      return (
        <div>
          {record?.level_1 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 1: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_1).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
          {record?.level_2 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 2: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_2).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
          {record?.level_3 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 3: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_3).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
          {record?.level_4 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 4: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_4).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
          {record?.level_5 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 5: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_5).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
          {record?.level_6 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 6: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_6).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
          {record?.level_7 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 7: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_7).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
          {record?.level_8 > 0 && (
            <Typography.Paragraph style={{ color: '#989696', marginBottom: 5 }}>
              <strong>Level 8: </strong>

              <span style={{ color: '#222' }}>
                ${' '}
                {parseFloat(record?.level_8).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
            </Typography.Paragraph>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (community_id) {
      setLoading(true);
      axios
        .get(`/communities/Get_HomeCommunityCompFees/${community_id}/`)
        .then((res) => {
          setLoading(false);
          const headerData: any[] = res.data?.Header;
          const Combination: any[] = res.data?.Combination;
          const Data: any[] = res.data?.Data;

          const table_columns: ColumnsType<DataType> = headerData.map((collumn: any) => ({
            title: (
              <div
                style={{
                  padding: '10px 4px',
                  whiteSpace: 'normal',
                }}
              >
                <Typography.Title style={{ margin: 0 }} level={4}>
                  {collumn?.CommunityName}

                  {collumn?.CommunityType === 'apartment' && (
                    <span
                      style={{
                        background: '#4CD7D2',
                        color: '#fff',
                        fontSize: '12px',
                        padding: '5px 9px',
                        borderRadius: '10px',
                        marginLeft: 6,
                        whiteSpace: 'normal',
                      }}
                    >
                      ME
                    </span>
                  )}
                </Typography.Title>
              </div>
            ),
            dataIndex: collumn?.CommunityID,
            key: collumn?.CommunityID,
            width: collumn?.CommunityType === 'apartment' ? (pdf ? '400px' : 400) : !pdf ? 300 : 'auto',
            render: (value: any, record: any) => {
              if (value) {
                const data = value[0];
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: collumn?.CommunityType === 'apartment' ? (pdf ? '350px' : 'auto') : 'auto',
                      minWidth: collumn?.CommunityType === 'apartment' ? '350px' : 'auto',
                    }}
                    className={collumn?.CommunityType === 'apartment' ? 'healing__active' : ''}
                  >
                    <div>
                      <Typography.Title level={4} style={{ margin: 0, marginBottom: -10 }}>
                        <strong>{data?.LivingType}:</strong>
                      </Typography.Title>
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                          fontSize: 16,
                          color: '#989696',
                          marginTop: 8,
                        }}
                      >
                        {data?.FeeType}
                      </Typography.Title>
                      {renderSubComp(data)}
                    </div>
                  </div>
                );
              } else {
                return <div style={{ padding: 20 }}></div>;
              }
            },
          }));
          settableCollumns(table_columns);
          setSplitCollumns(splitArray(headerData));
          const MergedCombinations = groupByLivingType(Combination, 'LivingTypeId');
          // console.log("merged", MergedCombinations);
          // set Table data
          const apartmentData = Data.filter((el) => el?.Type === 'apartment')[0];
          const rows = Object.keys(MergedCombinations).map((cmb: any) => {
            const cmb_data = Data.filter((d) => {
              const i = MergedCombinations[cmb].findIndex((e: any) => e.Combination_Id == d?.Combination_Id);
              if (i > -1) {
                return d;
              }
            });

            const hasCommunity = cmb_data?.find((el) => el?.ApartmentTypeName === 'apartment');

            if (cmb_data.length > 0 && !hasCommunity) {
              // console.log("not has community");
              const communityData = {
                id: `${Date.now() + Math.random()}`,
                // apartment_id: apartmentData
                //   ? apartmentData?.apartment_id
                //   : headerData[0]?.CommunityID,
                apartment_id: apartmentData ? apartmentData?.apartment_id : community_id,
                fees_type_id: cmb_data[0]?.fees_type_id,
                living_type_id: cmb_data[0]?.living_type_id,
                fee_note: null,
                created_at: '2022-10-18T20:56:58',
                updated_at: '2022-10-18T20:56:58',
                level_1: 0,
                level_2: 0,
                level_3: 0,
                level_4: 0,
                level_5: 0,
                level_6: 0,
                level_7: 0,
                level_8: 0,
                medication_management_fee: 0,
                average_care_cost: 0,
                created_by: 'Miguel  Cabrera',
                updated_by: 0,
                amount: 0,
                units: 0,
                occupancy: 0,
                unit_note: 0,
                care_pricing_type: cmb_data[0]?.care_pricing_type,
                additional_care_levels_rates: null,
                additional_care_costs: null,
                status: 1,
                Community: 'Palmilla Senior Living',
                CommunityName: 'Palmilla Senior Living',
                Communityid: 1484,
                LivingType: cmb_data[0]?.LivingType,
                LivingTypeName: cmb_data[0]?.LivingType,
                abv: 'MC',
                LivingTypeid: cmb_data[0]?.LivingTypeid,
                FeeType: cmb_data[0]?.FeeType,
                FeeTypeName: cmb_data[0]?.FeeTypeName,
                FeeTypeid: cmb_data[0]?.FeeTypeid,
                ApartmentTypeName: cmb_data[0]?.FeeTypeid,
                Combination_Id: 13,
              };

              cmb_data.push(communityData);
            }
            // console.log(cmb_data);
            return {
              ...groupByApartmentId(cmb_data),
            };
          });

          // const rows = Combination.map((cmb: any) => {
          //   const cmb_data = Data.filter(
          //     (d) => d?.Combination_Id === cmb?.Combination_Id
          //   );

          //   const hasCommunity = cmb_data?.find(
          //     (el) => el?.ApartmentTypeName === "apartment"
          //   );

          //   if (cmb_data.length > 0 && !hasCommunity) {
          //     // console.log("not has community");
          //     let communityData = {
          //       id: `${Date.now() + Math.random()}`,
          //       // apartment_id: apartmentData
          //       //   ? apartmentData?.apartment_id
          //       //   : headerData[0]?.CommunityID,
          //       apartment_id: apartmentData
          //         ? apartmentData?.apartment_id
          //         : community_id,
          //       fees_type_id: cmb_data[0]?.fees_type_id,
          //       living_type_id: cmb_data[0]?.living_type_id,
          //       fee_note: null,
          //       created_at: "2022-10-18T20:56:58",
          //       updated_at: "2022-10-18T20:56:58",
          //       level_1: 0,
          //       level_2: 0,
          //       level_3: 0,
          //       level_4: 0,
          //       level_5: 0,
          //       level_6: 0,
          //       level_7: 0,
          //       level_8: 0,
          //       medication_management_fee: 0,
          //       average_care_cost: 0,
          //       created_by: "Miguel  Cabrera",
          //       updated_by: 0,
          //       amount: 0,
          //       units: 0,
          //       occupancy: 0,
          //       unit_note: 0,
          //       care_pricing_type: cmb_data[0]?.care_pricing_type,
          //       additional_care_levels_rates: null,
          //       additional_care_costs: null,
          //       status: 1,
          //       Community: "Palmilla Senior Living",
          //       CommunityName: "Palmilla Senior Living",
          //       Communityid: 1484,
          //       LivingType: cmb_data[0]?.LivingType,
          //       LivingTypeName: cmb_data[0]?.LivingType,
          //       abv: "MC",
          //       LivingTypeid: cmb_data[0]?.LivingTypeid,
          //       FeeType: cmb_data[0]?.FeeType,
          //       FeeTypeName: cmb_data[0]?.FeeTypeName,
          //       FeeTypeid: cmb_data[0]?.FeeTypeid,
          //       ApartmentTypeName: cmb_data[0]?.FeeTypeid,
          //       Combination_Id: 13,
          //     };

          //     cmb_data.push(communityData);
          //   }
          //   // console.log(cmb_data);
          //   return {
          //     ...groupByApartmentId(cmb_data),
          //   };
          // });

          const filtered = rows.filter((r: any) => Object.keys(r).length !== 0);
          // console.log(filtered);

          settableData(filtered);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [community_id]);

  const splitArray = (array: any) => {
    const splitedArray = [];
    const limit = array.length > 1 ? array.length - 1 : array.length;
    for (let i = 0; i < limit; ) {
      const subArray = array.slice(i + 1, i + 4);
      const withComArr = [array[0], ...subArray];
      const table_column: ColumnsType<DataType> = withComArr.map((collumn: any) => ({
        title: (
          <div
            style={{
              padding: '10px 4px',
              whiteSpace: 'normal',
            }}
          >
            <Typography.Title style={{ margin: 0 }} level={4}>
              {collumn?.CommunityName}

              {collumn?.CommunityType === 'apartment' && (
                <span
                  style={{
                    background: '#4CD7D2',
                    color: '#fff',
                    fontSize: '12px',
                    padding: '5px 9px',
                    borderRadius: '10px',
                    marginLeft: 6,
                    whiteSpace: 'normal',
                  }}
                >
                  ME
                </span>
              )}
            </Typography.Title>
          </div>
        ),
        dataIndex: collumn?.CommunityID,
        key: collumn?.CommunityID,
        width: collumn?.CommunityType === 'apartment' ? (pdf ? '400px' : 400) : !pdf ? 300 : 'auto',
        render: (value: any, record: any) => {
          if (value) {
            const data = value[0];
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: collumn?.CommunityType === 'apartment' ? (pdf ? '350px' : 'auto') : 'auto',
                  minWidth: collumn?.CommunityType === 'apartment' ? '350px' : 'auto',
                }}
                className={collumn?.CommunityType === 'apartment' ? 'healing__active' : ''}
              >
                <div>
                  <Typography.Title level={4} style={{ margin: 0, marginBottom: -10 }}>
                    <strong>{data?.LivingType}:</strong>
                  </Typography.Title>
                  <Typography.Title
                    level={5}
                    style={{
                      margin: 0,
                      fontSize: 16,
                      color: '#989696',
                      marginTop: 8,
                    }}
                  >
                    {data?.FeeType}
                  </Typography.Title>
                  {renderSubComp(data)}
                </div>
              </div>
            );
          } else {
            return <div style={{ padding: 20 }}></div>;
          }
        },
      }));
      splitedArray.push(table_column);
      i = i + 3;
    }

    return splitedArray;
  };

  return (
    <div>
      {pdf ? (
        <div>
          {splitCollumns.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Table
                columns={item}
                dataSource={tableData}
                scroll={{ x: '1300' }}
                style={{ minWidth: '92vw' }}
                size="middle"
                bordered={true}
                className="p_healing_table"
                loading={loading}
                showHeader={true}
                pagination={false}
                rowKey={(record) => record[community_id][0].id}
                // rowKey={() => Math.random()}
              />
            </div>
          ))}
        </div>
      ) : (
        <Table
          columns={tableCollumns}
          dataSource={tableData}
          scroll={!pdf ? { x: 'max-content', y: '80vh' } : { x: '1300' }}
          size="middle"
          bordered={true}
          className="p_healing_table"
          loading={loading}
          showHeader={true}
          pagination={false}
          rowKey={(record) => record[community_id][0].id}
          // rowKey={() => Math.random()}
        />
      )}
    </div>
  );
};

export default CompareCare;
