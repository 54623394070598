import React from 'react';

import { useShopRequestModalContext } from './ShopRequestModalContext';
import { MYSTERY_SHOP_MODAL_REQUEST_FROM } from '../constants';
import ShopRequestStyledSelect from '../ShopRequestStyledSelect';

type Props = {
  communities: any[];
  value: number | null;
  disabled?: boolean;
  placeholder?: string;
  onChange: (value: any) => void;
};

const ShopRequestModalDropdown = ({ communities, onChange, value, placeholder, disabled = false }: Props) => {
  const { requestFrom } = useShopRequestModalContext();
  const options = communities?.map((community: any) => ({ value: community?.id, label: community?.comunity_name }));
  const defaultPlaceholder =
    requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.FURTHER ? 'Select another community' : 'Select another competitor';

  const performChange = (newValue: number) => {
    const community = communities?.find((el) => el.id === newValue);
    onChange(community);
  };

  return (
    <ShopRequestStyledSelect
      placeholder={placeholder || defaultPlaceholder}
      options={options}
      onChange={performChange}
      value={value}
      disabled={disabled}
      // search should be handled by backend to allow fuzzy searching
      showSearch
      filterOption={(input, option) =>
        Boolean(option?.label) &&
        typeof option?.label === 'string' &&
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default ShopRequestModalDropdown;
