import React from 'react';

import { TextAreaProps } from 'antd/es/input';
import AntDTextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';

const StyledTextArea = styled(AntDTextArea)`
  background-color: var(--background-gray);
  border: 1px solid var(--line-gray);
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-secondary);
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  && {
    resize: none;
  }
`;

const TextArea = (props: TextAreaProps) => {
  return <StyledTextArea {...props}>{props.children}</StyledTextArea>;
};

export default TextArea;
