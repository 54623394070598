import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { CloseOutlined } from '@ant-design/icons/lib';
import { Button, Table } from 'antd';
import { format } from 'date-fns';

import classes from './cma.module.scss';
import { useCMASummaryContext } from './CMASummaryContext';
import { MODAL_TYPES } from './constants';

const CMASummaryPDFModal = () => {
  const componentRef = useRef<any>();
  const { openModal, setOpenModal, tableColumns, tableDataSource, dataLoading, communityName } = useCMASummaryContext();

  const file_name_with_date = communityName + '-' + format(new Date(), 'MM-dd-yyyy');

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: file_name_with_date,
  });

  return (
    <div className={classes.table_wrapper}>
      {openModal === MODAL_TYPES.PDF && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            background: '#fff',
            padding: '10px 20px',
            boxSizing: 'border-box',
            overflow: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <Button onClick={handlePrint} type="primary">
              Download
            </Button>
            <Button shape="circle" icon={<CloseOutlined />} onClick={() => setOpenModal('')} />
          </div>
          <div ref={componentRef}>
            <Table
              pagination={false}
              columns={tableColumns}
              dataSource={tableDataSource}
              bordered={true}
              loading={dataLoading}
              showHeader={true}
              rowKey="tableKey"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CMASummaryPDFModal;
