import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '../../store/hook';

const CommunityOverview = () => {
  const navigate = useNavigate();
  const { communityId } = useParams();
  const { views } = useAppSelector((state) => state.global);

  useEffect(() => {
    const defaultItem = views.length > 0 && views[0];
    if (defaultItem) {
      navigate(`/community/${communityId}/view/${defaultItem.id}`);
    }
  }, [views]);
  return null;
};

export default CommunityOverview;
