import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Button, message, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import shortUUID from 'short-uuid';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

import classes from './cma.module.scss';
import CMASummaryActions from './CMASummaryActions';
import { CMASummaryContext } from './CMASummaryContext';
import CMASummaryEmailModal from './CMASummaryEmailModal';
import CMASummaryPDFModal from './CMASummaryPDFModal';
import { ACCOMODATION, CARE_FEES, COMMUNITY_FEES, COMMUNITY_FIELDS, TITLE_ARR } from './constants';

function groupByApartmentId(data: any[], key: any) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val[key];
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
}

interface Props {
  community_id: number;
  communityInfo: any;
}

const CMASummary = ({ community_id, communityInfo }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const apartmentTypes = useAppSelector((state) => state.global.apartmenttypes);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataHeaders, setHeaders] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState('');

  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const SUBTITLE_ARR = livingTypes.map((item) => item.type);

  const showMessage = (type: 'success' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const renderColumnName = (name: string) => {
    if (!name) return '';
    const newName = name?.split('_').join(' ');
    return newName;
  };

  const { isLoading } = useQuery({
    queryKey: `Get_CommunityCMASummary_${community_id}`,
    queryFn: () => axios.get(`/communities/Get_CommunityCMASummary/${community_id}`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const headerData = data[0]?.Header;
      const cmm: any[] = data[1]?.community;
      const comp: any[] = data[2]?.competitors;
      const feeInc: any[] = data[3]?.feesinc;
      const livinginfo: any[] = data[4]?.livinginfo;
      const carefees: any[] = data[5]?.carefees;
      const shopnotes: any[] = data[6]?.shopnotes;
      const occupancies: any = data[7].occupancies;
      const dateWiseFeeInc: any = data[8]?.datewisefeeinc;
      setHeaders(headerData);
      const table_columns: ColumnsType<any> = [
        {
          title: (
            <div
              style={{
                padding: '10px 4px',
              }}
            >
              <Typography.Title style={{ margin: 0 }} level={4}>
                Community Name
              </Typography.Title>
            </div>
          ),
          dataIndex: 'dataKey',
          key: 'dataKey',
          render(value, record, index) {
            const apartmentTypeNames = apartmentTypes.map((el) => el.type);

            if (TITLE_ARR.indexOf(value) !== -1) {
              return (
                <Button
                  type="text"
                  className="p_healing_active_tab"
                  shape="round"
                  style={{
                    marginRight: 20,
                    height: 'auto',
                    padding: '5px 20px',
                    fontSize: 20,
                    pointerEvents: 'none',
                  }}
                >
                  {value}
                </Button>
              );
            } else if (SUBTITLE_ARR.indexOf(value) !== -1) {
              return (
                <Typography.Title style={{ margin: 0, textDecoration: 'underline' }} level={4}>
                  {value}
                </Typography.Title>
              );
            } else if (apartmentTypeNames.indexOf(value) !== -1) {
              return (
                <Typography.Title
                  style={{
                    margin: 0,
                    fontWeight: 700,
                    fontSize: 18,
                    textTransform: 'capitalize',
                  }}
                  level={4}
                >
                  {value}
                </Typography.Title>
              );
            } else {
              return (
                <Typography.Title
                  style={{
                    margin: 0,
                    fontSize: 16,
                    textTransform: 'capitalize',
                  }}
                  level={5}
                >
                  {renderColumnName(value)}
                </Typography.Title>
              );
            }
          },
          fixed: 'left',
          width: 230,
        },
        ...headerData.map((collumn: any) => ({
          title: (
            <div
              style={{
                padding: '10px 4px',
              }}
            >
              <Typography.Title style={{ margin: 0, whiteSpace: 'break-spaces' }} level={4}>
                {collumn?.comunity_name}
                {collumn?.type === 'apartment' && (
                  <span
                    style={{
                      background: '#4CD7D2',
                      color: '#fff',
                      fontSize: '12px',
                      padding: '5px 9px',
                      borderRadius: '10px',
                      marginLeft: 6,
                    }}
                  >
                    ME
                  </span>
                )}
              </Typography.Title>
            </div>
          ),
          dataIndex: collumn?.id,
          key: collumn?.id,
          render(value: any, record: any) {
            if (value && value.length > 0) {
              const rowData = value[0];
              if (record.dataKey === 'website' || record.dataKey === 'file_url') {
                return (
                  <Typography.Link href={rowData[record.dataKey]} style={{ margin: 0, marginBottom: -10 }}>
                    {rowData[record.dataKey]}
                  </Typography.Link>
                );
              } else if (record.dataKey === 'incentive' || record.dataKey === 'occupancies') {
                return rowData[record.dataKey]?.map((inc: any) => (
                  <>
                    <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                      {inc}
                    </Typography.Title>
                    <br />
                  </>
                ));
              } else if (
                record.dataKey === 'accommodation_updated_on' ||
                record.dataKey === 'last_fees_&_care_updated_date' ||
                record.dataKey === 'request_date'
              ) {
                if (rowData[record.dataKey]) {
                  return (
                    <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                      {format(new Date(rowData[record.dataKey]), 'MM-dd-yyyy')}
                    </Typography.Title>
                  );
                } else {
                  return (
                    <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                      {rowData[record.dataKey]}
                    </Typography.Title>
                  );
                }
              } else if (record.dataKey === 'attachment') {
                if (rowData[record.dataKey]) {
                  return (
                    <Typography.Link
                      href={`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                      style={{ margin: 0, marginBottom: -10 }}
                    >
                      {`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                    </Typography.Link>
                  );
                } else {
                  return (
                    <Typography.Link
                      href={`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                      style={{ margin: 0, marginBottom: -10 }}
                    >
                      {`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                    </Typography.Link>
                  );
                }
              } else {
                return (
                  <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                    {rowData[record.dataKey]}
                  </Typography.Title>
                );
              }
            }
            return <span></span>;
          },
          width: 300,
        })),
      ];
      setTableColumns(table_columns);

      //======

      const comm_comp_list = [];
      if (cmm && cmm.length > 0) {
        const updated_cmm = cmm.map((item) => ({
          ...item,
          'last_fees_&_care_updated_date': item.last_fees_care_updated_date,
        }));
        comm_comp_list.push(...updated_cmm);
      }
      if (comp && comp.length > 0) {
        const updated_comp = comp.map((item) => ({
          ...item,
          'last_fees_&_care_updated_date': item.last_fees_care_updated_date,
        }));
        comm_comp_list.push(...updated_comp);
      }

      const grup__apartment = groupByApartmentId(comm_comp_list, 'id');

      const table_apartment_data: any[] = [];
      COMMUNITY_FIELDS.forEach((name, index) => {
        const newRow = {
          dataKey: name,
          tableKey: shortUUID.generate(),
          ...grup__apartment,
        };
        table_apartment_data.push(newRow);
      });

      // for fee/inc
      table_apartment_data.push({
        dataKey: 'Community Fees',
        tableKey: shortUUID.generate(),
      });

      const feeInc_by_living_type = groupByApartmentId(feeInc, 'LivingTypeName');

      if (Object.keys(feeInc_by_living_type).length > 0) {
        Object.keys(feeInc_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: shortUUID.generate(),
          });

          const type_data_arr = feeInc_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
            community_fee: el?.community_fee > 0 ? `$ ${el?.community_fee}` : '',
            pet_fee: el?.pet_fee > 0 ? `$ ${el?.pet_fee}` : '',
            second_person_fee: el?.second_person_fee > 0 ? `$ ${el?.second_person_fee}` : '',
            additional_fee: el?.additional_fee > 0 ? `$ ${el?.additional_fee}` : '',
          }));

          const iLGroupById = groupByApartmentId(updated_arr, 'CommunityId');

          COMMUNITY_FEES.forEach((name, index) => {
            const newRow = {
              dataKey: name,
              tableKey: shortUUID.generate(),
              ...iLGroupById,
            };
            table_apartment_data.push(newRow);
          });
        });
      }

      // for living types
      table_apartment_data.push({
        dataKey: 'Accommodation',
        tableKey: shortUUID.generate(),
      });
      const grp_by_living_type = groupByApartmentId(livinginfo, 'LivingTypeName');

      if (Object.keys(grp_by_living_type).length > 0) {
        Object.keys(grp_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: shortUUID.generate(),
          });

          const type_data_arr = grp_by_living_type[living_type];
          const IL_grp_by_aprtmenttype = groupByApartmentId(type_data_arr, 'ApartmentTypeName');
          if (Object.keys(IL_grp_by_aprtmenttype).length > 0) {
            Object.keys(IL_grp_by_aprtmenttype).forEach((aprtment_type) => {
              const apartment_list = IL_grp_by_aprtmenttype[aprtment_type];
              if (apartment_list && apartment_list?.length > 0) {
                const updated_list = apartment_list.map((el: any) => ({
                  ...el,
                  [`${aprtment_type}`]: el.base_rent > 0 ? `$ ${el.base_rent}` : '',
                  price_per_sq: el?.price_per_sq > 0 ? `$ ${el?.price_per_sq}` : '',
                }));
                const data_grp = groupByApartmentId(updated_list, 'CommunityId');
                table_apartment_data.push({
                  dataKey: aprtment_type,
                  tableKey: shortUUID.generate(),
                  ...data_grp,
                });
                ACCOMODATION.forEach((name, idx) => {
                  const newRow = {
                    dataKey: name,
                    tableKey: shortUUID.generate(),
                    ...data_grp,
                  };
                  table_apartment_data.push(newRow);
                });
              }
            });
          }
        });
      }

      // for care fees

      table_apartment_data.push({
        dataKey: 'Care Fees',
        tableKey: shortUUID.generate(),
      });
      const carefees_by_living_type = groupByApartmentId(carefees, 'LivingTypeName');

      if (Object.keys(carefees_by_living_type).length > 0) {
        Object.keys(carefees_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: shortUUID.generate(),
          });

          const type_data_arr = carefees_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
            fee_type: el?.FeeTypeName,
            level_1: el?.level_1 > 0 ? `$ ${el?.level_1}` : '',
            level_2: el?.level_2 > 0 ? `$ ${el?.level_2}` : '',
            level_3: el?.level_3 > 0 ? `$ ${el?.level_3}` : '',
            level_4: el?.level_4 > 0 ? `$ ${el?.level_4}` : '',
            level_5: el?.level_5 > 0 ? `$ ${el?.level_5}` : '',
            level_6: el?.level_6 > 0 ? `$ ${el?.level_6}` : '',
            level_7: el?.level_7 > 0 ? `$ ${el?.level_7}` : '',
            level_8: el?.level_8 > 0 ? `$ ${el?.level_8}` : '',
            medication_management_fee: el?.medication_management_fee > 0 ? `$ ${el?.medication_management_fee}` : '',
            average_care_cost: el?.average_care_cost > 0 ? `$ ${el?.average_care_cost}` : '',
          }));
          const iLGroupById = groupByApartmentId(updated_arr, 'CommunityId');

          CARE_FEES.forEach((name, idx) => {
            const newRow = {
              dataKey: name,
              tableKey: shortUUID.generate(),
              ...iLGroupById,
            };
            table_apartment_data.push(newRow);
          });
        });
      }

      // for shop notes

      table_apartment_data.push({
        dataKey: 'Shop Notes',
        tableKey: shortUUID.generate(),
      });
      const array: any = [];
      const SN_grp_by_id = groupByApartmentId(shopnotes, 'CommunityId');
      let max_length = 0;

      Object.keys(SN_grp_by_id).forEach((key: any) => {
        if (max_length < SN_grp_by_id[key].length) {
          max_length = SN_grp_by_id[key].length;
        }
      });
      for (let i = 0; i < max_length; i++) {
        const obj: any = {};
        Object.keys(SN_grp_by_id).forEach((key) => {
          if (SN_grp_by_id[key][i]) {
            obj[key] = [SN_grp_by_id[key][i]];
          }
        });
        array.push(obj);
      }
      //
      for (let i = 0; i < max_length; i++) {
        [`${i + 1}`, 'request_date', 'file_url', 'attachment', 'request_notes'].forEach((name, index) => {
          const newRow = {
            dataKey: name,
            tableKey: shortUUID.generate(),
            ...array[i],
          };
          table_apartment_data.push(newRow);
        });
      }

      //for incentives

      table_apartment_data.push({
        dataKey: 'Incentives',
        tableKey: shortUUID.generate(),
      });

      const combDateWiseFeeincentives = [...dateWiseFeeInc.community, ...dateWiseFeeInc.competitors];
      const date_wise_feeinc_by_living_type = groupByApartmentId(combDateWiseFeeincentives, 'Type');
      if (Object.keys(date_wise_feeinc_by_living_type).length > 0) {
        Object.keys(date_wise_feeinc_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: shortUUID.generate(),
          });
          const type_data_arr = date_wise_feeinc_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
            community_fee: el?.community_fee > 0 ? `$ ${el?.community_fee}` : '',
            pet_fee: el?.pet_fee > 0 ? `$ ${el?.pet_fee}` : '',
            second_person_fee: el?.second_person_fee > 0 ? `$ ${el?.second_person_fee}` : '',
            additional_fee: el?.additional_fee > 0 ? `$ ${el?.additional_fee}` : '',
          }));

          const iLGroupById = groupByApartmentId(updated_arr, 'apartment_id');
          Object.keys(iLGroupById).forEach((key) => {
            const array: string[] = [];
            let name = '';
            iLGroupById[key].forEach((item: any) => {
              if (item.incentive_value || item.incentive) {
                array.push(
                  `${item.incentive_value ? '$' + item.incentive_value : ''} ${
                    item.incentive_value && item.incentive ? ' - ' : ''
                  } ${item.incentive ? item.incentive : ''}`
                );
              }
              name = item.CommunityName;
            });
            iLGroupById[key] = [{ incentive: array, CommunityName: name }];
          });
          ['incentive'].forEach((name, index) => {
            const newRow = {
              dataKey: name,
              tableKey: shortUUID.generate(),
              ...iLGroupById,
            };
            table_apartment_data.push(newRow);
          });
        });
      }

      //for occupancies

      table_apartment_data.push({
        dataKey: 'Occupancies',
        tableKey: shortUUID.generate(),
      });
      const occupancies_by_living_type = groupByApartmentId(occupancies, 'living_type_name');

      if (Object.keys(occupancies_by_living_type).length > 0) {
        Object.keys(occupancies_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: shortUUID.generate(),
          });
          const type_data_arr = occupancies_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
          }));

          //

          const iLGroupById = groupByApartmentId(updated_arr, 'apartment_id');
          Object.keys(iLGroupById).forEach((key) => {
            const array: string[] = [];
            let name = '';
            iLGroupById[key].forEach((item: any) => {
              array.push(item.occupancies_status);
              name = item.community_name;
            });
            iLGroupById[key] = [{ occupancies: array, CommunityName: name }];
          });
          ['occupancies'].forEach((name, index) => {
            const newRow = {
              dataKey: name,
              tableKey: shortUUID.generate(),
              ...iLGroupById,
            };
            table_apartment_data.push(newRow);
          });
        });
      }
      setDataSource(table_apartment_data);
    },
  });

  return (
    <CMASummaryContext.Provider
      value={{
        communityName: communityInfo?.comunity_name,
        currentUser: {
          name: currentUser?.name || '',
          fullName: currentUser?.FullName || '',
          email: currentUser?.companyPrimaryEmail ?? currentUser?.email ?? '',
        },
        dataLoading: isLoading,
        tableColumns: tableColumns,
        tableDataHeaders: dataHeaders,
        tableDataSource: dataSource,
        openModal: openModal,
        setOpenModal: setOpenModal,
        showMessage: showMessage,
      }}
    >
      {contextHolder}
      <div className={classes.table_wrapper}>
        <CMASummaryActions />
        <CMASummaryEmailModal />
        <CMASummaryPDFModal />
        <Table
          pagination={false}
          columns={tableColumns}
          dataSource={dataSource}
          bordered={true}
          loading={isLoading}
          showHeader={true}
          scroll={{ x: '1300px', y: '57vh' }}
          rowKey="tableKey"
        />
      </div>
    </CMASummaryContext.Provider>
  );
};

export default CMASummary;
