import React from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import GraphWidgetContentGraph from './GraphWidgetContentGraph';
import GraphWidgetContentTable from './GraphWidgetContentTable';
import { fetchData } from '../../../apis/DataAPI';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import { DATA_DISPLAY_TYPE } from '../Widget/constants';
import NoDataInfo from '../Widget/NoDataContent';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

const GraphWidgetContent = () => {
  const { communityId } = useCommunityViewContext();

  const { type, filters, rangeType, displayType } = useWidgetContext();
  const { time, competitors, careTypes, roomTypes, careLevels, reviewSources, reviewCategories, swotCategories } =
    filters;

  const { data } = useQuery({
    queryKey: [type, communityId, filters, rangeType],
    queryFn: () =>
      fetchData(communityId, type, rangeType, {
        time,
        competitors,
        care_types: careTypes,
        room_types: roomTypes,
        care_levels: careLevels,
        review_sources: reviewSources,
        review_categories: reviewCategories,
        swot_categories: swotCategories,
      }),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  return (
    <Container>
      {data?.length > 0 && displayType === DATA_DISPLAY_TYPE.GRAPH && <GraphWidgetContentGraph data={data} />}
      {data?.length > 0 && displayType === DATA_DISPLAY_TYPE.TABLE && <GraphWidgetContentTable data={data} />}
      {data?.length === 0 && <NoDataInfo>No Data Available</NoDataInfo>}
    </Container>
  );
};

export default GraphWidgetContent;
