import React from 'react';

import SelectAdvancedFilterDate from 'components/lib/SelectAdvancedFilterDate';
import { getTimestamp } from 'helpers/dates';

import { SHOP_REQUEST_DUE_DATE_RANGES } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const ShopRequestsDueDateFilter = () => {
  const { filters, setFilters } = useShopRequestsDataContext();
  return (
    <SelectAdvancedFilterDate
      selected={filters.dueDate ? new Date(filters.dueDate) : new Date()}
      minDate={new Date()}
      onChange={(date: any) => {
        // in case of large timezone difference, the date will never be shifted to a day earlier or
        // after, if we set the hours to 12
        date.setHours(12);
        const timestamp = getTimestamp(date);
        setFilters({
          ...filters,
          dueDate: timestamp,
          dueDateRange: SHOP_REQUEST_DUE_DATE_RANGES.ALL_REQUESTS,
        });
      }}
    />
  );
};

export default ShopRequestsDueDateFilter;
