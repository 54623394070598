import React from 'react';

import styled from 'styled-components';

import Input from 'components/lib/Input';

import { useShopRequestsDataContext } from './ShopRequestsDataContext';

const StyledInput = styled(Input)`
  width: 500px;
`;

const ShopRequestSearch = () => {
  const { setSearch, filters } = useShopRequestsDataContext();
  return (
    <StyledInput
      value={filters.search}
      onChange={(event: any) => setSearch(event.target.value)}
      placeholder="Search by request, company, community or assignee name"
    />
  );
};

export default ShopRequestSearch;
