type CommunitiesRatingsPayloadType = {
  id: number;
  ratings_average: number;
  ratings_count: number;
};

const getAverageAndSum = (communities: CommunitiesRatingsPayloadType[]) => {
  const totalRatings = communities.reduce((sum, community) => sum + community.ratings_average, 0);
  const totalRatingsCount = communities.reduce((sum, community) => sum + community.ratings_count, 0);

  const averageRating = totalRatings / communities.length;

  return { averageRating, totalRatingsCount };
};

const getCommunityCompetitorsRatings = (
  communityCompetitors: any[],
  competitorsRatings?: CommunitiesRatingsPayloadType[]
) => {
  if (!competitorsRatings || !communityCompetitors?.length) {
    return {
      competitors: undefined,
      competitorsRating: undefined,
      competitorsRatingsCount: undefined,
    };
  }

  const competitors = communityCompetitors?.map((c: any) => {
    const matchingCompetitor = competitorsRatings.find((competitorRating) => competitorRating.id === c.id);
    return {
      ...c,
      rating: matchingCompetitor?.ratings_average,
      ratingsCount: matchingCompetitor?.ratings_count,
    };
  });

  const { averageRating: competitorsRating, totalRatingsCount: competitorsRatingsCount } =
    getAverageAndSum(competitorsRatings);

  return {
    competitors,
    competitorsRating,
    competitorsRatingsCount,
  };
};

export const addRatingsToCommunities = (
  communities: any[],
  communitiesRatings?: CommunitiesRatingsPayloadType[],
  competitorsRatings?: CommunitiesRatingsPayloadType[]
) => {
  if (!communitiesRatings) {
    return;
  }

  return communities.map((community) => {
    const matchingCommunity = communitiesRatings.find((c) => c.id === community.id);
    const { competitors, competitorsRating, competitorsRatingsCount } = getCommunityCompetitorsRatings(
      community.competitors,
      competitorsRatings
    );

    return {
      ...community,
      rating: matchingCommunity?.ratings_average,
      ratingsCount: matchingCommunity?.ratings_count,
      competitors,
      competitorsRating,
      competitorsRatingsCount,
    };
  });
};

export const generateMgmtCompanyRatings = (communities: any[], competitors: any[]) => {
  let community = 0;
  let total = 0;
  let market = 0;

  if (communities.length > 0) {
    const communityRatings = communities.filter((community) => community.rating !== null && community.rating !== 0);
    community = communityRatings.reduce((acc, curr) => acc + curr.rating, 0) / communityRatings.length;
    total = communityRatings.reduce((acc, curr) => acc + curr.ratingsCount, 0);
  }

  if (competitors.length > 0) {
    const marketRatings = competitors.filter((competitor) => competitor.rating !== null && competitor.rating !== 0);
    market = marketRatings.reduce((acc, curr) => acc + curr.rating, 0) / marketRatings.length;
  }

  return {
    averageRating: {
      community,
      total,
      market,
    },
  };
};
