import axios from '../config/axiosPrivate';

export const getViews = async (communityId: number, params: any) => {
  const url = `/views/${communityId}/list`;

  const response = await axios.get(url, { params });
  return response.data;
};

export const getView = async (viewId: number) => {
  const url = `/views/${viewId}`;

  const response = await axios.get(url);
  return response.data;
};

export const createView = async (payload: any) => {
  const url = `/views/`;

  const response = await axios.post(url, payload);
  return response.data;
};

export const duplicateView = async (viewId: number, payload: any) => {
  const url = `/views/${viewId}/duplicate`;

  const response = await axios.put(url, payload);
  return response.data;
};

export const updateView = async (viewId: number, payload: any) => {
  const url = `/views/${viewId}`;

  const response = await axios.put(url, payload);
  return response.data;
};

export const updatePartialView = async (viewId: number, payload: any) => {
  const url = `/views/${viewId}`;

  const response = await axios.patch(url, payload);
  return response.data;
};

export const deleteView = async (viewId: number) => {
  const url = `/views/${viewId}`;

  const response = await axios.delete(url);
  return response.data;
};

export const linkView = async (payload: any) => {
  const url = `/views/link`;

  const response = await axios.post(url, payload);
  return response.data;
};

export const shareView = async (communityId: number, payload: any) => {
  const url = `/views/${communityId}/share`;

  const response = await axios.post(url, payload);
  return response.data;
};

export const getPublication = async (hash: string | undefined) => {
  const url = `/views/publication/${hash}`;

  const response = await axios.get(url);
  return response.data;
};
