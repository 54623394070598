import React from 'react';
import { Link } from 'react-router-dom';

import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';

const statuses = [
  { label: 'Requested', color: '#dc3545' },
  { label: 'In Progress', color: '#17a2b8' },
  { label: 'Ready for Review', color: '#007bff' },
  { label: 'Solved', color: '#28a745' },
  { label: 'Satisfied', color: '#28a745' },
];

interface DataType {
  id: number;
  company: number;
  CompanyName: string;
  CommunityName: string;
  apartment: number;
  apartment_name: string;
  user_id: number;
  user_name: string;
  requested_date: string;
  comments: string | null;
  status: string;
  email_from: string | null;
  email_to: string | null;
  created_at: string | null;
  created_by: string | null;
  updated_at: string | null;
  updated_by: string | null;
  request_deadline: string | null;
  unique_id: string | null;
  token: string | null;
  complete_status: string | null;
}

const typeList: any = {
  care_type_independent: 'IL',
  care_type_assisted: 'AL',
  care_type_memory: 'MC',
  care_type_other: 'OT',
};

const getTypes = (val: any) => {
  const results: string[] = [];
  Object.keys(val).forEach((key) => {
    if (val[key] === 'Yes') {
      results.push(typeList[key]);
    }
  });
  return results;
};

interface Props {
  data: any[];
  loading?: boolean;
  handleEdit: (data: any) => void;
  handleDelete: (data: any) => void;
  handleUpdateStatus: (data: any) => void;
}

const MysteryShopTable: React.FC<Props> = ({ data, loading = false, handleEdit, handleUpdateStatus, handleDelete }) => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Serial No',
      dataIndex: 'sr_no',
      key: 'sr_no',
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            User Name
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: '12x' }}>Company Name</Typography.Paragraph>
        </div>
      ),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            {row.user_name}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: '12x' }}>
            {''}
            {row.CompanyName}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Community Name
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: '12x' }}>Comments</Typography.Paragraph>
        </div>
      ),
      dataIndex: 'apartment_name',
      key: 'apartment_name',
      width: 350,
      render: (_, row) => (
        <div>
          <Typography.Title level={4} style={{ margin: 0, color: '#007bff', fontSize: '16px' }}>
            <Link to={`/view-apartments?id=${row.apartment}`}>
              {row.CommunityName}
              {/* {getCommunityName(row.apartment)} */}
            </Link>
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: '12x' }}> {row.comments}</Typography.Paragraph>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Competitors
          </Typography.Title>
        </div>
      ),
      dataIndex: 'Details',
      key: 'Details',
      align: 'left',
      width: 250,
      render: (value, row) => (
        <ol
          style={{
            paddingLeft: '10px',
            marginTop: '0px',
          }}
        >
          {value &&
            value?.length > 0 &&
            value?.map((val: any, idx: number) => (
              <li key={idx}>
                <Typography.Title level={5} style={{ margin: 0, fontSize: 14 }}>
                  {val?.competitor_name} <span style={{ color: 'blue' }}>( {getTypes(val).join(', ')})</span>
                </Typography.Title>
              </li>
            ))}
        </ol>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '14px' }}>
            Priority
          </Typography.Title>
        </div>
      ),
      dataIndex: 'request_deadline',
      key: 'request_deadline',
      render: (_, row) => (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '14px' }}>
            {row.request_deadline}
          </Typography.Title>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Requested <br /> Date
          </Typography.Title>
        </div>
      ),
      dataIndex: 'requested_date',
      key: 'requested_date',
      render: (_, row) => (
        <div>
          <Typography.Title level={5} style={{ margin: 0, fontSize: 14 }}>
            {row.requested_date && format(new Date(row.requested_date), 'MM-dd-yyyy')}
          </Typography.Title>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Created By
          </Typography.Title>
        </div>
      ),
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, row) => (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: 14 }}>
            {row.created_by}
          </Typography.Title>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Updated <br /> Date
          </Typography.Title>
        </div>
      ),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_, row) => (
        <div style={{ width: 100 }}>
          <Typography.Title level={5} style={{ margin: 0, fontSize: 14 }}>
            {row.updated_at && format(new Date(row.updated_at), 'MM-d-yyyy')}
          </Typography.Title>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Status
          </Typography.Title>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <div>
          <Typography.Title
            level={5}
            style={{
              margin: 0,
              color: statuses[value - 1]?.color,
              fontSize: 14,
              whiteSpace: 'nowrap',
            }}
          >
            {statuses[value - 1]?.label}
          </Typography.Title>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Actions
          </Typography.Title>
        </div>
      ),
      key: 'action',
      render: (_, record) => (
        <>
          <Button size="small" style={{ marginBottom: 5 }} onClick={() => handleUpdateStatus(record)}>
            Change Status
          </Button>

          <Space size="middle" direction="horizontal">
            <Button size="small" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
            <Popconfirm
              title="Delete Shop Request"
              description="Are you sure to delete the shop request?"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" icon={<DeleteFilled />} danger />
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  return <Table columns={columns} dataSource={data} scroll={{ x: 991 }} bordered loading={loading} rowKey="id" />;
};

export default MysteryShopTable;
