import React from 'react';

import { MailOutlined } from '@ant-design/icons/lib';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import { useCMASummaryContext } from './CMASummaryContext';
import { MODAL_TYPES } from './constants';
import { exportToExcel } from './utils';

const Container = styled.div`
  display: flex;
  margin-bottom: 10px;
  gap: 5px;
`;

const CMASummaryActions = () => {
  const { setOpenModal, tableDataHeaders, tableDataSource, currentUser, communityName } = useCMASummaryContext();

  return (
    <Container>
      <Tooltip title="Export to Excel Sheet" placement="topRight">
        <Button
          onClick={() => exportToExcel(tableDataSource, tableDataHeaders, currentUser.name, communityName)}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
              fill="#FFF"
              strokeMiterlimit="10"
              strokeWidth="2"
              style={{ width: '100%', height: 25 }}
            >
              <path
                stroke="#979593"
                d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z"
              />
              <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18" />
              <path
                fill="#C8C6C4"
                d="M51 61H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 60.5523 51.5523 61 51 61zM51 55H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 54.5523 51.5523 55 51 55zM51 49H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 48.5523 51.5523 49 51 49zM51 43H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 42.5523 51.5523 43 51 43zM51 67H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 66.5523 51.5523 67 51 67zM79 61H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 67H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67zM79 55H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM65 61H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 60.5523 65.5523 61 65 61zM65 67H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 66.5523 65.5523 67 65 67zM65 55H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 54.5523 65.5523 55 65 55zM65 49H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 48.5523 65.5523 49 65 49zM65 43H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 42.5523 65.5523 43 65 43z"
              />
              <path
                fill="#107C41"
                d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z"
              />
              <path d="M16.9492,66l7.8848-12.0337L17.6123,42h5.8115l3.9424,7.6486c0.3623,0.7252,0.6113,1.2668,0.7471,1.6236 h0.0508c0.2617-0.58,0.5332-1.1436,0.8164-1.69L33.1943,42h5.335l-7.4082,11.9L38.7168,66H33.041l-4.5537-8.4017 c-0.1924-0.3116-0.374-0.6858-0.5439-1.1215H27.876c-0.0791,0.2684-0.2549,0.631-0.5264,1.0878L22.6592,66H16.9492z" />
            </svg>
          }
        />
      </Tooltip>
      <Tooltip title="Export to PDF" placement="topRight">
        <Button
          icon={
            <svg
              enableBackground="new 0 0 334.371 380.563"
              version="1.1"
              viewBox="0 0 14 16"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: '100%', height: 25 }}
            >
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                <polygon
                  points="51.791 356.65 51.791 23.99 204.5 23.99 282.65 102.07 282.65 356.65"
                  fill="#fff"
                  strokeWidth="212.65"
                />
                <path
                  d="m201.19 31.99 73.46 73.393v243.26h-214.86v-316.66h141.4m6.623-16h-164.02v348.66h246.85v-265.9z"
                  strokeWidth="21.791"
                />
              </g>
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                <polygon
                  points="282.65 356.65 51.791 356.65 51.791 23.99 204.5 23.99 206.31 25.8 206.31 100.33 280.9 100.33 282.65 102.07"
                  fill="#fff"
                  strokeWidth="212.65"
                />
                <path
                  d="m198.31 31.99v76.337h76.337v240.32h-214.86v-316.66h138.52m9.5-16h-164.02v348.66h246.85v-265.9l-6.43-6.424h-69.907v-69.842z"
                  strokeWidth="21.791"
                />
              </g>
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" strokeWidth="21.791">
                <polygon points="258.31 87.75 219.64 87.75 219.64 48.667 258.31 86.38" />
                <path d="m227.64 67.646 12.41 12.104h-12.41v-12.104m-5.002-27.229h-10.998v55.333h54.666v-12.742z" />
              </g>
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" fill="#ed1c24" strokeWidth="212.65">
                <polygon points="311.89 284.49 22.544 284.49 22.544 167.68 37.291 152.94 37.291 171.49 297.15 171.49 297.15 152.94 311.89 167.68" />
                <path d="m303.65 168.63 1.747 1.747v107.62h-276.35v-107.62l1.747-1.747v9.362h272.85v-9.362m-12.999-31.385v27.747h-246.86v-27.747l-27.747 27.747v126h302.35v-126z" />
              </g>
              <rect x="1.7219" y="7.9544" width="10.684" height="4.0307" fill="none" />
              <g transform="matrix(.04589 0 0 .04589 1.7219 11.733)" fill="#fff" strokeWidth="21.791">
                <path d="m9.216 0v-83.2h30.464q6.784 0 12.928 1.408 6.144 1.28 10.752 4.608 4.608 3.2 7.296 8.576 2.816 5.248 2.816 13.056 0 7.68-2.816 13.184-2.688 5.504-7.296 9.088-4.608 3.456-10.624 5.248-6.016 1.664-12.544 1.664h-8.96v26.368zm22.016-43.776h7.936q6.528 0 9.6-3.072 3.2-3.072 3.2-8.704t-3.456-7.936-9.856-2.304h-7.424z" />
                <path d="m87.04 0v-83.2h24.576q9.472 0 17.28 2.304 7.936 2.304 13.568 7.296t8.704 12.8q3.2 7.808 3.2 18.816t-3.072 18.944-8.704 13.056q-5.504 5.12-13.184 7.552-7.552 2.432-16.512 2.432zm22.016-17.664h1.28q4.48 0 8.448-1.024 3.968-1.152 6.784-3.84 2.944-2.688 4.608-7.424t1.664-12.032-1.664-11.904-4.608-7.168q-2.816-2.56-6.784-3.456-3.968-1.024-8.448-1.024h-1.28z" />
                <path d="m169.22 0v-83.2h54.272v18.432h-32.256v15.872h27.648v18.432h-27.648v30.464z" />
              </g>
            </svg>
          }
          onClick={() => setOpenModal(MODAL_TYPES.PDF)}
        />
      </Tooltip>
      <Tooltip title="Send via Email" placement="topRight">
        <Button icon={<MailOutlined />} onClick={() => setOpenModal(MODAL_TYPES.EMAIL)} />
      </Tooltip>
    </Container>
  );
};

export default CMASummaryActions;
