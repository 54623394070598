import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { getReviewsMetrics } from 'apis/ReviewsAPI';
import { addRatingsToCommunities } from 'utils/data/reviews';

import OverviewCommunities from './OverviewCommunities';
import OverviewCompetitors from './OverviewCompetitors';
import { OverviewContext } from './OverviewContext';
import OverviewHeader from './OverviewHeader';
import OverviewMap from './OverviewMap';
import OverviewStats from './OverviewStats';
import axios from '../../config/axiosPrivate';
import { useAppSelector } from '../../store/hook';
import { processFullHomeCommunities } from '../../utils/data/communities';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  background-color: var(--background-gray);
  gap: 20px;
`;

const OverviewManagementCompany = () => {
  const { managementCompanyId } = useParams();
  const { selectedClient, selectedCompany } = useAppSelector((state) => state.global);

  const [communities, setCommunities] = useState<any>([]);
  const [competitors, setCompetitors] = useState<any>([]);
  // Perform fetching for current user selection (management company, community)
  const { isSuccess: communitiesLoaded } = useQuery({
    queryKey: [selectedClient, selectedCompany, managementCompanyId],
    queryFn: () =>
      axios.post('/communities/Get_FullHomeCommunities/', {
        client_id: selectedClient?.id,
        company_list: selectedCompany ? [selectedCompany?.id] : [],
        management_company_list: managementCompanyId ? [parseInt(managementCompanyId)] : [],
        comm_list: [],
        caretype_list: [],
      }),
    onSuccess: (response) => {
      const data = response.data?.payload;

      const { updatedCommunities, updatedCompetitor } = processFullHomeCommunities(data);

      setCommunities(updatedCommunities);
      setCompetitors(updatedCompetitor);
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: [':managementCompanyMetrics', managementCompanyId],
    queryFn: () =>
      getReviewsMetrics({
        companies: selectedCompany ? [selectedCompany.id] : [],
        management_companies: managementCompanyId ? [managementCompanyId] : undefined,
      }),
    onSuccess: (data) => {
      const preparedCommunities = addRatingsToCommunities(communities, data.communities, data.competitors);
      const preparedCompetitors = addRatingsToCommunities(competitors, data.competitors);
      setCommunities(preparedCommunities);
      setCompetitors(preparedCompetitors);
    },
    refetchOnWindowFocus: false,
    enabled: !!communities.length && communitiesLoaded,
  });

  return (
    <OverviewContext.Provider
      value={{
        communities: communities,
        competitors: competitors,
      }}
    >
      <Container>
        <OverviewHeader />
        <Content>
          <OverviewMap />
          <OverviewStats />
          <OverviewCommunities />
          <OverviewCompetitors />
        </Content>
      </Container>
    </OverviewContext.Provider>
  );
};

export default OverviewManagementCompany;
