import React, { useCallback, useEffect, useState } from 'react';

import { Space } from 'antd';
import styled from 'styled-components';

import DropdownSearch from './DropdownSearch';
import { useSelectorContext } from './SelectorContext';
import { CompanyDataType } from './types';
import { getCompanies, getCompany } from '../../apis/UserAPI';
import useDataList from '../../utils/hooks/useDataList';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectorCompany = () => {
  const [options, setOptions] = useState<any>([]);
  const { items, filters, setItems, setSearch } = useDataList();

  const { client, company, setClient, setCompany, setManagementCompany } = useSelectorContext();

  const fetchCompanies = useCallback(
    async ({ search }: any) => {
      try {
        const { results, count } = await getCompanies({ search });
        setItems({ results, count });
      } catch (e) {
        console.error(e);
      }
    },
    [setItems]
  );

  const fetchCompany = useCallback(
    async (clientId: number, companyId: number) => {
      try {
        const response = await getCompany(clientId, companyId);
        setCompany(response);
        setItems({ results: [response], count: 1 });
      } catch (e) {
        console.error(e);
      }
    },
    [setItems]
  );

  useEffect(() => {
    if (!client) {
      fetchCompanies(filters);
    } else if (client.company) {
      fetchCompany(client.id, client.company);
    }
  }, [fetchCompanies, filters, client]);

  useEffect(() => {
    setCompany(undefined);
  }, [client]);

  useEffect(() => {
    const dropdownOptions = (items || []).map((item: any) => ({
      value: item.id,
      label: item.name,
      email: item.primary_email,
    }));

    setOptions(dropdownOptions);
  }, [items]);

  const handleSelect = (selectedCompanyId: number) => {
    const selectedClient = (items || []).find((item: CompanyDataType) => item.id === selectedCompanyId);
    setClient(undefined);
    setCompany(selectedClient);
    setManagementCompany(undefined);
  };

  const handleClear = () => {
    setCompany(undefined);
    setManagementCompany(undefined);
    setSearch('');
  };

  return (
    <DropdownSearch
      value={company?.id}
      options={options}
      disabled={!!client}
      placeholder={'Search Company' as const}
      onSearch={setSearch}
      onSelect={handleSelect}
      onClear={handleClear}
      optionRender={(option: any) => {
        return (
          <Space>
            <InfoContainer>
              <span>
                <b>{option.label}</b>
              </span>
              <span>{option.data.email}</span>
            </InfoContainer>
          </Space>
        );
      }}
    />
  );
};

export default SelectorCompany;
