import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { isMysteryShopAdmin, isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';
import { useShopRequestsContext } from '../ShopRequestsContext';
import ShopRequestStyledSelect from '../ShopRequestStyledSelect';
import ShopRequestUserAvatar from '../ShopRequestUserAvatar';
import ShopRequestUserInfo from '../ShopRequestUserInfo';

const Container = styled.div`
  display: flex;
  flef-flow: row;
  align-items: center;
`;

const Label = styled(Typography.Text)`
  margin-right: 10px;
  font-weight: 900;
`;

const StyledSelect = styled(ShopRequestStyledSelect)`
  &&&.ant-select {
    margin: 0 !important;
  }
`;

const EditShopRequestModalShopper = () => {
  const { shopRequest, setShopRequest } = useEditShopRequestModalContext();
  const { staffUsers } = useShopRequestsContext();
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);
  const isShopRequestSalesperson = currentUser && isMysteryShopShopper(currentUser);

  return (
    <Container>
      <Label>Shopper</Label>
      {isShopRequestAdmin && (
        <StyledSelect
          options={staffUsers?.map((user) => ({ value: user.userId, label: user.name }))}
          placeholder="Select a shopper (Optional)"
          value={shopRequest?.shopper?.userId}
          onChange={(value: number) => {
            const shopper = staffUsers.find((user) => user.userId === value)!;
            if (shopRequest) {
              setShopRequest({ ...shopRequest, shopper });
            }
          }}
          labelRender={({ value, label }) => (
            <Container key={value}>
              <ShopRequestUserAvatar label={label} />
              {label}
            </Container>
          )}
          style={{ marginBottom: '10px' }}
        />
      )}
      {isShopRequestSalesperson && shopRequest && <ShopRequestUserInfo shopRequest={shopRequest} />}
    </Container>
  );
};

export default EditShopRequestModalShopper;
