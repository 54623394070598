import React from 'react';

import { Typography } from 'antd';

import { COMMUNITY_LABEL_COLOR } from './constants';

type Props = {
  color: number;
  children: string;
};

const CommunitySettingLabel = ({ color, children }: Props) => {
  let colorValue;

  switch (color) {
    case COMMUNITY_LABEL_COLOR.POSITIVE:
      colorValue = 'var(--positive-green)';
      break;
    case COMMUNITY_LABEL_COLOR.NEUTRAL:
    default:
      colorValue = 'var(--text-secondary)';
      break;
  }

  return <Typography.Text style={{ color: colorValue }}>{children}</Typography.Text>;
};

export default CommunitySettingLabel;
