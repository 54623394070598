import React from 'react';

import styled from 'styled-components';

import { SWOT_CATEGORIES } from 'common/Widgets/constants';

const StyledContainer = styled.div`
  width: 300px;
  height: 5px;
  border-radius: 5px;
  background-color: #eaeaeb;
  display: flex;
  flex-flow: row;
`;

const ProgressContainer = styled.div`
  height: 5px;
  background-image: linear-gradient(to right, #275aff, #27b4ff);
  border-radius: 5px;
`;

type Props = {
  step: number;
};

const ProgressBar = ({ step }: Props) => {
  return (
    <StyledContainer>
      <ProgressContainer style={{ width: `${(step / SWOT_CATEGORIES.length) * 100}%` }} />
    </StyledContainer>
  );
};

export default ProgressBar;
