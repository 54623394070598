import { DEFAULT_FILTERS } from './constants';

export const transformFilterInbound = (baseFilter: any) => {
  if (!baseFilter) return DEFAULT_FILTERS;

  const {
    id,
    time,
    competitors,
    care_types,
    room_types,
    care_levels,
    review_sources,
    review_categories,
    swot_categories,
  } = baseFilter;

  return {
    id: id,
    time: time,
    competitors: competitors,
    careTypes: care_types,
    roomTypes: room_types,
    careLevels: care_levels,
    reviewSources: review_sources,
    reviewCategories: review_categories,
    swotCategories: swot_categories,
  };
};

export const transformFilterOutbound = (baseFilter: any) => {
  const { time, competitors, careTypes, roomTypes, careLevels, reviewSources, reviewCategories, swotCategories } =
    baseFilter;

  return {
    time: time,
    competitors: competitors,
    care_types: careTypes,
    room_types: roomTypes,
    care_levels: careLevels,
    review_sources: reviewSources,
    review_categories: reviewCategories,
    swot_categories: swotCategories,
  };
};

export const equalFilters = (initialFilter: any, currentFilter: any) => {
  if (!initialFilter || !currentFilter) return false;

  const { care_types, room_types, care_levels, review_sources, review_categories, swot_categories } = initialFilter;
  const { careTypes, roomTypes, careLevels, reviewSources, reviewCategories, swotCategories } = currentFilter;

  return (
    initialFilter.time == currentFilter.time &&
    initialFilter.competitors == currentFilter.competitors &&
    care_types == careTypes &&
    room_types == roomTypes &&
    care_levels == careLevels &&
    review_sources == reviewSources &&
    review_categories == reviewCategories &&
    swot_categories == swotCategories
  );
};

export const getWidgetFilter = (isDefaultView: boolean, globalFilters: any, itemFilters: any) => {
  if (isDefaultView) {
    return globalFilters;
  }

  const filters = transformFilterInbound(itemFilters);

  return Object.assign({}, filters, {
    time: globalFilters.time,
    competitors: globalFilters.competitors,
  });
};
