import React from 'react';

import CardAverageContent from './CardAverageContent';
import CardContainer from './CardContainer';
import CardHeader from './CardHeader';

interface Props {
  title: string;
  subtitle: string;
  value?: number;
  market_value?: number;
  footerLabel: string;
}

const CardAverage = ({ title, subtitle, footerLabel, value = 0, market_value = 0 }: Props) => (
  <CardContainer>
    <CardHeader title={title} subtitle={subtitle} />
    <CardAverageContent value={value} marketValue={market_value} footerLabel={footerLabel} />
  </CardContainer>
);

export default CardAverage;
