import React from 'react';

import styled from 'styled-components';

import IncentivesCreateModal from '../create/IncentivesCreateModal';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const Container = styled.div`
  display: flex;
`;

const IncentivesActions = () => {
  const { resetFilters } = useIncentivesDataContext();

  const handleClose = () => {
    resetFilters();
  };

  return (
    <Container>
      <IncentivesCreateModal onClose={handleClose} />
    </Container>
  );
};

export default IncentivesActions;
