import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import CheckboxComponent from 'components/lib/Checkbox';

import { useSettingsContext } from './SettingsContext';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  padding: 20px 0 14px 15px;
  align-items: center;
`;

const CommunitiesListHeader = () => {
  const { isMultipleSelection, allCommunitiesSelected, toggleAllCommunities } = useSettingsContext();

  let checkbox;
  if (isMultipleSelection) {
    checkbox = (
      <CheckboxComponent
        checked={allCommunitiesSelected}
        onChange={toggleAllCommunities}
        style={{ marginRight: '15px' }}
      />
    );
  }

  return (
    <Container>
      {checkbox}
      <Typography.Text
        style={{
          fontSize: '12px',
          fontWeight: 900,
          letterSpacing: '0.5px',
          color: '#222',
        }}
      >
        COMMUNITY
      </Typography.Text>
    </Container>
  );
};

export default CommunitiesListHeader;
