import React from 'react';

import CardContainer from './CardContainer';
import CardHeader from './CardHeader';
import CardRatingContent from './CardRatingContent';

interface Props {
  title: string;
  subtitle: string;
  value?: number;
  total?: number;
  market_value?: number;
}

const CardRating = ({ title, subtitle, value = 0, total = 0, market_value = 0 }: Props) => (
  <CardContainer>
    <CardHeader title={title} subtitle={subtitle} />
    <CardRatingContent
      value={value}
      total={total}
      marketValue={market_value}
      footerLabel="Competitor avg."
      showTotals
    />
  </CardContainer>
);

export default CardRating;
