import React from 'react';

import styled from 'styled-components';

import FeesFormOnboardingCareFees from './FeesFormOnboardingCareFees';
import FeesFormOnboardingCommunityFees from './FeesFormOnboardingCommunityFees';
import StepNavigationBottom from '../common/StepNavigationBottom';

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const FeesFormOnboarding = ({ current, setCurrent }: Props) => (
  <Container>
    <FeesFormOnboardingCommunityFees />
    <FeesFormOnboardingCareFees />
    <StepNavigationBottom current={current} setCurrent={setCurrent} />
  </Container>
);

export default FeesFormOnboarding;
