import React from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons/lib';
import { Table } from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';

import UserOnboardingActions from './UserOnboardingActions';
import { useUserOnboardingContext } from './UserOnboardingContext';
import { MEDIA_BREAK_POINTS } from '../../constants';
import { PAGINATION } from '../../utils/hooks/useDataList';
import { ClientDataType } from '../Selector/types';

const Container = styled.div``;

const UserOnboardingTable = () => {
  const { clients, count, loading, setPage } = useUserOnboardingContext();

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Full Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Level',
      dataIndex: 'level',
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
    },
    {
      title: 'Management Company',
      dataIndex: 'management_company_name',
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 70,
      align: 'center' as const,
      render: (value: number) => {
        if (value) {
          return <CheckCircleOutlined style={{ color: 'green' }} />;
        }
        return <CloseCircleOutlined style={{ color: 'red' }} />;
      },
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login',
      width: 160,
      render: (value: string) => {
        return <span>{value && format(new Date(value), 'MMM dd, yyyy hh:mm a')}</span>;
      },
    },
    {
      title: 'Last Invitation Sent',
      dataIndex: 'last_invitation_sent',
      width: 160,
      render: (value: string) => {
        return <span>{value && format(new Date(value), 'MMM dd, yyyy hh:mm a')}</span>;
      },
    },
    {
      title: 'Actions',
      width: 120,
      align: 'center' as const,
      render: (_: any, record: ClientDataType) => {
        return <UserOnboardingActions user={record} />;
      },
    },
  ];

  return (
    <Container>
      <Table
        bordered
        pagination={{
          total: count,
          showSizeChanger: false,
          pageSize: PAGINATION.PAGE_SIZE,
          hideOnSinglePage: true,
          onChange: (page) => setPage(page),
        }}
        size={'small'}
        loading={loading}
        scroll={{ x: MEDIA_BREAK_POINTS.MOBILE, y: 600 }}
        dataSource={clients}
        rowKey="email"
        tableLayout={'fixed'}
        columns={columns}
      />
    </Container>
  );
};

export default UserOnboardingTable;
