import React, { useState } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { getShopRequestStats } from 'apis/ShopRequestAPI';
import SelectAdvancedFilterRadio from 'components/lib/SelectAdvancedFilterRadio';

import ShopRequestsMetricsLabels from './ShopRequestsMetricsLabels';
import { SHOP_REQUEST_STATS } from '../constants';

const Container = styled.div`
  margin: 12px 0 10px 30px;
  display: flex;
  flex-flow: row;
`;

const Metrics = styled.div`
  width: 60%;
  display: flex;
  flex-flow: column;
  margin-left: 20px;
`;

const MetricsBar = styled.div`
  height: 2px;
  width: 100%;
  background-color: rgba(95, 95, 255, 0.22);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  position: relative;
`;

const WhiteCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 54px;
  background-color: white;
`;

const ShopRequestsMetrics = () => {
  const [selectedRange, setSelectedRange] = useState(SHOP_REQUEST_STATS[0].value);

  const { data: stats } = useQuery({
    queryKey: ['shopRequestStats', selectedRange],
    queryFn: () => getShopRequestStats({ time_range: selectedRange }),
    refetchOnWindowFocus: false,
  });

  return (
    <Container>
      <SelectAdvancedFilterRadio
        options={SHOP_REQUEST_STATS}
        defaultValue={selectedRange}
        showSemicolon={false}
        onSelect={(range: number) => setSelectedRange(range)}
      />
      <Metrics>
        <MetricsBar>
          <WhiteCover />
        </MetricsBar>
        <ShopRequestsMetricsLabels stats={stats} />
      </Metrics>
    </Container>
  );
};

export default ShopRequestsMetrics;
