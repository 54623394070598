import axios from '../config/axiosPrivate';

interface FilterType {
  time?: number | undefined;
  competitors: number[];
  care_types: number[];
  room_types: number[];
  care_levels: number[];
  review_sources: number[];
  review_categories: number[];
  swot_categories: number[];
}

export const fetchData = async (communityId: number, widgetType: number, range_type: string, params: FilterType) => {
  const url = `/views/${communityId}/data/${widgetType}/${range_type}`;
  const response = await axios.get(url, {
    params,
  });
  return response.data;
};
