import React, { useRef } from 'react';

import { Table, Typography } from 'antd';
import { format } from 'date-fns';
import shortUUID from 'short-uuid';
import styled from 'styled-components';

import { MEDIA_BREAK_POINTS } from '../../../constants';
import DashboardReportsChartHandler from '../charts/DashboardReportsChartHandler';
import { CommunityFeeIncentivesDataType } from '../datatypes';

interface Props {
  data: CommunityFeeIncentivesDataType[];
}

const StyledTable = styled(Table)`
  .ant-table-tbody > tr:nth-child(odd) {
    background-color: inherit;
  }
`;

export const DashboardReportIncentivesTable = ({ data }: Props) => {
  const componentRef: React.MutableRefObject<HTMLElement | undefined | null> = useRef();
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date_created',
      width: '200px',
      render: (value: any, record: any) => <span>{format(new Date(value), 'MMM dd, yyyy')}</span>,
      sortDirections: ['ascend' as const, 'descend' as const],
      sorter: (a: any, b: any) => {
        return new Date(a?.date_created).getTime() - new Date(b?.date_created).getTime();
      },
    },
    {
      title: 'Community Name',
      dataIndex: 'community_name',
      width: '340px',
      sortDirections: ['ascend' as const, 'descend' as const],
      sorter: (a: any, b: any) => {
        return a?.community_name.localeCompare(b?.community_name);
      },
    },
    {
      title: 'Incentives',
      dataIndex: 'incentive',
    },
  ];

  return (
    <DashboardReportsChartHandler chartRef={componentRef}>
      <div ref={(node) => (componentRef.current = node)}>
        <Typography.Paragraph style={{ fontSize: '24px', marginBottom: 0 }}>Incentive</Typography.Paragraph>
        <StyledTable
          bordered
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ x: MEDIA_BREAK_POINTS.MOBILE }}
          rowKey={() => shortUUID.generate()}
        />
      </div>
    </DashboardReportsChartHandler>
  );
};

export default DashboardReportIncentivesTable;
