import React from 'react';

import styled from 'styled-components';

import Select from 'components/lib/Select';

import { SHOP_REQUEST_STATUS, SHOP_REQUEST_STATUS_LABELS } from './constants';
import { MysteryShopRequestInbound } from './types';

const StyledSelect = styled(Select)<{ statuscolor: string }>`
  &&&.ant-select {
    width: 152px;
    height: 34px;
    .ant-select-selection-item {
      font-size: 14px;
    }
    .ant-select-selector {
      border: none;
      padding: 0 6px 0 7px;
      border-radius: 4px;
      background: ${(props) => props.statuscolor};
    }
  }
`;

const STATUS_COLOR_MAP = {
  [SHOP_REQUEST_STATUS_LABELS.NOT_STARTED]: 'var(--background)',
  [SHOP_REQUEST_STATUS_LABELS.IN_PROGRESS]: 'var(--pale-blue)',
  [SHOP_REQUEST_STATUS_LABELS.WAITING_FOR_REPLY]: 'var(--pale-blue)',
  [SHOP_REQUEST_STATUS_LABELS.NEEDS_APPROVAL]: 'var(--light-green)',
  [SHOP_REQUEST_STATUS_LABELS.APPROVED]: 'var(--light-green)',
  [SHOP_REQUEST_STATUS_LABELS.CANCELED]: 'var(--pale-yellow)',
};

type Props = {
  shopRequest: MysteryShopRequestInbound;
  disabled?: boolean;
  onChange: (item: any) => void;
};

const ShopRequestStatusSelector = ({ shopRequest, disabled, onChange }: Props) => {
  return (
    <StyledSelect
      options={SHOP_REQUEST_STATUS.map((item) => ({ label: item.label, value: item.id }))}
      value={SHOP_REQUEST_STATUS.find((item) => item.id === shopRequest.status)}
      statuscolor={STATUS_COLOR_MAP[shopRequest.status]}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default ShopRequestStatusSelector;
