import { BarConfig } from '@ant-design/plots';
import type { Element } from '@antv/g2';

const ANTD_AXIS_LINE_ELEMENT_ID = '-axis-line';

/**
 * Calculate and retrieve the position of data insertion bars for AntD charts.
 * Unfortunately we have to calculate this manually since it is not directly provided by Antd charts.
 */
export const getDataInsertionBars = (plot: any, barProps: BarConfig) => {
  const barElements = plot.chart.geometries[0].elements;
  const positions = barElements
    .map((bar: Element, index: number) => {
      const barContainer = bar.shape.getBBox();
      if (barContainer.width > 0) {
        return undefined;
      }

      let x, y;
      const axisLine = plot.container.querySelector(`#${ANTD_AXIS_LINE_ELEMENT_ID}`)?.parentNode;
      if (axisLine) {
        x = axisLine.getBBox().width;
        const axisLabels = axisLine.querySelectorAll('text');
        if (axisLabels) {
          const labelElement = Array.from(axisLabels as Element[]).find(
            (el: any) => el.textContent === barProps.data[index].community_name
          );
          y = labelElement?.getBBox().y;
        }
      }
      return { x, y, index };
    })
    .filter((el: any) => !!el);

  return positions;
};
