import React, { useEffect } from 'react';

import classNames from 'classnames';

import DataTable from './DataTable';
import { valueFormatter } from './utils';
import { COMMUNITY_TYPE } from '../../../constants';
import { useWidgetContext } from '../Widget/WidgetContext';

interface Props {
  data: any;
}

const DataTableAverage = ({ data }: Props) => {
  const { type, setCSVData } = useWidgetContext();

  useEffect(() => {
    const communitiesData = data.map((community: any) => [community.community_name, community.value.toFixed(2)]);
    const exportData = [['COMMUNITY', 'VALUE'], ...communitiesData];
    setCSVData(exportData);
  }, [data]);

  const columns = [
    {
      title: 'COMMUNITY',
      dataIndex: 'community_name',
      width: '50%',
      align: 'left' as const,
      render: (value: any, record: any) => (
        <span className={classNames({ highlighted: record.community_type === COMMUNITY_TYPE.COMMUNITY })}>{value}</span>
      ),
    },
    {
      title: 'VALUE',
      dataIndex: 'value',
      align: 'center' as const,
      render: (value: any, record: any) => <span>{valueFormatter(value, type)}</span>,
    },
  ];

  return (
    <DataTable
      columns={columns}
      dataSource={data}
      scroll={{ x: 'max-content' }}
      pagination={false}
      rowKey={(record: any) => record?.community_name}
    />
  );
};

export default DataTableAverage;
