import React from 'react';

import { Button, Col, Modal, Row, Steps } from 'antd';

import axios from 'config/axiosPrivate';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  onSuccess: () => void;
  selectedId: any;
  status: number;
}

const UpdateStatusModal: React.FC<Props> = ({ open, handleClose, onSuccess, selectedId, status }) => {
  const [current, setCurrent] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const onChange = (value: number) => {
    setCurrent(value);
  };

  const upadteStatus = async () => {
    setLoading(true);
    axios
      .post('/communities/Save_MysteryShopStatus/', {
        data: [
          {
            id: selectedId,
            status: current + 1,
            op: 'edited',
            disabled: false,
          },
        ],
      })
      .then((res) => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    setCurrent(status);
  }, [status]);
  return (
    <>
      <Modal
        title="Update Status"
        centered
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
        width={900}
        footer={false}
      >
        <br />
        <br />
        <Steps
          current={current}
          onChange={onChange}
          labelPlacement="vertical"
          items={[
            {
              title: 'Requested',
            },
            {
              title: 'In Progress',
            },
            {
              title: 'Ready for Review',
            },
            {
              title: 'Solved',
            },
            {
              title: 'Satisfied',
            },
          ]}
        />
        <br />
        <Row>
          <Col xs={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                borderRadius: 8,
                padding: 10,
              }}
            >
              <Button type="primary" htmlType="submit" onClick={upadteStatus} loading={loading}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default UpdateStatusModal;
