import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select, Tooltip } from 'antd';

import axios from 'config/axiosPrivate';
import LocationSearchInput from 'formComponents/LocationSearchInput';
import { CITY_OPTIONS, STATES } from 'helpers/constants';
import { useAppSelector } from 'store/hook';

interface Props {
  initialData: any;
  community_id: any;
  apartment_id: number;
  getCompetitorInfo?: any;
  setData: (data: any) => void;
}

const BasicInfoForm = ({ initialData, community_id, apartment_id, getCompetitorInfo, setData }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const paymentTypes = useAppSelector((state) => state.global.PaymentTypes);
  const business_models = useAppSelector((state) => state.global.business_models);

  const [location, setLocation] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm<any>();

  const onFinish = (values: any) => {
    const payload = {
      id: community_id ? parseInt(community_id) : 1,
      company_id: initialData?.Company,
      management_company_id: initialData.ManagementCompany,
      comunity_name: values.ApartmentName || '',
      address: values?.ApartmentAddress || '',
      state: values.State || '',
      managed_by: values?.ManagedBy || '',
      phone_number: values?.PhoneNumber || '',
      website: values?.Website || '',
      year_opened: values?.YearOpened || '',
      payment_types: values?.payment_types || '',
      business_model: `${values?.business_model}` || '',
      latitude: location?.lat || initialData?.Latitude,
      longitude: location?.lng || initialData?.Longitude,
      city: values?.City || '',
      zip_code: values?.ZipCode || '',
      google_map_location: values?.GoogleMapLocation || '',
      google_formatted_address: values?.ApartmentAddress || '',
      google_rating: initialData?.GoogleRating || 0,
      google_reviews: initialData?.GoogleReviews || 0,
      a_place_for_mom_id: values?.APlaceForMomID || '',
      caring_url: values?.CaringURL || '',
      op: 'edited',
      disabled: false,
      accessibility: 0,
      region: '1',
      apartment_id: apartment_id,
    };

    const newLocalState = {
      ...initialData,
      ...form.getFieldsValue(),
      ManagementCompany: form.getFieldValue('management_company'),
      PaymentTypes: form.getFieldValue('payment_types'),
      BusinessModel: form.getFieldValue('business_model'),
    };

    setLoading(true);
    axios
      .post('/communities/Save_AddComm_CompetitorInfo/', {
        data: [payload],
      })
      .then((res) => {
        showMessage('success', 'Saved successfully!');
        setData(newLocalState);
        setLoading(false);
        if (getCompetitorInfo) {
          getCompetitorInfo(true);
        }
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong!');
        setLoading(false);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const paymentTypesOptions = paymentTypes.map((pt) => ({
    label: pt.type,
    value: pt.id,
  }));

  const businessOptions = business_models.map((pt: any) => ({
    label: pt.type,
    value: pt.id,
  }));

  const handleSetLocation = (data: any) => {
    setLocation(data);
    form.setFieldsValue({
      ApartmentName: data?.name,
      ApartmentAddress: data?.formatted_address,
      City: data?.city,
      State: data?.state,
      ZipCode: data?.zip_code,
      GoogleMapLocation: data?.place_id,
      PhoneNumber: data?.phone_number,
      Website: data?.website,
    });
  };

  React.useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        ...initialData,
        id: +initialData?.id,
        management_company: +initialData?.ManagementCompany,
        payment_types: +initialData?.PaymentTypes || null,
        business_model: +initialData?.BusinessModel || null,
      });
    }
  }, [initialData, community_id]);

  return (
    <div>
      {contextHolder}
      <div style={{ padding: '0px 20px' }}>
        <div>
          <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off" form={form}>
            <div
              style={{
                maxWidth: '570px',
                margin: '0 auto',
              }}
            >
              <Row gutter={[5, 0]}>
                <Col xs={24} style={{ marginBottom: 20 }}>
                  <Form.Item
                    name="ApartmentName"
                    label={<p className="form_label">Competitor Name:</p>}
                    rules={[{ required: true, message: 'Enter Community Name' }]}
                  >
                    <LocationSearchInput
                      getLocationBySearch={handleSetLocation}
                      placeholder="Enter name or select from Google Places"
                      style={{ borderRadius: '6px', height: '40px', boxShadow: '0px' }}
                      updateCommunityName={(value: string) => form.setFieldValue('ApartmentName', value)}
                      defaultValue={initialData?.ApartmentName}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="ApartmentAddress"
                    label={<p className="form_label">Address:</p>}
                    rules={[{ required: true, message: 'Enter Address' }]}
                  >
                    <Input
                      suffix={
                        initialData.Longitude ? (
                          <Tooltip
                            title={() => (
                              <span>
                                Longitude: {initialData.Longitude} <br /> Latitude: {initialData.Latitude} <br />
                                Rating: {initialData.GoogleRating} <br />
                                Reviews {initialData.GoogleReviews}
                              </span>
                            )}
                            color="blue"
                          >
                            <InfoCircleOutlined
                              style={{
                                color: 'rgba(0,0,0,.45)',
                                cursor: 'pointer',
                              }}
                            />
                          </Tooltip>
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="City"
                    label={<p className="form_label">City:</p>}
                    rules={[{ required: true, message: 'Please Select City' }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={CITY_OPTIONS}
                      placeholder="Select city"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="State"
                    label={<p className="form_label">State:</p>}
                    rules={[{ required: true, message: 'Please Select State' }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select state"
                      options={STATES}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="ZipCode"
                    label={<p className="form_label">Zip Code:</p>}
                    rules={[{ required: false, message: 'Enter Zip Code' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="PhoneNumber"
                    label={<p className="form_label">Phone Number:</p>}
                    rules={[{ required: false, message: 'Please Enter Phone Number' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} style={{ marginBottom: '30px' }}>
                  <Form.Item
                    name="Website"
                    label={<p className="form_label">Website:</p>}
                    rules={[{ required: false, message: 'Please Enter Website Url' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="GoogleMapLocation"
                    label={<p className="form_label">Google Place ID</p>}
                    rules={[{ required: false, message: 'Please Enter Place ID' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} style={{ marginBottom: '30px' }}>
                  <Form.Item
                    name="YearOpened"
                    label={<p className="form_label">Year Opened:</p>}
                    rules={[{ required: false, message: 'Please Year Opened' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="APlaceForMomID" label={<p className="form_label">A Place For Mom ID</p>}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="CaringURL" label={<p className="form_label">Caring URL</p>}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="ManagedBy"
                    label={<p className="form_label">Managed by</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter managed by',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="payment_types"
                    label={<p className="form_label">Payment type:</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please Select Payment type',
                      },
                    ]}
                  >
                    <Select options={paymentTypesOptions} placeholder="Select" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="business_model"
                    label={<p className="form_label">Business Model:</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please Select  Business Model',
                      },
                    ]}
                  >
                    <Select options={businessOptions} placeholder="Select" />
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  width: '100%',
                  borderTop: '2px solid #eee',
                  boxSizing: 'border-box',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  height: '80px',
                  marginTop: 30,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoForm;
