import React, { useState } from 'react';

import { Button, Modal, Typography } from 'antd';
import dayjs from 'dayjs';

import { useAppSelector } from 'store/hook';

import { CareFeeDateType, CommunityFeeDateType, LEVELS } from './constants';
import { EditableTable } from '../../../common/Table';

interface Props {
  loading: boolean;
  data: CareFeeDateType[];
  onCreate: (record: Partial<CareFeeDateType>) => void;
  onUpdate: (record: Partial<CareFeeDateType>) => void;
  onDelete: (record: Partial<CareFeeDateType>) => void;
}

const FeesFormCareFeesTable = ({ data, loading, onCreate, onUpdate, onDelete }: Props) => {
  const [selectedFeeType, setSelectedFeeType] = useState<string | undefined>('');

  const living_types = useAppSelector((state) => state.global.livingtypes);
  const feeTypes = useAppSelector((state) => state.global.FeeTypes);

  const living_options = living_types.map((val) => ({
    label: val.type,
    value: +val.id,
  }));

  const fee_type_options = feeTypes.map((val) => ({
    label: val.type,
    value: +val.id,
  }));

  const levelOption = fee_type_options.find((val) => val.label == 'Levels');

  const uniqueLivingTypeValidator = async (record: CommunityFeeDateType) => {
    const filteredData = data.filter((entry) => entry.key != record.key);

    const hasLivingTypeEntry = filteredData.some((entry) => entry.livingTypeId === record.livingTypeId);

    if (hasLivingTypeEntry) {
      return Promise.reject('This entry matches an existing record with the same Living Type.');
    }

    return Promise.resolve();
  };

  const isLevelSelected = (form: any) => {
    const record = form.getFieldsValue();
    if (record.feesTypeId == levelOption?.value) {
      setSelectedFeeType(levelOption?.value.toString());
    } else {
      setSelectedFeeType('');
    }
  };

  const futureDatesValidator = async (record: CommunityFeeDateType) => {
    const isFutureDate = record.date > dayjs().endOf('day');
    if (isFutureDate) {
      return Promise.reject('Future dates are not acceptable. Choose a valid date.');
    }
    return Promise.resolve();
  };

  const handleSelect = (form: any) => {
    const handleConfirm = () => {
      form.setFieldsValue({
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        level6: null,
        level7: null,
        level8: null,
      });
      Modal.destroyAll();
    };

    const handleCancel = () => {
      form.setFieldValue('feesTypeId', levelOption?.value);
      Modal.destroyAll();
    };

    const record = form.getFieldsValue();

    const levelHasRecord = LEVELS.find((level) => !!record[level]);
    const isLevelOptionSelected = selectedFeeType == levelOption?.value;

    isLevelOptionSelected &&
      levelHasRecord &&
      Modal.confirm({
        icon: null,
        title: 'Are you sure?',
        content:
          'You are about to delete all level values. This action is irreversible. Once deleted, you will not be able to recover any data. Please confirm if you wish to proceed.',
        footer: (
          <div style={{ float: 'right', marginTop: '10px' }}>
            <Button key="ok" type="primary" style={{ marginRight: '4px' }} onClick={handleConfirm}>
              Confirm
            </Button>
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        ),
      });
  };

  const columns = [
    {
      title: 'Living Type',
      dataIndex: 'livingTypeId',
      inputType: 'select',
      options: living_options,
      width: '200px',
      required: true,
      editable: true,
      validator: uniqueLivingTypeValidator,
    },
    {
      title: 'Fee Type',
      dataIndex: 'feesTypeId',
      inputType: 'select',
      options: fee_type_options,
      handleChange: handleSelect,
      width: '130px',
      required: true,
      editable: true,
    },
    {
      title: 'Fee Note',
      dataIndex: 'feeNote',
      inputType: 'textArea',
      editable: true,
      width: '150px',
    },
    {
      title: 'Medication Management Fee',
      dataIndex: 'medicationManagementFee',
      inputType: 'currency',
      ellipsis: true,
      editable: true,
      width: '120px',
    },
    {
      title: 'Average Care Cost',
      dataIndex: 'averageCareCost',
      inputType: 'currency',
      ellipsis: true,
      editable: true,
      width: '120px',
    },
    {
      title: 'Level 1',
      dataIndex: 'level1',
      inputType: 'currency',
      ellipsis: true,
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level1' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Level 2',
      dataIndex: 'level2',
      inputType: 'currency',
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level2' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Level 3',
      dataIndex: 'level3',
      inputType: 'currency',
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level3' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Level 4',
      dataIndex: 'level4',
      inputType: 'currency',
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level4' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Level 5',
      dataIndex: 'level5',
      inputType: 'currency',
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level5' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Level 6',
      dataIndex: 'level6',
      inputType: 'currency',
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level6' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Level 7',
      dataIndex: 'level7',
      inputType: 'currency',
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level7' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Level 8',
      dataIndex: 'level8',
      inputType: 'currency',
      editable: true,
      calculatedDataIndex: selectedFeeType != levelOption?.value ? 'level8' : '',
      calculated: isLevelSelected,
      dependencies: ['feesTypeId'],
      width: '110px',
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      inputType: 'date',
      editable: true,
      initialValue: dayjs(),
      width: '200px',
      validator: futureDatesValidator,
    },
  ];

  return (
    <div>
      <Typography.Title level={3}>Care Fees</Typography.Title>
      <EditableTable
        tableLayout="fixed"
        loading={loading}
        columns={columns}
        data={data}
        onAdd={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    </div>
  );
};

export default FeesFormCareFeesTable;
