import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Typography } from 'antd';

import classes from './styles.module.scss';

interface Props {
  item: any;
  handleRemove: () => void;
  onSelectItem: () => void;
  selectedItem: any;
}

const CompetitorSearchItem = ({ item, handleRemove, onSelectItem, selectedItem }: Props) => {
  return (
    <div
      style={{
        borderBottom: '1px solid #ccc',
        paddingRight: 50,
        position: 'relative',
        marginBottom: 10,
        background: selectedItem?.id === item?.id ? '#eee' : '#fff',
        padding: 5,
        borderRadius: 10,
        cursor: 'pointer',
      }}
      onClick={onSelectItem}
      className={classes.competitor_item}
    >
      {/* <Link to={`/view-apartments?id=${item.id}`}> */}
      <Typography.Title level={5} style={{ margin: 0 }}>
        {item?.name}
      </Typography.Title>
      {/* </Link> */}
      <Typography.Paragraph style={{ color: '#ccc' }}>{item?.formatted_address}</Typography.Paragraph>
      <Popconfirm
        title="Delete"
        description="Are you sure to delete this competitor?"
        onConfirm={handleRemove}
        okText="Yes"
        cancelText="No"
      >
        <Button
          icon={<CloseOutlined />}
          type="link"
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            marginTop: -20,
            zIndex: 99,
          }}
          danger
          className={classes.competitor_item_remove_btn}
        />
      </Popconfirm>
    </div>
  );
};

export default CompetitorSearchItem;
