import React, { useEffect, useState } from 'react';

import { SelectProps } from 'antd';

import axios from 'config/axiosPrivate';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  handleSetLoader,
  handleStoreUserCommunities,
  handleStoreUserCommunitiesCompetitors,
} from 'store/slices/globalSlice';

import { useHeaderSelectorContext } from './HeaderSelectorContext';
import { STATUS } from '../../../constants';
import SelectInput from '../../../formComponents/SelectInput';
import { processFullHomeCommunities } from '../../../utils/data/communities';

const HeaderSelectorCommunity = () => {
  const dispatch = useAppDispatch();
  const { selectedClient, selectedCompany, selectedManagementCompany } = useAppSelector((state) => state.global);

  const { communityList, setCommunityList } = useHeaderSelectorContext();

  const [loading, setLoading] = useState(STATUS.IDLE);
  const [communityOptions, setCommunityOptions] = useState<SelectProps['options']>([]);

  const getFullHomeCommunities = () => {
    dispatch(handleSetLoader(true));
    setLoading(STATUS.LOADING);
    axios
      .post('/communities/Get_FullHomeCommunities/', {
        client_id: selectedClient?.id,
        company_list: selectedCompany ? [selectedCompany?.id] : [],
        management_company_list: selectedManagementCompany ? [selectedManagementCompany?.id] : [],
        comm_list: communityList,
        caretype_list: [],
      })
      .then((res) => {
        const data = res.data?.payload;

        const { communities, updatedCommunities, updatedCompetitor } = processFullHomeCommunities(data);

        setCommunityOptions(communities);
        setLoading(STATUS.LOADED);

        dispatch(handleStoreUserCommunities(updatedCommunities));
        dispatch(handleStoreUserCommunitiesCompetitors(updatedCompetitor));
        dispatch(handleSetLoader(false));
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(STATUS.FAILURE);
        dispatch(handleSetLoader(false));
      });
  };

  useEffect(() => {
    if (!selectedCompany) return;
    getFullHomeCommunities();
  }, [selectedCompany, selectedManagementCompany, communityList]);

  return (
    <SelectInput
      mode={'multiple' as const}
      placeholder="Communities"
      options={communityOptions}
      onApply={(value) => {
        setCommunityList(value);
      }}
      loading={loading === STATUS.LOADING}
      initValue={communityList}
    />
  );
};

export default HeaderSelectorCommunity;
