import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Typography } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const Container = styled.div``;

const StepNavigationBottom = ({ current, setCurrent }: Props) => {
  const navigate = useNavigate();
  const { managementCompanyId } = useParams();

  const basic_info = useAppSelector((state) => state.community.basic_info);

  const previousStep = () => {
    setCurrent((prevState) => prevState - 1);
  };

  const nextStep = () => {
    setCurrent((prevState) => prevState + 1);
  };

  return (
    <Container
      style={{
        width: '100%',
        borderTop: '2px solid #eee',
        padding: '10px 30px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '80px',
        marginTop: 30,
      }}
    >
      <Button onClick={() => navigate(`/overview/${managementCompanyId}`)}>Cancel</Button>
      <div style={{ textAlign: 'center', padding: '20px 50px', maxWidth: '70%' }}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {basic_info?.comunity_name}
        </Typography.Title>
        <Typography.Paragraph style={{ color: '#ccc', fontSize: 16 }}>{basic_info?.address}</Typography.Paragraph>
      </div>
      <div>
        {current !== 0 && (
          <Button style={{ marginRight: 10 }} onClick={previousStep}>
            Previous Step
          </Button>
        )}
        {current === 3 ? (
          <Button type="primary">Finish</Button>
        ) : (
          <Button type="primary" htmlType="button" onClick={nextStep}>
            Next Step
          </Button>
        )}
      </div>
    </Container>
  );
};

export default StepNavigationBottom;
