import React, { useState } from 'react';

import axios from 'config/axiosPrivate';

import { CommunityFeeDateType } from './constants';
import { useFeesContext } from './FeesContext';
import FeesFormCommunityFeesTable from './FeesFormCommunityFeesTable';
import { mapCommunityFeeDataToRequest, mapCommunityFeeResponseToData } from './utils';
import { STATUS } from '../../../constants';

const FeesFormCommunityFees = () => {
  const { communityId, showMessage } = useFeesContext();

  const [communityFees, setCommunityFees] = useState<CommunityFeeDateType[]>([]);

  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  React.useEffect(() => {
    if (communityId) {
      getCommunityFeesIncentives();
    }
  }, [communityId]);

  const getCommunityFeesIncentives = () => {
    setDataLoading(STATUS.LOADING);
    axios
      .get(`/communities/${communityId}/fee-incentives?active=1`)
      .then((response) => {
        const data = response.data;
        const formatted_data: CommunityFeeDateType[] = data?.map((item: any) => mapCommunityFeeResponseToData(item));
        setCommunityFees(formatted_data);
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to fetch Community Fees');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleCreate = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityFeeDataToRequest(item);

    axios
      .post(`/communities/${communityId}/fee-incentives`, [data])
      .then((response) => {
        const newCommunityFees = response.data.map((record: CommunityFeeDateType) =>
          mapCommunityFeeResponseToData(record)
        );
        setCommunityFees([...communityFees, ...newCommunityFees]);
        showMessage('success', 'Community Fee added successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to add Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityFeeDataToRequest(item);

    axios
      .put(`/communities/${communityId}/fee-incentives/${item.id}`, data)
      .then((response) => {
        const fee_index = communityFees.findIndex((fee) => fee.id === item.id);
        communityFees[fee_index] = mapCommunityFeeResponseToData(response.data);
        setCommunityFees([...communityFees]);
        showMessage('success', 'Community Fee changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to change Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<CommunityFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    axios
      .delete(`/communities/${communityId}/fee-incentives/${item.id}`)
      .then((response) => {
        const fee_index = communityFees.findIndex((fee) => fee.id === item.id);
        communityFees.splice(fee_index, 1);
        setCommunityFees([...communityFees]);
        showMessage('success', 'Community Fee deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Community Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <FeesFormCommunityFeesTable
      loading={dataLoading === STATUS.LOADING}
      data={communityFees}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default FeesFormCommunityFees;
