import React from 'react';

import { useAppSelector } from 'store/hook';

import AdminPageHeader from '../common/Admin/AdminPageHeader';

const UserLogReport = () => {
  const userInfo: any = useAppSelector((state) => state.auth.currentUser);

  return (
    <div style={{ padding: '20px' }}>
      <AdminPageHeader title={'User Log Report'} description={'Preview collected log entries.'} />
      {userInfo?.userlog_dashboard_url ? (
        <iframe
          title="dashboard"
          src={userInfo?.userlog_dashboard_url}
          style={{ border: 0, width: '100%', height: '100vh' }}
        ></iframe>
      ) : (
        <div style={{ textAlign: 'center', minHeight: 100 }}>
          <h2>No dashboard found</h2>
        </div>
      )}
    </div>
  );
};

export default UserLogReport;

// https://lookerstudio.google.com/embed/reporting/0dc9fd78-3708-4f92-8ec5-e40098a0c688/page/QTudD
