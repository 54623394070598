import React, { useState } from 'react';

import styled from 'styled-components';

import ConfirmModal from '../../../common/Modal/ConfirmModal';
import Input from '../../lib/Input';
import { VIEW_MODALS, VIEW_MODALS_CONTENT } from '../constants';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-around;
  padding: 20px 0 0 0;
  flex-grow: 1;
`;

const Label = styled.span`
  font-weight: 900;
`;

interface Props {
  open: boolean;
  type: string;
  loading: boolean;
  onConfirm: any;
  onCancel: any;
}
const ViewInputModal = ({ open, type, loading, onConfirm, onCancel }: Props) => {
  const [name, setName] = useState<string>('');

  const handleConfirm = () => {
    onConfirm(name);
  };

  const handleChange = (e: any) => {
    setName(e.target.value);
  };

  return (
    <ConfirmModal
      open={open}
      title={VIEW_MODALS_CONTENT[type].title}
      description={VIEW_MODALS_CONTENT[type].description}
      confirmLabel={VIEW_MODALS_CONTENT[type].label}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      loading={loading}
      disabled={type != VIEW_MODALS.DELETE && type !== VIEW_MODALS.RESET_LAYOUT && name.length == 0}
    >
      {VIEW_MODALS_CONTENT[type].showInput && (
        <InputContainer>
          <Label>View Name</Label>
          <Input placeholder={'Enter a name'} onChange={handleChange} />
        </InputContainer>
      )}
    </ConfirmModal>
  );
};

export default ViewInputModal;
