import dayjs from 'dayjs';
import shortUUID from 'short-uuid';

import { LivingInfoDataType } from './constants';
import { GLOBAL_DATE_FORMAT } from '../../../constants';

export const mapCommunityLivingInfoToData = (item: any) => {
  return {
    id: item?.id,
    key: shortUUID.generate(),
    apartmentType: item?.apartment_type,
    apartmentTypeId: item?.apartment_type_id,
    livingTypeId: item?.living_type_id,
    livingType: item?.living_type,
    baseRent: item?.base_rent,
    squareFootage: item?.sq_footage,
    pricePerSquare: item?.price_per_sq,
    notes: item?.notes,
    createdBy: item?.created_by,
    createdAt: dayjs(new Date(item?.created_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    updatedBy: item?.updated_by,
    updatedAt: dayjs(new Date(item?.updated_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    date: dayjs(new Date(item?.date).toDateString(), { format: GLOBAL_DATE_FORMAT }),
  };
};

export const mapCommunityLivingInfoDataToRequest = (item: Partial<LivingInfoDataType>) => {
  const pricePerSquareFoot =
    item.baseRent && item.squareFootage ? (item.baseRent / item.squareFootage).toFixed(2) : undefined;
  return {
    apartment_type_id: item.apartmentTypeId,
    living_type_id: item.livingTypeId,
    base_rent: item.baseRent,
    sq_footage: item.squareFootage,
    price_per_sq: pricePerSquareFoot,
    notes: item.notes,
    updated_by: item?.updatedBy,
    updated_at: item?.updatedAt,
    date: item?.date,
  };
};
