import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { Button, Image, Typography } from 'antd';
import styled from 'styled-components';

import { useSettingsContext } from 'components/Settings/SettingsContext';

const OuterContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 20px;
  left: 0;
  display: flex;
  justify-content: space-around;
`;

const Container = styled.div`
  background-color: white;
  width: 455px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-gray);
`;

const StyledCloseButton = styled(Button)`
  margin-left: 10px;
  border: 0;
  padding: 0;
  outline: 0;
  box-shadow: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  totalCount: number;
  selectedCount: number;
  singularLabel: string;
  multipleLabel: string;
  onClose: () => void;
  actions: ReactNode;
};

const Notification = ({ totalCount, selectedCount, actions, singularLabel, multipleLabel, onClose }: Props) => {
  if (!selectedCount) {
    return null;
  }

  let text;
  if (selectedCount === totalCount) {
    text = `All ${selectedCount} ${multipleLabel} on page selected.`;
  } else if (selectedCount === 1) {
    text = `1 ${singularLabel} selected.`;
  } else {
    text = `${selectedCount} ${multipleLabel} selected.`;
  }

  const notificationContent = (
    <OuterContainer>
      <Container>
        <Typography.Text>{text}</Typography.Text>
        <ButtonsContainer>
          {actions}
          <StyledCloseButton onClick={onClose}>
            <Image height={16} width={16} preview={false} src="/icons/close.svg" />
          </StyledCloseButton>
        </ButtonsContainer>
      </Container>
    </OuterContainer>
  );
  return ReactDOM.createPortal(notificationContent, document.body);
};

export default Notification;
