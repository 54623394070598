import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Col, Form, Input, Row, Select } from 'antd';

import LocationSearchInput from 'formComponents/LocationSearchInput';
import { CITY_OPTIONS, STATES } from 'helpers/constants';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreBasicInfo } from 'store/slices/communitySlice';

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const BasicInfoForm = ({ current, setCurrent }: Props) => {
  const dispatch = useAppDispatch();
  const paymentTypes = useAppSelector((state) => state.global.PaymentTypes);
  const business_models = useAppSelector((state) => state.global.business_models);
  const basic_info = useAppSelector((state) => state.community.basic_info);
  const navigate = useNavigate();
  const { managementCompanyId } = useParams();

  const [location, setLocation] = React.useState<any>(null);

  const onFinish = (values: any) => {
    const payload = {
      company_id: values?.company_id,
      management_company_id: values.management_company_id,
      comunity_name: values.comunity_name || '',
      address: values?.address || '',
      state: values.state || '',
      managed_by: values?.managed_by || '',
      phone_number: values?.phone_number || '',
      website: values?.website || '',
      year_opened: values?.year_opened || '',
      payment_types: values?.payment_types || '',
      business_model: values?.business_model || '',
      latitude: location?.lat || '',
      longitude: location?.lng || '',
      city: values?.city || '',
      zip_code: values?.zip_code || '',
      google_map_location: values?.google_map_location || '',
      google_formatted_address: values?.address || '',
      google_rating: 0,
      google_reviews: 0,
      a_place_for_mom_id: values?.a_place_for_mom_id || '',
      caring_url: values?.caring_url || '',
      op: 'added',
      disabled: false,
    };

    dispatch(handleStoreBasicInfo(payload));
    setCurrent((prevState) => prevState + 1);
  };

  const paymentTypesOptions = paymentTypes.map((pt) => ({
    label: pt.type,
    value: pt.id,
  }));

  const businessOptions = business_models.map((pt: any) => ({
    label: pt.type,
    value: pt.id,
  }));

  const [form] = Form.useForm<any>();

  const handleSetLocation = (data: any) => {
    setLocation(data);
    form.setFieldsValue({
      comunity_name: data?.name,
      address: data?.formatted_address,
      city: data?.city,
      state: data?.state,
      zip_code: data?.zip_code,
      google_map_location: data?.place_id,
      phone_number: data?.phone_number,
      website: data?.website,
    });
  };

  return (
    <div>
      <div style={{ padding: '0px 20px' }}>
        <div>
          <Form
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            autoComplete="off"
            initialValues={{
              ...basic_info,
              payment_types: basic_info?.payment_types || null,
              business_model: +basic_info?.business_model || null,
            }}
            form={form}
          >
            <div
              style={{
                maxWidth: '570px',
                margin: '0 auto',
              }}
            >
              <Row gutter={[5, 0]}>
                <Col xs={24} style={{ marginBottom: 20 }}>
                  <Form.Item
                    name="comunity_name"
                    label={<p className="form_label">Competitor Name:</p>}
                    rules={[{ required: true, message: 'Enter Community Name' }]}
                  >
                    <LocationSearchInput
                      getLocationBySearch={handleSetLocation}
                      placeholder="Enter name or select from Google Places"
                      style={{ borderRadius: '6px', height: '40px', boxShadow: '0px' }}
                      defaultValue={basic_info?.comunity_name}
                      updateCommunityName={(value: string) => form.setFieldValue('comunity_name', value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="address"
                    label={<p className="form_label">Address:</p>}
                    rules={[{ required: true, message: 'Enter Address' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="city"
                    label={<p className="form_label">City:</p>}
                    rules={[{ required: true, message: 'Please Select City' }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={CITY_OPTIONS}
                      placeholder="Select city"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="state"
                    label={<p className="form_label">State:</p>}
                    rules={[{ required: true, message: 'Please Select State' }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select state"
                      options={STATES}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name="zip_code"
                    label={<p className="form_label">Zip Code:</p>}
                    rules={[{ required: false, message: 'Enter Zip Code' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="phone_number"
                    label={<p className="form_label">Phone Number:</p>}
                    rules={[{ required: false, message: 'Please Enter Phone Number' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} style={{ marginBottom: '30px' }}>
                  <Form.Item
                    name="website"
                    label={<p className="form_label">Website:</p>}
                    rules={[{ required: false, message: 'Please Enter Website Url' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="google_map_location"
                    label={<p className="form_label">Google Place ID</p>}
                    rules={[{ required: false, message: 'Please Enter Place ID' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} style={{ marginBottom: '30px' }}>
                  <Form.Item
                    name="year_opened"
                    label={<p className="form_label">Year Opened:</p>}
                    rules={[{ required: false, message: 'Please Year Opened' }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="a_place_for_mom_id" label={<p className="form_label">A Place For Mom ID</p>}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="caring_url" label={<p className="form_label">Caring URL</p>}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="managed_by"
                    label={<p className="form_label">Managed by</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please enter managed by',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name="payment_types"
                    label={<p className="form_label">Payment type:</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please Select Payment type',
                      },
                    ]}
                  >
                    <Select options={paymentTypesOptions} placeholder="Select" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name="business_model"
                    label={<p className="form_label">Business Model:</p>}
                    rules={[
                      {
                        required: false,
                        message: 'Please Select  Business Model',
                      },
                    ]}
                  >
                    <Select options={businessOptions} placeholder="Select" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div
              style={{
                width: '100%',
                borderTop: '2px solid #eee',
                padding: '10px 30px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '80px',
                marginTop: 30,
              }}
            >
              <Button onClick={() => navigate(`/overview/${managementCompanyId}`)}>Cancel</Button>
              <div>
                {current !== 0 && (
                  <Button style={{ marginRight: 10 }} onClick={() => setCurrent((prevState) => prevState - 1)}>
                    Previous Step
                  </Button>
                )}
                {current === 3 ? (
                  <Button type="primary">Finish</Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Next Step
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoForm;
