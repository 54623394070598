import React from 'react';

import styled from 'styled-components';

import HeaderViewFiltersCareLevel from './HeaderViewFiltersCareLevel';
import HeaderViewFiltersCareType from './HeaderViewFiltersCareType';
import HeaderViewFiltersCompare from './HeaderViewFiltersCompare';
import HeaderViewFiltersCompetitors from './HeaderViewFiltersCompetitors';
import HeaderViewFiltersReviewCategory from './HeaderViewFiltersReviewCategory';
import HeaderViewFiltersReviewSource from './HeaderViewFiltersReviewSource';
import HeaderViewFiltersRoomType from './HeaderViewFiltersRoomType';
import HeaderViewFiltersSwotCategory from './HeaderViewFiltersSwotCategory';
import HeaderViewFiltersTime from './HeaderViewFiltersTime';
import { useCommunityViewContext } from '../CommunityViewContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 5px;
`;

const HeaderViewFiltersMenu = () => {
  const { view, competitorId } = useCommunityViewContext();

  return (
    <Container>
      <HeaderViewFiltersTime />
      {competitorId && <HeaderViewFiltersCompare />}
      {!competitorId && <HeaderViewFiltersCompetitors />}
      {view?.default && (
        <>
          <HeaderViewFiltersCareType />
          <HeaderViewFiltersRoomType />
          <HeaderViewFiltersCareLevel />
          <HeaderViewFiltersReviewSource />
          <HeaderViewFiltersReviewCategory />
          <HeaderViewFiltersSwotCategory />
        </>
      )}
    </Container>
  );
};

export default HeaderViewFiltersMenu;
