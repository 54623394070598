import React from 'react';

import { useAppSelector } from 'store/hook';

import SelectInput from '../../../formComponents/SelectInput';

const HeaderSelectorCompany = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const state = useAppSelector((state) => state.global);
  const { companyList, selectedCompany } = state;

  const initialValue = selectedCompany ? [selectedCompany?.id] : [];

  return (
    <SelectInput
      placeholder="Company"
      options={companyList}
      disabled={currentUser?.isSuperuser}
      initValue={initialValue}
    />
  );
};

export default HeaderSelectorCompany;
