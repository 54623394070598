import React, { createContext, useContext } from 'react';

type OverviewContent = {
  communities: any;
  competitors: any;
};

export const OverviewContext = createContext<OverviewContent>({
  communities: [],
  competitors: [],
});

export const useOverviewContext = () => useContext(OverviewContext);
