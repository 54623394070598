import React, { ChangeEvent } from 'react';

import { RadioChangeEvent, Typography } from 'antd';
import styled from 'styled-components';

import { DatePicker } from 'components/DatePicker/DatePicker';
import Input from 'components/lib/Input';
import Radio from 'components/lib/Radio';

import { useShopRequestModalContext } from './ShopRequestModalContext';
import ShopRequestModalShopperSelect from './ShopRequestModalShopperSelect';
import { MYSTERY_SHOP_REQUEST_DUE_DATES } from '../constants';
import ShopRequestsRepeatPicker from '../ShopRequestsRepeatPicker';

const Container = styled.div`
  border-left: solid 1px var(--line-gray);
  padding: 20px 30px 15px 20px;
  display: flex;
  flex-flow: column;
  max-width: 370px;
  box-sizing: border-box;
`;

const StyledTitle = styled.div`
  font-family: var(--font-bold);
  margin-bottom: 10px;
`;

const DatesContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-flow: column;
`;

const StyledRadioButton = styled(Radio)`
  font-family: var(--font-regular);
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledNote = styled(Typography.Text)`
  color: var(--text-secondary);
  font-size: 12px;
`;

const StyledInput = styled(Input)`
  width: 320px;
`;

const ShopRequestModalInfo = () => {
  const {
    dueDate,
    repeat,
    name,
    specificDueDate,
    repeatEnabled,
    endRepeat,
    setEndRepeat,
    setRepeatEnabled,
    setSpecificDueDate,
    setDueDate,
    setName,
    setRepeat,
  } = useShopRequestModalContext();

  return (
    <Container>
      <StyledTitle>Name (Optional)</StyledTitle>
      <StyledInput
        placeholder="Enter an optional name for easier search"
        value={name || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
      />
      <DatesContainer>
        <div>
          <StyledTitle>Due date</StyledTitle>
          <StyledRadioGroup onChange={(event: RadioChangeEvent) => setDueDate(event.target.value)} value={dueDate}>
            <StyledRadioButton value={MYSTERY_SHOP_REQUEST_DUE_DATES.IN_3_DAYS}>In 3 days</StyledRadioButton>
            <StyledRadioButton value={MYSTERY_SHOP_REQUEST_DUE_DATES.IN_A_WEEK}>In a week</StyledRadioButton>
            <StyledRadioButton value={MYSTERY_SHOP_REQUEST_DUE_DATES.IN_2_WEEKS}>In 2 weeks</StyledRadioButton>
            <StyledRadioButton value={MYSTERY_SHOP_REQUEST_DUE_DATES.SPECIFIC_DATE}>Specific date</StyledRadioButton>
          </StyledRadioGroup>
          {dueDate === MYSTERY_SHOP_REQUEST_DUE_DATES.SPECIFIC_DATE && (
            <DatePicker selectedDate={specificDueDate} onChange={setSpecificDueDate} />
          )}
        </div>
        <ShopRequestsRepeatPicker
          repeat={repeat}
          endRepeat={endRepeat}
          repeatEnabled={repeatEnabled}
          setRepeat={setRepeat}
          setEndRepeat={setEndRepeat}
          setRepeatEnabled={setRepeatEnabled}
        />
      </DatesContainer>
      <StyledTitle style={{ marginTop: '30px' }}>Shopper (Optional)</StyledTitle>
      <ShopRequestModalShopperSelect />
      <StyledNote>
        If no shopper is selected, any shopper will be able to assign this request to themselves from their request
        list.
      </StyledNote>
    </Container>
  );
};

export default ShopRequestModalInfo;
