import React from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import { AntdTableExpandIcon } from '../types';

const StyledButton = styled(Button)`
  height: 24px;
  width: 24px;
  padding: 0;
  &&&:active,
  &&&:hover {
    background: #f0f1f3;
  }
`;

const ShopRequestGroupTableExpandIcon = ({ expanded, onExpand, record }: AntdTableExpandIcon) => {
  const icon = expanded ? <UpOutlined /> : <DownOutlined />;
  return (
    <StyledButton type="text" onClick={(event: React.MouseEvent) => onExpand(record, event)}>
      {icon}
    </StyledButton>
  );
};

export default ShopRequestGroupTableExpandIcon;
