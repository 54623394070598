import React from 'react';

import { Dropdown as AntDDropdown, DropdownProps } from 'antd';
import styled from 'styled-components';

const StyledDropdown = styled(AntDDropdown)`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const StyledMenu = styled.div`
  .ant-dropdown-menu {
    padding: 0;
    border-radius: 0;
    border: 1px solid var(--line-gray);
    border-bottom: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 155px;

    .ant-dropdown-menu-item {
      font-size: 14px;
      padding: 6px 10px;
      border-bottom: 1px solid var(--line-gray);
    }
  }
`;

const Dropdown = (props: DropdownProps) => {
  return <StyledDropdown dropdownRender={(menu) => <StyledMenu>{menu}</StyledMenu>} {...props} />;
};

Dropdown.Button = ({ ...props }) => <AntDDropdown.Button {...props} />;

export default Dropdown;
