import React, { useEffect, useState } from 'react';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

import { useAccommodationContext } from './AccommodationContext';
import { LivingInfoDataType } from './constants';
import LivingInfoDataTable from './LivingInfoDataTable';
import { mapCommunityLivingInfoDataToRequest, mapCommunityLivingInfoToData } from './utils';
import { STATUS } from '../../../constants';

interface Props {
  livingType: number;
}

const LivingInfoData = ({ livingType }: Props) => {
  const names = useAppSelector((state) => state.global.livingtypes);
  const { communityId, showMessage } = useAccommodationContext();

  const [accommodationData, setAccommodationData] = useState<LivingInfoDataType[]>([]);
  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  useEffect(() => {
    if (communityId) {
      getAccommodationFees();
    }
  }, [communityId]);

  const getAccommodationFees = () => {
    setDataLoading(STATUS.LOADING);
    axios
      .get(`/communities/${communityId}/living-info?living_info_type=${livingType}&active=1`)
      .then((response) => {
        const formatted_data: LivingInfoDataType[] = response?.data.map((record: any) =>
          mapCommunityLivingInfoToData(record)
        );
        setAccommodationData(formatted_data);
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to fetch Community Accommodation Data');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleCreate = (item: Partial<LivingInfoDataType>) => {
    setDataLoading(STATUS.LOADING);

    // Append Living Type Id field not being used in the Table Form
    Object.assign(item, { livingTypeId: livingType });

    const request_data = mapCommunityLivingInfoDataToRequest(item);
    axios
      .post(`/communities/${communityId}/living-info`, [request_data])
      .then((response) => {
        const newCommunityAccommodation: LivingInfoDataType[] = response.data.map((record: any) =>
          mapCommunityLivingInfoToData(record)
        );
        setAccommodationData([...accommodationData, ...newCommunityAccommodation]);
        showMessage('success', 'Accommodation created successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to create Accommodation');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<LivingInfoDataType>) => {
    setDataLoading(STATUS.LOADING);

    // Append Living Type Id field not being used in the Table Form
    Object.assign(item, { livingTypeId: livingType });

    const request_data = mapCommunityLivingInfoDataToRequest(item);

    axios
      .put(`/communities/${communityId}/living-info/${item.id}`, request_data)
      .then((response) => {
        const accommodation_index = accommodationData.findIndex((accommodation) => accommodation.id === item.id);
        accommodationData[accommodation_index] = mapCommunityLivingInfoToData(response.data);
        setAccommodationData([...accommodationData]);
        showMessage('success', 'Accommodation changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to change Accommodation');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<LivingInfoDataType>) => {
    setDataLoading(STATUS.LOADING);
    axios
      .delete(`/communities/${communityId}/living-info/${item.id}`)
      .then((response) => {
        const accommodation_index = accommodationData.findIndex((accomodation) => accomodation.id === item.id);
        accommodationData.splice(accommodation_index, 1);
        setAccommodationData([...accommodationData]);
        showMessage('success', 'Accommodation deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Accommodation');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <LivingInfoDataTable
      loading={dataLoading === STATUS.LOADING}
      data={accommodationData}
      livingTypeName={names[livingType - 1].type}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default LivingInfoData;
