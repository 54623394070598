import React from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from './CommunityViewContext';
import CommunityViewWidget from './create/CommunityViewAddWidget';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Info = styled.div`
  color: var(--text-primary);
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
`;

const Note = styled.div`
  color: var(--gray-dark);
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;

const CommunityViewLayout = () => {
  const { preview } = useCommunityViewContext();

  return (
    <Container>
      <Content>
        <Info>There are no widgets in this view.</Info>
        <Note>
          <span>Use the button below to add and customize analytics widgets.</span>
          <br />
          <span>Group widgets by views for easy access from the sidebar.</span>
        </Note>
        {!preview && <CommunityViewWidget />}
      </Content>
    </Container>
  );
};

export default CommunityViewLayout;
