import React from 'react';

import { format } from 'date-fns';

import { UnitType } from '../../types';

interface Props {
  record: UnitType;
}

const IncentivesTableUnitsArchived = ({ record }: Props) => (
  <span
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {record.archived_at && <span>{format(new Date(record.archived_at), 'MMM dd yyyy')}</span>}
    <span
      style={{
        fontSize: 12,
        color: 'var(--text-secondary)',
      }}
    >
      Deleted
    </span>
  </span>
);

export default IncentivesTableUnitsArchived;
