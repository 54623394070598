import React from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

import { COMMUNITY_TYPE } from '../../../constants';
import { useCommunityViewContext } from '../CommunityViewContext';

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: var(--text-primary);

    &.competitor {
      color: var(--competitor-purple);
    }

    &.terminal {
      color: var(--text-secondary);
    }
  }
`;

interface Props {
  community: any;
}

const CommunityViewHeaderBreadcrumbs = ({ community }: Props) => {
  const { view } = useCommunityViewContext();

  const isCompetitor = community?.type === COMMUNITY_TYPE.COMPETITOR;

  return (
    <StyledBreadcrumb
      items={[
        {
          className: isCompetitor ? 'competitor' : '',
          title: isCompetitor ? 'Competitor' : 'My Community',
        },
        {
          className: 'terminal',
          title: view?.name,
        },
      ]}
      separator="·"
    />
  );
};

export default CommunityViewHeaderBreadcrumbs;
