import React from 'react';
import DatePickerCalendar from 'react-datepicker';

import styled from 'styled-components';

const StyledDatePicker = styled(DatePickerCalendar)`
  font-size: 14px;
  width: 120px;
  padding: 8px 0 7px 12px;
  border: solid 1px var(--line-gray);
  background-color: var(--background);
  border-radius: 0;
  cursor: pointer;
  box-sizing: border-box;
`;

type Props = {
  selectedDate: Date;
  customInput?: React.ReactElement;
  onChange: (value: Date) => void;
};

export const DatePicker = ({ selectedDate, customInput, onChange }: Props) => {
  return (
    <StyledDatePicker
      selected={selectedDate}
      minDate={new Date()}
      showPopperArrow={false}
      customInput={customInput}
      onChange={(date) => onChange(date!)}
      className="date-picker"
    />
  );
};
