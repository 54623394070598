import React from 'react';

import styled from 'styled-components';

import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';

const StyledSelectAdvancedFilter = styled(SelectAdvancedFilter)`
  pointer-events: none;
`;

const HeaderViewFiltersCompare = () => {
  const { community } = useCommunityViewContext();

  const communityOption = [
    {
      label: community?.comunity_name,
      value: community?.id,
    },
  ];

  return (
    <StyledSelectAdvancedFilter
      className="active"
      placeholder={'Compare With'}
      options={communityOption}
      defaultValue={[community?.id]}
      onSelect={() => {
        // Do nothing
      }}
    />
  );
};

export default HeaderViewFiltersCompare;
