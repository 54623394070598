import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: var(--text-primary);
  font-family: var(--font-bold);
  font-size: 18px;
`;

const Subtitle = styled.div`
  color: var(--text-secondary);
  font-size: 12px;
`;

interface Props {
  title: string;
  subtitle: string;
}

const CardHeader = ({ title, subtitle }: Props) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);

export default CardHeader;
