import React from 'react';

import styled from 'styled-components';

import HeaderViewActionsControls from './HeaderViewActionsControls';
import HeaderViewActionsShare from './HeaderViewActionsShare';
import HeaderViewActionsSwot from './HeaderViewActionsSwot';
import { useCommunityViewContext } from '../CommunityViewContext';
import CommunityViewAddWidget from '../create/CommunityViewAddWidget';
const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const HeaderViewActions = () => {
  const { view, preview } = useCommunityViewContext();

  return (
    <Container>
      {view && !view.default && !preview && <CommunityViewAddWidget />}
      <HeaderViewActionsShare />
      <HeaderViewActionsSwot />
      {!preview && <HeaderViewActionsControls />}
    </Container>
  );
};

export default HeaderViewActions;
