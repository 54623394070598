import React, { useState } from 'react';

import styled from 'styled-components';

import EditShopRequestModal from '../EditShopRequestModal/EditShopRequestModal';
import ShopRequestCommunity from '../TableColumns/ShopRequestCommunity';
import ShopRequestsDueDate from '../TableColumns/ShopRequestsDueDate';
import ShopRequestsShopper from '../TableColumns/ShopRequestsShopper';
import ShopRequestsStatus from '../TableColumns/ShopRequestsStatus';
import ShopRequestsUpdatedAt from '../TableColumns/ShopRequestsUpdatedAt';
import { MysteryShopRequestInbound } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  padding-left: 24px;
  background: white;
  border: solid 1px var(--line-gray);
  border-top: 0;

  .due-date-column,
  .updated-at-column,
  .status-column,
  .community-column,
  .shopper-column {
    padding: 10px 0 10px 20px;
  }
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
  refetch: () => void;
};

const ShopRequestGroupTableRow = ({ shopRequest, refetch }: Props) => {
  const [modifyingShopRequest, setModifyingShopRequest] = useState<MysteryShopRequestInbound | null>(null);

  const onClick = () => {
    setModifyingShopRequest(shopRequest);
  };

  return (
    <>
      <Container className="shop-request-group-table-row" onClick={onClick}>
        <ShopRequestCommunity shopRequest={shopRequest} />
        <ShopRequestsShopper shopRequest={shopRequest} />
        <ShopRequestsStatus shopRequest={shopRequest} />
        <ShopRequestsUpdatedAt shopRequest={shopRequest} />
        <ShopRequestsDueDate shopRequest={shopRequest} />
      </Container>
      {modifyingShopRequest && (
        <EditShopRequestModal
          shopRequest={modifyingShopRequest}
          refetchData={refetch}
          onCancel={() => setModifyingShopRequest(null)}
        />
      )}
    </>
  );
};

export default ShopRequestGroupTableRow;
