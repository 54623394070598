import React, { useEffect } from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';

import ShopRequestModalCheckboxes from './ShopRequestModalCheckboxes';
import { useShopRequestModalContext } from './ShopRequestModalContext';
import ShopRequestModalDropdown from './ShopRequestModalDropdown';

const Container = styled.div`
  margin: 0 0 30px 30px;
  display: flex;
  flex-flow: column;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.5px;
  width: 183px;
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 167px;
  font-size: 14px;
  font-weight: 900;
  &&& .ant-checkbox + span {
    padding-inline-start: 10px;
    padding-inline-end: 30px;
    display: flex;
    align-items: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 10px;
`;

const ShopRequestModalBaseCommunitySelect = () => {
  const {
    selectedCompany,
    shopForBaseCommunity,
    selectedBaseCommunity,
    companyCommunities,
    baseCommunityIL,
    baseCommunityAL,
    baseCommunityMC,
    setBaseCommunityIL,
    setBaseCommunityAL,
    setBaseCommunityMC,
    setShopForBaseCommunity,
    setSelectedBaseCommunity,
    setShopRequests,
  } = useShopRequestModalContext();

  useEffect(() => {
    setSelectedBaseCommunity(null);
  }, [selectedCompany]);

  useEffect(() => {
    setShopRequests([]);
  }, [selectedBaseCommunity]);

  const disableCheckboxes = !selectedBaseCommunity || !shopForBaseCommunity;

  return (
    <Container>
      <Row>
        <Title>BASE COMMUNITY</Title>
        <StyledCheckbox
          checked={shopForBaseCommunity}
          onChange={(event: CheckboxChangeEvent) => {
            setShopForBaseCommunity(event.target.checked);
            setBaseCommunityIL(event.target.checked);
            setBaseCommunityAL(event.target.checked);
            setBaseCommunityMC(event.target.checked);
          }}
          disabled={!selectedCompany || !selectedBaseCommunity}
          className={'green'}
        >
          Shop for base community as well
        </StyledCheckbox>
      </Row>
      <Row>
        <ShopRequestModalDropdown
          communities={companyCommunities}
          value={selectedBaseCommunity?.id}
          disabled={!selectedCompany}
          placeholder="Select a community"
          onChange={(value: any) => setSelectedBaseCommunity(value)}
        />
        <ShopRequestModalCheckboxes
          independantLiving={baseCommunityIL && !disableCheckboxes}
          assistedLiving={baseCommunityAL && !disableCheckboxes}
          memoryCare={baseCommunityMC && !disableCheckboxes}
          disabled={disableCheckboxes}
          toggleIndependentLiving={setBaseCommunityIL}
          toggleAssistedLiving={setBaseCommunityAL}
          toggleMemoryCare={setBaseCommunityMC}
        />
      </Row>
    </Container>
  );
};

export default ShopRequestModalBaseCommunitySelect;
