import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import { useShareViaEmailContext } from './ShareViaEmailContext';
import { STATUS } from '../../../constants';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 18px;
    color: var(--community-marker-blue);
  }

  .ant-tabs-tab-btn {
    font-size: 18px;
    color: var(--text-secondary);
  }

  .ant-tabs .ant-tabs-tab {
    padding: 0;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-ink-bar {
    display: none; /* Remove the underline */
  }
`;

const Title = styled.h1`
  font-size: 20px;
  color: var(--text-primary);
`;

const ShareViaEmailHeader = () => {
  const { loading, handleClose } = useShareViaEmailContext();
  return (
    <Header>
      <Title>Share via Email</Title>{' '}
      <Button
        disabled={loading === STATUS.LOADING}
        type="text"
        icon={<CloseOutlined style={{ fontSize: 20, cursor: 'pointer', color: 'var(--gray-dark)' }} />}
        onClick={handleClose}
      />
    </Header>
  );
};

export default ShareViaEmailHeader;
