import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { REPEAT_LABELS } from '../constants';
import { MysteryShopRequestInbound } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const Title = styled(Typography.Text)`
  font-weight: 900;
  height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
`;

const StyledSubTitle = styled(Typography.Text)`
  color: var(--text-secondary);
  font-size: 12px;
  height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
};

const ShopRequestCommunity = ({ shopRequest }: Props) => {
  return (
    <Container className="community-column">
      <Title>{shopRequest.community?.name || shopRequest.name}</Title>
      <StyledSubTitle>
        {REPEAT_LABELS[shopRequest.repeat] ? `${REPEAT_LABELS[shopRequest.repeat]} · ` : ''}
        {shopRequest.requestGroup?.company ? shopRequest.requestGroup.company.name : 'Further'}
      </StyledSubTitle>
    </Container>
  );
};

export default ShopRequestCommunity;
