import React from 'react';

import styled from 'styled-components';

import MapMarkerIcon from './MapMarkerIcon';
import MapMarkerInfo from './MapMarkerInfo';

interface Props {
  data: any;
  lat: number | string;
  lng: number | string;
}

const Container = styled.div`
  &:hover {
    .marker-info {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const MapMarker = ({ data }: Props) => {
  return (
    <Container>
      <MapMarkerIcon type={data.apartmenttype} />
      <MapMarkerInfo data={data} />
    </Container>
  );
};

export default MapMarker;
