import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { getViews } from '../../apis/ViewAPI';
import { ACTOR_TYPE } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  handleSetSelectedCommunity,
  handleSetSelectedManagementCompany,
  handleStoreUserViews,
} from '../../store/slices/globalSlice';

interface Props {
  communityId: number;
}

const CommunityLoad = ({ communityId }: Props) => {
  const dispatch = useAppDispatch();
  const { managementCompanyList, communityList, communityCompetitorsList } = useAppSelector((state) => state.global);

  useEffect(() => {
    if (communityId) {
      const allCommunities = [...communityList, ...communityCompetitorsList];
      const community = allCommunities.find((item) => item.id === communityId);

      if (!community) {
        return;
      }

      const managementCompany = managementCompanyList.find((item) => item.id === community.management_company_id);
      const selectedCommunity = {
        id: community.id,
        name: community.community.apartmentname,
        type:
          community.community.apartmenttype === ACTOR_TYPE.COMPETITOR ? ACTOR_TYPE.COMPETITOR : ACTOR_TYPE.COMMUNITY,
        address: community.community.apartmentaddress,
        parentId: community.parent_id,
      };

      if (managementCompany) {
        dispatch(handleSetSelectedManagementCompany(managementCompany));
      }

      dispatch(handleSetSelectedCommunity(selectedCommunity));
    }
  }, [communityId, communityList, communityCompetitorsList]);

  useEffect(() => {
    if (communityId) {
      const fetchCommunityViews = async () => {
        return await getViews(communityId, {});
      };

      fetchCommunityViews().then((response: any) => {
        dispatch(handleStoreUserViews(response));
      });
    }
  }, [communityId]);

  return <Outlet />;
};

export default CommunityLoad;
