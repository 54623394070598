import React, { useState } from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import Button from 'components/lib/Button';

import NoteModal from '../NoteModal/NoteModal';

const Title = styled(Typography.Text)`
  font-weight: 900;
`;

const EditButton = styled(Button)`
  color: var(--link-blue);
  cursor: pointer;
  font-weight: 500;
  margin-left: 2px;
  padding: 0;
  box-shadow: none;
`;

const Note = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
  width: 260px;
  word-break: break-word;
  white-space: pre-wrap;
`;

type Props = {
  note?: string | null;
  style?: any;
  title: string;
  allowEdit: boolean;
  disabled?: boolean;
  updateNote: (note: string) => void;
};

const EditShopRequestModalNote = ({ note, style, title, allowEdit, disabled = false, updateNote }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openNoteEditing = () => setModalOpen(true);

  return (
    <>
      <div style={style}>
        <Title>{title}</Title>
        {allowEdit && (
          <>
            ·
            <EditButton size="small" type="link" disabled={disabled} onClick={openNoteEditing}>
              Edit
            </EditButton>
          </>
        )}
      </div>
      <Note>{note}</Note>
      {isModalOpen && (
        <NoteModal
          title="Edit Note"
          note={note}
          isModalOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          modifyNote={updateNote}
        />
      )}
    </>
  );
};

export default EditShopRequestModalNote;
