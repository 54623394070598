import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm } from 'antd';
import clsx from 'clsx';
import { format } from 'date-fns';

import classes from './styles.module.scss';

import '@react-pdf-viewer/core/lib/styles/index.css';

interface Props {
  img: any;
  imagePath: string;
  handleDeleteData: (img: any, type: any) => void;
}

const BrochureFileComp = ({ img, imagePath, handleDeleteData }: Props) => {
  return (
    <Card
      key={img.id}
      size="small"
      title=""
      headStyle={{ height: '30px' }}
      bodyStyle={{ padding: 0 }}
      style={{
        width: 300,
        height: 200,
        borderRadius: 8,
        position: 'relative',
      }}
      className={clsx('box_shadow', classes.item_card)}
    >
      <Popconfirm
        title="Delete"
        description="Are you sure to delete?"
        onConfirm={() => handleDeleteData(img, 'file')}
        onOpenChange={() => console.log('open change')}
      >
        <Button
          icon={<CloseOutlined />}
          type="default"
          size="small"
          danger
          shape="circle"
          style={{
            position: 'absolute',
            top: -10,
            right: -10,
            zIndex: 99,
          }}
          className={classes.closeBtn}
        />
      </Popconfirm>
      {img.file_based_on === 'upload' ? (
        <div
          style={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <a href={`${imagePath}${img?.file_path}`} target="_blank" rel="noreferrer">
            <svg width="80px" height="80px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#494c4e"
                d="M10 7H8c-.553 0-1-.448-1-1s.447-1 1-1h2c.553 0 1 .448 1 1s-.447 1-1 1zM15 11H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1zM15 15H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z"
              />
              <path
                fill="#494c4e"
                d="M20.71 4.29l-4-4C16.52.1 16.26 0 16 0H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-.26-.1-.52-.29-.71zM16 2.42L18.58 5H16.5c-.28 0-.5-.22-.5-.5V2.42zm3 19.08c0 .28-.22.5-.5.5h-13c-.28 0-.5-.22-.5-.5v-19c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5V5c0 1.1.9 2 2 2h2.5c.28 0 .5.22.5.5v14z"
              />
              <path fill="#494c4e" d="M15 19H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z" />
            </svg>
          </a>
          <a
            href={`${imagePath}${img?.file_path}`}
            target="_blank"
            rel="noreferrer"
            style={{
              textAlign: 'center',
              width: '200px',
              wordWrap: 'break-word',
            }}
          >
            {img.file_name}
          </a>
          <p>{format(new Date(img?.updated_at), 'MM-dd-yyyy')}</p>
        </div>
      ) : (
        <div
          style={{
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}
        >
          <a href={`${img?.file_url}`} target="_blank" rel="noreferrer">
            <svg width="80px" height="80px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#494c4e"
                d="M10 7H8c-.553 0-1-.448-1-1s.447-1 1-1h2c.553 0 1 .448 1 1s-.447 1-1 1zM15 11H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1zM15 15H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z"
              />
              <path
                fill="#494c4e"
                d="M20.71 4.29l-4-4C16.52.1 16.26 0 16 0H5C3.9 0 3 .9 3 2v20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-.26-.1-.52-.29-.71zM16 2.42L18.58 5H16.5c-.28 0-.5-.22-.5-.5V2.42zm3 19.08c0 .28-.22.5-.5.5h-13c-.28 0-.5-.22-.5-.5v-19c0-.28.22-.5.5-.5h8c.28 0 .5.22.5.5V5c0 1.1.9 2 2 2h2.5c.28 0 .5.22.5.5v14z"
              />
              <path fill="#494c4e" d="M15 19H8c-.553 0-1-.448-1-1s.447-1 1-1h7c.553 0 1 .448 1 1s-.447 1-1 1z" />
            </svg>
          </a>
          <a
            href={`${img?.file_url}`}
            target="_blank"
            rel="noreferrer"
            style={{
              textAlign: 'center',
              width: '200px',
              wordWrap: 'break-word',
            }}
          >
            {img.file_name}
          </a>
          <p>{format(new Date(img?.updated_at), 'MM-dd-yyyy')}</p>
        </div>
      )}
    </Card>
  );
};

export default BrochureFileComp;
