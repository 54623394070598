import React from 'react';

import styled from 'styled-components';

import AccommodationFormOnboardingData from './AccommodationFormOnboardingData';
import StepNavigationBottom from '../common/StepNavigationBottom';

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AccommodationFormOnboarding = ({ current, setCurrent }: Props) => (
  <Container>
    <AccommodationFormOnboardingData />
    <StepNavigationBottom current={current} setCurrent={setCurrent} />
  </Container>
);

export default AccommodationFormOnboarding;
