import React from 'react';

import { format } from 'date-fns';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { HOUSING_CONTRACT_STATUS } from '../../constants';
import { UnitType } from '../../types';

const PositiveStatus = styled.span`
  font-size: 12px;
  color: var(--positive-green);
`;

const NegativeStatus = styled.span`
  font-size: 12px;
  color: var(--negative-orange);
`;

interface Props {
  unit: UnitType;
}

const IncentivesTableUnitsUnitStatus = ({ unit }: Props) => {
  const housingContracts = unit?.housing_contracts;
  const latestHousingContract = housingContracts?.length ? housingContracts[0] : undefined;
  const latestHousingContractStatus = latestHousingContract?.status;
  const latestHousingContractMoveOutDate = latestHousingContract?.move_out_date;

  return (
    <>
      {latestHousingContractStatus === HOUSING_CONTRACT_STATUS.CURRENT && <NegativeStatus>Occupied</NegativeStatus>}
      {latestHousingContractStatus === HOUSING_CONTRACT_STATUS.NOTICE && (
        <PositiveStatus>
          Vacant {latestHousingContractMoveOutDate && format(new Date(latestHousingContractMoveOutDate), 'MMM dd')}
        </PositiveStatus>
      )}
      {latestHousingContractStatus === HOUSING_CONTRACT_STATUS.FUTURE && <NegativeStatus>Reserved</NegativeStatus>}
      {latestHousingContractStatus === HOUSING_CONTRACT_STATUS.PRIOR && (
        <PositiveStatus>
          <span>Vacant for </span>
          <span>
            {latestHousingContractMoveOutDate && dayjs().diff(dayjs(latestHousingContractMoveOutDate), 'days')}
          </span>
          <span> days</span>
        </PositiveStatus>
      )}
      {!latestHousingContractStatus && <PositiveStatus>Vacant</PositiveStatus>}
    </>
  );
};

export default IncentivesTableUnitsUnitStatus;
