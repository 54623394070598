export const FILE_EXTENSION = {
  PNG: 'png',
  PDF: 'pdf',
};

export const CHART_TYPE = {
  BAR: 'Bar',
  LINE: 'Line',
};

export const LINE_CHART_DATA_OFFSET_FACTOR = 0.1;

export const LABEL_FONT_SIZE = 10;
export const LABEL_FONT_FAMILY = 'AvenirLTPro-Medium';
export const LABEL_FONT_FAMILY_COMMUNITY = 'AvenirLTPro-Heavy';
export const LABEL_FONT_COLOR = '#1d252d';

export const COLOR_DEFAULT = 'l(0) 0:#999 1:#000';
export const COLOR_BORDER_DEFAULT = '#000';
export const COLOR_PALETTES = {
  GRADIENTS: [
    'l(0) 0:#9898ff 1:#5f5fff',
    'l(0) 0:#47e9ea 1:#22cfd1',
    'l(0) 0:#ff94c9 1:#ff5b98',
    'l(0) 0:#17dcae 1:#0ab776',
    'l(0) 0:#fdad88 1:#fa7550',
    'l(0) 0:#b6b6b6 1:#7f7f7f',
    'l(0) 0:#25549a 1:#102a61',
    'l(0) 0:#ffe500 1:#ffc600',
    'l(0) 0:#42afdb 1:#1f77b4',
    'l(0) 0:#dfe047 1:#bcbd22',
    'l(0) 0:#e4d8ec 1:#c5b0d5',
    'l(0) 0:#c08f82 1:#8c564b',
    'l(0) 0:#ed4f51 1:#d62728',
    'l(0) 0:#f3afe3 1:#e377c2',
    'l(0) 0:#ffdfb0 1:#ffbb78',
  ],
  BORDERS: [
    '#5f5fff',
    '#22cfd1',
    '#ff5b98',
    '#0ab776',
    '#fa7550',
    '#7f7f7f',
    '#102a61',
    '#ffc600',
    '#1f77b4',
    '#bcbd22',
    '#c5b0d5',
    '#8c564b',
    '#d62728',
    '#e377c2',
    '#ffbb78',
  ],
};

export const MIN_BAR_WIDTH = 15;
export const MAX_BAR_WIDTH = 15;

export const INTERVAL_PADDING = 16;
