import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Table } from 'antd';
import styled from 'styled-components';

import ComparePhotosModal from './ComparePhotosModal';
import { COMMUNITY_NAME_COLUMN, IMAGE_UPLOAD_SOURCE } from './constants';
import PhotosThumbnail from './PhotosThumbnail';
import { getCommunityImages } from '../../../apis/CommunityAPI';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-right: 15px;
`;

const HeaderTitleWrapper = styled.div`
  margin: 0;
  font-size: 10px;
  text-align: center;
  font-weight: 900;
`;

const StyledTable = styled(Table)`
  width: 100%;
  &&&&&&&&& {
    tr {
      background-color: #fff;
      font-size: 10px;
    }

    td {
      padding: 0;
      height: 56px;
    }

    th {
      background-color: white;
      color: var(--text-tertiary);
      font-family: var(--font-bold);
      font-size: 10px;

      &.ant-table-cell {
        padding: 10px;
      }
      &.ant-table-cell::before {
        background-color: transparent;
      }
    }

    .ant-table-cell {
      padding: 0px;
    }
  }
`;
const CommunityTitle = styled.span`
  color: var(--text-primary);
  &.community {
    font-family: var(--font-bold);
  }
`;

interface Props {
  communityId: number;
  competitors: number[];
}

const Photos = ({ communityId, competitors }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const [selection, setSelection] = useState<{
    record: any | undefined;
    column: string | undefined;
  }>({
    record: undefined,
    column: undefined,
  });

  const { data, isLoading } = useQuery({
    queryKey: ['photos', communityId, competitors],
    queryFn: () =>
      getCommunityImages(communityId, {
        competitors,
      }),
    select: (data) => {
      const headerData: any[] = data.payload?.Header;
      const categories: any[] = data.payload?.Combination;
      const imagesData: any[] = data.payload?.Data;

      const tableImageColumns: any = categories?.map((column: any) => ({
        title: <HeaderTitleWrapper>{column?.image_category}</HeaderTitleWrapper>,
        dataIndex: column?.image_category,
        key: column?.Combination_Id,
        onCell: (record: any, rowIndex: number) => {
          return {
            onClick: (ev: any) => {
              setSelection({ record, column: column?.image_category });
              setOpen(true);
            },
          };
        },
        render: (value: any) => {
          if (value && value.length) {
            return <PhotosThumbnail images={value} />;
          }
        },
      }));

      const tableNameColumn = {
        title: ' ',
        dataIndex: COMMUNITY_NAME_COLUMN.FIELD,
        key: COMMUNITY_NAME_COLUMN.ID,
        width: 200,
        render: (value: any, row: any) => {
          if (value) {
            return (
              <CommunityTitle className={row.communityType == 'apartment' ? 'community' : ''}>{value}</CommunityTitle>
            );
          }
        },
      };

      const tableColumns = [tableNameColumn, ...tableImageColumns];

      const updatedData: any = headerData.map((header) => {
        const communityImageData = categories.reduce((prev, cur) => ({ ...prev, [cur.image_category]: [] }), {});

        imagesData
          .filter((imageData) => imageData.CommunityId === header.CommunityID)
          .forEach((imageData) => {
            if (imageData.image_category in communityImageData) {
              const imgUrl =
                imageData.image_based_on === IMAGE_UPLOAD_SOURCE
                  ? `${data.BaseURL}/media/${imageData?.image_path}`
                  : imageData?.image_url;
              communityImageData[imageData.image_category].push(imgUrl);
            }
          });

        communityImageData[COMMUNITY_NAME_COLUMN.FIELD] = header.CommunityName;
        communityImageData[COMMUNITY_NAME_COLUMN.TYPE] = header.CommunityType;

        return communityImageData;
      });

      return { columns: tableColumns, data: updatedData };
    },
    refetchOnWindowFocus: false,
  });

  // @ts-ignore
  return (
    <Container>
      <ComparePhotosModal
        open={open}
        columns={data?.columns}
        data={data?.data}
        selectedData={selection}
        handleClose={() => {
          setOpen(false);
        }}
      />

      <StyledTable
        columns={data?.columns}
        dataSource={data?.data}
        loading={isLoading}
        pagination={false}
        rowKey={(record: any) => record?.communityName}
      />
    </Container>
  );
};

export default Photos;
