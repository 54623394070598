import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import Button from 'components/lib/Button';

import NoteModal from '../NoteModal/NoteModal';

const Container = styled(Button)`
  border: solid 1px;
  border-radius: 30px;
  width: 16px;
  height: 16px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  margin-left: -13px;
  z-index: 1;
`;

const PlusButton = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: var(--button-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 6px;
  left: 8px;
`;

type Props = {
  note?: string | null;
  title?: string;
  okButtonDisabled?: boolean;
  isModalOpen: boolean;
  modifyNote: (note: string) => void;
  setModalOpen: (isOpen: boolean) => void;
  onCloseModal: () => void;
};

const EditNoteButton = ({
  note,
  title,
  okButtonDisabled,
  isModalOpen,
  modifyNote,
  setModalOpen,
  onCloseModal,
}: Props) => {
  const closeModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCloseModal();
  };

  return (
    <Container
      className={classNames('note-button-container', { 'shop-request-add-note': note?.length })}
      style={{
        borderColor: note?.length ? 'black' : 'var(--button-blue)',
      }}
      onClick={() => setModalOpen(true)}
    >
      <img
        src={note?.length ? '/icons/edit-note-black.svg' : '/icons/edit-note-blue.svg'}
        height={12}
        width={12}
        alt="edit note"
      />
      {!note?.length && (
        <PlusButton>
          <img src="/icons/add.svg" height={10} width={10} alt="add" />
        </PlusButton>
      )}
      {isModalOpen && (
        <NoteModal
          title={title || 'Edit Note'}
          note={note}
          isModalOpen={isModalOpen}
          okButtonDisabled={okButtonDisabled}
          closeModal={closeModal}
          modifyNote={modifyNote}
        />
      )}
    </Container>
  );
};

export default EditNoteButton;
