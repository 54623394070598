import React from 'react';

import styled from 'styled-components';

import { INCENTIVES_SAVINGS_DISCOUNT_TYPE } from './constants';
import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import { calculateTotalSavings } from '../helpers';
import { IncentiveSavingType, UnitType } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalSavings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding-bottom: 10px;
  color: var(--text-primary);
`;

const Count = styled.div``;

const IncentivesCreateActionsOverview = () => {
  const { savings, units } = useIncentivesCreateModalContext();

  const amountSavings = savings.filter(
    (item: IncentiveSavingType) => item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT
  );
  const showTotalSavings = savings.every(
    (item: IncentiveSavingType) => item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT
  );

  const showUnitWithIncentivesInfo = units.some((item: UnitType) => item.incentives.length > 0);
  return (
    <Container>
      {showTotalSavings && (
        <TotalSavings>
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <span style={{ fontSize: 16, lineHeight: '16px' }}>$</span>
            <span style={{ fontSize: 36, lineHeight: '36px' }}>{calculateTotalSavings(amountSavings)}</span>
          </div>
          <div style={{ fontSize: 16, marginLeft: 5 }}>Total Savings</div>
        </TotalSavings>
      )}
      <Count>
        {units?.length} <span style={{ color: 'var(--text-secondary)' }}>units selected for this incentive.</span>
      </Count>
      {showUnitWithIncentivesInfo && (
        <span style={{ color: 'var(--text-secondary)', fontSize: 12 }}>
          Some selected units <span style={{ color: 'var(--negative-orange)' }}>already have incentives</span>. When
          sending proposals, you will be able to choose which incentive to send.
        </span>
      )}
    </Container>
  );
};

export default IncentivesCreateActionsOverview;
