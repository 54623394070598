import React from 'react';

import { ContentContainer } from './IncentivesProposalModalInfo';
import { useIncentivesContext } from '../IncentivesContext';

const IncentivesProposalModalInfoCareTyoe = () => {
  const { selectedProposalUnit } = useIncentivesContext();

  return (
    <ContentContainer>
      <div style={{ fontFamily: 'var(--font-bold)' }}>Care Type</div>
      <span>{selectedProposalUnit?.care_type}</span>
    </ContentContainer>
  );
};

export default IncentivesProposalModalInfoCareTyoe;
