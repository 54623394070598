import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';

const HeaderViewFiltersCompetitors = () => {
  const { viewFilters, competitors, setViewFilters } = useCommunityViewContext();

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, competitors: values.map((item: any) => item.value) }));
  };

  const options = competitors?.map((item) => ({
    label: item.comunity_name,
    value: item.id,
    color: item.color,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: viewFilters?.competitors?.length > 0 })}
      placeholder={'Competitors'}
      options={options}
      defaultValue={viewFilters.competitors}
      onSelect={handleSelect}
    />
  );
};

export default HeaderViewFiltersCompetitors;
