import React from 'react';

import { DatePicker, Form, Input, InputNumber, Select, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { DEPENDENCY_TYPE, EditableTableDataType } from './constants';
import { GLOBAL_DATE_FORMAT, INPUT_TYPES } from '../../constants';

const { TextArea } = Input;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  options: DefaultOptionType[];
  title: any;
  required?: boolean;
  inputType: string;
  record: EditableTableDataType;
  index: number;
  disableTooltip?: boolean;
  handleChange?: (_: any) => void;
  validator?: (_: any) => void;
  dependencies?: string[];
  dependencyType?: string;
  children: React.ReactNode;
  calculatedDataIndex?: string;
  calculated: (_: any, dataIndex: string) => void;
}

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditableCell = ({
  editing,
  dataIndex,
  required,
  title,
  inputType,
  record,
  index,
  options,
  children,
  handleChange = () => {
    return;
  },
  validator = async () => {
    return;
  },
  dependencies,
  dependencyType,
  disableTooltip = false,
  calculatedDataIndex,
  calculated,

  ...restProps
}: EditableCellProps) => {
  const formInstance = Form.useFormInstance();

  let label = children;

  // Utilizing 'dependencies' in different manner than expected to ensure data validity by disabling competing inputs
  // Dependencies still used to update individual related Field.Item instances
  const dependenciesFields = formInstance.getFieldsValue(dependencies ?? []);
  const dependenciesValues = Object.values(dependenciesFields);
  const excludeDependencyCallback =
    dependencyType === DEPENDENCY_TYPE.EXCLUDE && dependenciesValues.some((value) => !!value);
  const includeDependencyCallback =
    dependencyType === DEPENDENCY_TYPE.INCLUDE && dependenciesValues.every((value) => !value);
  const excludeCalculatedDependencyCallback = calculatedDataIndex == dataIndex;
  calculated && calculated(formInstance, dataIndex);
  const disabled =
    (dependencies && (excludeDependencyCallback || includeDependencyCallback)) || excludeCalculatedDependencyCallback;

  let inputNode = <InputNumber disabled={disabled} style={{ width: '100%' }} />;

  switch (inputType) {
    case INPUT_TYPES.NUMBER_POSITIVE:
      inputNode = <InputNumber disabled={disabled} min={0} style={{ width: '100%' }} />;
      break;
    case INPUT_TYPES.TEXTAREA:
      inputNode = <TextArea disabled={disabled} placeholder="Note..." autoSize={{ minRows: 1, maxRows: 4 }} />;
      break;
    case INPUT_TYPES.TEXT:
      inputNode = <Input disabled={disabled} />;
      break;
    case INPUT_TYPES.CURRENCY:
      inputNode = <InputNumber disabled={disabled} addonBefore="$" min={0} style={{ width: '100%' }} />;
      label = <>${children}</>;
      break;
    case INPUT_TYPES.DATE: {
      inputNode = (
        <DatePicker disabled={disabled} defaultValue={dayjs()} format={GLOBAL_DATE_FORMAT} style={{ width: '100%' }} />
      );
      // @ts-ignore
      const stringDate = record && record[dataIndex] && record[dataIndex].format(GLOBAL_DATE_FORMAT);
      label = stringDate && <span>{stringDate}</span>;
      break;
    }
    case INPUT_TYPES.SELECT: {
      // @ts-ignore
      inputNode = (
        <Select
          disabled={disabled}
          showSearch
          options={options}
          filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
          onChange={() => handleChange(formInstance)}
        />
      );
      // @ts-ignore
      const selectedOption = options.find((option) => option.value === record[dataIndex]);
      label = <span>{record && selectedOption && selectedOption.label}</span>;

      break;
    }
  }

  const getTooltipContent = () => {
    return (
      <TooltipContent>
        <span>Created By: {record?.createdBy}</span>
        <span>Created Date: {dayjs(record?.createdAt).format(GLOBAL_DATE_FORMAT)}</span>
        <span>Updated By: {record?.updatedBy}</span>
        <span>Updated Date: {dayjs(record?.updatedAt).format(GLOBAL_DATE_FORMAT)}</span>
      </TooltipContent>
    );
  };

  const tooltipHasContent = record && record?.updatedBy && record?.updatedAt;
  const hideTooltip = disableTooltip || !tooltipHasContent || editing;
  const tooltipTitle = hideTooltip ? '' : getTooltipContent;

  const rules = [
    {
      required: required,
      message: `Please Input ${title}!`,
    },
    {
      validator: async () => {
        const currentValues = formInstance.getFieldsValue();
        const payload = Object.assign({}, currentValues, { id: record.id, key: record.key });
        return validator(payload);
      },
    },
  ];

  return (
    <Tooltip title={tooltipTitle} placement="topLeft" color="blue">
      <td {...restProps}>
        {!editing && label}
        {editing && (
          <Form.Item name={dataIndex} rules={rules} dependencies={dependencies}>
            {inputNode}
          </Form.Item>
        )}
      </td>
    </Tooltip>
  );
};

export default EditableCell;
