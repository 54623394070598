export const TITLE_ARR = ['Community Fees', 'Accommodation', 'Care Fees', 'Incentives', 'Occupancies', 'Shop Notes'];
export const SUBTITLE_ARR = ['Independent Living', 'Assisted Living', 'Memory Care'];
export const CARE_FEES = [
  'fee_type',
  'level_1',
  'level_2',
  'level_3',
  'level_4',
  'level_5',
  'level_6',
  'level_7',
  'level_8',
  'medication_management_fee',
  'average_care_cost',
  'fee_note',
];
export const COMMUNITY_FEES = ['community_fee', 'pet_fee', 'second_person_fee', 'respite_fee', 'additional_fee'];
export const COMMUNITY_FIELDS = [
  'address',
  'website',
  'google_rating',
  'google_reviews',
  'accommodation_updated_on',
  'last_fees_&_care_updated_date',
];

export const ACCOMODATION = ['sq_footage', 'price_per_sq'];

export const MODAL_TYPES = {
  PDF: 'pdf',
  EMAIL: 'email',
};

export const MODAL_WIDTH = 800;

export const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const FILE_EXTENSION = '.xlsx';

export const DATE_COLUMNS = ['accommodation_updated_on', 'last_fees_&_care_updated_date'];
