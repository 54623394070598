import React from 'react';

import styled from 'styled-components';

import { STEPS } from './constants';
import SwotCompetitorAnalysisActionOptionsList from './SwotCompetitorAnalysisActionOptionsList';
import SwotCompetitorAnalysisCompetitorsOptionsList from './SwotCompetitorAnalysisCompetitorsOptionsList';
import { useSwotCompetitorAnalysisContext } from './SwotCompetitorAnalysisContext';

const Container = styled.div`
  padding-left: 30px;
`;

const SwotCompetitorAnalysisModalBody = () => {
  const { step } = useSwotCompetitorAnalysisContext();

  const showCompetitors = step == STEPS.COMPETITOR_SELECTOR;

  return (
    <Container>
      {!showCompetitors && <SwotCompetitorAnalysisActionOptionsList />}
      {showCompetitors && <SwotCompetitorAnalysisCompetitorsOptionsList />}
    </Container>
  );
};

export default SwotCompetitorAnalysisModalBody;
