import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { EXPORTABLE_PAGE_CLASS } from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  children: ReactNode;
}

const ExportableContentPages = ({ children }: Props) => (
  <Container className={EXPORTABLE_PAGE_CLASS}>{children}</Container>
);

export default ExportableContentPages;
