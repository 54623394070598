import React from 'react';

import { Checkbox } from 'antd';
import styled from 'styled-components';

import { User } from './types';
import { getUsernameInitials } from './utils';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserIntials = styled.span`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${(props) => props.color}AA;
  background-color: ${(props) => props.color}33;
  color: ${(props) => props.color};
  font-size: 6px;
  font-weight: 500;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  text-transform: uppercase;
`;

const CheckBox = styled(Checkbox)`
  margin-bottom: 10px;
`;
const Paragraph = styled.p`
  margin-bottom: 0px;
`;

const Text = styled.span`
  color: #89919d;
  margin-left: 5px;
`;

interface Props {
  user: User;
  checked?: boolean;
  handleChange: (id: number) => void;
}

const ShareViaEmailUsers = ({ user, checked = false, handleChange }: Props) => {
  const initials = getUsernameInitials(user.name).slice(0, 2);

  return (
    <>
      <CheckBox checked={checked} onChange={() => handleChange(user.id)}>
        <FlexContainer>
          <UserIntials color={user?.color}>{initials}</UserIntials>
          <span>{user.name}</span>
          <Paragraph>
            <Text> {user.email}</Text>
          </Paragraph>
        </FlexContainer>
      </CheckBox>
      <br />
    </>
  );
};

export default ShareViaEmailUsers;
