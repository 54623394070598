import React from 'react';

import styled from 'styled-components';

import Button from '../../../lib/Button';
import Notification from '../../../Notification/Notification';
import { useIncentivesDataContext } from '../../IncentivesDataContext';

const SelectorControl = styled.div`
  position: absolute;
  z-index: 10;
  right: 10px;
`;

const IncentivesTableSelectorControl = () => {
  const { items, selectionMode, setSelectionMode, selectedItemsIds, setSelectedItemsIds, setArchivedItemsIds } =
    useIncentivesDataContext();

  const handleCancelSelection = () => {
    setSelectedItemsIds([]);
    setArchivedItemsIds([]);
  };

  const handleSelectionModeChange = () => {
    setSelectionMode((prev: boolean) => !prev);
    setSelectedItemsIds([]);
    setArchivedItemsIds([]);
  };

  return (
    <SelectorControl>
      <Notification
        totalCount={items.length}
        selectedCount={selectedItemsIds.length}
        singularLabel={'incentive'}
        multipleLabel={'incentives'}
        onClose={handleCancelSelection}
        actions={
          <Button
            style={{
              color: 'var(--negative-orange)',
              borderColor: 'var(--negative-orange)',
              backgroundColor: 'var(--light-orange)',
            }}
            onClick={() => setArchivedItemsIds(selectedItemsIds)}
          >
            Archive Multiple
          </Button>
        }
      />
      <Button
        style={{
          display: 'flex',
          width: 36,
          height: 36,
          padding: 7,
          alignItems: 'center',
          borderColor: selectionMode ? 'var(--button-blue)' : 'initial',
          backgroundColor: selectionMode ? 'var(--light-blue)' : 'initial',
        }}
        onClick={handleSelectionModeChange}
      >
        <img
          src={selectionMode ? '/icons/checklist-blue.svg' : '/icons/checklist.svg'}
          alt="TalkFurther Pricing Assistant"
          style={{
            width: 24,
            height: 24,
          }}
        />
      </Button>
    </SelectorControl>
  );
};

export default IncentivesTableSelectorControl;
