export const TITLE_ARR = ['Community Fees', 'Accommodation', 'Care Fees', 'Incentives', 'Occupancies', 'Shop Notes'];
export const CARE_FEES = [
  'fee_type',
  'level_1',
  'level_2',
  'level_3',
  'level_4',
  'level_5',
  'level_6',
  'level_7',
  'level_8',
  'medication_management_fee',
  'fee_note',
  'average_care_cost',
];

export const CITY_OPTIONS = [
  {
    label: 'New York',
    value: 'New York',
  },
  {
    label: 'Buffalo',
    value: 'Buffalo',
  },
  {
    label: 'Rochester',
    value: 'Rochester',
  },
  {
    label: 'Yonkers',
    value: 'Yonkers',
  },
  {
    label: 'Syracuse',
    value: 'Syracuse',
  },
  {
    label: 'West Chester',
    value: 'West Chester',
  },
  {
    label: 'Affton',
    value: 'Affton',
  },
  {
    label: 'Albany',
    value: 'Albany',
  },
  {
    label: 'Liverpool',
    value: 'Liverpool',
  },
  {
    label: 'Allison Park',
    value: 'Allison Park',
  },
  {
    label: 'New Rochelle',
    value: 'New Rochelle',
  },
  {
    label: 'Mount Vernon',
    value: 'Mount Vernon',
  },
  {
    label: 'Schenectady',
    value: 'Schenectady',
  },
  {
    label: 'Utica',
    value: 'Utica',
  },
  {
    label: 'White Plains',
    value: 'White Plains',
  },
  {
    label: 'Hempstead',
    value: 'Hempstead',
  },
  {
    label: 'Troy',
    value: 'Troy',
  },
  {
    label: 'Niagara Falls',
    value: 'Niagara Falls',
  },
  {
    label: 'Binghamton',
    value: 'Binghamton',
  },
  {
    label: 'Freeport',
    value: 'Freeport',
  },
  {
    label: 'Valley Stream',
    value: 'Valley Stream',
  },
  {
    label: 'Los Angeles',
    value: 'Los Angeles',
  },
  {
    label: 'San Diego',
    value: 'San Diego',
  },
  {
    label: 'San Jose',
    value: 'San Jose',
  },
  {
    label: 'San Francisco',
    value: 'San Francisco',
  },
  {
    label: 'Fresno',
    value: 'Fresno',
  },
  {
    label: 'Sacramento',
    value: 'Sacramento',
  },
  {
    label: 'Long Beach',
    value: 'Long Beach',
  },
  {
    label: 'Oakland',
    value: 'Oakland',
  },
  {
    label: 'Bakersfield',
    value: 'Bakersfield',
  },
  {
    label: 'Anaheim',
    value: 'Anaheim',
  },
  {
    label: 'Santa Ana',
    value: 'Santa Ana',
  },
  {
    label: 'Riverside',
    value: 'Riverside',
  },
  {
    label: 'Stockton',
    value: 'Stockton',
  },
  {
    label: 'Chula Vista',
    value: 'Chula Vista',
  },
  {
    label: 'Irvine',
    value: 'Irvine',
  },
  {
    label: 'Fremont',
    value: 'Fremont',
  },
  {
    label: 'San Bernardino',
    value: 'San Bernardino',
  },
  {
    label: 'Modesto',
    value: 'Modesto',
  },
  {
    label: 'Fontana',
    value: 'Fontana',
  },
  {
    label: 'Oxnard',
    value: 'Oxnard',
  },
  {
    label: 'Moreno Valley',
    value: 'Moreno Valley',
  },
  {
    label: 'Huntington Beach',
    value: 'Huntington Beach',
  },
  {
    label: 'Glendale',
    value: 'Glendale',
  },
  {
    label: 'Santa Clarita',
    value: 'Santa Clarita',
  },
  {
    label: 'Garden Grove',
    value: 'Garden Grove',
  },
  {
    label: 'Oceanside',
    value: 'Oceanside',
  },
  {
    label: 'Rancho Cucamonga',
    value: 'Rancho Cucamonga',
  },
  {
    label: 'Santa Rosa',
    value: 'Santa Rosa',
  },
  {
    label: 'Ontario',
    value: 'Ontario',
  },
  {
    label: 'Lancaster',
    value: 'Lancaster',
  },
  {
    label: 'Elk Grove',
    value: 'Elk Grove',
  },
  {
    label: 'Corona',
    value: 'Corona',
  },
  {
    label: 'Palmdale',
    value: 'Palmdale',
  },
  {
    label: 'Salinas',
    value: 'Salinas',
  },
  {
    label: 'Pomona',
    value: 'Pomona',
  },
  {
    label: 'Hayward',
    value: 'Hayward',
  },
  {
    label: 'Escondido',
    value: 'Escondido',
  },
  {
    label: 'Torrance',
    value: 'Torrance',
  },
  {
    label: 'Sunnyvale',
    value: 'Sunnyvale',
  },
  {
    label: 'Orange',
    value: 'Orange',
  },
  {
    label: 'Fullerton',
    value: 'Fullerton',
  },
  {
    label: 'Pasadena',
    value: 'Pasadena',
  },
  {
    label: 'Thousand Oaks',
    value: 'Thousand Oaks',
  },
  {
    label: 'Visalia',
    value: 'Visalia',
  },
  {
    label: 'Simi Valley',
    value: 'Simi Valley',
  },
  {
    label: 'Concord',
    value: 'Concord',
  },
  {
    label: 'Roseville',
    value: 'Roseville',
  },
  {
    label: 'Victorville',
    value: 'Victorville',
  },
  {
    label: 'Santa Clara',
    value: 'Santa Clara',
  },
  {
    label: 'Vallejo',
    value: 'Vallejo',
  },
  {
    label: 'Berkeley',
    value: 'Berkeley',
  },
  {
    label: 'El Monte',
    value: 'El Monte',
  },
  {
    label: 'Downey',
    value: 'Downey',
  },
  {
    label: 'Costa Mesa',
    value: 'Costa Mesa',
  },
  {
    label: 'Inglewood',
    value: 'Inglewood',
  },
  {
    label: 'Carlsbad',
    value: 'Carlsbad',
  },
  {
    label: 'San Buenaventura (Ventura)',
    value: 'San Buenaventura (Ventura)',
  },
  {
    label: 'Fairfield',
    value: 'Fairfield',
  },
  {
    label: 'West Covina',
    value: 'West Covina',
  },
  {
    label: 'Murrieta',
    value: 'Murrieta',
  },
  {
    label: 'Richmond',
    value: 'Richmond',
  },
  {
    label: 'Norwalk',
    value: 'Norwalk',
  },
  {
    label: 'Antioch',
    value: 'Antioch',
  },
  {
    label: 'Temecula',
    value: 'Temecula',
  },
  {
    label: 'Burbank',
    value: 'Burbank',
  },
  {
    label: 'Daly City',
    value: 'Daly City',
  },
  {
    label: 'Rialto',
    value: 'Rialto',
  },
  {
    label: 'Santa Maria',
    value: 'Santa Maria',
  },
  {
    label: 'El Cajon',
    value: 'El Cajon',
  },
  {
    label: 'San Mateo',
    value: 'San Mateo',
  },
  {
    label: 'Clovis',
    value: 'Clovis',
  },
  {
    label: 'Compton',
    value: 'Compton',
  },
  {
    label: 'Jurupa Valley',
    value: 'Jurupa Valley',
  },
  {
    label: 'Vista',
    value: 'Vista',
  },
  {
    label: 'South Gate',
    value: 'South Gate',
  },
  {
    label: 'Mission Viejo',
    value: 'Mission Viejo',
  },
  {
    label: 'Vacaville',
    value: 'Vacaville',
  },
  {
    label: 'Carson',
    value: 'Carson',
  },
  {
    label: 'Hesperia',
    value: 'Hesperia',
  },
  {
    label: 'Santa Monica',
    value: 'Santa Monica',
  },
  {
    label: 'Westminster',
    value: 'Westminster',
  },
  {
    label: 'Redding',
    value: 'Redding',
  },
  {
    label: 'Santa Barbara',
    value: 'Santa Barbara',
  },
  {
    label: 'Chico',
    value: 'Chico',
  },
  {
    label: 'Newport Beach',
    value: 'Newport Beach',
  },
  {
    label: 'San Leandro',
    value: 'San Leandro',
  },
  {
    label: 'San Marcos',
    value: 'San Marcos',
  },
  {
    label: 'Whittier',
    value: 'Whittier',
  },
  {
    label: 'Hawthorne',
    value: 'Hawthorne',
  },
  {
    label: 'Citrus Heights',
    value: 'Citrus Heights',
  },
  {
    label: 'Tracy',
    value: 'Tracy',
  },
  {
    label: 'Alhambra',
    value: 'Alhambra',
  },
  {
    label: 'Livermore',
    value: 'Livermore',
  },
  {
    label: 'Buena Park',
    value: 'Buena Park',
  },
  {
    label: 'Menifee',
    value: 'Menifee',
  },
  {
    label: 'Hemet',
    value: 'Hemet',
  },
  {
    label: 'Lakewood',
    value: 'Lakewood',
  },
  {
    label: 'Merced',
    value: 'Merced',
  },
  {
    label: 'Chino',
    value: 'Chino',
  },
  {
    label: 'Indio',
    value: 'Indio',
  },
  {
    label: 'Redwood City',
    value: 'Redwood City',
  },
  {
    label: 'Lake Forest',
    value: 'Lake Forest',
  },
  {
    label: 'Napa',
    value: 'Napa',
  },
  {
    label: 'Tustin',
    value: 'Tustin',
  },
  {
    label: 'Bellflower',
    value: 'Bellflower',
  },
  {
    label: 'Mountain View',
    value: 'Mountain View',
  },
  {
    label: 'Chino Hills',
    value: 'Chino Hills',
  },
  {
    label: 'Baldwin Park',
    value: 'Baldwin Park',
  },
  {
    label: 'Alameda',
    value: 'Alameda',
  },
  {
    label: 'Upland',
    value: 'Upland',
  },
  {
    label: 'San Ramon',
    value: 'San Ramon',
  },
  {
    label: 'Folsom',
    value: 'Folsom',
  },
  {
    label: 'Pleasanton',
    value: 'Pleasanton',
  },
  {
    label: 'Union City',
    value: 'Union City',
  },
  {
    label: 'Perris',
    value: 'Perris',
  },
  {
    label: 'Manteca',
    value: 'Manteca',
  },
  {
    label: 'Lynwood',
    value: 'Lynwood',
  },
  {
    label: 'Apple Valley',
    value: 'Apple Valley',
  },
  {
    label: 'Redlands',
    value: 'Redlands',
  },
  {
    label: 'Turlock',
    value: 'Turlock',
  },
  {
    label: 'Milpitas',
    value: 'Milpitas',
  },
  {
    label: 'Redondo Beach',
    value: 'Redondo Beach',
  },
  {
    label: 'Rancho Cordova',
    value: 'Rancho Cordova',
  },
  {
    label: 'Yorba Linda',
    value: 'Yorba Linda',
  },
  {
    label: 'Palo Alto',
    value: 'Palo Alto',
  },
  {
    label: 'Davis',
    value: 'Davis',
  },
  {
    label: 'Camarillo',
    value: 'Camarillo',
  },
  {
    label: 'Walnut Creek',
    value: 'Walnut Creek',
  },
  {
    label: 'Pittsburg',
    value: 'Pittsburg',
  },
  {
    label: 'South San Francisco',
    value: 'South San Francisco',
  },
  {
    label: 'Yuba City',
    value: 'Yuba City',
  },
  {
    label: 'San Clemente',
    value: 'San Clemente',
  },
  {
    label: 'Laguna Niguel',
    value: 'Laguna Niguel',
  },
  {
    label: 'Pico Rivera',
    value: 'Pico Rivera',
  },
  {
    label: 'Montebello',
    value: 'Montebello',
  },
  {
    label: 'Lodi',
    value: 'Lodi',
  },
  {
    label: 'Madera',
    value: 'Madera',
  },
  {
    label: 'Santa Cruz',
    value: 'Santa Cruz',
  },
  {
    label: 'La Habra',
    value: 'La Habra',
  },
  {
    label: 'Encinitas',
    value: 'Encinitas',
  },
  {
    label: 'Monterey Park',
    value: 'Monterey Park',
  },
  {
    label: 'Tulare',
    value: 'Tulare',
  },
  {
    label: 'Cupertino',
    value: 'Cupertino',
  },
  {
    label: 'Gardena',
    value: 'Gardena',
  },
  {
    label: 'National City',
    value: 'National City',
  },
  {
    label: 'Rocklin',
    value: 'Rocklin',
  },
  {
    label: 'Petaluma',
    value: 'Petaluma',
  },
  {
    label: 'Huntington Park',
    value: 'Huntington Park',
  },
  {
    label: 'San Rafael',
    value: 'San Rafael',
  },
  {
    label: 'La Mesa',
    value: 'La Mesa',
  },
  {
    label: 'Arcadia',
    value: 'Arcadia',
  },
  {
    label: 'Fountain Valley',
    value: 'Fountain Valley',
  },
  {
    label: 'Diamond Bar',
    value: 'Diamond Bar',
  },
  {
    label: 'Woodland',
    value: 'Woodland',
  },
  {
    label: 'Santee',
    value: 'Santee',
  },
  {
    label: 'Lake Elsinore',
    value: 'Lake Elsinore',
  },
  {
    label: 'Porterville',
    value: 'Porterville',
  },
  {
    label: 'Paramount',
    value: 'Paramount',
  },
  {
    label: 'Eastvale',
    value: 'Eastvale',
  },
  {
    label: 'Rosemead',
    value: 'Rosemead',
  },
  {
    label: 'Hanford',
    value: 'Hanford',
  },
  {
    label: 'Highland',
    value: 'Highland',
  },
  {
    label: 'Brentwood',
    value: 'Brentwood',
  },
  {
    label: 'Novato',
    value: 'Novato',
  },
  {
    label: 'Colton',
    value: 'Colton',
  },
  {
    label: 'Cathedral City',
    value: 'Cathedral City',
  },
  {
    label: 'Delano',
    value: 'Delano',
  },
  {
    label: 'Yucaipa',
    value: 'Yucaipa',
  },
  {
    label: 'Watsonville',
    value: 'Watsonville',
  },
  {
    label: 'Placentia',
    value: 'Placentia',
  },
  {
    label: 'Glendora',
    value: 'Glendora',
  },
  {
    label: 'Gilroy',
    value: 'Gilroy',
  },
  {
    label: 'Palm Desert',
    value: 'Palm Desert',
  },
  {
    label: 'Cerritos',
    value: 'Cerritos',
  },
  {
    label: 'West Sacramento',
    value: 'West Sacramento',
  },
  {
    label: 'Aliso Viejo',
    value: 'Aliso Viejo',
  },
  {
    label: 'Poway',
    value: 'Poway',
  },
  {
    label: 'La Mirada',
    value: 'La Mirada',
  },
  {
    label: 'Rancho Santa Margarita',
    value: 'Rancho Santa Margarita',
  },
  {
    label: 'Cypress',
    value: 'Cypress',
  },
  {
    label: 'Dublin',
    value: 'Dublin',
  },
  {
    label: 'Covina',
    value: 'Covina',
  },
  {
    label: 'Azusa',
    value: 'Azusa',
  },
  {
    label: 'Palm Springs',
    value: 'Palm Springs',
  },
  {
    label: 'San Luis Obispo',
    value: 'San Luis Obispo',
  },
  {
    label: 'Ceres',
    value: 'Ceres',
  },
  {
    label: 'San Jacinto',
    value: 'San Jacinto',
  },
  {
    label: 'Lincoln',
    value: 'Lincoln',
  },
  {
    label: 'Newark',
    value: 'Newark',
  },
  {
    label: 'Lompoc',
    value: 'Lompoc',
  },
  {
    label: 'El Centro',
    value: 'El Centro',
  },
  {
    label: 'Danville',
    value: 'Danville',
  },
  {
    label: 'Bell Gardens',
    value: 'Bell Gardens',
  },
  {
    label: 'Coachella',
    value: 'Coachella',
  },
  {
    label: 'Rancho Palos Verdes',
    value: 'Rancho Palos Verdes',
  },
  {
    label: 'San Bruno',
    value: 'San Bruno',
  },
  {
    label: 'Rohnert Park',
    value: 'Rohnert Park',
  },
  {
    label: 'Brea',
    value: 'Brea',
  },
  {
    label: 'La Puente',
    value: 'La Puente',
  },
  {
    label: 'Campbell',
    value: 'Campbell',
  },
  {
    label: 'San Gabriel',
    value: 'San Gabriel',
  },
  {
    label: 'Beaumont',
    value: 'Beaumont',
  },
  {
    label: 'Morgan Hill',
    value: 'Morgan Hill',
  },
  {
    label: 'Culver City',
    value: 'Culver City',
  },
  {
    label: 'Calexico',
    value: 'Calexico',
  },
  {
    label: 'Stanton',
    value: 'Stanton',
  },
  {
    label: 'La Quinta',
    value: 'La Quinta',
  },
  {
    label: 'Pacifica',
    value: 'Pacifica',
  },
  {
    label: 'Montclair',
    value: 'Montclair',
  },
  {
    label: 'Oakley',
    value: 'Oakley',
  },
  {
    label: 'Monrovia',
    value: 'Monrovia',
  },
  {
    label: 'Los Banos',
    value: 'Los Banos',
  },
  {
    label: 'Martinez',
    value: 'Martinez',
  },
  {
    label: 'Chicago',
    value: 'Chicago',
  },
  {
    label: 'Aurora',
    value: 'Aurora',
  },
  {
    label: 'Rockford',
    value: 'Rockford',
  },
  {
    label: 'Joliet',
    value: 'Joliet',
  },
  {
    label: 'Naperville',
    value: 'Naperville',
  },
  {
    label: 'Springfield',
    value: 'Springfield',
  },
  {
    label: 'Peoria',
    value: 'Peoria',
  },
  {
    label: 'Elgin',
    value: 'Elgin',
  },
  {
    label: 'Waukegan',
    value: 'Waukegan',
  },
  {
    label: 'Cicero',
    value: 'Cicero',
  },
  {
    label: 'Champaign',
    value: 'Champaign',
  },
  {
    label: 'Bloomington',
    value: 'Bloomington',
  },
  {
    label: 'Arlington Heights',
    value: 'Arlington Heights',
  },
  {
    label: 'Evanston',
    value: 'Evanston',
  },
  {
    label: 'Decatur',
    value: 'Decatur',
  },
  {
    label: 'Schaumburg',
    value: 'Schaumburg',
  },
  {
    label: 'Bolingbrook',
    value: 'Bolingbrook',
  },
  {
    label: 'Palatine',
    value: 'Palatine',
  },
  {
    label: 'Skokie',
    value: 'Skokie',
  },
  {
    label: 'Des Plaines',
    value: 'Des Plaines',
  },
  {
    label: 'Orland Park',
    value: 'Orland Park',
  },
  {
    label: 'Tinley Park',
    value: 'Tinley Park',
  },
  {
    label: 'Oak Lawn',
    value: 'Oak Lawn',
  },
  {
    label: 'Berwyn',
    value: 'Berwyn',
  },
  {
    label: 'Mount Prospect',
    value: 'Mount Prospect',
  },
  {
    label: 'Normal',
    value: 'Normal',
  },
  {
    label: 'Wheaton',
    value: 'Wheaton',
  },
  {
    label: 'Hoffman Estates',
    value: 'Hoffman Estates',
  },
  {
    label: 'Oak Park',
    value: 'Oak Park',
  },
  {
    label: 'Downers Grove',
    value: 'Downers Grove',
  },
  {
    label: 'Elmhurst',
    value: 'Elmhurst',
  },
  {
    label: 'Glenview',
    value: 'Glenview',
  },
  {
    label: 'DeKalb',
    value: 'DeKalb',
  },
  {
    label: 'Lombard',
    value: 'Lombard',
  },
  {
    label: 'Belleville',
    value: 'Belleville',
  },
  {
    label: 'Moline',
    value: 'Moline',
  },
  {
    label: 'Buffalo Grove',
    value: 'Buffalo Grove',
  },
  {
    label: 'Bartlett',
    value: 'Bartlett',
  },
  {
    label: 'Urbana',
    value: 'Urbana',
  },
  {
    label: 'Quincy',
    value: 'Quincy',
  },
  {
    label: 'Crystal Lake',
    value: 'Crystal Lake',
  },
  {
    label: 'Plainfield',
    value: 'Plainfield',
  },
  {
    label: 'Streamwood',
    value: 'Streamwood',
  },
  {
    label: 'Carol Stream',
    value: 'Carol Stream',
  },
  {
    label: 'Romeoville',
    value: 'Romeoville',
  },
  {
    label: 'Rock Island',
    value: 'Rock Island',
  },
  {
    label: 'Hanover Park',
    value: 'Hanover Park',
  },
  {
    label: 'Carpentersville',
    value: 'Carpentersville',
  },
  {
    label: 'Wheeling',
    value: 'Wheeling',
  },
  {
    label: 'Park Ridge',
    value: 'Park Ridge',
  },
  {
    label: 'Addison',
    value: 'Addison',
  },
  {
    label: 'Calumet City',
    value: 'Calumet City',
  },
  {
    label: 'Houston',
    value: 'Houston',
  },
  {
    label: 'San Antonio',
    value: 'San Antonio',
  },
  {
    label: 'Dallas',
    value: 'Dallas',
  },
  {
    label: 'Austin',
    value: 'Austin',
  },
  {
    label: 'Fort Worth',
    value: 'Fort Worth',
  },
  {
    label: 'El Paso',
    value: 'El Paso',
  },
  {
    label: 'Arlington',
    value: 'Arlington',
  },
  {
    label: 'Corpus Christi',
    value: 'Corpus Christi',
  },
  {
    label: 'Plano',
    value: 'Plano',
  },
  {
    label: 'Laredo',
    value: 'Laredo',
  },
  {
    label: 'Lubbock',
    value: 'Lubbock',
  },
  {
    label: 'Garland',
    value: 'Garland',
  },
  {
    label: 'Irving',
    value: 'Irving',
  },
  {
    label: 'Amarillo',
    value: 'Amarillo',
  },
  {
    label: 'Grand Prairie',
    value: 'Grand Prairie',
  },
  {
    label: 'Brownsville',
    value: 'Brownsville',
  },
  {
    label: 'Pasadena',
    value: 'Pasadena',
  },
  {
    label: 'McKinney',
    value: 'McKinney',
  },
  {
    label: 'Mesquite',
    value: 'Mesquite',
  },
  {
    label: 'McAllen',
    value: 'McAllen',
  },
  {
    label: 'Killeen',
    value: 'Killeen',
  },
  {
    label: 'Frisco',
    value: 'Frisco',
  },
  {
    label: 'Waco',
    value: 'Waco',
  },
  {
    label: 'Carrollton',
    value: 'Carrollton',
  },
  {
    label: 'Denton',
    value: 'Denton',
  },
  {
    label: 'Midland',
    value: 'Midland',
  },
  {
    label: 'Abilene',
    value: 'Abilene',
  },
  {
    label: 'Beaumont',
    value: 'Beaumont',
  },
  {
    label: 'Round Rock',
    value: 'Round Rock',
  },
  {
    label: 'Odessa',
    value: 'Odessa',
  },
  {
    label: 'Wichita Falls',
    value: 'Wichita Falls',
  },
  {
    label: 'Richardson',
    value: 'Richardson',
  },
  {
    label: 'Lewisville',
    value: 'Lewisville',
  },
  {
    label: 'Tyler',
    value: 'Tyler',
  },
  {
    label: 'College Station',
    value: 'College Station',
  },
  {
    label: 'Pearland',
    value: 'Pearland',
  },
  {
    label: 'San Angelo',
    value: 'San Angelo',
  },
  {
    label: 'Allen',
    value: 'Allen',
  },
  {
    label: 'League City',
    value: 'League City',
  },
  {
    label: 'Sugar Land',
    value: 'Sugar Land',
  },
  {
    label: 'Longview',
    value: 'Longview',
  },
  {
    label: 'Edinburg',
    value: 'Edinburg',
  },
  {
    label: 'Mission',
    value: 'Mission',
  },
  {
    label: 'Bryan',
    value: 'Bryan',
  },
  {
    label: 'Baytown',
    value: 'Baytown',
  },
  {
    label: 'Pharr',
    value: 'Pharr',
  },
  {
    label: 'Temple',
    value: 'Temple',
  },
  {
    label: 'Missouri City',
    value: 'Missouri City',
  },
  {
    label: 'Flower Mound',
    value: 'Flower Mound',
  },
  {
    label: 'Harlingen',
    value: 'Harlingen',
  },
  {
    label: 'North Richland Hills',
    value: 'North Richland Hills',
  },
  {
    label: 'Victoria',
    value: 'Victoria',
  },
  {
    label: 'Conroe',
    value: 'Conroe',
  },
  {
    label: 'New Braunfels',
    value: 'New Braunfels',
  },
  {
    label: 'Mansfield',
    value: 'Mansfield',
  },
  {
    label: 'Cedar Park',
    value: 'Cedar Park',
  },
  {
    label: 'Rowlett',
    value: 'Rowlett',
  },
  {
    label: 'Port Arthur',
    value: 'Port Arthur',
  },
  {
    label: 'Euless',
    value: 'Euless',
  },
  {
    label: 'Georgetown',
    value: 'Georgetown',
  },
  {
    label: 'Pflugerville',
    value: 'Pflugerville',
  },
  {
    label: 'DeSoto',
    value: 'DeSoto',
  },
  {
    label: 'San Marcos',
    value: 'San Marcos',
  },
  {
    label: 'Grapevine',
    value: 'Grapevine',
  },
  {
    label: 'Bedford',
    value: 'Bedford',
  },
  {
    label: 'Galveston',
    value: 'Galveston',
  },
  {
    label: 'Cedar Hill',
    value: 'Cedar Hill',
  },
  {
    label: 'Texas City',
    value: 'Texas City',
  },
  {
    label: 'Wylie',
    value: 'Wylie',
  },
  {
    label: 'Haltom City',
    value: 'Haltom City',
  },
  {
    label: 'Keller',
    value: 'Keller',
  },
  {
    label: 'Coppell',
    value: 'Coppell',
  },
  {
    label: 'Rockwall',
    value: 'Rockwall',
  },
  {
    label: 'Huntsville',
    value: 'Huntsville',
  },
  {
    label: 'Duncanville',
    value: 'Duncanville',
  },
  {
    label: 'Sherman',
    value: 'Sherman',
  },
  {
    label: 'The Colony',
    value: 'The Colony',
  },
  {
    label: 'Burleson',
    value: 'Burleson',
  },
  {
    label: 'Hurst',
    value: 'Hurst',
  },
  {
    label: 'Lancaster',
    value: 'Lancaster',
  },
  {
    label: 'Texarkana',
    value: 'Texarkana',
  },
  {
    label: 'Friendswood',
    value: 'Friendswood',
  },
  {
    label: 'Weslaco',
    value: 'Weslaco',
  },
  {
    label: 'Philadelphia',
    value: 'Philadelphia',
  },
  {
    label: 'Pittsburgh',
    value: 'Pittsburgh',
  },
  {
    label: 'Allentown',
    value: 'Allentown',
  },
  {
    label: 'Erie',
    value: 'Erie',
  },
  {
    label: 'Reading',
    value: 'Reading',
  },
  {
    label: 'Scranton',
    value: 'Scranton',
  },
  {
    label: 'Bethlehem',
    value: 'Bethlehem',
  },
  {
    label: 'Lancaster',
    value: 'Lancaster',
  },
  {
    label: 'Harrisburg',
    value: 'Harrisburg',
  },
  {
    label: 'Altoona',
    value: 'Altoona',
  },
  {
    label: 'York',
    value: 'York',
  },
  {
    label: 'State College',
    value: 'State College',
  },
  {
    label: 'Wilkes-Barre',
    value: 'Wilkes-Barre',
  },
  {
    label: 'Phoenix',
    value: 'Phoenix',
  },
  {
    label: 'Tucson',
    value: 'Tucson',
  },
  {
    label: 'Mesa',
    value: 'Mesa',
  },
  {
    label: 'Chandler',
    value: 'Chandler',
  },
  {
    label: 'Glendale',
    value: 'Glendale',
  },
  {
    label: 'Scottsdale',
    value: 'Scottsdale',
  },
  {
    label: 'Gilbert',
    value: 'Gilbert',
  },
  {
    label: 'Tempe',
    value: 'Tempe',
  },
  {
    label: 'Peoria',
    value: 'Peoria',
  },
  {
    label: 'Surprise',
    value: 'Surprise',
  },
  {
    label: 'Yuma',
    value: 'Yuma',
  },
  {
    label: 'Avondale',
    value: 'Avondale',
  },
  {
    label: 'Goodyear',
    value: 'Goodyear',
  },
  {
    label: 'Flagstaff',
    value: 'Flagstaff',
  },
  {
    label: 'Buckeye',
    value: 'Buckeye',
  },
  {
    label: 'Lake Havasu City',
    value: 'Lake Havasu City',
  },
  {
    label: 'Casa Grande',
    value: 'Casa Grande',
  },
  {
    label: 'Sierra Vista',
    value: 'Sierra Vista',
  },
  {
    label: 'Maricopa',
    value: 'Maricopa',
  },
  {
    label: 'Oro Valley',
    value: 'Oro Valley',
  },
  {
    label: 'Prescott',
    value: 'Prescott',
  },
  {
    label: 'Bullhead City',
    value: 'Bullhead City',
  },
  {
    label: 'Prescott Valley',
    value: 'Prescott Valley',
  },
  {
    label: 'Marana',
    value: 'Marana',
  },
  {
    label: 'Apache Junction',
    value: 'Apache Junction',
  },
  {
    label: 'Jacksonville',
    value: 'Jacksonville',
  },
  {
    label: 'Miami',
    value: 'Miami',
  },
  {
    label: 'Tampa',
    value: 'Tampa',
  },
  {
    label: 'Orlando',
    value: 'Orlando',
  },
  {
    label: 'St. Petersburg',
    value: 'St. Petersburg',
  },
  {
    label: 'Hialeah',
    value: 'Hialeah',
  },
  {
    label: 'Tallahassee',
    value: 'Tallahassee',
  },
  {
    label: 'Fort Lauderdale',
    value: 'Fort Lauderdale',
  },
  {
    label: 'Port St. Lucie',
    value: 'Port St. Lucie',
  },
  {
    label: 'Cape Coral',
    value: 'Cape Coral',
  },
  {
    label: 'Pembroke Pines',
    value: 'Pembroke Pines',
  },
  {
    label: 'Hollywood',
    value: 'Hollywood',
  },
  {
    label: 'Miramar',
    value: 'Miramar',
  },
  {
    label: 'Gainesville',
    value: 'Gainesville',
  },
  {
    label: 'Coral Springs',
    value: 'Coral Springs',
  },
  {
    label: 'Miami Gardens',
    value: 'Miami Gardens',
  },
  {
    label: 'Clearwater',
    value: 'Clearwater',
  },
  {
    label: 'Palm Bay',
    value: 'Palm Bay',
  },
  {
    label: 'Pompano Beach',
    value: 'Pompano Beach',
  },
  {
    label: 'West Palm Beach',
    value: 'West Palm Beach',
  },
  {
    label: 'Lakeland',
    value: 'Lakeland',
  },
  {
    label: 'Davie',
    value: 'Davie',
  },
  {
    label: 'Miami Beach',
    value: 'Miami Beach',
  },
  {
    label: 'Sunrise',
    value: 'Sunrise',
  },
  {
    label: 'Plantation',
    value: 'Plantation',
  },
  {
    label: 'Boca Raton',
    value: 'Boca Raton',
  },
  {
    label: 'Deltona',
    value: 'Deltona',
  },
  {
    label: 'Largo',
    value: 'Largo',
  },
  {
    label: 'Deerfield Beach',
    value: 'Deerfield Beach',
  },
  {
    label: 'Palm Coast',
    value: 'Palm Coast',
  },
  {
    label: 'Melbourne',
    value: 'Melbourne',
  },
  {
    label: 'Boynton Beach',
    value: 'Boynton Beach',
  },
  {
    label: 'Lauderhill',
    value: 'Lauderhill',
  },
  {
    label: 'Weston',
    value: 'Weston',
  },
  {
    label: 'Fort Myers',
    value: 'Fort Myers',
  },
  {
    label: 'Kissimmee',
    value: 'Kissimmee',
  },
  {
    label: 'Homestead',
    value: 'Homestead',
  },
  {
    label: 'Tamarac',
    value: 'Tamarac',
  },
  {
    label: 'Delray Beach',
    value: 'Delray Beach',
  },
  {
    label: 'Daytona Beach',
    value: 'Daytona Beach',
  },
  {
    label: 'North Miami',
    value: 'North Miami',
  },
  {
    label: 'Wellington',
    value: 'Wellington',
  },
  {
    label: 'North Port',
    value: 'North Port',
  },
  {
    label: 'Jupiter',
    value: 'Jupiter',
  },
  {
    label: 'Ocala',
    value: 'Ocala',
  },
  {
    label: 'Port Orange',
    value: 'Port Orange',
  },
  {
    label: 'Margate',
    value: 'Margate',
  },
  {
    label: 'Coconut Creek',
    value: 'Coconut Creek',
  },
  {
    label: 'Sanford',
    value: 'Sanford',
  },
  {
    label: 'Sarasota',
    value: 'Sarasota',
  },
  {
    label: 'Pensacola',
    value: 'Pensacola',
  },
  {
    label: 'Bradenton',
    value: 'Bradenton',
  },
  {
    label: 'Palm Beach Gardens',
    value: 'Palm Beach Gardens',
  },
  {
    label: 'Pinellas Park',
    value: 'Pinellas Park',
  },
  {
    label: 'Coral Gables',
    value: 'Coral Gables',
  },
  {
    label: 'Doral',
    value: 'Doral',
  },
  {
    label: 'Bonita Springs',
    value: 'Bonita Springs',
  },
  {
    label: 'Apopka',
    value: 'Apopka',
  },
  {
    label: 'Titusville',
    value: 'Titusville',
  },
  {
    label: 'North Miami Beach',
    value: 'North Miami Beach',
  },
  {
    label: 'Oakland Park',
    value: 'Oakland Park',
  },
  {
    label: 'Fort Pierce',
    value: 'Fort Pierce',
  },
  {
    label: 'North Lauderdale',
    value: 'North Lauderdale',
  },
  {
    label: 'Cutler Bay',
    value: 'Cutler Bay',
  },
  {
    label: 'Altamonte Springs',
    value: 'Altamonte Springs',
  },
  {
    label: 'St. Cloud',
    value: 'St. Cloud',
  },
  {
    label: 'Greenacres',
    value: 'Greenacres',
  },
  {
    label: 'Ormond Beach',
    value: 'Ormond Beach',
  },
  {
    label: 'Ocoee',
    value: 'Ocoee',
  },
  {
    label: 'Hallandale Beach',
    value: 'Hallandale Beach',
  },
  {
    label: 'Winter Garden',
    value: 'Winter Garden',
  },
  {
    label: 'Aventura',
    value: 'Aventura',
  },
  {
    label: 'Indianapolis',
    value: 'Indianapolis',
  },
  {
    label: 'Fort Wayne',
    value: 'Fort Wayne',
  },
  {
    label: 'Evansville',
    value: 'Evansville',
  },
  {
    label: 'South Bend',
    value: 'South Bend',
  },
  {
    label: 'Carmel',
    value: 'Carmel',
  },
  {
    label: 'Bloomington',
    value: 'Bloomington',
  },
  {
    label: 'Fishers',
    value: 'Fishers',
  },
  {
    label: 'Hammond',
    value: 'Hammond',
  },
  {
    label: 'Gary',
    value: 'Gary',
  },
  {
    label: 'Muncie',
    value: 'Muncie',
  },
  {
    label: 'Lafayette',
    value: 'Lafayette',
  },
  {
    label: 'Terre Haute',
    value: 'Terre Haute',
  },
  {
    label: 'Kokomo',
    value: 'Kokomo',
  },
  {
    label: 'Anderson',
    value: 'Anderson',
  },
  {
    label: 'Noblesville',
    value: 'Noblesville',
  },
  {
    label: 'Greenwood',
    value: 'Greenwood',
  },
  {
    label: 'Elkhart',
    value: 'Elkhart',
  },
  {
    label: 'Mishawaka',
    value: 'Mishawaka',
  },
  {
    label: 'Lawrence',
    value: 'Lawrence',
  },
  {
    label: 'Jeffersonville',
    value: 'Jeffersonville',
  },
  {
    label: 'Columbus',
    value: 'Columbus',
  },
  {
    label: 'Portage',
    value: 'Portage',
  },
  {
    label: 'Columbus',
    value: 'Columbus',
  },
  {
    label: 'Cleveland',
    value: 'Cleveland',
  },
  {
    label: 'Cincinnati',
    value: 'Cincinnati',
  },
  {
    label: 'Toledo',
    value: 'Toledo',
  },
  {
    label: 'Akron',
    value: 'Akron',
  },
  {
    label: 'Dayton',
    value: 'Dayton',
  },
  {
    label: 'Parma',
    value: 'Parma',
  },
  {
    label: 'Canton',
    value: 'Canton',
  },
  {
    label: 'Youngstown',
    value: 'Youngstown',
  },
  {
    label: 'Lorain',
    value: 'Lorain',
  },
  {
    label: 'Hamilton',
    value: 'Hamilton',
  },
  {
    label: 'Springfield',
    value: 'Springfield',
  },
  {
    label: 'Kettering',
    value: 'Kettering',
  },
  {
    label: 'Elyria',
    value: 'Elyria',
  },
  {
    label: 'Lakewood',
    value: 'Lakewood',
  },
  {
    label: 'Cuyahoga Falls',
    value: 'Cuyahoga Falls',
  },
  {
    label: 'Middletown',
    value: 'Middletown',
  },
  {
    label: 'Euclid',
    value: 'Euclid',
  },
  {
    label: 'Newark',
    value: 'Newark',
  },
  {
    label: 'Mansfield',
    value: 'Mansfield',
  },
  {
    label: 'Mentor',
    value: 'Mentor',
  },
  {
    label: 'Beavercreek',
    value: 'Beavercreek',
  },
  {
    label: 'Cleveland Heights',
    value: 'Cleveland Heights',
  },
  {
    label: 'Strongsville',
    value: 'Strongsville',
  },
  {
    label: 'Dublin',
    value: 'Dublin',
  },
  {
    label: 'Fairfield',
    value: 'Fairfield',
  },
  {
    label: 'Findlay',
    value: 'Findlay',
  },
  {
    label: 'Warren',
    value: 'Warren',
  },
  {
    label: 'Lancaster',
    value: 'Lancaster',
  },
  {
    label: 'Lima',
    value: 'Lima',
  },
  {
    label: 'Huber Heights',
    value: 'Huber Heights',
  },
  {
    label: 'Westerville',
    value: 'Westerville',
  },
  {
    label: 'Marion',
    value: 'Marion',
  },
  {
    label: 'Grove City',
    value: 'Grove City',
  },
  {
    label: 'Charlotte',
    value: 'Charlotte',
  },
  {
    label: 'Raleigh',
    value: 'Raleigh',
  },
  {
    label: 'Greensboro',
    value: 'Greensboro',
  },
  {
    label: 'Durham',
    value: 'Durham',
  },
  {
    label: 'Winston-Salem',
    value: 'Winston-Salem',
  },
  {
    label: 'Fayetteville',
    value: 'Fayetteville',
  },
  {
    label: 'Cary',
    value: 'Cary',
  },
  {
    label: 'Wilmington',
    value: 'Wilmington',
  },
  {
    label: 'High Point',
    value: 'High Point',
  },
  {
    label: 'Greenville',
    value: 'Greenville',
  },
  {
    label: 'Asheville',
    value: 'Asheville',
  },
  {
    label: 'Concord',
    value: 'Concord',
  },
  {
    label: 'Gastonia',
    value: 'Gastonia',
  },
  {
    label: 'Jacksonville',
    value: 'Jacksonville',
  },
  {
    label: 'Chapel Hill',
    value: 'Chapel Hill',
  },
  {
    label: 'Rocky Mount',
    value: 'Rocky Mount',
  },
  {
    label: 'Burlington',
    value: 'Burlington',
  },
  {
    label: 'Wilson',
    value: 'Wilson',
  },
  {
    label: 'Huntersville',
    value: 'Huntersville',
  },
  {
    label: 'Kannapolis',
    value: 'Kannapolis',
  },
  {
    label: 'Apex',
    value: 'Apex',
  },
  {
    label: 'Hickory',
    value: 'Hickory',
  },
  {
    label: 'Goldsboro',
    value: 'Goldsboro',
  },
  {
    label: 'Detroit',
    value: 'Detroit',
  },
  {
    label: 'Grand Rapids',
    value: 'Grand Rapids',
  },
  {
    label: 'Warren',
    value: 'Warren',
  },
  {
    label: 'Sterling Heights',
    value: 'Sterling Heights',
  },
  {
    label: 'Ann Arbor',
    value: 'Ann Arbor',
  },
  {
    label: 'Lansing',
    value: 'Lansing',
  },
  {
    label: 'Flint',
    value: 'Flint',
  },
  {
    label: 'Dearborn',
    value: 'Dearborn',
  },
  {
    label: 'Livonia',
    value: 'Livonia',
  },
  {
    label: 'Westland',
    value: 'Westland',
  },
  {
    label: 'Troy',
    value: 'Troy',
  },
  {
    label: 'Farmington Hills',
    value: 'Farmington Hills',
  },
  {
    label: 'Kalamazoo',
    value: 'Kalamazoo',
  },
  {
    label: 'Wyoming',
    value: 'Wyoming',
  },
  {
    label: 'Southfield',
    value: 'Southfield',
  },
  {
    label: 'Rochester Hills',
    value: 'Rochester Hills',
  },
  {
    label: 'Taylor',
    value: 'Taylor',
  },
  {
    label: 'Pontiac',
    value: 'Pontiac',
  },
  {
    label: 'St. Clair Shores',
    value: 'St. Clair Shores',
  },
  {
    label: 'Royal Oak',
    value: 'Royal Oak',
  },
  {
    label: 'Novi',
    value: 'Novi',
  },
  {
    label: 'Dearborn Heights',
    value: 'Dearborn Heights',
  },
  {
    label: 'Battle Creek',
    value: 'Battle Creek',
  },
  {
    label: 'Saginaw',
    value: 'Saginaw',
  },
  {
    label: 'Kentwood',
    value: 'Kentwood',
  },
  {
    label: 'East Lansing',
    value: 'East Lansing',
  },
  {
    label: 'Roseville',
    value: 'Roseville',
  },
  {
    label: 'Portage',
    value: 'Portage',
  },
  {
    label: 'Midland',
    value: 'Midland',
  },
  {
    label: 'Lincoln Park',
    value: 'Lincoln Park',
  },
  {
    label: 'Muskegon',
    value: 'Muskegon',
  },
  {
    label: 'Memphis',
    value: 'Memphis',
  },
  {
    label: 'Nashville-Davidson',
    value: 'Nashville-Davidson',
  },
  {
    label: 'Knoxville',
    value: 'Knoxville',
  },
  {
    label: 'Chattanooga',
    value: 'Chattanooga',
  },
  {
    label: 'Clarksville',
    value: 'Clarksville',
  },
  {
    label: 'Murfreesboro',
    value: 'Murfreesboro',
  },
  {
    label: 'Jackson',
    value: 'Jackson',
  },
  {
    label: 'Franklin',
    value: 'Franklin',
  },
  {
    label: 'Johnson City',
    value: 'Johnson City',
  },
  {
    label: 'Bartlett',
    value: 'Bartlett',
  },
  {
    label: 'Hendersonville',
    value: 'Hendersonville',
  },
  {
    label: 'Kingsport',
    value: 'Kingsport',
  },
  {
    label: 'Collierville',
    value: 'Collierville',
  },
  {
    label: 'Cleveland',
    value: 'Cleveland',
  },
  {
    label: 'Smyrna',
    value: 'Smyrna',
  },
  {
    label: 'Germantown',
    value: 'Germantown',
  },
  {
    label: 'Brentwood',
    value: 'Brentwood',
  },
  {
    label: 'Boston',
    value: 'Boston',
  },
  {
    label: 'Worcester',
    value: 'Worcester',
  },
  {
    label: 'Springfield',
    value: 'Springfield',
  },
  {
    label: 'Lowell',
    value: 'Lowell',
  },
  {
    label: 'Cambridge',
    value: 'Cambridge',
  },
  {
    label: 'New Bedford',
    value: 'New Bedford',
  },
  {
    label: 'Brockton',
    value: 'Brockton',
  },
  {
    label: 'Quincy',
    value: 'Quincy',
  },
  {
    label: 'Lynn',
    value: 'Lynn',
  },
  {
    label: 'Fall River',
    value: 'Fall River',
  },
  {
    label: 'Newton',
    value: 'Newton',
  },
  {
    label: 'Lawrence',
    value: 'Lawrence',
  },
  {
    label: 'Somerville',
    value: 'Somerville',
  },
  {
    label: 'Waltham',
    value: 'Waltham',
  },
  {
    label: 'Haverhill',
    value: 'Haverhill',
  },
  {
    label: 'Malden',
    value: 'Malden',
  },
  {
    label: 'Medford',
    value: 'Medford',
  },
  {
    label: 'Taunton',
    value: 'Taunton',
  },
  {
    label: 'Chicopee',
    value: 'Chicopee',
  },
  {
    label: 'Weymouth Town',
    value: 'Weymouth Town',
  },
  {
    label: 'Revere',
    value: 'Revere',
  },
  {
    label: 'Peabody',
    value: 'Peabody',
  },
  {
    label: 'Methuen',
    value: 'Methuen',
  },
  {
    label: 'Barnstable Town',
    value: 'Barnstable Town',
  },
  {
    label: 'Pittsfield',
    value: 'Pittsfield',
  },
  {
    label: 'Attleboro',
    value: 'Attleboro',
  },
  {
    label: 'Everett',
    value: 'Everett',
  },
  {
    label: 'Salem',
    value: 'Salem',
  },
  {
    label: 'Westfield',
    value: 'Westfield',
  },
  {
    label: 'Leominster',
    value: 'Leominster',
  },
  {
    label: 'Fitchburg',
    value: 'Fitchburg',
  },
  {
    label: 'Beverly',
    value: 'Beverly',
  },
  {
    label: 'Holyoke',
    value: 'Holyoke',
  },
  {
    label: 'Marlborough',
    value: 'Marlborough',
  },
  {
    label: 'Woburn',
    value: 'Woburn',
  },
  {
    label: 'Chelsea',
    value: 'Chelsea',
  },
  {
    label: 'Seattle',
    value: 'Seattle',
  },
  {
    label: 'Spokane',
    value: 'Spokane',
  },
  {
    label: 'Tacoma',
    value: 'Tacoma',
  },
  {
    label: 'Vancouver',
    value: 'Vancouver',
  },
  {
    label: 'Bellevue',
    value: 'Bellevue',
  },
  {
    label: 'Kent',
    value: 'Kent',
  },
  {
    label: 'Everett',
    value: 'Everett',
  },
  {
    label: 'Renton',
    value: 'Renton',
  },
  {
    label: 'Yakima',
    value: 'Yakima',
  },
  {
    label: 'Federal Way',
    value: 'Federal Way',
  },
  {
    label: 'Spokane Valley',
    value: 'Spokane Valley',
  },
  {
    label: 'Bellingham',
    value: 'Bellingham',
  },
  {
    label: 'Kennewick',
    value: 'Kennewick',
  },
  {
    label: 'Auburn',
    value: 'Auburn',
  },
  {
    label: 'Pasco',
    value: 'Pasco',
  },
  {
    label: 'Marysville',
    value: 'Marysville',
  },
  {
    label: 'Lakewood',
    value: 'Lakewood',
  },
  {
    label: 'Redmond',
    value: 'Redmond',
  },
  {
    label: 'Shoreline',
    value: 'Shoreline',
  },
  {
    label: 'Richland',
    value: 'Richland',
  },
  {
    label: 'Kirkland',
    value: 'Kirkland',
  },
  {
    label: 'Burien',
    value: 'Burien',
  },
  {
    label: 'Sammamish',
    value: 'Sammamish',
  },
  {
    label: 'Olympia',
    value: 'Olympia',
  },
  {
    label: 'Lacey',
    value: 'Lacey',
  },
  {
    label: 'Edmonds',
    value: 'Edmonds',
  },
  {
    label: 'Bremerton',
    value: 'Bremerton',
  },
  {
    label: 'Puyallup',
    value: 'Puyallup',
  },
  {
    label: 'Denver',
    value: 'Denver',
  },
  {
    label: 'Colorado Springs',
    value: 'Colorado Springs',
  },
  {
    label: 'Aurora',
    value: 'Aurora',
  },
  {
    label: 'Fort Collins',
    value: 'Fort Collins',
  },
  {
    label: 'Lakewood',
    value: 'Lakewood',
  },
  {
    label: 'Thornton',
    value: 'Thornton',
  },
  {
    label: 'Arvada',
    value: 'Arvada',
  },
  {
    label: 'Westminster',
    value: 'Westminster',
  },
  {
    label: 'Pueblo',
    value: 'Pueblo',
  },
  {
    label: 'Centennial',
    value: 'Centennial',
  },
  {
    label: 'Boulder',
    value: 'Boulder',
  },
  {
    label: 'Greeley',
    value: 'Greeley',
  },
  {
    label: 'Longmont',
    value: 'Longmont',
  },
  {
    label: 'Loveland',
    value: 'Loveland',
  },
  {
    label: 'Grand Junction',
    value: 'Grand Junction',
  },
  {
    label: 'Broomfield',
    value: 'Broomfield',
  },
  {
    label: 'Castle Rock',
    value: 'Castle Rock',
  },
  {
    label: 'Commerce City',
    value: 'Commerce City',
  },
  {
    label: 'Parker',
    value: 'Parker',
  },
  {
    label: 'Littleton',
    value: 'Littleton',
  },
  {
    label: 'Northglenn',
    value: 'Northglenn',
  },
  {
    label: 'Washington',
    value: 'Washington',
  },
  {
    label: 'Baltimore',
    value: 'Baltimore',
  },
  {
    label: 'Frederick',
    value: 'Frederick',
  },
  {
    label: 'Rockville',
    value: 'Rockville',
  },
  {
    label: 'Gaithersburg',
    value: 'Gaithersburg',
  },
  {
    label: 'Bowie',
    value: 'Bowie',
  },
  {
    label: 'Hagerstown',
    value: 'Hagerstown',
  },
  {
    label: 'Annapolis',
    value: 'Annapolis',
  },
  {
    label: 'Louisville/Jefferson County',
    value: 'Louisville/Jefferson County',
  },
  {
    label: 'Lexington-Fayette',
    value: 'Lexington-Fayette',
  },
  {
    label: 'Bowling Green',
    value: 'Bowling Green',
  },
  {
    label: 'Owensboro',
    value: 'Owensboro',
  },
  {
    label: 'Covington',
    value: 'Covington',
  },
  {
    label: 'Portland',
    value: 'Portland',
  },
  {
    label: 'Eugene',
    value: 'Eugene',
  },
  {
    label: 'Salem',
    value: 'Salem',
  },
  {
    label: 'Gresham',
    value: 'Gresham',
  },
  {
    label: 'Hillsboro',
    value: 'Hillsboro',
  },
  {
    label: 'Beaverton',
    value: 'Beaverton',
  },
  {
    label: 'Bend',
    value: 'Bend',
  },
  {
    label: 'Medford',
    value: 'Medford',
  },
  {
    label: 'Springfield',
    value: 'Springfield',
  },
  {
    label: 'Corvallis',
    value: 'Corvallis',
  },
  {
    label: 'Albany',
    value: 'Albany',
  },
  {
    label: 'Tigard',
    value: 'Tigard',
  },
  {
    label: 'Lake Oswego',
    value: 'Lake Oswego',
  },
  {
    label: 'Keizer',
    value: 'Keizer',
  },
  {
    label: 'Oklahoma City',
    value: 'Oklahoma City',
  },
  {
    label: 'Tulsa',
    value: 'Tulsa',
  },
  {
    label: 'Norman',
    value: 'Norman',
  },
  {
    label: 'Broken Arrow',
    value: 'Broken Arrow',
  },
  {
    label: 'Lawton',
    value: 'Lawton',
  },
  {
    label: 'Edmond',
    value: 'Edmond',
  },
  {
    label: 'Moore',
    value: 'Moore',
  },
  {
    label: 'Midwest City',
    value: 'Midwest City',
  },
  {
    label: 'Enid',
    value: 'Enid',
  },
  {
    label: 'Stillwater',
    value: 'Stillwater',
  },
  {
    label: 'Muskogee',
    value: 'Muskogee',
  },
  {
    label: 'Milwaukee',
    value: 'Milwaukee',
  },
  {
    label: 'Madison',
    value: 'Madison',
  },
  {
    label: 'Green Bay',
    value: 'Green Bay',
  },
  {
    label: 'Kenosha',
    value: 'Kenosha',
  },
  {
    label: 'Racine',
    value: 'Racine',
  },
  {
    label: 'Appleton',
    value: 'Appleton',
  },
  {
    label: 'Waukesha',
    value: 'Waukesha',
  },
  {
    label: 'Eau Claire',
    value: 'Eau Claire',
  },
  {
    label: 'Oshkosh',
    value: 'Oshkosh',
  },
  {
    label: 'Janesville',
    value: 'Janesville',
  },
  {
    label: 'West Allis',
    value: 'West Allis',
  },
  {
    label: 'La Crosse',
    value: 'La Crosse',
  },
  {
    label: 'Sheboygan',
    value: 'Sheboygan',
  },
  {
    label: 'Wauwatosa',
    value: 'Wauwatosa',
  },
  {
    label: 'Fond du Lac',
    value: 'Fond du Lac',
  },
  {
    label: 'New Berlin',
    value: 'New Berlin',
  },
  {
    label: 'Wausau',
    value: 'Wausau',
  },
  {
    label: 'Brookfield',
    value: 'Brookfield',
  },
  {
    label: 'Greenfield',
    value: 'Greenfield',
  },
  {
    label: 'Beloit',
    value: 'Beloit',
  },
  {
    label: 'Las Vegas',
    value: 'Las Vegas',
  },
  {
    label: 'Henderson',
    value: 'Henderson',
  },
  {
    label: 'Reno',
    value: 'Reno',
  },
  {
    label: 'North Las Vegas',
    value: 'North Las Vegas',
  },
  {
    label: 'Sparks',
    value: 'Sparks',
  },
  {
    label: 'Carson City',
    value: 'Carson City',
  },
  {
    label: 'Albuquerque',
    value: 'Albuquerque',
  },
  {
    label: 'Las Cruces',
    value: 'Las Cruces',
  },
  {
    label: 'Rio Rancho',
    value: 'Rio Rancho',
  },
  {
    label: 'Santa Fe',
    value: 'Santa Fe',
  },
  {
    label: 'Roswell',
    value: 'Roswell',
  },
  {
    label: 'Farmington',
    value: 'Farmington',
  },
  {
    label: 'Clovis',
    value: 'Clovis',
  },
  {
    label: 'Kansas City',
    value: 'Kansas City',
  },
  {
    label: 'St. Louis',
    value: 'St. Louis',
  },
  {
    label: 'Springfield',
    value: 'Springfield',
  },
  {
    label: 'Independence',
    value: 'Independence',
  },
  {
    label: 'Columbia',
    value: 'Columbia',
  },
  {
    label: "Lee's Summit",
    value: "Lee's Summit",
  },
  {
    label: "O'Fallon",
    value: "O'Fallon",
  },
  {
    label: 'St. Joseph',
    value: 'St. Joseph',
  },
  {
    label: 'St. Charles',
    value: 'St. Charles',
  },
  {
    label: 'St. Peters',
    value: 'St. Peters',
  },
  {
    label: 'Blue Springs',
    value: 'Blue Springs',
  },
  {
    label: 'Florissant',
    value: 'Florissant',
  },
  {
    label: 'Joplin',
    value: 'Joplin',
  },
  {
    label: 'Chesterfield',
    value: 'Chesterfield',
  },
  {
    label: 'Jefferson City',
    value: 'Jefferson City',
  },
  {
    label: 'Cape Girardeau',
    value: 'Cape Girardeau',
  },
  {
    label: 'Virginia Beach',
    value: 'Virginia Beach',
  },
  {
    label: 'Norfolk',
    value: 'Norfolk',
  },
  {
    label: 'Chesapeake',
    value: 'Chesapeake',
  },
  {
    label: 'Richmond',
    value: 'Richmond',
  },
  {
    label: 'Newport News',
    value: 'Newport News',
  },
  {
    label: 'Alexandria',
    value: 'Alexandria',
  },
  {
    label: 'Hampton',
    value: 'Hampton',
  },
  {
    label: 'Roanoke',
    value: 'Roanoke',
  },
  {
    label: 'Portsmouth',
    value: 'Portsmouth',
  },
  {
    label: 'Suffolk',
    value: 'Suffolk',
  },
  {
    label: 'Lynchburg',
    value: 'Lynchburg',
  },
  {
    label: 'Harrisonburg',
    value: 'Harrisonburg',
  },
  {
    label: 'Leesburg',
    value: 'Leesburg',
  },
  {
    label: 'Charlottesville',
    value: 'Charlottesville',
  },
  {
    label: 'Danville',
    value: 'Danville',
  },
  {
    label: 'Blacksburg',
    value: 'Blacksburg',
  },
  {
    label: 'Manassas',
    value: 'Manassas',
  },
  {
    label: 'Atlanta',
    value: 'Atlanta',
  },
  {
    label: 'Columbus',
    value: 'Columbus',
  },
  {
    label: 'Augusta-Richmond County',
    value: 'Augusta-Richmond County',
  },
  {
    label: 'Savannah',
    value: 'Savannah',
  },
  {
    label: 'Athens-Clarke County',
    value: 'Athens-Clarke County',
  },
  {
    label: 'Sandy Springs',
    value: 'Sandy Springs',
  },
  {
    label: 'Roswell',
    value: 'Roswell',
  },
  {
    label: 'Macon',
    value: 'Macon',
  },
  {
    label: 'Johns Creek',
    value: 'Johns Creek',
  },
  {
    label: 'Albany',
    value: 'Albany',
  },
  {
    label: 'Warner Robins',
    value: 'Warner Robins',
  },
  {
    label: 'Alpharetta',
    value: 'Alpharetta',
  },
  {
    label: 'Marietta',
    value: 'Marietta',
  },
  {
    label: 'Valdosta',
    value: 'Valdosta',
  },
  {
    label: 'Smyrna',
    value: 'Smyrna',
  },
  {
    label: 'Dunwoody',
    value: 'Dunwoody',
  },
  {
    label: 'Omaha',
    value: 'Omaha',
  },
  {
    label: 'Lincoln',
    value: 'Lincoln',
  },
  {
    label: 'Bellevue',
    value: 'Bellevue',
  },
  {
    label: 'Grand Island',
    value: 'Grand Island',
  },
  {
    label: 'Minneapolis',
    value: 'Minneapolis',
  },
  {
    label: 'St. Paul',
    value: 'St. Paul',
  },
  {
    label: 'Rochester',
    value: 'Rochester',
  },
  {
    label: 'Duluth',
    value: 'Duluth',
  },
  {
    label: 'Bloomington',
    value: 'Bloomington',
  },
  {
    label: 'Brooklyn Park',
    value: 'Brooklyn Park',
  },
  {
    label: 'Plymouth',
    value: 'Plymouth',
  },
  {
    label: 'St. Cloud',
    value: 'St. Cloud',
  },
  {
    label: 'Eagan',
    value: 'Eagan',
  },
  {
    label: 'Woodbury',
    value: 'Woodbury',
  },
  {
    label: 'Maple Grove',
    value: 'Maple Grove',
  },
  {
    label: 'Eden Prairie',
    value: 'Eden Prairie',
  },
  {
    label: 'Coon Rapids',
    value: 'Coon Rapids',
  },
  {
    label: 'Burnsville',
    value: 'Burnsville',
  },
  {
    label: 'Blaine',
    value: 'Blaine',
  },
  {
    label: 'Lakeville',
    value: 'Lakeville',
  },
  {
    label: 'Minnetonka',
    value: 'Minnetonka',
  },
  {
    label: 'Apple Valley',
    value: 'Apple Valley',
  },
  {
    label: 'Edina',
    value: 'Edina',
  },
  {
    label: 'St. Louis Park',
    value: 'St. Louis Park',
  },
  {
    label: 'Mankato',
    value: 'Mankato',
  },
  {
    label: 'Maplewood',
    value: 'Maplewood',
  },
  {
    label: 'Moorhead',
    value: 'Moorhead',
  },
  {
    label: 'Shakopee',
    value: 'Shakopee',
  },
  {
    label: 'Wichita',
    value: 'Wichita',
  },
  {
    label: 'Overland Park',
    value: 'Overland Park',
  },
  {
    label: 'Kansas City',
    value: 'Kansas City',
  },
  {
    label: 'Olathe',
    value: 'Olathe',
  },
  {
    label: 'Topeka',
    value: 'Topeka',
  },
  {
    label: 'Lawrence',
    value: 'Lawrence',
  },
  {
    label: 'Shawnee',
    value: 'Shawnee',
  },
  {
    label: 'Manhattan',
    value: 'Manhattan',
  },
  {
    label: 'Lenexa',
    value: 'Lenexa',
  },
  {
    label: 'Salina',
    value: 'Salina',
  },
  {
    label: 'Hutchinson',
    value: 'Hutchinson',
  },
  {
    label: 'New Orleans',
    value: 'New Orleans',
  },
  {
    label: 'Baton Rouge',
    value: 'Baton Rouge',
  },
  {
    label: 'Shreveport',
    value: 'Shreveport',
  },
  {
    label: 'Lafayette',
    value: 'Lafayette',
  },
  {
    label: 'Lake Charles',
    value: 'Lake Charles',
  },
  {
    label: 'Kenner',
    value: 'Kenner',
  },
  {
    label: 'Bossier City',
    value: 'Bossier City',
  },
  {
    label: 'Monroe',
    value: 'Monroe',
  },
  {
    label: 'Alexandria',
    value: 'Alexandria',
  },
  {
    label: 'Honolulu',
    value: 'Honolulu',
  },
  {
    label: 'Anchorage',
    value: 'Anchorage',
  },
  {
    label: 'Newark',
    value: 'Newark',
  },
  {
    label: 'Jersey City',
    value: 'Jersey City',
  },
  {
    label: 'Paterson',
    value: 'Paterson',
  },
  {
    label: 'Elizabeth',
    value: 'Elizabeth',
  },
  {
    label: 'Clifton',
    value: 'Clifton',
  },
  {
    label: 'Trenton',
    value: 'Trenton',
  },
  {
    label: 'Camden',
    value: 'Camden',
  },
  {
    label: 'Passaic',
    value: 'Passaic',
  },
  {
    label: 'Union City',
    value: 'Union City',
  },
  {
    label: 'Bayonne',
    value: 'Bayonne',
  },
  {
    label: 'East Orange',
    value: 'East Orange',
  },
  {
    label: 'Vineland',
    value: 'Vineland',
  },
  {
    label: 'New Brunswick',
    value: 'New Brunswick',
  },
  {
    label: 'Hoboken',
    value: 'Hoboken',
  },
  {
    label: 'Perth Amboy',
    value: 'Perth Amboy',
  },
  {
    label: 'West New York',
    value: 'West New York',
  },
  {
    label: 'Plainfield',
    value: 'Plainfield',
  },
  {
    label: 'Hackensack',
    value: 'Hackensack',
  },
  {
    label: 'Sayreville',
    value: 'Sayreville',
  },
  {
    label: 'Kearny',
    value: 'Kearny',
  },
  {
    label: 'Linden',
    value: 'Linden',
  },
  {
    label: 'Atlantic City',
    value: 'Atlantic City',
  },
  {
    label: 'Boise City',
    value: 'Boise City',
  },
  {
    label: 'Nampa',
    value: 'Nampa',
  },
  {
    label: 'Meridian',
    value: 'Meridian',
  },
  {
    label: 'Idaho Falls',
    value: 'Idaho Falls',
  },
  {
    label: 'Pocatello',
    value: 'Pocatello',
  },
  {
    label: 'Caldwell',
    value: 'Caldwell',
  },
  {
    label: "Coeur d'Alene",
    value: "Coeur d'Alene",
  },
  {
    label: 'Twin Falls',
    value: 'Twin Falls',
  },
  {
    label: 'Birmingham',
    value: 'Birmingham',
  },
  {
    label: 'Montgomery',
    value: 'Montgomery',
  },
  {
    label: 'Mobile',
    value: 'Mobile',
  },
  {
    label: 'Huntsville',
    value: 'Huntsville',
  },
  {
    label: 'Tuscaloosa',
    value: 'Tuscaloosa',
  },
  {
    label: 'Hoover',
    value: 'Hoover',
  },
  {
    label: 'Dothan',
    value: 'Dothan',
  },
  {
    label: 'Auburn',
    value: 'Auburn',
  },
  {
    label: 'Decatur',
    value: 'Decatur',
  },
  {
    label: 'Madison',
    value: 'Madison',
  },
  {
    label: 'Florence',
    value: 'Florence',
  },
  {
    label: 'Gadsden',
    value: 'Gadsden',
  },
  {
    label: 'Des Moines',
    value: 'Des Moines',
  },
  {
    label: 'Cedar Rapids',
    value: 'Cedar Rapids',
  },
  {
    label: 'Davenport',
    value: 'Davenport',
  },
  {
    label: 'Sioux City',
    value: 'Sioux City',
  },
  {
    label: 'Iowa City',
    value: 'Iowa City',
  },
  {
    label: 'Waterloo',
    value: 'Waterloo',
  },
  {
    label: 'Council Bluffs',
    value: 'Council Bluffs',
  },
  {
    label: 'Ames',
    value: 'Ames',
  },
  {
    label: 'West Des Moines',
    value: 'West Des Moines',
  },
  {
    label: 'Dubuque',
    value: 'Dubuque',
  },
  {
    label: 'Ankeny',
    value: 'Ankeny',
  },
  {
    label: 'Urbandale',
    value: 'Urbandale',
  },
  {
    label: 'Cedar Falls',
    value: 'Cedar Falls',
  },
  {
    label: 'Little Rock',
    value: 'Little Rock',
  },
  {
    label: 'Fort Smith',
    value: 'Fort Smith',
  },
  {
    label: 'Fayetteville',
    value: 'Fayetteville',
  },
  {
    label: 'Springdale',
    value: 'Springdale',
  },
  {
    label: 'Jonesboro',
    value: 'Jonesboro',
  },
  {
    label: 'North Little Rock',
    value: 'North Little Rock',
  },
  {
    label: 'Conway',
    value: 'Conway',
  },
  {
    label: 'Rogers',
    value: 'Rogers',
  },
  {
    label: 'Pine Bluff',
    value: 'Pine Bluff',
  },
  {
    label: 'Bentonville',
    value: 'Bentonville',
  },
  {
    label: 'Salt Lake City',
    value: 'Salt Lake City',
  },
  {
    label: 'West Valley City',
    value: 'West Valley City',
  },
  {
    label: 'Provo',
    value: 'Provo',
  },
  {
    label: 'West Jordan',
    value: 'West Jordan',
  },
  {
    label: 'Orem',
    value: 'Orem',
  },
  {
    label: 'Sandy',
    value: 'Sandy',
  },
  {
    label: 'Ogden',
    value: 'Ogden',
  },
  {
    label: 'St. George',
    value: 'St. George',
  },
  {
    label: 'Layton',
    value: 'Layton',
  },
  {
    label: 'Taylorsville',
    value: 'Taylorsville',
  },
  {
    label: 'South Jordan',
    value: 'South Jordan',
  },
  {
    label: 'Lehi',
    value: 'Lehi',
  },
  {
    label: 'Logan',
    value: 'Logan',
  },
  {
    label: 'Murray',
    value: 'Murray',
  },
  {
    label: 'Draper',
    value: 'Draper',
  },
  {
    label: 'Bountiful',
    value: 'Bountiful',
  },
  {
    label: 'Riverton',
    value: 'Riverton',
  },
  {
    label: 'Roy',
    value: 'Roy',
  },
  {
    label: 'Providence',
    value: 'Providence',
  },
  {
    label: 'Warwick',
    value: 'Warwick',
  },
  {
    label: 'Cranston',
    value: 'Cranston',
  },
  {
    label: 'Pawtucket',
    value: 'Pawtucket',
  },
  {
    label: 'East Providence',
    value: 'East Providence',
  },
  {
    label: 'Woonsocket',
    value: 'Woonsocket',
  },
  {
    label: 'Jackson',
    value: 'Jackson',
  },
  {
    label: 'Gulfport',
    value: 'Gulfport',
  },
  {
    label: 'Southaven',
    value: 'Southaven',
  },
  {
    label: 'Hattiesburg',
    value: 'Hattiesburg',
  },
  {
    label: 'Biloxi',
    value: 'Biloxi',
  },
  {
    label: 'Meridian',
    value: 'Meridian',
  },
  {
    label: 'Sioux Falls',
    value: 'Sioux Falls',
  },
  {
    label: 'Rapid City',
    value: 'Rapid City',
  },
  {
    label: 'Bridgeport',
    value: 'Bridgeport',
  },
  {
    label: 'New Haven',
    value: 'New Haven',
  },
  {
    label: 'Stamford',
    value: 'Stamford',
  },
  {
    label: 'Hartford',
    value: 'Hartford',
  },
  {
    label: 'Waterbury',
    value: 'Waterbury',
  },
  {
    label: 'Norwalk',
    value: 'Norwalk',
  },
  {
    label: 'Danbury',
    value: 'Danbury',
  },
  {
    label: 'New Britain',
    value: 'New Britain',
  },
  {
    label: 'Meriden',
    value: 'Meriden',
  },
  {
    label: 'Bristol',
    value: 'Bristol',
  },
  {
    label: 'West Haven',
    value: 'West Haven',
  },
  {
    label: 'Milford',
    value: 'Milford',
  },
  {
    label: 'Middletown',
    value: 'Middletown',
  },
  {
    label: 'Norwich',
    value: 'Norwich',
  },
  {
    label: 'Shelton',
    value: 'Shelton',
  },
  {
    label: 'Columbia',
    value: 'Columbia',
  },
  {
    label: 'Charleston',
    value: 'Charleston',
  },
  {
    label: 'North Charleston',
    value: 'North Charleston',
  },
  {
    label: 'Mount Pleasant',
    value: 'Mount Pleasant',
  },
  {
    label: 'Rock Hill',
    value: 'Rock Hill',
  },
  {
    label: 'Greenville',
    value: 'Greenville',
  },
  {
    label: 'Summerville',
    value: 'Summerville',
  },
  {
    label: 'Sumter',
    value: 'Sumter',
  },
  {
    label: 'Goose Creek',
    value: 'Goose Creek',
  },
  {
    label: 'Hilton Head Island',
    value: 'Hilton Head Island',
  },
  {
    label: 'Florence',
    value: 'Florence',
  },
  {
    label: 'Spartanburg',
    value: 'Spartanburg',
  },
  {
    label: 'Manchester',
    value: 'Manchester',
  },
  {
    label: 'Nashua',
    value: 'Nashua',
  },
  {
    label: 'Concord',
    value: 'Concord',
  },
  {
    label: 'Fargo',
    value: 'Fargo',
  },
  {
    label: 'Bismarck',
    value: 'Bismarck',
  },
  {
    label: 'Grand Forks',
    value: 'Grand Forks',
  },
  {
    label: 'Minot',
    value: 'Minot',
  },
  {
    label: 'Billings',
    value: 'Billings',
  },
  {
    label: 'Missoula',
    value: 'Missoula',
  },
  {
    label: 'Great Falls',
    value: 'Great Falls',
  },
  {
    label: 'Bozeman',
    value: 'Bozeman',
  },
  {
    label: 'Wilmington',
    value: 'Wilmington',
  },
  {
    label: 'Dover',
    value: 'Dover',
  },
  {
    label: 'Portland',
    value: 'Portland',
  },
  {
    label: 'Cheyenne',
    value: 'Cheyenne',
  },
  {
    label: 'Casper',
    value: 'Casper',
  },
  {
    label: 'Charleston',
    value: 'Charleston',
  },
  {
    label: 'Huntington',
    value: 'Huntington',
  },
  {
    label: 'Burlington',
    value: 'Burlington',
  },
];
export const STATES = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];
