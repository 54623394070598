import React, { useContext } from 'react';

import { DownOutlined } from '@ant-design/icons/lib';
import styled from 'styled-components';

import { SELECTOR_ITEM_DESCRIPTION } from './constants';
import { SidebarContext } from './SidebarContext';

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 11px 14px 11px 20px;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 var(--sidebar-selector-shadow);
  border: solid 1px var(--sidebar-selector-border);
  background: linear-gradient(to right, var(--sidebar-selector-purple-dark) 20%, var(--sidebar-selector-purple-light));
  user-select: none;
`;

const SelectorInfo = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
`;

const SelectorInfoParent = styled.span`
  font-size: 10px;
`;

const SelectorInfoCommunity = styled.div`
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
`;

const SidebarSelectorButton = () => {
  const { selectedItem } = useContext(SidebarContext);
  return (
    <Selector>
      <SelectorInfo>
        <SelectorInfoParent>{selectedItem && SELECTOR_ITEM_DESCRIPTION[selectedItem.type]}</SelectorInfoParent>
        <SelectorInfoCommunity>{selectedItem?.name || 'No Selection'}</SelectorInfoCommunity>
      </SelectorInfo>
      <DownOutlined style={{ color: 'white', fontSize: 10 }} />
    </Selector>
  );
};

export default SidebarSelectorButton;
