import React, { useEffect } from 'react';

import styled from 'styled-components';

import { isMysteryShopAdmin } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';
import useDataList from 'utils/hooks/useDataList';

import {
  MYSTERY_SHOP_MODAL_REQUEST_FROM,
  SHOP_REQUEST_COLUMNS,
  SHOP_REQUEST_DUE_DATE_RANGES,
  SHOP_REQUEST_VIEW_FILTER_OPTIONS,
  SHOP_REQUESTS_PAGE_SIZE,
} from './constants';
import ShopRequestFilters from './Filters/ShopRequestFilters';
import ShopRequestsCreateButton from './ShopRequestsCreateButton';
import { ShopRequestsDataContext } from './ShopRequestsDataContext';
import ShopRequestSearch from './ShopRequestSearch';
import ShopRequestsGroupTable from './ShopRequestsTable/ShopRequestsGroupTable';
import ShopRequestsTable from './ShopRequestsTable/ShopRequestsTable';
import ShopRequestsTabs from './Tabs/ShopRequestsTabs';

const SearchAndCreateContainer = styled.div`
  margin: 0 30px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-top: 12px;
`;

const ShopRequestsData = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);
  const { filters, ordering, setOrdering, setFilters, setPage, setSearch } = useDataList({
    initialState: {
      filters: {
        page: 1,
        pageSize: SHOP_REQUESTS_PAGE_SIZE,
        search: '',
        status: [],
        requestFrom: MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY,
        shoppers: [],
        companyIds: [],
        dueDate: undefined,
        dueDateRange: SHOP_REQUEST_DUE_DATE_RANGES.DUE_THIS_WEEK,
        groupBy: undefined,
      },
      ordering: SHOP_REQUEST_COLUMNS.dueDate.id,
    },
  });

  useEffect(() => {
    if (currentUser && !isShopRequestAdmin) {
      setFilters({ ...filters, shoppers: [currentUser.PAUser_Id, 0] });
    }
  }, [currentUser]);

  return (
    <ShopRequestsDataContext.Provider
      value={{
        filters,
        ordering,
        setOrdering,
        setFilters,
        setSearch,
        setPage,
      }}
    >
      <Container>
        <SearchAndCreateContainer>
          <ShopRequestSearch />
          {isShopRequestAdmin && <ShopRequestsCreateButton />}
        </SearchAndCreateContainer>
        <ShopRequestFilters />
        <ShopRequestsTabs />
        {filters.groupBy === SHOP_REQUEST_VIEW_FILTER_OPTIONS[0].value ? (
          <ShopRequestsGroupTable />
        ) : (
          <ShopRequestsTable />
        )}
      </Container>
    </ShopRequestsDataContext.Provider>
  );
};

export default ShopRequestsData;
