import React from 'react';

import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import { ContentContainer } from './IncentivesProposalModalInfo';
import SavingsItem from '../common/SavingsItem';
import { IncentiveSavingType } from '../types';

const IncentivesProposalModalInfoSavings = () => {
  const { incentive } = useIncentivesProposalModalContext();

  return (
    <ContentContainer>
      <div style={{ fontFamily: 'var(--font-bold)' }}>Savings</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {incentive?.savings?.map((item: IncentiveSavingType) => (
          <SavingsItem key={`${item.type}-${item.name}`} item={item} />
        ))}
        {!incentive && <span>No savings</span>}
      </div>
    </ContentContainer>
  );
};

export default IncentivesProposalModalInfoSavings;
