import { useCallback } from 'react';

import { useImmerReducer } from 'use-immer';

export const PAGINATION = {
  PAGE: 1,
  PAGE_SIZE: 30,
  MEDIUM_PAGE_SIZE: 300,
  MAX_PAGE_SIZE: 1000,
  MAX_LARGE_PAGE_SIZE: 10000,
};

export const INITIAL_STATE = {
  items: undefined,
  totalItemsCount: 0,
  totalPages: undefined,
  hasMore: false,
  filters: {
    page: PAGINATION.PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
    search: '',
    groupBy: undefined,
  },
  ordering: undefined,
};

const dataListActionTypes = {
  setItems: 'SET_ITEMS',
  setPage: 'SET_PAGE',
  setPageSize: 'SET_PAGE_SIZE',
  setSearch: 'SET_SEARCH',
  setFilters: 'SET_FILTERS',
  setOrdering: 'SET_ORDERING',
};

export const dataListReducer = (draft: any, action: any) => {
  switch (action.type) {
    case dataListActionTypes.setItems: {
      const { results, count, extend, reversed } = action.payload;

      if (extend) {
        if (reversed) {
          draft.items = [...results, ...draft.items];
        } else {
          draft.items = [...draft.items, ...results];
        }
      } else {
        draft.items = results;
        draft.totalItemsCount = count;
        draft.totalPages = Math.ceil(count / draft.filters.pageSize);
      }

      draft.hasMore = draft.filters.page < draft.totalPages && draft.totalPages !== 1;
      break;
    }
    case dataListActionTypes.setPage:
      draft.filters.page = action.payload;
      break;
    case dataListActionTypes.setPageSize:
      draft.filters.page = PAGINATION.PAGE;
      draft.filters.pageSize = action.payload;
      break;
    case dataListActionTypes.setSearch:
      draft.filters.page = PAGINATION.PAGE;
      draft.filters.search = action.payload;
      break;
    case dataListActionTypes.setFilters:
      draft.filters = { ...draft.filters, ...action.payload, page: PAGINATION.PAGE };
      break;
    case dataListActionTypes.setOrdering:
      draft.filters.page = PAGINATION.PAGE;
      if (draft.ordering === action.payload) {
        draft.ordering = `-${action.payload}`;
      } else {
        draft.ordering = action.payload;
      }
      break;
    default:
      console.warn(`Unhandled action ${JSON.stringify(action)}`);
      break;
  }
};

type ItemsDataType = {
  results: any[];
  count: number;
  reversed?: boolean;
};

/**
 * Handle list with pagination, search and filters
 */
export const useDataList = ({ reducer = dataListReducer, initialState = {} } = {}) => {
  const _initialState = { ...INITIAL_STATE, ...initialState };
  const [{ items, totalItemsCount, totalPages, filters, hasMore, ordering }, dispatch] = useImmerReducer(
    reducer,
    _initialState
  );

  const setItems = useCallback(
    ({ results, count, reversed }: ItemsDataType) =>
      dispatch({ type: dataListActionTypes.setItems, payload: { results, count, reversed } }),
    [dispatch]
  );

  const setPage = useCallback(
    (value: number) => dispatch({ type: dataListActionTypes.setPage, payload: value }),
    [dispatch]
  );

  const setPageSize = useCallback(
    (value: number) => dispatch({ type: dataListActionTypes.setPageSize, payload: value }),
    [dispatch]
  );

  const setSearch = useCallback(
    (value: string) => dispatch({ type: dataListActionTypes.setSearch, payload: value }),
    [dispatch]
  );

  const setFilters = useCallback(
    (value: any) => dispatch({ type: dataListActionTypes.setFilters, payload: value }),
    [dispatch]
  );

  const setOrdering = useCallback(
    (value: string) => dispatch({ type: dataListActionTypes.setOrdering, payload: value }),
    [dispatch]
  );

  return {
    items,
    totalItemsCount,
    totalPages,
    hasMore,
    filters,
    ordering,
    setItems,
    setPage,
    setPageSize,
    setSearch,
    setFilters,
    setOrdering,
  };
};

export default useDataList;
