import axios from '../config/axiosPrivate';

export const fetchSwotCategoriesScores = async (communityId: number) => {
  const url = `/communities/${communityId}/swot-category-scores`;
  const response = await axios.get(url);
  return response.data;
};

export const fetchSwotScores = async (competitorId: number | null) => {
  const url = `/communities/${competitorId}/swot-scores`;
  const response = await axios.get(url);
  return response.data;
};
