import React, { useState } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { getShopRequests } from 'apis/ShopRequestAPI';

import ShopRequestSortIcon from './ShopRequestSortIcon';
import ShopRequestsStyledTable from './ShopRequestsStyledTable';
import { SHOP_REQUEST_COLUMNS, SHOP_REQUEST_VIEW_FILTER_OPTIONS, SHOP_REQUESTS_PAGE_SIZE } from '../constants';
import EditShopRequestModal from '../EditShopRequestModal/EditShopRequestModal';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import ShopRequestCommunity from '../TableColumns/ShopRequestCommunity';
import ShopRequestsDueDate from '../TableColumns/ShopRequestsDueDate';
import ShopRequestsShopper from '../TableColumns/ShopRequestsShopper';
import ShopRequestsStatus from '../TableColumns/ShopRequestsStatus';
import ShopRequestsUpdatedAt from '../TableColumns/ShopRequestsUpdatedAt';
import { MysteryShopRequestInbound } from '../types';
import { getShopRequestListingPayload } from '../utils';

const Container = styled.div`
  flex-grow: 1;
  background: var(--background-gray);
  padding: 0 10px;
  border-top: solid 1px var(--line-gray);
`;

const ShopRequestsTable = () => {
  const { ordering, filters, setPage, setOrdering } = useShopRequestsDataContext();
  const [modifyingShopRequest, setModifyingShopRequest] = useState<MysteryShopRequestInbound | null>(null);

  const {
    isLoading,
    isFetching,
    data,
    refetch: refetchData,
  } = useQuery({
    queryKey: ['shopRequests', filters, ordering],
    queryFn: () => getShopRequests(getShopRequestListingPayload(filters, ordering)),

    refetchOnWindowFocus: false,
    enabled: filters.groupBy !== SHOP_REQUEST_VIEW_FILTER_OPTIONS[0].value,
  });

  const columns = [
    {
      id: SHOP_REQUEST_COLUMNS.community.id,
      key: SHOP_REQUEST_COLUMNS.community.id,
      title: SHOP_REQUEST_COLUMNS.community.label,
      width: '27%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
      render: (value: any, record: any) => <ShopRequestCommunity shopRequest={record} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.shopper.id,
      key: SHOP_REQUEST_COLUMNS.shopper.id,
      title: SHOP_REQUEST_COLUMNS.shopper.label,
      width: '23%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
      render: (value: any, record: any) => <ShopRequestsShopper shopRequest={record} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.status.id,
      key: SHOP_REQUEST_COLUMNS.status.id,
      title: SHOP_REQUEST_COLUMNS.status.label,
      width: '20%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
      render: (value: any, record: any) => <ShopRequestsStatus shopRequest={record} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.updatedAt.id,
      key: SHOP_REQUEST_COLUMNS.updatedAt.id,
      title: SHOP_REQUEST_COLUMNS.updatedAt.label,
      width: '15%',
      sorter: true,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
      render: (value: any, record: any) => <ShopRequestsUpdatedAt shopRequest={record} />,
    },
    {
      id: SHOP_REQUEST_COLUMNS.dueDate.id,
      key: SHOP_REQUEST_COLUMNS.dueDate.id,
      title: SHOP_REQUEST_COLUMNS.dueDate.label,
      width: '15%',
      sorter: true,
      defaultSortOrder: 'ascend' as const,
      sortIcon: ({ sortOrder }: any) => <ShopRequestSortIcon sortOrder={sortOrder} />,
      render: (value: any, record: any) => <ShopRequestsDueDate shopRequest={record} />,
    },
  ];

  return (
    <Container>
      <ShopRequestsStyledTable
        columns={columns}
        dataSource={data?.results?.map((el: any) => ({ ...el, key: el.id }))}
        loading={isLoading || isFetching}
        onRow={(record) => ({
          onClick: () => setModifyingShopRequest(record as MysteryShopRequestInbound),
        })}
        pagination={{
          size: 'small',
          position: ['bottomLeft'],
          showSizeChanger: false,
          current: filters.page,
          pageSize: SHOP_REQUESTS_PAGE_SIZE,
          total: data?.count,
          onChange: (pageNumber: number) => {
            setPage(pageNumber);
          },
          showTotal: (total, range) => {
            const displayedItems = range[1] - range[0] + 1;
            return (
              <span>
                Showing {displayedItems} of {total} records
              </span>
            );
          },
        }}
        onChange={(pagination: any, filters: any, sorter: any) => {
          if (sorter) {
            if (!sorter.column) {
              setOrdering('');
            } else {
              const orderBy = sorter.order === 'descend' ? `-${sorter.column.id}` : sorter.column.id;
              if (orderBy !== ordering) {
                setOrdering(orderBy);
              }
            }
          }
          if (pagination) {
            setPage(pagination.current);
          }
        }}
      />
      {modifyingShopRequest && (
        <EditShopRequestModal
          shopRequest={modifyingShopRequest}
          refetchData={refetchData}
          onCancel={() => setModifyingShopRequest(null)}
        />
      )}
    </Container>
  );
};

export default ShopRequestsTable;
