import React from 'react';

import { ContentContainer } from './IncentivesProposalModalInfo';
import { useIncentivesContext } from '../IncentivesContext';

const IncentivesProposalModalInfoUnit = () => {
  const { selectedProposalUnit } = useIncentivesContext();

  return (
    <ContentContainer>
      <div style={{ fontFamily: 'var(--font-bold)' }}>Unit</div>
      <span>{selectedProposalUnit?.number}</span>
      <span
        style={{
          color: 'var(--text-secondary)',
        }}
      >
        {selectedProposalUnit?.name && <span>{selectedProposalUnit.name} · </span>}
        {selectedProposalUnit?.square_feet && <span>{selectedProposalUnit?.square_feet?.toLocaleString()} sq ft</span>}
      </span>
    </ContentContainer>
  );
};

export default IncentivesProposalModalInfoUnit;
