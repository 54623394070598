import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hook';
import { handleSetSelectedCommunity, handleSetSelectedManagementCompany } from '../../store/slices/globalSlice';

const Overview = () => {
  const { managementCompanyId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedManagementCompany, managementCompanyList } = useAppSelector((state) => state.global);

  useEffect(() => {
    if (managementCompanyId && managementCompanyList?.length) {
      const managementCompany = managementCompanyList.find((item) => item.id === parseInt(managementCompanyId));
      dispatch(handleSetSelectedManagementCompany(managementCompany));
      dispatch(handleSetSelectedCommunity(undefined));
    } else if (!managementCompanyId && selectedManagementCompany) {
      navigate(`/overview/${selectedManagementCompany.id}`);
    }
  }, [managementCompanyId, selectedManagementCompany]);

  if (location.pathname === '/home') {
    return <Navigate to="/overview" replace />;
  }

  return <Outlet />;
};

export default Overview;
