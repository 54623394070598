import React from 'react';

import styled from 'styled-components';

import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import Input from '../../lib/Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Label = styled.div`
  font-family: var(--font-bold);
  font-size: 14px
  color: var(--text-primary);
`;

const IncentivesCreateOffersInfoName = () => {
  const { name, setName } = useIncentivesCreateModalContext();

  const handleChange = (event: any) => {
    setName(event.target.value);
  };

  return (
    <Container>
      <Label>Name</Label>
      <Input
        defaultValue={name}
        value={name}
        style={{ height: 36, backgroundColor: 'white' }}
        onChange={handleChange}
      />
    </Container>
  );
};

export default IncentivesCreateOffersInfoName;
