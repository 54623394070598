import React, { useCallback, useEffect, useState } from 'react';

import { Avatar, SelectProps, Space } from 'antd';
import styled from 'styled-components';

import DropdownSearch from './DropdownSearch';
import { useSelectorContext } from './SelectorContext';
import { ClientDataType } from './types';
import { getClients } from '../../apis/UserAPI';
import useDataList from '../../utils/hooks/useDataList';

const OptionContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectorClient = () => {
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const { items, filters, setItems, setSearch } = useDataList();

  const { client, company, setClient, setCompany, setManagementCompany } = useSelectorContext();

  const fetchClients = useCallback(
    async ({ search }: any) => {
      try {
        const { results, count } = await getClients({
          search,
        });
        setItems({ results, count });
      } catch (e) {
        console.error(e);
      }
    },
    [setItems]
  );

  useEffect(() => {
    fetchClients(filters);
  }, [fetchClients, filters]);

  useEffect(() => {
    const dropdownOptions = (items || []).map((item: any) => ({
      value: item.id,
      label: item.name,
      email: item.email,
      avatar: item.avatar,
    }));

    setOptions(dropdownOptions);
  }, [items]);

  const handleSearch = (keyword: string) => {
    setSearch(keyword);
  };

  const handleSelect = (selectedClientId: number) => {
    const selectedClient = (items || []).find((item: ClientDataType) => item.id === selectedClientId);
    setClient(selectedClient);
    setCompany(undefined);
    setManagementCompany(undefined);
  };

  const handleClear = () => {
    setClient(undefined);
    setCompany(undefined);
    setManagementCompany(undefined);
    setSearch('');
  };

  return (
    <DropdownSearch
      value={client?.id}
      disabled={!!company && !client}
      placeholder={'Search User' as const}
      onSearch={handleSearch}
      onSelect={handleSelect}
      onClear={handleClear}
      options={options}
      optionRender={(option: any) => {
        return (
          <Space>
            <OptionContainer>
              <AvatarContainer>
                <Avatar size={30} src={option?.data.avatar} />
              </AvatarContainer>
              <InfoContainer>
                <span>
                  <b>{option.label}</b>
                </span>
                <span>{option.data.email}</span>
              </InfoContainer>
            </OptionContainer>
          </Space>
        );
      }}
    />
  );
};

export default SelectorClient;
