import dayjs from 'dayjs';

import { AccommodationDataType, CaresInfoDataType, LivingType } from './constants';
import { toCamelCase } from '../../../helpers/strings';

export const mapAccommodationToCareInfo = (record: Partial<AccommodationDataType>, livingTypes: LivingType[]) => {
  return {
    id: record?.id,
    key: record?.key,
    apartmentTypeId: record?.apartmentTypeId,
    squareFootage: record?.squareFootage,
    notes: record.notes,
    livingTypeId: livingTypes[0].id,
    livingTypes: livingTypes.map((livingType: LivingType) => ({
      id: livingType.id,
      value: record[toCamelCase(livingType.type)],
      type: livingType.type,
    })),
  };
};

export const mapCareInfoToAccommodation = (record: CaresInfoDataType) => {
  const accommodation: AccommodationDataType = {
    id: record?.id,
    key: record?.key,
    apartmentTypeId: record?.apartmentTypeId,
    livingTypeId: record.livingTypeId,
    squareFootage: record?.squareFootage,
    notes: record.notes,
    updatedBy: '',
    updatedAt: dayjs(),
  };

  record?.livingTypes.forEach((livingType) => {
    accommodation[toCamelCase(livingType.type)] = livingType.value;
  });

  return accommodation;
};
