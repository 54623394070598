export const SCREEN_WIDTH = 1920;
export const SCREEN_HEIGHT = 1080;
export const EXPORT_TIMEOUT = 30000;
export const PDF_IMAGE_OFFSET = 30;
export const PDF_IMAGE_PADDING = PDF_IMAGE_OFFSET * 2;

export const FILE_EXTENSION = {
  PNG: 'png',
  PDF: 'pdf',
};

export const COLUMN_MARGIN_RATIO = 0.1; // 10% of total column width
export const COLUMN_WIDTH = {
  SMALL: 10,
  MEDIUM: 30,
  LARGE: 60,
};

export const LABEL_FONT_SIZE = 14;
export const XAXIS_LABEL_LENGTH = 25;
export const LEGEND_MAX_ROWS = 10;
export const LEGEND_FONT_SIZE = 24;
export const LEGEND_FONT_FAMILY = 'AvenirLTPro-Medium';
export const COLOR_DEFAULT = '#275aff';
export const COLOR_OPACITY = 'FF'; // 70% Opacity
export const COLOR_PALETTES = {
  DEFAULT: [
    '#00429d',
    '#2b57a7',
    '#426cb0',
    '#5681b9',
    '#6997c2',
    '#7daeca',
    '#93c4d2',
    '#abdad9',
    '#caefdf',
    '#ffffe0',
  ],
  DIVERGING: [
    '#00429d',
    '#3e67ae',
    '#618fbf',
    '#85b7ce',
    '#b1dfdb',
    '#ffcab9',
    '#fd9291',
    '#e75d6f',
    '#c52a52',
    '#93003a',
  ],
  PASTEL: [
    '#ffadad',
    '#ffd6a5',
    '#fdffb6',
    '#caffbf',
    '#9bf6ff',
    '#a0c4ff',
    '#bdb2ff',
    '#ffc6ff',
    '#ceb8a1',
    '#d598f0',
    '#e3dcba',
    '#d7a4b7',
    '#f78e88',
    '#8297af',
    '#c1cc89',
    '#8fbdf9',
    '#e7f681',
    '#add9b2',
    '#d5a2f6',
    '#eaafe9',
    '#a7a1b4',
    '#bdf3d6',
    '#dbe1bb',
    '#9fb98e',
    '#ae93e2',
    '#84f1f9',
    '#b5c4d1',
    '#d6c1f1',
    '#bcba93',
    '#b4d7c7',
    '#dcd6b6',
    '#b1dddf',
    '#93e2df',
    '#cca280',
    '#e7f8b4',
    '#e7bfd2',
    '#a0e9d4',
    '#c0b9dc',
  ],
  CONTRAST: [
    '#54478c',
    '#2c699a',
    '#048ba8',
    '#0db39e',
    '#16db93',
    '#83e377',
    '#b9e769',
    '#efea5a',
    '#f1c453',
    '#f29e4c',
  ],
  EARTH: ['#797d62', '#9b9b7a', '#baa587', '#d9ae94', '#f1dca7', '#ffcb69', '#e8ac65', '#d08c60', '#b58463', '#997b66'],
  BRIGHT: [
    '#8d53bd',
    '#60b851',
    '#cf59bb',
    '#b0b044',
    '#5e73cb',
    '#de903a',
    '#4fa7d6',
    '#c74b33',
    '#4fb694',
    '#da477b',
    '#5b7d39',
    '#bc8ccd',
    '#a77c42',
    '#9f4b71',
    '#d57673',
  ],
};
