import React from 'react';

import styled from 'styled-components';

import HeaderUser from './HeaderUser';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 0px 30px;
  background-color: white;
`;

const Header = () => (
  <Container>
    <HeaderUser />
  </Container>
);

export default Header;
