import React from 'react';

import { Button, Col, Form, Input, Modal, Row } from 'antd';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  handleAddData: (values: any) => void;
  loading?: boolean;
}

const AddBrochureUrlModal: React.FC<Props> = ({ open, handleClose, title = '', handleAddData, loading }) => {
  const [form] = Form.useForm<any>();
  const onFinish = (values: any) => {
    form.resetFields();
    handleAddData({ data: values, type: 'url' });
  };

  const onClose = () => {
    form.resetFields();
    handleClose();
  };

  return (
    <>
      <Modal title={title} centered open={open} onCancel={onClose} width={500} footer={false}>
        <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off" form={form}>
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Form.Item
                name="imageUrl"
                label={<p className="form_label">URL:</p>}
                rules={[
                  { required: true, message: 'Please Enter Valid URL' },
                  {
                    type: 'url',
                    required: true,
                    message: 'You have entered wrong url',
                  },
                  { type: 'string', min: 6 },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddBrochureUrlModal;
