import React from 'react';

import { format } from 'date-fns';

import ChartTooltip from './ChartTooltip';
import {
  CHART_TYPE,
  LABEL_FONT_COLOR,
  LABEL_FONT_FAMILY,
  LABEL_FONT_FAMILY_COMMUNITY,
  LABEL_FONT_SIZE,
} from './constants';
import { COMMUNITY_TYPE } from '../../../constants';
import { WIDGET_TYPES } from '../constants';
import { valueFormatter } from '../DataTable/utils';

export const labelFormatter = (value: any, widgetType: number) => {
  return valueFormatter(value, widgetType);
};

export const axisLabelFormatter = (item: any, widgetType: number) => {
  const dateFormat = [WIDGET_TYPES.RATING_GRAPH, WIDGET_TYPES.RATING].includes(widgetType) ? 'MMM dd' : 'qqq yyyy';
  return item ? `${format(new Date(item), dateFormat)}` : '';
};

export const tooltipFormatter = (item: any, widgetType: number) => {
  return {
    name: item.community_name,
    value: valueFormatter(item?.value, widgetType),
  };
};

export const tooltipSorter = (originalItems: any) => {
  return originalItems.sort((a: any, b: any) => a.data.value - b.data.value);
};

export const tooltipContent = (title: string, data: any, type: string = CHART_TYPE.BAR) => {
  return <ChartTooltip title={title} data={data} type={type} />;
};

export const textStyle = (title: string, data: any) => {
  const isCommunity = data.find(
    (item: any) => title.includes(item.community_name) && item.community_type == COMMUNITY_TYPE.COMMUNITY
  );
  const fontFamily = isCommunity ? LABEL_FONT_FAMILY_COMMUNITY : LABEL_FONT_FAMILY;
  return { fill: LABEL_FONT_COLOR, fontFamily: fontFamily, fontSize: LABEL_FONT_SIZE };
};
