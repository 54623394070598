import React from 'react';

import { Button, Image, Input, Select } from 'antd';
import styled from 'styled-components';

import { useSettingsContext } from './SettingsContext';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  padding: 0;
  border: 0;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--line-gray);

  &&.ant-btn > span {
    display: flex;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-flow: row;
`;

const StyledSelect = styled(Select)`
  margin-right: 10px;

  &&& {
    .ant-select-selector {
      width: 148px;
    }

    &:hover,
    &:focus {
      .ant-select-selector {
        border: solid 1px var(--line-gray) !important;
      }
    }
    .ant-select-selector {
      padding: 0 10px;
      border-radius: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px var(--line-gray);
    }
  }
`;

type Props = {
  toggleMultipleSelection: () => void;
  setSearchTerm: (value: string) => void;
  setStatus: (value: any) => void;
  selectPopupWidth?: number;
};

const SettingsToolbar = ({ toggleMultipleSelection, setSearchTerm, setStatus, selectPopupWidth }: Props) => {
  const { filterOptions, isMultipleSelection, status, searchTerm } = useSettingsContext();
  return (
    <Container>
      <StyledButton
        style={
          isMultipleSelection
            ? {
                borderColor: 'var(--button-blue)',
              }
            : undefined
        }
        onClick={toggleMultipleSelection}
      >
        <Image
          src={isMultipleSelection ? '/icons/checklist-selected.svg' : '/icons/checklist.svg'}
          width={24}
          height={24}
          preview={false}
        />
      </StyledButton>
      <Filters>
        <StyledSelect
          options={filterOptions}
          value={status}
          onChange={(value: any) => setStatus(value)}
          popupMatchSelectWidth={selectPopupWidth}
        />
        <Input
          placeholder="Search communities"
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
        />
      </Filters>
    </Container>
  );
};

export default SettingsToolbar;
