import React, { createContext, useContext } from 'react';

import { SELECTOR_ITEM_TYPES } from './constants';
import { SelectorItemDatatype } from './types';

type SidebarContent = {
  filterType: string;
  filterKeyword: string;
  selectedItem?: SelectorItemDatatype;
  setFilterType: any;
  setFilterKeyword: any;
  handleItemSelected: any;
};

export const SidebarContext = createContext<SidebarContent>({
  filterType: SELECTOR_ITEM_TYPES.ALL,
  filterKeyword: '',
  selectedItem: undefined,
  setFilterType: () => {
    // Does Nothing
  },
  setFilterKeyword: () => {
    // Does Nothing
  },
  handleItemSelected: () => {
    // Does Nothing
  },
});

export const useSelectorContext = () => useContext(SidebarContext);
