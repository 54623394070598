import React, { useEffect, useState } from 'react';

import { Button, Select, Space } from 'antd';

interface ComponentProps {
  placeholder?: string;
  options?: any[];
  loading?: boolean;
  disabled?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  initValue?: any[];
  onApply?: (values: any[]) => void;
  onSelect?: (values: any[]) => void;
  onClear?: () => void;
}

const SelectInput: React.FC<ComponentProps> = ({
  placeholder = 'Select',
  options = [],
  loading = false,
  disabled = false,
  mode,
  initValue,
  onApply = () => {
    // Do nothing
  },
  onSelect = () => {
    // Do nothing
  },
  onClear = () => {
    // Do nothing
  },
}) => {
  const [value, setValue] = useState<string[]>(initValue ? initValue : []);

  const handleSelect = (value: any) => {
    if (typeof onSelect === 'function') {
      onSelect(value);
    }
  };

  const handleSelectAll = () => {
    setValue(options);
  };

  const handleSelectNone = () => {
    setValue([]);
    onApply([]);
  };

  useEffect(() => {
    if (!initValue) return;
    setValue(initValue);
  }, [initValue ? initValue : null]);

  return (
    <Select
      showSearch
      mode={mode}
      value={value}
      options={options}
      onChange={(newValue: string[]) => {
        setValue(newValue);
      }}
      placeholder={placeholder}
      maxTagCount="responsive"
      style={{ width: '100%' }}
      listItemHeight={45}
      listHeight={600}
      onDeselect={(val) => {
        onApply(value.filter((item) => item != val));
      }}
      onClear={() => {
        onApply([]);
        onClear();
      }}
      onSelect={handleSelect}
      allowClear
      loading={loading}
      disabled={disabled}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
      }
      dropdownRender={(menu) => (
        <div style={{ padding: '10px' }}>
          {!!mode && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Space>
                <Button size="small" onClick={handleSelectAll}>
                  Select All
                </Button>
                <Button size="small" onClick={handleSelectNone}>
                  Select None
                </Button>
              </Space>
              <Space>
                <Button size="small" onClick={() => onApply(value)}>
                  Apply
                </Button>
              </Space>
            </div>
          )}
          <br />
          {menu}
        </div>
      )}
    />
  );
};

export default SelectInput;
