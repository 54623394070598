import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'antd';
import styled from 'styled-components';

import { SELECTOR_ITEM_TYPES } from './constants';
import { SidebarContext } from './SidebarContext';
import SidebarSelectorButton from './SidebarSelectorButton';
import SidebarSelectorDropdown from './SidebarSelectorDropdown';
import { SelectorItemDatatype } from './types';
import { ACTOR_TYPE, USER_LEVELS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { handleSetSelectedCommunity, handleSetSelectedManagementCompany } from '../../../store/slices/globalSlice';

const Container = styled.div`
  position: relative;
  padding: 0px 10px 20px 10px;
`;

const SidebarSelector = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedClient, selectedCompany, selectedManagementCompany, selectedCommunity } = useAppSelector(
    (state) => state.global
  );

  const [filterType, setFilterType] = useState<string>(SELECTOR_ITEM_TYPES.ALL);
  const [filterKeyword, setFilterKeyword] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<SelectorItemDatatype>();

  useEffect(() => {
    if (selectedCommunity) {
      setSelectedItem({
        id: selectedCommunity.id,
        type: selectedCommunity.type,
        name: selectedCommunity.name,
      });
    } else if (selectedManagementCompany) {
      setSelectedItem({
        id: selectedManagementCompany.id,
        type: SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY,
        name: selectedManagementCompany.management_company,
      });
    } else if (selectedCompany && selectedClient?.level != USER_LEVELS.MANAGEMENT_COMPANY) {
      setSelectedItem({
        id: selectedCompany.id,
        type: SELECTOR_ITEM_TYPES.COMPANY,
        name: selectedCompany.name,
      });
    }
  }, [selectedManagementCompany, selectedCommunity, selectedCompany]);

  const handleItemSelected = (item: SelectorItemDatatype) => {
    if (ACTOR_TYPE.COMMUNITY === item.type) {
      navigate(`/community/${item.id}`);
    } else if (ACTOR_TYPE.COMPETITOR === item.type) {
      navigate(`/competitor/${item.id}`);
    } else if (item.type === ACTOR_TYPE.MANAGEMENT_COMPANY) {
      dispatch(handleSetSelectedCommunity(undefined));
      navigate(`/overview/${item.id}`);
    } else if (item.type === ACTOR_TYPE.COMPANY) {
      dispatch(handleSetSelectedManagementCompany(undefined));
      dispatch(handleSetSelectedCommunity(undefined));
      navigate(`/overview/`);
    }
  };

  return (
    <SidebarContext.Provider
      value={{
        filterKeyword,
        filterType,
        setFilterType,
        setFilterKeyword,
        selectedItem,
        handleItemSelected,
      }}
    >
      <Container>
        <Dropdown menu={{ items: [] }} dropdownRender={(menu: any) => <SidebarSelectorDropdown />}>
          <div>
            <SidebarSelectorButton />
          </div>
        </Dropdown>
      </Container>
    </SidebarContext.Provider>
  );
};

export default SidebarSelector;
