import React from 'react';

import styled from 'styled-components';

import { useIncentivesDataContext } from '../IncentivesDataContext';

const Link = styled.span`
  color: var(--active-blue);
  font-size: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  pointer-events: cursor;
`;

const IncentivesFiltersReset = () => {
  const { filters, resetFilters } = useIncentivesDataContext();

  const currentFilters = [
    filters.communities,
    filters.roomTypes,
    filters.careTypes,
    filters.unitAvailability,
    filters.incentiveStatus,
    filters.proposalStatus,
  ];
  const active = currentFilters.some((filter: any) => Boolean(filter.length));

  if (!active) return null;

  return <Link onClick={resetFilters}>Reset</Link>;
};

export default IncentivesFiltersReset;
