import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';
import ShopRequestStatusSelector from '../ShopRequestStatusSelector';

const Label = styled(Typography.Text)`
  margin-right: 10px;
  font-weight: 900;
  margin-right: 10px;
`;

const EditShopRequestModalStatus = () => {
  const { shopRequest, setShopRequest } = useEditShopRequestModalContext();
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestSalesperson = currentUser && isMysteryShopShopper(currentUser);

  return (
    <>
      <Label>Status</Label>
      <ShopRequestStatusSelector
        shopRequest={shopRequest!}
        disabled={!!isShopRequestSalesperson && currentUser?.PAUser_Id !== shopRequest?.shopper?.userId}
        onChange={(status: number) => {
          if (shopRequest) {
            setShopRequest({ ...shopRequest, status });
          }
        }}
      />
    </>
  );
};

export default EditShopRequestModalStatus;
