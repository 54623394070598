import React from 'react';

import styled from 'styled-components';

import CardAverage from 'common/Widgets/Card/CardAverage';
import CardRating from 'common/Widgets/Card/CardRating';

import { useOverviewStatsContext } from './OverviewStatsContext';
import { LivingTypeRateDateType } from './types';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
`;

const OverviewAverages = () => {
  const { overviewStats } = useOverviewStatsContext();
  return (
    <Container>
      <CardAverage
        footerLabel="Competitor avg."
        title="Market Rate"
        subtitle="All Care Types . Average"
        value={overviewStats?.averageRate?.community}
        market_value={overviewStats?.averageRate?.market}
      />
      {overviewStats?.averageLivingTypeRates?.map((item: LivingTypeRateDateType) => (
        <CardAverage
          key={item.type}
          footerLabel="Competitor avg."
          title="Market Rate"
          subtitle={`${item.type} . Average`}
          value={item.rate}
          market_value={item.marketRate}
        />
      ))}
      <CardAverage
        footerLabel="Competitor avg."
        title="Rate/sq ft"
        subtitle="All Room Types . Average"
        value={overviewStats?.averagePricePerSquareFoot?.community}
        market_value={overviewStats?.averagePricePerSquareFoot?.market}
      />
      <CardRating
        title="Rating"
        subtitle="All Sources . All Categories"
        value={overviewStats?.averageRating?.community || 0}
        total={overviewStats?.averageRating?.total || 0}
        market_value={overviewStats?.averageRating?.market || 0}
      />
    </Container>
  );
};

export default OverviewAverages;
