import React, { useState } from 'react';

import { Button, Modal, Typography } from 'antd';

import { useUserOnboardingContext } from './UserOnboardingContext';
import { sendInvitation } from '../../apis/UserAPI';
import { STATUS } from '../../constants';
import { ClientDataType } from '../Selector/types';

interface Props {
  user: ClientDataType;
}

const UserOnboardingActionsSendInvitation = ({ user }: Props) => {
  const { showMessage, refresh } = useUserOnboardingContext();

  const [status, setStatus] = useState(STATUS.IDLE);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen((prev) => !prev);
  };

  const handleConfirm = async () => {
    try {
      setStatus(STATUS.LOADING);
      const response = await sendInvitation(user.id);
      showMessage('success', 'User invitation sent!');
      refresh();
      setStatus(STATUS.LOADED);
    } catch (e) {
      setStatus(STATUS.FAILURE);
      showMessage('error', 'Trouble sending user invitation.');
      console.error('Trouble sending user invitation.', e);
    } finally {
      setModalOpen(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button type="primary" size={'small'} onClick={handleOpen}>
        Send Invitation
      </Button>
      <Modal
        title={`Are you sure you want to send invitation to ${user.name || user.username}`}
        open={modalOpen}
        cancelButtonProps={{
          disabled: status === STATUS.LOADING,
        }}
        okButtonProps={{
          disabled: status === STATUS.LOADING,
          loading: status === STATUS.LOADING,
        }}
        onOk={handleConfirm}
        onCancel={handleCancel}
      >
        <Typography.Paragraph>
          By proceeding, an email will be sent to {user.email} containing login credentials, along with instructions for
          setting a new password upon first successful login.
        </Typography.Paragraph>
        <Typography.Paragraph type={'secondary'}>
          <b>Please note:</b> The current user's login credentials will become invalid once this action is completed.
        </Typography.Paragraph>
      </Modal>
    </>
  );
};

export default UserOnboardingActionsSendInvitation;
