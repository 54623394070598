import React, { ReactNode } from 'react';

import { Avatar as AntDAvatar } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import { ACTOR_TYPE } from '../../constants';

const StyledAvatar = styled(AntDAvatar)`
  color: var(--positive-green);
  background-color: var(--positive-green-light);
  border: 1px solid var(--positive-green);

  &.competitor {
    color: var(--neutral-purple);
    background-color: var(--neutral-purple-light);
    border: 1px solid var(--neutral-purple);
  }
  &.company {
    background-color: var(--positive-green);
  }
  span {
    font-size: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  name?: string;
  type: string;
  icon?: ReactNode;
}

const Avatar = ({ name, type, icon }: Props) => (
  <Container>
    <StyledAvatar
      className={classNames({ competitor: type === ACTOR_TYPE.COMPETITOR, company: type === ACTOR_TYPE.COMPANY })}
      icon={icon}
      size={30}
    >
      {name ? name[0] : 'n/a'}
    </StyledAvatar>
  </Container>
);

export default Avatar;
