
import React, { useEffect } from 'react';

import { FormInstance } from 'antd';
import dayjs from 'dayjs';

import { SIGNATURE_DOCUMENT_TYPES } from './constants';
import { INCENTIVES_FEES_TYPE } from './create/constants';
import { DEFAULT_INCENTIVE_PROPOSAL_LEAD, PROPOSAL_DURATION } from './proposal/constants';
import { IncentiveSavingType, IncentiveType, ProposalType, UnitType } from './types';

type Props = {
  form: FormInstance<any>;
  incentive: IncentiveType | undefined;
  selectedProposalUnit: UnitType | undefined;
  selectedProposal: ProposalType | undefined;
  readOnly?: boolean;
};

const useIncentiveInitialFormValues = ({ form, incentive, selectedProposalUnit, selectedProposal, readOnly }: Props) => {
  useEffect(() => {
    const alwaysSelectedRates = [INCENTIVES_FEES_TYPE.MONTHLY_RENT];
    const selectedIncentiveProposalRates =
      incentive?.savings?.map((item: IncentiveSavingType) =>
        item.type === INCENTIVES_FEES_TYPE.CUSTOM ? item.name : item.type
      ) ?? [];
    const selectedProposalRates = [...alwaysSelectedRates, ...selectedIncentiveProposalRates];
  
    // Preselect highest possible proposal length up to seven days
    const hasEndDate = Boolean(incentive?.end_at);
    const daysDifferenceIncentiveEnds = hasEndDate
      ? dayjs(incentive?.end_at).diff(dayjs(), 'day')
      : PROPOSAL_DURATION.SEVEN;
    const availableProposalDurationOptions = Object.values(PROPOSAL_DURATION).filter(
      (item: number) => item <= Math.min(daysDifferenceIncentiveEnds, PROPOSAL_DURATION.SEVEN)
    );
    const proposalDuration = availableProposalDurationOptions[availableProposalDurationOptions.length - 1];
  
    let signatureDocumentsInitialFormValues = selectedProposalUnit?.community?.signature_documents
      ? selectedProposalUnit.community.signature_documents.map(document => document.id)
      : undefined;
    // uncheck Addendum i if there is no incentive
    if (!incentive && signatureDocumentsInitialFormValues) {
      const incentiveAddendumDocument = selectedProposalUnit!.community!.signature_documents!.find(document => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_INCENTIVE);
      if (incentiveAddendumDocument) {
        signatureDocumentsInitialFormValues = signatureDocumentsInitialFormValues.filter(documentId => documentId !== incentiveAddendumDocument.id);
      }
    }
    // uncheck Addendum P if Rates Pet Fee is unchecked
    if (!form.getFieldValue('rates').includes(INCENTIVES_FEES_TYPE.PET_FEE) && signatureDocumentsInitialFormValues) {
      const petFeeAddendumDocument = selectedProposalUnit!.community!.signature_documents!.find(document => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_P);
      if (petFeeAddendumDocument && signatureDocumentsInitialFormValues) {
        signatureDocumentsInitialFormValues = signatureDocumentsInitialFormValues.filter(documentId => documentId !== petFeeAddendumDocument.id);
      }
    }
    // uncheck Addendum E from intially checked fields
    const motorizedAddendumDocument = selectedProposalUnit?.community?.signature_documents?.find(document => document.type === SIGNATURE_DOCUMENT_TYPES.TYPE_E);
    if (motorizedAddendumDocument && signatureDocumentsInitialFormValues) {
      signatureDocumentsInitialFormValues = signatureDocumentsInitialFormValues.filter(documentId => documentId !== motorizedAddendumDocument.id);
    }
  
    form.setFieldsValue({
      lead: (readOnly && selectedProposal) ? selectedProposal.lead : DEFAULT_INCENTIVE_PROPOSAL_LEAD,
      resident: (readOnly && selectedProposal?.resident) ? { ...selectedProposal.resident, enabled: true } : undefined,
      second_person: (readOnly && selectedProposal?.second_person) ? { ...selectedProposal.second_person, enabled: true } : undefined,
      rates: (readOnly && selectedProposal) ? selectedProposal.pricing.map(rate => rate.type) : selectedProposalRates,
      time: {
        limit: Boolean(incentive),
        duration: proposalDuration,
      },
      signature_documents_ids: (readOnly && selectedProposalUnit?.signature_documents_ids) ? selectedProposalUnit?.signature_documents_ids : signatureDocumentsInitialFormValues,
    });
  }, [incentive, selectedProposal, selectedProposalUnit, readOnly, form]);
};

export default useIncentiveInitialFormValues;

