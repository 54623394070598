import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { OccupancyType } from './constants';
import { useOccupanciesContext } from './OccupanciesContext';
import OccupanciesFormHistoryTable from './OccupanciesFormHistoryTable';
import { mapCommunityOccupancyResponseToData } from './utils';
import { STATUS } from '../../../constants';

const OccupanciesFormHistory = () => {
  const { communityId, showMessage } = useOccupanciesContext();

  const [dataLoading, setDataLoading] = React.useState(STATUS.IDLE);
  const [occupancies, setOccupancies] = useState<any[]>([]);

  const getCommunityOccupancies = () => {
    setDataLoading(STATUS.LOADING);
    axios
      .get(`/communities/${communityId}/occupancies?active=0`)
      .then((response) => {
        const data = response.data;
        const formatted_data: OccupancyType[] = data?.map((item: any) => mapCommunityOccupancyResponseToData(item));
        setOccupancies(formatted_data);
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to fetch Community Occupancies');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const deleteCommunityOccupancies = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    axios
      .delete(`/communities/${communityId}/occupancies/${item.id}`)
      .then((response) => {
        const occupancy_index = occupancies.findIndex((occupancy) => occupancy.id === item.id);
        occupancies.splice(occupancy_index, 1);
        setOccupancies([...occupancies]);
        showMessage('success', 'Historical Community Occupancy deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete historical Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  useEffect(() => {
    if (communityId) {
      getCommunityOccupancies();
    }
  }, [communityId]);

  return (
    <OccupanciesFormHistoryTable
      loading={dataLoading === STATUS.LOADING}
      data={occupancies}
      handleDelete={deleteCommunityOccupancies}
    />
  );
};

export default OccupanciesFormHistory;
