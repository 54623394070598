import React from 'react';

import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 11px 24px 20px;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 370px;
  gap: 8px;
}
`;

export default CardContainer;
