import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReloadOutlined } from '@ant-design/icons/lib';
import { Button, Popconfirm } from 'antd';
import styled from 'styled-components';

import { useCommunityReviewsContext } from './CommunityReviewsContext';
import { deleteCommunityReviews, initiateReviewsRequest } from '../../../apis/ReviewsAPI';
import { STATUS } from '../../../constants';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const CommunityReviewsRequestsListHeader = () => {
  const { communityId } = useParams();
  const { refreshRender, showMessage } = useCommunityReviewsContext();

  const [status, setStatus] = useState(STATUS.IDLE);

  const onRefresh = () => {
    showMessage('info', 'Refreshing reviews...');
    refreshRender();
  };

  const onInitiateReviewsRequest = async () => {
    try {
      setStatus(STATUS.LOADING);
      if (!communityId) throw new Error('Missing community ID when fetching reviews!');

      await initiateReviewsRequest([parseInt(communityId)]);
      refreshRender();
      setStatus(STATUS.LOADED);
    } catch (e) {
      setStatus(STATUS.FAILURE);
      showMessage('error', 'Trouble initiating fetching of community reviews');
    }
  };

  const onRemoveReviews = async () => {
    try {
      setStatus(STATUS.LOADING);
      if (!communityId) throw new Error('Missing community ID when deleting reviews!');

      await deleteCommunityReviews(parseInt(communityId));
      refreshRender();
      setStatus(STATUS.LOADED);
    } catch (e) {
      setStatus(STATUS.FAILURE);
      showMessage('error', 'Trouble removing community reviews');
    }
  };

  return (
    <Container>
      <span>Active Reviews Jobs</span>
      <ButtonContainer>
        <Button
          type={'primary'}
          size={'small'}
          icon={<ReloadOutlined />}
          disabled={status === STATUS.LOADING}
          onClick={onRefresh}
        >
          Refresh
        </Button>
        <Popconfirm
          title="Initiate Reviews Fetching"
          description="Are you sure to start reviews job for this community?"
          onConfirm={onInitiateReviewsRequest}
          okText="Yes"
          cancelText="No"
          disabled={status === STATUS.LOADING}
        >
          <Button size={'small'} disabled={status === STATUS.LOADING}>
            Fetch Reviews
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Remove All Reviews"
          description="Are you sure to remove all reviews for this community?"
          onConfirm={onRemoveReviews}
          okText="Yes"
          cancelText="No"
          disabled={status === STATUS.LOADING}
        >
          <Button size={'small'} danger disabled={status === STATUS.LOADING}>
            Delete All Reviews
          </Button>
        </Popconfirm>
      </ButtonContainer>
    </Container>
  );
};

export default CommunityReviewsRequestsListHeader;
