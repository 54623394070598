import React from 'react';

import { DeleteFilled } from '@ant-design/icons/lib';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { OccupancyType } from './constants';
import { GLOBAL_DATE_FORMAT, MEDIA_BREAK_POINTS } from '../../../constants';
import { useAppSelector } from '../../../store/hook';

interface Props {
  loading: boolean;
  data: OccupancyType[];
  handleDelete: (item: Partial<OccupancyType>) => void;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const OccupanciesFormHistoryTable = ({ loading, data, handleDelete }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;
  const missingDeletionPermission = permissions?.indexOf('PAScreens.delete_occupancies') == -1;

  const getTooltipContent = (record: any) => {
    return (
      <TooltipContent>
        <span>Created By: {record?.createdBy}</span>
        <span>Created Date: {dayjs(record?.createdAt).format(GLOBAL_DATE_FORMAT)}</span>
        <span>Updated By: {record?.updatedBy}</span>
        <span>Updated Date: {dayjs(record?.updatedAt).format(GLOBAL_DATE_FORMAT)}</span>
      </TooltipContent>
    );
  };

  const columns = [
    {
      title: 'Living Type',
      dataIndex: 'livingType',
      render: (value: string, record: OccupancyType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Accommodation',
      dataIndex: 'apartmentType',
      render: (value: string, record: OccupancyType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Total Units',
      dataIndex: 'totalApartmentsUnits',
      render: (value: number, record: OccupancyType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Vacant Units',
      dataIndex: 'freeApartmentsUnits',
      render: (value: number, record: OccupancyType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Occupancy %',
      dataIndex: 'occupancyPercentage',
      render: (value: string, record: OccupancyType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}%</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      render: (value: Dayjs, record: OccupancyType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value && value.format(GLOBAL_DATE_FORMAT)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      hidden: missingDeletionPermission,
      render: (_: any, record: Partial<OccupancyType>) => {
        return (
          <ButtonContainer>
            <Popconfirm
              title="Are you sure you want to delete this record?"
              placement="left"
              onConfirm={() => handleDelete(record)}
            >
              <Button size="small" type="link" danger icon={<DeleteFilled />} />
            </Popconfirm>
          </ButtonContainer>
        );
      },
    },
  ];

  return (
    <Table bordered loading={loading} scroll={{ x: MEDIA_BREAK_POINTS.MOBILE }} dataSource={data} columns={columns} />
  );
};

export default OccupanciesFormHistoryTable;
