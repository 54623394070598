import { format } from 'date-fns';

import { average } from '../../../utils/helpers/numbers';

export const transformCSVData = (columns: string[], data: any) => {
  const exportData = [['COMMUNITY', ...columns]];

  Object.values(data).forEach((value: any) => {
    const communityQuarterValues = columns.map((columnName: string) =>
      value[columnName] ? average(value[columnName]).toFixed(2) : ''
    );
    exportData.push([value.communityName, ...communityQuarterValues]);
  });

  if (exportData.length > 0 && exportData[0].length > 2) {
    exportData[0][1] = 'NOW';
  }

  return exportData;
};

export const getFilename = (communityName: string, widgetName: string) => {
  const adjustedCommunityName = communityName?.split(' ').join('_');
  const adjustedWidgetyName = widgetName?.split(' ').join('_');

  return `${adjustedCommunityName}_${adjustedWidgetyName}_${format(new Date(), 'yyyyMMdd')}`;
};
