import React, { createContext, useContext } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { IncentiveSavingType, UnitType } from '../types';

type IncentivesCreateModalContent = {
  loading: boolean;
  name: string;
  startDate: Dayjs;
  endDate?: Dayjs;
  description: string;
  savings: IncentiveSavingType[];
  units: UnitType[];
  handleClose: any;
  setLoading: any;
  setName: any;
  setStartDate: any;
  setDescription: any;
  setEndDate: any;
  setSavings: any;
  setUnits: any;
};

export const IncentivesCreateModalContext = createContext<IncentivesCreateModalContent>({
  loading: false,
  name: '',
  startDate: dayjs(),
  endDate: undefined,
  description: '',
  savings: [],
  units: [],
  handleClose: () => {
    // Do nothing
  },
  setLoading: () => {
    // Do nothing
  },
  setName: () => {
    // Do nothing
  },
  setStartDate: () => {
    // Do nothing
  },
  setEndDate: () => {
    // Do nothing
  },
  setDescription: () => {
    // Do nothing
  },
  setSavings: () => {
    // Do nothing
  },
  setUnits: () => {
    // Do nothing
  },
});

export const useIncentivesCreateModalContext = () => useContext(IncentivesCreateModalContext);
