import React from 'react';

import classNames from 'classnames';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from '../../../common/Widgets/constants';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';

const HeaderViewFiltersCareLevel = () => {
  const { viewFilters, setViewFilters } = useCommunityViewContext();

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, careLevels: values.map((item: any) => item.value) }));
  };

  const options = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.CARE_LEVEL].map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: viewFilters?.careLevels?.length > 0 })}
      placeholder={'Care Level'}
      options={options}
      defaultValue={viewFilters.careLevels}
      onSelect={handleSelect}
    />
  );
};

export default HeaderViewFiltersCareLevel;
