import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import { IncentiveType } from '../../types';

interface Props {
  item: IncentiveType;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-regular);
  color: var(--text-secondary);
  font-size: 12px;
  line-height: normal;
`;

const Date = styled.span`
  color: var(--text-primary);
  font-size: 14px;
`;

const IncentivesTableIncentivesDate = ({ item }: Props) => {
  return (
    <Container>
      <Date>{dayjs(item.created_at).format('MMM DD, YYYY')}</Date>
      <span>{dayjs(item.created_at).format('hh:mm A')}</span>
      <span>by {item.created_by}</span>
    </Container>
  );
};

export default IncentivesTableIncentivesDate;
