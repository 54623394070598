import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import OverviewCommunitiesAvatar from './OverviewCommunitiesAvatar';
import { ACTOR_TYPE } from '../../constants';

const CommunityCell = styled.div`
  display: flex;
  flex-direction: row;
  line-height: normal;
  padding-left: 10px;
  gap: 10px;
`;

const CommunityCellInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
`;

const StyledLink = styled(Link)`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
`;

const CommunityAddress = styled.span`
  font-size: 12px;
`;

interface Props {
  record: any;
}

const OverviewCommunitiesInfo = ({ record }: Props) => {
  const linkPrefix = record?.apartmenttype === ACTOR_TYPE.COMPETITOR ? ACTOR_TYPE.COMPETITOR : ACTOR_TYPE.COMMUNITY;

  return (
    <CommunityCell>
      <OverviewCommunitiesAvatar name={record?.community?.apartmentname} type={record?.apartmenttype} />
      <CommunityCellInfo>
        <StyledLink to={`/${linkPrefix}/${record.id}`}>{record?.community?.apartmentname}</StyledLink>
        <CommunityAddress>{record?.community?.apartmentaddress}</CommunityAddress>
      </CommunityCellInfo>
    </CommunityCell>
  );
};

export default OverviewCommunitiesInfo;
