import React from 'react';
import { useQueryClient } from 'react-query';

import { Button } from 'antd';
import axios from 'axios';
import styled from 'styled-components';

import { SWOT_CATEGORIES, WIDGET_TYPES } from 'common/Widgets/constants';
import { useWidgetContext } from 'common/Widgets/Widget/WidgetContext';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';
import { getCompetitorName } from 'selectors/communitySelector';
import { useAppSelector } from 'store/hook';
import { DEFAULT_SWOT_SCORES, getSwotScoresOutboundPayload } from 'utils/data/communities';

import { useEditSwotDataModalContext } from './EditSwotDataModalContext';

const StyledButton = styled(Button)`
  width: 142px;
  border-radius: 4px;
  margin-left: 10px;
`;
const SubmitSwotScoresButton = () => {
  const queryClient = useQueryClient();
  const { communityId } = useCommunityViewContext();
  const { filters, rangeType } = useWidgetContext();
  const { stepNumber, isEditMode, temporarySwotState, competitorId, onClose } = useEditSwotDataModalContext();
  const companyId = useAppSelector((state) => state.auth.currentUser?.company);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const communityName = useAppSelector((state) => state.global.selectedCommunity?.name);
  const { selectedManagementCompany } = useAppSelector((state) => state.global);
  const competitorName = useAppSelector((state) => getCompetitorName(state, competitorId));

  const showSaveButton = isEditMode || stepNumber === SWOT_CATEGORIES.length;

  const updateSwotScores = async () => {
    if (!competitorId || !currentUser?.email || !selectedManagementCompany?.id || !communityName) {
      return;
    }

    try {
      const existingId: number = temporarySwotState.id as number;
      const payload = {
        ...DEFAULT_SWOT_SCORES,
        ...temporarySwotState,
        ...getSwotScoresOutboundPayload({
          companyId: companyId!,
          competitorId,
          competitorName,
          communityId,
          communityName,
          managementCompanyId: selectedManagementCompany?.id,
          emailAddress: currentUser?.email,
          userId: currentUser?.user_id,
          id: existingId,
        }),
      };

      if (isEditMode) {
        await axios.put(`/communities/${competitorId}/swot-scores`, payload);
      } else {
        await axios.post(`/communities/${competitorId}/swot-scores`, payload);
      }

      // Reset SWOT Score and SWOT Performance graphs and reload their data
      queryClient.invalidateQueries([WIDGET_TYPES.SWOT_SCORE, communityId, filters, rangeType]);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('swot-performance'),
      });
      queryClient.invalidateQueries(['swot-categories-scores', communityId]);
      onClose();
    } catch (error) {
      console.log('error', 'Something went wrong while saving SWOT scores');
    }
  };

  return (
    <>
      {showSaveButton && (
        <StyledButton key="confirm" type="primary" onClick={updateSwotScores}>
          Save & Close
        </StyledButton>
      )}
    </>
  );
};

export default SubmitSwotScoresButton;
