import React, { useEffect, useState } from 'react';

import { Breadcrumb } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import { ACTOR_TYPE, USER_LEVELS } from '../../constants';
import { useAppSelector } from '../../store/hook';

const StyledBreadcrumb = styled(Breadcrumb)`
  li {
    color: var(--positive-green);

    .competitor {
      color: var(--competitor-purple);
    }

    &:last-child {
      color: var(--text-secondary);
    }
  }
`;

const HeaderBreadcrumbs = () => {
  const { selectedClient, selectedCompany, selectedManagementCompany, selectedCommunity } = useAppSelector(
    (state) => state.global
  );
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    if (selectedCommunity) {
      const isCompetitor = selectedCommunity.type === ACTOR_TYPE.COMPETITOR;
      const title = isCompetitor ? 'Competitor Community' : 'My community';

      setItems([
        {
          title: title,
          className: classNames({ competitor: isCompetitor }),
        },
        { title: selectedCommunity.address },
      ]);
    } else if (selectedManagementCompany && selectedCompany) {
      setItems([
        {
          title: 'Management Company',
        },
        { title: `Part of ${selectedCompany.name}` },
      ]);
    } else if (selectedCompany && selectedClient?.level != USER_LEVELS.MANAGEMENT_COMPANY) {
      setItems([
        {
          title: 'Company',
        },
        { title: ` ${selectedCompany.name}` },
      ]);
    }
  }, [selectedCompany, selectedManagementCompany, selectedCommunity]);

  return <StyledBreadcrumb items={items} separator="·" />;
};

export default HeaderBreadcrumbs;
