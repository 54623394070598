import { IncentiveProposalPricingType } from './types';
import {
  INCENTIVES_FEES_TYPE,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE,
  INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR,
} from '../create/constants';

export const getProposalPricingSavings = (rates: IncentiveProposalPricingType[]) => {
  const commonSavings = rates
    .filter((item: IncentiveProposalPricingType) => Boolean(item.discount) && Boolean(item.discount_type))
    .reduce((sum, current) => {
      const discountDurationFactor = INCENTIVES_SAVINGS_DURATION_TYPE_FACTOR[current.discount_duration_type!] ?? 1;
      const discountDuration = (current?.discount_duration ?? 1) * discountDurationFactor;

      if (current.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT && Boolean(current?.discount)) {
        return sum + (current?.discount ?? 0) * discountDuration;
      } else if (current.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE && Boolean(current?.discount)) {
        return sum + (current?.amount ?? 0) * ((current?.discount ?? 0) / 100) * discountDuration;
      }

      return sum;
    }, 0);

  const customSavings = rates
    .filter((item: IncentiveProposalPricingType) => item.type === INCENTIVES_FEES_TYPE.CUSTOM && Boolean(item.amount))
    .reduce((sum, current) => current.amount ?? 0, 0);

  return commonSavings + customSavings;
};
