import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

interface Props {
  title: string;
  description?: string;
}

const AdminPageHeader = ({ title, description }: Props) => {
  return (
    <Container>
      <Typography>
        <Typography.Title level={1}>{title}</Typography.Title>
        <Typography.Paragraph type={'secondary' as const}>{description}</Typography.Paragraph>
      </Typography>
    </Container>
  );
};

export default AdminPageHeader;
