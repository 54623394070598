import React from 'react';

import { Card, Col, Form, Input, Radio, RadioChangeEvent, Row, Select } from 'antd';

interface Props {
  setCurrentConfig: React.Dispatch<React.SetStateAction<any[]>>;
  currentConfig: any[];
  handleCalculationChange: (val: any) => void;
  calculationOptions: any[];
}

function ConfigurationHeader({ currentConfig, calculationOptions, handleCalculationChange, setCurrentConfig }: Props) {
  const handleStatusChange = (e: RadioChangeEvent) => {
    //
    const prevData = [...currentConfig];
    if (prevData.length) {
      prevData[0].status = e.target.value;
      setCurrentConfig(prevData);
    }
  };

  return (
    <Form>
      <Row gutter={[30, 10]}>
        <Col xs={24} md={18}>
          <Card
            className="box_shadow"
            style={{
              background: '#f7f7f7',
              height: '100%',
              marginRight: '20px',
            }}
          >
            <Row gutter={[20, 10]} style={{ marginBottom: '10px' }}>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Date:</p>

                  <Select
                    placeholder={'Select'}
                    options={calculationOptions}
                    onChange={(val) => handleCalculationChange(val)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                {/* <Form.Item>
                  <p className="form_label">Process Id:</p>
                  <Input
                    value={currentConfig[0]?.process_id}
                    placeholder="0"
                    type="text"
                  />
                </Form.Item> */}
              </Col>
            </Row>

            <Row gutter={[20, 10]} style={{ marginBottom: '10px' }}>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Current Occupancy:</p>
                  <Input value={currentConfig[0]?.current_occupancy} placeholder="0" type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Monthly Predicted Lead Volume:</p>
                  <Input value={currentConfig[0]?.lead_volume} placeholder="0" type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Sales Conversion Standard:</p>
                  <Input value={currentConfig[0]?.baseline_delta} placeholder="0" type="number" />
                </Form.Item>
              </Col>
              {/* <Col xs={24} md={8} style={{ display: "none" }}>
                <Form.Item>
                  <p className="form_label">Baseline Swot:</p>
                  <Input
                    value={currentConfig[0]?.baseline_swot}
                    placeholder="0"
                    type="number"
                  />
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={[20, 10]} style={{ marginBottom: '10px' }}>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Goal Occupancy:</p>
                  <Input value={currentConfig[0]?.goal_occupancy} placeholder="0" type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Monthly Predicted Move-Outs:</p>
                  <Input value={currentConfig[0]?.move_outs} placeholder="0" type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Competitor Market Avg SWOT Score:</p>
                  <Input value={currentConfig[0]?.competitors_avg_swot_score} placeholder="0" type="number" />
                </Form.Item>
              </Col>
              {/* <Col xs={24} md={8} style={{ display: "none" }}>
                <Form.Item>
                  <p className="form_label">Community Swot Score:</p>
                  <Input
                    value={currentConfig[0]?.community_swot_score}
                    placeholder="0"
                    type="number"
                  />
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={[20, 10]} style={{ marginBottom: '10px' }}>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Months to Goal:</p>
                  <Input value={currentConfig[0]?.months_to_goal} placeholder="0" type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}></Col>
              <Col xs={24} md={8}>
                <Form.Item>
                  <p className="form_label">Status:</p>
                  <Radio.Group value={currentConfig[0]?.status} onChange={handleStatusChange}>
                    <Radio value={1}>Active</Radio>
                    <Radio value={0}>Inactive</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card
            className="box_shadow"
            style={{
              background: '#fbfbfb',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Row gutter={[20, 10]}>
              <Col xs={24}>
                <Row gutter={[20, 10]} style={{ width: '60vw' }}>
                  <Col xs={24} md={8}>
                    <Form.Item>
                      <p className="form_label">Sales Conversion Delta:</p>
                      <Input value={currentConfig[0]?.needed_to_goal} placeholder="0" type="number" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 10]} style={{ width: '60vw' }}>
                  <Col xs={24} md={8}>
                    <Form.Item>
                      <p className="form_label">Inquiry to Move-in % Needed:</p>
                      <Input
                        value={currentConfig[0]?.inquiry_needed}
                        addonAfter="%"
                        placeholder="0"
                        type="number"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[20, 10]} style={{ width: '60vw' }}>
                  <Col xs={24} md={8}>
                    <Form.Item>
                      <p className="form_label">Sales Conversion Delta:</p>
                      <Input
                        value={currentConfig[0]?.sales_conversion}
                        addonAfter="%"
                        placeholder="0"
                        type="number"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}

export default ConfigurationHeader;
