import React from 'react';

import { Button as AntDButton } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import styled from 'styled-components';

const StyledButton = styled(AntDButton)`
  font-family: var(--font-regular);
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &.ant-btn-primary:disabled {
    background: var(--button-blue);
    opacity: 0.3;
    color: var(--white);
  }
`;

const Button = (props: ButtonProps) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};

export default Button;
