import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { fetchSwotScores } from 'apis/SwotAPI';
import { SWOT_CATEGORIES } from 'common/Widgets/constants';
import Modal from 'components/lib/Modal';
import { CompetitorSwotScores } from 'components/Selector/types';
import { useAppDispatch } from 'store/hook';
import { handleSetCompetitorSwotScores } from 'store/slices/globalSlice';

import { EditSwotDataModalContext } from './EditSwotDataModalContext';
import EditSwotDataModalFooter from './EditSwotDataModalFooter';
import EditSwotDataModalHeader from './EditSwotDataModalHeader';
import StepSelector from './StepSelector';
import SwotCategories from './SwotCategories';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  .ant-btn-primary:disabled {
    opacity: 0.3;
    color: #fff;
    background: #275aff;
  }

  &.ant-modal {
    width: 1040px!important;
  }
  &&& {
    .ant-modal-body {
      padding: 0px;
    }
`;

type Props = {
  open: boolean;
  competitorId: number | null;
  onClose: () => void;
};

const EditSwotDataModal = ({ open, onClose, competitorId }: Props) => {
  const dispatch = useAppDispatch();
  const [stepNumber, setStepNumber] = useState(1);
  const [isEditMode, setEditMode] = useState(false);
  const [temporarySwotState, setTemporarySwotState] = useState({});
  const selectedCategory = SWOT_CATEGORIES[stepNumber - 1];

  useQuery({
    queryKey: ['competitorSwotScores', competitorId],
    queryFn: () => fetchSwotScores(competitorId),
    onSuccess: (data) => {
      if (data && competitorId && !Object.keys(temporarySwotState).length) {
        setEditMode(true);
        dispatch(handleSetCompetitorSwotScores({ competitorId, scores: data }));
        setTemporarySwotState(data);
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!competitorId,
  });

  const updateTemporaryState = useCallback(
    (scores: CompetitorSwotScores) => {
      setTemporarySwotState({ ...temporarySwotState, ...scores });
    },
    [setTemporarySwotState, temporarySwotState]
  );

  return (
    <EditSwotDataModalContext.Provider
      value={{
        competitorId,
        isEditMode,
        stepNumber,
        setStepNumber,
        temporarySwotState,
        setTemporarySwotState: updateTemporaryState,
        onClose,
      }}
    >
      <StyledModal open={open} closable destroyOnClose onCancel={onClose} footer={<EditSwotDataModalFooter />}>
        <Container>
          <EditSwotDataModalHeader competitorId={competitorId} />
          <StepSelector stepTitle={selectedCategory.label} />
          <SwotCategories selectedCategory={selectedCategory} />
        </Container>
      </StyledModal>
    </EditSwotDataModalContext.Provider>
  );
};

export default EditSwotDataModal;
