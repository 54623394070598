import styled from 'styled-components';

const NoDataInfo = styled.div`
  width: 100%;
  height: 100%;
  color: var(--text-secondary);
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  align-content: center;
`;

export default NoDataInfo;
