import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { useCommunityReviewsContext } from './CommunityReviewsContext';
import CommunityReviewsRequestsList from './CommunityReviewsRequestsList';
import { REVIEW_REQUEST_STATUS } from './constants';
import { getCommunityReviewsRequests } from '../../../apis/ReviewsAPI';
import { STATUS } from '../../../constants';

const Container = styled.div`
  padding: 20px 0px;
`;

const CommunityReviewsRequests = () => {
  const { communityId } = useParams();
  const [reviewRequests, setReviewsRequests] = useState([]);
  const [status, setStatus] = useState(STATUS.IDLE);

  const { showMessage } = useCommunityReviewsContext();

  const getReviewsRequests = async (community: string) => {
    try {
      setStatus(STATUS.LOADING);
      const response = await getCommunityReviewsRequests(parseInt(community), {
        status: REVIEW_REQUEST_STATUS.INITIATED,
      });

      setReviewsRequests(response.results);
      setStatus(STATUS.LOADED);
    } catch (e) {
      setStatus(STATUS.FAILURE);
      showMessage('error', 'Trouble fetching reviews fetching jobs.');
    }
  };

  useEffect(() => {
    if (communityId) {
      getReviewsRequests(communityId);
    }
  }, [communityId]);

  return (
    <Container>
      <CommunityReviewsRequestsList requests={reviewRequests} disabled={status === STATUS.LOADING} />
    </Container>
  );
};

export default CommunityReviewsRequests;
