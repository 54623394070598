import React, { useEffect, useRef, useState } from 'react';

import GoogleMapReact, { fitBounds } from 'google-map-react';
import styled from 'styled-components';

import { GOOGLE_MAP_HEIGHT, GOOGLE_MAP_ZOOM, USA_CENTER_COORDINATES } from './constants';
import MapMarker from './MapMarker';
import { MapOptions } from '../types';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  a[href^="http://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span {
    display: none;
  }
  .gmnoprint div {
    background: none !important;
  }
  .gmnoprint,
  .gm-style-mtc {
    margin-bottom: 20px;
  }
  .gm-style button {
    margin-bottom: 20px;
  }
`;

type Props = {
  communities: any[];
  competitors: any[];
  getMapOptions: (maps: any) => MapOptions;
};

const Map = ({ communities, competitors, getMapOptions }: Props) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const [mapCenter, setMapCenter] = useState<any>(USA_CENTER_COORDINATES);
  const [mapHeight, setMapHeight] = useState<any>(GOOGLE_MAP_HEIGHT);
  const [zoom, setZoom] = useState<any>(GOOGLE_MAP_ZOOM);

  useEffect(() => {
    const allCommunities = [...communities, ...competitors];
    if (!allCommunities.length || !mapContainerRef?.current) return;
    const bounds = new google.maps.LatLngBounds();
    allCommunities.forEach((marker) => {
      bounds.extend(new google.maps.LatLng(marker?.latitude, marker?.longitude));
    });
    const newBounds = {
      ne: {
        lat: bounds.getNorthEast().lat(),
        lng: bounds.getNorthEast().lng(),
      },
      sw: {
        lat: bounds.getSouthWest().lat(),
        lng: bounds.getSouthWest().lng(),
      },
    };
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      setMapCenter(newBounds.ne);
      setZoom(GOOGLE_MAP_ZOOM);
    } else {
      const size = {
        width: mapContainerRef.current.clientWidth,
        height: mapContainerRef.current.clientHeight,
      };
      const { center, zoom } = fitBounds(newBounds, size);
      setMapCenter(center);
      setZoom(zoom);
    }
  }, [communities, competitors]);

  useEffect(() => {
    if (mapContainerRef.current) {
      const containerHeight = mapContainerRef.current.clientHeight;
      setMapHeight(containerHeight);
    }
  }, [mapContainerRef.current]);

  return (
    <Container ref={mapContainerRef}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${process.env.REACT_GMAP_API_KEY}`,
        }}
        center={mapCenter}
        zoom={zoom}
        options={getMapOptions}
        style={{
          height: mapHeight,
        }}
      >
        {[...communities, ...competitors]?.map((data: any, idx: number) => (
          <MapMarker key={idx} lat={data?.latitude} lng={data?.longitude} data={data} />
        ))}
      </GoogleMapReact>
    </Container>
  );
};

export default Map;
