import React from 'react';

import { Button } from 'antd';
import styled from 'styled-components';

import { FORM_MODE_SWITCH_TABS } from './constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

interface Props {
  selectedTab: any;
  setSelectedTab: any;
}

const FormModeSwitchTabs = ({ selectedTab, setSelectedTab }: Props) => (
  <Container>
    <Button
      type="text"
      className={selectedTab === FORM_MODE_SWITCH_TABS.EDIT ? 'p_healing_active_tab' : 'btnStyle'}
      shape="round"
      style={{ fontSize: 18, fontWeight: 600 }}
      onClick={() => setSelectedTab(FORM_MODE_SWITCH_TABS.EDIT)}
    >
      Edit
    </Button>
    <Button
      type="text"
      shape="round"
      style={{ fontSize: 18, fontWeight: 600 }}
      className={selectedTab === FORM_MODE_SWITCH_TABS.HISTORY ? 'p_healing_active_tab' : 'btnStyle'}
      onClick={() => setSelectedTab(FORM_MODE_SWITCH_TABS.HISTORY)}
    >
      History
    </Button>
  </Container>
);

export default FormModeSwitchTabs;
