import React, { useContext } from 'react';

import styled from 'styled-components';

import { CommunityViewWidgetModalContext } from './CommunityViewWidgetModalContext';
import { createFilter, deleteFilter, updatePartialFilter } from '../../../apis/FilterAPI';
import { createWidget, updatePartialWidget } from '../../../apis/WidgetAPI';
import { WIDGET_MODAL_TYPE } from '../../../common/Widgets/Widget/constants';
import { useRootContext } from '../../layout/RootContext';
import Button from '../../lib/Button';
import { useCommunityViewContext } from '../CommunityViewContext';

const Container = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 20px 30px;
  border-top: 1px solid var(--line-gray);
`;

const CommunityViewWidgetModalFooter = () => {
  const { showMessage } = useRootContext();
  const { viewId, viewRefetch } = useCommunityViewContext();
  const {
    id,
    type,
    name,
    note,
    filterId,
    competitors,
    careTypes,
    roomTypes,
    careLevels,
    reviewSources,
    reviewCategories,
    swotCategories,
    modalType,
    onClose,
  } = useContext(CommunityViewWidgetModalContext);

  const handleCreateWidget = async (filterId: number) => {
    await createWidget({
      type,
      name,
      note,
      view: viewId,
      filter: filterId,
    });
  };

  const handleUpdateWidget = async (widgetId: number) => {
    await updatePartialWidget(widgetId, {
      name,
      note,
    });
  };

  const handleUpdateFilter = async (filterId: number) => {
    await updatePartialFilter(filterId, {
      competitors,
      care_types: careTypes,
      room_types: roomTypes,
      care_levels: careLevels,
      review_sources: reviewSources,
      review_categories: reviewCategories,
      swot_categories: swotCategories,
    });
  };

  const handleCreate = async () => {
    await createFilter({
      competitors,
      care_types: careTypes,
      room_types: roomTypes,
      care_levels: careLevels,
      review_sources: reviewSources,
      review_categories: reviewCategories,
      swot_categories: swotCategories,
    })
      .then((response) => {
        handleCreateWidget(response.id)
          .then((response) => {
            showMessage('success', 'New widget added to the view.');
            viewRefetch();
            onClose();
          })
          .catch((reason) => {
            deleteFilter(response.id);
            showMessage('error', 'Trouble creating new widget.');
          });
      })
      .catch((reason: any) => {
        showMessage('error', 'Trouble creating new widget.');
      });
  };

  const handleUpdate = async () => {
    if (id) {
      handleUpdateWidget(id)
        .then((response) => {
          if (filterId) {
            handleUpdateFilter(filterId)
              .then((response) => {
                showMessage('success', 'Widget successfully updated.');
                viewRefetch();
                onClose();
              })
              .catch((reason: any) => {
                showMessage('error', 'Trouble updating widget filter.');
              });
          }
        })
        .catch((reason: any) => {
          showMessage('error', 'Trouble updating widget.');
        });
    }
  };

  return (
    <Container>
      {modalType === WIDGET_MODAL_TYPE.ADD && (
        <Button type={'primary'} onClick={handleCreate} style={{ width: 140 }}>
          Add Widget
        </Button>
      )}

      {modalType === WIDGET_MODAL_TYPE.EDIT && (
        <Button type={'primary'} onClick={handleUpdate} disabled={!id} style={{ width: 140 }}>
          Edit Widget
        </Button>
      )}
      <Button onClick={onClose} style={{ width: 120 }}>
        Cancel
      </Button>
    </Container>
  );
};

export default CommunityViewWidgetModalFooter;
