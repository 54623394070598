import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CaretRightFilled } from '@ant-design/icons';
import { Button, Col, message, Row, Tabs, Tooltip } from 'antd';
import { format } from 'date-fns';

import DashboardReports from 'components/Dashboard/DashboardReports';
import axios from 'config/axiosPrivate';
import BirdEyeModal from 'modals/BirdEyeModal';
import AdditionalLinks from 'pages/price_healing/AdditionalLinks';
import Calculator from 'pages/PriceHealing/Calculator';
import CommunityDetails from 'pages/PriceHealing/CommunityDetails';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleClearCommunityData } from 'store/slices/communitySlice';

import AccommodationForm from './Accommodation/AccommodationForm';
import BasicInfoForm from './BasicInfoForm';
import CMASummary from './CMASummary/CMASummary';
import ComparisonTab from './ComparisonTab';
import CompetitorsForm from './CompetitorsForm';
import FeesForm from './Fees/FeesForm';
import ImageBrochure from './ImageBrochure';
import Occupancies from './Occupancies/Occupancies';
import RentRoll from './RentRoll';
import ShopNotes from './ShopNotes';
import classes from './styles.module.scss';

const PriceHealing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const communityId = params.get('id') as string;
  const competitor_id = params.get('c_id') as string;
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const permissions = currentUser?.all_permissions;
  const [communityInfo, setCommunityInfo] = React.useState<any>(null);
  const [competitorInfo, setCompetitorInfo] = React.useState<any>(null);
  const [openPdfModal, setOpenPdfModal] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [openBirdEyeModal, setOpenBirdEyeModal] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [birdApiLoading, setBirdApiLoading] = useState({
    category: false,
    rating: false,
    reviews: false,
  });

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const getBirdEyeData = () => {
    const data = JSON.stringify({
      sindex: 0,
      count: 20,
      startIndex: 5,
      pageSize: 25,
      months: 12,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/keywords/opmetric/location?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&businessId=${communityInfo?.birdeye_business_id}&sindex=5&count=20&startIndex=5`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const payload: any = [];
        response.data.map((item: any) => {
          Object.keys(item.metricForLocn).map((key) => {
            payload.push({
              company_id: communityInfo?.CompanyId,
              alias: item.alias,
              overall_avgscore: item.avgScore,
              overall_grade: item.grade || '',
              overall_score: item.score || 0,
              category: item.metricForLocn[key].category,
              category_avgscore: item.metricForLocn[key].avgScore,
              category_count: item.metricForLocn[key].count,
              category_grade: item.metricForLocn[key].grade || '',
              op: 'added',
            });
          });
        });

        if (payload.length > 0) {
          saveBirdEyeData(payload);
        } else {
          setBirdApiLoading({ ...birdApiLoading, category: false });
        }
      })
      .catch((error) => {
        setBirdApiLoading({ ...birdApiLoading, category: false });
      });
  };
  const getBirdEyeRatingData = () => {
    const data = JSON.stringify({
      sourceAliases: [],
      months: 12,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/reports/rating/location?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&businessId=${communityInfo?.birdeye_business_id}&sortby=rating&sorder=0`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const ratingPayload: any = [];
        response.data.dataPoints.map((point: any, idx: any) => {
          if (idx < 1) {
            return;
          }
          ratingPayload.push({
            id: 1,
            company_id: communityInfo?.CompanyId,
            company_name: communityInfo?.CompanyName,
            community_name: communityInfo?.comunity_name,
            birdeye_business_id: point.businessNumber,
            alias: communityInfo?.birdeye_alias,
            totalcount: point.totalCount,
            count: point.count,
            average_rating: point.averageRating,
            nonzerocount: point.nonZeroCount,
            review_growth_percent: point.reviewGrowthPercent,
            avgrating_growth_percent: point.avgRatingGrowthPercent,
            prev_count: point.prevCount,
            prev_average_rating: point.prevAverageRating,
            op: 'added',
          });
        });

        if (ratingPayload.length > 0) {
          saveBirdEyeRatingData(ratingPayload);
        } else {
          setBirdApiLoading({ ...birdApiLoading, rating: false });
        }
      })
      .catch((error) => {
        setBirdApiLoading({ ...birdApiLoading, rating: false });
      });
  };

  const getBirdEyeReviewsData = () => {
    const data = JSON.stringify({
      ratings: [1, 2, 3, 4, 5, 0],
      subBusinessIds: [],
      statuses: ['published', 'parked'],
      allChild: 'true',
      fetchExtraParams: false,
      needCustomerInfo: false,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/review/businessId/${communityInfo?.birdeye_business_id}?sindex=1&count=30&api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&includeNonAggregatedReviews=false`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const reviewsPayload: any = [];
        response.data.forEach((review: any) => {
          reviewsPayload.push({
            // apartment_id: 0,
            type: review.businessType,
            birdeye_business_id: review.businessId,
            reviewid: review.reviewId,
            rating: review.rating,
            comments: review.comments,
            title: review.title,
            reviewer: review.reviewer.nickName,
            firstname: review.reviewer.firstName,
            lastname: review.reviewer.lastName,
            nickname: review.reviewer.nickName,
            thumbnailurl: review.reviewer.thumbnailUrl,
            emailid: review.reviewer.emailId,
            phone: review.reviewer.phone,
            facebookid: review.reviewer.facebookId,
            city: review.reviewer.city,
            state: review.reviewer.state,
            customerid: review.reviewer.customerId,
            reviewurl: review.reviewUrl,
            sourcetype: review.sourceType,
            reviewdate: review.reviewDate ? format(new Date(review.reviewDate), 'yyyy-MM-dd') : '',
            response: review.response,
            uniquereviewurl: review.uniqueReviewUrl,
            businessname: review.businessName,
            businesstype: review.businessType,
            tags: review.tags,
            review_status: review.status,
            extraparams: review.extraParams,
            recommended: review.recommended,
            assitedbyuser: '',
            sentimenttype: review.sentimentType,
            enablereply: review.enableReply,
            medias: '',
            customerid_0: review.customerId,
            rdate: review.rdate,
            status: 1,
            apartment: 0,
            op: 'added',
          });
        });
        if (reviewsPayload.length > 0) {
          saveBirdEyeReviewsData(reviewsPayload);
        } else {
          setBirdApiLoading({ ...birdApiLoading, reviews: false });
        }
        // setBirdEyeReviewsData(response.data);
      })
      .catch((error) => {
        setBirdApiLoading({ ...birdApiLoading, reviews: false });
        // setBirdEyeRatingData([]);
      });
  };

  const saveBirdEyeData = (payload: any) => {
    axios
      .post(`/birdeye/Save_Category_Report_by_Location/`, { data: payload })
      .then((res) => {
        //
        setBirdApiLoading({ ...birdApiLoading, category: false });
        showMessage('success', 'Saved Category Report successfully!');
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong');
        setBirdApiLoading({ ...birdApiLoading, category: false });
      });
  };

  const saveBirdEyeRatingData = (payload: any) => {
    axios
      .post(`/birdeye/Save_BirdeyeRatingReport/`, { data: payload })
      .then((res) => {
        //
        setBirdApiLoading({ ...birdApiLoading, rating: false });
        showMessage('success', 'Saved Rating successfully!');
      })
      .catch((err) => {
        setBirdApiLoading({ ...birdApiLoading, rating: false });
        showMessage('error', 'Something went wrong');
      });
  };

  const saveBirdEyeReviewsData = (payload: any) => {
    axios
      .post(`/birdeye/Save_BirdeyeReviewReport/`, { data: payload })
      .then((res) => {
        //
        setBirdApiLoading({ ...birdApiLoading, reviews: false });
        showMessage('success', 'Saved Reviews successfully!');
      })
      .catch((err) => {
        setBirdApiLoading({ ...birdApiLoading, reviews: false });
        showMessage('error', 'Something went wrong');
      });
  };

  const protectedTabs = () => {
    if (currentUser) {
      const items: any = [];
      if (permissions?.indexOf('PAScreens.view_screencomparison') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Comparison',
          key: 'Comparison',
          children: (
            <ComparisonTab
              community_id={parseInt(communityId)}
              communityInfo={communityInfo}
              competitorInfo={competitorInfo}
              loading={state.isLoading}
              openPdfModal={openPdfModal}
              setOpenPdfModal={setOpenPdfModal}
              setToggleRefresh={setToggleRefresh}
              toggleRefresh={toggleRefresh}
            />
          ),
          style: {
            padding: 0,
          },
        });
      }
      if (permissions?.indexOf('PAScreens.view_dashboardreports') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Dashboard',
          key: 'Dashboard',
          children: <DashboardReports communityId={parseInt(communityId)} communityInfo={communityInfo} />,
        });
      }
      if (permissions?.indexOf('PAScreens.view_basic_info') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Basic Info',
          key: 'Basic_Info',
          children: (
            <BasicInfoForm
              communityId={parseInt(communityId ?? '')}
              initialData={communityInfo}
              getCommunityInfo={() => state.refetch()}
              setData={setCommunityInfo}
            />
          ),
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_community_details') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Community Details',
          key: 'Community details',
          children: <CommunityDetails community_id={communityId} />,
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_accommodation') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Accommodation',
          key: 'Accommodation',
          children: <AccommodationForm community_id={communityId} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_fees_care') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Fees & Care',
          key: 'Fees',
          children: <FeesForm community_id={communityId} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_competitor') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Competitors',
          key: 'Competitors',
          children: <CompetitorsForm community_id={parseInt(communityId)} communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_imagesbrochure') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Images/Brochures',
          key: 'brochure',
          children: <ImageBrochure communityId={parseInt(communityId)} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_additionallinks') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Additional Links',
          key: 'links',
          children: <AdditionalLinks community_id={parseInt(communityId)} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_occupancies') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Occupancies',
          key: 'occupancies',
          children: <Occupancies communityId={communityId} />,
          style: {
            padding: 0,
          },

          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screenshopnotes') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Shop Notes',
          key: 'shop_notes',
          children: <ShopNotes community_id={parseInt(communityId)} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screencalculator') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Calculator',
          key: 'calculator',
          children: <Calculator communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.add_screenrentroll') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Rent Roll',
          key: 'rent-role',
          children: <RentRoll community_id={parseInt(communityId)} communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }
      if (permissions?.indexOf('PAScreens.view_screens_cma_summary') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'CMA Summary',
          key: 'cma_summary',
          children: <CMASummary community_id={parseInt(communityId)} communityInfo={communityInfo} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }

      return items;
    }
    return [];
  };

  React.useEffect(() => {
    dispatch(handleClearCommunityData());
    return () => {
      dispatch(handleClearCommunityData());
    };
  }, []);

  const state = useQuery({
    queryKey: `Get_HomeCommunityCompetitors_${communityId}`,
    queryFn: () =>
      axios.get(`/communities/Get_HomeCommunityCompetitors/${communityId}`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const community_data = {
        ...data?.CommunityInfo[0][0],
        livingInfo: data?.CommunityInfo[1]?.Details?.LivingInfo,
        logo: data?.CompanyLogo,
      };
      setCommunityInfo(community_data);
      const competitor_data: any = {};
      if (data?.CompetitorInfo) {
        Object.keys(data?.CompetitorInfo).forEach((key) => {
          competitor_data[key] = {
            ...data?.CompetitorInfo[key][0],
            livingInfo: data?.CompetitorInfo[key][1]?.Details?.LivingInfo,
          };
        });
      }
      setCompetitorInfo(competitor_data);
    },
    refetchOnWindowFocus: false,
  });

  const [activeTabKey, setActiveTabKey] = useState('Comparison');
  useEffect(() => {
    if (competitor_id) {
      setActiveTabKey('Competitors');
    }
  }, [competitor_id]);

  useEffect(() => {
    state.refetch();
  }, [toggleRefresh]);

  return (
    <div
      style={{
        padding: '20px 30px 0px',
      }}
    >
      <div>
        <BirdEyeModal
          loading={false}
          open={openBirdEyeModal}
          handleClose={() => setOpenBirdEyeModal(false)}
          title={communityInfo?.comunity_name}
          communityInfo={communityInfo}
        />
        <div style={{ marginBottom: '20px' }}>
          <span className={classes.page_heading}>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                margin: '0px 10px',
              }}
            >
              {communityInfo?.comunity_name}
            </span>
            {communityInfo?.address}
          </span>
          {communityInfo?.export_pdf && activeTabKey == 'Comparison' ? (
            <span style={{ float: 'right' }}>
              <Tooltip title="Update from BirdApi" placement="topRight">
                <Button
                  type="text"
                  icon={
                    <svg
                      fill="#275aff"
                      height="25px"
                      width="100%"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 424.987 424.987"
                      xmlSpace="preserve"
                    >
                      <g>
                        <path
                          d="M351.945,78.127c0-15.715-12.785-28.5-28.5-28.5s-28.5,12.785-28.5,28.5s12.785,28.5,28.5,28.5
                     S351.945,93.842,351.945,78.127z M323.445,86.627c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
                     S328.132,86.627,323.445,86.627z"
                        />
                        <path
                          d="M418.793,44.984c-1.861-5.2-7.586-7.905-12.786-6.042l-10.469,3.749C382.314,17.354,355.761,0,325.433,0
                     c-0.211,0-0.424,0.001-0.635,0.002c-28.26,0.232-54.416,15.711-68.26,40.398c-15.471,27.58-32.914,51.936-51.846,72.389
                     C148.867,173.092,68.993,257.436,66.238,260.344l-54.92,55.768c-6.843,6.942-7.62,17.3-1.89,25.187
                     c5.731,7.886,15.822,10.349,24.537,5.985c35.455-17.72,77.226-19.256,114.606-4.212c18.526,7.456,38.012,11.621,58.021,12.433
                     l20.874,62.641c1.396,4.191,5.299,6.841,9.486,6.841c1.047,0,2.114-0.166,3.162-0.516c5.24-1.746,8.072-7.409,6.326-12.648
                     l-18.893-56.697c5.313-0.412,10.579-1.064,15.785-1.949l21.651,64.969c1.396,4.19,5.299,6.841,9.486,6.841
                     c1.048,0,2.113-0.166,3.162-0.516c5.24-1.746,8.072-7.409,6.326-12.649l-21.048-63.16c27.994-8.187,53.671-23.389,74.897-44.743
                     c33.171-33.371,51.283-77.511,51.001-124.292c-0.102-15.604-2.251-31.018-6.398-45.91c14.413-15.004,22.375-35.102,22.026-56.085
                     c-0.094-5.551-0.779-10.96-1.973-16.178l10.285-3.684C417.95,55.908,420.655,50.184,418.793,44.984z M384.442,77.968
                     c0.287,17.303-6.992,33.803-19.973,45.27c-2.968,2.622-4.108,6.749-2.909,10.523c4.71,14.82,7.149,30.295,7.251,45.991
                     c0.25,41.412-15.797,80.502-45.186,110.068c-7.557,7.603-15.753,14.32-24.459,20.115c-4.381-13.661-6.606-27.898-6.606-42.473
                     c0-30.697,9.801-59.795,28.344-84.149c3.346-4.394,2.496-10.668-1.898-14.014c-4.396-3.347-10.67-2.495-14.014,1.898
                     c-21.217,27.866-32.432,61.154-32.432,96.265c0,18.106,3.003,35.765,8.919,52.624c-20.836,10.187-43.83,15.576-67.629,15.576
                     c-19.987,0-39.438-3.749-57.812-11.144c-19.451-7.828-39.949-11.72-60.345-11.72c-23.459,0-46.781,5.152-68.194,15.386
                     l53.056-53.876c0.045-0.046,0.09-0.092,0.135-0.139c0.813-0.859,82.074-86.649,138.679-147.794
                     c19.999-21.606,38.373-47.24,54.612-76.192c10.344-18.444,29.878-30.009,50.979-30.182c21.891-0.176,41.204,11.756,51.532,29.511
                     l-2.987,1.07c-5.199,1.862-7.904,7.587-6.042,12.786c1.463,4.085,5.311,6.631,9.414,6.631c1.119,0,2.258-0.189,3.372-0.588
                     l3.204-1.148C384.039,71.42,384.385,74.659,384.442,77.968z"
                        />
                        <path
                          d="M257.13,174.243c-0.009,0-0.017,0-0.025,0c-5.511,0-9.985,4.461-9.999,9.976c-0.001,0.365-0.005,0.729-0.01,1.086
                     c-0.339,22.138-9.196,43.061-24.938,58.914c-15.743,15.853-36.603,24.853-58.735,25.342c-8.946,0.196-17.647-0.942-26.018-3.397
                     l83.582-92.036c3.713-4.089,3.408-10.413-0.681-14.126c-4.088-3.713-10.413-3.409-14.126,0.68l-93.384,102.829
                     c-2.123,2.339-3.018,5.543-2.41,8.644c0.606,3.1,2.643,5.732,5.491,7.097c14.313,6.86,29.625,10.331,45.553,10.331
                     c0.81,0,1.621-0.009,2.434-0.027c27.334-0.604,53.076-11.7,72.485-31.245c19.407-19.543,30.326-45.362,30.745-72.709
                     c0.007-0.446,0.011-0.891,0.012-1.335C267.119,178.744,262.652,174.256,257.13,174.243z"
                        />
                        <path
                          d="M336.89,139.683c-3.255,2.417-6.46,4.992-9.525,7.654c-4.17,3.621-4.615,9.937-0.993,14.107
                     c1.977,2.277,4.758,3.443,7.554,3.443c2.325,0,4.66-0.806,6.553-2.45c2.683-2.33,5.487-4.583,8.334-6.697
                     c4.435-3.292,5.36-9.556,2.068-13.99C347.587,137.317,341.324,136.391,336.89,139.683z"
                        />
                      </g>
                    </svg>
                  }
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    if (birdApiLoading.category || birdApiLoading.rating || birdApiLoading.reviews) {
                      return;
                    }
                    setBirdApiLoading({
                      category: true,
                      rating: true,
                      reviews: true,
                    });
                    getBirdEyeData();
                    getBirdEyeRatingData();
                    getBirdEyeReviewsData();
                  }}
                />
              </Tooltip>
              <Tooltip title="Export to PDF" placement="topRight">
                <Button
                  type="text"
                  icon={
                    <svg
                      enableBackground="new 0 0 334.371 380.563"
                      version="1.1"
                      viewBox="0 0 14 16"
                      xmlSpace="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: '100%', height: 25 }}
                    >
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                        <polygon
                          points="51.791 356.65 51.791 23.99 204.5 23.99 282.65 102.07 282.65 356.65"
                          fill="#fff"
                          strokeWidth="212.65"
                        />
                        <path
                          d="m201.19 31.99 73.46 73.393v243.26h-214.86v-316.66h141.4m6.623-16h-164.02v348.66h246.85v-265.9z"
                          strokeWidth="21.791"
                        />
                      </g>
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                        <polygon
                          points="282.65 356.65 51.791 356.65 51.791 23.99 204.5 23.99 206.31 25.8 206.31 100.33 280.9 100.33 282.65 102.07"
                          fill="#fff"
                          strokeWidth="212.65"
                        />
                        <path
                          d="m198.31 31.99v76.337h76.337v240.32h-214.86v-316.66h138.52m9.5-16h-164.02v348.66h246.85v-265.9l-6.43-6.424h-69.907v-69.842z"
                          strokeWidth="21.791"
                        />
                      </g>
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" strokeWidth="21.791">
                        <polygon points="258.31 87.75 219.64 87.75 219.64 48.667 258.31 86.38" />
                        <path d="m227.64 67.646 12.41 12.104h-12.41v-12.104m-5.002-27.229h-10.998v55.333h54.666v-12.742z" />
                      </g>
                      <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" fill="#ed1c24" strokeWidth="212.65">
                        <polygon points="311.89 284.49 22.544 284.49 22.544 167.68 37.291 152.94 37.291 171.49 297.15 171.49 297.15 152.94 311.89 167.68" />
                        <path d="m303.65 168.63 1.747 1.747v107.62h-276.35v-107.62l1.747-1.747v9.362h272.85v-9.362m-12.999-31.385v27.747h-246.86v-27.747l-27.747 27.747v126h302.35v-126z" />
                      </g>
                      <rect x="1.7219" y="7.9544" width="10.684" height="4.0307" fill="none" />
                      <g transform="matrix(.04589 0 0 .04589 1.7219 11.733)" fill="#fff" strokeWidth="21.791">
                        <path d="m9.216 0v-83.2h30.464q6.784 0 12.928 1.408 6.144 1.28 10.752 4.608 4.608 3.2 7.296 8.576 2.816 5.248 2.816 13.056 0 7.68-2.816 13.184-2.688 5.504-7.296 9.088-4.608 3.456-10.624 5.248-6.016 1.664-12.544 1.664h-8.96v26.368zm22.016-43.776h7.936q6.528 0 9.6-3.072 3.2-3.072 3.2-8.704t-3.456-7.936-9.856-2.304h-7.424z" />
                        <path d="m87.04 0v-83.2h24.576q9.472 0 17.28 2.304 7.936 2.304 13.568 7.296t8.704 12.8q3.2 7.808 3.2 18.816t-3.072 18.944-8.704 13.056q-5.504 5.12-13.184 7.552-7.552 2.432-16.512 2.432zm22.016-17.664h1.28q4.48 0 8.448-1.024 3.968-1.152 6.784-3.84 2.944-2.688 4.608-7.424t1.664-12.032-1.664-11.904-4.608-7.168q-2.816-2.56-6.784-3.456-3.968-1.024-8.448-1.024h-1.28z" />
                        <path d="m169.22 0v-83.2h54.272v18.432h-32.256v15.872h27.648v18.432h-27.648v30.464z" />
                      </g>
                    </svg>
                  }
                  style={{ marginLeft: 5 }}
                  onClick={() => setOpenPdfModal(true)}
                />
              </Tooltip>
            </span>
          ) : null}
        </div>
        <Row gutter={[16, 10]}>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px',
            }}
            lg={19}
            xl={19}
          >
            <span className={classes.community_name} style={{}}>
              {communityInfo?.comunity_name}{' '}
              {communityInfo && (
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${communityInfo.address}&query_place_id=<placeId>${communityInfo.google_map_location}`}
                  style={{
                    position: 'absolute',
                  }}
                  rel="noreferrer"
                >
                  <img src="/images/world-icon.png" height="25" width="auto" style={{ translate: '3px -20px' }} />
                </a>
              )}
            </span>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: '15px',
            }}
            lg={5}
            xl={5}
          >
            {(permissions?.indexOf('PAScreens.can_view_swot_analysis') != -1 || currentUser?.isSuperuser) && (
              <div className={classes.survey_btn} onClick={() => navigate(`/swot-survey?cid=${communityId}`)}>
                SWOT Analysis
              </div>
            )}
          </Col>
        </Row>
      </div>
      <Tabs
        activeKey={activeTabKey}
        moreIcon={<CaretRightFilled />}
        tabPosition="top"
        tabBarGutter={30}
        size="small"
        destroyInactiveTabPane
        onChange={(key: any) => setActiveTabKey(key)}
        items={protectedTabs()}
      />
    </div>
  );
};

export default PriceHealing;
