import React, { ReactNode } from 'react';

import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  font-size: 10px;
  color: #fff;
  opacity: 0.6;
`;

interface Props {
  title: string;
  children?: ReactNode;
}

const SidebarMenuCategoryHeader = ({ title, children }: Props) => (
  <Header>
    <span>{title}</span>
    {children}
  </Header>
);

export default SidebarMenuCategoryHeader;
