import React from 'react';

import DataTableIncentives from '../DataTable/DataTableIncentives';

interface Props {
  data: any;
}

export const Incentives = ({ data }: Props) => (
  <>
    <DataTableIncentives data={data} />
  </>
);

export default Incentives;
