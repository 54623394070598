const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
};

export const generatePastelColor = () => {
  // Generate random RGB values for a pastel color
  let r = Math.floor(Math.random() * 256);
  let g = Math.floor(Math.random() * 256);
  let b = Math.floor(Math.random() * 256);

  // Calculate the average to create a pastel effect
  r = Math.floor((r + 255) / 2);
  g = Math.floor((g + 255) / 2);
  b = Math.floor((b + 255) / 2);

  // Convert RGB to hexadecimal
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
