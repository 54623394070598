import React from 'react';

import SidebarMenuItem from './SidebarMenuItem';
import { useAppSelector } from '../../../store/hook';

const SidebarMenuCommon = () => {
  const { selectedManagementCompany } = useAppSelector((state) => state.global);

  return (
    <div>
      <SidebarMenuItem to={`/overview/${selectedManagementCompany?.id}`} label={'Overview'} />
    </div>
  );
};

export default SidebarMenuCommon;
