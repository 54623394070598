import React from 'react';

import styled from 'styled-components';

import ShopRequestCommunitiesSelector from './ShopRequestCommunitiesSelector';
import ShopRequestModalCommunityRows from './ShopRequestModalCommunityRows';
import ShopRequestModalInfo from './ShopRequestModalInfo';
import ShopRequestModalSourceSelector from './ShopRequestModalSourceSelector';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledCommunitiesInfoContainer = styled.div`
  display: flex;
  flex-flow: row;
`;

const CreateShopRequestModalContent = () => {
  return (
    <Container>
      <ShopRequestModalSourceSelector />
      <StyledCommunitiesInfoContainer>
        <div>
          <ShopRequestCommunitiesSelector />
          <ShopRequestModalCommunityRows />
        </div>
        <ShopRequestModalInfo />
      </StyledCommunitiesInfoContainer>
    </Container>
  );
};

export default CreateShopRequestModalContent;
