import React from 'react';

import { Radio as AntDRadio, RadioProps } from 'antd';
import styled from 'styled-components';

const StyledRadio = styled(AntDRadio)`
  color: var(--text-primary);
  font-size: 12px;
`;

const Radio = (props: RadioProps) => {
  return <StyledRadio {...props} />;
};

Radio.Group = ({ ...props }) => <AntDRadio.Group {...props} />;

export default Radio;
