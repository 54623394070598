import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import CommunityInfoCommunityContent from './CommunityInfoCommunityContent';
import CommunityInfoCompetitorContent from './CommunityInfoCompetitorContent';
import { ACTOR_TYPE } from '../../constants';
import { useAppSelector } from '../../store/hook';

const CommunityInfoContent = () => {
  const { competitorId } = useParams();
  const { selectedCommunity } = useAppSelector((state) => state.global);

  return (
    <>
      {selectedCommunity?.type === ACTOR_TYPE.COMMUNITY && !competitorId && <CommunityInfoCommunityContent />}
      {(selectedCommunity?.type === ACTOR_TYPE.COMPETITOR || competitorId) && <CommunityInfoCompetitorContent />}
    </>
  );
};

export default CommunityInfoContent;
