import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { List } from 'antd';
import styled from 'styled-components';

import {
  COMMUNITY_DIGEST_STATUS,
  COMPETITOR_INCENTIVE_UPDATES,
  MARKET_RATE_COMPARISON_UPDATES_STATUS,
  REMINDER_TO_UPDATE_PRICING_STATUS,
} from './constants';
import SettingsHeader from './SettingsHeader';
import SettingsListItem from './SettingsListItem';
import { SettingsListItem as SettingsListItemType } from './types';

const Container = styled.div`
  width: 100%;
  // padding: 0 30px 30px 30px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
`;

const Content = styled.div`
  padding: 30px;
`;

const StyledList = styled(List)`
  // TODO: Remove the !imporant where possible
  margin-top: 20px;

  && .ant-list-item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  && .ant-list-item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .custom-list-item-meta .ant-list-item-meta-avatar {
    margin-inline-end: 0 !important;
  }

  .custom-list-item-meta .ant-list-item-meta-description {
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .custom-list-item-meta .ant-list-item-meta-title {
    margin-bottom: 0 !important;
  }
`;

const SettingsOverview = () => {
  const { managementCompanyId } = useParams();

  const EMAIL_NOTIFICATIONS_LIST_DATA: SettingsListItemType[] = [
    {
      icon: COMMUNITY_DIGEST_STATUS.icon,
      title: COMMUNITY_DIGEST_STATUS.title,
      description: COMMUNITY_DIGEST_STATUS.description,
      url: `/preferences/${managementCompanyId}/community-digest/`,
    },
    {
      icon: MARKET_RATE_COMPARISON_UPDATES_STATUS.icon,
      title: MARKET_RATE_COMPARISON_UPDATES_STATUS.title,
      description: MARKET_RATE_COMPARISON_UPDATES_STATUS.description,
      url: `/preferences/${managementCompanyId}/market-rates-comparison-updates/`,
    },
    {
      icon: COMPETITOR_INCENTIVE_UPDATES.icon,
      title: COMPETITOR_INCENTIVE_UPDATES.title,
      description: COMPETITOR_INCENTIVE_UPDATES.description,
      url: `/preferences/${managementCompanyId}/competitor-incentive-updates`,
    },
    {
      icon: REMINDER_TO_UPDATE_PRICING_STATUS.icon,
      title: REMINDER_TO_UPDATE_PRICING_STATUS.title,
      description: REMINDER_TO_UPDATE_PRICING_STATUS.description,
      url: `/preferences/${managementCompanyId}/reminder-to-update-pricing`,
    },
  ];

  return (
    <Container>
      <SettingsHeader />
      <Content>
        <Title>Email Notifications</Title>
        <StyledList
          bordered
          dataSource={EMAIL_NOTIFICATIONS_LIST_DATA}
          renderItem={(item: any): ReactNode => <SettingsListItem item={item} />}
        />
      </Content>
    </Container>
  );
};

export default SettingsOverview;
