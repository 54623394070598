import React from 'react';

import { Layout } from 'antd';

import { useAppSelector } from 'store/hook';

import MainHeader from './HeaderInitial';
import Sidebar from './SidebarInitial';

const { Content } = Layout;

interface Props {
  children: React.ReactNode;
}

const AlphaContent = ({ children }: Props) => {
  const state = useAppSelector((state) => state.global);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const { selectedCompany } = state;

  return (
    <Layout style={{ overflowY: 'auto' }}>
      {currentUser?.isSuperuser && selectedCompany && <Sidebar />}
      <Layout className="site-layout">
        <MainHeader />
        <Content
          style={{
            margin: '0px',
            padding: '0px',
            height: 'calc(100vh - 50px)',
            overflowY: 'auto',
            boxSizing: 'border-box',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AlphaContent;
