import React from 'react';
import { useQueryClient } from 'react-query';

import { Typography } from 'antd';
import styled from 'styled-components';

import { updateShopRequest } from 'apis/ShopRequestAPI';
import { useAppSelector } from 'store/hook';

import { useShopRequestsDataContext } from '../ShopRequestsDataContext';
import ShopRequestUserInfo from '../ShopRequestUserInfo';
import { MysteryShopRequestInbound } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

const Title = styled(Typography.Text)`
  height: 21px;
`;

const Subtitle = styled(Typography.Text)`
  color: var(--link-blue);
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
};

const ShopRequestsShopper = ({ shopRequest }: Props) => {
  const queryClient = useQueryClient();
  const { currentUser } = useAppSelector((state) => state.auth);
  const { filters, ordering } = useShopRequestsDataContext();
  const assignToMe = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!currentUser) {
      return;
    }
    await updateShopRequest(shopRequest.id, { shopper_id: currentUser.PAUser_Id }).then(() => {
      queryClient.invalidateQueries(['shopRequests', filters, ordering]);
      queryClient.invalidateQueries(['shopRequestsGroups', filters, ordering]);
    });
  };

  if (!shopRequest.community) {
    return <div />;
  }

  return (
    <Container className="shopper-column">
      {shopRequest.shopper ? (
        <ShopRequestUserInfo shopRequest={shopRequest} />
      ) : (
        <LinkContainer>
          <Title>Unassigned</Title>
          <Subtitle tabIndex={0} onClick={assignToMe}>
            Assign to me
          </Subtitle>
        </LinkContainer>
      )}
    </Container>
  );
};

export default ShopRequestsShopper;
