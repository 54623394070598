import React from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: var(--text-primary);

    &.competitor {
      color: var(--competitor-purple);
    }

    &.terminal {
      color: var(--text-secondary);
    }
  }
`;

interface Props {
  competitor: boolean;
}

const CompetitorHeaderBreadcrumbs = ({ competitor }: Props) => (
  <StyledBreadcrumb
    items={[
      {
        className: competitor ? 'competitor' : '',
        title: competitor ? 'Competitor' : 'My Community',
      },
      {
        className: 'terminal',
        title: `Community Info`,
      },
    ]}
    separator="·"
  />
);

export default CompetitorHeaderBreadcrumbs;
