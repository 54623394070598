import React, { useCallback } from 'react';

import BarChart from './BarChart';
import { CHART_TYPE, LABEL_FONT_COLOR, LABEL_FONT_FAMILY, LABEL_FONT_SIZE } from './constants';
import LineChart from './LineChart';
import { DataItemType } from './types';
import { labelFormatter, textStyle, tooltipContent, tooltipFormatter } from './utils';

interface Props {
  widgetType: number;
  type: string;
  data: DataItemType[];
  xField: string;
  yField: string;
  seriesField: string;
}

export const Chart = ({ widgetType, type, data, xField, yField, seriesField }: Props) => {
  const colorCallback = useCallback(
    (colorData: any) => {
      const dataEntry = data.find((item: any) => item[seriesField] === colorData[seriesField]);
      return dataEntry?.community_gradient ?? '';
    },
    [data]
  );

  const borderCallback = useCallback(
    (colorData: any) => {
      const dataEntry = data.find((item: any) => item[seriesField] === colorData[seriesField]);
      return dataEntry?.community_color ?? '';
    },
    [data]
  );

  const config = {
    data,
    autoFit: true,
    xField,
    yField,
    seriesField,
    renderer: 'svg' as const,
    animation: false,
    appendPadding: [10, 45, 20, 0],
    theme: {
      styleSheet: {
        backgroundColor: 'white' as const,
      },
    },
    legend: false as const,
    xAxis: {
      animate: undefined,
      label: {
        style: {
          fill: LABEL_FONT_COLOR,
          fontFamily: LABEL_FONT_FAMILY,
          fontSize: LABEL_FONT_SIZE,
        },
      },
    },
    yAxis: {
      animate: undefined,
      label: {
        style: (label: string) => textStyle(label, data),
        autoEllipsis: true,
      },
    },
    colorField: 'type',
    color: colorCallback,
    barStyle: borderCallback,
    label: {
      position: 'right' as const,
      style: {
        fontSize: LABEL_FONT_SIZE,
      },
      autoHide: false as boolean,
      autoRotate: true as boolean,
      formatter: (item: any) => labelFormatter(item?.value, widgetType),
    },
    tooltip: {
      shared: false,
      customContent: tooltipContent,
      formatter: (item: any) => tooltipFormatter(item, widgetType),
      domStyles: {
        'g2-tooltip': {
          opacity: 1,
          borderRadius: 0,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: 0,
        },
      },
    },
  };

  return (
    <>
      {type === CHART_TYPE.BAR && <BarChart {...config} />}
      {type === CHART_TYPE.LINE && <LineChart widgetType={widgetType} lineProps={config} />}
    </>
  );
};

export default Chart;
