import React from 'react';

import { Tabs } from 'antd';

import Competitortab from 'pageComponents/home/CompetitorTab';
import { useAppSelector } from 'store/hook';

const Home = () => {
  const state = useAppSelector((state) => state.global);
  const currentUser: any = useAppSelector((state) => state.auth.currentUser);

  return (
    <div>
      <Tabs
        defaultActiveKey="Competitive"
        tabBarStyle={{ padding: '0px 15px 0px 30px' }}
        className="home__competitor__tab"
        style={{ padding: 0 }}
        items={[
          {
            label: 'Market Overview',
            key: 'Competitive',
            children: (
              <Competitortab
                communityList={state.communityList || []}
                commpetitorList={state.communityCompetitorsList || []}
                filterCommunity={state.communityList || []}
                filterCompany={state.selectedCompany ? [state.selectedCompany] : []}
                filterManagementCompany={state.selectedManagementCompany ? [state.selectedManagementCompany] : []}
                filterCare={state.careList || []}
                loading={state.loading}
              />
            ),
          },
          {
            label: 'Dashboard',
            key: 'Dashboard',
            children: currentUser?.dashboard_url ? (
              <iframe
                title="dashboard"
                src={currentUser?.dashboard_url}
                style={{ border: 0, width: '100%', height: '100vh' }}
              ></iframe>
            ) : (
              <div style={{ textAlign: 'center', minHeight: 100 }}>
                <h2>No dashboard found</h2>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Home;
