import React from 'react';

import { Select as AntDSelect, SelectProps } from 'antd';
import styled from 'styled-components';

const StyledSelect = styled(AntDSelect)`
  &.ant-select {
    font-family: var(--font-regular);
    font-size: 12px;
    height: 28px;
    color: inherit;
    color: var(--deep-blue);
    background-color: var(--lighter-gray);
    border-radius: 4px;

    &.active {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
    }

    &.ant-select-focused {
      color: var(--dark-blue);
      background-color: var(--faded-blue);
      border: 1px solid var(--button-blue);
    }

    .ant-select-arrow {
      color: inherit;
      width: 16px;
      height: 16px;
      font-weight: 900;
      top: 12px;
    }
    .ant-select-arrow .anticon {
      width: 8px;
      height: 4.9px;
      margin: auto;
    }
    .ant-select-selector {
      color: inherit;
      .ant-select-selection-placeholder {
        color: inherit;
        font-size: 12px;
      }
      .ant-select-selection-item {
        color: inherit;
        font-size: 12px;
      }
    }
  }
`;

const StyledMenu = styled.div`
  .ant-select-item {
    font-size: 14px;
    padding: 6px 10px;
    border-bottom: 1px solid var(--line-gray);
  }
`;

const SelectFilter = (props: SelectProps) => {
  return (
    <StyledSelect
      {...props}
      dropdownRender={(menu) => <StyledMenu>{menu}</StyledMenu>}
      dropdownStyle={{
        padding: 0,
        width: 150,
        borderRadius: 0,
        border: '1px solid var(--line-gray)',
        borderBottom: 0,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
      }}
    />
  );
};

export default SelectFilter;
