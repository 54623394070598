import React from 'react';

import styled from 'styled-components';

import { useIncentivesContext } from 'components/Incentives/IncentivesContext';

import DownCaretIcon from '../../../../common/Icons/DownCaretIcon';
import Button from '../../../lib/Button';
import Dropdown from '../../../lib/Dropdown';
import { ProposalType } from '../../types';

interface Props {
  record: ProposalType;
}

const Wrapper = styled.div`
  visibility: hidden;
`;

const IncentivesTableProposalsActionsDropdown = ({ record }: Props) => {
  const { setSelectedProposalUnit, setSelectedProposal } = useIncentivesContext();

  const openProposalModal = () => {
    setSelectedProposal(record);
    setSelectedProposalUnit({ ...record.unit, incentive_id: record.incentive.id });
  };

  return (
    <Wrapper className={'hover-visible'}>
      <Dropdown
        menu={{
          items: [
            {
              label: 'View Recipient Info',
              key: 'viewRecipientInfo',
              onClick: openProposalModal,
            },
            {
              label: 'View Proposal Page',
              key: 'viewProposalPage',
              onClick: () => window.open(record.proposal_page_url, '_blank'),
            },
          ].filter(Boolean),
        }}
      >
        <Button style={{ width: 100 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <span>Actions</span>
            <DownCaretIcon />
          </div>
        </Button>
      </Dropdown>
    </Wrapper>
  );
};

export default IncentivesTableProposalsActionsDropdown;
