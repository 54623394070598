import React from 'react';

import styled from 'styled-components';

import CardFooter from './CardFooter';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  color: var(--text-primary);
  font-size: 42px;
`;

const Amount = styled.div`
  font-size: 42px;
  line-height: 42px;
`;

const PercentageSign = styled.div`
  font-size: 20px;
  padding-left: 2px;
`;

interface Props {
  value?: number;
  marketValue?: number;
  footerLabel: string;
}

const CardPercentageContent = ({ value = 0, marketValue = 0, footerLabel }: Props) => {
  const competitorsValue = marketValue
    ? `${marketValue?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`
    : 'Not Available';
  return (
    <Content>
      <ValueContainer>
        <Amount>
          <span>{value?.toFixed(0) || 0}</span>
        </Amount>
        <PercentageSign>%</PercentageSign>
      </ValueContainer>
      <CardFooter label={footerLabel} value={competitorsValue} positive={marketValue >= value} />
    </Content>
  );
};

export default CardPercentageContent;
