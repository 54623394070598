import React, { useRef } from 'react';

import classNames from 'classnames';

import IncentivesFiltersUnitAvailabilityVacancyCount from './IncentivesFiltersUnitAvailabilityVacancyCount';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { UNIT_AVAILABILITY, UNIT_AVAILABILITY_OPTIONS } from '../constants';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const IncentivesFiltersUnitAvailability = () => {
  const { filters, setFilters } = useIncentivesDataContext();

  const vacancyCountRef = useRef<any>(null);

  const handleSelect = (selection: number[]) => {
    const value = selection.map((item: any) => item.value);

    // Unconventional, but such is Tarik's approach to uniform design system
    const vacancyElement = vacancyCountRef.current;
    const checkboxElement = vacancyElement.querySelector('#vacancy-checkbox');
    const inputElement = vacancyElement.querySelector('#vacancy-input');

    const unitVacancyEnabled = checkboxElement?.checked;
    const unitVacancyDayCount = inputElement?.value;

    if (value.includes(UNIT_AVAILABILITY.VACANT) && unitVacancyEnabled) {
      setFilters({ ...filters, page: 1, unitAvailability: value, unitVacancyDayCount });
    } else {
      setFilters({ ...filters, page: 1, unitAvailability: value, unitVacancyDayCount: undefined });
    }
  };

  const filteredOptions = UNIT_AVAILABILITY_OPTIONS?.filter((item: any) => item.id != UNIT_AVAILABILITY.VACANT).map(
    (item: any) => ({
      label: item.name,
      value: item.id,
    })
  );

  const options = [
    {
      component: <IncentivesFiltersUnitAvailabilityVacancyCount vacancyCountRef={vacancyCountRef} />,
      value: UNIT_AVAILABILITY.VACANT,
      label: 'Vacant',
    },
    ...filteredOptions,
  ];

  return (
    <SelectAdvancedFilter
      className={classNames({ active: Boolean(filters?.unitAvailability?.length) })}
      placeholder={'Unit Availability'}
      options={options}
      defaultValue={filters?.unitAvailability ?? []}
      onSelect={handleSelect}
    />
  );
};

export default IncentivesFiltersUnitAvailability;
