import React, { useRef } from 'react';

import { DatePicker as AntDDatePicker, DatePickerProps } from 'antd';
import styled from 'styled-components';

import { US_DATE_FORMAT } from '../../constants';

const StyledDatePicker = styled.div`
  .ant-picker {
    background: white;
    border-radius: 0;
    border: 1px solid var(--line-gray);
    height: 36px;
    width: 133px;
    padding: 0px 10px;

    input {
      font-family: var(--font-regular);
      font-size: 14px;
      color: var(--text-primary);
    }
  }

  .ant-picker-panel-container {
    border-radius: 0;
    border: 1px solid var(--line-gray);
  }

  .ant-picker-panel-layout {
    .ant-picker-header {
      height: 36px;
      border-bottom: 1px solid var(--line-gray);

      .ant-picker-header-super-prev-btn {
        display: none;
      }

      .ant-picker-header-super-next-btn {
        display: none;
      }

      button {
        font-family: var(--font-bold);
        font-size: 14px;
      }
    }

    .ant-picker-content {
      thead > th {
        font-size: 12px;
        color: var(--text-tertiary);
        font-family: var(--font-bold);
      }
      tbody {
        .ant-picker-cell {
          padding: 0;
          font-size: 12px;
          font-family: var(--font-regular);

          .ant-picker-cell-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
          &.ant-picker-cell-disabled::before {
            background-color: transparent;
          }
          &.ant-picker-cell-today {
            .ant-picker-cell-inner::before {
              border: none;
            }
          }
          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              color: var(--dark-blue);
              border: 1px solid var(--dark-blue);
              background-color: var(--feather-blue);
            }
          }
        }
      }
    }

    .ant-picker-footer {
      display: none;
    }
  }
`;

const DatePicker = (props: DatePickerProps) => {
  const datePickerContainerRef = useRef<HTMLDivElement>(null);

  return (
    <StyledDatePicker ref={datePickerContainerRef}>
      <AntDDatePicker
        allowClear={false}
        suffixIcon={null}
        showNow={false}
        format={US_DATE_FORMAT}
        superNextIcon={null}
        superPrevIcon={null}
        getPopupContainer={() => datePickerContainerRef.current ?? document.body}
        {...props}
      />
    </StyledDatePicker>
  );
};

export default DatePicker;
