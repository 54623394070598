import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { GOOGLE_MAP_MARKER_INFO_OFFSET } from './constants';
import { ACTOR_TYPE } from '../../../constants';

interface Propsa {
  width?: number;
}

interface Props {
  data?: any;
}

const Container = styled.div<Propsa>`
  position: absolute;
  left: -${(props) => (props?.width ?? GOOGLE_MAP_MARKER_INFO_OFFSET) / 2}px;
  top: ${GOOGLE_MAP_MARKER_INFO_OFFSET}px;
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  font-size: 10px;
  font-family: var(--font-regular);
  padding: 6px 10px;
  color: #fff;
  border-radius: 10px;
  border: solid 1px #fff;
  background: linear-gradient(to left, var(--community-marker-purple-light), var(--community-marker-purple-dark));
  transition:
    opacity 400ms,
    visibility 400ms;
  z-index: 20;
`;

const StyledLink = styled(Link)`
  font-size: 10px;
  color: #fff;
  text-decoration: none;
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  gap: 2px;
`;

const Amount = styled.span`
  font-size: 14px;
`;

const MapMarkerInfo = ({ data }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const linkPrefix = data?.apartmenttype === ACTOR_TYPE.COMPETITOR ? ACTOR_TYPE.COMPETITOR : ACTOR_TYPE.COMMUNITY;
  return (
    <Container ref={ref} className={'marker-info'} width={ref?.current?.clientWidth}>
      <StyledLink to={`/${linkPrefix}/${data.id}`}>
        <span>{data?.apartmentname}</span>
        <div>Market Rate Avg.</div>
        <AmountContainer>
          <span>$</span>
          <Amount>{Math.round(data?.Avg_base_rent).toLocaleString()}</Amount>
        </AmountContainer>
      </StyledLink>
    </Container>
  );
};

export default MapMarkerInfo;
