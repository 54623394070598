import React from 'react';

import styled from 'styled-components';

import ShopRequestUserAvatar from './ShopRequestUserAvatar';
import { MysteryShopRequestInbound } from './types';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
};

const ShopRequestUserInfo = ({ shopRequest }: Props) => {
  if (!shopRequest.shopper) {
    return <div>Unassigned</div>;
  }

  return (
    <Container>
      <ShopRequestUserAvatar label={shopRequest.shopper.name} />
      {shopRequest.shopper.name}
    </Container>
  );
};

export default ShopRequestUserInfo;
