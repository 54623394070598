import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import OverviewCommunitiesTableHeader from './OverviewCommunitiesTableHeader';
import OverviewCommunitiesTablePreview from './OverviewCommunitiesTablePreview';

const Container = styled.div`
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

interface Props {
  type: string;
  communities: any[];
}

const OverviewCommunitiesTable = ({ type, communities }: Props) => {
  const [filteredCommunities, setFilteredCommunities] = useState<any>([]);
  const [filterKeyword, setFilterKeyword] = useState<string>('');

  useEffect(() => {
    const adjustedFilterKeyword = filterKeyword?.toLowerCase();
    setFilteredCommunities(
      communities
        .filter(
          (community) =>
            community?.apartmentname?.toLowerCase().includes(adjustedFilterKeyword?.toLowerCase()) ||
            community?.apartmentaddress?.toLowerCase().includes(adjustedFilterKeyword?.toLowerCase())
        )
        .map((community) => ({ ...community, children: community.competitors }))
    );
  }, [communities, filterKeyword]);

  return (
    <Container>
      <OverviewCommunitiesTableHeader type={type} handleSearch={setFilterKeyword} />
      <OverviewCommunitiesTablePreview type={type} communities={filteredCommunities} />
    </Container>
  );
};

export default OverviewCommunitiesTable;
