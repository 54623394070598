import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import classNames from 'classnames';

import { getCommunitiesOptions } from '../../../apis/IncentiveAPI';
import { useAppSelector } from '../../../store/hook';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useIncentivesDataContext } from '../IncentivesDataContext';

type CommunityType = {
  id: number;
  comunity_name: string;
};

const IncentivesFiltersCommunity = () => {
  const { managementCompanyId } = useParams();

  const { selectedCompany } = useAppSelector((state) => state.global);
  const { filters, setFilters } = useIncentivesDataContext();

  const handleSelect = (selection: any[]) => {
    const values = selection.map((item: any) => item.value);
    setFilters({ ...filters, page: 1, communities: values });
  };

  const { data } = useQuery({
    queryKey: ['communities', 'incentives', selectedCompany, managementCompanyId, filters.roomTypes],
    queryFn: () =>
      getCommunitiesOptions({
        companies: selectedCompany ? [selectedCompany.id] : [],
        management_companies: managementCompanyId ? [managementCompanyId] : undefined,
        room_types: filters?.roomTypes,
      }),
    enabled: Boolean(selectedCompany) || Boolean(managementCompanyId),
    refetchOnWindowFocus: false,
  });

  const options = data?.map((item: CommunityType) => ({
    label: item.comunity_name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: Boolean(filters.communities?.length) })}
      placeholder={'Community'}
      options={options}
      defaultValue={filters.communities ?? []}
      onSelect={handleSelect}
    />
  );
};

export default IncentivesFiltersCommunity;
