import React, { useState } from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';
import { Divider } from 'components/lib/Divider';

import Input from '../../lib/Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  margin-top: 20px;
`;

const Label = styled.span`
  display: flex;
  font-family: var(--font-bold);
  font-size: 14px;
  justify-content: end;
  width: 75px;
  color: var(--text-primary);
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-left: 10px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    display: flex;
  }
`;

const IncentivesProposalModalProposalSecondPerson = () => {
  const [disabled, setDisabled] = useState(true);
  return (
    <Container>
      <LabelContainer>
        <Form.Item noStyle name={['second_person', 'enabled']} valuePropName="checked">
          <Checkbox className={'green'} onChange={(event: any) => setDisabled(!event.target.checked)} />
        </Form.Item>
        <Title>2nd Resident (if applicable)</Title>
      </LabelContainer>
      <Divider />
      <FormContainer>
        <Form.Item
          name={['second_person', 'first_name']}
          label={<Label>First Name</Label>}
          rules={[{ required: !disabled, message: 'This field is required!' }]}
          style={{ height: 36 }}
        >
          <Input
            placeholder={"Enter the 2nd resident's first name"}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item
          name={['second_person', 'last_name']}
          label={<Label>Last Name</Label>}
          rules={[{ required: !disabled, message: 'This field is required!' }]}
          style={{ height: 36 }}
        >
          <Input
            placeholder={"Enter the 2nd resident's last name"}
            disabled={disabled}
          />
        </Form.Item>
      </FormContainer>
    </Container>
  );
};

export default IncentivesProposalModalProposalSecondPerson;
