import React from 'react';

import styled from 'styled-components';

import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import IncentivesData from '../InventivesData';
import { UnitType } from '../types';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 0px 30px 30px;
`;

const IncentivesCreateUnitsList = () => {
  const { setUnits } = useIncentivesCreateModalContext();

  return (
    <Container>
      <IncentivesData title={'Select Units'} createMode onSelectionChanged={setUnits} />
    </Container>
  );
};

export default IncentivesCreateUnitsList;
