import React, { useState } from 'react';

import styled from 'styled-components';

import DashboardCareTypeMenu from './DashboardCareTypeMenu';
import { DashboardReportsContext } from './DashboardReportsContext';
import DashboardReportsExportHeader from './DashboardReportsExportHeader';
import DashboardReportCareType from './reports/DashboardReportCareType';
import DashboardReportFee from './reports/DashboardReportFee';
import DashboardReportGoogleRating from './reports/DashboardReportGoogleRating';
import DashboardReportIncentives from './reports/DashboardReportIncentives';
import DashboardReportOccupancy from './reports/DashboardReportOccupancy';
import DashboardReportRentAverageCareCost from './reports/DashboardReportRentAverageCareCost';
import DashboardReportRentSquareFootage from './reports/DashboardReportRentSquareFootage';
import DashboardReportSecondPersonFee from './reports/DashboardReportSecondPersonFee';
import ExportableContent from '../../common/Export/ExportableContent';
import ExportableContentHeader from '../../common/Export/ExportableContentHeader';
import ExportableContentPages from '../../common/Export/ExportableContentPages';
import { APARTMENT_TYPE_CATEGORIES, STATUS } from '../../constants';

const Container = styled.div`
  padding: 0px 60px;
  background-color: var(--background-gray);
`;

const EXPORT_FILENAME = 'Dashboard_Reports';

interface Props {
  communityId: number;
  communityInfo: any;
}

const DashboardReports = ({ communityId, communityInfo }: Props) => {
  const [dataStatus, setDataStatus] = useState(STATUS.IDLE);
  const [selectedCareTypeId, setSelectedCareTypeId] = useState<number>(1);
  const [selectedApartmentTypeCategory, setSelectedApartmentTypeCategory] = useState<number | undefined>(
    APARTMENT_TYPE_CATEGORIES.BASIC
  );

  const handleCareTypeChange = (careTypeId: number) => {
    setSelectedCareTypeId(careTypeId);
  };

  const handleApartmentTypeCategoryChange = (basicApartmentTypeCategoryChecked: boolean) => {
    setSelectedApartmentTypeCategory(basicApartmentTypeCategoryChecked ? APARTMENT_TYPE_CATEGORIES.BASIC : undefined);
  };

  return (
    <DashboardReportsContext.Provider
      value={{
        communityId: communityId,
        communityName: communityInfo?.comunity_name,
        careTypeId: selectedCareTypeId,
        apartmentTypeCategory: selectedApartmentTypeCategory,
        dataStatus,
        setDataStatus,
      }}
    >
      <Container>
        <ExportableContent filename={EXPORT_FILENAME}>
          <ExportableContentHeader>
            <DashboardReportsExportHeader />
          </ExportableContentHeader>
          <ExportableContentPages>
            <DashboardCareTypeMenu
              onCareTypeChange={handleCareTypeChange}
              onApartmentTypeCategoryChange={handleApartmentTypeCategoryChange}
            />
            <DashboardReportCareType />
            <DashboardReportRentSquareFootage />
            <DashboardReportRentAverageCareCost />
            <DashboardReportGoogleRating />
            <DashboardReportFee />
            <DashboardReportSecondPersonFee />
            <DashboardReportOccupancy />
            <DashboardReportIncentives />
          </ExportableContentPages>
        </ExportableContent>
      </Container>
    </DashboardReportsContext.Provider>
  );
};

export default DashboardReports;
