import React from 'react';

import styled from 'styled-components';

import PublicSidebarMenu from './PublicSidebarMenu';
import SidebarLogo from '../layout/Sidebar/SidebarLogo';

const Container = styled.div`
  width: 210px;
  width: 210px;
  background: linear-gradient(to top, var(--sidebar-purple-dark) 20%, var(--sidebar-purple-light));
`;

const PublicSidebar = () => (
  <Container>
    <SidebarLogo />
    <PublicSidebarMenu />
  </Container>
);

export default PublicSidebar;
