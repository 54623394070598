export const COMMUNITY_NAME_COLUMN = {
  ID: 0,
  CATEGORY: 'Community Name',
  FIELD: 'communityName',
  TYPE: 'communityType',
};

export const IMAGE_UPLOAD_SOURCE = 'upload';

export const COMPARISON_MODAL_WIDTH = 1380;
