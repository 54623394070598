import React from 'react';

import styled from 'styled-components';

import CardFooter from './CardFooter';
import Rate from '../../../components/lib/Rate';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
`;

const ValueContainer = styled.div`
  display: flex;
  color: var(--text-primary);
`;

const Amount = styled.div`
  font-size: 42px;
  line-height: 42px;
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
`;

const Total = styled.div`
  font-size: 20px;
`;

interface Props {
  value?: number;
  total?: number;
  marketValue?: number;
  footerLabel: string;
  showTotals: boolean;
}

const CardRatingContent = ({ value = 0, total = 0, marketValue = 0, footerLabel, showTotals }: Props) => (
  <Content>
    <ValueContainer>
      <Amount>{(value ?? 0).toFixed(1)}</Amount>
      <AdditionalInfoContainer>
        <Rate disabled allowHalf value={value} />
        {showTotals && <Total>({total ?? 0})</Total>}
      </AdditionalInfoContainer>
    </ValueContainer>
    <CardFooter
      label={footerLabel}
      value={marketValue ? `${marketValue?.toFixed(1)}` : 'Not Available'}
      positive={marketValue >= value}
    />
  </Content>
);

export default CardRatingContent;
