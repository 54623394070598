import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import IncentivesTableUnitsActions from './IncentivesTableUnitsActions';
import IncentivesTableUnitsArchived from './IncentivesTableUnitsArchived';
import IncentivesTableUnitsCommunity from './IncentivesTableUnitsCommunity';
import IncentivesTableUnitsIncentive from './IncentivesTableUnitsIncentive';
import IncentivesTableUnitsSelectorControl from './IncentivesTableUnitsSelectorControl';
import IncentivesTableUnitsStandardRate from './IncentivesTableUnitsStandardRate';
import IncentivesTableUnitsUnit from './IncentivesTableUnitsUnit';
import { getUnits } from '../../../../apis/IncentiveAPI';
import { isIncentivesCorporate } from '../../../../helpers/permissions';
import { useAppSelector } from '../../../../store/hook';
import { INCENTIVES_PAGE_SIZE, TABLE_ACTIONS } from '../../constants';
import { getOrdering, getRecordKey } from '../../helpers';
import { useIncentivesContext } from '../../IncentivesContext';
import { useIncentivesDataContext } from '../../IncentivesDataContext';
import { IncentiveType, UnitType } from '../../types';
import IncentivesStyledTable from '../IncentivesStyledTable';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

const IncentivesTableUnits = () => {
  const { managementCompanyId } = useParams();

  const { currentUser } = useAppSelector((state) => state.auth);
  const { selectedCompany } = useAppSelector((state) => state.global);

  const containerRef = useRef<HTMLDivElement>(null);

  const { key, currentTab } = useIncentivesContext();
  const {
    createMode,
    selectionMode,
    archiveMode,
    items,
    ordering,
    selectedItemsIds,
    totalItemsCount,
    filters,
    setItems,
    setOrdering,
    setPage,
    setSelectedItemsIds,
    handleSelection,
  } = useIncentivesDataContext();

  const getRecordClassName = (record: any) => {
    return classNames({ 'mixed-selection': !createMode, selectable: Boolean(record.incentive_id) });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const filteredSelectedRowKeys = newSelectedRowKeys.filter((key) =>
      createMode ? String(key).includes('units') : String(key).includes('units_incentives')
    );

    setSelectedItemsIds(filteredSelectedRowKeys);
    const filteredItems = (items ?? []).filter((item: UnitType) =>
      filteredSelectedRowKeys.includes(getRecordKey(item))
    );
    handleSelection(filteredItems);
  };

  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'incentives',
      'units',
      'list',
      managementCompanyId,
      selectedCompany,
      filters,
      ordering,
      key,
      createMode,
      archiveMode,
      currentTab,
    ],
    queryFn: () =>
      getUnits({
        search: filters.search,
        page: filters.page,
        page_size: filters.pageSize,
        ordering,
        companies: [selectedCompany?.id],
        management_companies: managementCompanyId ? [managementCompanyId] : undefined,
        communities: filters.communities,
        room_types: filters.roomTypes,
        care_types: filters.careTypes,
        occupancy_range: filters.occupancyRange,
        unit_availability: filters.unitAvailability,
        unit_vacancy_day_count: filters.unitVacancyDayCount,
        incentive_status: filters.incentiveStatus,
        distinct_units: createMode,
        archive_mode: archiveMode,
      }),
    onSuccess: (data) => {
      setItems(data);
    },
    enabled: Boolean(selectedCompany) || Boolean(managementCompanyId),
    refetchOnWindowFocus: false,
  });

  const handleChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    const { action } = extra;

    if (action === TABLE_ACTIONS.PAGINATE) {
      setPage(pagination.current);
    } else if (action === TABLE_ACTIONS.SORT) {
      setOrdering(getOrdering(sorter));
    }
  };

  const columns = [
    {
      title: 'Incentive',
      dataIndex: 'incentive',
      hidden: createMode,
      width: '30%',
      key: 'incentive_name',
      sorter: true,
      render: (value: any, record: any) => {
        const incentive = record.incentives?.find((item: IncentiveType) => item.id === record.incentive_id);
        return <IncentivesTableUnitsIncentive incentive={incentive} unit={record} />;
      },
    },
    {
      title: 'Unit',
      dataIndex: 'room_type',
      key: 'number',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableUnitsUnit record={record} />,
    },
    {
      title: 'Care Type',
      dataIndex: 'care_type',
      key: 'care_type',
      sorter: true,
    },
    {
      title: 'Standard Rate',
      dataIndex: 'market_rate',
      key: 'market_rate',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableUnitsStandardRate record={record} />,
    },
    {
      title: 'Community',
      key: 'community__comunity_name',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableUnitsCommunity record={record} />,
    },
    {
      title: 'Archived',
      hidden: !archiveMode,
      key: 'incentive_archived',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableUnitsArchived record={record} />,
    },
    {
      title: '',
      width: 100,
      hidden: createMode || selectionMode || archiveMode,
      render: (value: any, record: any) => <IncentivesTableUnitsActions record={record} />,
    },
  ];

  const showSelector = isIncentivesCorporate(currentUser) && !createMode && !archiveMode;
  return (
    <Container ref={containerRef}>
      {showSelector && <IncentivesTableUnitsSelectorControl />}
      <IncentivesStyledTable
        columns={columns}
        dataSource={items}
        loading={isLoading || isFetching}
        onChange={handleChange}
        showSorterTooltip={false}
        rowClassName={(record, index) => getRecordClassName(record)}
        rowKey={(record: any) => getRecordKey(record)}
        rowSelection={
          selectionMode
            ? {
                selectedRowKeys: selectedItemsIds,
                preserveSelectedRowKeys: true,
                onChange: onSelectChange,
              }
            : undefined
        }
        pagination={
          createMode
            ? false
            : {
                size: 'small',
                position: ['bottomLeft'],
                showSizeChanger: false,
                current: filters.page,
                pageSize: INCENTIVES_PAGE_SIZE,
                total: totalItemsCount,
                showTotal: (total, range) => {
                  const displayedItems = range[1] - range[0] + 1;
                  return (
                    <span>
                      Showing {displayedItems} of {total} records
                    </span>
                  );
                },
              }
        }
      />
    </Container>
  );
};

export default IncentivesTableUnits;
