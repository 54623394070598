import React, { useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';

import { createGoogleMetrics, createGoogleReviews, getGoogleMetricsReviews } from 'apis/GoogleMetricsAPI';
import { initiateReviewsRequest } from 'apis/ReviewsAPI';
import { useAppSelector } from 'store/hook';

import { COMMUNITY_GOOGLE_METRICS_PAGE_SIZE } from './constants';
import { GoogleMetricsDataType } from './types';
import AdminPageHeader from '../../common/Admin/AdminPageHeader';
import useDataList from '../../utils/hooks/useDataList';
import { useRootContext } from '../layout/RootContext';

const GoogleMetrics = () => {
  const { showMessage } = useRootContext();
  const { selectedCompany, companyList: userCompanies } = useAppSelector((state) => state.global);

  const { items, totalItemsCount, filters, setItems, setPage } = useDataList({
    initialState: {
      filters: {
        page: 1,
        pageSize: COMMUNITY_GOOGLE_METRICS_PAGE_SIZE,
      },
    },
  });

  const [communityType, setCommunityType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<number | null | undefined>(selectedCompany?.id);
  const [updates, setUpdates] = useState<any[]>([]);
  const [reviewsUpdates, setReviewsUpdates] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const fixRating = (rating: any | null) => {
    if (rating) {
      return Number(rating).toFixed(2);
    } else {
      return '';
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const { data, isLoading, refetch } = useQuery({
    queryKey: [communityType, filters],
    queryFn: () => getGoogleMetricsReviews({ ...filters, page_size: filters.pageSize, company, type: communityType }),
    onSuccess: (data) => {
      setItems(data);
    },
    enabled: Boolean(company) || Boolean(communityType),
  });

  const saveGoogleMetrics = () => {
    setLoading(true);

    Promise.all([createGoogleMetrics(updates), createGoogleReviews(reviewsUpdates)])
      .then((res) => {
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        refetch();
        setUpdates([]);
        setSelectedRowKeys([]);
        setReviewsUpdates([]);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  const fetchGoogleReviews = async () => {
    try {
      await initiateReviewsRequest(selectedRowKeys.map(Number));
      showMessage('success', 'Initiated reviews fetching for selected communities.');
    } catch (e) {
      showMessage('error', 'Something went wrong while fetching reviews.');
    }
  };

  const getGoogleMetrics = () => {
    const updates: any = [];
    let reviewUpdates: any = [];
    let place_id: any = null;
    let count = 0;

    const service = new google.maps.places.PlacesService(document.createElement('div'));

    const filteredCommunities = (items ?? []).filter((community: GoogleMetricsDataType) =>
      selectedRowKeys.includes(community.id)
    );

    filteredCommunities.forEach(async (community: GoogleMetricsDataType) => {
      if (community.google_map_location) {
        place_id = community.google_map_location;
      } else {
        try {
          const results = await geocodeByAddress(community.comunity_name);
          place_id = results[0]?.place_id || '';
        } catch (e) {
          count++;
          console.log(e);
          return;
        }
      }

      try {
        service.getDetails(
          {
            placeId: place_id,
          },
          async function callback(place: any, status: any) {
            if (status == 'INVALID_REQUEST') {
              try {
                const results = await geocodeByAddress(community.comunity_name);
                place_id = results[0]?.place_id || '';
                service.getDetails(
                  {
                    placeId: place_id,
                  },
                  callback
                );
                return;
              } catch (e) {
                console.log(e);
              }
            }
            count++;
            if (place) {
              updates.push({
                apartment: community.id,
                old_google_ratings: community.google_rating,
                old_google_reviews: community.google_reviews,
                google_ratings: place.rating || 0,
                google_reviews: place.user_ratings_total || 0,
                is_latest: true,
              });

              const placeReviews = place.reviews?.map((review: any) => ({
                apartment: community.id,
                google_review_user: review.author_name,
                google_review_date: new Date(review.time * 1000),
                google_review_text: review.text,
                google_review_url: review.author_url,
                is_latest: true,
              }));
              reviewUpdates = [...reviewUpdates, ...(placeReviews || [])];
            }
            if (count == selectedRowKeys.length) {
              setUpdates(updates);
              setReviewsUpdates(reviewUpdates);
              showMessage('success', 'Fetching Google Metrics completed.');
            }
          }
        );
      } catch (err) {
        showMessage('error', 'Trouble fetching Google Metrics');
        console.log(err);
      }
    });
  };

  const COLUMNS: ColumnsType<GoogleMetricsDataType> = [
    Table.EXPAND_COLUMN,
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 110,
    },
    {
      title: 'Community',
      dataIndex: 'comunity_name',
      key: 'comunity_name',
      width: 200,
      render: (_, row) => <Link to={`${row.id}`}>{row.comunity_name}</Link>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (_, row) => (row.type == 'apartment' ? 'Community' : 'Competitor'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: 300,
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      key: 'latitude',
      width: 100,
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      key: 'longitude',
      width: 100,
    },
    {
      title: 'Old Rating',
      dataIndex: 'google_rating',
      key: 'google_rating',
      render: (_, row) => {
        const lastMetricsEntry = row?.metrics.length && row.metrics[row.metrics.length - 1];
        return fixRating(lastMetricsEntry && lastMetricsEntry.old_google_ratings);
      },
    },
    {
      title: 'Old Reviews',
      dataIndex: 'google_reviews',
      key: 'google_reviews',
      render: (_, row) => {
        const lastMetricsEntry = row?.metrics.length && row.metrics[row.metrics.length - 1];
        return fixRating(lastMetricsEntry && lastMetricsEntry.old_google_reviews);
      },
    },
    {
      title: 'Rating',
      dataIndex: 'google_rating',
      key: 'google_rating',
      render: (_, row) => {
        const updatedCommunity = updates.find((updatedEntry: any) => updatedEntry.apartment_id === row.id);
        return updatedCommunity?.google_ratings ?? row.google_rating?.toFixed(2);
      },
    },
    {
      title: 'Reviews',
      dataIndex: 'google_reviews',
      key: 'google_reviews',
      render: (_, row) => {
        const updatedCommunity = updates.find((updatedEntry: any) => updatedEntry.apartment_id === row.id);
        return updatedCommunity?.google_reviews ?? row.google_reviews;
      },
    },
    Table.SELECTION_COLUMN,
  ];

  return (
    <div style={{ padding: '20px', width: '100%' }}>
      <AdminPageHeader
        title={'Google Metrics'}
        description={'Preview community data and information available on Google Business.'}
      />
      <Row gutter={[16, 16]}>
        <Col offset={4} xs={24} lg={6}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={company}
            options={userCompanies}
            placeholder="Company"
            style={{ width: '100%' }}
            onSelect={(val) => {
              setCompany(val);
            }}
          />
        </Col>
        <Col xs={24} lg={6}>
          <Select
            options={[
              {
                label: 'Community',
                value: 'apartment',
              },
              {
                label: 'Competitor',
                value: 'competitor',
              },
            ]}
            placeholder="Type"
            style={{ width: '100%' }}
            onSelect={(val) => setCommunityType(val)}
          />
        </Col>
        <Col xs={24} lg={8}>
          <Button
            style={{ float: 'right', padding: '0 32px', marginRight: '15px' }}
            onClick={getGoogleMetrics}
            disabled={!hasSelected}
          >
            Get Metrics
          </Button>
          <Button
            style={{ float: 'right', padding: '0 32px', marginRight: '15px' }}
            onClick={fetchGoogleReviews}
            disabled={!hasSelected}
          >
            Fetch Reviews
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={items}
        columns={COLUMNS}
        loading={isLoading}
        pagination={{
          defaultCurrent: 1,
          hideOnSinglePage: true,
          showSizeChanger: false,
          total: totalItemsCount,
          onChange: (pageNumber: number) => setPage(pageNumber),
          pageSize: filters.pageSize,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: '0 30px 20px' }}>
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  <h4>Author</h4>
                </Col>
                <Col span={10}>
                  <h4>Text</h4>
                </Col>
                <Col span={6}>
                  <h4>Url</h4>
                </Col>
                <Col span={4}>
                  <h4>Date</h4>
                </Col>
              </Row>
              {record?.reviews.map((item: any, index: any) => (
                <Row key={'id' + index} gutter={[16, 16]}>
                  <Col span={4}>
                    <p>{item.google_review_user}</p>
                  </Col>
                  <Col span={10}>
                    <Typography.Paragraph ellipsis={{ rows: 6, expandable: true, symbol: 'more' }}>
                      {item.google_review_text}
                    </Typography.Paragraph>
                  </Col>
                  <Col span={6}>
                    <p>{item.google_review_url}</p>
                  </Col>
                  <Col span={4}>
                    <p>{format(new Date(item.google_review_date), 'MM-dd-yyyy:hh:mm:ss')}</p>
                  </Col>
                </Row>
              ))}
            </div>
          ),
          rowExpandable: (record) => Boolean(record?.reviews?.length),
          expandIcon: ({ expanded, onExpand, record }) => (
            <>
              {Boolean(record?.reviews?.length) && (
                <div>
                  {expanded ? (
                    <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                  )}
                </div>
              )}
            </>
          ),
        }}
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
      />
      <Button
        type="primary"
        onClick={saveGoogleMetrics}
        loading={loading}
        disabled={updates.length < 1}
        style={{ float: 'right', marginRight: '20px', marginTop: '20px' }}
      >
        Save
      </Button>
    </div>
  );
};

export default GoogleMetrics;
