import React from 'react';

import styled from 'styled-components';

import { isIncentivesCorporate } from '../../../helpers/permissions';
import { useAppSelector } from '../../../store/hook';
import IncentivesActions from '../actions/IncentivesActions';
import IncentivesFilters from '../filters/IncentivesFilters';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IncentivesControls = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const { createMode, archiveMode, selectionMode } = useIncentivesDataContext();

  const showActions = isIncentivesCorporate(currentUser) && !createMode && !archiveMode && !selectionMode;

  return (
    <Container>
      <IncentivesFilters />
      {showActions && <IncentivesActions />}
    </Container>
  );
};

export default IncentivesControls;
