import React from 'react';

import { UnitType } from '../../types';

interface Props {
  record: UnitType;
}

const IncentivesTableUnitsStandardRate = ({ record }: Props) => (
  <>
    ${record?.market_rate?.toLocaleString()}
    <span
      style={{
        fontSize: 12,
        color: 'var(--text-secondary)',
      }}
    >
      /mo
    </span>
  </>
);

export default IncentivesTableUnitsStandardRate;
