import React, { useContext } from 'react';

import { HomeOutlined } from '@ant-design/icons/lib';
import classNames from 'classnames';
import styled from 'styled-components';

import { SELECTOR_ITEM_DESCRIPTION } from './constants';
import { SidebarContext } from './SidebarContext';
import { SelectorItemDatatype } from './types';
import Avatar from '../../../common/Avatar';
import { ACTOR_TYPE } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px 10px;
  gap: 10px;
  border-top: solid 1px var(--line-gray);
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    background-color: var(--background-gray);
  }

  &.selected {
    background-color: var(--background-gray);
  }

  &:first-child {
    border-top: none;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary):
`;

const Description = styled.div`
  font-size: 12px;
  color: var(--gray-dark);
`;

const Type = styled.span`
  color: var(--positive-green);

  &.competitor {
    color: var(--neutral-purple);
  }
`;

const Dot = styled.span`
  padding: 0px 4px;
`;

interface Props {
  item: SelectorItemDatatype;
}

const SidebarSelectorDropdownListItem = ({ item }: Props) => {
  const { selectedItem, handleItemSelected } = useContext(SidebarContext);
  const Icons = {
    [ACTOR_TYPE.COMPANY]: (
      <img
        src={'/icons/business.svg'}
        style={{
          width: 16,
          height: 16,
          color: '',
        }}
      />
    ),
    [ACTOR_TYPE.MANAGEMENT_COMPANY]: <HomeOutlined />,
    [ACTOR_TYPE.COMMUNITY]: undefined,
    [ACTOR_TYPE.COMPETITOR]: undefined,
  };

  return (
    <Container
      className={classNames({ selected: item.id === selectedItem?.id && item.type === selectedItem?.type })}
      onClick={() => handleItemSelected(item)}
    >
      <Avatar name={item.name} type={item.type} icon={Icons[item.type]} />
      <Details>
        <Title>{item.name}</Title>
        <Description>
          <Type className={classNames({ competitor: item.type === ACTOR_TYPE.COMPETITOR })}>
            {SELECTOR_ITEM_DESCRIPTION[item.type]} {item.type == ACTOR_TYPE.COMPANY && 'homepage'}
          </Type>
          {[ACTOR_TYPE.COMPETITOR, ACTOR_TYPE.COMMUNITY].includes(item.type) && (
            <>
              <Dot>·</Dot>
              <span>{item.description}</span>
            </>
          )}
        </Description>
      </Details>
    </Container>
  );
};

export default SidebarSelectorDropdownListItem;
