import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteView } from '../../../apis/ViewAPI';
import { STATUS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { handleStoreUserViews } from '../../../store/slices/globalSlice';
import { useRootContext } from '../../layout/RootContext';
import { VIEW_MODALS } from '../../View/constants';
import ViewInputModal from '../../View/modals/ViewInputModal';
import { useCommunityViewContext } from '../CommunityViewContext';

interface Props {
  open: boolean;
  onClose: any;
}

const DeleteViewModal = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { viewId, communityId, competitorId } = useCommunityViewContext();

  const { showMessage } = useRootContext();
  const { views } = useAppSelector((state) => state.global);
  const [deleteStatus, setDeleteStatus] = useState<string>(STATUS.IDLE);

  const handleConfirm = async () => {
    setDeleteStatus(STATUS.LOADING);
    return await deleteView(viewId)
      .then(async (viewResponse) => {
        const newViews = views.filter((item: any) => item.id != viewId);
        dispatch(handleStoreUserViews([...newViews]));

        showMessage('success', 'View deleted successfully.');
        setDeleteStatus(STATUS.LOADED);
        if (competitorId) {
          navigate(`/competitor/${competitorId}`);
        } else {
          navigate(`/community/${communityId}`);
        }
        onClose();
      })
      .catch((reason) => {
        setDeleteStatus(STATUS.FAILURE);
        showMessage('error', 'Trouble deleted the view.');
      });
  };

  return (
    <ViewInputModal
      open={open}
      type={VIEW_MODALS.DELETE}
      loading={deleteStatus === STATUS.LOADING}
      onConfirm={handleConfirm}
      onCancel={() => onClose()}
    />
  );
};

export default DeleteViewModal;
