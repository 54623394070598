import React from 'react';

import { format } from 'date-fns';
import * as XLSX from 'xlsx';
// @ts-ignore
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

import { DATE_COLUMNS, FILE_EXTENSION, FILE_TYPE, SUBTITLE_ARR } from './constants';

const indexToExcelColumn = (index: number) => {
  let column = '';
  while (index > 0) {
    const remainder = (index - 1) % 26;
    column = String.fromCharCode(65 + remainder) + column;
    index = Math.floor((index - 1) / 26);
  }
  return column;
};


const addStyle = (workbookBlob: any, dataInfo: any, headerIndexes: number, mainKeys: number[]) => {
  return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook: any) => {
    workbook.sheets().forEach((sheet: any, index: number) => {
      if (index == 0) {
        sheet.usedRange().style({
          fontFamily: 'AvenirLTPro-Medium',
          verticalAlignment: 'center',
        });

        sheet.column('D').width(30);
        for (let i = 0; i < headerIndexes; i++) {
          const length = sheet.column(String.fromCharCode(65 + i)).cell(3)._value.length;
          sheet.column(String.fromCharCode(65 + i)).width(length * 2.5);
        }

        sheet.range(dataInfo.titleRange).merged(true).style({
          fontSize: 36,
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          underline: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: 'left',
            bold: true,
            fontSize: 10,
            wrapText: true,
          });
        }
        sheet.row(1).style({
          horizontalAlignment: 'left',
          fontSize: 9,
        });
        sheet.cell(2, 4).style({
          horizontalAlignment: 'center',
          verticalAlignment: 'bottom',
          fontSize: 9,
        });
        sheet.row(3).style({
          bold: true,
          horizontalAlignment: 'left',
          fontSize: 24,
        });

        if (dataInfo.tlinkRange) {
          sheet.range(dataInfo.tlinkRange).style({
            horizontalAlignment: 'left',
            bold: false,
            fontSize: 14,
            fontColor: '0563c1',
            underline: true,
          });
        }
        sheet.range(dataInfo.theadRange).style({
          fill: 'D9D9D9',
          bold: true,
          horizontalAlignment: 'left',
        });
        for (let i = 0; i < mainKeys.length; i++) {
          sheet.cell(4 + mainKeys[i], 1).style({
            underline: true,
            fontSize: 16,
          });
        }
      } else if (index == 1) {
        sheet.column('A').width(35);
        sheet.column('B').width(55);
        sheet.cell(7, 2).style({
          horizontalAlignment: 'left',
          fontColor: '0563c1',
          underline: true,
        });
      }
    });

    return workbook.outputAsync();
  });
};

export const generateExcelFile = async (
  tableData: any[],
  dataHeaders: any[],
  fileName: string,
  userFullName: string,
  communityName: string
) => {
  const updated_data = tableData.map((el) => {
    let obj = {};

    Object.keys(el).forEach((key) => {
      if (key !== 'tableKey') {
        if (key !== 'dataKey') {
          const header_name = el[key][0].comunity_name || el[key][0].CommunityName;
          if (DATE_COLUMNS.includes(el['dataKey']) && el[key][0][el['dataKey']]) {
            obj = {
              ...obj,
              [header_name]: format(new Date(el[key][0][el['dataKey']]), 'MM-dd-yyyy'),
            };
          } else if ((el['dataKey'] == 'incentive' || el['dataKey'] == 'occupancies') && el[key][0][el['dataKey']]) {
            obj = {
              ...obj,
              [header_name]: el[key][0][el['dataKey']].join('\n'),
            };
          } else {
            obj = {
              ...obj,
              [header_name]: el[key][0][el['dataKey']],
            };
          }
        } else {
          if (el[key].indexOf('_') !== -1) {
            const value = el[key].split('_');
            for (let i = 0; i < value.length; i++) {
              value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
            }
            obj = {
              ['Community Name']: value.join(' '),
              ...obj,
            };
          } else if (el[key] == 'incentive' || el[key] == 'occupancies') {
            {
              return;
            }
          } else {
            obj = {
              ['Community Name']: el[key].charAt(0).toUpperCase() + el[key].slice(1),
              ...obj,
            };
          }
        }
      }
    });
    return obj;
  });

  const header: any[] = [];
  Object.keys(updated_data[0]).forEach((key) => {
    header.push(key);
  });

  const fromIndex = header.indexOf(communityName);
  const value = header[1];
  header[1] = communityName;
  header[fromIndex] = value;
  let headerIndexes = 0;
  const mainKeys: number[] = [];
  updated_data.forEach((data, index) => {
    for (const key in data) {
      // @ts-ignore
      if (SUBTITLE_ARR.indexOf(data[key]) !== -1) {
        mainKeys.push(index);
      }
    }

    const keysLength = Object.keys(data).length;
    headerIndexes = keysLength > headerIndexes ? keysLength : headerIndexes;
  });

  const ws = XLSX.utils.json_to_sheet([]);

  const wb = {
    Sheets: { CMA_Summary: ws },
    SheetNames: ['CMA_Summary'],
    origin: 'A1',
  };

  XLSX.utils.sheet_add_json(
    wb.Sheets.CMA_Summary,
    [
      {
        note: "Report Generated by Further's Pricing Assistant",
      },
    ],
    {
      header: ['note'],
      skipHeader: true,
      origin: 'A1',
    }
  );

  XLSX.utils.sheet_add_json(
    wb.Sheets.CMA_Summary,
    [
      {
        logo: <img src="/pricing_assistant_logo.png" />,
        note: 'CMA Summary',
        space: '',
        at: `Report Created at: ${format(new Date(), 'MM-dd-yyyy:hh:mm:ss')}`,
      },
    ],
    {
      header: ['logo', 'note', 'space', 'at'],
      skipHeader: true,
      origin: 'A2',
    }
  );

  XLSX.utils.sheet_add_json(wb.Sheets.CMA_Summary, updated_data, {
    header: header,
    skipHeader: false,
    origin: 'A3',
  });
  const ws_details_sheet = 'Pricing Assistant Details';

  /* make worksheet */
  const ws_data = [
    ['Company Name', 'Further Technologies'],
    ['Product Name', 'Pricing Assistant'],
    ['File Name', `${fileName}`],
    ['Product Name', 'Pricing Assistant'],
    ['Download By', `${userFullName}`],
    ['Download Date', `${format(new Date(), 'MM-dd-yyyy')}`],
    ['Learn More about the Pricing Assistant', 'https://www.talkfurther.com/pricingassistant'],
  ];
  const ws_details = XLSX.utils.aoa_to_sheet(ws_data);

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws_details, ws_details_sheet);

  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
    Props: {
      Company: 'Further Technologies',
      Manager: 'Alex Smith',
      Author: `${userFullName}`,
      LastAuthor: `${userFullName}`,
      Title: 'CMA Summary From Pricing Assistant',
      Subject: `CMA Summary File of ${communityName}`,
    },
  });

  const data = new Blob([excelBuffer], { type: FILE_TYPE });

  const dataInfo = {
    atCell: 'D2:D2',
    titleRange: 'B2:C2',
    tbodyRange: `A4:${indexToExcelColumn(headerIndexes)}${updated_data.length + 3}`,
    tlinkRange: `B5:${indexToExcelColumn(headerIndexes)}5`,
    theadRange: `A3:${indexToExcelColumn(headerIndexes-1)}3`,
  };

  return addStyle(data, dataInfo, headerIndexes, mainKeys);
};

export const exportToExcel = async (
  tableData: any[],
  dataHeaders: any[],
  userFullName: string,
  communityName: string
) => {
  const filename = communityName + '-' + format(new Date(), 'MM-dd-yyyy');
  generateExcelFile(tableData, dataHeaders, filename, userFullName, communityName)
    .then((workbookBlob: any) => URL.createObjectURL(workbookBlob))
    .then((url: any) => {
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', url);
      downloadAnchorNode.setAttribute('download', filename + FILE_EXTENSION);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
};
