import React, { useState } from 'react';

import { updatePartialView } from '../../../apis/ViewAPI';
import { STATUS } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { handleStoreUserViews } from '../../../store/slices/globalSlice';
import { useRootContext } from '../../layout/RootContext';
import { VIEW_MODALS } from '../../View/constants';
import ViewInputModal from '../../View/modals/ViewInputModal';
import { useCommunityViewContext } from '../CommunityViewContext';

interface Props {
  open: boolean;
  onClose: any;
}

const RenameViewModal = ({ open, onClose }: Props) => {
  const { viewId } = useCommunityViewContext();
  const dispatch = useAppDispatch();

  const { views } = useAppSelector((state) => state.global);
  const { showMessage } = useRootContext();
  const [renameStatus, setRenameStatus] = useState<string>(STATUS.IDLE);

  const handleConfirm = async (viewName: string) => {
    setRenameStatus(STATUS.LOADING);
    return await updatePartialView(viewId, {
      name: viewName,
    })
      .then(async (viewResponse) => {
        const modifiedViewIndex = views.findIndex((item: any) => item.id == viewId);
        if (modifiedViewIndex) {
          const newViews = [...views];
          newViews[modifiedViewIndex] = viewResponse;
          dispatch(handleStoreUserViews([...newViews]));
        }
        setRenameStatus(STATUS.LOADED);
        showMessage('success', 'View renamed successfully.');
        onClose();
      })
      .catch((reason) => {
        setRenameStatus(STATUS.FAILURE);
        showMessage('error', 'Trouble renaming the view.');
      });
  };

  return (
    <ViewInputModal
      open={open}
      type={VIEW_MODALS.RENAME}
      loading={renameStatus === STATUS.LOADING}
      onConfirm={handleConfirm}
      onCancel={() => onClose()}
    />
  );
};

export default RenameViewModal;
