import React from 'react';
import { useParams } from 'react-router';

import CommunityView from './CommunityView';
import { useAppSelector } from '../../store/hook';

const CommunityViewWrapper = () => {
  const { viewId } = useParams();
  const { selectedCommunity } = useAppSelector((state) => state.global);

  const communityId = selectedCommunity?.id || -1;
  return (
    <CommunityView
      viewId={parseInt(viewId ?? '')}
      communityId={communityId}
      fallbackUrl={`/community/${communityId}`}
    />
  );
};

export default CommunityViewWrapper;
