import React, { createContext, useContext } from 'react';

import { ClientDataType, CompanyDataType, ManagementCompanyDataType } from './types';

type SelectorContent = {
  client?: ClientDataType;
  company?: CompanyDataType;
  managementCompany?: ManagementCompanyDataType;
  setClient: (value: ClientDataType | undefined) => void;
  setCompany: (value: CompanyDataType | undefined) => void;
  setManagementCompany: (value: ManagementCompanyDataType | undefined) => void;
};

export const SelectorContext = createContext<SelectorContent>({
  client: undefined,
  company: undefined,
  managementCompany: undefined,
  setClient: () => {
    // Do nothing
  },
  setCompany: () => {
    // Do nothing
  },
  setManagementCompany: () => {
    // Do nothing
  },
});

export const useSelectorContext = () => useContext(SelectorContext);
