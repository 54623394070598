import React from 'react';

import { updateIncentiveUnit } from '../../../apis/IncentiveAPI';
import ConfirmModal from '../../../common/Modal/ConfirmModal';
import { useRootContext } from '../../layout/RootContext';

interface Props {
  open: boolean;
  incentives: React.Key[];
  onClose: any;
}

const IncentiveUnitArchiveModal = ({ open, incentives, onClose }: Props) => {
  const { showMessage } = useRootContext();
  const handleConfirm = async () => {
    Promise.all(
      incentives
        .filter((id: React.Key) => id)
        .map((id: React.Key) => {
          const [type, unit_id, incentiveId] = String(id).split('-');
          return updateIncentiveUnit(Number(incentiveId), Number(unit_id), { archived_at: new Date() });
        })
    )
      .then((response: any) => {
        showMessage('success', 'Incentive record archived.');
        onClose();
      })
      .catch((reason: any) => {
        showMessage('error', 'Trouble archiving incentive record');
      });
  };

  return (
    <ConfirmModal
      open={open}
      title={'Archive Incentive Record?'}
      description={
        `This will remove the incentive(s) from the selected unit(s) and archive the\n` +
        'record. The incentive, if applied to other units, will still remain.'
      }
      confirmLabel={'Archive'}
      onConfirm={handleConfirm}
      onCancel={onClose}
    />
  );
};

export default IncentiveUnitArchiveModal;
