import React from 'react';

import { Col, Row } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import HeaderSelectorClear from './HeaderSelectorClear';
import HeaderSelectorCommunity from './HeaderSelectorCommunity';
import HeaderSelectorCompany from './HeaderSelectorCompany';
import HeaderSelectorManagementCompany from './HeaderSelectorManagementCompany';

const Container = styled.div`
  width: 51rem;
  margin: 0 10px;
`;
const HeaderSelectorContent = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  return (
    <Container>
      <Row gutter={[10, 5]} justify="space-around" align="middle">
        {currentUser?.isSuperuser && (
          <Col xs={24} lg={8}>
            <HeaderSelectorCompany />
          </Col>
        )}
        <Col xs={24} lg={currentUser?.isSuperuser ? 7 : 12}>
          <HeaderSelectorManagementCompany />
        </Col>
        <Col xs={24} lg={currentUser?.isSuperuser ? 7 : 12}>
          <HeaderSelectorCommunity />
        </Col>
        {currentUser?.isSuperuser && (
          <Col xs={24} lg={2}>
            <HeaderSelectorClear />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default HeaderSelectorContent;
