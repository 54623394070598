import { useEffect } from 'react';

/**
 * When multiple selection is disabled, we need to clear the remaining selected communities.
 * That way, when user enables multiple selection again, no communities will be selected.
 */
export const useRemoveSelectedCommunities = (
  isMultipleSelection: boolean,
  selectedCommunitiesLength: number,
  setSelectedCommunitiesIds: (ids: number[]) => void
) => {
  useEffect(() => {
    if (!isMultipleSelection && selectedCommunitiesLength) {
      setSelectedCommunitiesIds([]);
    }
  }, [isMultipleSelection, selectedCommunitiesLength, setSelectedCommunitiesIds]);
};

/**
 *  Once all communities in the list are selected, we want to check the "All communities selected" checkbox.
 *  Otherwise, if all communities were selected and user unchecks one of them - we want to uncheck the
 *  "All communities selected" checkbox.
 */
export const useAllCommunitiesSelectedCheckbox = (
  allCommunitiesSelected: boolean,
  selectedCommunitiesLenght: number,
  communitiesLength: number,
  setAllCommunitiesSelected: (value: boolean) => void
) => {
  useEffect(() => {
    if (allCommunitiesSelected && selectedCommunitiesLenght !== communitiesLength) {
      setAllCommunitiesSelected(false);
    } else if (selectedCommunitiesLenght === communitiesLength && !allCommunitiesSelected) {
      setAllCommunitiesSelected(true);
    }
  }, [selectedCommunitiesLenght, allCommunitiesSelected, communitiesLength, setAllCommunitiesSelected]);
};
