import React, { useState } from 'react';

import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import Checkbox from '../../lib/Checkbox';
import DatePicker from '../../lib/DatePicker';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  font-family: var(--font-bold);
  font-size: 14px
  color: var(--text-primary);
`;

const IncentivesCreateOffersInfoDates = () => {
  const { startDate, endDate, setStartDate, setEndDate } = useIncentivesCreateModalContext();
  const [disableEndDate, setDisableEndDate] = useState<boolean>(true);

  const handleTimeLimitToggle = (e: any) => {
    const checked = e.target.checked;
    setDisableEndDate(!checked);
    setEndDate(checked ? dayjs(startDate).add(1, 'month') : undefined);
  };

  const handleStartDateChange = (value: Dayjs) => {
    setStartDate(value);
    if (dayjs(value).isAfter(dayjs(endDate))) {
      setEndDate(dayjs(value).add(30, 'day'));
    }
  };

  return (
    <Container>
      <div>
        <Label>Starts</Label>
        <DatePicker value={startDate} minDate={dayjs()} onChange={handleStartDateChange} />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            gap: 5,
          }}
        >
          <Checkbox
            className={classNames('green', 'small')}
            checked={!disableEndDate}
            onChange={handleTimeLimitToggle}
          />
          <Label>Ends</Label>
        </div>
        <DatePicker
          disabled={disableEndDate}
          value={endDate ?? dayjs(startDate).add(1, 'month')}
          minDate={startDate}
          onChange={(value: Dayjs) => setEndDate(value)}
        />
      </div>
    </Container>
  );
};

export default IncentivesCreateOffersInfoDates;
