import React from 'react';

import { WIDGET_MODAL_TYPE } from './constants';
import { useWidgetContext } from './WidgetContext';
import CommunityViewWidgetModal from '../../../components/View/create/CommunityViewWidgetModal';

const WidgetEditModal = () => {
  const { id, type, name, note, filters, showEditWidgetModal, setShowEditWidgetModal } = useWidgetContext();

  const handleClose = () => {
    setShowEditWidgetModal(false);
  };

  return (
    <CommunityViewWidgetModal
      open={showEditWidgetModal}
      onClose={handleClose}
      modalType={WIDGET_MODAL_TYPE.EDIT}
      widget={{
        id,
        type,
        name,
        note,
        filters,
      }}
    />
  );
};

export default WidgetEditModal;
