import React from 'react';

import { Steps, Typography } from 'antd';

import { useAppDispatch } from 'store/hook';
import { handleClearCommunityData } from 'store/slices/communitySlice';

import BasicInfoForm from './BasicInfoForm';
import CompetitorsForm from './CompetitorsForm';
import AccommodationFormOnboarding from '../../pageComponents/AddCommunityCompetitor/accommodation/AccommodationFormOnboarding';
import FeesFormOnboarding from '../../pageComponents/AddCommunityCompetitor/fees/FeesFormOnboarding';

interface Props {
  onComplete: (community: any) => void;
}

const AddCommunity = ({ onComplete }: Props) => {
  const dispatch = useAppDispatch();
  const [current, setCurrent] = React.useState(0);

  const renderComponent = () => {
    if (current === 0) {
      return <BasicInfoForm current={current} setCurrent={setCurrent} />;
    } else if (current === 1) {
      return <AccommodationFormOnboarding current={current} setCurrent={setCurrent} />;
    } else if (current === 2) {
      return <FeesFormOnboarding current={current} setCurrent={setCurrent} />;
    } else if (current === 3) {
      return <CompetitorsForm current={current} setCurrent={setCurrent} onCreate={onComplete} />;
    }
    return null;
  };

  React.useEffect(() => {
    dispatch(handleClearCommunityData());
    return () => {
      dispatch(handleClearCommunityData());
    };
  }, []);

  return (
    <div
      style={{
        padding: '30px',
        position: 'relative',
        height: '100%',
      }}
    >
      <Typography.Title style={{ textAlign: 'center', fontSize: '56px' }}>Add Community</Typography.Title>
      <div style={{ maxWidth: 570, margin: '0 auto' }}>
        <Steps
          current={current}
          labelPlacement="vertical"
          size="small"
          items={[
            {
              title: 'Basic Info',
            },
            {
              title: 'Accommodation',
            },
            {
              title: 'Fees & Care',
            },
            {
              title: 'Competitors',
            },
          ]}
        />
      </div>
      <div style={{ margin: '40px 0px 30px 0px' }}>{renderComponent()}</div>
    </div>
  );
};

export default AddCommunity;
