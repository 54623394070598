import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import Dropdown from '../../components/lib/Dropdown';
import DownCaretIcon from '../Icons/DownCaretIcon';

const StyledDropdownMenu = styled.div`
  .ant-dropdown-menu {
    padding: 0;
    border-radius: 0;
    border: 1px solid var(--line-gray);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 155px;
    max-height: 160px;
    overflow-y: auto;

    .ant-dropdown-menu-item {
      font-size: 12px;
      pointer-events: none;
    }
  }
`;

const Label = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  &.aggregate {
    cursor: pointer;
    &:hover {
      color: var(--link-blue);
    }
  }
`;

interface Props {
  labelPlural: string;
  options: any[];
}

const LabelsList = ({ options, labelPlural }: Props) => {
  const items = options.map((item: any, index: number) => ({
    key: index,
    label: item,
  }));

  const hasMultipleOptions = options.length > 1;
  const firstItemLabel = options.length === 1 ? options[0] : '';
  const label = hasMultipleOptions ? `${options.length} ${labelPlural}` : firstItemLabel;

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={(menu: any) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
      placement={'bottomRight'}
      disabled={!hasMultipleOptions}
    >
      <Label
        className={classNames({
          aggregate: hasMultipleOptions,
        })}
      >
        {label}
        {hasMultipleOptions && <DownCaretIcon size={8} />}
      </Label>
    </Dropdown>
  );
};

export default LabelsList;
