import { INCENTIVES_FEES_TYPE } from '../create/constants';

export const INCENTIVE_PROPOSAL_MODAL_WIDTH = 900;

export const DEFAULT_INCENTIVE_PROPOSAL_LEAD = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
};

export const DEFAULT_INCENTIVE_PROPOSAL_RATES = [INCENTIVES_FEES_TYPE.MONTHLY_RENT, INCENTIVES_FEES_TYPE.COMMUNITY_FEE];

export const BLANK_INCENTIVE_PROPOSAL_PRICING = {
  amount: undefined,
  name: undefined,
  type: undefined,
  discount_type: undefined,
  discount: undefined,
  discount_duration_type: undefined,
  discount_duration: undefined,
};

export const PROPOSAL_DURATION = {
  EXPIRATION_DATE: -1,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FIVE: 5,
  SEVEN: 7,
  TEN: 10,
  FIFTEEN: 15,
};

export const DEFAULT_INCENTIVE_PROPOSAL = {
  expires_at: undefined,
  lead: DEFAULT_INCENTIVE_PROPOSAL_LEAD,
  pricing: [],
};
