import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Dropdown from '../../../components/lib/Dropdown';
import DownCaretIcon from '../../Icons/DownCaretIcon';

const StyledDropdownMenu = styled.div`
  .ant-dropdown-menu {
    padding: 0;
    border-radius: 0;
    border: 1px solid var(--line-gray);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    width: 155px;
    max-height: 160px;
    overflow-y: auto;

    .ant-dropdown-menu-item {
      font-size: 12px;
    }
  }
`;

const Label = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  gap: 2px;

  span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

interface Props {
  selection: number;
  options: any[];
  onSelect: any;
}

const WidgetFiltersInfoSelect = ({ selection, options, onSelect }: Props) => {
  const [selectedItem, setSelectedItem] = useState<any>(undefined);

  useEffect(() => {
    const currentSelectedItem = options?.find((item: any) => item.id === selection);

    if (currentSelectedItem) {
      setSelectedItem(currentSelectedItem);
    } else if (options?.length > 0) {
      setSelectedItem(options[0]);
    }
  }, [selection]);

  const items = options.map((item: any) => ({
    key: item.id,
    label: item.name,
    onClick: () => onSelect(item.id),
  }));

  return (
    <>
      <Dropdown
        menu={{ items }}
        dropdownRender={(menu) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
        placement={'bottomRight'}
      >
        <Label style={{ cursor: 'pointer' }}>
          <span>{selectedItem?.name}</span>
          <DownCaretIcon />
        </Label>
      </Dropdown>
      <span>·</span>
    </>
  );
};

export default WidgetFiltersInfoSelect;
