import React, { useEffect, useState } from 'react';

import { Breadcrumb } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import { useAppSelector } from '../../store/hook';

const StyledBreadcrumb = styled(Breadcrumb)`
  li {
    &:first-child {
      color: var(--text-primary);
    }
    &:last-child {
      color: var(--text-secondary);
    }

    .dark-blue {
      color: var(--dark-blue);
    }
  }
`;

type Props = {
  showAllSettings: boolean;
};

const SettingsBreadcrumbs = ({ showAllSettings }: Props) => {
  const { selectedManagementCompany } = useAppSelector((state) => state.global);
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    if (selectedManagementCompany) {
      const classes = classNames({
        'dark-blue': showAllSettings,
      });
      setItems([
        {
          title: showAllSettings ? selectedManagementCompany.management_company : 'Settings',
          className: classes,
        },
        { title: showAllSettings ? 'Management Company' : 'Email Notifications' },
      ]);
    }
  }, [selectedManagementCompany]);

  return <StyledBreadcrumb items={items} separator="·" />;
};

export default SettingsBreadcrumbs;
