import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { useWidgetContext } from './WidgetContext';
import WidgetFiltersInfo from './WidgetFiltersInfo';
import { WIDGET_TYPES } from '../constants';
import GraphWidgetDisplayControls from '../GraphWidget/GraphWidgetDisplayControls';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.map {
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 5;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 18px;
  color: var(--text-primary);
  word-break: break-word;
  overflow: auto;
`;

const Note = styled.div`
  font-size: 12px;
  color: var(--gray-dark);
  word-break: break-word;
`;

const WidgetHeader = () => {
  const { name, note, type } = useWidgetContext();
  const showTitle = type !== WIDGET_TYPES.MAP;

  return (
    <Container
      className={classNames({
        map: type === WIDGET_TYPES.MAP,
      })}
    >
      <Row>
        {showTitle && (
          <Title title={name} className="widget-title">
            {name}
          </Title>
        )}
        <GraphWidgetDisplayControls />
      </Row>
      <WidgetFiltersInfo />
      <Note>{note}</Note>
    </Container>
  );
};

export default WidgetHeader;
