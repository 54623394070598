import React from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { SORT_ORDER } from '../../../constants';

type Props = {
  sortOrder: any;
};

const ShopRequestSortIcon = ({ sortOrder }: Props) => {
  if (sortOrder === SORT_ORDER.ASCEND) {
    return <DownOutlined />;
  } else if (sortOrder === SORT_ORDER.DESCEND) {
    return <UpOutlined />;
  }
  return <div />;
};

export default ShopRequestSortIcon;
