export const INCENTIVES_CREATE_MODAL_WIDTH = 1440;
export const INCENTIVES_PAGE_SIZE = 12;
export const INCENTIVES_PAGE_SIZE_ALL = 10000;

export const TABLE_ACTIONS = {
  SORT: 'sort',
  PAGINATE: 'paginate',
  FILTER: 'filter'
};

export const INCENTIVE_STATUS = {
  ARCHIVED: 0,
  ACTIVE: 1,
};

export const INCENTIVES_TABS = {
  INCENTIVE: 'incentive',
  UNIT: 'unit',
  PROPOSAL: 'proposal',
  ARCHIVE: 'archive',
};

export const HOUSING_CONTRACT_STATUS = {
  PRIOR: 'prior',
  FUTURE: 'future',
  CURRENT: 'current',
  NOTICE: 'notice',
  RESERVED: 'reserved',
};

export const INCENTIVE_STATUS_FILTER = {
  NO_INCENTIVE: 1,
  INCENTIVE_IN_PROGRESS: 2,
  INCENTIVE_STARTS_SOON: 3,
  INCENTIVE_ENDS_SOON: 4,
};

export const INCENTIVE_STATUS_OPTIONS = [
  { id: INCENTIVE_STATUS_FILTER.NO_INCENTIVE, name: 'No incentive' },
  { id: INCENTIVE_STATUS_FILTER.INCENTIVE_IN_PROGRESS, name: 'Incentive in progress' },
  { id: INCENTIVE_STATUS_FILTER.INCENTIVE_STARTS_SOON, name: 'Incentive starts soon' },
  { id: INCENTIVE_STATUS_FILTER.INCENTIVE_ENDS_SOON, name: 'Incentive ends soon' },
];

export const UNIT_AVAILABILITY = {
  VACANT: 1,
  VACANT_SOON: 2,
  OCCUPIED: 3,
  RESERVED: 4,
};

export const DEFAULT_UNIT_VACANCY_DAY_COUNT = 10;

export const UNIT_AVAILABILITY_OPTIONS = [
  { id: UNIT_AVAILABILITY.VACANT, name: 'Vacant' },
  { id: UNIT_AVAILABILITY.VACANT_SOON, name: 'Vacant Soon' },
  { id: UNIT_AVAILABILITY.OCCUPIED, name: 'Occupied' },
  { id: UNIT_AVAILABILITY.RESERVED, name: 'Reserved' },
];

export const SIGNATURE_DOCUMENT_TYPES = {
  TYPE_AGREEMENT: "agreement",
  TYPE_A: "type_a",
  TYPE_P: "type_p",
  TYPE_INCENTIVE: "incentive",
  TYPE_E: "type_e",
};

export const PROPOSAL_SIGNATURE_SUBMISSION_STATUS = {
  SENT: "sent",
  OPENED_BY_RESIDENT: "agreement_opened_by_lead",
  SIGNED_BY_RESIDENT: "agreement_signed_by_lead",
  OPENED_BY_COMMUNITY: "agreement_opened_by_community",
  SIGNED_BY_COMMUNITY: "agreement_signed_by_community",
};

export const PROPOSAL_SIGNATURE_SUBMISSION_STATUS_LABEL = {
  [PROPOSAL_SIGNATURE_SUBMISSION_STATUS.SENT]: "Sent",
  [PROPOSAL_SIGNATURE_SUBMISSION_STATUS.OPENED_BY_RESIDENT]: "Opened by Lead",
  [PROPOSAL_SIGNATURE_SUBMISSION_STATUS.SIGNED_BY_RESIDENT]: "Signed by Lead",
  [PROPOSAL_SIGNATURE_SUBMISSION_STATUS.OPENED_BY_COMMUNITY]: "Opened by Community",
  [PROPOSAL_SIGNATURE_SUBMISSION_STATUS.SIGNED_BY_COMMUNITY]: "Signed by Community",
};