import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { message } from 'antd';
import styled from 'styled-components';

import UserOnboardingContent from './UserOnboardingContent';
import { UserOnboardingContext } from './UserOnboardingContext';
import { getClients } from '../../apis/UserAPI';
import AdminPageHeader from '../../common/Admin/AdminPageHeader';
import { useAppSelector } from '../../store/hook';
import useDataList from '../../utils/hooks/useDataList';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
`;

const UserOnboarding = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { selectedCompany, selectedManagementCompany } = useAppSelector((state) => state.global);

  const { filters, setSearch, setPage } = useDataList();

  const showMessage = (type: 'success' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [filters, selectedManagementCompany],
    queryFn: () =>
      getClients({
        ...filters,
        page_size: filters.pageSize,
        company: selectedCompany?.id,
      }),
    refetchOnWindowFocus: false,
  });

  return (
    <Container>
      {contextHolder}
      <UserOnboardingContext.Provider
        value={{
          clients: data?.results,
          count: data?.count,
          setPage: setPage,
          setSearch: setSearch,
          loading: isLoading,
          showMessage: showMessage,
          refresh: () => refetch(),
        }}
      >
        <AdminPageHeader
          title={'Onboarding'}
          description={'Preview and manage necessary actions to finalize User account setup.'}
        />
        <UserOnboardingContent />
      </UserOnboardingContext.Provider>
    </Container>
  );
};

export default UserOnboarding;
