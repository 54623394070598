import React from 'react';
import { useNavigate } from 'react-router-dom';

import AddCompetitor from './AddCompetitor';

const AddCompetitorInitial = () => {
  const navigate = useNavigate();

  const handleAddCompetitor = (competitor: any, community: any) => {
    navigate(`/view-apartments?id=${community?.Id}`);
  };

  return <AddCompetitor onComplete={handleAddCompetitor} />;
};

export default AddCompetitorInitial;
