import React, { useEffect, useState } from 'react';

import { Alert, Spin } from 'antd';
import styled from 'styled-components';

import DashboardReportFeeChart from './DashboardReportFeeChart';
import { getCommunityFee } from '../../../apis/CommunityAPI';
import { STATUS } from '../../../constants';
import { useDashboardReportsContext } from '../DashboardReportsContext';
import { CommunityLivingDataType } from '../datatypes';

const SpinWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 20px;
`;

export const DashboardReportFee = () => {
  const { communityId, careTypeId, dataStatus, setDataStatus } = useDashboardReportsContext();

  const [data, setData] = useState<CommunityLivingDataType[]>([]);

  useEffect(() => {
    const loadCommunityCareTypes = async () => {
      setDataStatus(STATUS.LOADING);
      try {
        const params = {
          care_type: careTypeId,
        };

        const communityFees = await getCommunityFee(communityId ?? 0, params);
        setData(communityFees);
        setDataStatus(STATUS.LOADED);
      } catch (e) {
        console.error(e);
        setDataStatus(STATUS.FAILURE);
      }
    };
    loadCommunityCareTypes();
  }, [careTypeId]);

  const loading = dataStatus === STATUS.LOADING;
  const hasData = data.length > 0;

  return (
    <>
      {loading && (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      )}
      {!loading && !hasData && (
        <Alert
          message="No data for Community Fees for selected Care Type"
          style={{ marginTop: '20px' }}
          type="info"
          showIcon
        />
      )}
      {!loading && hasData && <DashboardReportFeeChart data={data} />}
    </>
  );
};

export default DashboardReportFee;
