import React, { useContext } from 'react';

import styled from 'styled-components';

import { OverviewContext } from './OverviewContext';
import Map from '../../common/Widgets/Map/Map';

const Container = styled.div`
  position: relative;
  overflow-y: hidden;
  border-radius: 10px;
`;

const OverviewMap = () => {
  const { communities, competitors } = useContext(OverviewContext);
  const getMapOptions = (maps: any) => {
    return {
      zoomControl: false,
      fullscreenControl: false,
    };
  };

  return (
    <Container>
      <Map communities={communities} competitors={competitors} getMapOptions={getMapOptions} />
    </Container>
  );
};

export default OverviewMap;
