import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { format } from 'date-fns';

import DataTable from './DataTable';
import { valueFormatter } from './utils';
import { COMMUNITY_TYPE } from '../../../constants';
import { average } from '../../../utils/helpers/numbers';
import { DataItemType } from '../Graph/types';
import { transformCSVData } from '../GraphWidget/utils';
import { useWidgetContext } from '../Widget/WidgetContext';

interface Props {
  data: any;
}

const DataTableRange = ({ data }: Props) => {
  const { type, setCSVData } = useWidgetContext();
  const [tableData, setTableData] = useState<any>([]);
  const [quarterList, setQuarterList] = useState<string[]>([]);

  useEffect(() => {
    // @ts-ignore
    const enhancedData = data.map((item: DataItemType) => ({
      ...item,
      quarter: format(new Date(item.date ?? ''), 'qqq yyyy'),
    }));

    const dataQuarterList = Array.from(new Set(enhancedData?.map((item: any) => item.quarter))).sort(
      (quarterA: any, quarterB: any) => (new Date(quarterA) > new Date(quarterB) ? 1 : -1)
    );

    setQuarterList(dataQuarterList as string[]);

    const aggregatedValues = {};

    enhancedData.forEach((item: any) => {
      const quarterKey = item.quarter;

      // Initialize counts object for the community if not exists
      if (!(item.community_name in aggregatedValues)) {
        // @ts-ignore
        aggregatedValues[item.community_name] = {};
      }

      // @ts-ignore
      const currentCommunity = aggregatedValues[item.community_name];

      if (!(quarterKey in currentCommunity)) {
        // @ts-ignore
        aggregatedValues[item.community_name][quarterKey] = [item.value];
      } else {
        // @ts-ignore
        aggregatedValues[item.community_name][quarterKey].push(item.value);
      }
    });

    const adjustedData = Object.entries(aggregatedValues).map(([communityName, quarterValues]) => ({
      communityName,
      communityType: data.find((item: DataItemType) => item.community_name === communityName)?.community_type,
      ...(quarterValues as object),
    }));

    setCSVData(transformCSVData(dataQuarterList as string[], adjustedData));
    setTableData(adjustedData);
  }, [data]);

  const quarterColumns = quarterList.map((quarter, index) => ({
    title: index === 0 ? 'NOW' : quarter,
    dataIndex: quarter,
    align: 'center' as const,
    render: (value: any, record: any) => {
      if (!value) {
        return <span>-</span>;
      }

      return <span>{valueFormatter(average(value), type)}</span>;
    },
  }));

  const columns = [
    {
      title: 'COMMUNITY',
      dataIndex: 'communityName',
      width: '250px',
      align: 'left' as const,
      render: (value: any, record: any) => (
        <span className={classNames({ highlighted: record.communityType === COMMUNITY_TYPE.COMMUNITY })}>{value}</span>
      ),
    },
    ...quarterColumns,
  ];

  return (
    <DataTable
      columns={columns}
      dataSource={tableData}
      scroll={{ x: 'max-content' }}
      pagination={false}
      rowKey={(record: any) => record?.communityName}
    />
  );
};

export default DataTableRange;
