import React from 'react';

import styled from 'styled-components';

import IncentivesTableUnitsActionsDropdown from './IncentivesTableUnitsActionsDropdown';
import IncentivesTableUnitsActionsSendProposal from './IncentivesTableUnitsActionsSendProposal';
import { isIncentivesCorporate, isIncentivesSalesperson } from '../../../../helpers/permissions';
import { useAppSelector } from '../../../../store/hook';
import { UnitType } from '../../types';

interface Props {
  record: UnitType;
}

const Wrapper = styled.div`
  visibility: hidden;
`;

const IncentivesTableUnitsActions = ({ record }: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const showDropdown = isIncentivesCorporate(currentUser);
  const showButton = !showDropdown && isIncentivesSalesperson(currentUser);

  return (
    <Wrapper className={'hover-visible'}>
      {showButton && <IncentivesTableUnitsActionsSendProposal unit={record} />}
      {showDropdown && <IncentivesTableUnitsActionsDropdown unit={record} />}
    </Wrapper>
  );
};

export default IncentivesTableUnitsActions;
