import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import IncentivesTableProposalsActionsDropdown from './IncentivesTableProposalsActionsDropdown';
import IncentivesTableProposalsRecipient from './IncentivesTableProposalsRecipient';
import IncentivesTableProposalsSent from './IncentivesTableProposalsSent';
import IncentivesTableProposalsStatus from './IncentivesTableProposalsStatus';
import { getProposals } from '../../../../apis/IncentiveAPI';
import { useAppSelector } from '../../../../store/hook';
import { INCENTIVES_PAGE_SIZE, TABLE_ACTIONS } from '../../constants';
import { getOrdering } from '../../helpers';
import { useIncentivesContext } from '../../IncentivesContext';
import { useIncentivesDataContext } from '../../IncentivesDataContext';
import IncentivesStyledTable from '../IncentivesStyledTable';
import IncentivesTableUnitsCommunity from '../unit/IncentivesTableUnitsCommunity';
import IncentivesTableUnitsIncentive from '../unit/IncentivesTableUnitsIncentive';
import IncentivesTableUnitsUnit from '../unit/IncentivesTableUnitsUnit';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

const IncentivesTableProposals = () => {
  const { managementCompanyId } = useParams();
  const { selectedCompany } = useAppSelector((state) => state.global);

  const containerRef = useRef<HTMLDivElement>(null);

  const { key } = useIncentivesContext();
  const {
    createMode,
    selectionMode,
    archiveMode,
    items,
    totalItemsCount,
    filters,
    ordering,
    setItems,
    setOrdering,
    setPage,
  } = useIncentivesDataContext();

  const { isLoading, isFetching } = useQuery({
    queryKey: [
      'incentives',
      'proposals',
      managementCompanyId,
      selectedCompany,
      filters,
      ordering,
      key,
      createMode,
      archiveMode,
      selectionMode,
    ],
    queryFn: () =>
      getProposals({
        page: filters.page,
        page_size: filters.pageSize,
        search: filters.search,
        ordering,
        companies: [selectedCompany?.id],
        management_companies: managementCompanyId ? [managementCompanyId] : undefined,
        communities: filters.communities,
        room_types: filters.roomTypes,
        care_types: filters.careTypes,
        proposal_status: filters.proposalStatus?.length ? filters.proposalStatus : undefined,
      }),
    onSuccess: (data) => {
      setItems(data);
    },
    enabled: Boolean(selectedCompany) || Boolean(managementCompanyId),
    refetchOnWindowFocus: false,
  });

  const handleChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    const { action } = extra;

    if (action === TABLE_ACTIONS.PAGINATE) {
      setPage(pagination.current);
    } else if (action === TABLE_ACTIONS.SORT) {
      setOrdering(getOrdering(sorter));
    }
  };

  const columns = [
    {
      title: 'Recipient',
      key: 'lead',
      sorter: false,
      render: (value: any, record: any) => <IncentivesTableProposalsRecipient record={record} />
    },
    {
      title: 'Incentive',
      dataIndex: 'incentive',
      hidden: createMode,
      width: '30%',
      key: 'incentive__name',
      sorter: true,
      render: (value: any, record: any) => (
        <IncentivesTableUnitsIncentive incentive={record.incentive} unit={record.unit} hideIcon />
      ),
    },
    {
      title: 'Unit',
      dataIndex: 'room_type',
      key: 'unit__number',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableUnitsUnit record={record.unit} />,
    },
    {
      title: 'Care Type',
      dataIndex: 'care_type',
      key: 'unit__care_type',
      sorter: true,
      render: (value: any, record: any) => <span>{record?.unit?.care_type}</span>,
    },
    {
      title: 'Community',
      key: 'unit__community__comunity_name',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableUnitsCommunity record={record.unit} />,
    },
    {
      title: 'Sent',
      key: 'created_at',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableProposalsSent record={record} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '90px',
      key: 'status',
      sorter: true,
      render: (value: any, record: any) => <IncentivesTableProposalsStatus record={record} />,
    },
    {
      title: '',
      width: 100,
      render: (value: any, record: any) => <IncentivesTableProposalsActionsDropdown record={record} />,
    },
  ];

  return (
    <Container ref={containerRef}>
      <IncentivesStyledTable
        columns={columns}
        dataSource={items}
        loading={isLoading || isFetching}
        rowKey={(record: any) => record.id}
        onChange={handleChange}
        showSorterTooltip={false}
        pagination={
          createMode
            ? false
            : {
                size: 'small',
                position: ['bottomLeft'],
                showSizeChanger: false,
                current: filters.page,
                pageSize: INCENTIVES_PAGE_SIZE,
                total: totalItemsCount,
                showTotal: (total, range) => {
                  const displayedItems = range[1] - range[0] + 1;
                  return (
                    <span>
                      Showing {displayedItems} of {total} records
                    </span>
                  );
                },
              }
        }
      />
    </Container>
  );
};

export default IncentivesTableProposals;
