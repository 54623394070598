import React, { useEffect, useState } from 'react';

import { InputProps } from 'antd/es/input';

import Input from './Input';
import { useDebounce } from '../../utils/hooks/useDebounce';

const InputDebounced = (props: InputProps) => {
  const [event, setEvent] = useState<any>(undefined);
  const debouncedKeyword = useDebounce(event);

  useEffect(() => {
    if (typeof props.onChange == 'function') {
      props.onChange(event);
    }
  }, [debouncedKeyword]);

  const handleChange = (e: any) => {
    setEvent(e);
  };

  return (
    <Input {...props} onChange={handleChange}>
      {props.children}
    </Input>
  );
};

export default InputDebounced;
