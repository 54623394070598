import React, { createContext, useContext } from 'react';

import {
  END_REPEAT_VALUES,
  MYSTERY_SHOP_MODAL_REQUEST_FROM,
  MYSTERY_SHOP_REQUEST_DUE_DATES,
  SHOP_REQUEST_REPEAT_OCCURENCES,
} from '../constants';
import { MysteryShopRequestModalContentType } from '../types';

export const ShopRequestModalContext = createContext<MysteryShopRequestModalContentType>({
  requestFrom: MYSTERY_SHOP_MODAL_REQUEST_FROM.FURTHER,
  remainingCommunities: [],
  dueDate: MYSTERY_SHOP_REQUEST_DUE_DATES.IN_3_DAYS,
  repeat: SHOP_REQUEST_REPEAT_OCCURENCES.NEVER,
  name: null,
  shopRequests: [],
  specificDueDate: new Date(),
  staffUsers: [],
  shopperId: null,
  selectedCompany: null,
  shopForBaseCommunity: false,
  selectedBaseCommunity: null,
  companyCommunities: [],
  companyCompetitors: [],
  communityList: [],
  baseCommunityIL: false,
  baseCommunityAL: false,
  baseCommunityMC: false,
  endRepeat: END_REPEAT_VALUES.AFTER_1_YEAR,
  repeatEnabled: false,
  setEndRepeat: () => {
    // empty
  },
  setRepeatEnabled: () => {
    // empty
  },
  setBaseCommunityIL: () => {
    // empty
  },
  setBaseCommunityAL: () => {
    // empty
  },
  setBaseCommunityMC: () => {
    // empty
  },
  setSelectedBaseCommunity: () => {
    // empty
  },
  setShopForBaseCommunity: () => {
    // empty
  },
  setSelectedCompany: () => {
    // empty
  },
  setShopperId: () => {
    // empty
  },
  setName: () => {
    // empty
  },
  setRequestFrom: () => {
    // empty
  },
  selectCommunity: () => {
    // empty
  },
  setDueDate: () => {
    // empty
  },
  setRepeat: () => {
    // empty
  },
  setShopRequests: () => {
    // empty
  },
  deleteCommunity: () => {
    // empty
  },
  replaceCommunity: () => {
    // empty
  },
  setSpecificDueDate: () => {
    // empty
  },
});

export const useShopRequestModalContext = () => useContext(ShopRequestModalContext);
