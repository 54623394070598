import React, { useState } from 'react';

import { DeleteFilled } from '@ant-design/icons/lib';
import { Button, Popconfirm, Table, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import axios from 'config/axiosPrivate';

import { CareFeeDateType, CommunityFeeDateType } from './constants';
import { useFeesContext } from './FeesContext';
import { mapCareFeeResponseToData } from './utils';
import { GLOBAL_DATE_FORMAT, MEDIA_BREAK_POINTS, STATUS } from '../../../constants';
import { useAppSelector } from '../../../store/hook';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeesFormHistoryCareFees = () => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;
  const missingDeletionPermission =
    permissions?.indexOf('PAScreens.delete_feescare') == -1 &&
    permissions?.indexOf('PAScreens.delete_screens_fees_care') == -1;

  const { communityId, showMessage } = useFeesContext();

  const [careHistoryData, setCareHistoryData] = React.useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState(STATUS.IDLE);

  React.useEffect(() => {
    getCareFees();
  }, [communityId]);

  const getCareFees = () => {
    setDataLoading(STATUS.LOADING);
    axios
      .get(`/communities/${communityId}/care-fees?active=0`)
      .then((response) => {
        const data = response.data;
        const formatted_data: CareFeeDateType[] = data?.map((item: any) => mapCareFeeResponseToData(item));
        setCareHistoryData(formatted_data);
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to fetch historical Care Fees');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const deleteCommunityCareFee = (item: Partial<CareFeeDateType>) => {
    setDataLoading(STATUS.LOADING);
    axios
      .delete(`/communities/${communityId}/care-fees/${item.id}`)
      .then((response) => {
        const fee_index = careHistoryData.findIndex((care_fee) => care_fee.id === item.id);
        careHistoryData.splice(fee_index, 1);
        setCareHistoryData([...careHistoryData]);
        showMessage('success', 'Care Fee deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Care Fee');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const getTooltipContent = (record: any) => {
    return (
      <TooltipContent>
        <span>Created By: {record?.createdBy}</span>
        <span>Created Date: {dayjs(record?.createdAt).format(GLOBAL_DATE_FORMAT)}</span>
        <span>Updated By: {record?.updatedBy}</span>
        <span>Updated Date: {dayjs(record?.updatedAt).format(GLOBAL_DATE_FORMAT)}</span>
      </TooltipContent>
    );
  };

  const careColumns = [
    {
      title: 'Living Type',
      dataIndex: 'livingType',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Fee Type',
      dataIndex: 'feesType',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Fee Note',
      dataIndex: 'feeNote',
      render: (value: string, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Medication Management Fee',
      dataIndex: 'medicationManagementFee',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Average Care Cost',
      dataIndex: 'averageCareCost',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 1',
      dataIndex: 'level1',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 2',
      dataIndex: 'level2',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 3',
      dataIndex: 'level3',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 4',
      dataIndex: 'level4',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 5',
      dataIndex: 'level5',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 6',
      dataIndex: 'level6',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 7',
      dataIndex: 'level7',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Level 8',
      dataIndex: 'level8',
      render: (value: number, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>${value}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      render: (value: Dayjs, record: CommunityFeeDateType) => {
        return (
          <Tooltip title={() => getTooltipContent(record)} placement="topLeft" color="blue">
            <span>{value && value.format(GLOBAL_DATE_FORMAT)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      hidden: missingDeletionPermission,
      render: (_: any, record: Partial<CommunityFeeDateType>) => {
        return (
          <ButtonContainer>
            <Popconfirm
              title="Are you sure you want to delete this record?"
              placement="left"
              onConfirm={() => deleteCommunityCareFee(record)}
            >
              <Button
                size="small"
                type="link"
                danger
                icon={<DeleteFilled />}
                disabled={dataLoading === STATUS.LOADING}
              />
            </Popconfirm>
          </ButtonContainer>
        );
      },
    },
  ];

  return (
    <div>
      <Typography.Title level={3}>Care Fees</Typography.Title>
      <Table
        bordered
        loading={dataLoading === STATUS.LOADING}
        scroll={{ x: MEDIA_BREAK_POINTS.MOBILE }}
        columns={careColumns}
        dataSource={careHistoryData}
      />
    </div>
  );
};

export default FeesFormHistoryCareFees;
