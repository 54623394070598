import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

const LabelsContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const MetricsBox = styled.div`
  color: var(--text-secondary);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-top: -2px;
`;

const Counter = styled.div`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: normal;
  color: var(--text-primary);
  padding-top: 4px;
`;

const MarkerLine = styled.div`
  background-color: var(--competitor-purple);
  width: 15px;
  height: 2px;
`;

const Label = styled(Typography.Text)`
  padding-top: 14px;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.5px;
`;

type Props = {
  stats: {
    approved: number;
    created: number;
    in_progress: number;
    needs_approval: number;
  } | null;
};

const ShopRequestsMetricsLabels = ({ stats }: Props) => {
  return (
    <LabelsContainer>
      <MetricsBox>
        <MarkerLine />
        <Label>CREATED</Label>
        <Counter>{stats?.created ?? 0}</Counter>
      </MetricsBox>
      <MetricsBox>
        <MarkerLine />
        <Label>IN PROGRESS</Label>
        <Counter>{stats?.in_progress ?? 0}</Counter>
      </MetricsBox>
      <MetricsBox>
        <MarkerLine />
        <Label>NEEDS APPROVAL</Label>
        <Counter>{stats?.needs_approval ?? 0}</Counter>
      </MetricsBox>
      <MetricsBox>
        <MarkerLine />
        <Label>APPROVED</Label>
        <Counter>{stats?.approved ?? 0}</Counter>
      </MetricsBox>
    </LabelsContainer>
  );
};

export default ShopRequestsMetricsLabels;
