import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';
import styled from 'styled-components';

import Modal from 'components/lib/Modal';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-footer {
      display: flex;
      flex-flow: row-reverse;

      .ant-btn:last-of-type {
        margin-left: 10px;
      }
      .ant-btn:first-of-type {
        margin-left: 10px;
      }

      .ant-btn {
        height: 40px;
        padding: 10px 45px 10px 44px;
        border-radius: 4px;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  communitiesToModify: any[];
  onSave: () => void;
  children: React.ReactNode;
  title: string;
  disabled?: boolean;
};

const SettingsModal = ({ onClose, onSave, isOpen, title, communitiesToModify, children, disabled }: Props) => {
  let subtitle;
  if (communitiesToModify.length === 1) {
    subtitle = (
      <Link to={`/community/${communitiesToModify[0].id}`} target="_blank">
        {communitiesToModify[0].comunity_name}
      </Link>
    );
  } else if (communitiesToModify.length > 1) {
    subtitle = (
      <Typography.Text style={{ color: 'var(--text-secondary)', fontSize: '14px' }}>
        {communitiesToModify.length} communities selected
      </Typography.Text>
    );
  }

  return (
    <StyledModal
      title={
        <Typography.Title level={3} style={{ fontSize: '20px', marginBottom: 0 }}>
          {title}
        </Typography.Title>
      }
      open={isOpen}
      onOk={onSave}
      onCancel={onClose}
      centered
      okText="Save"
      okButtonProps={{ disabled }}
    >
      <Container>
        {subtitle}
        {children}
      </Container>
    </StyledModal>
  );
};

export default SettingsModal;
