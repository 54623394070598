import React from 'react';

import { useAppSelector } from 'store/hook';

import { AccommodationDataType } from './constants';
import EditableTable from '../../../common/Table/EditableTable';
import { INPUT_TYPES } from '../../../constants';
import { toCamelCase } from '../../../helpers/strings';
import { LivingInfoDataType } from '../../../pages/price_healing/Accommodation/constants';

interface Props {
  selectedTab: string;
  data: AccommodationDataType[];
  onCreate: (record: Partial<AccommodationDataType>) => void;
  onUpdate: (record: Partial<AccommodationDataType>) => void;
  onDelete: (record: Partial<AccommodationDataType>) => void;
}

const AccommodationFormOnboardingTable = ({ selectedTab, data, onCreate, onUpdate, onDelete }: Props) => {
  const apartmentTypes = useAppSelector((state) => state.global.apartmenttypes);

  const accommodationOptions = apartmentTypes.map((val) => ({
    label: val.type,
    value: +val.id,
  }));

  const uniqueApartmentTypeValidator = async (record: LivingInfoDataType) => {
    const filteredData = data.filter((entry) => entry.key != record?.key);

    const hasLivingTypeEntry = filteredData.some((entry) => entry.apartmentTypeId === record.apartmentTypeId);
    if (hasLivingTypeEntry) {
      return Promise.reject('This entry matches an existing record with the same Accommodation.');
    }

    return Promise.resolve();
  };

  const columns = [
    {
      title: 'Accommodation',
      dataIndex: 'apartmentTypeId',
      inputType: INPUT_TYPES.SELECT,
      options: accommodationOptions,
      editable: true,
      required: true,
      width: '340px',
      validator: uniqueApartmentTypeValidator,
    },
    {
      title: 'Square Footage',
      dataIndex: 'squareFootage',
      inputType: INPUT_TYPES.NUMBER_POSITIVE,
      editable: true,
      width: '200px',
    },
    {
      title: 'Rate',
      dataIndex: toCamelCase(selectedTab),
      inputType: INPUT_TYPES.CURRENCY,
      editable: true,
      ellipsis: true,
      required: true,
      width: '200px',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      inputType: INPUT_TYPES.TEXTAREA,
      editable: true,
    },
  ];

  return (
    <EditableTable
      tableLayout="fixed"
      loading={false}
      columns={columns}
      data={data}
      onAdd={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  );
};

export default AccommodationFormOnboardingTable;
