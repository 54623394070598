import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';

import { CaretRightFilled } from '@ant-design/icons/lib';
import { Tabs } from 'antd';

import { CommunityInfoContext } from './CommunityInfoContext';
import AccommodationForm from '../../pages/price_healing/Accommodation/AccommodationForm';
import AdditionalLinks from '../../pages/price_healing/AdditionalLinks';
import BasicInfoForm from '../../pages/price_healing/BasicInfoForm';
import FeesForm from '../../pages/price_healing/Fees/FeesForm';
import ImageBrochure from '../../pages/price_healing/ImageBrochure';
import Occupancies from '../../pages/price_healing/Occupancies/Occupancies';
import ShopNotes from '../../pages/price_healing/ShopNotes';
import CommunityDetails from '../../pages/PriceHealing/CommunityDetails';
import { useAppSelector } from '../../store/hook';
import { useRootContext } from '../layout/RootContext';

const CommunityInfoCommunityContent = () => {
  const { communityId } = useParams();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const permissions = currentUser?.all_permissions;

  const { refreshCommunities } = useRootContext();
  const { activeTabKey, setActiveTabKey, communityInfo, setCommunityInfo } = useContext(CommunityInfoContext);

  const protectedTabs = () => {
    if (currentUser) {
      const items: any = [];

      if (permissions?.indexOf('PAScreens.view_basic_info') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Basic Info',
          key: 'Basic_Info',
          children: (
            <BasicInfoForm
              communityId={parseInt(communityId ?? '')}
              initialData={communityInfo}
              setData={setCommunityInfo}
              getCommunityInfo={() => {
                refreshCommunities();
              }}
            />
          ),
          disabled: communityInfo === null,
        });
      }

      if (permissions?.indexOf('PAScreens.view_screens_community_details') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Community Details',
          key: 'Community details',
          children: <CommunityDetails community_id={communityId} />,
          disabled: communityInfo === null,
        });
      }

      if (permissions?.indexOf('PAScreens.view_screens_accommodation') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Accommodation',
          key: 'Accommodation',
          children: <AccommodationForm community_id={communityId} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }

      if (permissions?.indexOf('PAScreens.view_screens_fees_care') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Fees & Care',
          key: 'Fees',
          children: <FeesForm community_id={communityId} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }

      if (permissions?.indexOf('PAScreens.view_imagesbrochure') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Images/Brochures',
          key: 'brochure',
          children: <ImageBrochure communityId={parseInt(communityId || '')} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }

      if (permissions?.indexOf('PAScreens.view_additionallinks') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Additional Links',
          key: 'links',
          children: <AdditionalLinks community_id={parseInt(communityId ?? '')} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }

      if (permissions?.indexOf('PAScreens.view_occupancies') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Occupancies',
          key: 'occupancies',
          children: <Occupancies communityId={communityId} />,
          style: {
            padding: 0,
          },

          disabled: communityInfo === null,
        });
      }

      if (permissions?.indexOf('PAScreens.view_screenshopnotes') != -1 || currentUser.groups.length == 0) {
        items.push({
          label: 'Shop Notes',
          key: 'shop_notes',
          children: <ShopNotes community_id={parseInt(communityId ?? '')} />,
          style: {
            padding: 0,
          },
          disabled: communityInfo === null,
        });
      }

      return items;
    }
    return [];
  };

  const availableTabs = protectedTabs();

  return (
    <Tabs
      activeKey={activeTabKey}
      moreIcon={<CaretRightFilled />}
      tabPosition="top"
      tabBarGutter={30}
      size="small"
      destroyInactiveTabPane
      onChange={(key: any) => setActiveTabKey(key)}
      items={availableTabs}
    />
  );
};

export default CommunityInfoCommunityContent;
