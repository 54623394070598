import React from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';

import TotalSavingsPercentage from './TotalSavingsPercentage';
import { getUnitFeePrice } from '../helpers';
import { getProposalPricingSavings } from '../proposal/helpers';
import { IncentiveProposalPricingType } from '../proposal/types';
import { IncentiveSavingType, IncentiveType, UnitType } from '../types';

interface Props {
  incentive: IncentiveType;
  unit: UnitType;
}

const Container = styled.div`
  display: flex;
  font-size: 12px;
`;

const SavingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--positive-green);
`;

const DatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  color: var(--text-secondary);
`;

const TotalSavings = ({ incentive, unit }: Props) => {
  const hasStartCountdown = incentive?.start_at && dayjs().isBefore(dayjs(incentive?.start_at), 'day');
  const hasEndCountdown =
    !hasStartCountdown && incentive?.end_at && dayjs(incentive?.end_at).isSameOrAfter(dayjs(), 'day');

  const savingsRates: IncentiveProposalPricingType[] = incentive.savings.map((item: IncentiveSavingType) => {
    return Object.assign({}, item, { amount: getUnitFeePrice(unit, item.type) as number });
  });

  return (
    <Container>
      <SavingsContainer>
        <span>${getProposalPricingSavings(savingsRates)} total savings</span>
        <TotalSavingsPercentage incentive={incentive} unit={unit} />
      </SavingsContainer>
      <DatesContainer>
        <span>
          {(hasStartCountdown || hasEndCountdown) && <span style={{ margin: '0px 3px' }}>·</span>}
          {hasStartCountdown && <span>Starts {dayjs(incentive?.start_at).format('MMM D')}</span>}
          {hasEndCountdown && <span>Expires {dayjs(incentive?.end_at).format('MMM D')}</span>}
        </span>
      </DatesContainer>
    </Container>
  );
};
export default TotalSavings;
