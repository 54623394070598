import React from 'react';

import { Table } from 'antd';
import styled from 'styled-components';

const DataTable = styled(Table)`
  &&&&&& {
    tr {
      background-color: #fff;
      font-size: 12px;
      color: var(--text-primary);
    }

    td {
      padding: 0;
      height: 56px;

      .highlighted {
        font-family: var(--font-bold);
      }
    }

    th {
      font-family: var(--font-bold);
      color: var(--text-tertiary);
      background-color: #fff;
      font-size: 10px;
      padding: 10px;

      &.ant-table-cell::before {
        background-color: transparent;
      }
    }
  }
`;

export default DataTable;
