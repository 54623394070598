import React, { useRef, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons/lib';
import { Button, message, Popconfirm } from 'antd';
import styled from 'styled-components';

import { exportElementToPDF } from './helpers';
import { STATUS } from '../../constants';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  justify-content: end;
  margin: 10px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  filename: string;
  children: any;
}

const ExportableContent = ({ filename, children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const [status, setStatus] = useState(STATUS.IDLE);

  const handleExportToPDF = async () => {
    try {
      setStatus(STATUS.LOADING);
      await exportElementToPDF(filename, ref.current);
      setStatus(STATUS.LOADED);
    } catch (e) {
      console.error(e);
      messageApi.error('Trouble exporting community reports. Please contact help for assistance.');
      setStatus(STATUS.FAILURE);
    }
  };

  return (
    <Container>
      {contextHolder}
      <Actions>
        <Popconfirm
          placement="topRight"
          title="Export All Reports"
          description="Are you sure you want to download PDF file with reports?"
          okText="Yes"
          cancelText="Cancel"
          onConfirm={handleExportToPDF}
        >
          <Button
            type="primary"
            size={'small'}
            icon={<DownloadOutlined />}
            loading={status === STATUS.LOADING}
            disabled={status === STATUS.LOADING}
          >
            Export All Reports
          </Button>
        </Popconfirm>
      </Actions>
      <Content ref={ref}>{children}</Content>
    </Container>
  );
};

export default ExportableContent;
