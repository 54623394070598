import React from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

const { Content } = Layout;

const SettingsPage = () => {
  return (
    <Layout style={{ marginLeft: '0px', background: '#eee' }}>
      <Content style={{ margin: '0px' }}>
        <div style={{ padding: 24, background: '#fff' }}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};

export default SettingsPage;
