import React from 'react';

import { Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';
import { isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';
import { SHOP_REQUEST_CHECKLIST } from '../constants';
import ProgressBar from '../ProgressBar';
import { ShopRequestChecklistFields } from '../types';

const Container = styled.div`
  padding: 20px 10px 10px 30px;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  max-width: 370px;
`;

const Title = styled(Typography.Text)`
  font-weight: 900;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
  .ant-checkbox-inner {
    height: 18px;
    width: 18px;
  }
`;

const CheckboxLabel = styled.span`
  font-size: 14px;
  color: var(--text-primary);
`;

const PercentageBarContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const EditShopRequestModalChecklist = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestSalesperson = currentUser && isMysteryShopShopper(currentUser);
  const { shopRequest, setShopRequest } = useEditShopRequestModalContext();

  const currentUserNotShopper = isShopRequestSalesperson && currentUser?.PAUser_Id !== shopRequest?.shopper?.userId;
  const checkedItems = SHOP_REQUEST_CHECKLIST.filter(
    (item) => shopRequest![item.id as keyof ShopRequestChecklistFields]
  );
  const checkedPercentage = Math.round((checkedItems.length / SHOP_REQUEST_CHECKLIST.length) * 100);

  return (
    <Container>
      <Row>
        <Title>Checklist</Title>
        <PercentageBarContainer>
          {`${checkedPercentage}%`}
          <ProgressBar percentage={checkedPercentage} />
        </PercentageBarContainer>
      </Row>
      <Column>
        {SHOP_REQUEST_CHECKLIST.map((item) => (
          <StyledCheckbox
            key={item.id}
            className={'green'}
            checked={shopRequest![item.id as keyof ShopRequestChecklistFields]}
            disabled={!!currentUserNotShopper}
            onChange={(event: CheckboxChangeEvent) => {
              if (shopRequest) {
                setShopRequest({ ...shopRequest, [item.id]: event.target.checked });
              }
            }}
          >
            <CheckboxLabel>{item.label}</CheckboxLabel>
          </StyledCheckbox>
        ))}
      </Column>
    </Container>
  );
};

export default EditShopRequestModalChecklist;
