import React from 'react';

import { Col, Row } from 'antd';

import ScatterChart from '../Graph/ScatterChart';

type Props = {
  data: any;
};

const SwotPerformanceScatterGraph = ({ data }: Props) => {
  return (
    <>
      <div
        style={{
          marginLeft: '40px',
          marginRight: '20px',
          marginBottom: '-10px',
          marginTop: '10px',
          color: '#aaa',
        }}
      >
        <Row>
          <Col span={4}></Col>
          <Col span={3} style={{ textAlign: 'center' }}>
            WORSE
          </Col>
          <Col span={4}></Col>
          <Col span={3} style={{ textAlign: 'center' }}>
            SAME
          </Col>
          <Col span={4}></Col>
          <Col span={4} style={{ textAlign: 'center' }}>
            BETTER
          </Col>
        </Row>
      </div>
      <ScatterChart data={data} />
    </>
  );
};

export default SwotPerformanceScatterGraph;
