import React, { createContext, useContext } from 'react';

type SelectorContent = {
  refreshRender: () => void;
  showMessage: (type: 'success' | 'info' | 'error', message: string) => void;
};

export const CommunityReviewsContext = createContext<SelectorContent>({
  refreshRender: () => {
    // Does nothing
  },
  showMessage: () => {
    // Does nothing
  },
});

export const useCommunityReviewsContext = () => useContext(CommunityReviewsContext);
