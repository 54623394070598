export const groupByApartmentId = (data: any[], key: any) => {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val[key];
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
};
