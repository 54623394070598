import React, { useState } from 'react';

import styled from 'styled-components';

import LivingInfoData from './LivingInfoData';
import LivingInfoHistory from './LivingInfoHistory';
import FormModeSwitch from '../../../common/FormNavigation/FormModeSwitch';

interface Props {
  livingType: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  min-width: 60%;
  width: 100%;
`;

const LivingInfoTab = ({ livingType }: Props) => (
  <Container>
    <Content>
      <FormModeSwitch
        editChild={<LivingInfoData livingType={livingType} />}
        historyChild={<LivingInfoHistory livingType={livingType} />}
      />
    </Content>
  </Container>
);

export default LivingInfoTab;
