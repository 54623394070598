import React, { useState } from 'react';

import { Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { uuid } from 'short-uuid';
import styled from 'styled-components';

import { DEFAULT_SAVINGS_ENTRY } from './constants';
import { IncentivesCreateModalContext } from './IncentivesCreateModalContext';
import IncentivesCreateOffers from './IncentivesCreateOffers';
import IncentivesCreateUnitsList from './IncentivesCreateUnitsList';
import Button from '../../lib/Button';
import { INCENTIVES_CREATE_MODAL_WIDTH } from '../constants';
import { IncentiveSavingType, UnitType } from '../types';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0px;
    padding: 0;
  }

  .ant-modal-footer {
    margin: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 700px;
`;

interface Props {
  onClose: any;
}

const IncentivesCreateModal = ({ onClose }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | undefined>(undefined);
  const [savings, setSavings] = useState<IncentiveSavingType[]>([
    Object.assign({}, DEFAULT_SAVINGS_ENTRY, { id: uuid() }),
  ]);
  const [units, setUnits] = useState<UnitType[]>([]);

  const handleClose = () => {
    setOpen(false);
    setName('');
    setDescription('');
    setStartDate(dayjs());
    setEndDate(undefined);
    setSavings([Object.assign({}, DEFAULT_SAVINGS_ENTRY, { id: uuid() })]);
    setUnits([]);

    onClose();
  };

  return (
    <IncentivesCreateModalContext.Provider
      value={{
        loading,
        name,
        startDate,
        endDate,
        description,
        savings,
        units,
        handleClose,
        setLoading,
        setName,
        setStartDate,
        setEndDate,
        setDescription,
        setSavings,
        setUnits,
      }}
    >
      <StyledModal
        open={open}
        footer={false}
        closable
        onCancel={handleClose}
        destroyOnClose
        width={INCENTIVES_CREATE_MODAL_WIDTH}
      >
        <Container>
          <IncentivesCreateUnitsList />
          <IncentivesCreateOffers />
        </Container>
      </StyledModal>
      <Button type={'primary'} onClick={() => setOpen(true)}>
        Create Incentive
      </Button>
    </IncentivesCreateModalContext.Provider>
  );
};

export default IncentivesCreateModal;
