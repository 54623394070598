import React, { useState } from 'react';

import styled from 'styled-components';

import SelectorContent from './SelectorContent';
import { SelectorContext } from './SelectorContext';
import SelectorFooter from './SelectorFooter';
import SelectorHeader from './SelectorHeader';
import { ClientDataType, CompanyDataType, ManagementCompanyDataType } from './types';

const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  gap: 10px;
`;

const Selector = () => {
  const [client, setClient] = useState<ClientDataType | undefined>(undefined);
  const [company, setCompany] = useState<CompanyDataType | undefined>(undefined);
  const [managementCompany, setManagementCompany] = useState<ManagementCompanyDataType | undefined>(undefined);

  return (
    <Container>
      <Content>
        <SelectorContext.Provider
          value={{
            client: client,
            company: company,
            managementCompany: managementCompany,
            setClient: setClient,
            setCompany: setCompany,
            setManagementCompany: setManagementCompany,
          }}
        >
          <SelectorHeader />
          <SelectorContent />
          <SelectorFooter />
        </SelectorContext.Provider>
      </Content>
    </Container>
  );
};

export default Selector;
