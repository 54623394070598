import React, { createContext, useContext } from 'react';

import { OverviewStatsDataType } from './types';

type OverviewStatsContent = {
  overviewStats?: OverviewStatsDataType;
};

export const OverviewStatsContext = createContext<OverviewStatsContent>({
  overviewStats: {
    averageRate: {
      community: undefined,
      market: undefined,
    },
    averageLivingTypeRates: [],
    averagePricePerSquareFoot: {
      community: undefined,
      market: undefined,
    },
    averageRating: {
      community: undefined,
      market: undefined,
    },
  },
});

export const useOverviewStatsContext = () => useContext(OverviewStatsContext);
