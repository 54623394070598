import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Tabs, TabsProps } from 'antd';
import { motion } from 'framer-motion';
import GoogleMapReact, { fitBounds } from 'google-map-react';

import BlackBar from 'components/BlackBar';
import Marker from 'components/Marker';
import axios from 'config/axiosPrivate';
import { getDecimals } from 'helpers';

import CommunityTable from './CommunityTable';
import classes from './styles.module.scss';
import { useAppSelector } from '../../../store/hook';

interface Props {
  communityList: any[];
  commpetitorList: any[];
  filterCommunity: any[];
  filterCompany: any[];
  filterManagementCompany: any[];
  filterCare: any[];
  loading: boolean;
}

function Competitortab({
  communityList,
  commpetitorList,
  loading,
  filterCommunity,
  filterCompany,
  filterManagementCompany,
  filterCare,
}: Props) {
  const navigate = useNavigate();
  const { selectedClient } = useAppSelector((state) => state.global);

  const [communityData, setCommunityData] = React.useState<any>(null);
  const [competitorData, setCompetitorData] = React.useState<any>(null);

  const [whiteCommunity, setWhiteCommunity] = React.useState<any[]>([]);
  const [whiteCompetitor, setWhiteCompetitor] = React.useState<any[]>([]);
  const [mapCenter, setMapCenter] = React.useState<any>({
    lat: 44.5,
    lng: -89.5,
  });
  const [zoom, setZoom] = React.useState<any>(10);
  const size = {
    width: 500,
    height: 550,
  };

  useEffect(() => {
    const d = [...communityList, ...commpetitorList];
    if (d.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      d.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.community.latitude, marker.community.longitude));
      });
      const newBounds = {
        ne: {
          lat: bounds.getNorthEast().lat(),
          lng: bounds.getNorthEast().lng(),
        },
        sw: {
          lat: bounds.getSouthWest().lat(),
          lng: bounds.getSouthWest().lng(),
        },
      };
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        setMapCenter(newBounds.ne);
        setZoom(10);
      } else {
        const { center, zoom } = fitBounds(newBounds, size);
        setMapCenter(center);
        setZoom(zoom);
      }
    }
  }, [communityList, commpetitorList]);

  useEffect(() => {
    axios
      .post('/communities/Get_UserCommunitiesAverages/', {
        client_id: selectedClient?.id,
        comm_list: filterCommunity.map((item) => item.id),
        management_company_list: filterManagementCompany.map((item) => item.id),
        caretype_list: filterCare,
        company_list: filterCompany.map((item) => item.id),
      })
      .then((res) => {
        const data = res.data?.payload;
        if (data) {
          const avg_community: any[] = data?.Community_Summary[0]?.Avg_Values;
          const avg_competitor: any[] = data?.Community_Summary[0]?.Avg_Values_mrkt;
          const google_values: any[] = data?.Community_Summary[0]?.Google_Values;
          const cmmunity_rating: any = google_values[0].filter((el: any) => el?.apartmenttype === 'apartment');
          const cmmunity_reviews: any = google_values[1]?.filter((el: any) => el?.apartmenttype === 'apartment');
          const competitor_rating: any = google_values[0]?.filter((el: any) => el?.apartmenttype === 'competitor');
          const competitor_reviews: any = google_values[1]?.filter((el: any) => el?.apartmenttype === 'competitor');
          const black_community = avg_community?.filter((el) => el?.apartmenttype === 'apartment');
          const black_competitor = avg_competitor?.filter((el) => el?.Type === 'competitor');
          const apObj = {
            ...black_community[0],
            g_rating: cmmunity_rating ? cmmunity_rating[0]?.Avg_google_rating : 0,
            g_reviews: cmmunity_reviews ? cmmunity_reviews[0]?.Avg_google_reviews : 0,
          };
          const cmpObj = {
            ...black_competitor[0],
            g_rating: competitor_rating ? competitor_rating[0]?.Avg_google_rating : 0,
            g_reviews: competitor_reviews ? competitor_reviews[0]?.Avg_google_reviews : 0,
          };
          setCommunityData(apObj);
          setCompetitorData(cmpObj);

          setWhiteCommunity(data?.Community_Detail[0]?.Avg_Values);
          setWhiteCompetitor(data?.Community_Detail[0]?.Avg_Values_mrkt);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [filterCompany, filterCommunity, filterCare]);

  const dropdownitems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Add My Community',
      onClick: () => navigate('/add-community'),
    },
    {
      key: '2',
      label: 'Add a Competitor',
      onClick: () => navigate('/add-competitor'),
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `My Communities (${communityList.length})`,
      children: <CommunityTable data={communityList} loading={loading} title="MY COMMUNITY" />,
    },
    {
      key: '2',
      label: `Competitors (${commpetitorList.length})`,
      children: <CommunityTable data={commpetitorList} loading={loading} title="COMPETITOR" />,
    },
  ];

  const [showBars, setShowbars] = React.useState(true);
  const getWhiteIndex = (value: any) => {
    const found = whiteCommunity.filter((item) => item.LivingType == value);
    if (found.length > 0) {
      return found[0].Avg_base_rent;
    }
    return 0;
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '550px',
          position: 'relative',
          overflowY: 'hidden',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAkEDUPfo1e_5N59IClF74jjicyKbTMYw4',
          }}
          center={mapCenter}
          zoom={zoom}
          key={mapCenter.lat}
          style={{
            height: 550,
          }}
        >
          {[...commpetitorList, ...communityList].length > 0 &&
            [...commpetitorList, ...communityList]?.map((data: any, idx: number) => (
              <Marker
                key={idx}
                lat={data?.community?.latitude}
                lng={data?.community?.longitude}
                data={data}
                communityList={communityList}
              />
            ))}
        </GoogleMapReact>

        <motion.div
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            display: 'inline-block',
            marginLeft: -500,
          }}
          animate={{
            y: showBars ? 0 : 230,
          }}
        >
          <motion.div style={{ position: 'relative' }}>
            <span onClick={() => setShowbars(!showBars)} className={classes.down_trigger}>
              {showBars ? (
                <svg width="12" height="7" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.575 0 4.858 3.709 1.142 0 0 1.142 4.858 6l4.859-4.858z"
                    fill="#89919D"
                    fillRule="nonzero"
                  />
                </svg>
              ) : (
                <svg width="12" height="7" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.59 7 6 2.673 1.41 7 0 5.668 6 0l6 5.668z" fill="#FFF" fillRule="nonzero" />
                </svg>
              )}
            </span>
            <BlackBar communityData={communityData} competitorData={competitorData} />

            <div className={classes.white_bar}>
              {whiteCommunity.length > 3 || whiteCompetitor.length > 3 ? (
                <LeftOutlined
                  style={{
                    marginRight: '30px',
                    fontSize: 24,
                    color: '#275AFF',
                    fontWeight: 800,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    const container = document.getElementById('guage-container');
                    if (container) {
                      container.scrollLeft -= 200;
                    }
                  }}
                />
              ) : null}
              <div
                id="guage-container"
                style={{
                  width: '900px',
                  display: 'flex',
                  justifyContent: whiteCommunity.length > 3 || whiteCompetitor.length > 3 ? '' : 'center',
                  overflow: 'auto',
                }}
                className="hide-scrollbar"
              >
                {whiteCommunity.length > whiteCompetitor.length
                  ? whiteCommunity?.map((data, idx) => (
                      <div key={idx} style={{}}>
                        <span className={classes.white_title}>
                          {data?.LivingType}
                          <span>AVG.</span>
                        </span>
                        <div style={{ position: 'relative' }}>
                          <span className={classes.doller_sign} style={{ color: '#222' }}>
                            $
                          </span>
                          <span className={classes.amount_text} style={{ color: '#222' }}>
                            {data?.Avg_base_rent
                              ? parseInt(`${getDecimals(data?.Avg_base_rent).int}`).toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                            <span style={{ color: '#222' }}>.{getDecimals(data?.Avg_base_rent).point}</span>
                          </span>
                        </div>
                        <span className={classes.market_avg} style={{ color: '#222' }}>
                          Market avg.
                          <span
                            style={{
                              color:
                                whiteCompetitor[idx]?.Avg_base_rent < whiteCommunity[idx]?.Avg_base_rent
                                  ? '#fa7550'
                                  : '#0ab776',
                            }}
                          >
                            $
                            {whiteCompetitor[idx]?.Avg_base_rent
                              ? parseFloat(whiteCompetitor[idx]?.Avg_base_rent).toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })
                              : 0.0}
                          </span>
                        </span>
                      </div>
                    ))
                  : whiteCompetitor?.map((data, idx) => (
                      <div key={idx} style={{ marginRight: '15px' }}>
                        <span className={classes.white_title}>
                          {whiteCompetitor[idx]?.LivingType}
                          <span>AVG.</span>
                        </span>
                        <div style={{ position: 'relative' }}>
                          <span className={classes.doller_sign} style={{ color: '#222' }}>
                            $
                          </span>
                          <span className={classes.amount_text} style={{ color: '#222' }}>
                            {getWhiteIndex(whiteCompetitor[idx]?.LivingType)
                              ? parseInt(
                                  `${getDecimals(getWhiteIndex(whiteCompetitor[idx]?.LivingType)).int}`
                                ).toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                            <span style={{ color: '#222' }}>
                              .{getDecimals(getWhiteIndex(whiteCompetitor[idx]?.LivingType)).point}
                            </span>
                          </span>
                        </div>
                        <span className={classes.market_avg} style={{ color: '#222' }}>
                          Market avg.
                          <span
                            style={{
                              color:
                                whiteCompetitor[idx]?.Avg_base_rent < whiteCommunity[idx]?.Avg_base_rent
                                  ? '#fa7550'
                                  : '#0ab776',
                            }}
                          >
                            $
                            {whiteCompetitor[idx]?.Avg_base_rent
                              ? parseFloat(whiteCompetitor[idx]?.Avg_base_rent).toLocaleString('en-US', {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })
                              : 0.0}
                          </span>
                        </span>
                      </div>
                    ))}
              </div>
              {whiteCommunity.length > 3 || whiteCompetitor.length > 3 ? (
                <RightOutlined
                  style={{
                    marginLeft: '30px',
                    fontSize: 24,
                    color: '#275AFF',
                    fontWeight: 800,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    const container = document.getElementById('guage-container');
                    if (container) {
                      container.scrollLeft += 200;
                    }
                  }}
                />
              ) : null}
            </div>
          </motion.div>
        </motion.div>
      </div>
      <Tabs
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ padding: '0px 30px' }}
        className={classes.tab_style}
        tabBarExtraContent={
          <Dropdown menu={{ items: dropdownitems }} placement="bottomRight" arrow trigger={['click']}>
            <Button type="primary">Add</Button>
          </Dropdown>
        }
      />
    </>
  );
}

export default Competitortab;
