import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ClearOutlined,
  LogoutOutlined,
  SettingFilled,
  UnlockOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons/lib';
import { Avatar, Dropdown, MenuProps } from 'antd';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from 'store/hook';

import axios from '../../../config/axiosPrivate';
import ChangeAvatarModal from '../../../modals/ChangeAvatar';
import ChangePasswordModal from '../../../modals/ChangePassword';
import { handleLogout } from '../../../store/slices/authSlice';
import { handleReset } from '../../../store/slices/globalSlice';

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  gap: 15px;
`;

const UserNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
`;

const HeaderUserActions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentUser, featureFlags } = useAppSelector((state) => state.auth);

  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const onLogoutUser = async () => {
    await axios.get(`api/jwt/auth/logout/`).then(() => {
      dispatch(handleLogout());
      dispatch(handleReset());
      navigate('/sign-in');
    });
  };

  // TODO: Remove settings completely from the New Version header after SWOT updates are enabled through New Version widgets
  const showSettings =
    currentUser?.isSuperuser ||
    currentUser?.all_permissions?.indexOf('PASettings.view_importancerating') != -1 ||
    currentUser?.all_permissions?.indexOf('PAScreens.can_view_swot_analysis') != -1;
  const settings = {
    label: 'Settings',
    key: '1',
    icon: <SettingFilled />,
    style: {
      minWidth: 200,
    },
    onClick: () => {
      // Do nothing
    },
    children: [
      currentUser?.all_permissions?.indexOf('PASettings.view_importancerating') != -1 || currentUser?.isSuperuser
        ? {
            label: 'SWOT Settings',
            key: 'swot_settings',
            style: {
              minWidth: 200,
            },
            onClick: () => navigate(`/settings/swot-settings`),
          }
        : null,
      currentUser?.all_permissions?.indexOf('PAScreens.can_view_swot_analysis') != -1 || currentUser?.isSuperuser
        ? {
            label: 'SWOT Survey',
            key: 'swot_survey',
            style: {
              minWidth: 200,
            },
            onClick: () => navigate(`/swot-survey`),
          }
        : null,
      currentUser?.all_permissions?.indexOf('PAScreens.can_view_swot_analysis') != -1 || currentUser?.isSuperuser
        ? {
            label: 'SWOT Survey List',
            key: 'swot_survey_list',
            style: {
              minWidth: 200,
            },
            onClick: () => navigate(`/swot-survey-list`),
          }
        : null,
    ],
  };

  const items: MenuProps['items'] = [
    showSettings ? settings : null,
    {
      label: 'Change Avatar',
      key: 'Change Avatar',
      icon: <UploadOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: () => setOpenAvatarModal(true),
    },
    {
      label: 'Change Password',
      key: 'Change Password',
      icon: <UnlockOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: () => setOpenPasswordModal(true),
    },
    currentUser?.isSuperuser
      ? {
          label: 'Clear Selection',
          key: 'clear_selection',
          icon: <ClearOutlined />,
          style: {
            minWidth: 200,
          },
          onClick: () => {
            dispatch(handleReset());
          },
        }
      : null,
    {
      label: 'Logout',
      key: '0',
      icon: <LogoutOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: onLogoutUser,
    },
  ];

  return (
    <div>
      <ChangePasswordModal
        open={openPasswordModal}
        handleClose={() => setOpenPasswordModal(false)}
        title="Change Password"
      />
      <ChangeAvatarModal open={openAvatarModal} handleClose={() => setOpenAvatarModal(false)} title="Change Avatar" />
      <Dropdown menu={{ items }} placement="bottomRight" arrow trigger={['click']}>
        <UserContainer>
          <UserNameContainer>
            <span>{currentUser?.fname || currentUser?.FullName || currentUser?.email}</span>
          </UserNameContainer>
          {currentUser?.avatar ? (
            <Avatar size={30} src={currentUser?.avatar} />
          ) : (
            <Avatar size={30} icon={<UserOutlined />} />
          )}
        </UserContainer>
      </Dropdown>
    </div>
  );
};

export default HeaderUserActions;
