import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { addRatingsToCommunities } from 'utils/data/reviews';

import CompetitorHeaderBreadcrumbs from './CompetitorsHeaderBreadcrumbs';
import { getReviewsMetrics } from '../../apis/ReviewsAPI';
import Header from '../../common/Header';
import axios from '../../config/axiosPrivate';
import { useAppSelector } from '../../store/hook';
import { processFullHomeCommunities } from '../../utils/data/communities';
import OverviewCompetitors from '../Overview/OverviewCompetitors';
import { OverviewContext } from '../Overview/OverviewContext';
import OverviewMap from '../Overview/OverviewMap';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  height: 100%;
  background-color: var(--background-gray);
  gap: 20px;
`;

const Competitors = () => {
  const { communityId } = useParams();

  const { selectedClient, selectedCompany, selectedManagementCompany, selectedCommunity } = useAppSelector(
    (state) => state.global
  );

  const [communities, setCommunities] = useState<any>([]);
  const [competitors, setCompetitors] = useState<any>([]);

  // Perform fetching for current user selection (management company, community)
  const { isFetched: communitiesLoaded } = useQuery({
    queryKey: [communityId, selectedClient, selectedCompany, selectedManagementCompany],
    queryFn: () =>
      axios.post('/communities/Get_FullHomeCommunities/', {
        client_id: selectedClient?.id,
        company_list: selectedCompany ? [selectedCompany?.id] : [],
        management_company_list: selectedManagementCompany ? [selectedManagementCompany.id] : [],
        comm_list: communityId ? [parseInt(communityId)] : [],
        caretype_list: [],
      }),
    onSuccess: (response) => {
      const data = response.data?.payload;

      const { updatedCommunities, updatedCompetitor } = processFullHomeCommunities(data);

      setCommunities(updatedCommunities);
      setCompetitors(updatedCompetitor);
    },
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: [':communityMetrics', communityId],
    queryFn: () =>
      getReviewsMetrics({
        community_id: communityId,
      }),
    onSuccess: (data) => {
      const preparedCompetitors = addRatingsToCommunities(competitors, data.competitors);
      setCompetitors(preparedCompetitors);
    },
    refetchOnWindowFocus: false,
    enabled: !!competitors.length && communitiesLoaded,
  });

  return (
    <OverviewContext.Provider
      value={{
        communities: communities,
        competitors: competitors,
      }}
    >
      <Container>
        <Header title={selectedCommunity?.name || ''} breadcrumbs={<CompetitorHeaderBreadcrumbs />} />
        <Content>
          <OverviewMap />
          <OverviewCompetitors />
        </Content>
      </Container>
    </OverviewContext.Provider>
  );
};

export default Competitors;
