import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Avatar, Descriptions, List, Typography } from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';

import { useCommunityReviewsContext } from './CommunityReviewsContext';
import { REVIEW_AVATAR_COLORS, REVIEW_SOURCE } from './constants';
import { ReviewDataType } from './types';
import { getCommunityReviews } from '../../../apis/ReviewsAPI';
import { STATUS } from '../../../constants';
import useDataList from '../../../utils/hooks/useDataList';

const Container = styled.div`
  width: 300px;
  align-self: start;
`;

const CommunityReviewsList = () => {
  const { communityId } = useParams();
  const { items, totalItemsCount, filters, setItems, setPage } = useDataList();

  const { showMessage } = useCommunityReviewsContext();

  const [status, setStatus] = useState(STATUS.IDLE);

  const getReviews = async (community: string, { page }: any) => {
    try {
      setStatus(STATUS.LOADING);
      const response = await getCommunityReviews(parseInt(community), {
        page: page,
      });
      setItems(response);
      setStatus(STATUS.LOADED);
    } catch (e) {
      showMessage('error', 'Trouble fetching community reviews.');
      setStatus(STATUS.FAILURE);
    }
  };

  useEffect(() => {
    if (communityId) {
      getReviews(communityId, filters);
    }
  }, [communityId, filters]);

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  return (
    <List
      header={<div>User Reviews</div>}
      pagination={{
        position: 'top',
        align: 'start',
        defaultCurrent: 1,
        hideOnSinglePage: true,
        showSizeChanger: false,
        total: totalItemsCount,
        onChange: handlePageChange,
      }}
      size={'small'}
      bordered
      loading={status === STATUS.LOADING}
      dataSource={items}
      renderItem={(item: ReviewDataType) => {
        const colorsCount = REVIEW_AVATAR_COLORS.length;
        const firstNameLetter = item.name ? item.name[0] : '';
        const avatarColor = REVIEW_AVATAR_COLORS[firstNameLetter.charCodeAt(0) % colorsCount];

        return (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={
                <Avatar style={{ backgroundColor: avatarColor, verticalAlign: 'middle' }} src={item.profile_picture}>
                  {firstNameLetter}
                </Avatar>
              }
              title={<Typography.Text strong>{item.name}</Typography.Text>}
              description={item.text}
            />
            <Container>
              <Descriptions layout="vertical">
                <Descriptions.Item label="Date">{format(new Date(item.date), 'MM/dd/yyyy')}</Descriptions.Item>
                <Descriptions.Item label="Rating">{item.rating}</Descriptions.Item>
                <Descriptions.Item label="Source">
                  <Typography.Link href={item.url}>{REVIEW_SOURCE[item.type]}</Typography.Link>
                </Descriptions.Item>
              </Descriptions>
            </Container>
          </List.Item>
        );
      }}
    />
  );
};

export default CommunityReviewsList;
