import React from 'react';

import { Image, Typography } from 'antd';
import styled from 'styled-components';

import { SWOT_CATEGORIES } from 'common/Widgets/constants';
import Dropdown from 'components/lib/Dropdown';

import { useEditSwotDataModalContext } from './EditSwotDataModalContext';
import ProgressBar from './ProgressBar';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding: 8px 30px 12px 30px;
`;

const StyledImage = styled(Image)`
  margin-right: 4px;
  cursor: pointer;
`;

const StyledLabel = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-primary);
`;

const StepNumberLabel = styled(Typography.Text)`
  font-weight: 500;
  color: var(--text-secondary);
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 4px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 5px;
`;

const StyledDropdown = styled(Dropdown)`
  // TODO, nothing of below things work, it still takes 155px width from the Dropdown component.
  width: auto;
  &&& {
    width: auto;

    .ant-dropdown-menu {
      width: auto;
    }
  }
  .ant-dropdown-menu {
    width: auto;
  }
  & .ant-dropdown-menu {
    width: auto;
  }
`;

const StyledBorder = styled.div`
  background-image: linear-gradient(
    to right,
    var(--background-gray-light) 0%,
    var(--line-gray) 50%,
    var(--background-gray-light)
  );
  width: 100%;
  height: 1px;
`;

type Props = {
  stepTitle: string | null;
};

const StepSelector = ({ stepTitle }: Props) => {
  const { stepNumber, setStepNumber, isEditMode } = useEditSwotDataModalContext();

  let dropdown;
  if (isEditMode) {
    const items = SWOT_CATEGORIES.map((el) => ({
      label: `Step ${el.value}: ${el.label}`,
      key: el.id,
      onClick: () => setStepNumber(el.value),
    }));
    dropdown = (
      <StyledDropdown menu={{ items }}>
        <StyledImage src="/icons/expand-more.svg" width={24} height={24} preview={false} />
      </StyledDropdown>
    );
  }

  return (
    <>
      <Container>
        <LabelContainer>
          {isEditMode && dropdown}
          <StepNumberLabel>{`Step ${stepNumber}/${SWOT_CATEGORIES.length} `}</StepNumberLabel>
          <StyledLabel>· {stepTitle}</StyledLabel>
        </LabelContainer>
        <ProgressBar step={stepNumber} />
      </Container>
      <StyledBorder />
    </>
  );
};

export default StepSelector;
