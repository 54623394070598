import React from 'react';
import { Route, Routes } from 'react-router-dom';

import GoogleMetrics from './GoogleMetrics';
import CommunityReviews from './reviews/CommunityReviews';

const GoogleMetricsRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="" element={<GoogleMetrics />} />
        <Route path="/:communityId" element={<CommunityReviews />} />
      </Routes>
    </>
  );
};

export default GoogleMetricsRoutes;
