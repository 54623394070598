import React from 'react';

import { Checkbox } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from '../../../store/hook';

const Sidebar = styled.div`
  width: 220px;
  min-height: 550px;
  padding-top: 28px;
  border-right: solid 1px var(--line-gray);
  font-size: 12px;
  padding-left: 20px;
  background-color: var(--background-gray-light);
`;

const Title = styled.h3`
  font-size: 16px;
`;

const Paragraph = styled.p`
  margin-bottom: 1px;
  font-size: 12px;
  color: ${(props) => props.color || '#89919d'};
  margin-top: ${(props: any) => props['margin-top'] || ''};
`;

const ViewsList = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Competitor {
  id: number;
  apartmentname: string;
}

interface Props {
  competitors: Competitor[];
  viewsSelection: number[];
  handleViewSelection: any;
}

const ShareViaEmailSidebar = ({ competitors, viewsSelection, handleViewSelection }: Props) => {
  const { views } = useAppSelector((state) => state.global);

  return (
    <Sidebar>
      <Title>Filters</Title>
      <Paragraph margin-top="15px">Competitors</Paragraph>
      {competitors.map((comp) => (
        <Paragraph key={comp.id} color="#222">
          {comp.apartmentname}
        </Paragraph>
      ))}
      <Paragraph margin-top="20px">Views</Paragraph>
      <Checkbox.Group value={viewsSelection} onChange={handleViewSelection}>
        <ViewsList>
          {views.map((item) => (
            <Checkbox key={item.id} value={item.id}>
              {item.name}
            </Checkbox>
          ))}
        </ViewsList>
      </Checkbox.Group>
    </Sidebar>
  );
};

export default ShareViaEmailSidebar;
