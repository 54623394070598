import React from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import Photos from './Photos';
import { useCommunityViewContext } from '../../../components/View/CommunityViewContext';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-y: auto;
`;

export const PhotosWidgetContent = () => {
  const { communityId } = useCommunityViewContext();
  const { filters = { competitors: [] } } = useWidgetContext();
  const { competitors } = filters;

  return (
    <Container className="mini-scroll">
      <Photos communityId={communityId} competitors={competitors} />
    </Container>
  );
};

export default PhotosWidgetContent;
