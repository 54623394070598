import React, { useState } from 'react';

import { Button, List, Popconfirm, Tag, Typography } from 'antd';
import { format } from 'date-fns';

import { useCommunityReviewsContext } from './CommunityReviewsContext';
import CommunityReviewsRequestsListHeader from './CommunityReviewsRequestsListHeader';
import { REVIEW_REQUEST_STATUS, REVIEW_REQUEST_STATUS_TAGS } from './constants';
import { ReviewRequestDataType } from './types';
import { updateCommunityReviewsRequest } from '../../../apis/ReviewsAPI';
import { STATUS } from '../../../constants';

type Props = {
  requests: ReviewRequestDataType[];
  disabled: boolean;
};

const CommunityReviewsRequestsList = ({ requests, disabled }: Props) => {
  const [status, setStatus] = useState(STATUS.IDLE);

  const { showMessage, refreshRender } = useCommunityReviewsContext();

  const handleCancelReviewRequest = async (reviewRequestId: number) => {
    try {
      setStatus(STATUS.LOADING);
      await updateCommunityReviewsRequest(reviewRequestId, { status: REVIEW_REQUEST_STATUS.CANCELED });
      refreshRender();
      setStatus(STATUS.LOADED);
    } catch (e) {
      showMessage('error', 'Trouble with canceling community review fetching job.');
      setStatus(STATUS.FAILURE);
    }
  };

  return (
    <List
      header={<CommunityReviewsRequestsListHeader />}
      bordered
      size={'small'}
      loading={disabled || status === STATUS.LOADING}
      dataSource={requests}
      renderItem={(item) => (
        <List.Item key={item.job_id}>
          <List.Item.Meta
            title={<Typography.Text strong>Job #{item.job_id}</Typography.Text>}
            description={`Initiated on ${format(new Date(item.created_at), 'MM/dd/yyyy hh:mm')}`}
          />
          <div>
            <Tag color={REVIEW_REQUEST_STATUS_TAGS[item.status].color}>
              {REVIEW_REQUEST_STATUS_TAGS[item.status].label}
            </Tag>
            <Popconfirm
              title="Cancel Reviews Fetching"
              description="Are you sure to abort reviews job for this community?"
              onConfirm={() => handleCancelReviewRequest(item.id)}
              okText="Yes"
              cancelText="No"
              disabled={status === STATUS.LOADING}
            >
              <Button danger size={'small'} disabled={status === STATUS.LOADING}>
                Cancel
              </Button>
            </Popconfirm>
          </div>
        </List.Item>
      )}
    />
  );
};

export default CommunityReviewsRequestsList;
