import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '../../store/hook';

const CompetitorOverview = () => {
  const navigate = useNavigate();
  const { communityId } = useParams();
  const { views } = useAppSelector((state) => state.global);

  useEffect(() => {
    const defaultItem = views.length > 0 && views[0];
    if (defaultItem) {
      navigate(`/competitor/${communityId}/view/${defaultItem.id}`);
    }
  }, [views]);
  return null;
};

export default CompetitorOverview;
