import React from 'react';

import styled from 'styled-components';

import CategoriesHeader from './CategoriesHeader';
import SwotCategoryRow from './SwotCategoryRow';
import SwotNote from './SwotNote';

const StyledCategories = styled.div`
  display: flex;
  flex-flow: row;
  padding: 20px 30px 0px 20px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  background-image: linear-gradient(
    to bottom,
    var(--background-gray-light) 0%,
    var(--line-gray) 50%,
    var(--background-gray-light) 100%
  );
  margin: 0 30px;
`;

const StyledCategoryContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;

const HorizontalDivider = styled.div`
  background-image: linear-gradient(
    to right,
    var(--background-gray-light) 0%,
    var(--line-gray) 50%,
    var(--background-gray-light)
  );
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
`;

type Props = {
  selectedCategory: any;
};

const SwotCategories = ({ selectedCategory }: Props) => {
  return (
    <StyledCategories>
      <StyledCategoryContainer>
        <CategoriesHeader />
        <HorizontalDivider />
        {selectedCategory.fields.map((field: any) => (
          <SwotCategoryRow key={field.key} category={field} />
        ))}
      </StyledCategoryContainer>
      <VerticalDivider />
      <SwotNote />
    </StyledCategories>
  );
};

export default SwotCategories;
