import React from 'react';

import styled from 'styled-components';

import SidebarMenuCategoryHeader from './SidebarMenuCategoryHeader';
import SidebarMenuItem from './SidebarMenuItem';

const Container = styled.div`
  padding-top: 20px;
`;

const SidebarMenuMysteryShopping = () => {
  return (
    <Container>
      <SidebarMenuCategoryHeader title={'MYSTERY SHOPPING'} />
      <SidebarMenuItem to={`/mystery-shopping`} label="Shop Requests" />
    </Container>
  );
};

export default SidebarMenuMysteryShopping;
