import React, { useState } from 'react';

import { Button } from 'antd';
import styled from 'styled-components';

import SwotCompetitorAnalysisModal from '../modals/SwotCompetitorAnalysisModal/SwotCompetitorAnalysisModal';

const StyledButton = styled(Button)`
  padding: 6px;
  border-radius: 4px;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const HeaderViewActionsSwot = () => {
  const [openSwotModal, setOpenSwotModal] = useState<boolean>(false);
  return (
    <>
      <SwotCompetitorAnalysisModal open={openSwotModal} onClose={() => setOpenSwotModal(false)} />
      <StyledButton onClick={() => setOpenSwotModal(true)}>
        <img src="/icons/swot-icon.svg" alt="custom" />
        <span>SWOT</span>
      </StyledButton>
    </>
  );
};

export default HeaderViewActionsSwot;
