import { createContext, useContext } from 'react';

import { HeaderContent } from './types';

type SettingsContent = {
  filterOptions: { value: number; label: string }[];
  isMultipleSelection: boolean;
  page: number;
  searchTerm: string;
  status: number;
  communities: any[];
  communitiesTotalCount: number;
  selectedCommunitiesIds: number[];
  allCommunitiesSelected: boolean;
  headerContent: HeaderContent;
  getItemStatusComponent: (item: any) => React.ReactNode | undefined;
  toggleAllCommunities: () => void;
  toggleOneCommunity: (item: any) => void;
};

export const SettingsContext = createContext<SettingsContent>({
  filterOptions: [],
  isMultipleSelection: false,
  page: 1,
  searchTerm: '',
  status: 0,
  communities: [],
  communitiesTotalCount: 0,
  selectedCommunitiesIds: [],
  allCommunitiesSelected: false,
  headerContent: {
    title: '',
    description: '',
    iconSrc: '',
    iconBackgroundColor: '',
  },
  getItemStatusComponent: () => undefined,
  toggleAllCommunities: () => undefined,
  toggleOneCommunity: () => undefined,
});

export const useSettingsContext = () => useContext(SettingsContext);
