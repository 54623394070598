import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import Slider from 'components/lib/Slider';

import { useEditSwotDataModalContext } from './EditSwotDataModalContext';

const CategoryRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  padding-left: 10px;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;

  &:hover {
    width: calc(100% + 1px);
    padding-left: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px var(--line-gray);
  }

  && {
    .ant-slider-rail {
      background-color: transparent;
    }
  }

  :hover {
    && {
      .ant-slider-rail {
        background-color: #efefef;
      }
    }
  }
`;

const SliderContainer = styled.div`
  width: 304px;
  padding-right: 40px;
`;

const StyledLabel = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 310px;
`;

type Props = {
  category: {
    name: string;
    key: string;
  };
};

const SwotCategoryRow = ({ category }: Props) => {
  const { temporarySwotState, setTemporarySwotState } = useEditSwotDataModalContext();

  const onChange = (value: number) => {
    setTemporarySwotState({ [category.key]: value });
  };

  return (
    <CategoryRow key={category.key}>
      <StyledLabel title={category.name}>{category.name}</StyledLabel>
      <SliderContainer>
        <Slider
          min={1}
          max={5}
          value={temporarySwotState[category.key] as number}
          defaultValue={3}
          onChangeComplete={onChange}
          onChange={onChange}
        />
      </SliderContainer>
    </CategoryRow>
  );
};

export default SwotCategoryRow;
