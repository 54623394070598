import React from 'react';

import styled from 'styled-components';

import SelectAdvancedFilterRadio from 'components/lib/SelectAdvancedFilterRadio';

import { MYSTERY_SHOP_MODAL_REQUEST_FROM_OPTIONS } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const StyledFilter = styled(SelectAdvancedFilterRadio)`
  margin-right: 5px;
`;

const ShopRequestsRequestsFromFilter = () => {
  const { filters, setFilters } = useShopRequestsDataContext();

  const onChange = (newValue: number) => {
    setFilters({ ...filters, requestFrom: newValue });
  };

  return (
    <StyledFilter
      options={MYSTERY_SHOP_MODAL_REQUEST_FROM_OPTIONS}
      defaultValue={filters.requestFrom}
      onSelect={onChange}
      placeholder="Request From"
    />
  );
};

export default ShopRequestsRequestsFromFilter;
