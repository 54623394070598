import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { SELECTOR_ITEM_TYPES } from './constants';
import { SidebarContext } from './SidebarContext';
import SidebarSelectorDropdownListItem from './SidebarSelectorDropdownListItem';
import { SelectorItemDatatype } from './types';
import { useAppSelector } from '../../../store/hook';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-x: auto;
  border-top: solid 1px var(--line-gray);

  &.empty {
    border-top: none;
  }
`;

const SidebarSelectorDropdownList = () => {
  const { managementCompanyList, communityList, communityCompetitorsList } = useAppSelector((state) => state.global);

  const { filterKeyword, filterType } = useContext(SidebarContext);
  const [items, setItems] = useState<SelectorItemDatatype[]>([]);

  useEffect(() => {
    const adjustedManagementCompanies = managementCompanyList?.map((item) => ({
      id: item.id,
      type: SELECTOR_ITEM_TYPES.MANAGEMENT_COMPANY,
      name: item.management_company,
    }));

    const adjustedCommunities = communityList?.map((item) => ({
      id: item.id,
      type: SELECTOR_ITEM_TYPES.COMMUNITY,
      managementCompanyId: item.management_company_id,
      name: item.apartmentname,
      address: item.apartmentaddress,
      description: item.apartmentaddress,
    }));

    const adjustedCompetitors = communityCompetitorsList?.map((item) => {
      const parentCommunity = adjustedCommunities.find(
        (community: SelectorItemDatatype) => community.id === item.parent_id
      );

      return {
        id: item.id,
        type: SELECTOR_ITEM_TYPES.COMPETITOR,
        managementCompanyId: parentCommunity?.managementCompanyId,
        name: item.apartmentname,
        address: item.apartmentaddress,
        description: item.apartmentaddress,
      };
    });

    const allItems = [...adjustedManagementCompanies, ...adjustedCommunities, ...adjustedCompetitors]
      .filter((item: SelectorItemDatatype) => item.type === filterType || filterType === SELECTOR_ITEM_TYPES.ALL)
      .filter(
        (item: SelectorItemDatatype) =>
          !filterKeyword ||
          item.name?.toLowerCase().includes(filterKeyword.toLowerCase()) ||
          item.description?.toLowerCase().includes(filterKeyword.toLowerCase())
      );
    setItems(allItems);
  }, [managementCompanyList, communityList, communityCompetitorsList, filterType, filterKeyword]);

  return (
    <Container className={classNames({ empty: items.length == 0 }, 'mini-scroll')}>
      {items.map((entry: SelectorItemDatatype) => (
        <SidebarSelectorDropdownListItem key={entry.type + entry.id} item={entry} />
      ))}
    </Container>
  );
};

export default SidebarSelectorDropdownList;
