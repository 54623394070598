import React from 'react';

import { getDecimals } from 'helpers';

import classes from './styles.module.scss';

interface Props {
  communityData: any;
  competitorData: any;
}

const BlackBar = ({ communityData, competitorData }: Props) => {
  return (
    <div className={classes.black_bar}>
      {/* my avg rate */}
      <div>
        <span className={classes.black_bar_title}>MY AVG. RATE</span>
        <div style={{ position: 'relative' }}>
          <span className={classes.doller_sign}>$</span>
          <span className={classes.amount_text}>
            {communityData?.Avg_base_rent
              ? parseInt(`${getDecimals(communityData?.Avg_base_rent).int}`).toLocaleString()
              : 0}
            <span>.{getDecimals(communityData?.Avg_base_rent).point}</span>
          </span>
        </div>
        <span className={classes.market_avg}>
          Market avg.
          <span
            style={{
              color: `${communityData?.Avg_base_rent > competitorData?.Avg_base_rent ? ' #fa7550' : '#1ad392'}`,
            }}
          >
            $
            {competitorData?.Avg_base_rent
              ? parseFloat(competitorData?.Avg_base_rent).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : 0.0}
          </span>
        </span>
      </div>

      {/* my avg sq ft */}
      <div>
        <span className={classes.black_bar_title}>MY AVG. SQ. FT.</span>
        <div style={{ position: 'relative' }}>
          <span className={classes.amount_text}>
            {communityData?.Avg_sq_footage
              ? parseInt(`${getDecimals(communityData?.Avg_sq_footage).int}`).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })
              : 0}
            <span>.{getDecimals(communityData?.Avg_sq_footage).point}</span>
          </span>
        </div>
        <span className={classes.market_avg}>
          Market avg.
          <span
            style={{
              color: `${communityData?.Avg_sq_footage > competitorData?.Avg_sq_footage ? ' #fa7550' : '#1ad392'}`,
            }}
          >
            {competitorData?.Avg_sq_footage
              ? parseFloat(competitorData?.Avg_sq_footage).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : 0.0}
          </span>
        </span>
      </div>

      {/* my avg rate / st ft */}
      <div>
        <span className={classes.black_bar_title}>MY AVG. RATE / SQ. FT.</span>
        <div style={{ position: 'relative' }}>
          <span className={classes.doller_sign}>$</span>
          <span className={classes.amount_text}>
            {communityData?.Avg_price_per_sq ? getDecimals(communityData?.Avg_price_per_sq).int : 0}
            <span>.{getDecimals(communityData?.Avg_price_per_sq).point}</span>
          </span>
        </div>
        <span className={classes.market_avg}>
          Market avg.
          <span
            style={{
              color: `${communityData?.Avg_price_per_sq > competitorData?.Avg_price_per_sq ? ' #fa7550' : '#1ad392'}`,
            }}
          >
            $
            {competitorData?.Avg_price_per_sq
              ? parseInt(competitorData?.Avg_price_per_sq).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : 0.0}
          </span>
        </span>
      </div>

      {/* my avg google rating */}
      <div>
        <span className={classes.black_bar_title}>MY AVG. GOOGLE RATING</span>
        <div style={{ position: 'relative' }}>
          <span className={classes.rating_text}>
            <span>{communityData?.g_rating ? communityData?.g_rating?.toFixed(1) : 0}</span> (
            {communityData?.g_reviews
              ? Math.floor(communityData?.g_reviews).toLocaleString('en-US', {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                })
              : 0}
            )
          </span>
          {/* <span className={classes.amount_text}>
                    {communityData?.Avg_google_rating
                      ? communityData?.Avg_google_rating?.toFixed(1)
                      : 0}
                  </span> */}
        </div>
        <span className={classes.market_avg}>
          Competitor avg
          <span
            style={{
              color: `${communityData?.g_reviews < competitorData?.g_reviews ? ' #fa7550' : '#1ad392'}`,
            }}
          >
            {competitorData?.g_rating ? competitorData?.g_rating?.toFixed(1) : 0}
          </span>{' '}
          (
          {competitorData?.g_reviews
            ? Math.floor(competitorData?.g_reviews).toLocaleString('en-US', {
                maximumFractionDigits: 0,
              })
            : 0}
          )
        </span>
      </div>
    </div>
  );
};

export default BlackBar;
