export const WIDGET_MODAL_TYPE = {
  ADD: 'add',
  EDIT: 'edit',
};

export const DATA_RANGE_TYPE = {
  NOW: 'now',
  OVERTIME: 'overtime',
};

export const DATA_DISPLAY_TYPE = {
  GRAPH: 'graph',
  TABLE: 'table',
};

export const DEFAULT_WIDGET = {
  type: '',
  name: '',
  note: '',
  filters: {
    competitors: [],
    careTypes: [],
    roomTypes: [],
    careLevels: [],
    reviewSources: [],
    reviewCategories: [],
    swotCategories: [],
  },
};
