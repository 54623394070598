import React, { createContext, useContext } from 'react';

import { MysteryShopRequestInbound } from '../types';

type EditShopRequestModalContent = {
  shopRequest: MysteryShopRequestInbound | null;
  isLoading: boolean;
  setLoading: (value: boolean) => void;
  setShopRequest: (item: MysteryShopRequestInbound) => void;
};

export const EditShopRequestModalContext = createContext<EditShopRequestModalContent>({
  shopRequest: null,
  isLoading: false,
  setLoading: () => {
    // empty
  },
  setShopRequest: () => {
    // empty
  },
});

export const useEditShopRequestModalContext = () => useContext(EditShopRequestModalContext);
