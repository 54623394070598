import React from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons/lib';
import Table, { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';

import { COMMUNITIES_INDENT_SIZE, COMMUNITIES_PAGE_SIZE, COMMUNITIES_TABLE_FIRST_COLUMN_TITLE } from './constants';
import OverviewCommunitiesInfo from './OverviewCommunitiesInfo';
import OverviewCommunitiesRatingCell from './OverviewCommunitiesRatingCell';
import OverviewCommunitiesInfoValueCell from './OverviewCommunitiesValueCell';
import { COMMUNITY_TYPE } from '../../constants';

const StyledTable = styled(Table)`
  &&& {
    .ant-table-thead > tr > th {
      font-family: var(--font-bold);
      font-size: 12px;
      background-color: transparent;
      color: var(--text-primary);
    }
    .ant-table-tbody > tr:nth-child(odd) {
      background-color: inherit;
    }
    .ant-table-tbody > tr > td {
      padding: 11px;
    }
    .ant-table-cell-with-append {
      display: flex;
    }
    .ant-table-pagination {
      padding: 0px 24px;
      margin: 22px 0px;
      align-items: baseline;
      font-size: 12px;

      .ant-pagination-total-text {
        position: absolute;
        right: 20px;
      }

      .ant-pagination-item {
        line-height: 24px;
        color: var(--text-primary);
      }

      .ant-pagination-item-active {
        border-radius: 11px;

        box-shadow: 0 2px 6px 0 var(--box-shadow-gray);
        background-color: #fff;
        border: none;
        a {
          color: var(--text-primary) !important;
        }
      }
    }
  }
`;

const FirstColumnHeader = styled.span`
  padding-left: 15px;
`;

const ExpandIconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
`;

interface Props {
  type: string;
  communities: any[];
}

const OverviewCommunitiesTablePreview = ({ type, communities }: Props) => {
  const columns: ColumnsType<any> = [
    {
      title: <FirstColumnHeader>{COMMUNITIES_TABLE_FIRST_COLUMN_TITLE[type]}</FirstColumnHeader>,
      dataIndex: 'id',
      width: 400,
      key: 'id',
      render: (value, record: any) => {
        return <OverviewCommunitiesInfo record={record} />;
      },
    },
    {
      title: 'MARKET RATE AVG.',
      dataIndex: 'Avg_base_rent',
      key: 'Avg_base_rent',
      width: 200,
      render: (value, record: any) => {
        return (
          <OverviewCommunitiesInfoValueCell value={value} marketValue={record.market_Avg_base_rent} prefix={'$'} />
        );
      },
    },
    {
      title: 'SQ FT AVG.',
      dataIndex: 'Avg_sq_footage',
      key: 'Avg_sq_footage',
      width: 200,
      render: (value, record: any) => (
        <OverviewCommunitiesInfoValueCell value={value} marketValue={record.market_Avg_sq_footage} />
      ),
    },
    {
      title: 'RATE/SQ FT AVG.',
      dataIndex: 'Avg_price_per_sq',
      key: 'Avg_price_per_sq',
      width: 200,
      render: (value, record: any) => (
        <OverviewCommunitiesInfoValueCell value={value} marketValue={record.market_Avg_price_per_sq} prefix={'$'} />
      ),
    },
    {
      title: 'RATING AVG.',
      dataIndex: 'Avg_google_rating',
      key: 'Avg_google_rating',
      width: 200,
      render: (value, record: any) => {
        const isCompetitor = record?.apartmenttype === COMMUNITY_TYPE.COMPETITOR;
        const marketValue = isCompetitor ? 0 : record.competitorsRating;
        const marketTotal = isCompetitor ? 0 : record?.competitorsRatingsCount;

        return (
          <OverviewCommunitiesRatingCell
            value={record?.rating}
            total={record?.ratingsCount}
            marketValue={marketValue}
            marketTotal={marketTotal}
          />
        );
      },
    },
  ];

  return (
    <StyledTable
      columns={columns}
      size={'small'}
      dataSource={communities}
      bordered={false}
      rowKey={(record: any) => record.id}
      expandable={{
        indentSize: COMMUNITIES_INDENT_SIZE,
        expandIcon: ({ expanded, onExpand, record }) => (
          <ExpandIconContainer>
            {record?.apartmenttype === COMMUNITY_TYPE.COMMUNITY && record.children?.length > 0 && (
              <>
                {expanded ? (
                  <UpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                )}
              </>
            )}
          </ExpandIconContainer>
        ),
      }}
      pagination={{
        size: 'small',
        position: ['bottomLeft'],
        showSizeChanger: false,
        pageSize: COMMUNITIES_PAGE_SIZE,
        showTotal: (total, range) => {
          const displayedItems = range[1] - range[0] + 1;
          return (
            <span>
              Showing {displayedItems} of {total} records
            </span>
          );
        },
      }}
    />
  );
};

export default OverviewCommunitiesTablePreview;
