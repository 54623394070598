import React from 'react';

import styled from 'styled-components';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';

import { SHOP_REQUEST_STATUS } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const StyledFilter = styled(SelectAdvancedFilter)`
  margin-right: 5px;
`;

const ShopRequestsStatusFilter = () => {
  const { filters, setFilters } = useShopRequestsDataContext();

  const onChange = (values: any[]) => {
    setFilters({ ...filters, status: values.map((item: any) => item.value) });
  };

  return (
    <StyledFilter
      options={SHOP_REQUEST_STATUS.map((item) => ({ value: item.id, label: item.label }))}
      defaultValue={filters.status ?? []}
      onSelect={onChange}
      placeholder="Status"
    />
  );
};

export default ShopRequestsStatusFilter;
