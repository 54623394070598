import React, { useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Input } from 'antd';

type PlaceState = {
  address: string;
};

interface Props {
  getLocationBySearch: (data: any) => void;
  placeholder?: string;
  style?: any;
  defaultValue?: string;
  updateCommunityName?: (data: string) => void;
}

const LocationSearchInput = ({
  getLocationBySearch,
  placeholder = '',
  style,
  defaultValue,
  updateCommunityName,
}: Props) => {
  const [state, setState] = React.useState<PlaceState>({ address: '' });

  const handleChange = (address: string) => {
    setState((prevState) => ({ ...prevState, address }));
    updateCommunityName && updateCommunityName(address);
  };

  useEffect(() => {
    if (defaultValue) {
      setState((prev) => ({ ...prev, address: defaultValue }));
    } else {
      setState({ address: '' });
    }
  }, [defaultValue]);

  const handleSelect = async (address: any) => {
    try {
      const values = {
        name: '',
        streetNumber: '',
        route: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        formatted_address: '',
        place_id: '',
        phone_number: '',
        website: '',
      };
      const results = await geocodeByAddress(address);

      const addressComponent = results[0].address_components;
      if (addressComponent) {
        values.formatted_address = results[0]?.formatted_address || '';
        values.place_id = results[0]?.place_id || '';
        for (let i = 0; i < addressComponent.length; i++) {
          const component = addressComponent[i];
          const addressType = component.types[0];

          switch (addressType) {
            case 'street_number':
              values.streetNumber = component.long_name;
              break;
            case 'route':
              values.route = component.short_name;
              break;
            case 'locality':
              values.city = component.long_name;
              break;
            case 'administrative_area_level_1':
              values.state = component.short_name;
              break;
            case 'postal_code':
              values.zip_code = component.long_name;
              break;
            case 'country':
              values.country = component.long_name;
              break;
          }
        }
      }

      const latlng = await getLatLng(results[0]);
      values.name = address.split(',')[0];

      const map_el: any = document.getElementById('map');

      if (map_el) {
        const service = new google.maps.places.PlacesService(map_el);
        service.getDetails(
          {
            placeId: values.place_id,
          },
          function (place: any, status) {
            getLocationBySearch({
              ...values,
              ...latlng,
              phone_number: place?.formatted_phone_number || '',
              website: place?.website || '',
              name: place?.name,
            });
            setState((prevState) => ({
              ...prevState,
              address: place?.name,
            }));
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div id="map"></div>
      <PlacesAutocomplete value={state.address} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ position: 'relative' }}>
            <Input
              size="middle"
              prefix={<SearchOutlined />}
              style={{
                borderRadius: '50px',
                fontSize: '14px',
                overflow: 'hidden',
                padding: '0px 15px',
                boxShadow: '1px 2px 5px #ccc',
                height: 50,
                ...style,
              }}
              {...getInputProps({
                placeholder: placeholder,
                className: 'location-search-input',
              })}
            />
            {suggestions.length > 0 && (
              <Card
                className="box_shadow"
                style={{
                  padding: 0,
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  zIndex: 999,
                }}
                bodyStyle={{
                  padding: '10px 0px',
                }}
              >
                {suggestions.map((suggestion, idx) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: 'var(--background-gray-light)',
                        cursor: 'pointer',
                        padding: '5px 20px',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '5px 20px',
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={idx}
                    >
                      <span>
                        <EnvironmentOutlined style={{ marginRight: 10 }} />
                        {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </Card>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default LocationSearchInput;
