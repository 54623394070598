import React, { createContext, useContext } from 'react';

type RootContent = {
  refreshCommunities: () => void;
  showMessage: (type: 'success' | 'info' | 'error', message: string) => void;
};

export const RootContext = createContext<RootContent>({
  refreshCommunities: () => {
    // Do nothing
  },
  showMessage: () => {
    // Do nothing
  },
});

export const useRootContext = () => useContext(RootContext);
