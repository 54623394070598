import React from 'react';

import { Skeleton, Space } from 'antd';

/**
 * TODO: There should most probably be only one loader used across the App.
 * Until we figure that out, let's use this one here.
 */
const Loader = () => (
  <div style={{ padding: '20px' }}>
    <Skeleton.Button active={true} style={{ width: '300px', height: 30 }} />
    <br />
    <br />
    <Space>
      <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />
      <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />
      <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />{' '}
      <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />
    </Space>
    <br />
    <br />
    <Space>
      <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
      <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
      <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />{' '}
      <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
    </Space>
  </div>
);

export default Loader;
