import React, { createContext, useContext } from 'react';

type OccupanciesContent = {
  communityId: number | null;
  showMessage: (type: 'success' | 'error', message: string) => void;
};

export const OccupanciesContext = createContext<OccupanciesContent>({
  communityId: null,
  showMessage: () => {
    // Does nothing
  },
});
export const useOccupanciesContext = () => useContext(OccupanciesContext);
