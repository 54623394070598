import React, { useEffect, useState } from 'react';

import { Button, message, Tabs } from 'antd';

import { useAppSelector } from 'store/hook';

import { AccommodationContext } from './AccommodationContext';
import LivingInfoTab from './LivingInfoTab';

interface Props {
  community_id: any;
}

const AccommodationForm = ({ community_id }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    setSelectedTab(livingTypes[0]?.type || '');
  }, [livingTypes]);

  const items = livingTypes.map((item: any) => ({
    label: (
      <Button
        type="text"
        className={selectedTab == item.type ? 'p_healing_active_tab' : 'btnStyle'}
        shape="round"
        style={{ fontSize: 14 }}
        onClick={() => setSelectedTab(item.type)}
      >
        {item.type}
      </Button>
    ),
    key: item.type,
    destroyInactiveTabPane: true,
    children: <LivingInfoTab livingType={item.livingtype_order} />,
    style: {
      padding: 0,
    },
  }));

  const showMessage = (type: 'success' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <AccommodationContext.Provider value={{ communityId: parseInt(community_id), showMessage: showMessage }}>
      {contextHolder}
      <Tabs activeKey={selectedTab} tabPosition="top" tabBarGutter={10} size="small" items={items} />
    </AccommodationContext.Provider>
  );
};

export default AccommodationForm;
