import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarMenuCategoryHeader from './SidebarMenuCategoryHeader';
import SidebarMenuItem from './SidebarMenuItem';
import { ACTOR_TYPE } from '../../../constants';

const Container = styled.div`
  margin-top: 10px;
`;

const SidebarMenuCommunity = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);

  return (
    <Container>
      <SidebarMenuCategoryHeader title={'OTHER'} />
      {selectedCommunity?.type === ACTOR_TYPE.COMMUNITY && (
        <SidebarMenuItem to={`/community/${selectedCommunity?.id}/competitors`} label={'Competitors'} />
      )}
      <SidebarMenuItem to={`/${selectedCommunity?.type}/${selectedCommunity?.id}/info`} label={'Community Info'} />
    </Container>
  );
};

export default SidebarMenuCommunity;
