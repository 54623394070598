import React, { useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons/lib';
import styled from 'styled-components';

import { COMMUNITIES_TABLE_SEARCH_PLACEHOLDER, COMMUNITIES_TABLE_TITLE } from './constants';
import { useDebounce } from '../../utils/hooks/useDebounce';
import Input from '../lib/Input';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
`;

const Title = styled.div`
  font-family: var(--font-bold);
  font-size: 18px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

interface Props {
  type: string;
  handleSearch: any;
}

const OverviewCommunitiesTableHeader = ({ type, handleSearch }: Props) => {
  const [keyword, setKeyword] = useState<string>('');
  const debouncedKeyword = useDebounce(keyword);

  useEffect(() => {
    handleSearch(debouncedKeyword);
  }, [debouncedKeyword]);

  const handleChange = (values: any) => {
    setKeyword(values.target.value);
  };

  return (
    <Container>
      <Title>{COMMUNITIES_TABLE_TITLE[type]}</Title>
      <Controls>
        <Input
          placeholder={COMMUNITIES_TABLE_SEARCH_PLACEHOLDER[type]}
          prefix={<SearchOutlined />}
          onChange={handleChange}
        />
      </Controls>
    </Container>
  );
};

export default OverviewCommunitiesTableHeader;
