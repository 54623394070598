import React from 'react';

import styled from 'styled-components';

import SelectAdvancedFilterRadio from 'components/lib/SelectAdvancedFilterRadio';

import { SHOP_REQUEST_VIEW_FILTER_OPTIONS } from '../constants';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const StyledFilter = styled(SelectAdvancedFilterRadio)`
  margin-right: 5px;
`;

const ShopRequestGroupByFilter = () => {
  const { filters, setFilters } = useShopRequestsDataContext();

  return (
    <StyledFilter
      options={SHOP_REQUEST_VIEW_FILTER_OPTIONS}
      defaultValue={filters.groupBy}
      onSelect={(newValue: number) => {
        if (filters) {
          setFilters({ ...filters, groupBy: newValue });
        }
      }}
      placeholder="View"
    />
  );
};

export default ShopRequestGroupByFilter;
