import React from 'react';

import Button from '../../../lib/Button';
import { useIncentivesContext } from '../../IncentivesContext';
import { UnitType } from '../../types';

interface Props {
  unit: UnitType;
}

const IncentivesTableUnitsActionsSendProposal = ({ unit }: Props) => {
  const { setSelectedProposalUnit } = useIncentivesContext();

  return (
    <Button
      style={{
        width: 100,
        height: 40,
      }}
      onClick={() => setSelectedProposalUnit(unit)}
    >
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          gap: 8,
        }}
      >
        <img
          src={'/icons/send-green.svg'}
          alt="send"
          style={{
            width: 24,
            height: 24,
          }}
        />
        Send
      </span>
    </Button>
  );
};

export default IncentivesTableUnitsActionsSendProposal;
