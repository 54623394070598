import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { getFormattedDate, getRelativeTime, isBeforeToday } from 'helpers/dates';

import { MysteryShopRequestInbound } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledSubTitle = styled(Typography.Text)`
  color: var(--text-secondary);
  font-size: 12px;
  height: 18px;
`;

type Props = {
  shopRequest: MysteryShopRequestInbound;
};

const ShopRequestsDueDate = ({ shopRequest }: Props) => {
  const isOverdue = isBeforeToday(shopRequest.dueDate);

  if (!shopRequest.dueDate) {
    return <div />;
  }

  return (
    <Container className="due-date-column">
      <Typography.Text style={{ color: isOverdue ? 'var(--dark-orange)' : undefined }}>
        {getFormattedDate(shopRequest.dueDate, 'MMMM D YYYY')}
      </Typography.Text>
      <StyledSubTitle>
        {isOverdue ? getRelativeTime(shopRequest.dueDate) : getFormattedDate(shopRequest.dueDate, 'dddd')}
      </StyledSubTitle>
    </Container>
  );
};

export default ShopRequestsDueDate;
