import React from 'react';

import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';

const StyledCheckboxes = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& .ant-checkbox + span {
    padding-inline-start: 10px;
    padding-inline-end: 30px;
    display: flex;
    align-items: center;
  }
`;

type Props = {
  style?: any;
  toggleIndependentLiving: (checked: boolean) => void;
  toggleAssistedLiving: (checked: boolean) => void;
  toggleMemoryCare: (checked: boolean) => void;
  independantLiving: boolean;
  assistedLiving: boolean;
  memoryCare: boolean;
  disabled?: boolean;
};

const ShopRequestModalCheckboxes = ({
  style,
  toggleIndependentLiving,
  toggleAssistedLiving,
  toggleMemoryCare,
  independantLiving,
  assistedLiving,
  memoryCare,
  disabled = false,
}: Props) => {
  return (
    <StyledCheckboxes style={style}>
      <StyledCheckbox
        checked={independantLiving}
        disabled={disabled}
        className={'green'}
        onChange={(event: CheckboxChangeEvent) => toggleIndependentLiving(event.target.checked)}
      >
        IL
      </StyledCheckbox>
      <StyledCheckbox
        checked={assistedLiving}
        disabled={disabled}
        className={'green'}
        onChange={(event: CheckboxChangeEvent) => toggleAssistedLiving(event.target.checked)}
      >
        AL
      </StyledCheckbox>
      <StyledCheckbox
        checked={memoryCare}
        disabled={disabled}
        className={'green'}
        onChange={(event: CheckboxChangeEvent) => toggleMemoryCare(event.target.checked)}
      >
        MC
      </StyledCheckbox>
    </StyledCheckboxes>
  );
};

export default ShopRequestModalCheckboxes;
