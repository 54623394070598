import React, { useState } from 'react';

import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { Col, message, Row, Tooltip, Typography } from 'antd';

import { getDecimals } from 'helpers';
import SearchCommunityModal from 'modals/SearchCommunityModal';

import { updateCommunity } from '../../../apis/CommunityAPI';
import { createGoogleMetrics, createGoogleReviews } from '../../../apis/GoogleMetricsAPI';

interface Props {
  avgData: any;
  communityInfo: any;
  getCompetitorInfo: any;
}

const ComparisonTab = ({ avgData, communityInfo, getCompetitorInfo }: Props) => {
  const [ratingLoading, setRatingLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [openSearchCommunityModal, setOpenSearchCommunityModal] = useState<boolean>(false);

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const handleSetLocation = (data: any) => {
    communityInfo.GoogleMapLocation = data.place_id;
    saveGooglePlaceId(communityInfo.Id, communityInfo.GoogleMapLocation);
    updateRating();
    setOpenSearchCommunityModal(false);
  };

  const updateRating = async () => {
    const updates: any = [];
    let place_id: any = null;
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    if (communityInfo.GoogleMapLocation) {
      place_id = communityInfo.GoogleMapLocation;
    } else {
      setOpenSearchCommunityModal(true);
      return;
    }
    setRatingLoading(true);
    try {
      service.getDetails(
        {
          placeId: place_id,
        },
        async function (place: any, status: any) {
          if (status == 'INVALID_REQUEST') {
            setOpenSearchCommunityModal(true);
            setRatingLoading(false);
            return;
          }
          updates.push({
            apartment: communityInfo.Id,
            old_google_ratings: communityInfo.GoogleRating,
            old_google_reviews: communityInfo.GoogleReviews,
            google_ratings: place.rating || 0,
            google_reviews: place.user_ratings_total || 0,
            is_latest: true,
          });

          const placeReviews = place.reviews?.map((review: any) => ({
            apartment: communityInfo.Id,
            google_review_user: review.author_name,
            google_review_date: new Date(review.time * 1000),
            google_review_text: review.text,
            google_review_url: review.author_url,
          }));
          saveGoogleMetrics(updates, placeReviews || []);
        }
      );
    } catch (err) {
      setRatingLoading(false);
    }
  };

  const saveGoogleMetrics = (updates: any, reviews: any) => {
    createGoogleMetrics(updates)
      .then((res) => {
        showMessage('success', 'Google Metrics saved successfully!');
        saveGoogleReviews(reviews);
      })
      .catch((err) => {
        console.log(err);
        showMessage('error', 'Something went wrong saving Google Metrics');
      });
  };

  const saveGoogleReviews = (reviews: any) => {
    if (reviews.length < 1) {
      getCompetitorInfo();
      showMessage('success', 'Google Reviews Saved successfully!');
      setRatingLoading(false);
      return;
    }

    createGoogleReviews(reviews)
      .then((res) => {
        getCompetitorInfo();
        showMessage('success', 'Google Reviews saved successfully!');
        setRatingLoading(false);
      })
      .catch((err) => {
        setRatingLoading(false);
        showMessage('error', 'Something went wrong saving Google Reviews');
      });
  };

  const saveGooglePlaceId = (communityId: number, googleMapLocation: string) => {
    updateCommunity(communityId, { google_map_location: googleMapLocation })
      .then((res) => {
        showMessage('success', 'Google Place ID saved successfully!');
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong saving Google Place ID');
      });
  };

  return (
    <div>
      {contextHolder}
      <SearchCommunityModal
        loading={false}
        open={openSearchCommunityModal}
        handleClose={() => setOpenSearchCommunityModal(false)}
        handleSetLocation={handleSetLocation}
        searchKeyword={communityInfo?.ApartmentName}
      />
      <div
        style={{
          maxWidth: '900px',
          padding: '0px 20px',
        }}
      >
        <Row className="rowgap-vbox" gutter={[5, 16]}>
          {/* 1 */}
          <Col xs={24} sm={24} md={12} lg={5} xl={5}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG. RATE
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>
                  {parseInt(avgData?.avgBaseRent || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(avgData?.avgBaseRent).point}</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Tooltip
              title={() => (
                <span>
                  Rate: ${parseInt(avgData?.avgBaseRent || 0).toLocaleString()}.
                  {getDecimals(avgData?.avgBaseRent).point} <br /> Care: $
                  {parseInt((avgData?.avgBaseRentCare - avgData?.avgBaseRent).toString()).toLocaleString()}.
                  {getDecimals(avgData?.avgBaseRentCare - avgData?.avgBaseRent).point}
                </span>
              )}
              placement="top"
              color="blue"
            >
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG.(RATE + CARE)
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>
                  {parseInt(avgData?.avgBaseRentCare || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(avgData?.avgBaseRentCare).point}</span>
                </Typography.Paragraph>
              </div>
            </Tooltip>
          </Col>
          {/* 2 */}
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 4,
                    display: 'block',
                  }}
                >
                  AVG. SQ. FT.
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  {parseInt(avgData?.avgSqFt || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(avgData?.avgSqFt).point}</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
          {/* 3 */}
          <Col xs={24} sm={24} md={12} lg={5} xl={5}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG. RATE / SQ. FT.
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>

                  {parseInt(avgData?.avgRatePerSqFt || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}> .{getDecimals(avgData?.avgRatePerSqFt).point}</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <div>
              <span
                className="c_rate_title"
                style={{
                  fontSize: '14px',
                  color: '#000',
                  display: 'block',
                  marginBottom: -5,
                  marginLeft: 4,
                }}
              >
                GOOGLE Rating{' '}
                {ratingLoading ? (
                  <LoadingOutlined />
                ) : (
                  <Tooltip title={communityInfo?.GoogleMapLocation} color="blue">
                    <RedoOutlined onClick={updateRating} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </span>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  {avgData?.google_rating ? avgData?.google_rating.toFixed(1) : 0}{' '}
                  <span style={{ fontSize: '17px' }}>({avgData?.google_reviews ? avgData?.google_reviews : 0})</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ComparisonTab;
