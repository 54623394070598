import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { IncentiveType, UnitType } from '../../types';

interface Props {
  item: IncentiveType;
}

const Container = styled.div`
  display: flex;
  min-height: 40px;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  font-family: var(--font-regular);
  font-size: 12px;
  color: var(--text-secondary);

  &:hover {
    color: var(--link-blue);
  }
`;

const IncentivesTableIncentivesCommunities = ({ item }: Props) => {
  const communities: { [index: number]: string } = {};

  item.units?.forEach((item: UnitType) => {
    if (!communities[item.community.id]) {
      communities[item.community.id] = item.community?.comunity_name;
    }
  });

  return (
    <Container>
      {Object.entries(communities)
        .sort((a, b) => {
          return a[1].localeCompare(b[1]);
        })
        .map(([id, name]) => (
          <StyledLink to={`/community/${id}`} target="_blank" key={name}>
            {name}
          </StyledLink>
        ))}
    </Container>
  );
};

export default IncentivesTableIncentivesCommunities;
