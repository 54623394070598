import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Column } from '@ant-design/plots';

import DashboardReportsChartHandler from './DashboardReportsChartHandler';
import {
  COLOR_DEFAULT,
  COLOR_OPACITY,
  COLOR_PALETTES,
  COLUMN_MARGIN_RATIO,
  COLUMN_WIDTH,
  LABEL_FONT_SIZE,
  LEGEND_FONT_FAMILY,
  LEGEND_FONT_SIZE,
  LEGEND_MAX_ROWS,
} from '../constants';
import { CommunityLivingDataType } from '../datatypes';
import { generatePastelColor } from '../utils';

interface Props {
  title: string;
  data: CommunityLivingDataType[];
  xField: string;
  yField: string;
  seriesField: string;
  colors?: string[];
  grouped?: boolean;
  labelFormatter?: (item: any) => any;
  tooltipFormatter?: (item: any) => any;
  tooltipSorter?: (item: any) => any;
  tooltipShowTitle?: boolean;
}

export const DashboardColumnChart = ({
  title,
  data,
  xField,
  yField,
  seriesField,
  grouped = false,
  colors = COLOR_PALETTES.BRIGHT,
  labelFormatter = undefined,
  tooltipFormatter = undefined,
  tooltipSorter = undefined,
  tooltipShowTitle = true,
}: Props) => {
  const [params] = useSearchParams();
  const communityId = params.get('id') as string;

  const ref = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const communities = new Set(data.map((community) => community.community_name));
  const categories = new Set(data.map((community) => community.apartment_type));
  const selectedCommunity = data.find(
    (community: CommunityLivingDataType) => community.community_id == parseInt(communityId)
  );

  // Track window size to determine necessity of showing scrollbar
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const availableColors = [...colors];
  const colorPalette: { [id: string]: string | undefined } = {};

  // Pick color from the palette wheel for each series (Community)
  const colorCallback = ({ community_name }: any) => {
    if (community_name === selectedCommunity?.community_name) {
      return COLOR_DEFAULT;
    }

    const communityHasColor = community_name in colorPalette;
    if (!communityHasColor) {
      const newColor = availableColors.shift();
      colorPalette[community_name] = newColor ?? generatePastelColor();
    }

    const seriesColor = colorPalette[community_name] || '';
    return `${seriesColor}${COLOR_OPACITY}`;
  };

  const scrollbarCategorySize = communities.size * (COLUMN_WIDTH.MEDIUM * (1 + COLUMN_MARGIN_RATIO));
  const showScrollbar = categories.size * scrollbarCategorySize > windowWidth;

  const config = {
    data,
    xField,
    yField,
    seriesField,
    isGroup: grouped,
    appendPadding: 20,
    marginRatio: COLUMN_MARGIN_RATIO,
    theme: {
      styleSheet: {
        backgroundColor: '#f9f9f9' as const,
      },
    },
    legend: {
      flipPage: false,
      maxRow: LEGEND_MAX_ROWS,
      title: {
        text: title,
        style: {
          fontSize: LEGEND_FONT_SIZE,
          fontFamily: LEGEND_FONT_FAMILY,
        },
      },
    },
    xAxis: {
      label: {
        autoRotate: true as boolean,
        autoEllipsis: true as boolean,
        layout: 'fixedOverlap' as const,
      },
    },
    color: colorCallback,
    label: {
      position: 'top' as const,
      style: {
        fontSize: LABEL_FONT_SIZE,
      },
      autoHide: false as boolean,
      autoRotate: true as boolean,
      formatter: labelFormatter,
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    tooltip: {
      customItems: tooltipSorter,
      formatter: tooltipFormatter,
      showTitle: tooltipShowTitle,
    },
    ...(showScrollbar && {
      scrollbar: {
        categorySize: scrollbarCategorySize,
        animate: false,
      },
    }),
  };

  return (
    <DashboardReportsChartHandler chartRef={ref}>
      <Column
        {...config}
        onReady={(plot) => {
          //@ts-ignore
          ref.current = plot;
        }}
      />
    </DashboardReportsChartHandler>
  );
};

export default DashboardColumnChart;
