import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { CheckOutlined, SearchOutlined } from '@ant-design/icons/lib';
import classNames from 'classnames';
import styled from 'styled-components';

import Button from './Button';
import Input from './Input';
import Select from './Select';

const StyledSelect = styled(Select)`
  && {
    height: 36px;
    .ant-select-selector {
      height: 36px;
      border-radius: 0;
      border: 1px solid var(--line-gray);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

const Label = styled.span`
  color: var(--text-primary);
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 10px 20px;
  gap: 10px;
`;

const Selection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SelectionActions = styled.div`
  display: flex;
  gap: 10px;

  span {
    color: var(--link-blue);
    font-size: 12px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--line-gray);
  max-height: 200px;
  overflow-y: scroll;
  padding: 10px 20px;
`;

const ItemsTitle = styled.span`
  font-family: var(--font-bold);
  margin-bottom: 15px;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;

  &.padded {
    padding-left: 24px;
  }
`;

const ColorDot = styled.span`
  color: ${(props) => props.color};
  margin-right: 8px;
`;

interface Props {
  disabled: boolean;
  placeholder?: string;
  options: any[];
  defaultValue: any[];
  onSelect: any;
}

const SelectAdvanced = ({ disabled, options, defaultValue, placeholder, onSelect }: Props) => {
  const [open, setOpen] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [nonSelectedItems, setNonSelectedItems] = useState<any[]>([]);

  const [filteredSelectedItems, setFilteredSelectedItems] = useState<any[]>([]);
  const [filteredNonSelectedItems, setFilteredNonSelectedItems] = useState<any[]>([]);

  useEffect(() => {
    setSelectedItems([...options.filter((item) => defaultValue.includes(item.value))]);
    setNonSelectedItems([...options.filter((item) => !defaultValue.includes(item.value))]);
  }, [open, options, defaultValue]);

  useEffect(() => {
    const filteredSelected = selectedItems.filter((item) =>
      item.label?.toLowerCase().includes(searchKeyword?.toLowerCase())
    );
    const filteredNonSelected = nonSelectedItems.filter((item) =>
      item.label?.toLowerCase().includes(searchKeyword?.toLowerCase())
    );

    setFilteredSelectedItems(filteredSelected);
    setFilteredNonSelectedItems(filteredNonSelected);
  }, [searchKeyword, selectedItems, nonSelectedItems]);

  const handleSelectItem = (selectedItem: any) => {
    setSelectedItems((prev) => [...prev, selectedItem]);
    setNonSelectedItems((prev) => [...prev.filter((item: any) => item.value !== selectedItem.value)]);
  };

  const handleDeselectItem = (selectedItem: any) => {
    setSelectedItems((prev) => [...prev.filter((item: any) => item.value !== selectedItem.value)]);
    setNonSelectedItems((prev) => [...options.filter((item: any) => !selectedItems.includes(item.value))]);
  };

  const handleSelectAll = () => {
    setSelectedItems([...options]);
    setNonSelectedItems([]);
  };

  const handleSelectNone = () => {
    setSelectedItems([]);
    setNonSelectedItems([...options]);
  };

  const handleSearch = (e: any) => {
    setSearchKeyword(e.target.value);
  };

  const handleApplySelection = () => {
    onSelect(selectedItems);
    setOpen(false);
  };

  return (
    <StyledSelect
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      disabled={disabled}
      placeholder={'None Selected'}
      value={defaultValue}
      labelRender={({ label, value }) => {
        let labelInfo = 'None Selected';

        if (selectedItems.length === 1) {
          labelInfo = selectedItems[0].label;
        } else if (selectedItems.length > 1 && selectedItems.length !== options?.length) {
          labelInfo = `${selectedItems.length} Selected`;
        } else if (selectedItems.length > 1 && selectedItems.length === options?.length) {
          labelInfo = 'All Selected';
        }

        return <Label>{labelInfo}</Label>;
      }}
      dropdownStyle={{
        padding: 0,
        borderRadius: 0,
        border: '1px solid var(--line-gray)',
        minWidth: 370,
      }}
      dropdownRender={(menu) => {
        return (
          <Container>
            <Controls>
              <Selection>
                <SelectionActions>
                  <span onClick={handleSelectAll} className={classNames({ disabled: nonSelectedItems.length === 0 })}>
                    Select All
                  </span>
                  <span onClick={handleSelectNone} className={classNames({ disabled: selectedItems.length === 0 })}>
                    Select None
                  </span>
                </SelectionActions>
                <Button type={'primary'} onClick={handleApplySelection} style={{ width: 96, height: 36 }}>
                  Apply
                </Button>
              </Selection>
              {options?.length > 6 && (
                <Input
                  placeholder={`Search ${placeholder?.toLowerCase() ?? ''}`}
                  prefix={<SearchOutlined />}
                  onChange={handleSearch}
                />
              )}
            </Controls>
            {filteredSelectedItems.length > 0 && (
              <DropdownItemsList
                title={'SELECTED'}
                items={filteredSelectedItems}
                icon={<CheckOutlined size={18} style={{ color: 'var(--positive-green)' }} />}
                onSelect={handleDeselectItem}
              />
            )}
            {filteredNonSelectedItems.length > 0 && (
              <DropdownItemsList title={'NOT SELECTED'} items={filteredNonSelectedItems} onSelect={handleSelectItem} />
            )}
          </Container>
        );
      }}
    />
  );
};

interface PropsList {
  title: string;
  items: any[];
  icon?: ReactNode;
  onSelect: any;
}

const DropdownItemsList = ({ title, items, icon, onSelect }: PropsList) => (
  <Items>
    <ItemsTitle>{title}</ItemsTitle>
    <ItemsList>
      {items.map((item) => (
        <Item key={item.value} className={classNames({ padded: !icon })} onClick={() => onSelect(item)}>
          {icon}
          <div>
            {item?.color && <ColorDot color={item.color}>●</ColorDot>}
            <span>{item.label}</span>
          </div>
        </Item>
      ))}
    </ItemsList>
  </Items>
);

export default SelectAdvanced;
