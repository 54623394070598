import { WIDGET_POSTFIX, WIDGET_TYPES, WIDGET_WITHOUT_PREFIX } from '../constants';

export const valueFormatter = (value: any, type: number) => {
  const widgetFixedPositionsMapping = {
    [WIDGET_TYPES.RATING_GRAPH]: 1,
    [WIDGET_TYPES.RATING]: 1,
    [WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE]: 2,
    [WIDGET_TYPES.MARKET_RATE_SQUARE_FOOTAGE_GRAPH]: 2,
    [WIDGET_TYPES.SWOT_SCORE]: 2,
  };

  const prefix = WIDGET_WITHOUT_PREFIX.includes(type) ? '' : '$';
  const postfix = WIDGET_POSTFIX[type] ?? '';

  const fixedPositions = widgetFixedPositionsMapping[type] !== undefined ? widgetFixedPositionsMapping[type] : 0;

  return value ? `${prefix}${value.toFixed(fixedPositions)}${postfix}` : '';
};
