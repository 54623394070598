import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button } from 'antd';
import axios from 'axios';
import styled from 'styled-components';

import Notification from 'components/Notification/Notification';
import { processCommunitiesSettings } from 'utils/data/communities';

import CommunitiesList from './CommunitiesList';
import { SETTINGS_PAGE_INFO } from './constants';
import { useAllCommunitiesSelectedCheckbox, useRemoveSelectedCommunities } from './customHooks';
import { SettingsContext } from './SettingsContext';
import SettingsHeader from './SettingsHeader';

const Container = styled.div`
  padding-bottom: 30px;
  width: 100%;
  background-color: white;
`;

const StyledEditButton = styled(Button)`
  background-color: var(--button-blue);
  border-color: var(--button-blue);
  padding: 10px 30px 10px 30px;
  height: 40px;
  font-family: var(--font-regular);
  border-radius: 4px;

  &:hover {
    background-color: #2051f2 !important;
  }
`;

type Props = {
  filterOptions: { value: number; label: string }[];
  getItemStatusComponent: (item: any) => React.ReactNode;
  filterCommunitiesByStatus: (data: any[], status: number) => any[];
  showCommunitiesEditModal: (items: any[]) => void;
  communities: any[];
  setCommunities: (communities: any[]) => void;
  selectPopupWidth?: number;
  isMultipleSelection: boolean;
  setMultipleSelection: (value: boolean) => void;
  settingsPageType: string;
};

const SettingsPage = ({
  filterOptions,
  getItemStatusComponent,
  filterCommunitiesByStatus,
  showCommunitiesEditModal,
  communities,
  setCommunities,
  selectPopupWidth,
  isMultipleSelection,
  setMultipleSelection,
  settingsPageType,
}: Props) => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [communitiesTotalCount, setCommunitiesTotalCount] = useState(0);
  const [status, setStatus] = useState(0);
  const [selectedCommunitiesIds, setSelectedCommunitiesIds] = useState<any>([]);
  const [allCommunitiesSelected, setAllCommunitiesSelected] = useState(false);
  const { managementCompanyId } = useParams();

  useAllCommunitiesSelectedCheckbox(
    allCommunitiesSelected,
    selectedCommunitiesIds.length,
    communities.length,
    setAllCommunitiesSelected
  );
  useRemoveSelectedCommunities(isMultipleSelection, selectedCommunitiesIds.length, setSelectedCommunitiesIds);

  useQuery({
    queryKey: [managementCompanyId, searchTerm, page],
    queryFn: () =>
      axios.get(`/communities/management-community-list/${managementCompanyId}/?page=${page}&search=${searchTerm}`),
    onSuccess: (response) => {
      setCommunities(processCommunitiesSettings(response.data.results));
      setCommunitiesTotalCount(response.data.count);
    },
    refetchOnWindowFocus: false,
  });

  const onShowCommunitiesEditModal = useCallback(() => {
    const communitiesToModify = communities.filter((c: any) => selectedCommunitiesIds.includes(c.id));
    showCommunitiesEditModal(communitiesToModify);
  }, [communities, selectedCommunitiesIds]);

  const toggleOneCommunity = useCallback(
    (communityId: number) => {
      const isSelected = selectedCommunitiesIds.some((id: number) => id === communityId);
      if (isSelected) {
        const filteredCommunities = selectedCommunitiesIds.filter((id: number) => id !== communityId);
        setSelectedCommunitiesIds(filteredCommunities);
      } else {
        setSelectedCommunitiesIds([...selectedCommunitiesIds, communityId]);
      }
    },
    [selectedCommunitiesIds, setSelectedCommunitiesIds]
  );

  const toggleAllCommunities = useCallback(() => {
    if (selectedCommunitiesIds.length === communities.length) {
      // unselect all
      setSelectedCommunitiesIds([]);
      setAllCommunitiesSelected(false);
    } else {
      // select all
      setSelectedCommunitiesIds(communities.map((c) => c.id));
      setAllCommunitiesSelected(true);
    }
  }, [selectedCommunitiesIds, communities, setSelectedCommunitiesIds, setAllCommunitiesSelected]);

  return (
    <SettingsContext.Provider
      value={{
        filterOptions: [
          {
            value: 0,
            label: 'Any status',
          },
          ...Object.values(filterOptions),
        ],
        isMultipleSelection,
        page,
        searchTerm,
        status,
        communities,
        communitiesTotalCount,
        selectedCommunitiesIds,
        allCommunitiesSelected,
        headerContent: SETTINGS_PAGE_INFO[settingsPageType],
        getItemStatusComponent,
        toggleAllCommunities,
        toggleOneCommunity,
      }}
    >
      <Container>
        <Notification
          totalCount={communities.length}
          selectedCount={selectedCommunitiesIds.length}
          singularLabel={'community'}
          multipleLabel={'communities'}
          onClose={() => {
            setSelectedCommunitiesIds([]);
            setMultipleSelection(false);
          }}
          actions={
            <StyledEditButton type="primary" onClick={onShowCommunitiesEditModal}>
              Edit Multiple
            </StyledEditButton>
          }
        />
        <SettingsHeader />
        <CommunitiesList
          filterCommunitiesByStatus={filterCommunitiesByStatus}
          selectPopupWidth={selectPopupWidth}
          toggleMultipleSelection={() => setMultipleSelection(!isMultipleSelection)}
          setPage={setPage}
          setSearchTerm={setSearchTerm}
          setStatus={setStatus}
          modifyCommunity={(item: any) => {
            if (!isMultipleSelection) {
              showCommunitiesEditModal([item]);
            }
          }}
        />
      </Container>
    </SettingsContext.Provider>
  );
};

export default SettingsPage;
