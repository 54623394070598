import React from 'react';

import { SelectProps } from 'antd/lib';
import styled from 'styled-components';

import Select from 'components/lib/Select';

const StyledSelect = styled(Select)`
  width: 320px;
  margin-right: 30px;
  &&&.ant-select {
    height: 36px;
  }

  &&& .ant-select-selector {
    border-radius: 0;
  }

  .ant-select-selection-item {
    font-size: 14px;
  }
`;

const ShopRequestStyledSelect = (props: SelectProps) => {
  return <StyledSelect {...props} />;
};

export default ShopRequestStyledSelect;
