import React from 'react';

import FeesFormCareFees from './FeesFormCareFees';
import FeesFormCommunityFees from './FeesFormCommunityFees';

const FeesFormEdit = () => (
  <>
    <FeesFormCommunityFees />
    <FeesFormCareFees />
  </>
);

export default FeesFormEdit;
