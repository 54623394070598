import React, { ChangeEvent, useEffect, useState } from 'react';

import { Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import styled from 'styled-components';

import { SWOT_CATEGORIES } from 'common/Widgets/constants';
import TextArea from 'components/lib/TextArea';

import { useEditSwotDataModalContext } from './EditSwotDataModalContext';

const StyledNoteContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledTitle = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-primary);
`;

const StyledTextArea = styled(TextArea)`
  width: 236px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &.ant-input {
    height: 220px;
  }
`;

const StyledNote = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 236px;
  margin-top: 10px;

  max-height: 220px;
  height: 220px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledSubtitle = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-secondary);
  font-size: 12px;

  a {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 12px;
  }
`;

const SwotNote = () => {
  const { temporarySwotState, stepNumber, setTemporarySwotState } = useEditSwotDataModalContext();
  const [manualEditMode, setManualEditMode] = useState(false);
  const swotCategory = SWOT_CATEGORIES.find((el) => el.value === stepNumber);
  const note = temporarySwotState[swotCategory?.noteField || ''] as string;

  const isEditMode = manualEditMode || !note?.length;

  useEffect(() => {
    setManualEditMode(false);
  }, [setManualEditMode, stepNumber]);

  if (!swotCategory) {
    return null;
  }

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setManualEditMode(true);
    setTemporarySwotState({
      ...temporarySwotState,
      [swotCategory.noteField]: event.target.value,
    });
  };

  return (
    <StyledNoteContainer>
      <StyledTitle>Note {isEditMode && '(Optional)'}</StyledTitle>
      {!isEditMode && (
        <StyledSubtitle>
          <Link onClick={() => setManualEditMode(true)}>Edit note</Link>
        </StyledSubtitle>
      )}
      {isEditMode ? (
        <StyledTextArea
          placeholder="Enter an optional note for this step in the comparison."
          value={note}
          onChange={onChange}
        />
      ) : (
        <StyledNote>{note}</StyledNote>
      )}
    </StyledNoteContainer>
  );
};

export default SwotNote;
