import React from 'react';

import styled from 'styled-components';

import { useIncentivesProposalModalContext } from './IncentivesProposalModalContext';
import { ContentContainer } from './IncentivesProposalModalInfo';
import { IncentiveProposalPricingType } from './types';
import {
  INCENTIVES_FEES_TYPE,
  INCENTIVES_FEES_TYPE_LABELS,
  INCENTIVES_SAVINGS_DISCOUNT_TYPE,
} from '../create/constants';

const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IncentivesProposalModalInfoRates = () => {
  const { rates } = useIncentivesProposalModalContext();

  return (
    <ContentContainer>
      <div style={{ fontFamily: 'var(--font-bold)' }}>Category</div>
      {rates.map((item: IncentiveProposalPricingType) => {
        const isCommonFeeType = item.type !== INCENTIVES_FEES_TYPE.CUSTOM;
        return (
          <Row key={`${item.type}-${item.name}`}>
            <span>
              {item.type === INCENTIVES_FEES_TYPE.MONTHLY_RENT && item.discount_duration_type && (
                <span style={{ color: 'var(--text-secondary)' }}>Monthly rent</span>
              )}
              {item.type !== INCENTIVES_FEES_TYPE.MONTHLY_RENT && (
                <span style={{ color: 'var(--text-secondary)' }}>
                  {item.type && isCommonFeeType && <span>{INCENTIVES_FEES_TYPE_LABELS[item.type]}</span>}
                  {item.type && !isCommonFeeType && <span>{item.name}</span>}
                </span>
              )}
            </span>
            <span>
              {(isCommonFeeType || (!isCommonFeeType && item.amount)) && (
                <span
                  style={{
                    color: 'var(--text-secondary)',
                    textDecoration: item.discount_type ? 'line-through' : 'none',
                  }}
                >
                  ${item.amount}
                </span>
              )}
              {item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.AMOUNT && item.discount && item.amount && (
                <span style={{ color: 'var(--positive-green)', marginLeft: 2 }}>
                  ${(item.amount - item.discount).toLocaleString()}
                </span>
              )}
              {item.discount_type === INCENTIVES_SAVINGS_DISCOUNT_TYPE.PERCENTAGE && item.discount && item.amount && (
                <span style={{ color: 'var(--positive-green)', marginLeft: 2 }}>
                  ${(item.amount - (item.amount * item.discount) / 100).toLocaleString()}
                </span>
              )}
            </span>
          </Row>
        );
      })}
    </ContentContainer>
  );
};

export default IncentivesProposalModalInfoRates;
