import React, { createContext, useContext } from 'react';

type FeesContent = {
  communityId: number | null;
  showMessage: (type: 'success' | 'error', message: string) => void;
};

export const FeesContext = createContext<FeesContent>({
  communityId: null,
  showMessage: () => {
    // Does nothing
  },
});
export const useFeesContext = () => useContext(FeesContext);
