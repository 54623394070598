import React, { useEffect, useState } from 'react';

import { MenuProps, Space } from 'antd';
import styled from 'styled-components';

import DownCaretIcon from '../../../common/Icons/DownCaretIcon';
import { getFilename } from '../../../common/Widgets/GraphWidget/utils';
import { STATUS } from '../../../constants';
import { useAppSelector } from '../../../store/hook';
import { exportElementAsImage, exportElementAsPDF } from '../../../utils/helpers/export';
import { useRootContext } from '../../layout/RootContext';
import Button from '../../lib/Button';
import Dropdown from '../../lib/Dropdown';
import { useCommunityViewContext } from '../CommunityViewContext';
import ShareViaEmailModal from '../share/ShareViaEmailModal';

const Container = styled.div``;

const HeaderViewActionsShare = () => {
  const { showMessage } = useRootContext();
  const { contentRef, view, preview } = useCommunityViewContext();

  const { selectedCommunity } = useAppSelector((state) => state.global);

  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [filename, setFilename] = useState<string>('');
  const [loading, setLoading] = useState<string>(STATUS.IDLE);

  useEffect(() => {
    setFilename(getFilename(selectedCommunity?.name || 'Community', view?.name));
  }, [selectedCommunity, view]);

  const handleDownloadPDF = async () => {
    setLoading(STATUS.LOADING);
    try {
      await exportElementAsPDF(contentRef, filename, { padding: 0 });
      showMessage('success', 'View PDF download completed!');
      setLoading(STATUS.LOADED);
    } catch (e) {
      showMessage('error', 'Trouble downloading view PDF.');
      setLoading(STATUS.FAILURE);
    }
  };

  const handleDownloadImage = async () => {
    setLoading(STATUS.LOADING);
    try {
      await exportElementAsImage(contentRef, filename);
      showMessage('success', 'View image download completed!');
      setLoading(STATUS.LOADED);
    } catch (e) {
      showMessage('error', 'Trouble downloading view image');
      setLoading(STATUS.FAILURE);
    }
  };

  const items: MenuProps['items'] = [];
  if (!preview) {
    items.push({
      label: 'Share via Email',
      key: 'shareEmail',
      onClick: () => setOpenShareModal(true),
    });
  }

  items.push(
    {
      label: 'Download as PDF',
      key: 'downloadPdf',
      onClick: () => handleDownloadPDF(),
    },
    {
      label: 'Download as Image',
      key: 'downloadImage',
      onClick: () => handleDownloadImage(),
    }
  );

  return (
    <Container>
      {!preview && <ShareViaEmailModal open={openShareModal} onClose={() => setOpenShareModal(false)} />}
      <Dropdown menu={{ items }}>
        <Button loading={loading === STATUS.LOADING}>
          <Space align="baseline">
            <span>Share</span>
            <DownCaretIcon />
          </Space>
        </Button>
      </Dropdown>
    </Container>
  );
};

export default HeaderViewActionsShare;
