import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import { CloseCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Modal, Popconfirm, Row } from 'antd';

import axios from 'config/axiosPrivate';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleUpdateAvatar } from 'store/slices/authSlice';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
}

const ChangeAvatarModal: React.FC<Props> = ({ open, handleClose, title = '' }) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [file, setFile] = useState<any>(null);
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [errMgs, setErrMgs] = useState('');
  const onUpload = (files: any) => {
    setErrMgs('');
    if (files.length > 0) {
      if (files[0].type.includes('image/')) {
        setFile(files[0]);
        const url = URL.createObjectURL(files[0]);
        setPreview(url);
      } else {
        setErrMgs('Please upload only image files');
      }
    }
  };
  const onFinish = (values: any) => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    // @ts-ignore
    formData.append('id', currentUser?.PAUser_Id);
    formData.append('avatar', file);
    formData.append('op', 'edited');
    axios
      .post('/communities/Save_UserAvatars/', formData)
      .then((res) => {
        dispatch(handleUpdateAvatar(res.data?.URL));
        setLoading(false);
        handleCloseModal();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeDisplay = () => {
    if (removeLoading) return;
    const formData = new FormData();
    // @ts-ignore
    formData.append('id', currentUser?.PAUser_Id);
    formData.append('op', 'delete');
    setRemoveLoading(true);
    axios
      .post('/communities/Save_UserAvatars/', formData)
      .then((res) => {
        dispatch(handleUpdateAvatar(''));
        setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRemoveLoading(false);
      });
  };

  const handleCloseModal = () => {
    setFile(null);
    handleClose();
  };

  return (
    <>
      <Modal title={title} centered open={open} onCancel={handleCloseModal} width={500} footer={false}>
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
          initialValues={{
            category: 'Other Image',
          }}
        >
          {errMgs && <p style={{ color: 'red' }}>{errMgs}</p>}
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              {file && (
                <div
                  key={file?.name}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#eee',
                    padding: '4px',
                    marginBottom: 5,
                    borderRadius: '5px',
                    position: 'relative',
                  }}
                >
                  <Image src={preview} preview={false} width={200} height={150} />
                  <Button
                    icon={<CloseCircleFilled />}
                    size="middle"
                    danger
                    className="p-button-link"
                    onClick={() => setFile(null)}
                    htmlType="button"
                  />
                </div>
              )}
              {!file && currentUser?.avatar && (
                <div
                  key="user_avatar"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#eee',
                    padding: '4px',
                    marginBottom: 5,
                    borderRadius: '5px',
                    position: 'relative',
                  }}
                >
                  <Image src={currentUser?.avatar} preview={false} width="auto" height={150} />
                  <Popconfirm
                    title="Delete Display Image"
                    description="Are you sure to delete display image?"
                    onConfirm={() => removeDisplay()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button icon={<CloseCircleFilled />} size="middle" danger className="p-button-link" />
                  </Popconfirm>
                </div>
              )}
            </Col>
            <Col xs={24}>
              <Dropzone onDrop={(acceptedFiles) => onUpload(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps({ accept: 'image/*' })} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: '4px dotted #eee',
                          padding: 20,
                          borderRadius: 8,
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <UploadOutlined style={{ fontSize: '45px' }} />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ChangeAvatarModal;
