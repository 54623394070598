import React from 'react';
import { useParams } from 'react-router';

import CommunityLoad from './CommunityLoad';

const Community = () => {
  const { communityId } = useParams();

  return <CommunityLoad communityId={parseInt(communityId || '')} />;
};

export default Community;
