import React, { createContext, useContext } from 'react';

import { DEFAULT_INCENTIVE_PROPOSAL } from './constants';
import { IncentiveProposalPricingType, IncentiveProposalType } from './types';
import { IncentiveType } from '../types';

type IncentivesProposalModalContent = {
  rates: IncentiveProposalPricingType[];
  incentive?: IncentiveType;
  proposal: IncentiveProposalType;
  readOnly: boolean;
  setRates: (value: IncentiveProposalPricingType[]) => void;
  setIncentive: (value: IncentiveType | undefined) => void;
  setProposal: (value: IncentiveProposalType) => void;
  handleClose: () => void;
};

export const IncentivesProposalModalContext = createContext<IncentivesProposalModalContent>({
  rates: [],
  incentive: undefined,
  proposal: DEFAULT_INCENTIVE_PROPOSAL,
  readOnly: false,
  setRates: () => {
    // Do nothing
  },
  setIncentive: () => {
    // Do nothing
  },
  setProposal: () => {
    // Do nothing
  },
  handleClose: () => {
    // Do nothing
  },
});

export const useIncentivesProposalModalContext = () => useContext(IncentivesProposalModalContext);
