import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { INCENTIVE_STATUS_OPTIONS } from '../constants';
import { useIncentivesDataContext } from '../IncentivesDataContext';

const IncentivesFiltersIncentiveStatus = () => {
  const { filters, setFilters } = useIncentivesDataContext();

  const handleSelect = (selection: number[]) => {
    const values = selection.map((item: any) => item.value);
    setFilters({ ...filters, page: 1, incentiveStatus: values });
  };

  const options = INCENTIVE_STATUS_OPTIONS?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: Boolean(filters.incentiveStatus?.length) })}
      placeholder={'Incentive Status'}
      options={options}
      defaultValue={filters?.incentiveStatus ?? []}
      onSelect={handleSelect}
    />
  );
};

export default IncentivesFiltersIncentiveStatus;
