import axios from '../config/axiosPrivate';

export const getWidgets = async (communityId: number) => {
  const url = `/views/widget/${communityId}/list`;

  const response = await axios.get(url);
  return response.data;
};

export const getWidget = async (widgetId: number) => {
  const url = `/views/widget/${widgetId}`;

  const response = await axios.get(url);
  return response.data;
};

export const createWidget = async (payload: any) => {
  const url = `/views/widget`;

  const response = await axios.post(url, payload);
  return response.data;
};

export const updateWidget = async (widgetId: number, payload: any) => {
  const url = `/views/widget/${widgetId}`;

  const response = await axios.put(url, payload);
  return response.data;
};

export const updatePartialWidget = async (widgetId: number, payload: any) => {
  const url = `/views/widget/${widgetId}`;

  const response = await axios.patch(url, payload);
  return response.data;
};

export const removeWidget = async (widgetId: number) => {
  const url = `/views/widget/${widgetId}`;

  const response = await axios.delete(url);
  return response.data;
};
