export const REVIEW_TYPE = {
  GOOGLE: 1,
  APFM: 2,
  CARING: 3,
};

export const REVIEW_SOURCE = {
  [REVIEW_TYPE.GOOGLE]: 'Google',
  [REVIEW_TYPE.APFM]: 'A Place For Mom',
  [REVIEW_TYPE.CARING]: 'Caring',
};

export const REVIEW_REQUEST_STATUS = {
  INITIATED: 1,
  COMPLETED: 2,
  CANCELED: 3,
  FAILED: 4,
};

export const REVIEW_REQUEST_STATUS_TAGS = {
  [REVIEW_REQUEST_STATUS.INITIATED]: {
    color: 'blue',
    label: 'Running',
  },
  [REVIEW_REQUEST_STATUS.COMPLETED]: {
    color: 'green',
    label: 'Completed',
  },
  [REVIEW_REQUEST_STATUS.CANCELED]: {
    color: 'orange',
    label: 'Canceled',
  },
  [REVIEW_REQUEST_STATUS.FAILED]: {
    color: 'red',
    label: 'Failed',
  },
};

export const REVIEW_AVATAR_COLORS = [
  '#ffadad',
  '#ffd6a5',
  '#a0c4ff',
  '#bdb2ff',
  '#ffc6ff',
  '#ceb8a1',
  '#d598f0',
  '#d7a4b7',
  '#f78e88',
  '#8297af',
  '#c1cc89',
  '#8fbdf9',
  '#d5a2f6',
  '#eaafe9',
  '#a7a1b4',
  '#9fb98e',
  '#ae93e2',
  '#bcba93',
  '#cca280',
  '#e7bfd2',
];
