import React from 'react';

import styled from 'styled-components';

import { useIncentivesCreateModalContext } from './IncentivesCreateModalContext';
import TextArea from '../../lib/TextArea';

const StyledTextArea = styled(TextArea)`
  background-color: white;
`;

const IncentivesCreateOffersInfoDescription = () => {
  const { description, setDescription } = useIncentivesCreateModalContext();

  const handleChange = (e: any) => {
    const value = e.target.value;
    setDescription(value);
  };

  return (
    <StyledTextArea
      value={description}
      placeholder={'Enter incentive description (optional)'}
      rows={3}
      onChange={handleChange}
    />
  );
};

export default IncentivesCreateOffersInfoDescription;
