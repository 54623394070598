import React from 'react';

import styled from 'styled-components';

import { GOOGLE_MAP_MARKER_SIZE } from './constants';
import { COMMUNITY_TYPE } from '../../../constants';

interface Props {
  type: string;
}

const Container = styled.div<Props>`
  position: absolute;
  width: ${GOOGLE_MAP_MARKER_SIZE}px;
  height: ${GOOGLE_MAP_MARKER_SIZE}px;
  left: ${-GOOGLE_MAP_MARKER_SIZE / 2}px;
  top: ${-GOOGLE_MAP_MARKER_SIZE / 2}px;
  background-color: ${(props) =>
    `${props.type === COMMUNITY_TYPE.COMPETITOR ? 'var(--community-marker-orange)' : 'var(--community-marker-blue)'}`};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 2px #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
`;

const MapMarkerIcon = (props: Props) => {
  return <Container {...props} />;
};

export default MapMarkerIcon;
