import React from 'react';

import classNames from 'classnames';

import { WIDGET_FILTER_OPTIONS, WIDGET_FILTER_TYPES } from '../../../common/Widgets/constants';
import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';

const HeaderViewFiltersCareType = () => {
  const { viewFilters, setViewFilters } = useCommunityViewContext();

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, careTypes: values.map((item: any) => item.value) }));
  };

  const options = WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.CARE_TYPE].map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: viewFilters?.careTypes?.length > 0 })}
      placeholder={'Care Type'}
      options={options}
      defaultValue={viewFilters.careTypes}
      onSelect={handleSelect}
    />
  );
};

export default HeaderViewFiltersCareType;
