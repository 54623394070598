import React, { useCallback, useEffect, useRef, useState } from 'react';

import { BarConfig } from '@ant-design/plots';
import { Bar } from '@ant-design/plots/lib';
import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';
import { useAppSelector } from 'store/hook';
import { getDataInsertionBars } from 'utils/helpers/antdChart';
import { useClickableAntdBars } from 'utils/hooks/useClickableAntdBars';

import { INTERVAL_PADDING, MAX_BAR_WIDTH, MIN_BAR_WIDTH } from './constants';
import DataInsertionBars from './DataInsertionBars';
import EditDataModal from './EditDataModal';
import { CareFeeType, DataInsertionBar } from './types';
import { textStyle } from './utils';
import { WIDGET_TYPES, WIDGETS_WITH_ALLOWED_DATA_INSERTION } from '../constants';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const BarChart = (props: BarConfig) => {
  const chartRef = useRef<any>();
  const { type } = useWidgetContext();
  const { communityId } = useCommunityViewContext();
  const [addDataBarPositions, setAddDataBarPositions] = useState<DataInsertionBar[]>([]);
  const [showInsertDataModal, setShowInsertDataModal] = useState(false);
  const [selectedCompetitorId, setSelectedCompetitorId] = useState<number | null>(null);
  const feeTypes = useAppSelector((state) => state.global.FeeTypes);
  const livingTypes = useAppSelector((state) => state.global.livingtypes);

  const closeModal = useCallback(() => setShowInsertDataModal(false), [setShowInsertDataModal]);

  const showCareType = type === WIDGET_TYPES.CARE_FEES_GRAPH;

  const onReady = (plot: any) => {
    if (!WIDGETS_WITH_ALLOWED_DATA_INSERTION.includes(type)) {
      return;
    }
    const positions = getDataInsertionBars(plot, props);
    setAddDataBarPositions(positions);
  };

  const yAxisLabelFormatter = useCallback(
    (text: string, item: any, index: number) => {
      const care_fees = props.data[index].care_fees;
      let care_fee_text = '';
      care_fees.forEach((care_fee: CareFeeType) => {
        const fee_type = feeTypes.find((feeType) => feeType.id == care_fee.fee_type_id);
        const living_type = livingTypes.find((livingType) => livingType.id == care_fee.living_type_id);
        if (care_fee.levels_count > 0) {
          care_fee_text = care_fee_text + `${living_type?.abv}: Level (${care_fee.levels_count}), `;
        } else if (fee_type) {
          care_fee_text = care_fee_text + `${living_type?.abv}: ${fee_type.type}, `;
        }
      });
      if (care_fee_text) {
        return `${text} \n${care_fee_text.slice(0, -2)}`;
      }
      return text;
    },
    [feeTypes, props.data]
  );

  const yAxis = showCareType
    ? {
        ...props.yAxis,
        label: {
          style: (text: string) => textStyle(text, props.data),
          formatter: yAxisLabelFormatter,
        },
      }
    : props.yAxis;

  useEffect(() => {
    if (chartRef.current && props.data.length) {
      onReady(chartRef.current);
    }
  }, [props.data, chartRef.current]);

  const onBarClick = useCallback(
    (competitorId: number) => {
      setShowInsertDataModal(true);
      setSelectedCompetitorId(competitorId);
    },
    [setShowInsertDataModal, setSelectedCompetitorId]
  );

  useClickableAntdBars({
    chartRef,
    type,
    communityId,
    onBarClickCallback: onBarClick,
  });

  const filteredData = showCareType ? props.data.filter((_d) => _d.value > 0) : props.data;
  const barConfig = {
    yAxis,
    data: filteredData,
    minBarWidth: MIN_BAR_WIDTH,
    maxBarWidth: MAX_BAR_WIDTH,
    intervalPadding: INTERVAL_PADDING,
  };

  const expandedConfig = { ...props, ...barConfig };

  return (
    <Container>
      <Bar onReady={onReady} chartRef={chartRef} {...expandedConfig} />
      <DataInsertionBars positions={addDataBarPositions} data={props.data} />
      {showInsertDataModal && (
        <EditDataModal
          onClose={closeModal}
          open={showInsertDataModal}
          competitorId={selectedCompetitorId}
          widgetType={type}
        />
      )}
    </Container>
  );
};

export default BarChart;
