import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { EXPORTABLE_HEADER_CLASS } from './constants';

const Container = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  z-index: -1;
  flex-direction: column;
  padding-bottom: 80px;
`;

interface Props {
  children: ReactNode;
}

const ExportableContentHeader = ({ children }: Props) => (
  <Container className={EXPORTABLE_HEADER_CLASS}>{children}</Container>
);

export default ExportableContentHeader;
