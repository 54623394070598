import React from 'react';

import styled from 'styled-components';

import SelectAdvancedFilter from 'components/lib/SelectAdvancedFilter';

import { useShopRequestsContext } from '../ShopRequestsContext';
import { useShopRequestsDataContext } from '../ShopRequestsDataContext';

const StyledFilter = styled(SelectAdvancedFilter)`
  margin-right: 5px;
`;

const ShopRequestsCompanyFilter = () => {
  const { companies } = useShopRequestsContext();
  const { filters, setFilters } = useShopRequestsDataContext();

  const onChange = (values: any[]) => {
    setFilters({ ...filters, companyIds: values.map((item: any) => item.value) });
  };

  return (
    <StyledFilter
      options={companies?.map((company) => ({ value: company.id, label: company.name })) ?? []}
      defaultValue={filters.companyIds ?? []}
      onSelect={onChange}
      placeholder="Company"
    />
  );
};

export default ShopRequestsCompanyFilter;
