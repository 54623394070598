import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { fetchData } from 'apis/DataAPI';
import { fetchSwotCategoriesScores } from 'apis/SwotAPI';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import SwotPerformanceScatterGraph from './SwotPerformanceScatterGraph';
import { SWOT_CATEGORIES } from '../constants';
import { SwotCategoryScoresInbound } from '../types';
import NoDataInfo from '../Widget/NoDataContent';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

export const SwotPerformanceWidgetContent = () => {
  const { communityId } = useCommunityViewContext();
  const { type, filters, rangeType } = useWidgetContext();
  const { time, competitors, careTypes, roomTypes, careLevels, reviewSources, reviewCategories, swotCategories } =
    filters;
  const [scores, setScores] = useState([]);

  const { data: swotAverages } = useQuery({
    queryKey: ['swot-performance', type, communityId, filters],
    queryFn: () =>
      fetchData(communityId, type, rangeType, {
        time,
        competitors,
        care_types: careTypes,
        room_types: roomTypes,
        care_levels: careLevels,
        review_sources: reviewSources,
        review_categories: reviewCategories,
        swot_categories: swotCategories,
      }),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const { data: swotCategoriesScores } = useQuery({
    queryKey: ['swot-categories-scores', communityId],
    queryFn: () => fetchSwotCategoriesScores(communityId),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  useEffect(() => {
    if (swotCategoriesScores?.length && swotAverages?.length) {
      const swotAveragesItems = swotAverages[0];
      const preparedScores = swotCategoriesScores.map((el: SwotCategoryScoresInbound) => {
        const categoryScore = Number.parseFloat(el.category_score);
        const categoryId = el.category_id;
        const swotCategory = SWOT_CATEGORIES.find((el) => el.value === categoryId);
        const score = Number.parseFloat(swotAveragesItems[`${swotCategory?.id}_avg`]);
        return {
          categoryScore,
          score,
          categoryName: swotCategory?.label,
          ['Score']: score,
          ['Category Score']: categoryScore,
        };
      });

      setScores(preparedScores);
    }
  }, [swotCategoriesScores, swotAverages]);

  return (
    <Container>
      {scores?.length > 0 && <SwotPerformanceScatterGraph data={scores} />}
      {scores?.length === 0 && <NoDataInfo>No Data Available</NoDataInfo>}
    </Container>
  );
};

export default SwotPerformanceWidgetContent;
