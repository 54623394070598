import React from 'react';

import styled from 'styled-components';

import { useSwotCompetitorAnalysisContext } from './SwotCompetitorAnalysisContext';
import Input from '../../../lib/Input';
import Radio from '../../../lib/Radio';
import TextArea from '../../../lib/TextArea';

const Container = styled.div`
  height: 230px;
  padding-right: 10px;
  overflow-y: scroll;
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  padding-left: 24px;
  margin-bottom: 20px;
  & > :first-child {
    margin-bottom: 10px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const Title = styled.div`
  font-size: 14px;
  font-family: var(--font-bold);
  color: var(--text-primary);
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;

const options = [
  {
    key: 'editData',
    title: 'Edit Data',
    subTitle: 'Enter up-to-date competitor analysis data yourself.',
  },
  {
    key: 'inviteToEdit',
    title: 'Invite to Edit',
    subTitle: 'Email a link for the recipient to enter competitor analysis data.',
  },
  {
    key: 'inviteToView',
    title: 'Invite to View',
    subTitle: 'Email a link allowing the recipient to view, but not edit, competitor analysis data.',
  },
];

const SwotCompetitorAnalysisActionOptionsList = () => {
  const { selectedAction, setSelectedAction } = useSwotCompetitorAnalysisContext();

  return (
    <Container className="mini-scroll">
      {options.map((option) => (
        <div key={option.key}>
          <OptionContainer>
            <Radio checked={selectedAction == option.key} onClick={() => setSelectedAction(option.key)} />
            <TitleContainer>
              <Title>{option.title}</Title>
              <SubTitle>{option.subTitle}</SubTitle>
            </TitleContainer>
          </OptionContainer>
          {selectedAction == option.key && option.key != 'editData' && (
            <InputContainer>
              <Input placeholder="Enter email address" />
              <TextArea placeholder="Enter a note (optional)" />
            </InputContainer>
          )}
        </div>
      ))}
    </Container>
  );
};

export default SwotCompetitorAnalysisActionOptionsList;
