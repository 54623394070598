import axios from '../config/axiosPrivate';

export const getCategories = async () => {
  const url = `/reviews/categories`;

  const response = await axios.get(url);
  return response.data;
};

export const initiateReviewsRequest = async (communities: number[]) => {
  const url = `/reviews/request`;

  const response = await axios.post(url, { communities });
  return response.data;
};

export const getCommunityReviews = async (communityId: number, params: any = {}) => {
  const url = `/reviews/${communityId}`;

  const response = await axios.get(url, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });
  return response.data;
};

export const deleteCommunityReviews = async (communityId: number) => {
  const url = `/reviews/${communityId}/delete`;

  const response = await axios.delete(url);
  return response.data;
};

export const getCommunityReviewsRequests = async (communityId: number, params: any) => {
  const url = `/reviews/request/list/${communityId}`;

  const response = await axios.get(url, { params });
  return response.data;
};

export const updateCommunityReviewsRequest = async (requestId: number, data: any) => {
  const url = `/reviews/request/${requestId}`;

  const response = await axios.patch(url, data);
  return response.data;
};

export const getReviewsMetrics = async (params: any) => {
  const response = await axios.get(`/reviews/metrics`, { params });
  return response.data;
};
