import React from 'react';

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from '../../../store/hook';
import { useIncentivesContext } from '../IncentivesContext';

const StyledBreadcrumb = styled(Breadcrumb)`
  span {
    color: var(--text-primary);

    &.fixed {
      color: var(--text-secondary);
    }
  }
`;

const IncentivesHeaderBreadcrumbs = () => {
  const { selectedCompany } = useAppSelector((state) => state.global);
  const { managementCompany } = useIncentivesContext();

  return (
    <StyledBreadcrumb
      items={[
        {
          title: managementCompany ? managementCompany?.management_company : selectedCompany?.name,
        },
        {
          className: 'fixed',
          title: managementCompany ? 'Management Company' : 'Company',
        },
      ]}
      separator="·"
    />
  );
};

export default IncentivesHeaderBreadcrumbs;
