import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { FILE_EXTENSION, PDF_IMAGE_PADDING } from '../../components/Dashboard/constants';

type PdfExportOrientationType = {
  PORTRAIT: 'portrait';
  LANDSCAPE: 'landscape';
};

export const PDF_EXPORT_ORIENTATION: PdfExportOrientationType = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

export const exportElementAsPDF = async (ref: any, filename: string, options: { padding: number }) => {
  if (!ref?.current) return;

  const base64data = await getElementAsImage(ref);

  const image = new Image();
  image.src = base64data;
  image.onload = () => {
    const imageHeight = image.height + PDF_IMAGE_PADDING;
    const imageWidth = image.width + PDF_IMAGE_PADDING;

    const orientation = imageWidth > imageHeight ? PDF_EXPORT_ORIENTATION.LANDSCAPE : PDF_EXPORT_ORIENTATION.PORTRAIT;

    const doc = new jsPDF({
      orientation: orientation,
      format: [imageHeight, imageWidth],
      unit: 'px',
      hotfixes: ['px_scaling'],
      compress: true,
    });

    doc.addImage(image, FILE_EXTENSION.PNG, options.padding, options.padding, 0, 0, undefined, 'FAST');
    doc.save(`${filename}.pdf`);
  };
};

export const getElementAsImage = async (ref: any) => {
  const canvas = await html2canvas(ref.current, { scale: 1.5, allowTaint: true, useCORS: true });
  return canvas.toDataURL('image/png', 0.1);
};

export const exportElementAsImage = async (ref: any, filename: string) => {
  const data = await getElementAsImage(ref);
  const link = document.createElement('a');

  link.href = data;
  link.download = `${filename}.png`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
