import React from 'react';

import styled from 'styled-components';

import HeaderUserActions from './HeaderUserActions';
import HeaderUserNotifications from './HeaderUserNotifications';
import HeaderUserApplicationSwitch from '../HeaderInitial/HeaderUserApplicationSwitch';

const Container = styled.div`
  display: flex;
  justify-content: end;
  gap: 30px;
  padding-right: 10px;
`;

const HeaderUser = () => (
  <Container>
    <HeaderUserApplicationSwitch />
    <HeaderUserNotifications />
    <HeaderUserActions />
  </Container>
);

export default HeaderUser;
