import React from 'react';
import { useNavigate } from 'react-router-dom';

import { List, Typography } from 'antd';
import styled from 'styled-components';

import SettingsIcon from './SettingsIcon';
import { SettingsListItem as SettingsListItemType } from './types';

const StyledListItem = styled(List.Item)`
  background-color: white;
  cursor: pointer;
`;

type Props = {
  item: SettingsListItemType;
};

const SettingsListItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const handleItemClick = () => {
    navigate(item.url);
  };

  return (
    <StyledListItem key={item.title} style={{ padding: '12px 10px' }} tabIndex={0} onClick={handleItemClick}>
      <List.Item.Meta
        className="custom-list-item-meta"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        avatar={
          <SettingsIcon
            style={{ backgroundColor: item.icon.backgroundColor, borderRadius: '4px' }}
            size={30}
            src={<img src={item.icon.url} style={{ width: '20px', height: '20px', borderRadius: '4px' }} />}
          />
        }
        title={
          <Typography.Text strong style={{ marginBottom: 0 }}>
            {item.title}
          </Typography.Text>
        }
        description={
          <Typography.Text style={{ color: '#6d6d6d', fontSize: '12px' }}>{item.description}</Typography.Text>
        }
      />
      <img src="/icons/chevron-right.svg" style={{ width: '24px', height: '24px' }} />
    </StyledListItem>
  );
};

export default SettingsListItem;
