import React, { createContext, useContext } from 'react';

import { PublicationType } from './datatypes';

type PublicationContent = {
  publication: PublicationType;
  views: any[];
};

export const PublicationContext = createContext<PublicationContent>({
  publication: {
    community_id: 0,
    views: [],
  },
  views: [],
});

export const usePublicationContext = () => useContext(PublicationContext);
