import React, { useState } from 'react';

import { message, Typography } from 'antd';
import shortUUID from 'short-uuid';
import styled from 'styled-components';

import { CommunityReviewsContext } from './CommunityReviewsContext';
import CommunityReviewsList from './CommunityReviewsList';
import CommunityReviewsRequests from './CommunityReviewsRequests';

const Container = styled.div`
  padding: 20px 60px;
  width: 100%;
`;

const CommunityReviews = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [renderKey, setRenderKey] = useState(shortUUID.generate());

  const refreshRender = () => {
    setRenderKey(shortUUID.generate());
  };

  const showMessage = (type: 'success' | 'info' | 'error', message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  return (
    <CommunityReviewsContext.Provider value={{ refreshRender, showMessage }}>
      <Container>
        {contextHolder}
        <Typography.Title>Community Reviews</Typography.Title>
        <div key={renderKey}>
          <CommunityReviewsRequests />
          <CommunityReviewsList />
        </div>
      </Container>
    </CommunityReviewsContext.Provider>
  );
};

export default CommunityReviews;
