import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { GRAPH_WIDGET_TYPES, WIDGETS_WITH_NO_CONTROLS } from '../constants';
import { DATA_DISPLAY_TYPE, DATA_RANGE_TYPE } from '../Widget/constants';
import WidgetActions from '../Widget/WidgetActions';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-left: auto;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const RangeItem = styled.span`
  font-size: 12px;
  color: var(--text-secondary);
  cursor: pointer;

  &.active {
    color: var(--active-blue);
  }
`;

const DisplayItem = styled.div`
  cursor: pointer;

  img {
    cursor: pointer;
  }
`;

const GraphWidgetDisplayControls = () => {
  const { type, rangeType, displayType, setRangeType, setDisplayType } = useWidgetContext();
  const showGraphWidgetControls = GRAPH_WIDGET_TYPES.includes(type) && !WIDGETS_WITH_NO_CONTROLS.includes(type);

  return (
    <Container>
      {showGraphWidgetControls && (
        <>
          <ItemsContainer>
            <RangeItem
              className={classNames({ active: rangeType === DATA_RANGE_TYPE.NOW })}
              onClick={() => setRangeType(DATA_RANGE_TYPE.NOW)}
            >
              Now
            </RangeItem>
            <span>·</span>
            <RangeItem
              className={classNames({ active: rangeType === DATA_RANGE_TYPE.OVERTIME })}
              onClick={() => setRangeType(DATA_RANGE_TYPE.OVERTIME)}
            >
              Over Time
            </RangeItem>
          </ItemsContainer>
          <ItemsContainer>
            <DisplayItem onClick={() => setDisplayType(DATA_DISPLAY_TYPE.GRAPH)}>
              <img
                src={displayType === DATA_DISPLAY_TYPE.GRAPH ? '/icons/graph-data-blue.svg' : '/icons/graph-data.svg'}
                alt="Graph Display"
                style={{
                  width: 14,
                  height: 14,
                }}
              />
            </DisplayItem>
            <DisplayItem onClick={() => setDisplayType(DATA_DISPLAY_TYPE.TABLE)}>
              <img
                src={displayType === DATA_DISPLAY_TYPE.TABLE ? '/icons/table-data-blue.svg' : '/icons/table-data.svg'}
                alt="Table Display"
                style={{
                  width: 14,
                  height: 14,
                  color: '#007aff',
                }}
              />
            </DisplayItem>
          </ItemsContainer>
        </>
      )}
      <DisplayItem>
        <WidgetActions />
      </DisplayItem>
    </Container>
  );
};

export default GraphWidgetDisplayControls;
