import React from 'react';

import styled from 'styled-components';

import { isMysteryShopAdmin, isMysteryShopShopper } from 'helpers/permissions';
import { useAppSelector } from 'store/hook';

const PopoverContainer = styled.div`
  padding: 3px;
  display: flex;
  flex-flow: column;
  width: 200px;
  max-width: 400px;
  max-height: 300px;
  overflow-y: auto;
`;

const Title = styled.span`
  font-weight: 900;
`;
const EditButton = styled.span`
  color: var(--link-blue);
  cursor: pointer;
`;

const NoteText = styled.div`
  white-space: pre-wrap;
`;

type PopoverProps = {
  shopperNote?: string | null;
  adminNote?: string | null;
  shopperId?: number | null;
  editShopperNote: () => void;
  editAdminNote: () => void;
};

const PopoverContent = ({ shopperNote, adminNote, editShopperNote, editAdminNote, shopperId }: PopoverProps) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const isShopRequestSalesperson = currentUser && isMysteryShopShopper(currentUser);
  const allowShopperNoteEdit = isShopRequestSalesperson && currentUser?.PAUser_Id === shopperId;
  const isShopRequestAdmin = currentUser && isMysteryShopAdmin(currentUser);

  return (
    <PopoverContainer>
      {adminNote?.length && (
        <>
          <div>
            <Title>Note from Further</Title>
            {isShopRequestAdmin && (
              <>
                · <EditButton onClick={editAdminNote}>Edit</EditButton>
              </>
            )}
          </div>
          <NoteText>{adminNote}</NoteText>
        </>
      )}
      {shopperNote?.length && (
        <>
          <div style={{ marginTop: adminNote ? '10px' : undefined }}>
            <Title>Note from Shopper</Title>
            {allowShopperNoteEdit && (
              <>
                · <EditButton onClick={editShopperNote}>Edit</EditButton>
              </>
            )}
          </div>
          <NoteText>{shopperNote}</NoteText>
        </>
      )}
    </PopoverContainer>
  );
};

export default PopoverContent;
