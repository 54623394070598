import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 10px;
`;

const SidebarLogo = () => (
  <Container>
    <Link to="/home">
      <img src={'/logos/further-logo-text-light.svg'} />
    </Link>
  </Container>
);

export default SidebarLogo;
