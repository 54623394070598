import React from 'react';

import IncentivesTableUnitsUnitStatus from './IncentivesTableUnitsUnitStatus';
import { UnitType } from '../../types';

interface Props {
  record: UnitType;
}

const IncentivesTableUnitsUnit = ({ record }: Props) => (
  <span
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <span>{record?.number}</span>
    <span
      style={{
        fontSize: 12,
        color: 'var(--text-secondary)',
      }}
    >
      {record?.name && <span>{record.name} · </span>}
      {record?.square_feet && <span>{record?.square_feet?.toLocaleString()} sq ft</span>}
    </span>
    <IncentivesTableUnitsUnitStatus unit={record} />
  </span>
);

export default IncentivesTableUnitsUnit;
