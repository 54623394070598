import dayjs from 'dayjs';
import shortUUID from 'short-uuid';

import { OccupancyType } from './constants';
import { GLOBAL_DATE_FORMAT } from '../../../constants';

export const mapCommunityOccupancyResponseToData = (item: any) => {
  return {
    id: item?.id,
    key: shortUUID.generate(),
    apartmentType: item?.apartment_type,
    apartmentTypeId: item?.apartment_type_id,
    livingTypeId: item?.living_type_id,
    livingType: item?.living_type,
    totalApartmentsUnits: item?.total_apartments_units,
    freeApartmentsUnits: item?.free_apartments_units,
    occupancyPercentage: item?.pct_occupancy,
    createdBy: item?.created_by,
    createdAt: dayjs(new Date(item?.created_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    updatedBy: item?.updated_by,
    updatedAt: dayjs(new Date(item?.updated_at).toDateString(), { format: GLOBAL_DATE_FORMAT }),
    date: dayjs(new Date(item?.date).toDateString(), { format: GLOBAL_DATE_FORMAT }),
  };
};

export const mapCommunityOccupancyDataToRequest = (item: Partial<OccupancyType>) => {
  const vacantUnits = item?.freeApartmentsUnits;
  const totalUnits = item?.totalApartmentsUnits;
  const hasTotalGreaterVacant =
    totalUnits != undefined &&
    totalUnits > 0 &&
    vacantUnits != undefined &&
    vacantUnits >= 0 &&
    totalUnits >= vacantUnits;

  const occupancyPercentage = hasTotalGreaterVacant ? ((totalUnits - vacantUnits) / totalUnits) * 100 : null;

  return {
    apartment_type_id: item.apartmentTypeId,
    living_type_id: item.livingTypeId,
    total_apartments_units: item.totalApartmentsUnits,
    free_apartments_units: item.freeApartmentsUnits,
    pct_occupancy: occupancyPercentage?.toFixed(2),
    date: item?.date,
  };
};
