import React from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import { getFormattedDate } from 'helpers/dates';

import { useEditShopRequestModalContext } from './EditShopRequestModalContext';
import EditShopRequestModalShopper from './EditShopRequestModalShopper';
import EditShopRequestModalStatus from './EditShopRequestModalStatus';
import { REPEAT_LABELS } from '../constants';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  border-bottom: solid 1px var(--line-gray);
  padding: 0 30px 10px 30px;
  box-sizing: border-box;
`;

const Subtitle = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--text-secondary);
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled(Typography.Text)`
  color: var(--text-secondary);
  margin-right: 30px;
`;

const EditShopRequestModalHeader = () => {
  const { shopRequest } = useEditShopRequestModalContext();
  const requestFrom = shopRequest?.requestGroup?.company ? 'Company' : 'Further';
  const shopper = shopRequest?.shopper && `, via ${shopRequest.shopper.name}`;
  const companyName =
    shopRequest?.requestGroup?.company && `, on behalf of ${shopRequest?.requestGroup?.company?.name}`;

  return (
    <Container>
      <Subtitle>
        {REPEAT_LABELS[shopRequest!.repeat]} · Requested by {requestFrom}
        {shopper}
        {companyName}
      </Subtitle>
      <Row>
        <EditShopRequestModalShopper />
        <div>
          <Label>Added {getFormattedDate(shopRequest!.createdAt, 'MMMM DD')}</Label>
          <EditShopRequestModalStatus />
        </div>
      </Row>
    </Container>
  );
};

export default EditShopRequestModalHeader;
