import React, { createContext, useContext } from 'react';

type HeaderSelectorContent = {
  communityList: any[];
  setCommunityList: (value: any) => void;
};

export const HeaderSelectorContext = createContext<HeaderSelectorContent>({
  communityList: [],
  setCommunityList: () => {
    // Do nothing
  },
});

export const useHeaderSelectorContext = () => useContext(HeaderSelectorContext);
