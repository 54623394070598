import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';
import styled from 'styled-components';

import { useSelectorContext } from './SelectorContext';
import { STATUS } from '../../constants';
import { useAppDispatch } from '../../store/hook';
import {
  handleSetLoader,
  handleSetSelectedClient,
  handleSetSelectedCompany,
  handleSetSelectedManagementCompany,
  handleStoreUserCompanies,
} from '../../store/slices/globalSlice';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SelectorFooter = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.IDLE);
  const { client, company, managementCompany, setClient, setCompany, setManagementCompany } = useSelectorContext();

  const onReset = () => {
    setClient(undefined);
    setCompany(undefined);
    setManagementCompany(undefined);
  };

  const onView = () => {
    try {
      setStatus(STATUS.LOADING);
      const companies = company ? [{ value: company.id, label: company.name }] : [];

      dispatch(handleSetLoader(true));
      dispatch(handleSetLoader(false));
      dispatch(handleStoreUserCompanies(companies));

      dispatch(handleSetSelectedClient(client));
      dispatch(handleSetSelectedCompany(company));
      dispatch(handleSetSelectedManagementCompany(managementCompany));

      navigate('/overview');
      setStatus(STATUS.LOADED);
    } catch (err) {
      console.log(err);
      setStatus(STATUS.FAILURE);
    } finally {
      dispatch(handleSetLoader(false));
    }
  };

  const viewDisabled = status === STATUS.LOADING || !company;

  return (
    <Container>
      <Button type={'link' as const} onClick={onReset}>
        Reset
      </Button>
      <Button type={'primary' as const} disabled={viewDisabled} loading={status === STATUS.LOADING} onClick={onView}>
        View
      </Button>
    </Container>
  );
};

export default SelectorFooter;
