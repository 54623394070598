import axios from '../config/axiosPrivate';

export const getCommunityIncentives = async (communityId: number, params: any = {}) => {
  const url = `/pricingassistant/incentives/${communityId}`;

  const response = await axios.get(url, {
    params,
  });
  return response.data;
};
