import React from 'react';

import DashboardColumnChart from '../charts/DashboardColumnChart';
import { CommunityLivingDataType } from '../datatypes';

interface Props {
  data: CommunityLivingDataType[];
}

export const DashboardReportRentSquareFootageChart = ({ data }: Props) => {
  const tooltipFormatter = (item: any) => {
    return {
      name: item.community_name,
      value:
        item?.average_rent_square_footage_ratio >= 0 ? `$${item.average_rent_square_footage_ratio.toFixed(2)}` : '',
    };
  };

  const labelFormatter = (item: any) => {
    return item?.average_rent_square_footage_ratio >= 0 ? `$${item.average_rent_square_footage_ratio.toFixed(2)}` : '';
  };

  const tooltipSorter = (originalItems: any) => {
    return originalItems.sort(
      (a: any, b: any) => a.data.average_rent_square_footage_ratio - b.data.average_rent_square_footage_ratio
    );
  };

  return (
    <DashboardColumnChart
      title="Market Rate Per Square Foot Comparison"
      data={data}
      xField="apartment_type"
      yField="average_rent_square_footage_ratio"
      seriesField="community_name"
      grouped
      labelFormatter={labelFormatter}
      tooltipFormatter={tooltipFormatter}
      tooltipSorter={tooltipSorter}
    />
  );
};

export default DashboardReportRentSquareFootageChart;
