import React, { useCallback } from 'react';

import { Typography } from 'antd';
import styled from 'styled-components';

import ShopRequestModalBaseCommunitySelect from './ShopRequestModalBaseCommunitySelect';
import OptionsCheckboxes from './ShopRequestModalCheckboxes';
import { useShopRequestModalContext } from './ShopRequestModalContext';
import { MYSTERY_SHOP_MODAL_REQUEST_FROM } from '../constants';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledLabel = styled(Typography.Text)`
  padding: 20px 0 20px 30px;
  color: var(--text-secondary);
  font-size: 14px;
`;

const CommunitySelector = styled.div`
  display: flex;
  flex-flow: row;
  border-bottom: solid 1px var(--line-gray);
  padding: 0 0 9px 30px;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.5px;
  width: 183px;
`;

const ShopRequestCommunitiesSelector = () => {
  const { shopRequests, requestFrom, setShopRequests } = useShopRequestModalContext();

  const allIndependantLivingEnabled =
    shopRequests.filter((item: any) => item.independentLiving).length === shopRequests.length;
  const allAssistedLivingEnabled =
    shopRequests.filter((item: any) => item.assistedLiving).length === shopRequests.length;
  const allMemoryCareEnabled = shopRequests.filter((item: any) => item.memoryCare).length === shopRequests.length;

  const toggleAllAssistedLiving = useCallback(
    (checked: boolean) => {
      setShopRequests(shopRequests.map((request) => ({ ...request, assistedLiving: checked })));
    },
    [shopRequests, setShopRequests]
  );

  const toggleAllIndependentLiving = useCallback(
    (checked: boolean) => {
      setShopRequests(shopRequests.map((request) => ({ ...request, independentLiving: checked })));
    },
    [shopRequests, setShopRequests]
  );

  const toggleAllMemoryCare = useCallback(
    (checked: boolean) => {
      setShopRequests(shopRequests.map((request) => ({ ...request, memoryCare: checked })));
    },
    [shopRequests, setShopRequests]
  );

  const noCommunitiesSelected = shopRequests.length === 0;

  return (
    <Container>
      <StyledLabel>
        {requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.FURTHER
          ? 'Add communities and specify which of their care types need mystery shop data.'
          : 'Select a community, then specify which of its competitors you want to shop for.'}
      </StyledLabel>
      {requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.COMPANY && <ShopRequestModalBaseCommunitySelect />}
      <CommunitySelector>
        <Title>
          {requestFrom === MYSTERY_SHOP_MODAL_REQUEST_FROM.FURTHER ? 'COMMUNITY' : 'COMPETITORS TO SHOP FOR'}
        </Title>
        <OptionsCheckboxes
          style={{ marginLeft: '167px' }}
          independantLiving={allIndependantLivingEnabled && !noCommunitiesSelected}
          assistedLiving={allAssistedLivingEnabled && !noCommunitiesSelected}
          memoryCare={allMemoryCareEnabled && !noCommunitiesSelected}
          disabled={noCommunitiesSelected}
          toggleIndependentLiving={toggleAllIndependentLiving}
          toggleAssistedLiving={toggleAllAssistedLiving}
          toggleMemoryCare={toggleAllMemoryCare}
        />
      </CommunitySelector>
    </Container>
  );
};

export default ShopRequestCommunitiesSelector;
