import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, InputNumber, message, Row, Tabs } from 'antd';
import { format } from 'date-fns';

import axios from 'config/axiosPrivate';

import CalculationsTab from './CauculationsTab';
import CommunityTitleBar from './common/CommunityTitleBar';
import GeneratedCalculationTable from './components/GeneratedCalculationTable';

interface AllValuesType {
  current_occupancy: string | number;
  goal_occupancy: string | number;
  months_to_goal: string | number;
  lead_volume: string | number;
  move_outs: string | number;
  need_to_goal: string | number;
  inquiry_needed: string | number;
  avg_base_rent: string | number;
  LivingTypeName: string | number;
  ApartmentTypeName: string | number;
  competitors_avg_swot_score: string | number;
  first_adjustment: string | number;
  second_adjustment: string | number;
  final_recommended_rate: string | number;
}

interface Props {
  communityInfo: any;
}

const data: any[] = [];

const Calculator = ({ communityInfo }: Props) => {
  const [params] = useSearchParams();
  const communityId = params.get('id');
  const [calculatorAllData, setCalculatorAllData] = useState<any>({});
  const [isCRUDComplete, setIsCRUDComplete] = useState(false);
  const [configurationOptions, setConfigurationOptions] = useState<any[]>();
  const [searchOptions, setSearchOptions] = useState<any[]>();
  const [companyId, setCompanyId] = useState<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedService, setSelectedService] = useState(undefined);
  const [selectedFloorPlan, setSelectedFloorPlan] = useState(undefined);
  const [calculationsData, setCalculationsData] = useState<any[]>([]);
  const [currentEditId, setCurrentEditId] = useState<any>(undefined);

  const [calculations, setCalculations] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [swotLoading, setSwotLoading] = useState<boolean>(false);
  const [avgMarketRate, setAvgMarketrate] = useState(0);
  const [selectedTab, setSelectedTab] = useState<'calculator' | 'calculations'>('calculator');

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };
  const onFinish = (values: any) => {
    handleGenerate();
  };

  const handleDelete = (id: string) => {
    const payload = {
      data: [
        {
          id: id,
          op: 'deleted',
        },
      ],
    };
    axios
      .post('/communities/Save_CommunityCalculator/', payload)
      .then((res) => {
        setIsCRUDComplete(true);
      })
      .catch((err) => {
        console.log('Error deleted data');
      });
  };

  useEffect(() => {
    if (calculatorAllData && Object.keys(calculatorAllData).length !== 0) {
      form.setFieldsValue({
        avg_base_rent: calculatorAllData.avg_base_rent,
        competitors_avg_swot_score: calculatorAllData.competitors_avg_swot_score,
        current_occupancy: calculatorAllData.current_occupancy,
        final_recommended_rate: calculatorAllData.final_recommended_rate,
        ApartmentTypeName: calculatorAllData.ApartmentTypeName,
        LivingTypeName: calculatorAllData.LivingTypeName,
        goal_occupancy: calculatorAllData.goal_occupancy,
        inquiry_needed: calculatorAllData.inquiry_needed,
        move_outs: calculatorAllData.move_outs,
        need_to_goal: calculatorAllData.need_to_goal,
        lead_volume: calculatorAllData.lead_volume,
        months_to_goal: calculatorAllData.months_to_goal,
        first_adjustment: calculatorAllData.first_adjustment,
        second_adjustment: calculatorAllData.second_adjustment,
      });
    }
  }, [calculatorAllData]);

  useEffect(() => {
    if (communityId) {
      getGeneratedData();
      if (isCRUDComplete) setIsCRUDComplete(false);
    }
  }, [communityId, isCRUDComplete]);

  const getGeneratedData = () => {
    axios
      .get(`/communities/Get_CommunityCalculator/${communityId}/`)
      .then((res: any) => {
        if (res.data?.payload !== '') {
          setCalculations(res.data?.payload);
        }
      })
      .catch((err) => {
        console.log('calculations get error', err);
      });

    axios
      .get(`/communities/Get_CommunityInfo/${communityId}`)
      .then((res) => {
        const data = res.data?.payload;
        if (data) {
          setCompanyId(data.Community_Info[0]?.CompanyID);
        }
      })
      .catch((err) => {
        console.log('Error | Get_CalculatorConfig ');
      });
  };

  useEffect(() => {
    axios
      .get(`/communities/Get_UniqueList/`)
      .then((res: any) => {
        const serviceType = res.data?.payload['LivingTypes'];
        const floorPlan = res.data?.payload['ApartmentTypes'];

        const modifiedServiceTypes = serviceType.map((service: any) => ({
          label: service.type,
          value: Math.floor(service.id),
        }));

        const modifiedFloorPlans = floorPlan.map((floorPlan: any) => ({
          label: floorPlan.type,
          value: Math.floor(floorPlan.id),
        }));

        setServiceTypes(modifiedServiceTypes);
        setFloorPlans(modifiedFloorPlans);
      })
      .catch((err) => {
        console.log('get Unique list error', err);
      });
  }, []);

  const [form] = Form.useForm();

  const [allValues, setAllValues] = useState<AllValuesType>({
    current_occupancy: '',
    goal_occupancy: '',
    months_to_goal: '',
    lead_volume: '',
    move_outs: '',
    need_to_goal: '',
    competitors_avg_swot_score: '',
    inquiry_needed: '',
    avg_base_rent: '',
    LivingTypeName: '',
    ApartmentTypeName: '',
    first_adjustment: '',
    second_adjustment: '',
    final_recommended_rate: '',
  });

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues && Object.keys(changedValues).length > 0) {
      Object.keys(changedValues).forEach((key: any) => {
        form.setFieldValue(key, changedValues[key]);
      });
    }

    updateForm(form.getFieldsValue());
  };

  const handleConfigurationChange = (val: number) => {
    const foundObj: any = configurationOptions?.find((obj: any) => obj.value === val);

    if (foundObj) {
      updateForm(foundObj);
    }
  };

  const getProcessId = () => {
    return format(new Date(), 'dd-MMM-yyyy').toLocaleUpperCase();
  };

  const updateForm = (foundObj: any) => {
    const needToGoal =
      ((parseFloat(foundObj?.goal_occupancy) || 0) - (parseFloat(foundObj?.current_occupancy) || 0)) /
        (parseFloat(foundObj?.months_to_goal) || 0) +
      (parseFloat(foundObj?.move_outs) || 0);

    const inquiryNeeded = (needToGoal / (parseFloat(foundObj?.lead_volume) || 0)) * 100;

    const sales_conversion: number = (parseFloat(foundObj?.baseline_delta) || 0) - inquiryNeeded;

    form.setFieldsValue({
      baseline_delta: parseFloat(foundObj?.baseline_delta) || 0,
      current_occupancy: parseFloat(foundObj?.current_occupancy) || 0,
      lead_volume: parseFloat(foundObj?.lead_volume) || 0,
      baseline_swot: 3,
      goal_occupancy: parseFloat(foundObj?.goal_occupancy) || 0,
      move_outs: parseFloat(foundObj?.move_outs) || 0,
      community_swot_score: 0,
      months_to_goal: parseFloat(foundObj?.months_to_goal) || 0,
      competitors_avg_swot_score: parseFloat(foundObj?.competitors_avg_swot_score) || 0,
      need_to_goal: needToGoal.toFixed(2),
      inquiry_needed: inquiryNeeded.toFixed(2),
      sales_conversion: sales_conversion.toFixed(2),
    });
  };

  useEffect(() => {
    if (selectedService && selectedFloorPlan) {
      axios
        .get(`/communities/Get_CommunityCalculatorRate/${communityId}/${selectedService}/${selectedFloorPlan}/`)
        .then((res) => {
          const base_rent = res.data?.payload['Avg_Values'][0]['Avg_base_rent'];
          form.setFieldsValue({
            mkt_avg_base_rent: Number(parseFloat(base_rent).toFixed(2)),
          });
        })
        .catch((err) => {
          console.log('Error in Avg base Rent');
        });
    }
  }, [selectedService, selectedFloorPlan]);

  const handleSave = () => {
    let editRecord: any = undefined;
    if (currentEditId) {
      editRecord = calculations.find((item: any) => item.id === currentEditId);
    }
    const payload = {
      data: [
        {
          id: currentEditId ? currentEditId : 4,
          apartment_id: communityId,
          description: 'Test',
          apartment_type_id: editRecord ? editRecord.ApartmentTypeId : selectedFloorPlan,
          living_type_id: selectedService,
          current_occupancy: allValues['current_occupancy'] || 0,
          goal_occupancy: allValues['goal_occupancy'] || 0,
          months_to_goal: allValues['months_to_goal'] || 0,
          lead_volume: allValues['lead_volume'] || 0,
          move_outs: allValues['move_outs'] || 0,
          status: 1,
          need_to_goal: allValues['need_to_goal'] || 0,
          avg_base_rent: avgMarketRate || 0,
          competitors_avg_swot_score: allValues['competitors_avg_swot_score'] || 0,
          first_adjustment: allValues['first_adjustment'] || 0,
          second_adjustment: allValues['second_adjustment'] || 0,
          final_recommended_rate: allValues['final_recommended_rate'] || 0,
          inquiry_needed: allValues['inquiry_needed'] || 0,
          op: currentEditId ? 'edited' : 'added',
          disabled: false,
        },
      ],
    };
    axios
      .post('/communities/Save_CommunityCalculator/', payload)
      .then((res) => {
        // console.log("Successfully posted  save");
      })
      .catch((err) => {
        console.log('Error saving data');
      });

    if (currentEditId) setCurrentEditId(undefined);

    setIsCRUDComplete(true);
  };

  useEffect(() => {
    getCalculatorList();
    getStrengthFinder();
  }, [communityId]);

  const getStrengthFinder = () => {
    if (swotLoading) {
      return;
    }
    setSwotLoading(true);
    axios
      .get(`/communities/Get_StrengthFinder/${communityId}`)
      .then((res) => {
        const swot_score = res.data?.payload.StrengthFinder.AvgOverallScore || 0;
        form.setFieldsValue({
          competitors_avg_swot_score: Number(parseFloat(swot_score).toFixed(2)),
        });
        setSwotLoading(false);
      })
      .catch((err) => {
        setSwotLoading(false);
        console.log(err);
      });
  };
  const getCalculatorList = () => {
    axios
      .get(`/communities/Get_CommunityCalculatorList/${communityId}`)
      .then((res) => {
        const procList: any[] = res.data.payload.CalculatorProc;

        const modifiedList = procList.map((obj: any) => ({
          label: obj.process_id,
          value: obj.id,
        }));

        setSearchOptions(modifiedList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (companyId) {
      axios
        .get(`/communities/Get_CalculatorConfig/${companyId}/`)
        .then((res) => {
          const procList: any[] = res.data.payload?.CalculatorConfig;

          const modifiedList = procList.map((obj: any) => ({
            label: obj.description,
            value: obj.id,
            ...obj,
          }));

          setConfigurationOptions(modifiedList);
        })
        .catch((err) => {
          console.log('Error | Get_CalculatorConfig');
        });
    }
  }, [companyId]);

  const handleGenerate = () => {
    const formValues = form.getFieldsValue();
    const targetId = form.getFieldValue('select_configuration');
    const targetObj = configurationOptions?.find((obj) => obj?.id === targetId);
    setIsGenerating(true);
    console.log({ targetId, targetObj, configurationOptions });

    const getMktBaseRent = (obj: any, cmpList: any[]) => {
      if (obj && cmpList.length > 0) {
        const target = cmpList.find(
          (el) => el?.ApartmentTypeId === obj?.ApartmentTypeId && el?.LivingTypeId === obj?.LivingTypeId
        );
        if (target) {
          return parseInt(target?.Avg_Market_rate || 0);
        }
      }
      return 0;
    };
    axios
      .get(`/communities/Get_CommunityCalculatorRateList/${communityId}/`)
      .then((res) => {
        const objList = res.data?.payload?.Community_base_rent?.map((obj: any, idx: number) => ({
          ...formValues,
          ...obj,
          id: idx,
          ApartmentTypeId: obj.ApartmentTypeId,
          ApartmentTypeName: obj.ApartmentTypeName,
          process_id: form.getFieldValue('process_id'),
          description: '',
          mkt_avg_base_rent: getMktBaseRent(obj, res.data?.payload?.Comp_avg_base_rent),
          calc_proc_id: targetId,
          competitors_avg_swot_score: form.getFieldValue('competitors_avg_swot_score'),
          community_swot_score: form.getFieldValue('community_swot_score'),
          sales_conversion: form.getFieldValue('sales_conversion'),
        }));

        let firstAdj;
        let secondAdj;
        let finalAdj;
        const modifiedList = objList?.map((obj: any) => {
          if (obj?.community_swot_score > obj?.competitors_avg_swot_score) {
            if (obj?.community_swot_score < parseInt(obj?.baseline_swot)) {
              firstAdj =
                parseInt(obj?.mkt_avg_base_rent) +
                (((parseInt(obj?.baseline_swot) + obj?.community_swot_score) * 100) / 100) *
                  parseInt(obj?.mkt_avg_base_rent);
            } else {
              firstAdj =
                parseInt(obj?.mkt_avg_base_rent) -
                (((obj?.community_swot_score - parseInt(obj?.baseline_swot)) * 100) / 100) *
                  parseInt(obj?.mkt_avg_base_rent);
            }
          } else {
            if (obj?.competitors_avg_swot_score < parseInt(obj?.baseline_swot)) {
              firstAdj =
                parseInt(obj?.mkt_avg_base_rent) +
                (((parseInt(obj?.baseline_swot) - obj?.competitors_avg_swot_score) * 100) / 100) *
                  parseInt(obj?.mkt_avg_base_rent);
            } else {
              firstAdj =
                parseInt(obj?.mkt_avg_base_rent) -
                (((obj?.competitors_avg_swot_score - parseInt(obj?.baseline_swot)) * 100) / 100) *
                  parseInt(obj?.mkt_avg_base_rent);
            }
          }
          secondAdj = (firstAdj * obj?.sales_conversion) / 100;
          finalAdj = firstAdj + secondAdj;

          const needToGoal =
            ((parseInt(obj?.goal_occupancy) || 0) - (parseInt(obj?.current_occupancy) || 0)) /
              (parseInt(obj.months_to_goal) || 0) +
            (parseInt(obj?.move_outs) || 0);

          const inquiryNeeded = (needToGoal / (parseInt(obj?.lead_volume) || 0)) * 100;

          return {
            ...obj,
            needed_to_goal: needToGoal,
            inquiry_needed: inquiryNeeded,
            first_adjustment: firstAdj.toFixed(2),
            second_adjustment: secondAdj.toFixed(2),
            final_recommended_rate: finalAdj.toFixed(2),
          };
        });

        setIsGenerating(false);
        showMessage('success', 'Records Generated Successfully !');
        console.log({ modifiedList });
        setCalculationsData(modifiedList);
      })
      .catch((err) => {
        setIsGenerating(false);
        showMessage('success', 'Something went wrong !');
        console.log('Error | Get_CommunityCalculatorRateList', err);
      });
  };

  return (
    <div className="tab-calculator-container">
      {contextHolder}
      <Tabs
        defaultActiveKey={selectedTab}
        tabPosition="top"
        destroyInactiveTabPane={true}
        tabBarGutter={10}
        size="small"
        items={[
          {
            label: (
              <Button
                type="text"
                className={selectedTab === 'calculator' ? 'p_healing_active_tab' : 'btnStyle'}
                shape="round"
                style={{
                  marginRight: 20,
                  height: 'auto',
                  padding: '5px 20px',
                  fontSize: 18,
                }}
                onClick={() => setSelectedTab('calculator')}
              >
                Calculator
              </Button>
            ),
            key: 'calculator',

            children: (
              <div>
                <CommunityTitleBar title="Goal Calculation" />
                <br />
                <Form
                  form={form}
                  onValuesChange={handleFormChange}
                  onFinish={onFinish}
                  layout="vertical"
                  requiredMark={false}
                  autoComplete="off"
                  colon={false}
                  initialValues={{ process_id: getProcessId() }}
                >
                  <Card style={{ margin: 'auto' }}>
                    <Row gutter={[30, 10]}>
                      <Col xs={24} md={18}>
                        <Card className="box_shadow" style={{ height: '100%', background: '#f9f9f9' }}>
                          <Row gutter={[20, 10]}>
                            <Col xs={24} md={8}>
                              <Form.Item
                                name="process_id"
                                label={<p className="form_label">Date: </p>}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter process name',
                                  },
                                ]}
                              >
                                <Input type="text" />
                              </Form.Item>
                            </Col>
                            {/* <Col xs={24} md={8}>
                              <Form.Item
                                name="select_configuration"
                                label={
                                  <p className="form_label">
                                    Select Configuration:
                                  </p>
                                }
                              >
                                <Select
                                  onChange={(val) =>
                                    handleConfigurationChange(val)
                                  }
                                  placeholder="Select"
                                  options={configurationOptions}
                                />
                              </Form.Item>
                            </Col> */}
                          </Row>
                          <Row gutter={[20, 10]}>
                            <Col xs={24} md={8}>
                              <Form.Item
                                name="current_occupancy"
                                label={<p className="form_label">Current Occupancy:</p>}
                              >
                                <Input value={calculatorAllData.current_occupancy} placeholder="0" type="number" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                name="lead_volume"
                                label={<p className="form_label">Monthly Predicted Lead Volume:</p>}
                              >
                                <Input value={calculatorAllData.lead_volume} placeholder="0" type="number" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8} style={{ display: 'none' }}>
                              <Form.Item name="baseline_swot" label={<p className="form_label">Baseline Swot:</p>}>
                                <InputNumber
                                  // value={3}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                name="baseline_delta"
                                label={<p className="form_label">Sales Conversion Standard:</p>}
                              >
                                <InputNumber
                                  // value={}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[20, 10]}>
                            <Col xs={24} md={8}>
                              <Form.Item name="goal_occupancy" label={<p className="form_label">Goal Occupancy:</p>}>
                                <Input value={calculatorAllData.goal_occupancy} placeholder="0" type="number" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                name="move_outs"
                                label={<p className="form_label">Monthly Predicted Move-Outs:</p>}
                              >
                                <Input value={calculatorAllData.move_outs} placeholder="0" type="number" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8} style={{ display: 'none' }}>
                              <Form.Item
                                name="community_swot_score"
                                label={<p className="form_label">Community Swot Score:</p>}
                              >
                                <InputNumber
                                  // value={}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                name="competitors_avg_swot_score"
                                label={<p className="form_label">Competitor Market Avg SWOT Score:</p>}
                              >
                                <InputNumber
                                  value={parseFloat(calculatorAllData.competitors_avg_swot_score).toFixed(2)}
                                  style={{ width: '100%' }}
                                  addonAfter={
                                    swotLoading ? (
                                      <LoadingOutlined />
                                    ) : (
                                      <RedoOutlined onClick={getStrengthFinder} style={{ cursor: 'pointer' }} />
                                    )
                                  }
                                  controls
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[20, 10]}>
                            <Col xs={24} md={8}>
                              <Form.Item name="months_to_goal" label={<p className="form_label">Months to Goal:</p>}>
                                <Input
                                  value={parseFloat(calculatorAllData.months_to_goal).toFixed(2)}
                                  placeholder="0"
                                  type="number"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}></Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col xs={24} md={6}>
                        <Card
                          className="box_shadow"
                          style={{
                            background: '#fdfdfd',
                          }}
                        >
                          <Row>
                            <Col xs={24}>
                              <Row gutter={[20, 10]}>
                                <Col xs={24}>
                                  <Form.Item
                                    name="need_to_goal"
                                    label={<p className="form_label">Monthly Move-ins Need to Goal:</p>}
                                  >
                                    <InputNumber
                                      value={parseFloat(calculatorAllData.need_to_goal).toFixed(2)}
                                      style={{
                                        width: '100%',
                                        textAlign: 'right',
                                      }}
                                      disabled
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={[20, 10]}>
                                <Col xs={24}>
                                  <Form.Item
                                    name="inquiry_needed"
                                    label={<p className="form_label">Inquiry to Move-in % Needed:</p>}
                                  >
                                    <InputNumber
                                      value={parseFloat(calculatorAllData.inquiry_needed).toFixed(2)}
                                      addonAfter="%"
                                      style={{
                                        width: '100%',
                                        textAlign: 'right',
                                      }}
                                      disabled
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={[20, 10]}>
                                <Col xs={24}>
                                  <Form.Item
                                    name="sales_conversion"
                                    label={<p className="form_label">Sales Conversion Delta:</p>}
                                  >
                                    <InputNumber
                                      value={parseFloat(calculatorAllData.inquiry_needed).toFixed(2)}
                                      addonAfter="%"
                                      style={{
                                        width: '100%',
                                        textAlign: 'right',
                                      }}
                                      disabled
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                        <Card
                          className="box_shadow"
                          style={{
                            marginTop: '10px',
                            background: '#e3e3e3',
                            textAlign: 'center',
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            style={{ fontWeight: '100' }}
                            // onClick={handleGenerate}
                            loading={isGenerating}
                            disabled={isGenerating}
                          >
                            Generate Records
                          </Button>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Form>

                <div
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  {' '}
                  <GeneratedCalculationTable
                    calculationsData={calculationsData}
                    setCalculationsData={setCalculationsData}
                    currentConfig={calculationsData}
                    onSuccess={() => {
                      getGeneratedData();
                      getCalculatorList();
                      setSelectedTab('calculations');
                    }}
                  />
                </div>
              </div>
            ),
            style: {
              padding: 0,
            },
          },
          {
            label: (
              <Button
                type="text"
                className={selectedTab === 'calculations' ? 'p_healing_active_tab' : 'btnStyle'}
                shape="round"
                style={{
                  marginRight: 20,
                  height: 'auto',
                  padding: '5px 20px',
                  fontSize: 18,
                }}
                onClick={() => setSelectedTab('calculations')}
              >
                Generated Calculations
              </Button>
            ),
            key: 'calculations',
            children: <CalculationsTab communityInfo={communityInfo} />,
            style: {
              padding: 0,
            },
          },
        ]}
      />
    </div>
  );
};

export default Calculator;
