import React from 'react';

import classNames from 'classnames';

import SelectAdvancedFilter from '../../lib/SelectAdvancedFilter';
import { useCommunityViewContext } from '../CommunityViewContext';

const HeaderViewFiltersCareType = () => {
  const { viewFilters, apartmentTypes, setViewFilters } = useCommunityViewContext();

  const handleSelect = (values: number[]) => {
    setViewFilters((prevState: any) => ({ ...prevState, roomTypes: values.map((item: any) => item.value) }));
  };

  const options = apartmentTypes?.map((item) => ({
    label: item.type,
    value: item.id,
  }));

  return (
    <SelectAdvancedFilter
      className={classNames({ active: viewFilters?.roomTypes?.length > 0 })}
      placeholder={'Room Type'}
      options={options}
      defaultValue={viewFilters.roomTypes}
      onSelect={handleSelect}
    />
  );
};

export default HeaderViewFiltersCareType;
